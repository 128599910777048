<template>
    <div class="list-detail-view">
        <DetailLayout
            v-bind:is-available-more-btn="itemsToShow < itemList.length"
            :on-detail="detailItem != null"
            v-on:onRequestMoreList="itemsToShow += 5">
            <template v-slot:list-title-text>
                {{$t('menu.bike.list.report')}} 관리
            </template>
<!--            <template v-slot:map-container>-->
<!--                <gmap-map-->
<!--                    ref="gmap"-->
<!--                    :center="mapCenter"-->
<!--                    :zoom="mapZoom"-->
<!--                    :options="{-->
<!--                        streetViewControl:false,-->
<!--                        fullscreenControl:false,-->
<!--                        mapTypeControl:false-->
<!--                    }"-->
<!--                    class="google-map"-->
<!--                    style="width: 100%; height: 500px;">-->
<!--                    <gmap-marker-->
<!--                        :key="index"-->
<!--                        v-for="(m, index) in markers"-->
<!--                        :position="m.position">-->
<!--                    </gmap-marker>-->
<!--                </gmap-map>-->
<!--            </template>-->
            <template v-slot:top-items>
                <div class="list-top-btns">
                    <div class="add-item" v-on:click="onClickAddBtn()">
                        <span style="margin-right: 5px;">{{$t('list.add')}}</span>
                        <span class="fas fa-plus"></span>
                    </div>
                </div>
                <div class="sort-btn-wrapper">
                    <sort-and-search-input
                        :sort-type-list="sortTypeList"

                        v-on:onEnterSearch="onSearchTags"
                        v-on:onClickSortType="onClickSortType"/>
                </div>
            </template>
            <template v-slot:list-items>
                <div v-if="itemList.length === 0" class="empty-list">
                    <p>{{$t('list.has_no_material')}}</p>
                </div>
                <div v-else>
                    <template v-for="(item, index) in itemList">
                        <report-item v-if="index < itemsToShow"
                                   v-bind:item="item"
                                   :is-selected="detailItem != null && item.id === detailItem.id"
                                   v-on:onClick="onClickItem" />
                    </template>
                </div>
            </template>
            <template v-slot:detail-item>
                <report-editor
                    v-bind:item="detailItem"

                    v-on:onClose="onClickItem"
                    v-on:updateList="updateListView"

                    :is-add-mode="isAddMode"/>
            </template>
        </DetailLayout>
    </div>
</template>

<script>
    import BoundaryMapView from "../BoundaryMapView";
    import DropdownInput from "../../inputs/DropdownInput";

    import DetailLayout from "../DetailLayout";

    import {getItemById, includesTag, isOk, reorderItems} from "../../api/common";
    import PageEditor from "../../components/contents/magazine/Editor";
    import PageItem from "../../components/contents/magazine/Item";
    import {clone} from "../../utils/VarToString";
    import {getOperations, newOperation} from "../../api/operation";
    import {setTagsByOperatorId} from "../../api/operator";
    import {getOperatorId, getUserId} from "../../store";
    import OperationEditor from "../../components/contents/assign/Editor";
    import AssignItem from "../../components/contents/assign/Item";
    import SortAndSearchInput from "../../inputs/SortAndSearchInput";
    import ReportItem from "../../components/contents/report/Item";
    import ReportEditor from "../../components/contents/report/Editor";

    export default {
        name: "MagazineView",
        components: {
            ReportEditor,
            ReportItem,
            SortAndSearchInput,
            AssignItem,
            OperationEditor,
            PageItem,
            PageEditor,
            BoundaryMapView,
            DetailLayout,
            DropdownInput,
        },
        data() {
            return {
                mapCenter: {
                    lat: 36.5, lng:127.8
                },
                mapZoom: 7,
                markers: [],

                itemsToShow: 200,
                sortTypeList: [
                    {
                        name: 'ID',
                        value: 'id',
                        order: 'asc'
                    },
                    {
                        name: 'COMPLETED_TIME',
                        value: 'completedWhen',
                        order: 'desc'
                    },
                ],
                currentSortType: {},

                itemList: [],
                detailItem: null,

                isEditMode: false,
                isAddMode: false
            }
        },
        created() {
            this.updateListView();
            this.updateEditorView();
        },
        methods: {
            updateListView: function(check = isOk) {
                this.isAddMode = false;

                let that = this;

                getOperations("report").then(function(items){
                    that.itemList = [];

                    items.forEach(function (e) {
                        if(check(e)) {
                            that.itemList.push(e);
                        }
                    });

                    that.itemList = reorderItems(that.itemList, that.currentSortType)
                })
            },
            updateEditorView: function() {
            //     this.isEditMode = this.$route.query.hasOwnProperty('edit');
            //     this.isAddMode = this.$route.query.hasOwnProperty('new')
            //
                if (this.$route.query.hasOwnProperty('id')) {
                    let contentId = this.$route.query.id;

                    let that = this;
                    getItemById("operation", contentId).then(item => {
                        if(isOk(item)) that.detailItem = item;
                    });
            //     } else if (this.isAddMode) {
            //         let that = this
            //
            //         that.detailItem = newPlace()
            //         setTagsByOperatorId(getOperatorId(), that.detailItem);
            //
            //         this.isEditMode = true
            //     } else {
            //         this.isEditMode = false
                }
            },
            onClickSortType: function(selectedOption) {
                this.currentSortType = selectedOption;

                this.items = reorderItems(this.items, this.currentSortType)
            },
            onClickAddBtn: function() {
                this.isAddMode = true;

                this.detailItem = newOperation();
                this.detailItem.userId = getUserId();
                this.detailItem.type = "report";

                setTagsByOperatorId(getOperatorId(), this.detailItem);

                this.$router.push(`/admin/report?new`);
            },
            onClickItem: function(item) {
                this.isAddMode = false;

                this.detailItem = clone(item);

                let path = `/admin/report`;
                if(item) {
                    path += `?id=${item.id}`;
                }

                if(this.$route.fullPath !== path) {
                    this.$router.push(path);
                }
            },
            onSearchTags(v) {
                this.updateListView(e => { return isOk(e) && includesTag(v, e.tags) });
            }
        }
    }
</script>
