<template>
  <div class="list-item"  style="display: block;">
    <div v-on:click="onClickList(item)" style="width: 100%;">
      <span class="col-xs-6">
        <div class="title-desc text-left" style="padding-bottom: 10px; font-weight: bold;">
            <div v-if="item.placeId > 0">
                {{item.title}}
            </div>
            <span><i class="fas" v-bind:class="{'fa-bicycle': item.type.startsWith('bike_') , 'fa-tag': item.type === 'coupon', 'fa-marker-alt': item.type === 'place_coupon', 'fa-times': item.type===''}"></i>{{getTypeString(item.type)}}</span>
            <span v-if="item.operatorId > 0">/ 사업자:{{operatorName}}</span>

        </div>


      </span>
      <span class="col-xs-6">
<!--        <div class="title-desc text-right">{{$t('operator.regNumber')}}</div>-->
          <div class="title text-left" v-if="item.timeLimited === true">시간내 무제한 쿠폰</div>
          <div class="title text-left">{{item.name}} ({{item.duration/1000/60}}분 {{item.rate}}원)</div>
        <div class="title text-right">{{item.regNumber}}</div>
      </span>
    </div>
      <div v-if="item.list != null && item.list.length > 0" style="width: 100%; display: inline-block;">
          <hr/>Child Products
          <div v-for="subItem in item.list" style="width: 100%; margin-right: 10px; margin-top: 10px;background-color: #E4E6FF; border-radius: 10px; padding:10px;" v-on:click="onClickList(subItem)">
              <div class="title text-left">
                  <i class="fas" v-bind:class="{'fa-bicycle': subItem.type.startsWith('bike_') , 'fa-tag': subItem.type === 'coupon', 'fa-marker-alt': subItem.type === 'place_coupon', 'fa-times': subItem.type===''}"></i>
                  {{getTypeString(subItem.type)}}
              </div>

              <div class="title text-left">{{subItem.title}}</div>
              <div class="title text-left">{{subItem.name}} ({{subItem.duration/1000/60}}분 {{subItem.rate}}원)</div>
              <div class="title text-left">{{subItem.price}}</div>
          </div>

      </div>
  </div>
</template>

<script>
    import {getProductTypeString} from '../../../api/product';
    export default {
        name: "Item",
        props: {
            item: {
                type: Object,
                default: function() { return{} }
            },
            operatorName: {
                type: String,
                default: 'Unknown'
            }
        },
        methods: {
            onClickList: function(item) {
                console.log("onClickList " + item.id );
                this.$emit('onClick', item);
            },
            getTypeString: getProductTypeString,
        },
    }
</script>


<style lang="scss" scoped>
  .list-item {
    display: table;
    width: 100%;
    margin-top: 15px;
    background-color: #FFF;
    padding: 15px;
    border: 1px solid #EEE;
    border-left: 10px solid yellow;
    box-shadow: 2px 3px 4px -1px rgba(0,0,0,0.3);
    cursor: pointer;

    .select-wrapper {
      display: table-cell;
      vertical-align: middle;

      input {
        width: 30px;
      }
    }

    .content-info {
      display: table-cell;
      width: 100%;
      vertical-align: middle;

      .timestamp {
        font-size: 12px;
        color: #999;
        /*text-align: right;*/
      }
      .title {
        /*font-weight: bold;*/
        font-size: 24px;
        line-height: 33px;
        margin: 10px 0;
        letter-spacing: 0.0044em;
      }
      .title-desc {
        font-weight: bold;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: -0.03em;
        /*color: #B3B3B3;*/
      }
      .channel-number {
        vertical-align: middle;
        font-size: 14px;
        color: #666;
        margin-bottom: 10px;
      }

      .access-levels {
        font-size: 12px;
        color: #666;
        margin-top: 5px;

        .read-level, .write-level {
          display: inline-block;
        }
        .read-level {
          margin-right: 5px;
          padding-right: 10px;
          border-right: 1px solid #ddd;
        }
        .access-label {
          margin-right: 5px;
        }
      }
    }

  }
  .list-item:hover,
  .list-item.is-selected {
    border-color: #1982ce;
    box-shadow: 3px 4px 6px -1px rgba(0,0,0,0.4);
    .content-info {
      .title,
      .channel-number {
        color: #fefefe;
      }
      .timestamp,
      .access-levels {
        color: #EEE;
      }
    }
  }
  .list-item:hover { background-color: #52a5e0; }
  .list-item.is-selected { background-color: #2B90D9; }

  .low-stock {
    border-left: 10px solid red;
  }
  .stock-safe {
    border-left: 10px solid green;
  }
  .stock-warning {
    border-left: 10px solid orange;
  }
  .stock-danger {
    border-left: 10px solid red;
  }
</style>
