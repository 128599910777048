import moment from 'moment';
export function millisToReadable(timestamp) {
    if(timestamp == null || timestamp == 0) {
        return '';
    }
    let date = new Date(timestamp);

    let hh = date.getHours().toString();
    if (hh.length == 1) hh = '0'+hh;

    let minute = date.getMinutes().toString();
    if (minute.length == 1) minute = '0'+minute;

    let second = date.getSeconds().toString();
    if (second.length == 1) second = '0'+second;

    return millisToDate(timestamp) +' '+hh+':'+minute+':'+second;
}

export function millisToReadableWithoutSecond(timestamp) {
    if(timestamp == 0) {
        return '';
    }
  let date = new Date(timestamp);

  let hh = date.getHours().toString();
  if (hh.length == 1) hh = '0'+hh;

  let minute = date.getMinutes().toString();
  if (minute.length == 1) minute = '0'+minute;

  return millisToDate(timestamp) +' '+hh+':'+minute;
}

export function millisToDate(timestamp, splitter = '.') {
  let date = new Date(timestamp);

  let yy = date.getFullYear().toString();

  let mm = (date.getMonth()+1).toString();
  if (mm.length == 1) mm = '0'+mm;

  let dd = date.getDate().toString();
  if (dd.length == 1) dd = '0'+dd;

  return yy+splitter+mm+splitter+dd;
}

export function toTimestamp(strDate){

    return Date.parse(strDate);
}

export function toDateFormat(timestamp){
    return moment(new Date(timestamp)).format('YYYY-MM-DDTHH:mm:ss.SSS');
}
