<template>
  <div class="detail-view">

      <div class="col-12 text-right mary-button-container" style="position: sticky; top:0; background-color: white; z-index: 2; box-shadow: 2px 2px 3px gray;">
          <div class="mary-button" style="background-color: black;" v-on:click="onClickCloseBtn()"><span><i class="fas fa-times"></i></span> {{$t('detail.close')}}</div>
          <div class="mary-button" v-show="!isEditMode" v-on:click="onClickEditBtn()"><span><i class="fas fa-edit"></i></span> {{$t('detail.edit')}}</div>
          <div class="mary-button" v-show="isEditMode" v-on:click="onClickSaveBtn()"><span><i class="fas fa-save"></i></span> {{$t('detail.save')}}</div>
          <div class="mary-button" style="background-color: red; float:left;" v-show="!isAddMode" v-on:click="onClickRemoveBtn()"><span><i class="fas fa-trash"></i></span> {{$t('detail.remove')}}</div>
      </div>

      <div class="head-group">
          <div class="top"></div><!-- ./top -->

          <div class="bottom">
              <div class="content-header">
                  <vue-form style="width: 100%;" label-width="30%">
                      <AccessDropdownInput
                          :is-read-only="!isEditMode"
                          v-bind:operatorId="detailItem.operatorId ? detailItem.operatorId : 0"
                          v-on:onClick="setOperatorId"/>
                      <hr>
<!--                      <vue-form-item :label="$t('content.parentId')">-->
<!--                          <vue-input-->
<!--                              v-model="detailItem.parentId"-->
<!--                              :disabled="!isEditMode">-->
<!--                          </vue-input>-->
<!--                      </vue-form-item>-->
                      <vue-form-item label="사용자 정보">
                          <label>{{detailItem.userInfo == null ? "없음" : detailItem.userInfo}}</label>
                      </vue-form-item>
                      <vue-form-item :label="$t('content.type')">
                          <div v-if="isAddMode">
                              <vue-radio v-model="detailItem.type" value="COUPON" :disabled="!isEditMode">
                                  COUPON
                              </vue-radio>
                              <vue-radio v-model="detailItem.type" value="POINT" :disabled="!isEditMode">
                                  POINT
                              </vue-radio>
                          </div>
                          <label v-else>{{detailItem.type}}</label>
                      </vue-form-item>
                      <vue-form-item :label="$t('content.title')">
                          <vue-input
                              v-model="detailItem.title"
                              :disabled="!isEditMode">
                          </vue-input>
                      </vue-form-item>
                      <vue-form-item :label="$t('content.name')">
                          <vue-input
                              v-model="detailItem.name"
                              :disabled="!isEditMode">
                          </vue-input>
                      </vue-form-item>
                      <vue-form-item :label="$t('coupon.description')">
                          <vue-input
                              v-model="detailItem.description"
                              :disabled="!isEditMode">
                          </vue-input>
                      </vue-form-item>
                      <vue-form-item label="사용시간 (시간)">
                          <vue-input v-model="durationH"
                                     :disabled="!isEditMode">
                          </vue-input>

                      </vue-form-item>
                      <vue-form-item label="사용시간 (분)">
                          <vue-input v-model="durationM"
                                     :disabled="!isEditMode">
                          </vue-input>

                      </vue-form-item>
                      <hr>
                      <vue-form-item :label="$t('coupon.credit')">
                          <vue-input v-if="isAddMode"
                              v-model="detailItem.credit"
                              :disabled="!isEditMode">
                          </vue-input>
                          <label v-else>{{detailItem.credit}}</label>
                      </vue-form-item>
                      <vue-form-item :label="$t('coupon.balance')" v-if="!isAddMode">
                          <label>{{detailItem.balance}}</label>
                      </vue-form-item>
                      <vue-form-item :label="$t('coupon.used')" v-if="!isAddMode">
                          <label>{{detailItem.used}}</label>
                      </vue-form-item>
                      <hr>
                      <vue-form-item :label="$t('coupon.usedTime')" v-if="!isAddMode">
                          <label>{{detailItem.usedTime}}</label>
                      </vue-form-item>
                      <vue-form-item :label="$t('coupon.validFromTime')">
                          <vue-input
                              v-model="detailItem.validFromTime"
                              :disabled="!isEditMode">
                          </vue-input>
                      </vue-form-item>
                      <vue-form-item :label="$t('coupon.validToTime')">
                          <vue-input
                              v-model="detailItem.validToTime"
                              :disabled="!isEditMode">
                          </vue-input>
                      </vue-form-item>
                        <hr>
                        <hash-tag-input v-model="detailItem.tags" v-bind:isReadOnly="!isEditMode"/>
                    </vue-form>
              </div><!-- ./content-header -->
          </div><!-- ./bottom -->
      </div><!-- ./head-group -->
  </div>
</template>

<script>
    import {millisToReadable} from "../../../utils/TimeConverter";

    import AccessDropdownInput from "../../../inputs/OperatorDropdownInput";
    import HashTagInput from "../../../inputs/HashTagInput";
    import {createCredit, deleteCredit, updateCredit} from "../../../api/credit";
    import {setTagsByOperatorId} from "../../../api/operator";

    export default {
        name: "CouponEditor",
        components: {
            AccessDropdownInput,
            HashTagInput
        },
        props: {
            detailItem: {
                type: Object,
                default: function() { return{} }
            },
            isAddMode: {
                type: Boolean,
                default: true
            },
        },
        data() {
            return {
                isEditMode: this.isAddMode,

                selectedType: this.detailItem.type,
                durationH:0,
                durationM:0,
            }
        },
        created() {

            let  m = 60000;
            let h = m*60;

            this.$data.durationH = this.detailItem.creditTime/h;
            this.$data.durationM = (this.detailItem.creditTime - (this.$data.durationH*h))/m;

        },
        watch: {
            $route() {
                this.isEditMode = this.isAddMode;
            }
        },
        methods:{
            setOperatorId: function(id) {
                setTagsByOperatorId(id, this.detailItem);
            },
            onClickCloseBtn: function() {
                this.isEditMode = false;

                this.$emit('onClose', null);
                // if(this.isAddMode || !this.isEditMode) {
                //     this.$router.push('/admin/coupon');
                // } else {
                //     this.$router.push('/admin/coupon?id='+this.detailItem.id);
                // }
            },
            onClickSaveBtn: async function() {
                let isSuccessed = false;

                let that = this;
                if(this.$data.durationH === '') {
                    this.$data.durationH = 0;
                }
                if(this.$data.durationM === '') {
                    this.$data.durationM = 0;
                }
                that.detailItem.creditTime = this.$data.durationM*60000 + this.$data.durationH*(60000*60);
                if(0 === that.detailItem.id){
                    await createCredit(that.detailItem).then(function (data) {
                        if(data.success == true) {
                            that.detailItem = data.item;
                        }
                        isSuccessed = data.success;
                    })
                } else {
                    await updateCredit(that.detailItem).then(function (coupon) {
                        that.detailItem = coupon;

                        isSuccessed = true;
                    })
                }

                if(isSuccessed){
                    this.isEditMode = false;

                    alert("저장 되었습니다.");

                    this.$emit("updateList");

                    let path =`/admin/coupon?id=${this.detailItem.id}`;
                    if(this.$route.fullPath !== path) {
                        await this.$router.push(path);
                    }
                }
            },
            onClickEditBtn: function() {
                this.isEditMode = true;
                // this.$router.push('/admin/coupon?id='+this.detailItem.id+'&edit');
            },
            onClickRemoveBtn: function() {
                let that = this;

                deleteCredit(that.detailItem.id).then(function() {
                    that.$router.push('/admin/coupon');
                });
            },
            millisToReadable: millisToReadable,
        }
    }
</script>
