<template>
    <div class="detail-view">

        <div class="col-12 text-right mary-button-container" style="position: sticky; top:0; background-color: white; z-index: 2; box-shadow: 2px 2px 3px gray;">
            <div class="mary-button" style="background-color: black;" v-on:click="onClickCloseBtn()"><span><i class="fas fa-times"></i></span> {{$t('detail.close')}}</div>
            <div class="mary-button" v-show="!isEditMode" v-on:click="onClickEditBtn()"><span><i class="fas fa-edit"></i></span> {{$t('detail.edit')}}</div>
            <div class="mary-button" v-show="isEditMode" v-on:click="onClickSaveBtn()"><span><i class="fas fa-save"></i></span> {{$t('detail.save')}}</div>
            <div class="mary-button" style="background-color: red; float:left;" v-show="!isAddMode" v-on:click="onClickRemoveBtn()"><span><i class="fas fa-trash"></i></span> {{$t('detail.remove')}}</div>
        </div>

        <div class="head-group">
            <div class="top"></div>
            <div class="bottom">
                <div class="content-header">
                    <vue-form style="width: 100%;" label-width="25%">
                        <vue-form-item :label="$t('operator.name')">
                            <vue-input
                                v-model="detailItem.name"
                                :disabled="!isEditMode">
                            </vue-input>
                        </vue-form-item>
                        <vue-form-item :label="$t('operator.type')">
                            <vue-select
                                :disabled="!isEditMode"
                                v-model="selectType"
                                @change="onTypeSelected"
                                :data="operatorTypes">
                                <vue-option
                                    v-for="(type, index) in operatorTypes"
                                    :key="'type-' + index"
                                    :value="type.value"
                                    :label="type.label">
                                </vue-option>
                            </vue-select>
                        </vue-form-item>
<!--                        <vue-form-item :label="$t('operator.type')">-->
<!--                            <vue-input-->
<!--                                v-model="detailItem.type"-->
<!--                                :disabled="!isEditMode">-->
<!--                            </vue-input>-->
<!--                        </vue-form-item>-->
                        <vue-form-item :label="$t('operator.email')">
                            <vue-input
                                v-model="detailItem.email"
                                :disabled="!isEditMode">
                            </vue-input>
                        </vue-form-item>
                        <vue-form-item :label="$t('operator.phoneNumber')">
                            <vue-input
                                v-model="detailItem.phoneNumber"
                                :disabled="!isEditMode">
                            </vue-input>
                        </vue-form-item>
                        <vue-form-item :label="$t('operator.code')">
                            <vue-input
                                v-model="detailItem.code"
                                :disabled="!isEditMode">
                            </vue-input>
                        </vue-form-item>
                        <vue-form-item :label="$t('operator.regNumber')">
                            <vue-input
                                v-model="detailItem.regNumber"
                                :disabled="!isEditMode">
                            </vue-input>
                        </vue-form-item>
                        <vue-form-item :label="$t('operator.taxNumber')">
                            <vue-input
                                v-model="detailItem.taxNumber"
                                :disabled="!isEditMode">
                            </vue-input>
                        </vue-form-item>
                        <vue-form-item :label="$t('operator.bankName')">
                            <vue-input
                                v-model="detailItem.bankName"
                                :disabled="!isEditMode">
                            </vue-input>
                        </vue-form-item>
                        <vue-form-item :label="$t('operator.accountNumber')">
                            <vue-input
                                v-model="detailItem.accountNumber"
                                :disabled="!isEditMode">
                            </vue-input>
                        </vue-form-item>
                        <vue-form-item :label="$t('asset.lat')">
                            <vue-input
                                v-model="detailItem.lat"
                                :disabled="!isEditMode">
                            </vue-input>
                        </vue-form-item>
                        <vue-form-item :label="$t('asset.lon')">
                            <vue-input
                                v-model="detailItem.lon"
                                :disabled="!isEditMode">
                            </vue-input>
                        </vue-form-item>
                        <hr>
                        <hash-tag-input v-model="detailItem.tags" v-bind:isReadOnly="!isEditMode"/>
                    </vue-form>
                </div><!-- ./content-header -->
            </div><!-- ./bottom -->
        </div><!-- ./head-group -->
    </div>
</template>

<script>
  import {millisToReadable} from "../../../utils/TimeConverter";
  import {createOperator, deleteOperator, getOperatorTypeList} from "../../../api/operator";

  import HashTagInput from "../../../inputs/HashTagInput";

  export default {
      name: 'EditorOperator',
        components: {
            HashTagInput
        },
      props: {
          detailItem: {
              type: Object,
              default: function () {
                  return {}
              }
          },
          isEditMode: {
              type: Boolean,
              default: true
          },
          isAddMode: {
              type: Boolean,
              default: true
          }
      },
      data(){
          return{
              modelKey: 'operator',
              operatorTypes: getOperatorTypeList(),
              selectType: this.detailItem.type,
          }
      },
      // watch: {
      //     selectType: function(){
      //         // console.log("type changed to " + this.selectType);
      //         this.detailItem.type = this.selectType;
      //         // console.log("type changed to " + this.detailItem.type);
      //     },
      // },
      // created() {
      //     this.$data.operatorTypes = getOperatorTypeList();
      //     this.setSelectType();
      // },
      methods: {
          onTypeSelected: function(value){
              this.detailItem.type = value;
          },
          // setSelectType: function() {
          //     this.$data.selectType = this.detailItem.type;
          // },
          onClickSaveBtn: function() {
              let that = this;

              // if(0 === this.$data.content.id){
              createOperator(that.detailItem).then(function (operator) {
                  // console.log("Operator saved ")
                  // console.log(operator);
                  let path = `/admin/${that.modelKey}?id=${operator.id}`;

                  that.$router.push(path)
              });
              // } else {
              //   that.$data.content.pass
              //   updateUser(that.$data.content).then(function (post) {
              //     let path = `/admin/operator?id=` + post.id ;
              //
              //     that.$router.push(path)
              //     });
              //   }
          },
          onClickCloseBtn: function() {
              let that = this;

              if(that.isAddMode || !that.isEditMode) {
                  that.$router.push(`/admin/${that.modelKey}`);
              } else {
                  that.$router.push(`/admin/${that.modelKey}?id=${that.$route.query.id}`);
              }
          },
          onClickEditBtn: function() {
            this.$router.push(`/admin/${this.modelKey}?id=${this.detailItem.id}&edit`);
          },
          onClickRemoveBtn: function () {
              let that = this;

              deleteOperator(that.detailItem.id).then(function (post) {
                  let path = `/admin/${that.modelKey}`;

                  that.$router.push(path)
              });
          },
          millisToReadable: millisToReadable
    }
  };
</script>
