<template>
    <transition name="slide-fade">
      <div v-if="showMenu == true" style="position:absolute; top:0; left:0; background-color: rgba(0,0,0,0.6); width: calc(100%); height: 100%;z-index: 2;" @click="showMenu = false">
          <div class="side-menu" v-on:click.stop="dummy">
              <div style="background: white; box-shadow: 3px 3px 20px black;">
                  <router-link
                      v-for="(value, key) in $t('menu2')"
                      v-if="superAdmin == true || (superAdmin == true && value.superAdmin == true) || (operatorManager == true && value.operatorManager == true) || (placeUser == true && value.placeManager == true)"
                      :key="'side-menu-' + key"
                      class="list-group-item"
                      :to="/admin/+ key"
                      v-on:click.native="sendTitle(value.title + ' 관리')"
                  >
                      <!--        <span v-bind:class="value[1]"/>-->
                      <span class="side-menu-text">{{value.title}} 관리</span>
                      <div class="activated-menu"></div>
                  </router-link>
                  <router-link to="/admin" exact class="list-group-item" v-on:click.native="onClickLogout" >로그아웃</router-link>
        <!--        <a :href="'#'+key" id="contents-head" class="list-group-item collapse-head" data-toggle="collapse" style="text-align: initial !important;background: #ddd; font-weight: bold;border:1px solid #bbb;">-->
        <!--          <span :class="value.icon"/>-->
        <!--          <span class="side-menu-text">{{value.title}}</span>-->
        <!--          <i class="active-arrow glyphicon glyphicon-chevron-down" style="position: absolute; right: 5px; top: 13px;"/>-->
        <!--        </a>-->
        <!--        <div class="list-group collapse" :id="key">-->
        <!--          <router-link-->
        <!--            v-for="(value, key) in value.list"-->
        <!--            :key="'side-menu-' + key"-->
        <!--            class="list-group-item"-->
        <!--            :to="/admin/+ key" >-->
        <!--    &lt;!&ndash;        <span v-bind:class="value[1]"/>&ndash;&gt;-->
        <!--            <span class="side-menu-text">{{value}} 관리</span>-->
        <!--              <div class="activated-menu"></div>-->
        <!--          </router-link>-->
        <!--        </div>-->
              </div>
          </div>
      </div>
    </transition>
</template>

<script>
    import EventBus from '../api/event-bus.js';
    import {isOperatorManager, isPlaceUser, isSuperAdmin} from "../store";
    import {cleanLocalStorage} from "../store/auth.module";
    export default {
        name: "SideMenu",
        data() {
            return{
                authorised:false,
                superAdmin:false,
                operatorManager:false,
                placeUser:false,
                showMenu:false,
            }
        },
        created() {
            // if(localStorage.getItem("authCode") != undefined &&
            //     localStorage.getItem("authCode").length != 0 &&
            //     localStorage.getItem("id") != undefined &&
            //     localStorage.getItem("id") > 0) {
            //     this.$data.authorised = true;
            // }
            EventBus.$on('logon', this.onLogged);
            EventBus.$on('sessionExpired', this.onExpired);
            EventBus.$on('toggleMenu', this.toggleMenu);
            this.$data.superAdmin = isSuperAdmin();
            this.$data.placeUser = isPlaceUser();
            this.$data.operatorManager = isOperatorManager();
        },
        methods: {
            dummy: function() {

            },
            onLogged: function(logged) {
                console.log("onLogged " + logged);
                this.$data.authorised = logged;
                this.$data.superAdmin = isSuperAdmin();
                this.$data.placeUser = isPlaceUser();
                this.$data.operatorManager = isOperatorManager();
            },
            isAuthorised: function() {
                return this.$parent.authorised;
            },
            loggedOnHandler: function(logged) {
                // console.log("Emit works");

                this.$data.authorised= logged;
            },
            onExpired: function() {
                console.log("Expired");
                this.$data.authorised = false;
                this.$data.superAdmin = false;
                this.$data.authorised = false;
                this.$data.operatorManager = false;
                this.$router.push('/');
            },
            toggleMenu: function(show) {
                this.$data.showMenu = !this.$data.showMenu;
            },
            sendTitle: function(title) {
                EventBus.$emit("setTitlePath", title);
                this.$data.showMenu = false;
            },
            onClickLogout: function () {
                cleanLocalStorage();

                this.$data.showMenu = false;
                this.$router.push('/admin');
            }
        }
    }
</script>

<style lang="scss" scoped>
  .side-menu {
    position: fixed;
    width: 220px;
    height: 100vh;
      padding-right: 20px;
    top: 50px;

    /*background-color: #FFF;*/
    z-index: 10;

      overflow-x: visible;

    overflow-y: scroll;

    padding-bottom: 100px;
      -ms-overflow-style: none;
    .list-group-item {
      position: relative;
      display: block;
      padding: 10px 15px;
      margin-bottom: -1px;
      background-color: #fff;
      border: 1px solid #ddd;
      border-right: 0;
      color: #333;
      .glyphicon,
      .glyphicons {
        margin-right: 5px;
      }

    }
    .list-group-item.router-link-active {
      background-color: #2b90d9;
      border-color: #1982ce;
      color: #fefefe;
    }
      .activated-menu {
          display: none;
      }
    .list-group-item.home.router-link-active {
      background-color: #fff;
      border: 1px solid #ddd;
      color: #333;

    }
    .list-group-item.home.router-link-exact-active.router-link-active {
      background-color: #2b90d9;
      border-color: #1982ce;
      color: #fefefe;
    }


    .list-group {
      margin: 0;

      .list-group-item {
        padding-left: 35px;
      }
    }

  }
  .side-menu::-webkit-scrollbar {
      display: none;
  }
  .router-link-active > .activated-menu {
      display: block;
      position: absolute;
      left: 100%;
      top:0;
      height: 100%;
      width: 20px;
      background-color:#2b90d9;
      clip-path: polygon(0 0, 100% 50%, 0 100%);
  }
  .slide-fade-enter-active {
      transition: all .3s ease;
  }
  .slide-fade-leave-active {
      transition: all .3s ease;
  }
  .slide-fade-enter, .slide-fade-leave-to
      /* .slide-fade-leave-active below version 2.1.8 */ {
      transform: translateX(-200px);
      opacity: 0;
  }
</style>
