<template>
    <div style="width: 100%; min-height: 100%; position: relative;">
        <div style="width: 100%; background: white; box-shadow: 3px 3px 5px gray; position:sticky; top: 0;z-index: 1">
            <div class="container-fluid">
                <div class="row" style="padding: 10px;">
                    <div class="col-12">
                        <button class="btn" v-bind:class="{'btn-success': mode === 'booking'}" @click="changeMode('booking')">예약관리</button>
                        <button class="btn" v-bind:class="{'btn-success': mode === 'products'}" @click="changeMode('products')">상품관리</button>
                        <button class="btn" v-bind:class="{'btn-success': mode === 'sales'}" @click="changeMode('sales')">판매현황</button>
                        <button class="btn" v-bind:class="{'btn-success': mode === 'statement'}" @click="changeMode('statement')">판매정산</button>
                        <button class="btn" v-bind:class="{'btn-success': mode === 'notification'}" @click="changeMode('notification')">알림관리</button>
                    </div>
                </div>
                <div class="row" style="padding: 10px;">
                    <div class="col-12">
                        <select v-model="currentPlaceId" @change="onPlaceSelected" style="width: 250px; height: 38px; line-height: 38px; border-radius: 5px;">
                            <option value="0">플레이스 선택</option>
                            <template v-for="p in places">
                                <option v-bind:key="'place-selector-'+p.id" :value="p.id">{{p.name}}</option>
                            </template>
                        </select>

                        <button class="btn btn-primary" v-if="mode === 'products'" style="margin-left: 30px;" @click="addNewProductItem"><i class="fas fa-plus"></i> 상품등록</button>
                    </div>
                </div>
            </div>
        </div>
        <div style="width: 100%;">

            <div class="container" v-if="mode === 'products'">

                <div class="row">
                    <div class="col-12 col-md-4 col-lg-3" v-for="p in products" v-bind:key="'product-card-' + p.id">
                        <div class="product-card">
                            <div class="product-card-img" v-bind:style="{backgroundImage: 'url(' + p.imageUrl + ')'}">

                            </div>
                            <div class="product-card-info">
                                {{p.title}}
                                <br/>

                                {{Math.floor(p.duration / (1000 * 60 * 60))}}시간
                                <br/>
                                {{p.rate.toLocaleString()}}원
                                <div>
                                    <button class="btn" v-bind:class="{'btn-success': p.available === true, 'btn-danger': p.available === false}" @click="toggleProductAvailability(p.id, !p.available)">
                                        <span v-if="p.available === true">ON</span>
                                        <span v-else>OFF</span>
                                    </button>

                                    <button class="btn btn-danger" style="margin-left: 10px;">품절설정</button>
                                    <button class="btn btn-primary" style="margin-left: 10px;" @click="openProductItem(p.id)">수정</button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="container" v-else-if="mode === 'booking'">

            </div>
            <div class="container" v-else-if="mode === 'sales'">

            </div>
            <div class="container" v-else-if="mode === 'statement'">

            </div>
            <div class="container" v-else-if="mode === 'notification'">

            </div>

        </div>
        <div style="position: fixed; z-index: 3; top:0; left: 0; width: 100%; height: 100%; background-color: rgba(0,0,0,0.5);" v-if="editProductItem != false">
            <div style="position: absolute; top:70px; left:50%; width: 1200px; height: calc(100% - 80px); transform: translateX(-50%); background-color: white; border-radius: 10px; box-shadow: 5px 5px 20px black; overflow-y: scroll;">
                <product-editor
                    v-bind:detail-item="editProductItem"
                    :on-closed="onCloseProduct"
                    :on-updated="onProductUpdated"
                    v-bind:places="[currentPlace]"
                    v-bind:items="products"

                    :is-add-mode="editProductItem.id === 0"/>
            </div>
        </div>
    </div>
</template>

<script>
import {getPlaces} from "@/api/place";
import {
    generateNewProduct,
    getPlaceBookingProduct,
    getPlaceProducts,
    getProductById,
    togglePlaceProductAvailability
} from "@/api/product";
import Editor from "@/components/contents/product/Editor.vue";
import {showDialog} from "@/api/common";


export default {
    name: "BookingManager",
    data() {
        return {
            mode:'booking',
            userRole:0,
            currentPlaceId:0,
            currentPlace:false,
            places:[],
            products:[],
            editProductItem:false,


        }
    },
    components: {
        'product-editor' : Editor,
    },
    created() {
    },
    mounted() {
        this.onStart();
    },
    methods: {
        dummy: function() {
            console.log("dummy");
        },
        onStart: function() {
            let self = this;
            console.log("onStart");
            getPlaces('typeClass=booking')
                .then(function(list) {
                    self.$data.places = list;
                    if(self.$data.places.length == 1) {
                        self.$data.currentPlaceId = self.$data.places[0].id;
                        self.$data.currentPlace = self.$data.places[0];
                        // §console.log("")
                        // self.connect();
                        // self.onPlaceSelected();
                        self.onModeSelected();
                    }
                })
                .catch(function(err) {
                    console.log(err);
                    if(err.response != undefined) {
                        console.log(err.response);
                    }
                })
        },
        onPlaceSelected: function() {
            this.$data.currentPlace = false;
          for(let i = 0 ; i < this.$data.places.length ; i++) {
              if(this.$data.places[i].id === this.$data.currentPlaceId) {
                  this.$data.currentPlace = this.$data.places[i];
                  break;
              }
          }
          if(this.$data.currentPlaceId === 0) {
              this.onModeSelected();
          }
        },
        changeMode: function(mode) {
          if(this.$data.mode === mode) {
              return;
          }
          this.$data.mode = mode;
          this.onModeSelected();
        },
        onModeSelected: function() {
            if(this.$data.mode === 'booking') {

            } else if (this.$data.mode === 'products') {
                this.getProductList();
            } else if(this.$data.mode === 'sales') {

            } else if(this.$data.mode === 'statement') {

            } else if(this.$data.mode === 'notification') {

            }
        },
        getProductList: function() {
            let self = this;
            getPlaceProducts(this.$data.currentPlaceId)
                .then(function(data) {
                    if(data.success == true) {
                        self.$data.products = data.item;
                    } else {
                        console.log(data.message);
                    }
                })
                .catch(function(err) {
                    let msg = err;
                    if(err.response != undefined) {
                        msg += ' (' + err.response + ')';
                        console.log(err.response);
                    }
                    console.log(msg);
                })
        },
        onCloseProduct: function() {
            console.log("onclosed called");
            this.$data.editProductItem = false;
        },
        onProductUpdated: function(item) {
            this.$data.editProductItem = false;
            for(let i = 0 ; i < this.$data.products.length ; i++) {
                if(this.$data.products[i].id === item.id) {
                    this.$data.products.splice(i, 1, item);

                }
            }
        },
        addNewProductItem: function() {
          this.$data.editProductItem = generateNewProduct();
          this.$data.editProductItem.placeId = this.$data.currentPlaceId;
        },
        openProductItem: function(id) {
            let self = this;
            getPlaceBookingProduct(this.$data.currentPlaceId, id)
                .then(function(data) {
                    if(data.success === true) {
                        self.$data.editProductItem = data.item;
                    } else {
                        showDialog(data.message);
                    }
                })
                .catch(function(err) {
                    console.log(err);
                    let msg = err;
                    if(err.response !== undefined) {
                        console.log(err.response);
                        msg += ' (' + err.response + ')';
                    }

                    showDialog(msg, "ERROR");
                })
        },
        toggleProductAvailability: function(id, available) {
            let self = this;
            togglePlaceProductAvailability(this.$data.currentPlaceId, id, available)
                .then(function(data) {
                    if(data.success === true) {
                        self.onProductUpdated(data.item);
                    } else {
                        showDialog(data.message);
                    }
                })
                .catch(function(err) {
                    let msg = err;
                    if(err.response !== undefined) {
                        msg += ' (' + err.response + ')';
                    }
                    showDialog(msg, "ERROR");
                })
        },

    }
}
</script>

<style scoped>
.product-card {
    width: 100%;
    margin-top:10px;
    margin-bottom: 10px;
    box-shadow: 3px 3px 5px gray;
}
.product-card-img {
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    padding-bottom: 100%;
    background-position: center;
}
.product-card-info {
    background-color: white;
    padding: 10px;
}
</style>
