import {errorHandler, getCommonHeader} from "./common";
import EventBus from "./event-bus";

const axios = require('axios');

export function getNotification(id) {
    return new Promise(function(resolve, reject) {
        EventBus.$emit("showLoading", true, "가져오는중");
        axios.get(process.env.VUE_APP_API_BASE_URL + 'admin/notification/' + id, {
            headers: getCommonHeader()
        })
            .then(function(response) {
                console.log(response);
                EventBus.$emit("showLoading", false);
                let data = response.data;
                resolve(data);
            })
            .catch(function(err) {
                console.log("get hours error");
                console.log(err);
                if(err.response != undefined) {
                    console.log(err.response);
                }
                EventBus.$emit("showLoading", false);
                errorHandler(err);
                reject(err);
            })
    })
}
export function addNotifications(items) {
    return new Promise(function(resolve, reject) {
        EventBus.$emit("showLoading", true, "가져오는중");
        axios.post(process.env.VUE_APP_API_BASE_URL + 'admin/notification/', JSON.stringify(items),{
            headers: getCommonHeader()
        })
            .then(function(response) {
                console.log(response);
                EventBus.$emit("showLoading", false);
                let data = response.data;
                resolve(data);
            })
            .catch(function(err) {
                console.log("get hours error");
                console.log(err);
                if(err.response != undefined) {
                    console.log(err.response);
                }
                EventBus.$emit("showLoading", false);
                errorHandler(err);
                reject(err);
            })
    })
}

export function updateNotification(item) {
    return new Promise(function(resolve, reject) {
        EventBus.$emit("showLoading", true, "가져오는중");
        axios.post(process.env.VUE_APP_API_BASE_URL + 'admin/notification/update', JSON.stringify(item),{
            headers: getCommonHeader()
        })
            .then(function(response) {
                console.log(response);
                EventBus.$emit("showLoading", false);
                let data = response.data;
                resolve(data);
            })
            .catch(function(err) {
                console.log("get hours error");
                console.log(err);
                if(err.response != undefined) {
                    console.log(err.response);
                }
                EventBus.$emit("showLoading", false);
                errorHandler(err);
                reject(err);
            })
    })
}
export function deleteNotification(id) {
    return new Promise(function(resolve, reject) {
        EventBus.$emit("showLoading", true, "가져오는중");

        axios.delete(process.env.VUE_APP_API_BASE_URL + 'admin/notification/' + id, {
            headers: getCommonHeader()
        })
            .then(function(response) {
                console.log(response);
                EventBus.$emit("showLoading", false);
                let data = response.data;
                resolve(data);
            })
            .catch(function(err) {
                console.log("get hours error");
                console.log(err);
                if(err.response != undefined) {
                    console.log(err.response);
                }
                EventBus.$emit("showLoading", false);
                errorHandler(err);
                reject(err);
            })
    })
}
export function getAdminNotification(from, to) {
    return new Promise(function(resolve, reject) {
        EventBus.$emit("showLoading", true, "가져오는중");
        axios.get(process.env.VUE_APP_API_BASE_URL + 'admin/notifications/' + from + '/' + to, {
            headers: getCommonHeader()
        })
            .then(function(response) {
                console.log(response);
                EventBus.$emit("showLoading", false);
                let data = response.data;
                resolve(data);
            })
            .catch(function(err) {
                console.log("get hours error");
                console.log(err);
                if(err.response != undefined) {
                    console.log(err.response);
                }
                EventBus.$emit("showLoading", false);
                errorHandler(err);
                reject(err);
            })
    })
}

export function getShopNotification(placeId, id) {
    return new Promise(function(resolve, reject) {
        EventBus.$emit("showLoading", true, "가져오는중");
        axios.get(process.env.VUE_APP_API_BASE_URL + 'admin/shop/' + placeId + '/notification/' + id, {
            headers: getCommonHeader()
        })
            .then(function(response) {
                console.log(response);
                EventBus.$emit("showLoading", false);
                let data = response.data;
                resolve(data);
            })
            .catch(function(err) {
                console.log("get hours error");
                console.log(err);
                if(err.response != undefined) {
                    console.log(err.response);
                }
                EventBus.$emit("showLoading", false);
                errorHandler(err);
                reject(err);
            })
    })
}
export function markNotificationAsRead(placeId, id) {
    return new Promise(function(resolve, reject) {
        EventBus.$emit("showLoading", true, "처리중");
        axios.get(process.env.VUE_APP_API_BASE_URL + 'admin/shop/' + placeId + '/notification/mark/read/' + id, {
            headers: getCommonHeader()
        })
            .then(function(response) {
                console.log(response);
                EventBus.$emit("showLoading", false);
                let data = response.data;
                resolve(data);
            })
            .catch(function(err) {
                console.log("get hours error");
                console.log(err);
                if(err.response != undefined) {
                    console.log(err.response);
                }
                EventBus.$emit("showLoading", false);
                errorHandler(err);
                reject(err);
            })
    })
}


export function getShopNotifications(placeId, from) {
    return new Promise(function(resolve, reject) {
        EventBus.$emit("showLoading", true, "가져오는중");
        axios.get(process.env.VUE_APP_API_BASE_URL + 'admin/shop/' + placeId + '/notifications?from=' + from , {
            headers: getCommonHeader()
        })
            .then(function(response) {
                console.log(response);
                EventBus.$emit("showLoading", false);
                let data = response.data;
                resolve(data);
            })
            .catch(function(err) {
                console.log("get hours error");
                console.log(err);
                if(err.response != undefined) {
                    console.log(err.response);
                }
                EventBus.$emit("showLoading", false);
                errorHandler(err);
                reject(err);
            })
    })
}

export function newNotificationItem() {
    let item = {
        id:0,
        parentId:0,
        placeId:0,
        title:'',
        description:'',
        content:'',
        image:'',
        important:false,
    }
    return item;
}
