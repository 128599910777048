<template>
    <div style="width: 100%; height:100%; position: relative;">
        <gmap-map
            ref="gmapPoints"
            :center="mapCenter"
            :zoom="mapZoom"
            :options="{
                        streetViewControl:false,
                        fullscreenControl:false,
                        mapTypeControl:false
                    }"
            @click="clickOnMap"
            class="google-map"
            style="width: 100%; height: 100%;">
            <gmap-marker

                v-for="(m, index) in markers"
                v-if="editItem == false || m.id !== editItem.id"
                :key="m.id"
                :position="m"
                @click="onMapAssetClick(m)"
                :icon="{
                            url: require('../../assets/images/ic_' + m.type + '.png'),
                            scaledSize: {width:30, height:30},
                            // labelOrigin:m.position,
                            }"

            >
            </gmap-marker>

            <gmap-marker
                v-if="editItem !== false && editItem.type !== '' && editItem.lat !== 0 && editItem.lng !== 0"
                :key="'edit-0'"
                :position="editItem"
                :icon="{
                            url: require('../../assets/images/ic_' + editItem.type + '.png'),
                            scaledSize: {width:60, height:60},
                            // labelOrigin:m.position,
                            }"
            >
            </gmap-marker>
        </gmap-map>
        <div style="position: absolute; top: 10px; left: 10px; display:flex; background-color: white;padding:10px;">
            <select v-model="selectedOperatorId" v-on:change="onOperatorChanged">
                <option v-for="o in operators" :value="o.value">{{o.name}}</option>
            </select>
            <select v-model="type" v-on:change="getPoints">
                <option v-for="t in typeList" :value="t.val">{{t.name}}</option>
            </select>
        </div>
        <div class="floating-button" style="position: absolute; bottom: 20px; left: 20px;" v-on:click="addNewPoint">
            <i class="fas fa-plus"></i>
        </div>

        <div class="point-editor-container" v-if="editItem != false">
            <div class="flex-form-row">
                <label>사업자</label>
                <select class="form-input" v-model="editItem.operatorId">
                    <option v-for="o in operators" :value="o.value">{{o.name}}</option>
                </select>
            </div>
            <div class="flex-form-row">
                <label>Type</label>
                <select class="form-input" v-model="editItem.type">
                    <option v-for="t in typeList" :value="t.val">{{t.name}}</option>
                </select>
            </div>
            <div class="flex-form-row">
                <label>이름</label>
                <input class="form-input" type="text" v-model="editItem.name"/>
            </div>
            <div class="flex-form-row">
                <label>위치</label>
                <div class="form-input">{{editItem.lat}},{{editItem.lng}}</div>
            </div>
            <div class="flex-form-row">
                <label>메모, 설명</label>
                <textarea class="form-input" rows="3" v-model="editItem.title"/>
            </div>

            <div class="flex-form-row">
                <label>이미지</label>
                <div class="form-input">
<!--                    <label>대표 이미지 URL</label>-->
                    <progress value="0" max="100" id="uploader"></progress>
                    <input accept="image/*" type="file" ref="file" value="upload" @change="fileBtn">
                    <img width="100%" :src="editItem.imageUrl"/>
                </div>
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-4">
                        <button class="btn btn-dark" v-on:click="editItem = false">Close</button>
                    </div>
                    <div class="col-4">
                        <button class="btn btn-danger" v-on:click="askDeleteItem(editItem)">삭제</button>
                    </div>
                    <div class="col-4">
                        <button class="btn btn-success" v-on:click="updateItem">Save</button>
                    </div>
                </div>
            </div>
        </div>
        <v-dialog max-width="400px" persistent/>
    </div>
</template>

<script>
    import {getOperatorId} from "../../store";
    import {getOperatorList} from "../../api/operator";
    import {deleteAsset, getAssetsUrl, writeAsset} from "../../api/asset";
    import {getApp} from "firebase/app";
    import {getDownloadURL, getStorage, ref, uploadBytesResumable} from "firebase/storage";
    import moment from "moment";
    // import * as firebase from "firebase";
    // import "firebase/auth";
    // import "firebase/storage";

    export default {
        name: "ParkingPointView",
        data() {
            return {
                mapCenter: {
                    lat: 36.5, lng:127.8
                },
                mapZoom: 7,
                operators:[],
                markers:[],
                type:'parking_point',
                selectedOperatorId: getOperatorId(),
                editItem:false,
                typeList: [
                    {
                        name:"주차위치",
                        val: 'parking_point',
                    },
                    {
                        name:"주차금지위치",
                        val: 'no_parking_point',
                    },
                    {
                        name:"주의",
                        val: 'attention',
                    },
                    {
                        name:"정지",
                        val: 'stop',
                    },
                    {
                        name:"자전거도로",
                        val: 'bikeroad',
                    },
                    {
                        name:"중요포인트",
                        val: 'bookmark',
                    }
                ]
            }
        },
        created() {

        },
        mounted() {
            this.initialise();
        },
        beforeDestroy() {
        },
        destroyed() {
        },
        methods: {
            async initialise() {
                this.operators = await getOperatorList();
                console.log(this.operators);
                this.getPoints();
                if(this.selectedOperatorId != 0) {
                    for (let i = 0; i < this.$data.operators.length; i++) {
                        if (this.selectedOperatorId == this.$data.operators[i].value) {
                            this.mapCenter.lat = this.$data.operators[i].lat;
                            this.mapCenter.lng = this.$data.operators[i].lng;

                            this.$refs.gmapPoints.$mapPromise.then((map) => {
                                map.setZoom(17);
                            });
                        }
                    }
                }
            },
            onOperatorChanged: function() {
                if(this.selectedOperatorId != 0) {
                    for (let i = 0; i < this.$data.operators.length; i++) {
                        if (this.selectedOperatorId == this.$data.operators[i].value) {
                            this.mapCenter.lat = this.$data.operators[i].lat;
                            this.mapCenter.lng = this.$data.operators[i].lng;
                            console.log("Map Center");
                            console.log(this.mapCenter);
                            this.$refs.gmapPoints.$mapPromise.then((map) => {
                                map.setZoom(15);
                            });
                        }
                    }
                }
                this.getPoints();
            },
            getPoints: function() {
                let url = "/assets?type="+this.$data.type + "&operatorId=" + this.selectedOperatorId;
                this.$data.markers = [];
                let self = this;
                getAssetsUrl(url)
                .then(function (points){
                    points.forEach(function (e) {
                        e.lng = e.lon;
                        self.$data.markers.push(e);

                    });
                })
                .catch(function(err) {

                })
            },
            onMapAssetClick: function(m) {
                this.$data.editItem = JSON.parse(JSON.stringify(m));
            },
            clickOnMap: function(ev) {
                console.log("Editor Map Click Event " + ev.latLng) ;
                console.log(ev);
                if(this.$data.editItem != false) {
                    this.$data.editItem.lat = ev.latLng.lat();
                    this.$data.editItem.lng = ev.latLng.lng();
                }

            },
            addNewPoint: function() {
                this.$data.editItem = {
                    name:'',
                    type:'parking_point',
                    lat:0,
                    lng:0,
                    operatorId:this.selectedOperatorId,
                    title:'',
                }
            },
            askDeleteItem: function(item) {
                let self = this;
                this.$modal.show('dialog', {
                    //title: '로그아웃',
                    text: '삭제 하시겠습니까?',
                    buttons: [
                        {
                            title: '아니요',
                            handler: () => {
                                self.$modal.hide('dialog');

                            }
                        },
                        {
                            title: '삭제',
                            handler: () => {
                                self.$modal.hide('dialog');
                                self.deleteItem(item);
                            }
                        },

                    ]

                });
            },
            deleteItem: function(item) {
                if(item.id == 0) {
                    this.$data.editItem = false;
                }
                let self = this;
                deleteAsset(item.id)
                .then(function(data) {

                    if(self.$data.editItem.id == item.id) {
                        self.$data.editItem = false;
                    }


                    for(let i = 0 ; i < self.$data.markers.length ; i++) {
                        if(self.$data.markers[i].id === item.id) {
                            self.$data.markers.splice(i, 1);
                            return;
                        }
                    }
                })
                .catch(function(err){

                })
            },
            updateItem: function() {
                if(this.$data.editItem == false) {
                    return;
                }
                if(this.$data.editItem.operatorId == 0) {
                    alert("사업자를 선택해주세요");
                    return;
                }
                if(this.$data.editItem.lat == 0 || this.$data.editItem.lng == 0) {
                    alert("위치를 선택해주세요");
                    return;
                }

                if(this.$data.editItem.type === '') {
                    alert("포인트 종류를 선택해주세요");
                    return;
                }
                this.$data.editItem.lon = this.$data.editItem.lng;
                let self = this;
                writeAsset(this.$data.editItem).then(function (data) {
                    if(data.success) {
                        self.$data.editItem = false;
                        data.item.lng = data.item.lon;
                        for(let i = 0 ; i < self.$data.markers.length ; i++) {
                            if(self.$data.markers[i].id == data.item.id) {
                                self.$data.markers.splice(i, 1, data.item);
                                return;
                            }
                        }
                        self.$data.markers.push(data.item);
                    } else {
                        alert("ERROR: " + data.message);
                    }
                    // that.$router.push(`/admin/bike?id=${item.id}`);
                })
            },
            fileBtn:function(e){
                // e.preventDefault();
                const uploader = document.getElementById('uploader');
                //get file
                console.log("File Btn Added");
                console.log(e);
                let getFile = e.target.files[0];
                console.log("File name - " + getFile.name);
                //set storage ref
                let d = new Date();
                let name  =  moment(d).format('YYYYMMDD-hhmmss');
                let names = getFile.name.split('.');
                let fileExt = names[names.length-1];
                let path = 'ncm-sign/' + this.editItem.id + '-' + name + '.' + names[names.length-1];
                const firebaseApp = getApp();
                let contentType = null;
                if(fileExt.toUpperCase() === 'JPG' || fileExt.toUpperCase() === 'JPEG') {
                    contentType = 'image/jpeg';
                } else if(fileExt.toUpperCase() === 'PNG') {
                    contentType = 'image/png';
                } else if(fileExt.toUpperCase() === 'PDF') {
                    contentType = 'application/pdf';
                }
                const metadata = {
                    contentType: contentType
                };
                const storage = getStorage(firebaseApp);
                let storageRef = ref(storage, path);
                const uploadTask = uploadBytesResumable(storageRef, getFile, metadata);
                let self = this;
                uploadTask.on('state_changed',
                    (snapshot) => {
                        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        console.log('Upload is ' + progress + '% done');
                        uploader.value = progress;
                        switch (snapshot.state) {
                            case 'paused':
                                console.log('Upload is paused');
                                break;
                            case 'running':
                                console.log('Upload is running');
                                break;
                        }
                    },
                    (error) => {
                        // EventBus.$emit("showLoading", false);
                        // A full list of error codes is available at
                        // https://firebase.google.com/docs/storage/web/handle-errors
                        switch (error.code) {
                            case 'storage/unauthorized':
                                // User doesn't have permission to access the object
                                break;
                            case 'storage/canceled':
                                // User canceled the upload
                                break;

                            // ...

                            case 'storage/unknown':
                                // Unknown error occurred, inspect error.serverResponse
                                break;
                        }
                    },
                    () => {
                        // Upload completed successfully, now we can get the download URL
                        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                            console.log('File available at', downloadURL);
                            self.editItem.imageUrl = downloadURL;
                        });
                    }
                );
                // mountainsRef.put(getFile).on('state_changed',
                //     function progress(snapshot){
                //
                //         let percentage = (snapshot.bytesTransferred / snapshot.totalBytes) *100;
                //         console.log("percentage " + percentage);
                //         uploader.value = percentage;
                //     },
                //     function error(err){
                //         console.log(err);
                //     },
                //     function complete(){
                //         console.log('complete upload');
                //         self.editItem.imageUrl = 'https://storage.googleapis.com/ncm-mary-254508.appspot.com/' + path;
                //     }
                // );

            }
        }
    }
</script>

<style scoped>

</style>
