<template>
    <div class="detail-view">

        <div class="col-12 text-right mary-button-container" style="position: sticky; top:0; background-color: white; z-index: 2; box-shadow: 2px 2px 3px gray;">
            <div class="mary-button" style="background-color: black;" v-on:click="onClickCloseBtn()"><span><i class="fas fa-times"></i></span> {{$t('detail.close')}}</div>
            <div class="mary-button" v-show="!isEditMode" v-on:click="isEditMode = true"><span><i class="fas fa-edit"></i></span> {{$t('detail.edit')}}</div>
            <div class="mary-button" v-show="isEditMode" v-on:click="onClickSaveBtn()"><span><i class="fas fa-save"></i></span> {{$t('detail.save')}}</div>
            <div class="mary-button" style="background-color: red; float:left;" v-show="!isAddMode" v-on:click="onClickRemoveBtn()"><span><i class="fas fa-trash"></i></span> {{$t('detail.remove')}}</div>
        </div>

        <div class="head-group" style="padding: 10px;">
            <div class="top"></div>
            <div class="bottom">
                <div class="content-header">
                    <vue-form style="width: 100%;" label-width="25%">
<!--                        <OperatorDropdownInput-->
<!--                            :is-read-only="!isEditMode"-->
<!--                            v-bind:operatorId="detailItem.operatorId"-->
<!--                            v-on:onClick="setOperatorId"/>-->
<!--                        <hr>-->
                        <!--                        <vue-form-item :label="$t('content.parentId')">-->
<!--                            <vue-input-->
<!--                                v-model="detailItem.parentId"-->
<!--                                :disabled="!isEditMode">-->
<!--                            </vue-input>-->
<!--                        </vue-form-item>-->

                        [{{detailItem.id}}]
                        <vue-form-item :label="$t('content.title')">
                            <vue-input
                                v-model="detailItem.title"
                                :disabled="!isEditMode">
                            </vue-input>
                        </vue-form-item>

                        <vue-form-item :label="$t('content.type')" v-if="userRole >= 99">
                            <select class="form-control" v-model="detailItem.type" :disabled="!isEditMode">
                                <option :value="''">Select</option>
                                <option v-for="type in productTypes" :value="type.value">{{type.name}}</option>
                            </select>
                        </vue-form-item>

                        <vue-form-item label="분류"  v-if="userRole >= 99">
                            <select class="form-control" v-model="detailItem.category" :disabled="!isEditMode">
                                <option :value="''">카테고리 선택</option>
                                <option v-for="type in categoryTypes" :value="type.value">{{type.name}}</option>
                            </select>
                        </vue-form-item>

                        <vue-form-item label="Parent Product" v-if="userRole >= 99">
                            <select class="form-control" v-model="detailItem.groupId" :disabled="!isEditMode">
                                <option value="0">Select</option>
                                <option v-for="p in items"  v-if="detailItem.id !== p.id" :value="p.id">{{p.title}}</option>
                            </select>
                        </vue-form-item>

                        <vue-form-item label="Place" v-if="userRole >= 99">
                            <select class="form-control" v-model="detailItem.placeId" :disabled="!isEditMode">
                                <option value="0">Select</option>
                                <option v-for="p in places" :value="p.id">{{p.name}}</option>
                            </select>
                        </vue-form-item>
                        <vue-form-item :label="$t('product.sellingType')" v-if="userRole >= 99">
                            <vue-input
                                v-model="detailItem.sellingType"
                                :disabled="!isEditMode">
                            </vue-input>
                        </vue-form-item>
                        <vue-form-item label="요금명" v-if="userRole >= 99">
                            <vue-input
                                v-model="detailItem.name"
                                :disabled="!isEditMode">
                            </vue-input>
                        </vue-form-item>
                        <vue-form-item :label="$t('product.shortTitle')" v-if="userRole >= 99">
                            <vue-input
                                v-model="detailItem.shortTitle"
                                :disabled="!isEditMode">
                            </vue-input>
                        </vue-form-item>
                        <vue-form-item :label="$t('content.imageUrl')">
                            <vue-input
                                v-model="detailItem.imageUrl"
                                :disabled="!isEditMode">
                            </vue-input>
                        </vue-form-item>
                        <vue-form-item :label="$t('content.imageUrl')">
                            <progress v-if="isEditMode" value="0" max="100" id="uploader"></progress>
                            <input accept="image/*" type="file" ref="file" value="upload" @change="imageSelected" :disabled="!isEditMode">
                            <br/>
                            <img width="512px" :src="detailItem.imageUrl"/>
                            <vue-input
                                v-model="detailItem.imageUrl"
                                :disabled="!isEditMode">
                            </vue-input>
                        </vue-form-item>
                        <vue-form-item label="양도 / 선물" v-if="userRole >= 99">
                            <select class="form-control" v-model="detailItem.transferable" :disabled="!isEditMode">
                                <option :value="false">불가능</option>
                                <option :value="true">가능</option>

                            </select>
                        </vue-form-item>
                        <vue-form-item label="쿠폰개수 (자전거 쿠폰에서만 해당)" v-if="userRole >= 99">
                            <select class="form-control" v-model="detailItem.count" :disabled="!isEditMode">
                                <option v-for="c in 20" :value="c">{{c}}</option>
                            </select>
                        </vue-form-item>
                        <vue-form-item label="사용시간 시간">
                            <vue-input
                                v-model="duration.hours"
                                :disabled="!isEditMode">
                            </vue-input>
                        </vue-form-item>
                        <vue-form-item label="사용시간 분">
                            <vue-input
                                v-model="duration.minutes"
                                :disabled="!isEditMode">
                            </vue-input>
                        </vue-form-item>
                        <vue-form-item label="자전거 쿠폰 방식" v-if="detailItem.type === 'coupon'">
                            <select class="form-control" v-model="detailItem.timeLimited" :disabled="!isEditMode">
                                <option :value="false">일반</option>
                                <option :value="true">시간 (정해진 시간내 무제한)</option>

                            </select>
                        </vue-form-item>
<!--                        <vue-form-item label="사용시간 초">-->
<!--                            <vue-input-->
<!--                                v-model="duration.seconds"-->
<!--                                :disabled="!isEditMode">-->
<!--                            </vue-input>-->
<!--                        </vue-form-item>-->
                        <vue-form-item label="가맹점 Payout" v-if="userRole >= 99">
                            <vue-input
                                v-model="detailItem.payoutAmount"
                                :disabled="!isEditMode">
                            </vue-input>
                        </vue-form-item>
                        <vue-form-item :label="$t('product.rate')">
                            <vue-input
                                v-model="detailItem.rate"
                                :disabled="!isEditMode">
                            </vue-input>
                        </vue-form-item>

                        <vue-form-item label="원가 (표기만)">
                            <vue-input
                                v-model="detailItem.originalPrice"
                                :disabled="!isEditMode">
                            </vue-input>
                        </vue-form-item>

                        <vue-form-item label="환불금액" v-if="userRole >= 99">
                            <vue-input
                                v-model="detailItem.refundable"
                                :disabled="!isEditMode">
                            </vue-input>
                        </vue-form-item>


                        <vue-form-item :label="$t('product.validTo')">
                            <vue-input
                                v-model="detailItem.validTo"
                                :disabled="!isEditMode"
                                type="date">
                            </vue-input>
                        </vue-form-item>
                        <vue-form-item :label="$t('product.validFor') + '(일)'">
                            <vue-input
                                v-model="expirationDate"
                                :disabled="!isEditMode">
                            </vue-input>
                        </vue-form-item>
                        <vue-form-item label="전날 예약 cut off 시간">
                            <vue-input
                                v-model="detailItem.cutOffHour"
                                :disabled="!isEditMode">
                            </vue-input>
                        </vue-form-item>
                        <vue-form-item label="예약전 최소 시간">
                            <vue-input
                                v-model="detailItem.minHours"
                                :disabled="!isEditMode">
                            </vue-input>
                        </vue-form-item>
                        <vue-form-item label="수량 이름 설정">
                            <vue-input
                                v-model="detailItem.priceName"
                                :disabled="!isEditMode">
                            </vue-input>
                        </vue-form-item>
                        <vue-form-item label="요금2 이름" v-if="userRole >= 99">
                            <vue-input
                                v-model="detailItem.priceName2"
                                :disabled="!isEditMode">
                            </vue-input>
                        </vue-form-item>
                        <vue-form-item label="요금3 이름" v-if="userRole >= 99">
                            <vue-input
                                v-model="detailItem.priceName3"
                                :disabled="!isEditMode">
                            </vue-input>
                        </vue-form-item>
                        <vue-form-item label="요금4 이름" v-if="userRole >= 99">
                            <vue-input
                                v-model="detailItem.priceName4"
                                :disabled="!isEditMode">
                            </vue-input>
                        </vue-form-item>
                        <vue-form-item label="요금5 이름" v-if="userRole >= 99">
                            <vue-input
                                v-model="detailItem.priceName5"
                                :disabled="!isEditMode">
                            </vue-input>
                        </vue-form-item>
                        설명
                        <vue-editor
                            v-model="detailItem.content"
                            :disabled="!isEditMode"/>

                        프로그램
                        <vue-editor v-if="userRole >= 99"
                            v-model="detailItem.program"
                            :disabled="!isEditMode"/>

                        규정
                        <vue-editor v-if="userRole >= 99"
                            v-model="detailItem.tnc"
                            :disabled="!isEditMode"/>

                        사용방법
                        <vue-editor v-if="userRole >= 99"
                            v-model="detailItem.manual"
                            :disabled="!isEditMode"/>

                        준비
                        <vue-editor v-if="userRole >= 99"
                            v-model="detailItem.preparation"
                            :disabled="!isEditMode"/>
                        <hr>
                        <hash-tag-input v-if="detailItem.tags != null && userRole >= 99" v-model="detailItem.tags" v-bind:isReadOnly="!isEditMode"/>
                        <div style="width: 100%; display: inline-block; border:1px solid black;" v-if="isEditMode && userRole >= 99">
                            <div style="padding:10px; float: left; margin: 10px; border: 1px solid grey; border-radius: 10px;" v-for="t in serviceTags" v-if="isTagOnList(t) == false" @click="onTagClicked(t)">
                                {{t.name}}
                            </div>
                        </div>
                    </vue-form>
                </div><!-- ./content-header -->
            </div><!-- ./bottom -->
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <h4>예약시간/가격 <button v-if="detailItem.hours == null || detailItem.hours.length == 0" class="btn btn-primary" @click="createDaySchedule">생성하기</button></h4>
                    </div>
                    <div class="col-12" v-if="detailItem != null && detailItem.hours != null && detailItem.hours.length > 0">
                        <table  class="table" >
                            <tr>
                                <th style="min-width: 100px;">요일</th>
                                <th style="min-width: 100px;">영업</th>
                                <th style="min-width: 100px;">시작</th>
                                <th style="min-width: 100px;">종료</th>
                                <th style="max-width: 100px;"><span v-if="detailItem.priceName != null && detailItem.priceName.length > 0">{{detailItem.priceName}}</span><span v-else>요금</span></th>
                                <th style="max-width: 100px;">Payout</th>
                                <th style="max-width: 100px;">slot time (분)</th>
                                <th style="max-width: 100px;">환불금액</th>
                                <th></th>
                            </tr>
                            <tbody v-for="day in detailItem.hours">
                            <tr>
                                <td>{{getDayString(day.dayOfWeek)}}</td>
                                <td><span @click="day.open = !day.open" >{{day.open==true?'영업':'휴업'}}</span></td>
                                <td>
                                    <select v-model="day.openHour">
                                        <option v-for="(h, index) in 24">{{index}}</option>
                                    </select>
                                    <select v-model="day.openMinute">
                                        <option v-for="(m, index) in 4">{{index*15}}</option>
                                    </select>
                                </td>
                                <td>
                                    <select v-model="day.closeHour">
                                        <option v-for="(h, index) in 24">{{index}}</option>
                                    </select>
                                    <select v-model="day.closeMinute">
                                        <option v-for="(m, index) in 4">{{index*15}}</option>
                                    </select>
                                </td>
                                <td style="max-width: 100px;">
                                    <span v-if="detailItem.priceName != null && detailItem.priceName.length > 0">{{detailItem.priceName}}</span>
                                    <input class="form-control" type="number" v-model="day.price"/>
                                    <template v-if="detailItem.priceName2 != null && detailItem.priceName2.length > 0">
                                        <span >{{detailItem.priceName2}}</span>
                                        <input class="form-control" type="number" v-model="day.price2"/>
                                    </template>
                                    <template v-if="detailItem.priceName3 != null && detailItem.priceName3.length > 0">
                                        <span >{{detailItem.priceName3}}</span>
                                        <input class="form-control" type="number" v-model="day.price3"/>
                                    </template>
                                    <template v-if="detailItem.priceName4 != null && detailItem.priceName4.length > 0">
                                        <span >{{detailItem.priceName4}}</span>
                                        <input class="form-control" type="number" v-model="day.price4"/>
                                    </template>
                                    <template v-if="detailItem.priceName5 != null && detailItem.priceName5.length > 0">
                                        <span >{{detailItem.priceName5}}</span>
                                        <input class="form-control" type="number" v-model="day.price5"/>
                                    </template>
                                </td>
                                <td style="max-width: 100px;">
                                    <span v-if="detailItem.priceName != null && detailItem.priceName.length > 0">{{detailItem.priceName}} 정산금액</span>
                                    <input class="form-control" type="number" v-model="day.payout"/>
                                    <template v-if="detailItem.priceName2 != null && detailItem.priceName2.length > 0">
                                        <span >{{detailItem.priceName2}} 정산금액</span>
                                        <input class="form-control" type="number" v-model="day.payout2"/>
                                    </template>
                                    <template v-if="detailItem.priceName3 != null && detailItem.priceName3.length > 0">
                                        <span >{{detailItem.priceName3}} 정산금액</span>
                                        <input class="form-control" type="number" v-model="day.payout3"/>
                                    </template>
                                    <template v-if="detailItem.priceName4 != null && detailItem.priceName4.length > 0">
                                        <span >{{detailItem.priceName4}} 정산금액</span>
                                        <input class="form-control" type="number" v-model="day.payout4"/>
                                    </template>
                                    <template v-if="detailItem.priceName5 != null && detailItem.priceName5.length > 0">
                                        <span >{{detailItem.priceName5}} 정산금액</span>
                                        <input class="form-control" type="number" v-model="day.payout5"/>
                                    </template>
                                </td>
                                <td style="max-width: 100px;">
                                    <input class="form-control" type="number" v-model="day.slotLength"/>
                                </td>
                                <td style="max-width: 100px;">
                                    <input class="form-control" type="number" v-model="day.refundable"/>
                                </td>
                                <td><button class="btn btn-danger" @click="deleteSchedule(day)"><i class="fas fa-trash"></i></button></td>
                            </tr>
                            </tbody>
                        </table>
                        <button class="btn btn-primary" @click="saveDaySchedule"><i class="fas fa-save"></i>저장</button>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <h4>환불 규정 <button class="btn btn-primary" @click="createRefundItem">추가하기</button></h4>
                    </div>
                    <div class="col-12">
                        <table class="table">
                            <tbody v-for="(r, ri) in detailItem.refundPolicy">
                            <tr>
                                <td>
                                    <select v-model="r.refundDayBefore" @change="saveRefundItem(ri)">
                                        <option v-for="(i, ii) in 30" :value="ii">{{ii}}</option>
                                    </select>
                                    일
                                </td>
                                <td>
                                    <select v-model="r.refundHoursBefore" @change="saveRefundItem(ri)">
                                        <option v-for="(i, ii) in 25" :value="ii">{{ii}}</option>
                                    </select>
                                    시간
                                </td>
                                <td>
                                    <select v-model="r.refundable" @change="saveRefundItem(ri)">
                                        <option v-for="(i, ii) in 101" :value="ii">{{ii}}</option>
                                    </select>
                                    %
                                </td>
                                <td>
                                    <button class="btn btn-success" @click="saveRefundItem(ri)"><i class="fas fa-save"></i></button>
                                    <button class="btn btn-danger" @click="removeRefundItem(ri)"><i class="fas fa-trash"></i></button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div><!-- ./head-group -->
    </div>
</template>

<script>
  import {millisToReadable, millisToDate} from "../../../utils/TimeConverter";

  import {deleteProduct, writeProduct, writeProuct} from "../../../api/product";
  import OperatorDropdownInput from "../../../inputs/OperatorDropdownInput";
  import {setTagsByOperatorId} from "../../../api/operator";
  import HashTagInput from "../../../inputs/HashTagInput";
  import DropdownInput from "../../../inputs/DropdownInput";
  import { VueEditor } from "vue2-editor";
  import {
      deleteHour,
      deleteSchedule,
      getDayString,
      getHours,
      newDateHour,
      updateHour,
      updateHours
  } from "../../../api/hours";

  import moment from 'moment';
  import * as firebase from "firebase/app";

  // Add the Firebase services that you want to use
  import {getApp} from "firebase/app";
  import {getDownloadURL, getStorage, ref, uploadBytesResumable} from "firebase/storage";
  import {getTagList} from "../../../api/tag";
  import {getPlaceTypes} from "../../../api/place";
  import {showDialog} from "../../../api/common";
  import {getRoleId} from "@/store";
  export default {
      name: 'EditorOperator',
        components: {
            DropdownInput,
            HashTagInput,
            OperatorDropdownInput,
            VueEditor,
        },
      props: {
          detailItem: {
              type: Object,
              default: null
          },
          isAddMode: {
              type: Boolean,
              default: false
          },
          items: {
              type: Array,
              default: function() {
                  return [];
              }
          },
          places: {
              type: Array,
              default: function() {
                  return [];
              }
          },
          place: {
              type: Object,
              default: null
          },
          onClosed: {
              type: Function,
              default: null,
          },
          onUpdated: {
              type: Function,
              default: null,
          }

      },
      data(){
          return{
              modelKey: 'product',
              productTypes: [
                  {name:'자전거 기본요금', value:'bike_base'},
                  {name:'자전거 정상요금', value:'bike_normal'},
                  {name:'자전거 주차요금', value:'bike_park'},
                  {name:'자전거 보험료', value:'bike_insurance'},
                  {name:'쿠폰상품', value:'coupon'},
                  {name:'플레이스 쿠폰상품', value:'place_coupon'},
                  {name:'플레이스 판매상품', value:'place_product'},
                  {name:'플레이스 예약상품', value:'place_booking_product'},
              ],

              duration: {
                  hours: 0,
                  minutes: 0,
                  seconds: 0
              },

              expirationDate: 0,

              isEditMode: this.isAddMode,
              serviceTags:[],
              categoryTypes: getPlaceTypes(),

              userRole:0,
          }
      },
      created() {
          this.updateDuration();
          this.getDaySchedule();
          this.getTagList();
      },
      mounted() {
          this.$data.userRole = getRoleId();
      },
      watch:{
          detailItem(){
              this.isEditMode = this.isAddMode;

              this.updateDuration();
              this.getDaySchedule();
              this.getTagList();
          }
      },
      methods: {
          getDayString: getDayString,
          updateDuration: function(){
              this.duration.seconds = Math.floor(this.detailItem.duration / 1000 % 60);
              this.duration.minutes = Math.floor(this.detailItem.duration / (1000 * 60) % 60);
              this.duration.hours = Math.floor(this.detailItem.duration / (1000 * 60 * 60));

              this.detailItem.validTo = millisToDate(this.detailItem.validTo, '-');
              this.expirationDate = this.detailItem.validFor / (1000 * 60 * 60 * 24);
          },
          // setOperatorId: function(id) {
          //     setTagsByOperatorId(id, this.detailItem);
          // },
          getTagList: function() {
            let self = this;
            console.log("get tag list");
            getTagList('service')
              .then(function(data) {
                  console.log(data);
                  if(data.success == true) {
                      self.$data.serviceTags = data.list;

                  }
              })
          },
          onClickSaveBtn: async function() {
              if(this.detailItem.type === '') {
                  this.$modal.show('dialog', {
                      title: '',
                      text: '상품 타입을 선택해주세요'
                  })
                  return;
              }

              console.log("exp " + this.expirationDate + " " + this.detailItem.type);
              if(this.detailItem.type === 'coupon' && parseInt(this.expirationDate) === 0) {
                  this.$modal.show('dialog', {
                      title: '',
                      text: '유효기간을 입력해주세요'
                  })
                  return;
              }

              this.detailItem.duration = 0;

              this.detailItem.duration += this.duration.hours * 60 * 60;
              this.detailItem.duration += this.duration.minutes * 60;
              this.detailItem.duration += this.duration.seconds;

              if(this.detailItem.type === 'coupon' && this.detailItem.duration === 0) {
                  this.$modal.show('dialog', {
                      title: '',
                      text: '사용시간을 입력해주세요'
                  })
                  return;
              }
              this.detailItem.duration *= 1000;

              if(this.detailItem.validTo === '0000-00-00') {
                  this.detailItem.validTo = 0;
              } else {
                  let date = new Date(this.detailItem.validTo);
                  this.detailItem.validTo = date.getTime();
              }
              this.detailItem.validFor = this.expirationDate * (1000 * 60 * 60 * 24);
              //this.detailItem.duration = this.detailItem.duration*60*1000;

              let that = this;
              console.log("요금 저장");
              console.log(this.detailItem);
              await writeProduct(this.detailItem).then(function (product) {

                  if(that.onUpdated !== null) {
                      that.onUpdated(product);
                      return;
                  }
                  alert("저장 되었습니다.")
                  // let path = `/admin/${that.modelKey}?id=${product.id}`;


                  // that.$router.push(path)
                  that.isEditMode = false;

                  that.$emit("updateList")
              });
          },
          onClickCloseBtn: function() {

              console.log("close button clicked");
              if(this.onClosed !== null) {
                  this.onClosed();
                  return;
              }
              console.log("onClosed is null");
              if(!this.isEditMode)
                this.$emit('onClose', null);

              this.isEditMode = false;
          },
          onClickRemoveBtn: function() {
              let self = this;
              this.$modal.show('dialog', {
                  title: '',
                  text: "상품 " + this.detailItem.title + '를 삭제 하시겠습니까?',
                  buttons:[
                      {
                          title: '아니요',
                          handler:() => {
                              self.$modal.hide('dialog');
                          }
                      },
                      {
                          title:'삭제',
                          handler:()=>{
                              self.$modal.hide('dialog');
                              self.onClickRemoveBtnConfirmed();
                          }
                      }
                  ]
              })
          },
          onClickRemoveBtnConfirmed: function () {
              let that = this;

              deleteProduct(that.detailItem.id).then(function (post) {
                  alert("삭제 되었습니다.");

                  that.$emit("updateList")

                  that.$emit('onClose', null);
                  // let path = `/admin/${that.modelKey}`;
                  //
                  // that.$router.push(path)
              });
          },
          millisToReadable: millisToReadable,
          createRefundItem: function() {
              let newItem = newDateHour();
              newItem.type = 'refund_policy';
              newItem.productId = this.detailItem.id;
              newItem.placeId = this.detailItem.placeId;
              this.detailItem.refundPolicy.push(newItem);
          },
          saveRefundItem: function(index) {
              let self = this;
              updateHour(this.detailItem.refundPolicy[index])
              .then(function(data) {
                  if(data.success == true) {
                      self.detailItem.refundPolicy.splice(index, 1, data.item);
                  } else {
                      showDialog(data.message);
                  }
              })
              .catch(function(err) {

              })
          },
          removeRefundItem: function(index) {
              if(this.detailItem.refundPolicy[index].id == 0) {
                  this.detailItem.refundPolicy.splice(index, 0);
                  return;
              }
              let self = this;
              deleteHour(this.detailItem.refundPolicy[index].id, this.detailItem.placeId)
              .then(function(data) {
                  if(data.success == true) {
                      self.detailItem.refundPolicy.splice(index, 0);
                  } else {
                      showDialog(data.message);
                  }
              })
          },
          createDaySchedule: function() {
              console.log("createDaySchedule");
              console.log(this.detailItem);
              if(this.detailItem.hours != null && this.detailItem.hours.length > 0) {
                  return;
              }

              this.detailItem.hours = [];
              for(let i = 0 ; i < 7 ; i++) {
                  let day = newDateHour();
                  day.dayOfWeek = i;
                  day.productId = this.detailItem.id;
                  day.type = 'schedule';
                  this.detailItem.hours.push(day);
              }
              let hday = newDateHour();
              hday.dayOfWeek = 100;
              hday.productId = this.detailItem.id;
              hday.type = 'schedule';
              this.detailItem.hours.push(hday);
              console.log(this.detailItem.hours);
          },
          getDaySchedule: function() {
              let query = "productId=" + this.detailItem.id + '&placeId=' + this.detailItem.placeId;
              let self = this;
              getHours(query)
                  .then(function(data) {
                      if(data.success == true) {
                          self.detailItem.hours = data.item;
                          for(let i = 0 ; i < self.detailItem.hours.length ; i++) {
                              if(self.detailItem.hours[i].dayOfWeek == 100) {
                                  return;
                              }
                          }
                          let hday = newDateHour();
                          hday.dayOfWeek = 100;
                          hday.productId = self.detailItem.id;
                          hday.type = 'schedule';
                          self.detailItem.hours.push(hday);
                      } else {
                          console.log(data.message);
                      }
                  })
                  .catch(function(err) {

                  })
          },
          deleteSchedule: function(hour) {
              if(hour.id == 0) {
                  for(let i = 0 ; i < this.detailItem.hours.length ; i++) {
                      if(this.detailItem.hours[i] == hour) {
                          this.detailItem.hours.splice(i, 1);
                          return;
                      }
                  }
              }

              let self = this;
              deleteSchedule(hour.id)
              .then(function(data) {
                  if(data.success == true) {
                      for(let i = 0 ; i < self.detailItem.hours.length ; i++) {
                          if(self.detailItem.hours[i].id == hour.id) {
                              self.detailItem.hours.splice(i, 1);
                              return;
                          }
                      }
                  } else {
                      showDialog("실페", data.message);
                  }
              })
              .catch(function(err) {
                  showDialog("에러", err);
              })
          },
          saveDaySchedule: function() {
              if(this.detailItem.hours == null && this.detailItem.hours.length == 0) {
                  return;
              }

              let days = JSON.parse(JSON.stringify(this.detailItem.hours));
              let self = this;
              updateHours(days)
                  .then(function(data) {
                      if(data.success == true) {
                          self.$data.detailItem.hours = data.item;
                      } else {
                          console.log(data.message);
                      }
                  })
                  .catch(function(err) {

                  })
          },
          isTagOnList: function(tag) {
              for(let i = 0 ; i < this.detailItem.tags.length ; i++) {
                  if(this.detailItem.tags[i].id == tag.id) {
                      return true;
                  }
              }
              return false;
          },
          onTagClicked: function(tag) {
              for(let i = 0 ; i < this.detailItem.tags.length ; i++) {
                  if(this.detailItem.tags[i].id == tag.id) {
                      return;
                  }
              }
              this.detailItem.tags.push(JSON.parse(JSON.stringify(tag)));
          },
          imageSelected:function(e){
              // e.preventDefault();
              const uploader = document.getElementById('uploader');
              //get file
              console.log("File Btn Added");
              console.log(e);
              let getFile = e.target.files[0];
              console.log("File name - " + getFile.name);
              //set storage ref
              let d = new Date();
              let name  =  moment(d).format('YYYYMMDD-hhmmss');
              let names = getFile.name.split('.');
              let fileExt = names[names.length-1];
              let path = 'ncm-product/'  + this.detailItem.id + '-' + name + '.' + names[names.length-1];
              const firebaseApp = getApp();
              let contentType = null;
              if(fileExt.toUpperCase() === 'JPG' || fileExt.toUpperCase() === 'JPEG') {
                  contentType = 'image/jpeg';
              } else if(fileExt.toUpperCase() === 'PNG') {
                  contentType = 'image/png';
              } else if(fileExt.toUpperCase() === 'PDF') {
                  contentType = 'application/pdf';
              }
              const metadata = {
                  contentType: contentType
              };
              const storage = getStorage(firebaseApp);
              let storageRef = ref(storage, path);
              const uploadTask = uploadBytesResumable(storageRef, getFile, metadata);
              let self = this;
              uploadTask.on('state_changed',
                  (snapshot) => {
                      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                      console.log('Upload is ' + progress + '% done');
                      uploader.value = progress;
                      switch (snapshot.state) {
                          case 'paused':
                              console.log('Upload is paused');
                              break;
                          case 'running':
                              console.log('Upload is running');
                              break;
                      }
                  },
                  (error) => {
                      // EventBus.$emit("showLoading", false);
                      // A full list of error codes is available at
                      // https://firebase.google.com/docs/storage/web/handle-errors
                      switch (error.code) {
                          case 'storage/unauthorized':
                              // User doesn't have permission to access the object
                              break;
                          case 'storage/canceled':
                              // User canceled the upload
                              break;

                          // ...

                          case 'storage/unknown':
                              // Unknown error occurred, inspect error.serverResponse
                              break;
                      }
                  },
                  () => {
                      // Upload completed successfully, now we can get the download URL
                      getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                          console.log('File available at', downloadURL);
                          self.detailItem.imageUrl = downloadURL;
                      });
                  }
              );
              // mountainsRef.put(getFile).on('state_changed',
              //     function progress(snapshot){
              //
              //         let percentage = (snapshot.bytesTransferred / snapshot.totalBytes) *100;
              //         console.log("percentage " + percentage);
              //         uploader.value = percentage;
              //     },
              //     function error(err){
              //         console.log(err);
              //     },
              //     function complete(){
              //         console.log('complete upload');
              //         self.detailItem.imageUrl = 'https://storage.googleapis.com/ncm-mary-254508.appspot.com/' + path;
              //     }
              // );

          }
    }
  };
</script>
