<template>

    <div style="width: 100%; height: 100%; overflow-y: auto;">
        <div class="container">
            <div class="row">
                <div class="col-6 text-left">
                    <h3>{{item.title}}</h3>
                </div>
                <div class="col-6 text-right">
                    <h3><span @click="closeView">Close</span></h3>
                </div>
            </div>
            <div class="col-12">
                <vue-good-table
                    mode="remote"
                    :columns="accessLogColumns"
                    :rows="logs"
                    :total-rows="totalRows"
                    @on-page-change="onPageChange"
                    @on-sort-change="onSortChange"
                    @on-per-page-change="onPerPageChange"
                    :pagination-options="paginationOption"
                    :sort-options="{enabled: true}"/>
            </div>
        </div>
    </div>
</template>

<script>
import {getPlaceAssetAccessLogs} from "../api/place";
import {millisToReadable} from "../utils/TimeConverter";

export default {
    name: "PlaceAssetLogView",
    props:{
        item:{
            type:Object,
            default:0,
        },
        onClose: {
            type:Function,
            default: function() {return null;}
        }
    },
    data() {
        return {
            pagination: {
                keyword: null,
                page: 0,
                size: 100,
                sort: "id,desc"
            },
            paginationOption: {
                enabled: true,
                perPage: 100,
                perPageDropdown: [50, 100, 250, 500],
                dropdownAllowAll: false
            },
            accessLogColumns: [
                {field: 'createdWhen', label: '시간'},
                {field: 'title', label:'제목'},
                {field: 'content', label:'세부내용'}
            ],
            logs:[],
            totalRows: 0,
        }
    },
    created() {
    },
    mounted() {
        this.updateList();
    },
    methods: {
        onPageChange(params) {
            this.pagination.page = params.currentPage - 1;

            this.updateList();
        },

        onPerPageChange(params) {
            this.pagination.page = 0;
            this.pagination.size = params.currentPerPage;

            this.updateList();
        },
        onSortChange(params) {
            this.pagination.page = 0;
            this.pagination.sort = `${params[0].field},${params[0].type}`;

            this.updateList();
        },
        updateList: function() {

            let self = this;
            getPlaceAssetAccessLogs(this.item.placeId, this.item.id, this.pagination)
            .then(function(data) {
                if(data.success === true) {
                    for(let i = 0 ; i < data.list.length ; i++) {
                        data.list[i].createdWhen = millisToReadable(data.list[i].createdWhen);
                    }
                    self.$data.logs = data.list;
                }
            })
            .catch(function(err) {
                console.log(err);
                if(err.response !== undefined) {
                    console.log(err.response);
                }
            })
        },

        closeView: function() {
            if(this.onClose !== null) {
                this.onClose();
            }
        }
    }
}
</script>

<style scoped>

</style>
