<template>
    <div class="detail-view" :ref="'place-'+item.id">

        <div class="col-12 text-right mary-button-container" style="position: sticky; top:0; background-color: white; z-index: 2; box-shadow: 2px 2px 3px gray;">
            <div class="mary-button" style="background-color: black;" v-on:click="onClickCloseBtn()"><span><i class="fas fa-times"></i></span> {{$t('detail.close')}}</div>
            <div class="mary-button" v-show="!isEditMode" v-on:click="onClickEditBtn()"><span><i class="fas fa-edit"></i></span> {{$t('detail.edit')}}</div>
            <div class="mary-button" v-show="isEditMode" v-on:click="onClickSaveBtn()"><span><i class="fas fa-save"></i></span> {{$t('detail.save')}}</div>
            <div class="mary-button" style="background-color: red; float:left;" v-show="!isAddMode" v-on:click="onClickRemoveBtn()"><span><i class="fas fa-trash"></i></span> {{$t('detail.remove')}}</div>
        </div>

        <div class="head-group">
            <div class="top"></div>
            <div class="bottom">
                <div class="content-header">
                    <vue-form style="width: 100%;" label-width="25%">

                        <!-- 사업자 구분 -->
<!--                        <AccessDropdownInput-->
<!--                            :is-read-only="!isEditMode"-->
<!--                            v-bind:operatorId="item.operatorId"-->
<!--                            v-on:onClick="setOperatorId"/>-->

                        <hr>

<!--                        <vue-form-item :label="$t('content.parentId')">-->
<!--                            <vue-input-->
<!--                                type="number"-->
<!--                                v-model="item.parentId"-->
<!--                                :disabled="!isEditMode">-->
<!--                            </vue-input>-->
<!--                        </vue-form-item>-->
<!--                        <vue-form-item :label="$t('content.userId')">-->
<!--                            <vue-input-->
<!--                                v-model="item.userId"-->
<!--                                :disabled="!isEditMode">-->
<!--                            </vue-input>-->
<!--                        </vue-form-item>-->

                        <h4>가맹점 정보</h4>

                        <div class="image-preview-list" v-if="item.imageUrls != undefined">
                            <div class="image-preview" v-for="(image, index) in item.imageUrls" :key="index">
                                <div class="image-remove" v-on:click="onClickRemoveImage(index)" v-if="isEditMode">
                                    <div class="icon-holder">
                                        <svg width="2em" height="2em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" d="M11.854 4.146a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708-.708l7-7a.5.5 0 0 1 .708 0z"/>
                                            <path fill-rule="evenodd" d="M4.146 4.146a.5.5 0 0 0 0 .708l7 7a.5.5 0 0 0 .708-.708l-7-7a.5.5 0 0 0-.708 0z"/>
                                        </svg>
                                    </div>
                                    <div class="icon-bg"></div>
                                </div>
                                <div class="image-holder" v-bind:style="{ backgroundImage: 'url('+image+')' }"></div>
                            </div>
                        </div>
                        <progress v-if="isEditMode" value="0" max="100" id="uploader"></progress>
                        <input accept="image/*" type="file" ref="file" value="upload" @change="fileBtn" :disabled="!isEditMode">

                        <vue-form-item :label="$t('content.name')">
                            <vue-input
                                v-model="item.name"
                                :disabled="!isEditMode">
                            </vue-input>
                        </vue-form-item>

                        <vue-form-item :label="'가맹점 종류'">
                            <select class="form-control" v-model="item.placeClass" :disabled="!isEditMode">
                                <option value="">종류를 선택하세요</option>
                                <option v-for="(type, index) in placeClasses"
                                        :key="index"
                                        :value="type.value">{{type.name}}</option>
                            </select>
                        </vue-form-item>
                        <vue-form-item :label="$t('place.type')">
                            <select class="form-control" v-model="item.type" :disabled="!isEditMode">
                                <option v-for="(type, index) in types"
                                        :key="index"
                                        :value="type.value">{{type.name}}</option>
                            </select>
                        </vue-form-item>
                        <vue-form-item label="수수료 (%)">
                            <vue-input
                                v-model="item.commissionRateFloat"
                                :type="'number'"
                                :disabled="!isEditMode">
                            </vue-input>
                        </vue-form-item>
                        <vue-form-item label="QRCode">
                            <vue-input
                                v-model="item.qrCode"
                                :disabled="!isEditMode">
                            </vue-input>

                        </vue-form-item>
                        <vue-form-item label="">
                            <qr-canvas :options="{cellSize:5, data:item.qrCode}"></qr-canvas>
                        </vue-form-item>
                        <vue-form-item label="주소">
                            <vue-input
                                v-model="item.address"
                                :disabled="!isEditMode">
                            </vue-input>
                        </vue-form-item>

                        <vue-form-item label="전화번호">
                            <vue-input
                                v-model="item.contact"
                                :disabled="!isEditMode">
                            </vue-input>
                        </vue-form-item>

                        <vue-form-item label="사업자명">
                            <vue-input
                                v-model="item.companyName"
                                :disabled="!isEditMode">
                            </vue-input>
                        </vue-form-item>

                        <vue-form-item label="사업자 번호">
                            <vue-input
                                v-model="item.companyNumber"
                                :disabled="!isEditMode">
                            </vue-input>
                        </vue-form-item>
                        <vue-form-item label="통신판매업 등록번호">
                            <vue-input
                                v-model="item.onlineRegNumber"
                                :disabled="!isEditMode">
                            </vue-input>
                        </vue-form-item>
                        <vue-form-item label="사업자구분">
                            <select class="form-control" v-model="item.companyType" :disabled="!isEditMode">
                                <option value="">선택하세요</option>
                                <option value="S">간이사업자</option>
                                <option value="G">일반사업자</option>
                            </select>
                        </vue-form-item>
                        <vue-form-item label="대표자">
                            <vue-input
                                v-model="item.representative"
                                :disabled="!isEditMode">
                            </vue-input>
                        </vue-form-item>
                        <vue-form-item label="이메일">
                            <vue-input
                                v-model="item.email"
                                :disabled="!isEditMode">
                            </vue-input>
                        </vue-form-item>
                        <vue-form-item label="거래은행">
                            <vue-input
                                v-model="item.bankName"
                                :disabled="!isEditMode">
                            </vue-input>
                        </vue-form-item>
                        <vue-form-item label="계좌번호">
                            <vue-input
                                v-model="item.accountNumber"
                                :disabled="!isEditMode">
                            </vue-input>
                        </vue-form-item>
                        <vue-form-item label="구독요금">
                            <vue-input
                                v-model="item.subscriptionFee"
                                :disabled="!isEditMode">
                            </vue-input>
                        </vue-form-item>
                        <vue-form-item label="맵 엠베드">
                            <vue-input
                                v-model="item.mapEmbed"
                                :disabled="!isEditMode">
                            </vue-input>
                        </vue-form-item>


                        <vue-form-item :label="$t('asset.lat')">
                            <vue-input
                                v-model="item.lat"
                                :type="'number'"
                                :disabled="!isEditMode">
                            </vue-input>
                        </vue-form-item>
                        <vue-form-item :label="$t('asset.lon')">
                            <vue-input
                                v-model="item.lon"
                                :type="'number'"
                                :disabled="!isEditMode">
                            </vue-input>
                        </vue-form-item>

                        <vue-form-item label="사이트">
                            <vue-input
                                v-model="item.siteAddress"
                                :disabled="!isEditMode">
                            </vue-input>
                        </vue-form-item>

                        <vue-form-item label="영업시간">
                            <vue-input
                                v-model="item.openingTime"
                                :disabled="!isEditMode">
                            </vue-input>
                        </vue-form-item>

                        <!-- <vue-form-item label="QR Code">
                            <vue-input
                                v-model="item.qrCode"
                                :disabled="!isEditMode">
                            </vue-input>
                        </vue-form-item> -->

                        <!--  -->
                        <hr/>
                        <strong>카드뷰 설정</strong>
                        <div style="width: 400px; height: 200px; background-size: cover; background-position: center; background-repeat: no-repeat; border: 1px solid black; position: relative;"
                             v-bind:style="{backgroundImage: 'url(' + item.cardBackground +')'}">
                            <div class="place-type-icon" style="position: absolute; top: 10px; left: 10px; width:35px; height:35px; background-color: transparent;"
                                 v-bind:class="'place-type-' + item.type"/>
                            <div style="width: 100%; position: absolute; bottom: 0; left: 0;">
                                <div style="padding-top:0; width: 100%; text-align: left; color: white; font-weight: bold; font-size: 30px;-webkit-text-stroke: 1px black;">{{item.cardTitle}}</div>
                                <div style="padding-top:0; width: 100%; text-align: left; color: white; font-weight: bold; font-size: 20px;-webkit-text-stroke: 1px black;">{{item.cardSubtitle}}</div>
                            </div>
                            <div style="position: absolute; top: 5px; right:5px;-webkit-text-stroke: 1px black; font-size: 18px; font-weight: bold; color:white;" >
                                <span v-if="item.cardLocation !== null">{{item.cardLocation}}</span>
                                <span v-if="item.cardRate !== null">{{item.cardRate}}</span>
                            </div>
                            <div v-if="item.hotDeal === true" style="position: absolute; bottom: 10px; right: 5px; padding-left:10px; padding-right:10px; height: 38px; line-height: 38px; border-radius: 19px; background-color: red; color: white; font-weight: bold;">
                                핫딜
                            </div>
                        </div>
                        <progress v-if="isEditMode" value="0" max="100" id="background-uploader"></progress>
                        <input accept="image/*" type="file" ref="file" value="upload" @change="bgImagePickedUp" :disabled="!isEditMode">

                        <input type="text" style="margin-top:10px;" class="form-control" placeholder="플레이스 카드 제목" v-model="item.cardTitle" :disabled="!isEditMode"/>
                        <input type="text" style="margin-top:10px;" class="form-control" placeholder="플레이스 카드 부제목" v-model="item.cardSubtitle" :disabled="!isEditMode"/>
                        <input type="text" style="margin-top:10px;" class="form-control" placeholder="플레이스 카드 할인내용" v-model="item.cardRate" :disabled="!isEditMode"/>
                        <input type="text" style="margin-top:10px;" class="form-control" placeholder="플레이스 카드 지역" v-model="item.cardLocation" :disabled="!isEditMode"/>
                        <div style="margin-top:10px; height: 38px; line-height: 38px; display: inline-block; ">
                            <div style="float:left; cursor:pointer; padding-left:10px; padding-right: 10px; height: 38px; line-height: 38px; border: 1px solid #ced4da;border-radius: .25rem;"
                                class="hot-deal-btn" v-bind:class="{'selected': item.hotDeal === true}" v-on:click="item.hotDeal = !item.hotDeal">
                                핫딜
                            </div>
                        </div>
                        <hr>

                        <hr/>
                        <strong>플레이스 페이지 구성</strong>

                        <br>
                        설명 이미지<br>
                        <div style="width: 100%; height: 300px; overflow-y: auto; display: flex;" v-if="item.images !== null && item.images.length > 0">
                            <div style="width: 300px; position: relative;" v-for="(image, ii) in item.images" >
                                <img style="width: 300px;" :src="image"/>
                                <div style="position: absolute; top: 20px; right: 20px;"><span style="color:red;" v-on:click="onClickRemoveDescriptionImage(ii)"><i class="fas fa-trash"></i></span></div>
                            </div>
                        </div>
                        <progress v-if="isEditMode" value="0" max="100" id="img-uploader"></progress>
                        <input accept="image/*" type="file" ref="descriptionfile" value="upload" @change="onImagePickedUp" :disabled="!isEditMode">

                        <br/>
                        설명
                        <vue-editor
                            v-model="item.description"
                            :disabled="!isEditMode"/>


                        Youtube 링크, 영상 아이디만 복사해서 붙여넣기 하세요. 예는 아래와 같이 URL에서 빨간색 부분만 복사해서 넣어주세요.
                        https://www.youtube.com/watch?v=<span style="color:red;">EC0TX2AxC4U</span>
                        <input type="text" style="margin-top:10px; " class="form-control" placeholder="유투브 링크" v-model="item.videoLink" :disabled="!isEditMode"  @change="correctYouTubeLink"/>
                        <div v-if="item.videoLink != null && item.videoLink.length > 0" class="youtube-container" style="width: 500px;">
                            <iframe :src="'https://www.youtube.com/embed/' + item.videoLink"  frameborder="0" style="width: 100%; height: 300px;" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                        <!-- 리뷰 입력 -->
                        <button v-if="isEditMode" type="button" class="btn btn-primary" v-on:click="onClickAddReview()">리뷰추가</button>
                        <div class="review-list">
                            <div class="review"
                                 v-for="(review, index) in item.reviews"
                                 :key="index">

                                <vue-form-item label="작성일">
                                    <vue-input
                                        v-model="review.createdWhen"
                                        :type="'date'"
                                        :disabled="!isEditMode">
                                    </vue-input>
                                </vue-form-item>

                                <vue-form-item label="유저이름">
                                    <vue-input
                                        v-model="review.userNickname"
                                        :disabled="!isEditMode">
                                    </vue-input>
                                </vue-form-item>

                                <!-- v-validate="'required|min:0|max:5'" -->
                                <!-- :data-vv-rules="'required|max:5|min:0'" -->
                                <vue-form-item label="평점 (0~5)">
                                    <!-- <vue-input
                                        v-model="review.rating"
                                        :type="'number'"
                                        v-validate="'required|min:0|max:5'"
                                        :disabled="!isEditMode">
                                    </vue-input> -->
                                    <div class="vue-input">
                                        <input type="number" class="vue-input__inner"
                                            min="0"
                                            max="5"
                                            v-model="review.rating"
                                            :disabled="!isEditMode">
                                    </div>
                                </vue-form-item>

                                <vue-form-item label="내용">
                                    <vue-input
                                        v-model="review.content"
                                        :disabled="!isEditMode">
                                    </vue-input>
                                </vue-form-item>

                                <button v-if="isEditMode" type="button" class="btn btn-danger" v-on:click="onClickRemoveReview(review)">리뷰 삭제</button>

                            </div>
                        </div>


                        <!--
                        <h4>가맹점 페이지 내용</h4><br>

                        <label>{{$t('content.title')}}</label>
                        <vue-input
                            v-model="item.title"
                            :disabled="!isEditMode">
                        </vue-input><br><br>

                        <label>대표 이미지 URL</label>
                        <progress v-if="isEditMode" value="0" max="100" id="uploader"></progress>
                        <input accept="image/*" type="file" ref="file" value="upload" @change="fileBtn" :disabled="!isEditMode">
                        <img width="100%" :src="item.imageUrl"/>
                        <vue-input
                            v-model="item.imageUrl"
                            :disabled="!isEditMode">
                        </vue-input><br><br>

                        <vue-editor
                            v-model="item.content"
                            :disabled="!isEditMode"
                            placeholder="저희 가맹점을 소개합니다."/>

                        <hr>


                        -->
                        <hash-tag-input v-model="item.tags" v-bind:isReadOnly="!isEditMode"/>
                        <div style="width: 100%; display: inline-block; border:1px solid black;" v-if="isEditMode">
                            <div style="padding:10px; float: left; margin: 10px; border: 1px solid grey; border-radius: 10px;" v-for="t in serviceTags" v-if="isTagOnList(t) == false" @click="onTagClicked(t)">
                                {{t.name}}
                            </div>
                        </div>
                    </vue-form>
                    <div v-if="item.hours == null || item.hours.length == 0"><span v-on:click="createDaySchedule"><i class="fas fa-plus"></i>영업시간 설정하기</span></div>
                    <template v-else>
                    <table  class="table">
                        <tbody v-for="day in item.hours">
                            <tr>
                                <td>{{getDayString(day.dayOfWeek)}}</td>
                                <td><span @click="day.open = !day.open" >{{day.open==true?'영업':'휴업'}}</span></td>
                                <td>
                                    <select v-model="day.openHour">
                                        <option v-for="(h, index) in 24">{{index}}</option>
                                    </select>
                                    <select v-model="day.openMinute">
                                        <option v-for="(m, index) in 4">{{index*15}}</option>
                                    </select>
                                </td>
                                <td>
                                    <select v-model="day.closeHour">
                                        <option v-for="(h, index) in 24">{{index}}</option>
                                    </select>
                                    <select v-model="day.closeMinute">
                                        <option v-for="(m, index) in 4">{{index*15}}</option>
                                    </select>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                        <button class="btn btn-primary" @click="saveDaySchedule"><i class="fas fa-save"></i>영업시간 저장</button>
                    </template>

                    <hr/>
                    <template v-if="item.products != null && item.products.length > 0">

                        <div class="container">
                            <div class="row">
                                <div class="col-12">
                                    <h6>상품 리스트</h6>
                                </div>
                                <div class="col-12" v-for="p in item.products">
                                    <product-item v-bind:item="p"
                                          v-on:onClick="openProduct"
                                          v-bind:class="{'is-selected': editProductItem != false && p.id === editProductItem.id}"/>
                                    <button class="btn btn-success" @click="openNewChildProduct(p)">Add Child Product</button>
                                </div>
                            </div>
                        </div>
                    </template>
                    <div class="container">
                        <div class="row">
                            <div class="col-12" style="margin-top:20px;">
                                <button class="btn btn-primary" @click="openNewProduct"><i class="fas fa-plus"></i>상품등록</button>
                            </div>
                        </div>
                    </div>

                </div><!-- ./content-header -->

            </div><!-- ./bottom -->

        </div><!-- ./head-group -->
        <div class="container" style="margin-top:30px;">
            <div class="row">
                <div class="col-12">
                    <h6>관리자 리스트</h6>
                </div>
            </div>
            <div class="row">
                <div class="col-8">
                    <input type="text" class="form-control" id="place-user-search-input" placeholder="추가할 사용자 이메일 검색"/>
                </div>
                <div class="col-4">
                    <button class="btn btn-success" v-on:click="searchUser"><i class="fas fa-search"></i></button>
                </div>
            </div>
            <div class="row" v-if="searchUserResults != null && searchUserResults.length > 0">
                <div class="col-12">
                    <ul class="list-group">
                        <li v-for="u in searchUserResults" class="list-group-item">
                            {{u.email}} {{u.name}}
                            <button class="btn btn-primary" style="float:right;" v-on:click="addPlaceUser(u, 97)">Manager</button>
                            <button class="btn btn-primary" style="float:right; margin-right: 20px;" v-on:click="addPlaceUser(u, 96)">Staff</button>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row" v-if="userLinks != null && userLinks.length > 0">
                <div class="col-12">
                    <table class="table">
                        <tr>
                            <th>이메일</th>
                            <th>이름</th>
                            <th>권한</th>
                            <td></td>
                        </tr>
                        <tr v-for="u in userLinks">
                            <td>{{u.user.email}}</td>
                            <td>{{u.user.name}}</td>
                            <td>
                                <select class="form-control" v-model="u.role" @change="updatePlaceUserLink(u)">
                                    <option value="96">STAFF</option>
                                    <option value="97">관리자</option>
                                </select>

                            </td>
                            <td><button class="btn btn-danger" @click="unlinkPlaceUser(u)"><i class="fas fa-trash"></i></button></td>

                        </tr>
                    </table>
                </div>
            </div>

        </div>
        <div style="position: fixed; z-index: 3; top:0; left: 0; width: 100%; height: 100%; background-color: rgba(0,0,0,0.5);" v-if="editProductItem != false">
            <div style="position: absolute; top:70px; left:50%; width: 1200px; height: calc(100% - 80px); transform: translateX(-50%); background-color: white; border-radius: 10px; box-shadow: 5px 5px 20px black; overflow-y: scroll;">
                <product-editor
                    v-bind:detail-item="editProductItem"
                    v-on:onClose="onCloseProduct"
                    v-on:updateList="onUpdateList"
                    v-bind:places="[item]"
                    v-bind:items="item.products"

                    :is-add-mode="editProductItem.id === 0"/>
            </div>
        </div>
    </div>
</template>

<script>
    import {millisToReadable} from "@/utils/TimeConverter";
    // import AccessDropdownInput from "@/inputs/OperatorDropdownInput";
    import HashTagInput from "@/inputs/HashTagInput";
    import {deletePlace, writePlace, getPlaceTypes, getPlaceUsers, getPlaceClassList} from "@/api/place";

    import { VueEditor } from "vue2-editor";
    const { QRCanvas } = require('qrcanvas-vue');
    import moment from 'moment';
    // import * as firebase from "firebase/app";

    // Add the Firebase services that you want to use
    import {getApp} from "firebase/app";
    import {getDownloadURL, getStorage, ref, uploadBytesResumable} from "firebase/storage";
    // import "firebase/auth";
    // import "firebase/storage";
    import {getDayString, getHours, newDateHour, updateHours} from "@/api/hours";
    import {generateNewProduct, getPlaceProducts, getProductById} from "@/api/product";

    import Editor from "../product/Editor";
    import Item from "../product/Item";
    import {searchUserByText, unlinkUserLink, updateUserLink} from "@/api/user";
    import {getTagList} from "@/api/tag";

    export default {
        name: "PlaceEditor",
        components: {
            // AccessDropdownInput,
            HashTagInput,

            VueEditor,
            QrCanvas: QRCanvas,
            'product-editor' : Editor,
            'product-item': Item,
        },
        props: {
            item: {
                type: Object,
                default: function() { return{} }
            },
            isAddMode: {
                type: Boolean,
                default: true
            },
            onUpdated:{
                type:Function,
                default: null,
            },
            onClose: {
                type: Function,
                default: null,
            }
        },
        data(){
          return {
              onOffValue: 0,
              types: [],
              isEditMode: this.isAddMode,
              editProductItem: false,
              userLinks: [],
              searchUserResults: [],
              serviceTags:[],
              placeClasses:getPlaceClassList(),
          }
        },
        created() {
            this.$data.types = getPlaceTypes();
            this.model = {}
            if(this.item.onOff){
                this.onOffValue = 1
            } else {
                this.onOffValue = 0
            }
            if(this.item.imageUrl != undefined && this.item.imageUrl != null && this.item.imageUrl.length > 0) {
                this.item.imageUrls = this.item.imageUrl.split(',');
            } else {
                this.item.imageUrls = [];
            }
            if(this.item.reviews != undefined) {
                for (let review of this.item.reviews) {
                    review.createdWhen = this.$moment(new Date(review.createdWhen)).format('YYYY-MM-DD');
                }
            }

        },
        mounted() {
            this.getDaySchedule();
            this.getProducts();
            this.$data.searchUserResults = [];
            this.getUserList();
            this.getTagList();
        },
        watch:{
            onOffValue: function(){
                this.item.onOff = (1 === this.onOffValue);
            },
            isAddMode(){
                this.isEditMode = this.isAddMode;
            },
            item: function() {
                console.log("item changed");
                this.$data.searchUserResults = [];
                this.getDaySchedule();
                this.getProducts();
                this.getUserList();
                this.getTagList();
            }
        },
        methods: {
            getDayString: getDayString,
            // setOperatorId: function(id) {
            //     setTagsByOperatorId(id, this.item);
            // },
            onClickSaveBtn: function() {
                let that = this;

                if(that.item.imageUrls.length > 0) {
                    that.item.imageUrl = that.item.imageUrls.join(',');
                }
                that.item.commissionRate = Math.round(parseFloat(that.item.commissionRateFloat)*100);
                writePlace(that.item).then(function (item) {
                    if(that.onUpdated !== null) {
                        that.onUpdated(item);
                        if(that.onClose !== null) {
                            that.onClose();
                        }
                        return;
                    }
                    alert("저장 되었습니다.");

                    that.isEditMode = false;

                    that.$emit("updateList")

                    // TODO: 현재 경로와 대조하여 이동
                    that.$router.push(`/admin/place?id=${item.id}`);
                })
            },
            onClickCloseBtn: function() {
                if(this.onClose !== null) {
                    this.onClose();
                    return;
                }
                this.isEditMode = false;

                this.$emit('onClose', null);
            },
            onClickEditBtn: function() {
                this.isEditMode = true;

                // this.$router.push(`/admin/${this.modelKey}?id=${this.$route.query.id}&edit`);
            },
            onClickRemoveBtn: function () {
                let that = this;

                deletePlace(that.item.id).then(function (post) {
                    alert("삭제 되었습니다.");

                    that.$emit("updateList")

                    that.$emit('onClose', null);
                    // let path = `/admin/${that.modelKey}`;
                    //
                    // that.$router.push(path)
                });
            },
            millisToReadable: millisToReadable,
            fileBtn:function(e){
                const uploader = document.getElementById('uploader');
                let self = this;

                //get file
                console.log("File Btn Added");
                console.log(e);
                let getFile = e.target.files[0];
                console.log("File name - " + getFile.name);

                //set storage ref
                let d = new Date();
                let name  =  moment(d).format('YYYYMMDD-hhmmss');
                let names = getFile.name.split('.');
                let path = 'ncm-place/' + this.item.id + '-' + name + '.' + names[names.length-1];
                let fileExt = names[names.length-1];
                const firebaseApp = getApp();
                let contentType = null;
                if(fileExt.toUpperCase() === 'JPG' || fileExt.toUpperCase() === 'JPEG') {
                    contentType = 'image/jpeg';
                } else if(fileExt.toUpperCase() === 'PNG') {
                    contentType = 'image/png';
                } else if(fileExt.toUpperCase() === 'PDF') {
                    contentType = 'application/pdf';
                }
                const metadata = {
                    contentType: contentType
                };
                const storage = getStorage(firebaseApp);
                let storageRef = ref(storage, path);
                const uploadTask = uploadBytesResumable(storageRef, getFile, metadata);
                uploadTask.on('state_changed',
                    (snapshot) => {
                        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        console.log('Upload is ' + progress + '% done');
                        uploader.value = progress;
                        switch (snapshot.state) {
                            case 'paused':
                                console.log('Upload is paused');
                                break;
                            case 'running':
                                console.log('Upload is running');
                                break;
                        }
                    },
                    (error) => {
                        // EventBus.$emit("showLoading", false);
                        // A full list of error codes is available at
                        // https://firebase.google.com/docs/storage/web/handle-errors
                        switch (error.code) {
                            case 'storage/unauthorized':
                                // User doesn't have permission to access the object
                                break;
                            case 'storage/canceled':
                                // User canceled the upload
                                break;

                            // ...

                            case 'storage/unknown':
                                // Unknown error occurred, inspect error.serverResponse
                                break;
                        }
                    },
                    () => {
                        // Upload completed successfully, now we can get the download URL
                        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                            console.log('File available at', downloadURL);
                            self.item.imageUrl = downloadURL;
                        });
                    }
                );
                // mountainsRef.put(getFile).on('state_changed',
                //     function progress(snapshot){
                //         let percentage = (snapshot.bytesTransferred / snapshot.totalBytes) *100;
                //         console.log("percentage " + percentage);
                //         uploader.value = percentage;
                //     },
                //     function error(err){
                //         console.log(err);
                //     },
                //     function complete(){
                //         console.log('complete upload');
                //         var uploadedUrl = 'https://storage.googleapis.com/ncm-mary-254508.appspot.com/' + path;
                //         self.item.imageUrls.push(uploadedUrl);
                //     }
                // );

            },
            correctYouTubeLink: function() {
                // if(this.item == false) {
                //     return;
                // }
                // if(this.item.videoLink.startsWith('https://www.youtube.com/watch?v=')) {
                //     this.item.videoLink = this.item.videoLink.replace('https://www.youtube.com/watch?v=', 'https://www.youtube.com/embed/');
                // }
                // if(this.item.videoLink.startsWith('https://www.youtube.com/watch?v=')) {
                //     this.item.videoLink = this.item.videoLink.replace('https://www.youtube.com/watch?v=', 'https://www.youtube.com/embed/');
                // }
            },
            onClickRemoveDescriptionImage: function(index) {
                this.item.images.splice(index, 1);
            },
            onImagePickedUp: function(e) {
                const uploader = document.getElementById('img-uploader');
                let self = this;

                //get file
                console.log("File Btn Added");
                console.log(e);
                let getFile = e.target.files[0];
                console.log("File name - " + getFile.name);

                //set storage ref
                let d = new Date();
                let name  =  moment(d).format('YYYYMMDD-hhmmss');
                let names = getFile.name.split('.');
                let fileExt = names[names.length-1];
                let path = 'ncm-place/' + this.item.id + '-' + name + '.' + names[names.length-1];
                const firebaseApp = getApp();
                let contentType = null;
                if(fileExt.toUpperCase() === 'JPG' || fileExt.toUpperCase() === 'JPEG') {
                    contentType = 'image/jpeg';
                } else if(fileExt.toUpperCase() === 'PNG') {
                    contentType = 'image/png';
                } else if(fileExt.toUpperCase() === 'PDF') {
                    contentType = 'application/pdf';
                }
                const metadata = {
                    contentType: contentType
                };
                const storage = getStorage(firebaseApp);
                let storageRef = ref(storage, path);
                const uploadTask = uploadBytesResumable(storageRef, getFile, metadata);
                uploadTask.on('state_changed',
                    (snapshot) => {
                        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        console.log('Upload is ' + progress + '% done');
                        uploader.value = progress;
                        switch (snapshot.state) {
                            case 'paused':
                                console.log('Upload is paused');
                                break;
                            case 'running':
                                console.log('Upload is running');
                                break;
                        }
                    },
                    (error) => {
                        // EventBus.$emit("showLoading", false);
                        // A full list of error codes is available at
                        // https://firebase.google.com/docs/storage/web/handle-errors
                        switch (error.code) {
                            case 'storage/unauthorized':
                                // User doesn't have permission to access the object
                                break;
                            case 'storage/canceled':
                                // User canceled the upload
                                break;

                            // ...

                            case 'storage/unknown':
                                // Unknown error occurred, inspect error.serverResponse
                                break;
                        }
                    },
                    () => {
                        // Upload completed successfully, now we can get the download URL
                        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                            console.log('File available at', downloadURL);

                            self.item.images.push(downloadURL);
                        });
                    }
                );
                // mountainsRef.put(getFile).on('state_changed',
                //     function progress(snapshot){
                //         let percentage = (snapshot.bytesTransferred / snapshot.totalBytes) *100;
                //         console.log("percentage " + percentage);
                //         uploader.value = percentage;
                //     },
                //     function error(err){
                //         console.log(err);
                //     },
                //     function complete(){
                //         console.log('complete upload');
                //         var uploadedUrl = 'https://storage.googleapis.com/ncm-mary-254508.appspot.com/' + path;
                //         self.item.images.push(uploadedUrl);
                //     }
                // );
            },
            bgImagePickedUp: function(e) {
                const uploader = document.getElementById('background-uploader');
                let self = this;

                //get file
                console.log("File Btn Added");
                console.log(e);
                let getFile = e.target.files[0];
                console.log("File name - " + getFile.name);

                //set storage ref
                let d = new Date();
                let name  =  moment(d).format('YYYYMMDD-hhmmss');
                let names = getFile.name.split('.');
                let fileExt = names[names.length-1];
                let path = 'ncm-place/' + this.item.id + '-' + name + '.' + names[names.length-1];
                const firebaseApp = getApp();
                let contentType = null;
                if(fileExt.toUpperCase() === 'JPG' || fileExt.toUpperCase() === 'JPEG') {
                    contentType = 'image/jpeg';
                } else if(fileExt.toUpperCase() === 'PNG') {
                    contentType = 'image/png';
                } else if(fileExt.toUpperCase() === 'PDF') {
                    contentType = 'application/pdf';
                }
                const metadata = {
                    contentType: contentType
                };
                const storage = getStorage(firebaseApp);
                let storageRef = ref(storage, path);
                const uploadTask = uploadBytesResumable(storageRef, getFile, metadata);
                uploadTask.on('state_changed',
                    (snapshot) => {
                        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        console.log('Upload is ' + progress + '% done');
                        uploader.value = progress;
                        switch (snapshot.state) {
                            case 'paused':
                                console.log('Upload is paused');
                                break;
                            case 'running':
                                console.log('Upload is running');
                                break;
                        }
                    },
                    (error) => {
                        // EventBus.$emit("showLoading", false);
                        // A full list of error codes is available at
                        // https://firebase.google.com/docs/storage/web/handle-errors
                        switch (error.code) {
                            case 'storage/unauthorized':
                                // User doesn't have permission to access the object
                                break;
                            case 'storage/canceled':
                                // User canceled the upload
                                break;

                            // ...

                            case 'storage/unknown':
                                // Unknown error occurred, inspect error.serverResponse
                                break;
                        }
                    },
                    () => {
                        // Upload completed successfully, now we can get the download URL
                        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                            console.log('File available at', downloadURL);
                            self.item.cardBackground = downloadURL;
                        });
                    }
                );
                // mountainsRef.put(getFile).on('state_changed',
                //     function progress(snapshot){
                //         let percentage = (snapshot.bytesTransferred / snapshot.totalBytes) *100;
                //         console.log("percentage " + percentage);
                //         uploader.value = percentage;
                //     },
                //     function error(err){
                //         console.log(err);
                //     },
                //     function complete(){
                //         console.log('complete upload');
                //         var uploadedUrl = 'https://storage.googleapis.com/ncm-mary-254508.appspot.com/' + path;
                //         self.item.cardBackground = uploadedUrl;
                //     }
                // );
            },
            onClickRemoveImage: function(index) {
                this.item.imageUrls.splice(index, 1);
            },
            onClickAddReview: function() {
                let newReview = {
                    id: 0,
                    createdWhen: new Date().getTime(),
                    userNickname: '',
                    rating: 5,
                };
                this.item.reviews.push(newReview);
            },
            onClickRemoveReview: function(review) {
                var index = this.item.reviews.indexOf(review);
                this.item.reviews.splice(index, 1);
            },
            createDaySchedule: function() {
                if(this.item.hours != null && this.item.hours.length > 0) {
                    return;
                }

                this.item.hours = [];
                for(let i = 0 ; i < 7 ; i++) {
                    let day = newDateHour();
                    day.dayOfWeek = i;
                    day.placeId = this.item.id;
                    this.item.hours.push(day);
                }
            },
            getDaySchedule: function() {
                let query = "placeId=" + this.item.id;
                let self = this;
                getHours(query)
                .then(function(data) {
                    if(data.success == true) {
                        self.item.hours = data.item;
                    } else {
                        console.log(data.message);
                    }
                })
                .catch(function(err) {

                })
            },
            saveDaySchedule: function() {
                if(this.item.hours == null && this.item.hours.length == 0) {
                    return;
                }

                let days = JSON.parse(JSON.stringify(this.item.hours));
                let self = this;
                updateHours(days)
                .then(function(data) {
                    if(data.success == true) {
                        self.$data.item.hours = data.item;
                    } else {
                        console.log(data.message);
                    }
                })
                .catch(function(err) {

                })
            },
            getProducts: function() {
                let self = this;
                getPlaceProducts(this.item.id)
                .then(function(data) {
                    if(data.success == true) {
                        self.item.products = data.item;
                    } else {
                        console.log(data.message);
                    }
                })
                .catch(function(err) {
                    let msg = err;
                    if(err.response != undefined) {
                        msg += ' (' + err.response + ')';
                        console.log(err.response);
                    }
                    console.log(msg);
                })
            },
            openProduct: function(product) {
                console.log("open p")
                let self = this;
                getProductById(product.id)
                .then(function(item) {
                    self.$data.editProductItem = item;
                    console.log()
                    // if(data.success == true) {
                    //
                    // } else {
                    //     showDialog(data.message);
                    // }
                })
                .catch(function(err) {

                })
                // this.$data.editProductItem = JSON.parse(JSON.stringify(product));
            },
            openNewChildProduct: function(product) {
                let newProduct = generateNewProduct();
                newProduct.groupId = product.id;
                this.$data.editProductItem = newProduct;
            },
            openNewProduct: function() {
                this.$data.editProductItem = generateNewProduct();
                this.$data.editProductItem.placeId = this.item.id;
                console.log("openNewProduct");
                console.log(this.$data.editProductItem);
            },
            onCloseProduct: function() {
                this.$data.editProductItem = false;
            },
            onUpdateList: function() {

            },
            getUserList: function() {
                let self = this;
                console.log("플레이스 사용자 가져오기");
                self.$data.userLinks = [];
                getPlaceUsers(this.item.id)
                .then(function(data) {

                    console.log("place user list response");
                    console.log(data);
                    if(data.success == true) {

                        self.$data.userLinks = data.list;
                    }

                })
                .catch(function(err) {
                    let msg = err;
                    if(err.response != undefined) {
                        msg += ' (' + err.response + ')';
                    }

                    console.log(msg);
                })
            },
            searchUser: function() {
                let value = document.getElementById("place-user-search-input").value;
                if(value == null || value.length < 3) {
                    return;
                }
                let role = "GENERAL";
                let self = this;
                console.log("search with " + value);
                self.$data.searchUserResults = [];
                searchUserByText(value, role).then(list => {
                    console.log(list);
                    self.$data.searchUserResults = list;
                })
            },
            unlinkPlaceUser: function(user) {
                let self = this;
                console.log("unlink place user");
                console.log(user);
                unlinkUserLink(user.id)
                .then(function(data) {
                    if(data.success == true) {
                        for(let i = 0 ; i < self.$data.userLinks.length ; i++) {
                            if(self.$data.userLinks[i].id == data.item.id) {
                                self.$data.userLinks.splice(i, 1);
                                break;
                            }
                        }
                    } else {
                        console.log("failed " + data.message);
                    }
                })
                .catch(function(err) {
                    console.log(err);
                    if(err.response != undefined) {
                        console.log(err.response);
                    }
                })
            },
            updatePlaceUserLink: function(userLink) {
                let link = JSON.parse(JSON.stringify(userLink));
                let self = this;
                updateUserLink(link)
                .then(function(data) {
                    console.log("update user link ");
                    console.log(data);
                    if(data.success == true) {
                        for(let i = 0 ; i < self.$data.userLinks.length ; i++) {
                            if(self.$data.userLinks[i].id == data.item.id) {
                                self.$data.userLinks.splice(i, 1, data.item);
                                return;
                            }
                        }

                    } else {

                    }
                })
                .catch(function(err) {
                    let msg = err;
                    if(err.response != undefined) {
                        msg += ' (' + err.response + ')';
                    }

                    console.log(msg);
                })
            },
            addPlaceUser: function(user, role) {
                console.log("add place user");
                let link = {
                    id: 0,
                    userId: user.id,
                    placeId: this.item.id,
                    role:role,
                }
                let self = this;
                self.$data.searchUserResults = [];
                updateUserLink(link)
                .then(function(data) {
                    console.log("add user link ");
                    console.log(data);
                    if(data.success == true) {
                        self.$data.userLinks.push(data.item);
                    } else {

                    }
                })
                .catch(function(err) {
                    let msg = err;
                    if(err.response != undefined) {
                        msg += ' (' + err.response + ')';
                    }

                    console.log(msg);
                })
            },
            getTagList: function() {
                let self = this;
                console.log("get tag list");
                getTagList('service')
                    .then(function(data) {
                        console.log(data);
                        if(data.success == true) {
                            self.$data.serviceTags = data.list;

                        }
                    })
            },
            isTagOnList: function(tag) {
                if(this.item.tags.length == 0 ) {
                    return false;
                }
                for(let i = 0 ; i < this.item.tags.length ; i++) {
                    if(this.item.tags[i].id == tag.id) {
                        return true;
                    }
                }
                return false;
            },
            onTagClicked: function(tag) {
                for(let i = 0 ; i < this.item.tags.length ; i++) {
                    if(this.item.tags[i].id == tag.id) {
                        return;
                    }
                }
                this.item.tags.push(JSON.parse(JSON.stringify(tag)));
            },
        }
    }
</script>
<style lang="scss" scoped>
.image-preview-list {
    display: block;
    width: 100%;

    .image-preview {
        position: relative;
        display: inline-block;
        width: 10vw;
        height: 10vw;

        .image-remove {
            position: absolute;
            display: table;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            opacity: 0;
            transition: all 0.1s;
            cursor: pointer;

            .icon-holder {
                position: relative;
                display: table-cell;
                vertical-align: middle;
                text-align: center;
                color: #fff;
                z-index: 1;
            }
            .icon-bg {
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                background-color: rgba(0,0,0,0.5);
                z-index: 0;
            }
        }
        .image-remove:hover {
            opacity: 1;
        }
        .image-holder {
            width: 100%;
            height: 100%;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
        }
    }
}
.review-list .review {
    border: 1px solid #CCC;
    padding: 10px;
    margin-top: 10px;
}
</style>
