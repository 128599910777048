<template>

    <div style="position:absolute; top:0; left: 0; width: 100%; height: 100%; background-color: rgba(0,0,0,0.5);">
        <div style="position: absolute; top: 50%; left: 50%; width: 1000px; height: 80%; transform: translate(-50%, -50%); background: white; border-radius: 10px;">

            <div style="width: 100%; height: 50px; line-height: 50px; font-size: 25px; font-weight: bold; text-align: center; border-bottom: 1px solid gray;">
                {{place.name}} 결제카드 관리
            </div>
            <div style="width: 100%; height: calc(100% - 100px); padding: 20px; overflow-y: scroll;">
                <div class="container">
                    <template v-if="items !== null && items.length > 0">
                    <div class="row" v-for="card in items">
                        <div class="col-4">
                            {{maskCardNumber(card.cardNumber)}}

                        </div>
                        <div class="col-2">
                            {{card.expMM}}/{{card.expYY}}
                        </div>
                        <div class="col-2">
                            {{card.ccv}}
                        </div>
                        <div class="col-2">
                            <span v-if="card.defaultCard === true" style="color:green;">기본</span>
                            <span v-else @click="setDefault(card.id)"><i style="color:gray;" class="fas fa-check"></i></span>
                        </div>
                        <div class="col-2">
                            <button class="btn btn-danger" @click="removeCard(card.id)"><i class="fas fa-trash"></i></button>
                        </div>
                    </div>
                    </template>
                    <template v-else>
                        <div class="row">
                            <div class="col-12 text-center" style="padding-top: 50px; padding-bottom: 50px;">
                                등록된 카드 없음
                            </div>
                        </div>
                    </template>
                    <div class="row" style="margin-top: 50px; border: 1px solid gray; padding:10px;" v-if="item !== false">
                        <div class="col-12">
                            <h4>새로운 카드</h4>
                        </div>
                        <div class="col-12">
                            카드번호
                            <input class="form-control" v-model="item.cardNumber"/>
                        </div>
                        <div class="col-4">
                            유효기간 월
                            <select class="form-control" v-model="item.expMM">
                                <option v-for="m in 12" :value="m < 10 ? '0'+m : m.toString()">{{m < 10 ? '0'+m : m}}</option>
                            </select>
                        </div>
                        <div class="col-4">
                            유효기간 연
                            <select class="form-control" v-model="item.expYY">
                                <option v-for="m in 10" :value="(currentYear + m -1).toString()">{{currentYear+m-1}}</option>
                            </select>
                        </div>
                        <div class="col-4">
                             CVC번호 3자리 (카드 뒷면)
                            <input class="form-control" v-model="item.ccv"/>
                        </div>
                        <div class="col-12 text-right" style="margin-top:10px;">
                            <button v-if="item !== false" class="btn btn-danger" @click="closeCard">취소</button>
                            <button v-if="item !== false" class="btn btn-success" @click="addNewCard">등록하기</button>
                        </div>
                    </div>

                </div>
            </div>
            <div style="width: 100%; height: 50px; line-height: 50px;">
                <div class="container">
                    <div class="row">
                        <div class="col-6 text-left">
                            <button class="btn btn-dark" @click="closeView">닫기</button>
                        </div>
                        <div class="col-6 text-right">
                            <button v-if="item === false" class="btn btn-primary" @click="openNewCard"><i class="fas fa-plus"></i> 새로운 카드 추가하기</button>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import {deletePlaceCard, getPlaceCardList, newCardItem, registerNewCard, setDefaultPlaceCard} from "../../api/place";
import {millisToDate, millisToYYYYMM, showDialog} from "../../api/common";

export default {
    name: "CardView",
    props: {
        place:{
            type:Object,
            default: function(){return null;}
        },
        onClose: {
            type: Function,
            default: null,
        }
    },
    data() {
        return {
            items:[],
            item:false,
            currentYear: new Date().getFullYear(),
        }
    },
    created() {
    },
    mounted() {
        this.getCardList();
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    methods: {
        maskCardNumber: function(number) {
            return '**** **** **** ' + number.substr(12);
        },
        sortCardList: function() {
            if(this.$data.items === null || this.$data.items.length < 2) {
                return;
            }

            this.$data.items.sort(function(a, b) {
                return a.defaultCard === true ? -1 : b.defaultCard === true ? 1 : 0;
            })
        },
        getCardList: function() {

            let self = this;
            getPlaceCardList(this.place.id)
            .then(function(data) {
                if(data.success === true) {
                    self.$data.items = data.item;
                    self.sortCardList();
                } else {
                    showDialog(data.message);
                }
            })
            .catch(function(err){
                let msg = err;
                if(err.response !== undefined) {
                    msg += ' (' + err.response + ')';
                }
                showDialog(msg, "ERROR");
            })
        },
        openNewCard: function() {
            this.$data.item = newCardItem(this.place.id);
        },
        closeCard: function() {
            this.$data.item = false;
        },
        addNewCard: function() {
            let self = this;
            if(this.$data.item.cardNumber === null || this.$data.item.cardNumber.length !== 16) {
                showDialog("카드번호는 16자리입니다.");
                return;
            }
            let expiry = this.$data.item.expYY + '-' + this.$data.item.expMM;

            let thisMonth = millisToYYYYMM(new Date().getTime(), '-');

            if(expiry < thisMonth) {
                showDialog("유효기간이 올바르지 않습니다.");
                return;
            }

            if(this.$data.item.ccv === null || this.$data.item.ccv.length !== 3) {
                showDialog(" 보안코드는 3자리 숫자입니다. 카드 뒷면에 있습니다.");
                return;
            }

            let newCard = {
                id:0,
                cardNumber: this.$data.item.cardNumber,
                expYY: this.$data.item.expYY.substr(2),
                expMM: this.$data.item.expMM,
                ccv: this.$data.item.ccv,
                placeId: this.place.id,
            }
            registerNewCard(this.place.id, newCard)
            .then(function(data) {
                if(data.success === true) {
                    self.$data.items.push(data.item);
                    self.sortCardList();
                } else {
                    showDialog(data.message);
                }
            })
            .catch(function(err){
                let msg = err;
                if(err.response !== undefined) {
                    msg += ' (' + err.response + ')';
                }
                showDialog(msg, "ERROR");
            })
        },
        setDefault: function(id) {
            let self = this;
            setDefaultPlaceCard(this.place.id, id)
            .then(function(data) {
                if(data.success === true) {
                    self.$data.items = data.item;
                    self.sortCardList();
                } else {
                    showDialog(data.message);
                }
            })
            .catch(function(err){
                let msg = err;
                if(err.response !== undefined) {
                    msg += ' (' + err.response + ')';
                }
                showDialog(msg, "ERROR");
            })
        },
        removeCard: function(id) {
            let self = this;
            deletePlaceCard(this.place.id, id)
            .then(function(data) {
                if(data.success == true) {
                    for(let i = 0 ; i < self.$data.items.length ; i++) {
                        if(self.$data.items[i].id === data.item.id) {
                            self.$data.items.splice(i, 1);
                            break;
                        }
                    }
                    self.sortCardList();
                } else {
                    showDialog(data.message);
                }
            })
            .catch(function(err){
                let msg = err;
                if(err.response !== undefined) {
                    msg += ' (' + err.response + ')';
                }
                showDialog(msg, "ERROR");
            })
        },
        closeView: function() {
            if(this.onClose !== null) {
                this.onClose();
            }
        }
    }
}
</script>

<style scoped>

</style>
