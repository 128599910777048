<template>
    <div class="detail-view">
        <div class="btns">
            <div class="naim-btn back" v-on:click="onClickCloseBtn()">{{$t('detail.close')}}</div>
        </div>

        <div class="head-group">
            <div class="top"></div>
            <div class="bottom">
                <div class="content-header">
                    <vue-form style="width: 100%;" label-width="25%">
<!--                        <OperatorDropdownInput :is-read-only="true"-->
<!--                            v-bind:operatorId="detailItem.operatorId"-->
<!--                            v-on:onClick="setOperatorId"/>-->
<!--                        <hr>-->
                        <vue-form-item :label="$t('order.operator')">
<!--                            <label>{{detailItem.operatorName}}</label>-->
                            <vue-input
                                :value="detailItem.operatorName"
                                :readonly="true">
                            </vue-input>
                        </vue-form-item>
                        <vue-form-item :label="$t('order.user')">
<!--                            <label>{{detailItem.userName}}</label>-->
                            <vue-input
                                v-model="detailItem.phoneNumber"
                                :readonly="true">
                            </vue-input>
                        </vue-form-item>
                        <vue-form-item :label="$t('order.state')">
                            <vue-input
                                v-model="detailItem.state"
                                :readonly="true">
                            </vue-input>
                        </vue-form-item>
                        <vue-form-item :label="$t('order.type')">
                            <vue-input
                                v-model="detailItem.type"
                                :readonly="true">
                            </vue-input>
                        </vue-form-item>
                        <vue-form-item :label="$t('order.start')">
                            <vue-input
                                v-model="detailItem.startTime"
                                :readonly="true">
                            </vue-input>
                        </vue-form-item>
                        <vue-form-item :label="$t('order.end')">
                            <vue-input
                                v-model="detailItem.toTime"
                                :readonly="true">
                            </vue-input>
                        </vue-form-item>
                        <vue-form-item :label="$t('order.finalDue')">
                            <vue-input
                                v-model="detailItem.finalDue"
                                :readonly="true">
                            </vue-input>
                        </vue-form-item>
                        <vue-form-item :label="$t('order.paid')">
                            <vue-input
                                v-model="detailItem.paid"
                                :readonly="true">
                            </vue-input>
                        </vue-form-item>
                    </vue-form>
                </div><!-- ./content-header -->
            </div><!-- ./bottom -->
        </div><!-- ./head-group -->
    </div>
</template>

<script>
  import {millisToReadable, millisToDate} from "../../../utils/TimeConverter";

  import {deleteProduct, writeProduct, writeProuct} from "../../../api/product";
  import OperatorDropdownInput from "../../../inputs/OperatorDropdownInput";
  import {setTagsByOperatorId} from "../../../api/operator";
  import HashTagInput from "../../../inputs/HashTagInput";
  import DropdownInput from "../../../inputs/DropdownInput";

  export default {
      name: 'OrderEditor',
        components: {
            DropdownInput,
            HashTagInput,
            OperatorDropdownInput,
        },
      props: {
          detailItem: {
              type: Object,
              default: function () {
                  return {}
              }
          }
      },
      methods: {
          onClickCloseBtn: function() {
              this.$emit('clearDetail');
          },
          millisToReadable: millisToReadable
    }
  };
</script>
