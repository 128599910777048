<template>
    <div style="width: 100%; height: 100%; padding-left:15px;padding-right: 15px;">
        <div style="width: calc(100% - 50px); height: 50px; padding-top:10px;">

            <button class="btn" v-bind:class="{'btn-success': mode === 5}" style="margin-left: 15px;" @click="openPurchaseMode">상품구매</button>
            <button class="btn" v-bind:class="{'btn-success': mode === 0}" style="margin-left: 15px;" @click="changeToProductManager">판매상품관리</button>

            <button class="btn" v-bind:class="{'btn-success': mode === 6}" style="margin-left: 15px;" @click="changeToAssetMode">장비관리</button>
            <button class="btn" v-bind:class="{'btn-success': mode === 1}" style="margin-left: 15px;" @click="mode = 1">판매현황</button>
            <button class="btn" v-bind:class="{'btn-success': mode === 2}" style="margin-left: 15px;" @click="mode = 2">판매정산</button>

<!--            <button class="btn" v-bind:class="{'btn-success': mode === 7}" style="margin-left: 15px;" @click="mode = 7">영수내역</button>-->
            <button class="btn" v-if="currentPlaceId > 0" style="margin-left: 15px;" @click="openPlaceOrderViewer">주문(매장용)</button>
            <button class="btn" v-if="currentPlaceId === 0 && userRole >= 99" style="margin-left: 15px;" @click="showHqOrders = true">주문(본사용)</button>
            <button class="btn btn-primary" v-if="userRole === 97 && currentPlaceId > 0" style="margin-left: 15px;" @click="openCardView">결제카드관리</button>

            <button class="btn" v-bind:class="{'btn-success': mode === 8}"
                    v-if="userRole >= 99"
                    style="margin-left: 15px;" @click="mode = 8">알림관리</button>

            <button class="btn notification-btn" v-bind:class="{'selected': mode === 9}"
                    v-if="userRole === 97"
                    style="margin-left: 15px;" @click="mode = 9">공지사항 <span v-if="getNewNotificationCount() > 0" class="new-notification-icon">N</span></button>
        </div>
        <div style="width: calc(100% - 50px); height: 50px; padding-top:10px;">
            <select v-if="placeItems.length > 0 && mode !== 8" v-model="currentPlaceId" style="height: 40px; width: 300px;border-radius: 5px; border: 1px solid grey;" @change="onPlaceSelected">
                <option value="0" v-if="userRole >= 99">전체</option>
                <template v-for="p in placeItems">
                    <option v-bind:key="'place-selector-' + p.id" :value="p.id">{{p.name}} <span v-if="userRole === 100">({{p.commissionRateFloat}}%)</span></option>
                </template>
            </select>
            <template v-if="mode === 0">
                <button class="btn btn-primary" @click="createProduct">상품추가</button>

                <button v-if="userRole >= 99" class="btn btn-dark" style="float:right; margin-right: 10px;" @click="downloadForm">EXCEL입력양식 받기</button>



<!--                <button v-if="currentPlaceId > 0" style="float:right; margin-right: 10px;" class="btn btn-primary" @click="getPlaceLabel">플레이스 라벨</button>-->
<!--                <button v-if="currentPlaceId > 0" style="float:right; margin-right: 10px;" class="btn btn-success" @click="getProductsLabel2(null)">상품 라벨</button>-->
                <button v-if="currentPlaceId > 0 && userRole >= 97" style="float:right; margin-right: 10px;" class="btn btn-primary" @click="getPlaceLabel2">플레이스 라벨 v2</button>
                <button v-if="currentPlaceId > 0 && userRole >= 97" style="float:right; margin-right: 10px;" class="btn btn-success" @click="getProductsLabel2(null)">상품 라벨 v2</button>
                <button v-if="currentPlaceId > 0" style="float:right; margin-right: 10px;" class="btn btn-danger" @click="getProductsLabel('asset')">상품 라벨 (장비용)</button>
                <button v-if="currentPlaceId > 0 && userRole >= 99" style="float:right; margin-right: 10px;" class="btn btn-success" @click="getProductListExcel">상품리스트 다운</button>
                <input type="file" ref="file" style="display: none" v-on:change="addFilePickedUp">
                <button v-if="currentPlaceId > 0 && userRole >= 99" class="btn btn-primary" @click="onFileSelectorClick" style="float: right; margin-right: 10px;">상품 업로드</button>
            </template>
            <template v-else-if="mode === 1">
                <date-picker v-model="dateRange" valueType="format" @change="dateRangeSelected" range></date-picker>
                <button class="btn btn-success" @click="getMarketSales">매출가져오기</button>
                <button class="btn btn-primary" v-if="currentPlaceId > 0 && userRole === 100" @click="openPlaceItem(currentPlaceId)">매장정보</button>
                <button class="btn btn-danger" v-if="currentPlaceId > 0 && userRole === 100" @click="updateCommission">수수료 적용하기</button>

                <button class="btn btn-danger" v-if="currentPlaceId > 0 && userRole === 100" @click="generateStatement">정산 생성</button>
            </template>
            <template v-else-if="mode === 2">
                <date-picker v-model="dateRange" valueType="format" @change="dateRangeSelected" range></date-picker>
                <button class="btn btn-success" @click="getStatementList">정산 목록 가져오기</button>
            </template>
            <template v-else-if="mode === 6">
                <button v-if="currentPlaceId > 0 && userRole >= 99" class="btn btn-success" @click="addPlaceAsset">Asset추가</button>
            </template>
            <template v-else-if="mode === 7">
                <date-picker v-model="dateRange" valueType="format" @change="dateRangeSelected" range></date-picker>
                <button class="btn btn-success" @click="getStatementItemList">영수내역가져오기</button>
            </template>
            <template v-else-if="mode === 8">
                공지관리  <button class="btn btn-primary" style="margin-left: 20px;" @click="createNewNotification">공지등록</button>
                <button class="btn btn-success" style="margin-left: 20px;" @click="getNotifications">가져오기</button>
            </template>
        </div>
        <div v-if="mode === 6" style="width: 100%; height: calc(100% - 100px);">
            <div class="container-fluid h-100" >
                <div class="row h-100">
                    <div class="col-12 h-100">
                        <strong>Assets</strong>
                        <table class="table text-left">
                            <tr>
                                <th>ID</th>
                                <th>이름</th>
                                <th></th>
                                <th>Relay / Index</th>
                                <th>상태</th>
                                <!--                                <th>S/N IP</th>-->
                                <th></th>
                                <th v-if="userRole >= 99"></th>
                                <th v-if="userRole >= 99"></th>

                            </tr>
                            <tr v-for="asset in assets" v-bind:class="{'selected-asset' : selectedAssetId === asset.id}" v-on:click="selectedAssetId = asset.id">
                                <td>{{asset.id}}</td>
                                <td>{{asset.title}}</td>
                                <td>{{getAssetTypeText(asset.assetType)}}</td>
                                <td>{{asset.mqttChannel}} #{{asset.idx}}</td>
                                <!--                                <td>{{asset.serialNumber}}<br/>{{asset.ipAddress}}</td>-->
                                <td>{{asset.status === true ? 'ON' : 'OFF'}}</td>
                                <td>
                                    <button v-if="asset.assetType === 'fridge'" class="btn btn-primary" @click="openFridge(asset.placeId, asset.id)">냉장고 열기</button>
                                    <button v-if="asset.assetType === 'door'" class="btn btn-primary" @click="openGate(asset.placeId, asset.id)">입구 열기</button>

                                    <button v-if="asset.assetType === 'timer'" class="btn btn-primary" @click="toggleTime(asset.placeId, asset.id, true)">켜기</button>
                                    <button v-if="asset.assetType === 'timer'" class="btn btn-danger" @click="toggleTime(asset.placeId, asset.id, false)">끄기</button>
                                </td>
                                <td v-if="userRole >= 99"><button class="btn btn-dark" @click="openPlaceAssetLog(asset)">LOG</button></td>
                                <td v-if="userRole >= 99"><button class="btn btn-success" v-on:click="openPlaceAsset(asset.id)"><i class="fas fa-edit"></i>수정</button></td>

                            </tr>
                        </table>
                    </div>
                </div>
            </div>

        </div>
        <div v-else-if="mode === 7" style="width: 100%; height: calc(100% - 100px);">
            <div class="container-fluid h-100" >
                <div class="row h-100">
                    <div class="col-12 h-100">
                        <table class="table">
                            <tr class="text-center">
                                <th rowspan="2">승인일</th>
                                <th colspan="4" class="text-center" style="background-color: #ADD4FE;">정산대상</th>
                                <th colspan="4" class="text-center" style="background-color: #ADFEB7;">수수료</th>
                                <th rowspan="2">정산액</th>
                            </tr>
                            <tr class="text-right">
                                <th style="background-color: #ADD4FE;">건수</th>
                                <th style="background-color: #ADD4FE;">금액</th>
                                <th style="background-color: #ADD4FE;">부가세</th>
                                <th style="background-color: #ADD4FE;">합계</th>
                                <th style="background-color: #ADFEB7;">수수료율</th>
                                <th style="background-color: #ADFEB7;">수수료</th>
                                <th style="background-color: #ADFEB7;">부가세</th>
                                <th style="background-color: #ADFEB7;">총 수수료</th>
                            </tr>
                            <template v-if="salesStatement !== false">
                                <tbody v-for="s in salesStatement.list">
                                <tr v-bind:key="'sales-statement-date-' + s.date" class="text-right">
                                    <td class="text-left">{{s.date}}</td>
                                    <td class="text-right" style="background-color: #CEE5FF;">{{s.count}}</td>
                                    <td class="text-right" style="background-color: #CEE5FF;">{{s.gross}}</td>
                                    <td class="text-right" style="background-color: #CEE5FF;">{{s.vat}}</td>
                                    <td class="text-right" style="background-color: #CEE5FF;">{{s.net}}</td>
                                    <td class="text-right" style="background-color: #CFFED5;"><span v-if="s.commissionRate >0">{{(s.commissionRate/100.0).toFixed(1)}}%</span></td>
                                    <td class="text-right" style="background-color: #CFFED5;">{{s.commission}}</td>
                                    <td class="text-right" style="background-color: #CFFED5;">{{s.commissionVat}}</td>
                                    <td class="text-right" style="background-color: #CFFED5;">{{s.commissionGross}}</td>
                                    <td class="text-right">{{s.payout}}</td>
                                </tr>
                                </tbody>
                                <tbody>
                                    <tr>
                                        <th class="text-left">합계</th>
                                        <th class="text-right">{{salesStatement.count}}</th>
                                        <th class="text-right">{{salesStatement.gross}}</th>
                                        <th class="text-right">{{salesStatement.vat}}</th>
                                        <th class="text-right">{{salesStatement.net}}</th>
                                        <th></th>
                                        <th class="text-right">{{salesStatement.commission}}</th>
                                        <th class="text-right">{{salesStatement.commissionVat}}</th>
                                        <th class="text-right">{{salesStatement.commissionGross}}</th>
                                        <th class="text-right">{{salesStatement.payout}}</th>
                                    </tr>
                                </tbody>
                            </template>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div v-else-if="mode === 5" style="width: 100%; height: calc(100% - 100px);">
            <div style="width: 100%; height: 50px; background-color: #ccc; display: flex;">
                <div style="width: 50%; height: 50px; line-height: 50px; padding-left: 30px;">
                    <span>{{getPurchasePath()}}</span>
                </div>
                <div style="width: 50%; height: 50px; line-height: 50px; padding-right: 30px; text-align: right">
                    <button class="btn btn-primary" v-if="placeOrder !== false && placeOrder.items.length > 0" @click="showBasketView"><i class="fas fa-shopping-basket"></i></button>
                </div>
            </div>
            <div style="width: 100%; height: calc(100% - 50px); display: flex;">
                <div style="width: 300px; height: 100%; overflow-y:scroll;">
                    <ul class="list-group">
                        <template v-for="c in pCategories">
                            <li class="list-group-item" v-bind:key="'purchase-category-' + c.id" v-on:click="selectPurchaseCategory(c)" v-bind:class="{'selected-category': pCategory !== false && c.id === pCategory.id}"><span style="font-weight: bold;">{{c.title}}</span></li>
                            <template v-for="cc in c.list">
                                <li class="list-group-item"  v-bind:key="'purchase-category-' + cc.id" v-on:click="selectPurchaseCategory(cc)" v-bind:class="{'selected-category': pCategory !== false && cc.id === pCategory.id}"><span style="color: #333;"> - {{cc.title}}</span></li>
                            </template>
                        </template>

                    </ul>
                </div>
                <div style="width: calc(100% - 300px); height: 100%; overflow-y: scroll;">
                    <div class="container">
                        <div class="row">
                            <template v-for="p in pProducts">
                                <div class="col-12 col-md-6 col-lg-4" v-bind:key="'purchase-product-' + p.id">
                                    <div class="purchase-product-container">
                                        <div class="product-image-container" v-bind:style="{backgroundImage: 'url(' + getThumbnail(p.mainImage) + ')'}">

                                        </div>
                                        <div class="product-info-container">
                                            <h5 class="product-title">{{p.title}}</h5>
                                            <h5 class="text-right">{{p.price}}</h5>

                                            <div style="width: 100%; display: inline-block;">
                                                <div style="float:right; padding-left: 20px; padding-right:20px; height: 40px; line-height: 40px; border-radius: 20px; background-color: #8AE68E; color:white; font-weight: bold;" v-on:click="addOrderItem(currentPlaceId, p)">
                                                    {{getBasketProductCount(p.id)}}
                                                </div>

                                                <div style="float:right; margin-right: 50px; padding-left: 20px; padding-right:20px; height: 40px; line-height: 40px; border-radius: 20px; background-color: red; color:white; font-weight: bold;" @click="clearOrderItem(p)"><i class="fas fa-trash"></i></div>
                                            </div>
                                        </div>


                                    </div>
<!--                                    {{p.title}}-->
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div v-if="mode === 0" style="width: 100%; height: calc(100% - 100px); overflow-y: auto;">
            <div class="container-fluid h-100" >
                <div class="row h-100">
<!--                    <div class="col-6 h-100" v-if="currentPlaceId > 0" style="overflow-y: auto; display: none;">-->

<!--                        <strong>Assets</strong>-->
<!--                        <table class="table text-left">-->
<!--                            <tr>-->
<!--                                <th>ID</th>-->
<!--                                <th>이름</th>-->
<!--                                <th></th>-->
<!--                                <th>Relay / Index</th>-->
<!--                                <th>상태</th>-->
<!--                                &lt;!&ndash;                                <th>S/N IP</th>&ndash;&gt;-->
<!--                                <th></th>-->
<!--                                <th v-if="userRole >= 99"></th>-->
<!--                                <th v-if="userRole >= 99"></th>-->

<!--                            </tr>-->
<!--                            <tr v-for="asset in assets" v-bind:class="{'selected-asset' : selectedAssetId === asset.id}"-->
<!--&lt;!&ndash;                                v-on:click="selectedAssetId = asset.id"&ndash;&gt;-->
<!--                            >-->
<!--                                <td>{{asset.id}}</td>-->
<!--                                <td>{{asset.title}}</td>-->
<!--                                <td>{{getAssetTypeText(asset.assetType)}}</td>-->
<!--                                <td>{{asset.mqttChannel}} #{{asset.idx}}</td>-->
<!--                                &lt;!&ndash;                                <td>{{asset.serialNumber}}<br/>{{asset.ipAddress}}</td>&ndash;&gt;-->
<!--                                <td>{{asset.status === true ? 'ON' : 'OFF'}}</td>-->
<!--                                <td>-->
<!--                                    <button v-if="asset.assetType === 'fridge'" class="btn btn-primary" @click="openFridge(asset.placeId, asset.id)">냉장고 열기</button>-->
<!--                                    <button v-if="asset.assetType === 'door'" class="btn btn-primary" @click="openGate(asset.placeId, asset.id)">입구 열기</button>-->

<!--                                    <button v-if="asset.assetType === 'timer'" class="btn btn-primary" @click="toggleTime(asset.placeId, asset.id, true)">켜기</button>-->
<!--                                    <button v-if="asset.assetType === 'timer'" class="btn btn-danger" @click="toggleTime(asset.placeId, asset.id, false)">끄기</button>-->
<!--                                </td>-->
<!--                                <td v-if="userRole >= 99"><button class="btn btn-dark" @click="openPlaceAssetLog(asset)">LOG</button></td>-->
<!--                                <td v-if="userRole >= 99"><button class="btn btn-success" v-on:click="openPlaceAsset(asset.id)"><i class="fas fa-edit"></i>수정</button></td>-->

<!--                            </tr>-->
<!--                        </table>-->



<!--                    </div>-->
                    <div class="h-100 col-5">
                        <div style="width: 100%; height: 100%; display: flex;" >
                            <div style="width:300px; height: 100%;">
                                <ul class="list-group">
                                    <li class="list-group-item" @click="getCategoryProducts(0)">전체</li>

                                    <template v-for="c in categories">
                                    <li class="list-group-item" v-bind:key="'cat-' + c.id" @click="selectCategory(c)" v-bind:class="{'selected-category' : category !== false && category.id === c.id}">
                                        <div style="width: 100%;">
                                            <div style="width: 100%; padding-bottom: 5px;">
                                                <input v-if="c.edit" class="form-control" v-model="c.title" @change="updateCategory(c)"/>

                                                <span v-else>{{c.title}}

                                                </span>
                                            </div>
                                            <div style="width: 100%; text-align: right;">
                                                <button class="btn btn-dark cat-btn"  v-if="c.edit === true" v-on:click.stop="c.edit=false">
                                                    <i class="fas fa-times" ></i>
                                                </button>
                                                <template v-if="c.edit === false">
                                                <button class="btn btn-primary  cat-btn" v-on:click.stop="c.edit=true">
                                                    <i class="fas fa-edit" ></i>
                                                </button>
                                                <button class="btn btn-danger  cat-btn" >
                                                    <i class="fas fa-trash" v-on:click.stop="deleteCategory(c)"></i>
                                                </button>
                                                <button class="btn btn-success  cat-btn" v-if="c.edit === false" >
                                                    <i class="fas fa-print" v-on:click.stop="getPlaceCategoryLabel2(c)"></i>
                                                </button>
                                                </template>
                                            </div>
                                        </div>

                                    </li>
                                    </template>
                                    <li class="list-group-item"><button class="btn btn-success" @click="addNewCategory(0, categories.length)"><i class="fas fa-plus"></i></button></li>
                                </ul>
                            </div>
                            <div style="width: 300px; height: 100%;" v-if="category !== false">
                                <ul class="list-group">
                                    <template v-for="c in category.list">
                                        <li class="list-group-item" v-bind:key="'cat-' + c.id" @click="selectSubCategory(c)" v-bind:class="{'selected-category': subCategory !== false && subCategory.id === c.id}">
                                            <div style="width: 100%; display: flex;">
                                                <div style="width: calc(100% - 80px);">
                                                    <input v-if="c.edit" class="form-control" v-model="c.title" @change="updateCategory(c)"/>

                                                    <span v-else>{{c.title}}

                                                </span>
                                                </div>
                                                <div style="width: 80px; text-align: right;">
                                                    <button class="btn btn-success" style="padding:0.2rem; margin-right:10px;" v-if="c.edit === true">
                                                        <i class="fas fa-times" v-on:click.stop="c.edit=false"></i>
                                                    </button>
                                                    <button v-else class="btn btn-primary" style="padding:0.2rem; margin-right:10px;">
                                                        <i class="fas fa-edit" v-on:click.stop="c.edit=true"></i>
                                                    </button>
                                                    <button class="btn btn-danger" style="padding:0.2rem;">
                                                        <i class="fas fa-trash" v-on:click.stop="deleteCategory(c)"></i>
                                                    </button>
                                                </div>
                                            </div>

                                        </li>
                                    </template>
                                    <li class="list-group-item"><button class="btn btn-success" @click="addNewCategory(category.id, category.list.length)"><i class="fas fa-plus"></i></button></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="h-100 col-7" style="overflow-y: auto;">
                        <strong>상품리스트 {{currentPlaceId}} {{userRole}}</strong>
                        <table style="width: 100%;">
                            <tr>
                                <th><span @click="selectAll">선택</span></th>
                                <th>사진</th>
                                <th class="text-left">상품명</th>
                                <th class="text-left">카테고리</th>
                                <th v-if="currentPlaceId > 0">
                                    Assets
                                </th>
                                <th>가격</th>
                                <!--                                <th>원가</th>-->
                                <!--                                <th>수수료율</th>-->
                                <!--                                <th>제조사</th>-->
                                <!--                                <th>원산지</th>-->
                                <th>재고</th>
                                <th>판매여부</th>
                                <th></th>
<!--                                <th v-if="currentPlaceId > 0"></th>-->

                            </tr>
                            <tbody  v-for="(product, index) in products">
                            <tr>
                                <td>
                                    <input type="checkbox" v-bind:value="product.selected" v-model="product.selected" @click="toggleSelection($event, index)"/>
                                </td>
                                <td>
                                    <div style="width: 50px; height: 50px;" class="product-item-image-holder" v-bind:style="{backgroundImage : 'url(' + getThumbnail(product.mainImage) + ')'}"></div>
                                </td>
                                <td class="text-left">
                                    <strong>{{product.title}}</strong>
                                </td>
                                <td class="text-left">{{getCategoryName(product.categoryId)}}</td>
                                <td v-if="currentPlaceId > 0">
                                    {{getAssetTitle(product.assetId)}}
                                </td>
                                <td>
                                    {{product.price}}
                                </td>

                                <td>{{product.stock}}</td>
                                <td>
                                    <span v-if="product.soldOut === false" style="color:green;">판매중</span>
                                    <span v-else style="color:red;">품절</span>
                                </td>

                                <td @click="openProduct(product.id)">
                                    <i class="fas fa-edit"></i><span >수정</span>
                                </td>
<!--                                <td v-if="currentPlaceId > 0">-->
<!--                                    <span @click="addPlaceOrderItem(currentPlaceId, product)">주문</span>-->
<!--                                </td>-->
                            </tr>
                            </tbody>
                        </table>

                    </div>
                    <div class="col-6 h-100" style="overflow-y: scroll; display: none;" v-if="userRole >= 99 && currentPlaceId === 0">
                        <h4>주문 리스트
                            <date-picker v-model="dates" valueType="format" @change="onDateSelected" range></date-picker>
                            <button class="btn btn-success" @click="getHqOrderListByTime">기간으로 가져오기</button>
                            <button class="btn btn-primary" @click="getHqOrderListByState(0, 90)">진행중인 주문</button>
                        </h4>
                        <table class="table">
                            <tr>

                                <th>주문일</th>
                                <th>매장</th>
                                <th>금액</th>
                                <th>부가세</th>
                                <th>총</th>
                                <th>상태</th>
                            </tr>
                            <tbody v-for="order in hqOrders">
                            <tr @click="openHqOrder(order.placeId, order.id)">
                                <td>{{dateString(order.orderedTime)}}</td>
                                <td>{{getShopTitle(order.placeId)}}</td>
                                <td>{{order.totalPrice}}</td>
                                <td>{{order.tax}}</td>
                                <td>{{order.total}}</td>
                                <td>{{order.state}}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div v-else-if="mode === 1" style="width: 100%; height: calc(100% - 100px); overflow-y: auto;">
            <div class="container-fluid h-100" v-if="report !== false">
                <div class="row h-100 text-left">
                    <template v-if="currentPlaceId > 0">
                        <div class="col-8 h-100" style="overflow-y: auto;">
                            <h3 style="margin-top: 10px;">{{report.title}}</h3>


                            <table class="table text-left" style="background: white;">
                                <tbody>
                                <tr>
                                    <th @click="selectAllOrders" v-if="userRole >= 99">선택</th>
                                    <th class="text-left">날짜 시간</th>
                                    <th class="text-center">번호</th>
                                    <th></th>
    <!--                                <th>수량</th>-->
<!--                                    <th class="text-right">금액</th>-->
                                    <th class="text-right">결제</th>
                                    <th class="text-right">환불</th>
                                    <th class="text-right" v-if="userRole >= 99">수수료율</th>
                                    <th class="text-right" v-if="userRole >= 99">수수료</th>
                                    <th class="text-right" v-if="userRole >= 99">정산액</th>
                                </tr>

                                <template v-for="(cr, ci) in report.orders" >
                                <tr v-bind:key="'sales-row-' + ci"  @click="openSalesOrder(cr.id)" v-bind:class="{'refunded-item': cr.refunded === true, 'selected-item-row': cr.selected === true}">
                                    <td v-if="userRole >= 99" class="item-check" v-bind:class="{'item-check-selected': cr.selected === true}" @click.stop="onSalesItemSelected(ci)">
                                        <i class="fas fa-check"></i>
                                    </td>
                                    <td class="text-left">
                                        {{dateTimeString(cr.createdWhen)}}
                                    </td>
                                    <td class="text-center">{{cr.id}}</td>
                                    <td>
                                        {{cr.userName}}<br/>{{cr.userPhoneNumber}}
                                    </td>
    <!--                                <td>-->
    <!--                                    <span v-if="cr.amount > 0">{{cr.amount}}</span>-->
    <!--                                </td>-->
<!--                                    <td class="text-right">-->
<!--                                        {{cr.finalDue}}-->
<!--                                    </td>-->
                                    <td class="text-right">
                                        {{cr.paid.toLocaleString()}}
                                    </td>
    <!--                                <td class="text-right">-->
    <!--                                    <span v-if="cr.refundId > 0">R</span>-->
    <!--                                </td>-->
                                    <td>
                                        <span v-if="cr.refundId > 0" style="color:red;">환불결제:{{cr.refundId}}</span>
                                        <span v-else-if="cr.originalOrderId > 0" style="color:red;">환불대상:{{cr.originalOrderId}}</span>
                                    </td>
                                    <td class="text-right" v-if="userRole >= 99">
                                        {{(cr.commissionRate/100.0).toFixed(1)}}%
                                    </td>
                                    <td class="text-right" v-if="userRole >= 99">
                                        {{cr.commission.toLocaleString()}}
                                    </td>
                                    <td class="text-right" v-if="userRole >= 99">
                                        {{cr.payoutAmount.toLocaleString()}}
                                    </td>
                                </tr>
                                </template>
                                <tr>
                                    <th colspan="3">총</th>
                                    <th><span v-if="report.amount > 0">{{report.amount}}</span></th>
<!--                                    <th class="text-right">{{report.totalPrice}}</th>-->
                                    <th class="text-right">{{report.paid.toLocaleString()}}</th>
    <!--                                <th class="text-right">{{report.refundedAmount}}</th>-->
    <!--                                <th class="text-right" style="font-size: 20px;">{{report.balance}}</th>-->
                                    <th/>
                                    <th class="text-right" v-if="userRole >= 99"></th>
                                    <th class="text-right" v-if="userRole >= 99">{{report.commission.toLocaleString()}}</th>
                                    <th class="text-right" style="font-size: 20px;" v-if="userRole >= 99">{{report.payoutAmount.toLocaleString()}}</th>
                                </tr>
                                </tbody>
                            </table>

                        </div>
                        <div class="col-4 h-100" style="overflow-y: auto;">
                            <h4 style="margin-top: 10px;">세부내역</h4>
                            <template v-if="salesOrderItem !== false && salesOrderItem.originalOrderId === 0">
                                소비자: {{salesOrderItem.userName}} {{salesOrderItem.userPhoneNumber}}
                                <table class="table" style="background: white;">
                                    <tr>
                                        <th colspan="2" class="text-left">픔목</th>

                                        <th class="text-center">수량</th>
                                        <th class="text-right">{{salesOrderItem.finalDue.toLocaleString()}}</th>
                                        <th></th>
                                    </tr>
                                    <template v-if="salesOrderItem.list !== null && salesOrderItem.list.length > 0">
                                        <tr v-for="o in salesOrderItem.list">
                                            <td style="max-width: 60px;">
                                                <div style="width: 50px; height: 50px;" v-if="o.imageUrl !== null && o.imageUrl.length > 0" class="product-item-image-holder" v-bind:style="{backgroundImage : 'url(' + getThumbnail(o.imageUrl) + ')'}"></div>
                                            </td>

                                            <td class="text-left">
                                                {{o.title}}
                                                <div v-if="o.options !== undefined && o.options !== null && o.options.length > 0">
                                                    {{getOptionText(o)}}
                                                </div>
                                            </td>
                                            <td class="text-center">{{o.amount}}</td>
                                            <td class="text-right">{{o.finalDue.toLocaleString()}}</td>
                                            <td></td>
                                        </tr>
                                    </template>
<!--                                    <template v-else>-->
<!--                                        <tr v-for="r in selectReport.list">-->
<!--                                            <td>{{r.title}}</td>-->
<!--                                            <td><span v-if="r.amount > 0">{{r.amount}}</span></td>-->
<!--                                            <td>{{r.totalPrice}}</td>-->
<!--                                            <td></td>-->
<!--                                        </tr>-->
<!--                                    </template>-->
                                </table>
                                <div style="margin-top: 10px; padding: 20px; border: 1px solid blue; border-radius: 10px; background:white;">
                                    <h4>결제 정보</h4>
                                    <p>결제 금액: {{salesOrderItem.paid.toLocaleString()}}</p>
                                    <p>결제 시간: {{dateTimeString(salesOrderItem.transactionTime)}}</p>
                                    <p>결제 번호: {{salesOrderItem.transactionId}}</p>
                                    <div v-if="salesOrderItem.content !== null && salesOrderItem.content.length > 0" v-html="salesOrderItem.content">

                                    </div>
                                </div>

                                <div v-if="salesOrderItem.refundOrder !== null" style="margin-top: 10px; padding: 20px; border: 2px solid red; border-radius: 10px; background: white;">
                                    <h4>결제 취소 (환불) 정보</h4>
                                    <p>환불 금액: {{salesOrderItem.refundOrder.paid.toLocaleString()}}</p>
                                    <p>환불 시간: {{dateTimeString(salesOrderItem.refundOrder.transactionTime)}}</p>
                                    <p>환불 번호: {{salesOrderItem.refundOrder.transactionId}}</p>
                                </div>
                                <div v-if="salesOrderItem.refundId === 0" style="padding-top: 20px;">
                                    <button class="btn btn-danger" @click="startRefund">결제 취소하기</button>
                                </div>
                            </template>
                            <template v-if="salesOrderItem !== false && salesOrderItem.originalOrderId > 0">
                                <div style="margin-top: 10px; padding: 20px; border: 1px solid blue; border-radius: 10px; background:white;">
                                    <h4>환불 정보</h4>
                                    <p>환불 금액: {{salesOrderItem.paid.toLocaleString()}}</p>
                                    <p>환불 시간: {{dateTimeString(salesOrderItem.transactionTime)}}</p>
                                    <p>환불 번호: {{salesOrderItem.transactionId}}</p>
                                    <p>환불 대상 주문번호: {{salesOrderItem.originalOrderId}}</p>
                                </div>
                            </template>
                        </div>
                    </template>
                    <div class="col-6 h-100" style="overflow-y: auto;">
                        <h4 style="margin-top: 10px;">세부내역</h4>
                        <template v-if="selectReport">
                            <table class="table" style="background: white;">
                                <tr>
                                    <th style="max-width: 60px;"></th>
                                    <th>{{selectReport.title}}</th>

                                    <th>수량</th>
                                    <th>{{selectReport.totalPrice}}</th>
                                    <th></th>
                                </tr>
                                <template v-if="selectReport.orders !== null && selectReport.orders.length > 0">
                                    <tr v-for="o in selectReport.orders">
                                        <td style="max-width: 60px;">
                                            <div style="width: 50px; height: 50px;" v-if="o.imageUrl !== null && o.imageUrl.length > 0" class="product-item-image-holder" v-bind:style="{backgroundImage : 'url(' + getThumbnail(o.imageUrl) + ')'}"></div>
                                        </td>

                                        <td>{{o.title}}</td>
                                        <td>{{o.amount}}</td>
                                        <td>{{o.finalDue}}</td>
                                        <td></td>
                                    </tr>
                                </template>
                                <template v-else>
                                <tr v-for="r in selectReport.list">
                                    <td>{{r.title}}</td>
                                    <td><span v-if="r.amount > 0">{{r.amount}}</span></td>
                                    <td>{{r.totalPrice}}</td>
                                    <td></td>
                                </tr>
                                </template>
                            </table>
                            <div v-if="selectReport.order !== null" style="margin-top: 10px; padding: 20px; border: 1px solid blue; border-radius: 10px; background:white;">
                                <h4>결제 정보</h4>
                                <p>결제 금액: {{selectReport.order.paid}}</p>
                                <p>결제 시간: {{dateTimeString(selectReport.order.transactionTime)}}</p>
                                <p>결제 번호: {{selectReport.order.transactionId}}</p>
                            </div>

                            <div v-if="selectReport.refund !== null" style="margin-top: 10px; padding: 20px; border: 2px solid red; border-radius: 10px; background: white;">
                                <h4>결제 취소 (환불) 정보</h4>
                                <p>환불 금액: {{selectReport.refund.paid}}</p>
                                <p>환불 시간: {{dateTimeString(selectReport.refund.transactionTime)}}</p>
                                <p>환불 번호: {{selectReport.refund.transactionId}}</p>
                            </div>
                            <div v-else-if="selectReport.order !== null && selectReport.order.paid > 0">
                                <button class="btn btn-danger" @click="startRefund">결제 취소하기</button>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>

        <div v-else-if="mode === 2" style="width: 100%; height: calc(100% - 100px); overflow-y: auto;">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <table class="table no-padding">
                            <tr>
                                <th>#</th>
                                <th v-if="userRole >= 99">Date</th>
                                <th>지급일</th>
                                <th>MBR</th>
                                <th>가맹점명</th>
                                <th>결제수단</th>
                                <th>정산대상일</th>
                                <th>건수</th>
                                <th>금액</th>
                                <th>부가세</th>
                                <th>합계</th>
                                <th>수수료율</th>
                                <th>수수료</th>
                                <th>부가세</th>
                                <th>합계</th>
                                <th>계산서 발행</th>
                                <th>조정금액</th>
                                <th>실지급액</th>
                                <th>지급선택</th>
                                <th>정산담당자</th>
                                <th>입금계좌</th>
                                <th v-if="userRole >= 99">확정</th>
                                <th v-if="userRole >= 99"></th>
                                <th v-if="userRole >= 99"></th>

                            </tr>
                            <template v-if="statements !== null && statements.length > 0">
                                <tbody v-for="(s, si) in statements">
                                <tr>
                                    <td>{{si+1}}</td>
                                    <td v-if="userRole >= 99">{{s.statementDate}}</td>
                                    <td>
                                        <input type="date" v-model="s.payoutDate" @change="onStatementItemChanged('payoutDate', si)" :disabled="s.statementStatus !== 100 || s.payoutStatus === 100 || userRole < 99"/>
                                    </td>
                                    <td>
                                        <span v-if="s.companyType === 'S'">간이</span>
                                        <span v-else>일반</span>
                                    </td>
                                    <td>{{s.placeName}}</td>
                                    <td></td>
                                    <td>{{s.dateFrom}}~{{s.dateTo}}</td>
                                    <td>{{s.count}}</td>
                                    <td>{{s.total}}</td>
                                    <td>{{s.vat}}</td>
                                    <td>{{s.net}}</td>
                                    <td>{{(s.commissionRate/100.0).toFixed(2)}}</td>
                                    <td>{{s.fee}}</td>
                                    <td>{{s.feeVat}}</td>
                                    <td>{{s.feeGross}}</td>
                                    <td>
                                        <select v-model="s.invoiceIssued" @change="onStatementItemChanged('invoiceIssued', si)" :disabled="userRole < 99">
                                            <option value="false">미발행</option>
                                            <option value="true">완료</option>
                                        </select>
                                    </td>
                                    <td>
                                        <input type="number" style="max-width: 100px;" v-model="s.adjustment" @change="onStatementItemChanged('adjustment', si)" :disabled="userRole < 99"/>

                                    </td>
                                    <td>{{s.finalPayout}}</td>
                                    <td>
                                        <select v-model="s.payoutStatus" @change="onStatementItemChanged('payoutStatus', si)" :disabled="userRole < 99">
                                            <option value="0">N/A</option>
                                            <option value="50">지급예정</option>
                                            <option value="100">지급완료</option>
                                            <option value="-100">지급보류</option>
                                        </select>
                                    </td>

                                    <td></td>
                                    <td>
                                        {{s.bankName}} {{s.accountNumber}}
                                    </td>
                                    <td v-if="userRole >= 99">
                                        <select v-model="s.statementStatus" @change="onStatementItemChanged('statementStatus', si)" :disabled="userRole < 99">
                                            <option value="0">검토</option>
                                            <option value="100">확정</option>
                                            <option value="-100">보류</option>
                                        </select>
                                    </td>
                                    <td v-if="userRole >= 99">
                                        <span><i class="fas fa-eye"></i></span>
                                    </td>
                                    <td v-if="userRole >= 99">
                                        <button @click="recalculateTax(s.id)">VAT 다시계산</button>
                                    </td>
                                </tr>
                                </tbody>
                            </template>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div v-else-if="mode === 8 || mode === 9" style="width: 100%; height: calc(100% - 100px);">

            <div style="width: 100%; height: 100%; display: flex;">
                <div style="width: 50%; height: 100%; overflow-y: scroll;">
                    <div class="container">
                        <div class="row">
                            <div class="col-12" v-for="n in notifications" v-bind:key="'notification-item-' + n.id" >
                                <div class="notification-card"
                                     v-bind:class="{'notification-selected': notificationItem !== false && notificationItem.id === n.id}"
                                     @click="openNotification(n)">
                                    <div class="title">{{n.title}} <span class="new-notification-icon" v-if="n.readTime === 0"> NEW</span></div>
                                    <div class="notification-content">{{n.content}}</div>
                                    <div class="time">{{timeToText(n.createdTime)}} <span v-if="n.updatedTime > 0">마지막수정:{{timeToText(n.updatedTime)}}</span>  <span v-if="n.readTime > 0">확인: {{timeToText(n.readTime)}}</span></div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="width: 50%; height: 100%; overflow-y: scroll;">
                    <div v-if="notificationItem !== false" class="notification-card">
                        <div class="title">{{notificationItem.title}}</div>
                        <div class="time">{{timeToText(notificationItem.createdTime)}}</div>
                        <div class="notification-content">{{notificationItem.content}}</div>
                        <img width="100%" style="margin-top: 10px;" v-if="notificationItem.image !== null && notificationItem.image.length > 0" :src="notificationItem.image"/>


                        <div v-if="notificationItem.list !== null && notificationItem.list.length > 0" style="margin-top: 30px;">
                            <h5>매장별 확인여부</h5>
                            <div class="notification-place-list-container">
                                <template v-for="ni in notificationItem.list">
                                    <div v-bind:key="'notification-place-item-' + ni.id" class="notification-place-item" v-bind:class="{'notification-read': ni.readTime > 0}">
                                        <h6>{{getPlaceName(ni.placeId)}}</h6>
                                        <p v-if="ni.readTime >0 ">확인시간: {{timeToText(ni.readTime)}}</p>
                                    </div>
                                </template>
                            </div>
                        </div>
                        <div class="time" v-if="notificationItem.readTime > 0">공지읽음: {{timeToText(notificationItem.readTime)}}</div>
                        <div style="margin-top:20px;">
                            <button class="btn btn-dark" v-if="userRole >= 99 || notificationItem.readTime > 0" @click="notificationItem = false">닫기</button>
                            <button class="btn btn-success" style="float:right; margin-left: 20px;" v-if="userRole >= 99" @click="openNotificationEditForm">수정하기</button>

                            <button class="btn btn-danger" style="float:right;" v-if="userRole >= 99" @click="deleteNotificationItem">삭제</button>


                            <button class="btn btn-primary" v-else-if="userRole === 97" @click="closeNotification">읽음 확인</button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div style="position: fixed; top:0; left: 0; width: 100%; height: 100%; background-color: rgba(0,0,0,0.3);" v-if="assetItem !== false" >
            <div style="position: absolute; width: 100%; max-width: 1000px; height: 90%; background: white; top:70px; left:50%; transform: translateX(-50%); overflow-y: auto;" v-on:click.stop="dummy">
                <div class="container">
                    <div class="row input-list-container">
                        <div class="col-12">
                            Asset 이름
                            <input class="form-control" v-model="assetItem.title"/>
                        </div>

                        <div class="col-12">
                            종류
                            <select class="form-control" v-model="assetItem.assetType">
                                <option value="door">출입구</option>
                                <option value="fridge">냉장고</option>
                                <option value="timer">타이머</option>
                            </select>
                        </div>
                        <div class="col-12">
                            MQTT Channel (relay 명: relay1001)
                            <input class="form-control" v-model="assetItem.mqttChannel"/>
                        </div>
                        <div class="col-12">
                            Idx
                            <select class="form-control" v-model="assetItem.idx">
                                <option value="-1">선택</option>
                                <option v-for="(i, ii) in 10" :value="ii">{{ii}}</option>
                            </select>
                        </div>
                        <template v-if="assetItem.assetType === 'door' || assetItem.assetType === 'timer'">
                        <div class="col-12" v-if="assetItem.assetType === 'door'">
                            시작 종료 시간, 영업시간내에만 문이 열림.

                        </div>
                        <div class="col-12" v-if="assetItem.assetType === 'timer'">
                            시작시간에 ON / 종료시간에 OFF메세지 전송
                        </div>
                        <div class="col-6">
                            시작 시간
                            <select v-model="assetItem.startHour" class="form-control">
                                <option value="-1">선택 (24시간 영업)</option>
                                <option v-for="(h, hi) in 24" :value="hi">{{hi}}시</option>
                            </select>
                        </div>
                        <div class="col-6">
                            종료 시간
                            <select v-model="assetItem.endHour" class="form-control">
                                <option value="-1">선택 (24시간 영업)</option>
                                <option v-for="(h, hi) in 24" :value="hi">{{hi}}시</option>
                            </select>
                        </div>
                        </template>
                        <div class="col-12">
                            Delay (초)
                            <select class="form-control" v-model="assetItem.delay">
                                <option v-for="(i, ii) in 10" :value="ii">{{ii}} 초</option>
                            </select>
                        </div>
                        <div class="col-12">
                            S/N
                            <input class="form-control" v-model="assetItem.serialNumber"/>
                        </div>
                        <div class="col-12">
                            IP Address
                            <input class="form-control" v-model="assetItem.ipAddress"/>
                        </div>
                        <div class="col-12">
                            MAC
                            <input class="form-control" v-model="assetItem.macAddress"/>
                        </div>
                    </div>
                    <div class="row input-list-container" v-if="assetItem.id > 0">
                        <div class="col-12">
                            <qr-canvas :options="{cellSize:5, data:'ncm.marysharing.com.market.asset?pid='+assetItem.placeId + '&id=' + assetItem.id}"></qr-canvas>
                        </div>
                    </div>

                    <div class="row" style="margin-top: 30px;padding-bottom: 10px;">
                        <div class="col-4">
                            <button class="btn btn-dark" @click="closePlaceAsset">닫기</button>
                        </div>
                        <div class="col-4 text-center">
                            <button class="btn btn-danger" v-if="assetItem.id > 0" @click="deletePlaceAsset(assetItem.id)">삭제</button>
                        </div>

                        <div class="col-4 text-right">
                            <button class="btn btn-primary" @click="updatePlaceAsset"><span v-if="assetItem.id === 0">등록</span><span v-else>수정</span></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div style="position: fixed; top:0; left: 0; width: 100%; height: 100%; background-color: rgba(0,0,0,0.3);" v-if="item !== false" >
            <div style="position: absolute; width: 100%; max-width: 1000px; height: 90%; background: white; top:70px; left:50%; transform: translateX(-50%); display: flex;" v-on:click.stop="dummy">
                <div style="width: 50%; height: 100%; overflow-y: scroll;">
                    <div class="container">
                        <div class="row input-list-container">
                            <div class="col-12">
                                상품명
                                <input class="form-control" v-model="item.title"/>
                            </div>
                        </div>
                        <div class="row input-list-container" v-if="item.id > 0">
                            <div class="col-6">
                                <div style="width: 150px; height: 150px;" class="product-item-image-holder" v-bind:style="{backgroundImage : 'url(' + item.mainImage + ')'}"></div>
                            </div>
                            <div class="col-6">
                                <progress value="0" max="100" id="uploader"></progress>
                                <input accept="image/*" type="file" ref="file" value="upload" @change="imageSelected($event,'mainImage')">
                            </div>
                        </div>
                        <div class="row input-list-container" >
                            <div class="col-12">
                                카테고리
                                <select v-model="item.categoryId" class="form-control">
                                    <option value="0">선택하세요</option>
                                    <template v-for="c in categories">
                                        <option v-bind:key="'category-selector-' + c.id" :value="c.id">{{c.title}}</option>
                                        <template v-for="cc in c.list">
                                            <option  v-bind:key="'category-selector-' + cc.id" :value="cc.id"> - {{cc.title}}</option>
                                        </template>
                                    </template>
                                </select>
                            </div>
                        </div>
                        <div class="row input-list-container" v-if="item.id > 0">
                            <div class="col-12">
                                <qr-canvas :options="{cellSize:5, data:'ncm.marysharing.com.market?pid='+item.placeId + '&id=' + item.id}"></qr-canvas>
                            </div>
                        </div>
                        <div class="row input-list-container">
                            <div class="col-12">
                                바코드
                                <input class="form-control" type="number" v-model="item.barcode"/>
                            </div>
                            <div class="col-12">
                                냉장고
                                <select class="form-control" v-model="item.assetId">
                                    <option value="0">선택하세요</option>
                                    <option v-for="a in assets" :value="a.id">{{a.title}}</option>
                                </select>
                            </div>
                            <div class="col-12">
                                가격
                                <input class="form-control" type="number" v-model="item.price"/>
                            </div>
                            <div class="col-12">
                                단위
                                <input class="form-control" v-model="item.unit"/>
                            </div>
                            <div class="col-12" v-if="item.placeId === 0">
                                단위당 개수 (주문완료시, 매장 재고는 단위당 개수로 곱하여 업데이트 됨)
                                <input class="form-control" type="number" v-model="item.amountPerUnit"/>
                            </div>
                            <div class="col-12" v-if="item.placeId > 0 && userRole >= 99">
                                본사 주문 연동 상품
                                <select v-model="item.parentId" class="form-control">
                                    <option value="0">선택하세요</option>
                                    <option v-for="p in hqProducts" :value="p.id">{{p.title}}</option>
                                </select>
                            </div>
                            <div class="col-12">
                                냉장고 연동
                                <select class="form-control" v-model="item.openFridge">
                                    <option value="true">결제시 냉장고 열기</option>
                                    <option value="false">결제시 냉장고 필요없음</option>
                                </select>
                            </div>
                            <div class="col-12">
                                제조사
                                <input class="form-control" v-model="item.originCountry"/>
                            </div>
                            <div class="col-12">
                                원산지
                                <input class="form-control" v-model="item.manufacturer"/>
                            </div>
                            <div class="col-12">
                                원가
                                <input class="form-control" type="number" v-model="item.originalPrice"/>
                            </div>
                            <div class="col-12">
                                수수료
                                <select class="form-control" v-model="item.commission">
                                    <option value="0">0 %</option>
                                    <option v-for="i in 100" :value="i">{{i}} %</option>
                                </select>
                            </div>
                            <div class="col-12" v-if="currentPlaceId === '0'">
                                최소 재고
                                <input type="number" v-model="item.minStock" class="form-control"/>
                            </div>
<!--                            <div class="col-12" v-if="currentPlaceId === '0'">-->
<!--                                매장 최소 재고-->
<!--                                <input type="number" v-model="item.shopMinStock" class="form-control"/>-->
<!--                            </div>-->
                            <div class="col-12">
                                제품설명
                                <textarea class="form-control" rows="3" v-model="item.description"/>
                            </div>

                            <div class="col-12">
                                선택항목 <button class="btn" :class="{'btn-success': item.optionEnabled === true}" @click="item.optioneEnabled = true">사용</button>
                                <button class="btn" :class="{'btn-dark': item.optionEnabled === false}" @click="item.optionEnabled = false">미사용</button>
                                <template v-for="(option, oi) in item.options">
                                    <div :key="'product-option-' + oi" class="option-container">
                                        <input v-model="option.title" class="form-control" placeholder="이름"/>
                                        <div style="margin-top: 10px;">
                                            <button class="btn" :class="{'btn-primary': option.required === true}" @click="option.required = true">필수선택</button>
                                            <button class="btn" :class="{'btn-primary': option.required === false}" @click="option.required = false">추가선택</button>
                                        </div>

                                        <div style="margin-top: 10px;">
                                            <button class="btn" :class="{'btn-primary': option.choiceType === 0}" @click="option.choiceType = 0">1개 선택</button>
                                            <button class="btn" :class="{'btn-primary': option.choiceType === 1}" @click="option.choiceType = 1">여러개 선택</button>
                                        </div>


                                        <template v-for="(s, si) in option.list">
                                            <div :key="'product-option-'+oi + '-item-' + si" class="option-selector">
                                                <div class="option-selector-input">
                                                    이름: <input v-model="s.title"/><br/>
                                                    가격: <input type="number" v-model="s.price"/><br/>
                                                </div>
                                                <div class="option-selector-btn-container">
                                                    <span @click="deleteOptionItem(oi, si)" style="font-size: 18px;"><i class="fas fa-trash"></i></span>
                                                </div>

                                            </div>
                                        </template>

                                        <div style="width: 100%; display: flex; margin-top: 10px;">
                                            <button class="btn btn-danger" @click="deleteOption(oi)"><i class="fas fa-trash"></i> 삭제</button>
                                            <button class="btn btn-success" style="margin-left: auto;" @click="addOptionItem(oi)"><i class="fas fa-plus"></i>선택항목 추가</button>

                                        </div>
                                    </div>

                                </template>
                                <div>
                                    <button class="btn btn-success" @click="addOption"><i class="fas fa-plus"></i></button>
                                </div>
                            </div>
                        </div>
                        <div class="row" style="margin-top: 30px;padding-bottom: 10px;">
                            <div class="col-4">
                                <button class="btn btn-dark" @click="closeProduct">닫기</button>
                            </div>
                            <div class="col-4 text-center">
                                <button class="btn btn-danger" @click="deleteProduct(item.id)">삭제</button>
                            </div>
                            <div class="col-4 text-right">
                                <button class="btn btn-primary" @click="updateProduct"><span v-if="item.id === 0">등록</span><span v-else>수정</span></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="width: 50%; height: 100%; overflow-y: scroll;">
                    <div class="container" v-if="item.id > 0">
                        <div class="row" style="margin-top: 30px;">
                            <div class="col-12">
                                <h4>재고 현황</h4>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                현재재고
                            </div>
                            <div class="col-6">
                                {{item.stock}}
                            </div>
                        </div>
                        <div class="row" style="margin-top:20px;">
                            <div class="col-12">
                                <button class="btn btn-success" @click="addNewStock(currentPlaceId, item.id)"><i class="fas fa-plus"></i> 입고 / 출고</button>
                            </div>
                        </div>
                        <div class="row" v-if="newStock !== false" style="margin-top:20px;">

                            <div class="col-4">
                                입출고 선택
                                <select class="form-control" v-model="newStock.type">
                                    <option value="stock_in">입고</option>
                                    <option value="stock_out_to_place" v-if="currentPlaceId === 0">매장으로 출고</option>
                                    <option value="stock_out">출고</option>
                                    <option value="stock_correction">조정 (입력된 수량으로 설정됨)</option>
                                </select>
                            </div>

                            <div class="col-4">
                                변경 수량
                                <input v-model="newStock.stock"/>
                            </div>
                            <div class="col-4" v-if="newStock.type === 'stock_out_to_place'">
                                매장을 선택하세요
                                <select v-model="newStock.toPlaceId" class="form-control">
                                    <option value="0">선택하세요</option>
                                    <option v-for="p in placeItems" :value="p.id">{{p.name}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="row" v-if="newStock !== false">
                            <div class="col-6">
                                <button class="btn btn-dark" @click="newStock = false">취소</button>
                            </div>
                            <div class="col-6 text-right">
                                <button class="btn btn-primary" @click="submitNewStock">완료</button>
                            </div>
                        </div>

                         <div class="row">
                             <div class="col-12">
                                 <h5>재고 이력</h5>
                             </div>
                             <div class="col-12">

                                 <date-picker v-model="stockDateRange" valueType="format" @change="stockDateRangeSelected" range></date-picker>
                                 <button class="btn btn-success" @click="getProductStocks">불러오기</button>
                             </div>

                             <div class="col-12">
                                <table class="table">
                                    <tr>
                                        <th>Time</th>
                                        <th>Type</th>
                                        <th>Diff</th>
                                        <th>재고</th>
                                    </tr>
                                    <tbody v-for="stock in stocks">
                                    <tr>
                                        <td>{{dateString(stock.createdWhen)}}</td>
                                        <td>{{stockString(stock.type)}}</td>
                                        <td>{{stock.stock}}</td>
                                        <td>{{stock.balance}}</td>
                                    </tr>
                                    </tbody>
                                </table>
                             </div>
                         </div>
                    </div>


                </div>

            </div>
        </div>
        <div style="position: fixed; top:0; left: 0; width: 100%; height: 100%; background-color: rgba(0,0,0,0.3);" v-if="showHqOrders !== false" @click="showHqOrders = false" >
            <div style="position: absolute; width: 100%; max-width: 1500px; height: 90%; background: white; top:70px; left:50%; transform: translateX(-50%); overflow-y: auto;" v-on:click.stop="dummy">
                <hq-place-orders-viewer></hq-place-orders-viewer>
            </div>
        </div>
        <div style="position: fixed; top:0; left: 0; width: 100%; height: 100%; background-color: rgba(0,0,0,0.3);" v-if="placeOrderView !== false" @click="placeOrderView = false" >
            <div style="position: absolute; width: 100%; max-width: 1500px; height: 90%; background: white; top:70px; left:50%; transform: translateX(-50%); overflow-y: auto;" v-on:click.stop="dummy">
                <place-orders-viewer
                    :place="placeOrderView">

                </place-orders-viewer>
            </div>
        </div>
        <div style="position: fixed; top:0; left: 0; width: 100%; height: 100%; background-color: rgba(0,0,0,0.3);" v-if="assetLogItem !== false" @click="assetLogItem = false" >
            <div style="position: absolute; width: 100%; max-width: 1500px; height: 90%; background: white; top:70px; left:50%; transform: translateX(-50%); overflow-y: auto;" v-on:click.stop="dummy">
                <place-asset-log-view
                    :item="assetLogItem"
                    :on-close="closePlaceAssetLog">

                </place-asset-log-view>
            </div>
        </div>
        <template v-if="placeCardView !== false">
            <card-view
                :place="placeCardView"
                :on-close="closeCardView"/>
        </template>

        <div style="position: fixed; top:0; left: 0; width: 100%; height: 100%; background-color: rgba(0,0,0,0.4);" v-if="placeItem !== false">
            <div style="position: absolute; top: 100px; left: 50%; width: 80%; max-width: 700px; height: calc(100% - 150px); background-color: white; transform: translateX(-50%); overflow-y: scroll;">

                <place-editor
                    v-bind:item="placeItem"

                    :on-updated="onPlaceItemUpdate"
                    :on-close="closePlaceItem"
                    :is-add-mode="false"/>
            </div>
        </div>



        <div style="position: fixed; top:0; left:0; width: 100%; height: 100%; background-color: rgba(0,0,0,0.4);" v-if="showBasket === true">
            <div style="position: absolute; top: 100px; left: 50%; width: 80%; max-width: 700px; height: calc(100% - 150px); background-color: white; transform: translateX(-50%); overflow-y: scroll;">
                <div v-if="placeOrder !== false" style="margin-top: 20px; padding: 20px;">
                    <h4>장바구니</h4>
                    <table class="table">
                        <tr>
                            <th>상품</th><th>가격</th><th>수량</th><th>금액</th><th>부가세</th><th>총</th><th/>
                        </tr>
                        <tbody v-for="(item, ii) in placeOrder.items">
                        <tr>
                            <td>{{item.title}} <span v-if="item.unit !== null && item.unit.length > 0">({{item.unit}} * {{item.amountPerUnit}})</span></td>
                            <td>{{item.unitPrice}}</td>
                            <td><input v-model="item.amount" @change="recalculatePlaceOrder" style="text-align: center;"/></td>
                            <td>{{item.totalPrice}}</td>
                            <td>{{item.tax}}</td>
                            <td>{{item.total}}</td>
                            <td><span @click="removePlaceOrderItem(ii)"><i class="fas fa-trash"/></span></td>
                        </tr>

                        </tbody>
                        <tr>
                            <th/><th/><th/><th>{{placeOrder.totalPrice}}</th><th>{{placeOrder.tax}}</th><th>{{placeOrder.total}}</th><th></th>
                        </tr>
                    </table>
                    <div class="container">
                        <div class="row">
                            <div class="col-4 text-left">
                                <button class="btn btn-dark" @click="showBasket = false">닫기</button>
                            </div>
                            <div class="col-4 text-right">
                                <button class="btn btn-danger" @click="clearPlaceOrder"><i class="fas fa-trash"></i> 비우기</button>
                            </div>
                            <div class="col-4 text-right">
                                <button v-if="placeOrder.items.length > 0" class="btn btn-primary" @click="submitPlaceOrder">주문하기</button>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>
        <div style="position: fixed; top:0; left: 0; width: 100%; height: 100%; background-color: rgba(0,0,0,0.4);" v-if="notificationForm !== false">
            <div style="position: absolute; top: 100px; left: 50%; width: 80%; max-width: 1000px; height: calc(100% - 150px); background-color: white; transform: translateX(-50%); overflow-y: scroll;">
                <notification-form
                    :item="notificationForm"
                    :places="placeItems"
                    :on-close="closeNotificationForm"
                    :on-updated="onNotificationUpdated"
                    :on-completed="onNotificationCreated"/>
            </div>
        </div>
    </div>
</template>

<script>


import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import PlaceEditor from "../components/contents/place/Editor";

// Add the Firebase services that you want to use
import {getApp} from "firebase/app";
import {getDownloadURL, getStorage, ref, uploadBytesResumable} from "firebase/storage";
import {
    getPlaces,
    getPlaceAsset,
    getPlaceAssets,
    updatePlaceAsset,
    newPlaceAsset,
    openFridgeRelay,
    openGateRelay,
    toggleTimerRelay,
    deletePlaceAsset,
    newStockItem,
    addShopProductStock,
    getShopProductStocks,
    getStockTypeString,
    getStatements,
    generateStatements,
    updateStatementItem,
    getMarketCategories,
    getNewMarketCategory,
    updateMarketCategory,
    deleteMarketCategory, getStatementItems, getMakerSalesOrder, getPlaceStatements, correctVatStatements
} from "@/api/place";
import {
    adminProcessPlaceOrder,
    deletePlaceShopProduct, getPlaceOrderList,
    getPlaceShopProduct,
    getPlaceShopProducts, getProductForOrder, newPlaceOrder,
    newShopProduct, updatePlaceOrder,
    updatePlaceShopProduct,
    updatePlaceShopProducts,

    submitPlaceOrder, getMarketCategoryProducts,
} from "@/api/product";
import {applyNewCommission, getMarkerSales, hireRefund, posOrderRefund} from "../api/order";
import {getBlob, millisToDateTime, postBlob, showDialog} from "../api/common";
import {getRoleId} from "../store";
import moment from "moment";

import PlaceAssetLogView from "./PlaceAssetLogView";
import {millisToDate, millisToReadable} from "../utils/TimeConverter";

// import  * as firebase from "firebase";
// import "firebase/auth";
// import "firebase/storage";

import PlaceOrdersViewer from "../components/PlaceOrdersViewer";
import HqPlaceOrdersViewer from "../components/HqPlaceOrdersViewer";
import CardView from "./contents/CardView";
import {
    deleteNotification,
    getAdminNotification,
    getNotification,
    getShopNotification, getShopNotifications,
    markNotificationAsRead,
    newNotificationItem
} from "@/api/notifications";
import NotificationForm from "@/views/contents/NotificationForm.vue";
import FileDownload from "js-file-download";

const { QRCanvas } = require('qrcanvas-vue');
// import * as mqtt from "mqtt"
// const mqtt = require('mqtt')
export default {
    name: "Shop",
    data() {
        return {
            currentPlaceId:0,
            placeItems:[],
            placeItem:false,
            products:[],
            selectedProductId:0,
            item:false,
            state: {
                tickets: [{name: "test"}],
                headers: ["Test header"]
            },
            mode:0,
            dateRange:null,
            stockDateRange:null,
            stocks:[],
            assetItem:false,
            assets:[],
            assetTypes:[
                {value:'door',name:'입구'},
                {value:'fridge',name:'냉장고'},
                {value:'timer',name:'타이머'},
            ],
            report:false,
            selectReport:false,
            selectedAssetId:0,
            assetLogItem: false,

            mqttClient:null,
            mqttConnected:false,
            mqttList:[],
            disconnectMqtt: false,
            userRole:0,
            newStock:false,
            placeOrder: false,
            placeOrders:[],
            hqOrder:false,
            hqOrders:[],
            dates:null,
            placeOrderView:false,
            showHqOrders: false,
            hqProducts:[],
            placeCardView: false,
            statements:[],

            categories:[],
            category:false,
            subCategory: false,

            pCategories:[],
            pCategory:false,
            pProducts:[],

            showBasket:false,

            salesStatement:[],
            salesOrderItem: false,


            notifications:[],
            notificationItem:false,
            notificationEditItem:false,
            notificationForm:false,
            notificationSelectedPlaces:[],
        }
    },
    components: {
        QrCanvas: QRCanvas,
        DatePicker,
        PlaceAssetLogView,
        PlaceOrdersViewer,
        HqPlaceOrdersViewer,
        CardView,
        PlaceEditor,
        NotificationForm
    },
    created() {
        this.connect();
    },
    mounted() {
        this.$data.userRole = getRoleId();
        this.onStart();
    },
    beforeDestroy() {
    },
    destroyed() {
        this.$data.disconnectMqtt = true;
        this.disconnect();
    },
    methods: {
        dateTimeString: millisToReadable,
        dateString:millisToDate,
        stockString:getStockTypeString,
        dummy: function() {
            console.log("dummy");
        },
        getOptionText: function(item) {
            if(item.options === undefined || item.options === null || item.options.length === 0) {
                return '';
            }

            let options = JSON.parse(item.options);
            let selectedOptions = [];
            for(let i = 0 ; i < options.length ; i++) {
                for(let j = 0 ; j < options[i].list.length ; j++) {
                    if(options[i].list[j].selected === true) {
                        selectedOptions.push(options[i].list[j].title);
                    }
                }
            }
            if(selectedOptions.length === 0) {
                return '';
            }
            return '(' + selectedOptions.join(", ") +')';
        },
        getAssetTypeText: function(value) {
          for(let i = 0 ; i < this.$data.assetTypes.length ; i++) {
              if(value === this.$data.assetTypes[i].value) {
                  return this.$data.assetTypes[i].name;
              }

          }
          return '알 수 없음';
        },

        openPlaceItem: function(id) {
            for(let i = 0 ; i < this.$data.placeItems.length ; i++) {
                if (this.$data.placeItems[i].id === id) {
                    this.$data.placeItem = this.$data.placeItems[i];
                    break;
                }
            }
        },
        closePlaceItem: function() {
            this.$data.placeItem = false;
        },
        onPlaceItemUpdate: function(item) {
          for(let i = 0 ; i < this.$data.placeItems.length ; i++) {
              if(this.$data.placeItems[i].id === item.id) {
                  this.$data.placeItems.splice(i, 1, item);
              }
          }
        },
        getShopTitle: function(id) {
            console.log("get shop title " + id);
            console.log(this.$data.placeItems);
            for(let i = 0 ; i < this.$data.placeItems.length ; i++) {
                if(this.$data.placeItems[i].id === id) {
                    return this.$data.placeItems[i].name;
                }
            }
            return '(' + id + ')';
        },
        onStart: function() {
            let self = this;
            console.log("onStart");
            getPlaces('typeClass=market')
                .then(function(list) {
                    console.log(list);
                    self.$data.placeItems = list;
                    if(self.$data.placeItems.length == 1) {
                        self.$data.currentPlaceId = self.$data.placeItems[0].id;
                        // §console.log("")
                        // self.connect();
                        self.onPlaceSelected();
                        if(self.$data.userRole === 97) {
                            self.getNotifications();
                        }

                    } else {

                    }
                    console.log("user role id " + self.$data.userRole);
                    if(self.$data.userRole >= 99) {
                        self.getHqProductList();
                    }
                })
                .catch(function(err) {
                    console.log(err);
                    if(err.response != undefined) {
                        console.log(err.response);
                    }
                })
        },
        onPlaceSelected: function() {
            this.$data.currentPlaceId = parseInt(this.$data.currentPlaceId);
            this.$data.selectedAssetId = 0;
            this.$data.assets = [];
            this.$data.placeOrder = false;
            if(this.$data.mode === 6) {
                this.getPlaceAssetList();
                return;
            } else if(this.$data.mode === 1) {
                this.$data.report = false;
                return;
            }
            this.subscribeChannel()
            this.changeToProductManager();
        },
        getHqProductList: function() {
            console.log("get hq product list");
          this.$data.hqProducts = [];
          let self = this;
            getPlaceShopProducts(0)
            .then(function(data) {
                if(data.success == true) {
                    console.log(data.list);
                    self.$data.hqProducts = data.list;

                }

            })
            .catch(function(err) {
                console.log("error to get hq products");
                console.log(err);
                if(err.response !== undefined) {
                    console.log(err.response);
                }
            })
        },
        selectCategory: function(category) {
            this.$data.category = category;
            this.$data.subCategory = false;
            this.$data.products = [];
            // if(category.list === null || category.list.length === 0) {
                this.getCategoryProducts(category.id);
            // }
        },
        selectSubCategory: function(category) {
            this.$data.subCategory = category;
            this.getCategoryProducts(this.$data.subCategory.id);

        },
        getCategoryProducts: function(cId) {
            let self = this;
            self.$data.products = [];
            getMarketCategoryProducts(this.$data.currentPlaceId, cId)
                .then(function(data) {
                    if(data.success === true) {
                        self.$data.products = data.list;
                    } else {
                        console.log("No products");
                    }
                })
                .catch(function(err) {
                    let msg = err;
                    if(err.response !== undefined) {
                        msg += ' (' + err.response + ')';
                    }
                    showDialog(msg);
                })
        },
        getCategories: function() {
            let self = this;
            this.$data.categories = [];
            this.$data.category = false;
            this.$data.subCategory = false;
          getMarketCategories(this.$data.currentPlaceId)
              .then(function(data) {
                  console.log(data);
                  if(data.success === true) {
                      self.$data.categories = data.item;
                  } else {
                      showDialog(data.message);
                  }
              })
              .catch(function(err) {
                  let msg = err;
                  console.log(err);
                  if(err.response !== undefined) {
                      msg += ' (' + err.response + ')';
                      console.log(err.response);
                  }
                  showDialog(msg);
              })
        },
        updateCategory: function(category) {
            let self = this;
            updateMarketCategory(this.$data.currentPlaceId, category)
                .then(function(data) {
                    if(data.success === true) {
                        category.edit = false;
                        // if(data.item.parentId === 0) {
                        //     self.$data.categories.push(data.item);
                        // } else {
                        //     for(let i = 0 ; i < self.$data.categories.length ; i++) {
                        //         if(self.$data.categories[i].id === data.item.parentId) {
                        //             self.$data.categories[i].list.push(data.item);
                        //         }
                        //     }
                        // }
                    } else {
                        category.edit = false;
                        showDialog(data.message);
                    }
                })
                .catch(function(err) {
                    let msg = err;
                    console.log(err);
                    if(err.response !== undefined) {
                        msg += ' (' + err.response + ')';
                        console.log(err.response);
                    }
                    showDialog(msg);
                })
        },
        deleteCategory: function(category) {

            let self = this;
            deleteMarketCategory(category.placeId, category.id)
                .then(function(data) {
                    if(data.success === true) {
                        for(let i = 0 ; i < self.$data.categories.length ; i++) {
                            if(self.$data.categories[i].id === category.id) {
                                self.$data.categories.splice(i, 1);
                                return;
                            }
                            for(let j = 0 ; j < self.$data.categories[i].list.length ; j++) {
                                if(self.$data.categories[i].list[j].id === category.id) {
                                    self.$data.categories[i].list.splice(j, 1);
                                    return;
                                }
                            }
                        }
                    }
                })
        },
        addNewCategory: function(parentId, pos) {
            let item = getNewMarketCategory(this.$data.currentPlaceId, parentId);
            item.pos = pos;

            let self = this;
            updateMarketCategory(this.$data.currentPlaceId, item)
                .then(function(data) {
                    if(data.success === true) {
                        if(data.item.parentId === 0) {
                            self.$data.categories.push(data.item);
                        } else {
                            for(let i = 0 ; i < self.$data.categories.length ; i++) {
                                if(self.$data.categories[i].id === data.item.parentId) {
                                    self.$data.categories[i].list.push(data.item);
                                }
                            }
                        }
                    } else {
                        showDialog(data.message);
                    }
                })
                .catch(function(err) {
                    let msg = err;
                    console.log(err);
                    if(err.response !== undefined) {
                        msg += ' (' + err.response + ')';
                        console.log(err.response);
                    }
                    showDialog(msg);
                })

        },
        getProductList: function() {
            let self = this;
            this.$data.products = [];
            this.$data.selectedProductId = 0;
            getPlaceShopProducts(this.$data.currentPlaceId)
            .then(function(data) {
                if(data.success == true) {
                    console.log(data.list);
                    self.$data.products = data.list;
                    // if(data.item.length == 1) {
                    //     self.$data.selectedProductId = data.list[0].id;
                    //     self.onProductSelected();
                    //
                    // }
                }
                self.getPlaceAssetList();
            })
            .catch(function(err) {

            })
        },
        getPurchasePath: function() {
            if(this.$data.pCategory === false) {
                return '';
            }

            for(let i = 0 ; i < this.$data.pCategories.length ; i++) {
                if(this.$data.pCategories[i].id === this.$data.pCategory.id) {
                    return this.$data.pCategories[i].title;
                }

                for(let j = 0 ; j < this.$data.pCategories[i].list.length ; j++) {
                    if(this.$data.pCategories[i].list[j].id === this.$data.pCategory.id) {
                        return this.$data.pCategories[i].title + ' / ' + this.$data.pCategories[i].list[j].title;
                    }
                }
            }

            return '';
        },
        getCategoryName: function(categoryId) {
          for(let i = 0 ; i < this.$data.categories.length ; i++) {
              if(this.$data.categories[i].id === categoryId) {
                  return this.$data.categories[i].title;
              }
              for(let j = 0 ; j < this.$data.categories[i].list.length ; j++) {
                  if(this.$data.categories[i].list[j].id === categoryId) {
                      return this.$data.categories[i].title + '/' + this.$data.categories[i].list[j].title;
                  }
              }
          }
          return 'NA';
        },
        getAssetTitle: function(id) {
            id = parseInt(id);
          if(id === 0) {
              return 'N/A';
          }
            if(this.$data.assets === null || this.$data.assets.length === 0) {
              return '(' + id + ')';
          }


          for(let i = 0 ; i < this.$data.assets.length ; i++) {
              if(this.$data.assets[i].id === id) {
                  return this.$data.assets[i].title;
              }
          }
          return '?';
        },
        toggleSelection: function(e, index) {
            this.$data.products[index].selected = !this.$data.products[index].selected;

        },
        selectAll: function() {
            let isAllSelected = false;
            let selectedCount = 0;
            for(let i = 0 ; i < this.$data.products.length ; i++) {
                if(this.$data.products[i].selected === true) {
                    selectedCount += 1;
                }
            }

            if(selectedCount === this.$data.products.length) {
                isAllSelected = true;
            }


            console.log('currently selected all ' + isAllSelected);
            for(let i = 0 ; i < this.$data.products.length ; i++) {
                this.$data.products[i].selected = !isAllSelected;
            }
        },
        openProduct: function(id) {
            let self = this;
            getPlaceShopProduct(this.$data.currentPlaceId, id)
            .then(function(data) {
                if(data.success === true) {
                    if(data.item.options === null || data.item.options === '') {
                        data.item.options = [];
                    } else {
                        data.item.options = JSON.parse(data.item.options);
                    }
                    self.$data.item = data.item;
                    self.initialiseStockSection();
                } else {
                    showDialog(data.message);
                }
            })
            .catch(function(err) {

            })
        },
        initialiseStockSection:function() {
          this.$data.stockDateRange = [];
          this.$data.stocks = [];

          let today = new Date();
          let todayString = millisToDate(today.getTime(), '-');
          today.setDate(-7);
          let fromString = millisToDate(today.getTime(), '-');
          this.$data.stockDateRange = [fromString, todayString];
          this.getProductStocks();
        },
        createProduct: function() {
            this.$data.item = newShopProduct(this.$data.currentPlaceId);

        },
        addOption: function() {
            let option = {
                title:'',
                choiceType: 0,
                required:false,
                list:[]
            }
            this.$data.item.options.push(option);
        },
        addOptionItem: function(oi) {
          let optionItem = {
              title: '',
              price:0,
              selected:false,
          }
          this.$data.item.options[oi].list.push(optionItem);
        },
        deleteOption: function(oi) {
            this.$data.item.options.splice(oi, 1);
        },
        deleteOptionItem: function(oi, ii) {
            this.$data.item.options[oi].list.splice(ii, 1);
        },
        closeProduct: function() {
            this.$data.item = false;
        },
        deleteProduct: function(id) {
            let self = this;
            console.log("delete product ask");
            this.$modal.show('dialog',{
                    text: this.$data.item.title + '을 삭제 하시겠습니까?',
                    buttons: [
                        {
                            title:'아니요',
                            handler:()=>{
                                self.$modal.hide('dialog');
                            }
                        },
                        {
                            title:'삭제하기',
                            handler:() => {
                                self.$modal.hide('dialog');
                                self.deleteProductConfirmed(id);
                            }
                        }
                    ]
                }

            );
        },
        deleteProductConfirmed: function(id) {
            let self = this;
            deletePlaceShopProduct(this.$data.currentPlaceId, this.$data.item.id)
            .then(function(data) {
                if(data.success === true) {
                    for(let i = 0 ; i < self.$data.products.length ; i++) {
                        if(self.$data.products[i].id === data.item.id) {
                            if(data.item.deleted === true) {
                                self.$data.products.splice(i, 1);
                            } else {
                                self.$data.products.splice(i, 1, data.item);
                            }
                            self.$data.item = false;
                            return;
                        }
                    }
                } else {
                    showDialog(data.message);
                }
            })
            .catch(function(err) {
                let msg = err;
                if(err.response !== undefined) {
                    msg += ' (' + err.response + ')';
                }
                showDialog(msg, "ERROR");
            })
        },
        updateProduct: function() {
            let self = this;

            let isNew = false;
            if(this.$data.item.id === 0) {
                isNew = true;
            }
            let item = JSON.parse(JSON.stringify(this.$data.item));
            item.options = JSON.stringify(item.options);
            updatePlaceShopProduct(this.$data.currentPlaceId, item)
            .then(function(data) {
                if(data.success === true) {
                    if(isNew) {
                        self.$data.products.push(data.item);
                        return;
                    }
                    for(let i = 0 ; i < self.$data.products.length ; i++) {
                        if(self.$data.products[i].id === data.item.id) {
                            if(data.item.deleted === true) {
                                self.$data.products.splice(i, 1);
                            } else {
                                self.$data.products.splice(i, 1, data.item);
                            }
                            return;
                        }
                    }
                } else {
                    showDialog(data.message);
                }
            })
            .catch(function(err) {
                let msg = err;
                if(err.response !== undefined) {
                    msg += '(' + err.response + ')';
                }
                showDialog(msg, "ERROR");
            })
        },
        getProductStocks: function() {
          if(this.$data.stockDateRange === null || this.$data.stockDateRange.length !== 2) {
              showDialog("기간을 선택하세요")
              return;
          }
          this.$data.stocks = [];
          let fromDate = new Date(this.$data.stockDateRange[0]);
          let toDate = new Date(this.$data.stockDateRange[1]);
          fromDate.setHours(0,0,0,0);
          toDate.setHours(23,59,59,999);

          let self = this;
            getShopProductStocks(this.$data.item.placeId, this.$data.item.id, fromDate.getTime(), toDate.getTime())
            .then(function(data) {
                if(data.success === true) {
                    self.$data.stocks = data.item;
                }
            })
            .catch(function(err) {
                let msg = err;
                if(err.response !== undefined) {
                    msg += ' (' + err.response + ')';
                }
                showDialog(msg);
            })
        },
        addNewStock: function(placeId, productId) {
          this.$data.newStock = newStockItem(placeId, productId);
        },
        submitNewStock: function() {
          if(this.$data.newStock === false) {
              return;
          }
          if(this.$data.newStock.stock === 0) {
              showDialog("수량을 입력하세요");
              return;
          }
          if(this.$data.newStock.type === 'stock_out_to_place' && this.$data.newStock.toPlaceId === 0) {
              showDialog("매장을 선택하세요");
              return;
          }
          let newItem = JSON.parse(JSON.stringify(this.$data.newStock));
          if(this.$data.newStock.type === 'stock_out' || this.$data.newStock.type === 'stock_out_to_place') {
              newItem.stock = -newItem.stock;
          }

          let self = this;

          addShopProductStock(newItem)
            .then(function(data) {
                if(data.success === true) {
                    console.log(data);
                    if(data.product !== undefined && data.product !== null) {
                        self.$data.item = data.product;
                        for(let i = 0 ; i < self.$data.products.length ; i++) {
                            if(self.$data.products[i].id === data.product.id) {
                                if(data.product.options === null || data.product.options === '') {
                                    data.product.options = [];
                                } else {
                                    data.product.options = JSON.parse(data.product.options);
                                }
                                self.$data.products.splice(i, 1, data.product);
                                break;
                            }
                        }
                        console.log(self.$data.products);
                    }


                    self.$data.newStock = false;
                } else {
                    showDialog(data.message);
                }
            })
            .catch(function(err) {
                let msg = err;
                if(err.response !== undefined) {
                    msg += ' (' + err.response + ')';
                }
                showDialog(msg);
            })
        },
        getThumbnail: function(url) {
            let words = url.split('.');
            words[words.length-2] += '_150x150';
            return words.join('.');
        },
        imageSelected:function(e, field){
            // e.preventDefault();
            const uploader = document.getElementById('uploader');
            //get file
            console.log("File Btn Added");
            console.log(e);
            let getFile = e.target.files[0];
            console.log("File name - " + getFile.name);
            //set storage ref
            let d = new Date();
            let name  =  moment(d).format('YYYYMMDD-hhmmss');
            let names = getFile.name.split('.');
            let fileExt = names[names.length-1];
            let path = 'ncm-shop-product/'  + this.$data.item.id + '-' + name + '.' + names[names.length-1];
            const firebaseApp = getApp();
            let contentType = null;
            if(fileExt.toUpperCase() === 'JPG' || fileExt.toUpperCase() === 'JPEG') {
                contentType = 'image/jpeg';
            } else if(fileExt.toUpperCase() === 'PNG') {
                contentType = 'image/png';
            } else if(fileExt.toUpperCase() === 'PDF') {
                contentType = 'application/pdf';
            }
            const metadata = {
                contentType: contentType
            };
            const storage = getStorage(firebaseApp);
            let storageRef = ref(storage, path);
            const uploadTask = uploadBytesResumable(storageRef, getFile, metadata);
            let self = this;
            uploadTask.on('state_changed',
                (snapshot) => {
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    console.log('Upload is ' + progress + '% done');
                    uploader.value = progress;
                    switch (snapshot.state) {
                        case 'paused':
                            console.log('Upload is paused');
                            break;
                        case 'running':
                            console.log('Upload is running');
                            break;
                    }
                },
                (error) => {
                    // EventBus.$emit("showLoading", false);
                    // A full list of error codes is available at
                    // https://firebase.google.com/docs/storage/web/handle-errors
                    switch (error.code) {
                        case 'storage/unauthorized':
                            // User doesn't have permission to access the object
                            break;
                        case 'storage/canceled':
                            // User canceled the upload
                            break;

                        // ...

                        case 'storage/unknown':
                            // Unknown error occurred, inspect error.serverResponse
                            break;
                    }
                },
                () => {
                    // Upload completed successfully, now we can get the download URL
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        console.log('File available at', downloadURL);

                        self.item[field] = downloadURL;
                        // setTimeout(function() {
                        //     self.item.item[field] = downloadURL;
                        // }, 1000);

                    });
                }
            );
            // mountainsRef.put(getFile).on('state_changed',
            //     function progress(snapshot){
            //
            //         let percentage = (snapshot.bytesTransferred / snapshot.totalBytes) *100;
            //         console.log("percentage " + percentage);
            //         uploader.value = percentage;
            //     },
            //     function error(err){
            //         console.log(err);
            //     },
            //     function complete(){
            //         console.log('complete upload');
            //         self.$data.item[field] = 'https://storage.googleapis.com/ncm-mary-254508.appspot.com/' + path;
            //     }
            // );

        },

        downloadForm: function() {
            const FileDownload = require('js-file-download');
            let self = this;
            getBlob("admin/shop/form/excel")
            .then(function(response) {
                FileDownload(response.data, "shop-product-form.xlsx");
            })
            .catch(function(err) {

            })
        },
        getProductListExcel: function() {
            const FileDownload = require('js-file-download');
            let self = this;
            getBlob("admin/shop/" + this.$data.currentPlaceId + "/products/excel")
            .then(function(response) {
                FileDownload(response.data, "shop-product-list.xlsx");
            })
            .catch(function(err) {

            })
        },
        getPlaceLabel: function() {
            // if(this.$data.selectedAssetId === 0) {
            //     showDialog("Asset을 선택하세요");
            //     return;
            // }

            const FileDownload = require('js-file-download');
            let self = this;
            let url = "admin/shop/" + this.$data.currentPlaceId + "/label";

            // if(this.$data.selectedAssetId > 0) {
            //     url += "?assetId=" + this.$data.selectedAssetId;
            // }

            // if(type !== null) {
            //     url += "?type=asset";
            // }
            let fileName = "";
            for(let i = 0 ; i < this.$data.placeItems.length ; i++) {
                if(this.$data.placeItems[i].id === this.$data.currentPlaceId) {
                    fileName += this.$data.placeItems[i].name;
                    break;
                }
            }
            let date = new Date();
            fileName += "-Label-" + date.getTime();
            getBlob(url)
                .then(function(response) {
                    FileDownload(response.data, fileName +".pdf");
                })
                .catch(function(err) {
                    console.log(err);
                    if(err.response !== undefined) {
                        console.log(err.response);
                    }

                })
        },
        getPlaceLabel2: function() {
            // if(this.$data.selectedAssetId === 0) {
            //     showDialog("Asset을 선택하세요");
            //     return;
            // }

            const FileDownload = require('js-file-download');
            let self = this;
            let url = "admin/shop/" + this.$data.currentPlaceId + "/label2";

            // if(this.$data.selectedAssetId > 0) {
            //     url += "?assetId=" + this.$data.selectedAssetId;
            // }

            // if(type !== null) {
            //     url += "?type=asset";
            // }
            let fileName = "";
            for(let i = 0 ; i < this.$data.placeItems.length ; i++) {
                if(this.$data.placeItems[i].id === this.$data.currentPlaceId) {
                    fileName += this.$data.placeItems[i].name;
                    break;
                }
            }
            let date = new Date();
            fileName += "-Label-v2-" + date.getTime();
            getBlob(url)
                .then(function(response) {
                    FileDownload(response.data, fileName +".pdf");
                })
                .catch(function(err) {
                    console.log(err);
                    if(err.response !== undefined) {
                        console.log(err.response);
                    }

                })
        },
        getPlaceCategoryLabel2: function(category) {
            // if(this.$data.selectedAssetId === 0) {
            //     showDialog("Asset을 선택하세요");
            //     return;
            // }

            const FileDownload = require('js-file-download');
            let self = this;
            let url = "admin/shop/" + this.$data.currentPlaceId + "/category/" + category.id + "/label";

            // if(this.$data.selectedAssetId > 0) {
            //     url += "?assetId=" + this.$data.selectedAssetId;
            // }

            // if(type !== null) {
            //     url += "?type=asset";
            // }
            let fileName = "Category-";
            for(let i = 0 ; i < this.$data.placeItems.length ; i++) {
                if(this.$data.placeItems[i].id === this.$data.currentPlaceId) {
                    fileName += this.$data.placeItems[i].name + '-' + category.title;
                    break;
                }
            }
            let date = new Date();
            fileName += "-Label-v2-" + date.getTime();
            getBlob(url)
                .then(function(response) {
                    FileDownload(response.data, fileName +".pdf");
                })
                .catch(function(err) {
                    console.log(err);
                    if(err.response !== undefined) {
                        console.log(err.response);
                    }

                })
        },
        getProductsLabel: function(type) {
            // if(this.$data.selectedAssetId === 0) {
            //     showDialog("Asset을 선택하세요");
            //     return;
            // }
            let ids = [];
            for(let i = 0 ; i < this.$data.products.length ; i++) {
                if(this.$data.products[i].selected === true) {
                    ids.push(this.$data.products[i].id)
                }
            }
            if(ids.length === 0) {
                showDialog("상품을 선택하세요");
                return;
            }
            const FileDownload = require('js-file-download');
            let self = this;
            let url = "admin/shop/" + this.$data.currentPlaceId + "/products/label";

            // if(this.$data.selectedAssetId > 0) {
            //     url += "?assetId=" + this.$data.selectedAssetId;
            // }

            if(type !== null) {
                url += "?type=asset";
            }

            postBlob(url, ids)
                .then(function(response) {
                    FileDownload(response.data, "shop-product-label.pdf");
                })
                .catch(function(err) {
                    console.log(err);
                    if(err.response !== undefined) {
                        console.log(err.response);
                    }

                })
        },
        getProductsLabel2: function(type) {
            // if(this.$data.selectedAssetId === 0) {
            //     showDialog("Asset을 선택하세요");
            //     return;
            // }
            let ids = [];
            for(let i = 0 ; i < this.$data.products.length ; i++) {
                if(this.$data.products[i].selected === true) {
                    ids.push(this.$data.products[i].id)
                }
            }
            if(ids.length === 0) {
                showDialog("상품을 선택하세요");
                return;
            }
            const FileDownload = require('js-file-download');
            let self = this;
            let url = "admin/shop/" + this.$data.currentPlaceId + "/products/label2";

            // if(this.$data.selectedAssetId > 0) {
            //     url += "?assetId=" + this.$data.selectedAssetId;
            // }

            if(type !== null) {
                url += "?type=asset";
            }

            postBlob(url, ids)
                .then(function(response) {
                    FileDownload(response.data, "shop-product-label-v2.pdf");
                })
                .catch(function(err) {
                    console.log(err);
                    if(err.response !== undefined) {
                        console.log(err.response);
                    }

                })
        },
        get_header_row: function (sheet) {
            var headers = [], range = XLSX.utils.decode_range(sheet['!ref']);
            //range.s.r = 2;//XLSX.utils.decode_row("3");
            var C, R = range.s.r; /* start in the first row */
            for (C = range.s.c; C <= range.e.c; ++C) { /* walk every column in the range */
                var cell = sheet[XLSX.utils.encode_cell({c: C, r: R})] /* find the cell in the first row */
                var hdr = "UNKNOWN " + C; // <-- replace with your desired default
                if (cell && cell.t) hdr = XLSX.utils.format_cell(cell);
                headers.push(hdr);
            }
            return headers;
        },
        fixdata: function (data) {
            var o = "", l = 0, w = 10240;
            for (; l < data.byteLength / w; ++l) o += String.fromCharCode.apply(null, new Uint8Array(data.slice(l * w, l * w + w)));
            o += String.fromCharCode.apply(null, new Uint8Array(data.slice(l * w)));
            return o;
        },
        onFileSelectorClick: function() {
            this.$refs.file.value = "";
            this.$refs.file.click()
        },
        addFilePickedUp: function(e) {
            let self = this;
            if (e.target != undefined) {
                if (e.target.files != undefined) {
                    let droppedFiles = e.target.files;
                    ([...droppedFiles]).forEach(f => {
                        let reader = new FileReader(),
                            name = f.name;

                        reader.onload = function (e) {
                            let results;
                            let data = e.target.result;


                            let fixedData = self.fixdata(data),
                                workbook = XLSX.read(btoa(fixedData), {type: 'base64'}),
                                firstSheetName = workbook.SheetNames[0],
                                worksheet = workbook.Sheets[firstSheetName];
                            console.log("XLS read ");
                            console.log(workbook);
                            self.$data.state.headers = self.get_header_row(worksheet);
                            results = XLSX.utils.sheet_to_json(worksheet, {range: 0, defval: ""});

                            //self.$data.state.tickets=results;
                            console.log("Result");
                            console.log(results);
                            let list = [];

                            for (let i = 1; i < results.length; i++) {
                                if(results[i].title === null || results[i].title.length === 0) {
                                    break;
                                }

                                let item = {
                                    id:0,
                                    placeId:self.$data.currentPlaceId,
                                    title:results[i].title,
                                    price:parseInt(results[i].price),
                                    originalPrice:parseInt(results[i].originalPrice),
                                    commission:parseInt(results[i].commission),
                                    originCountry:results[i].originCountry,
                                    manufacturer: results[i].manufacturer,
                                }

                                list.push(item);
                            }
                            // for(let i = 0 ; i < list.length ; i++) {
                            //   list[i].list = JSON.stringify(list[i].list);
                            // }
                            // return;
                            self.createList(list);
                        }

                        reader.readAsArrayBuffer(f);
                    });
                }
            }
        },
        createList: function(list) {
            if(list === null || list.length === 0) {
                return;
            }

            let self = this;
            updatePlaceShopProducts(this.$data.currentPlaceId, list)
            .then(function(data) {
                if(data.success === true) {
                    self.$data.products = self.$data.products.concat(data.list);
                }
            })
            .catch(function(err) {

            })
        },
        stockDateRangeSelected: function() {
            console.log("dateRangeSelected")
            console.log(this.$data.stockDateRange)

            if(this.$data.stockDateRange.length !== 2) {
                return;
            }

            if(this.$data.stockDateRange[0] > this.$data.stockDateRange[1]) {
                let temp = this.$data.stockDateRange[1];
                this.$data.stockDateRange[0] = this.$data.stockDateRange[1];
                this.$data.stockDateRange[1] = temp;
            }

        },
        dateRangeSelected: function() {
            console.log("dateRangeSelected")
            console.log(this.$data.dateRange)

            if(this.$data.dateRange.length !== 2) {
                return;
            }

            if(this.$data.dateRange[0] > this.$data.dateRange[1]) {
                let temp = this.$data.dateRange[1];
                this.$data.dateRange[0] = this.$data.dateRange[1];
                this.$data.dateRange[1] = temp;
            }

        },
        updateCommission: function() {
            let from = new Date(this.$data.dateRange[0]);
            from.setHours(0,0,0,0);

            let to = new Date(this.$data.dateRange[1]);
            to.setHours(23.59,59,999);

            let self = this;
            applyNewCommission(this.$data.currentPlaceId, from.getTime(), to.getTime(), 'market')
                .then(function(data) {
                    if(data.success === true) {
                        self.getMarketSales();
                    } else {
                        showDialog(data.message);
                    }
                })
                .catch(function(err) {
                    console.log(err);
                    if(err.response !== undefined) {
                        console.log(err.response);
                    }
                    showDialog(err, "ERROR");
                })
        },
        recalculateTax: function(id) {
            let self = this;
            correctVatStatements(id)
                .then(function(data) {
                    if(data.success === true) {
                        for(let i = 0 ; i < self.$data.statements.length ; i++) {
                            if(self.$data.statements[i].id === data.item.id) {
                                self.$data.statements.splice(i, 1, data.item);
                            }
                        }
                    }
                })
                .catch(function(err) {
                    console.log(err);
                    if (err.response !== undefined) {
                        console.log(err.response);
                    }
                })
        },
        getStatementList: function() {
            let from = this.$data.dateRange[0];


            let to = this.$data.dateRange[1];

            let self = this;
            if(this.$data.userRole >= 99) {


                getStatements(from, to, this.$data.currentPlaceId)
                    .then(function (data) {
                        console.log(data);
                        if (data.success === true) {
                            self.$data.statements = data.item;
                        }
                    })
                    .catch(function (err) {
                        console.log(err);
                        if (err.response !== undefined) {
                            console.log(err.response);
                        }
                    })
            } else {
                getPlaceStatements(from, to, this.$data.currentPlaceId)
                    .then(function (data) {
                        console.log(data);
                        if (data.success === true) {
                            self.$data.statements = data.item;
                        }
                    })
                    .catch(function (err) {
                        console.log(err);
                        if (err.response !== undefined) {
                            console.log(err.response);
                        }
                    })
            }
        },
        getStatementItemList: function() {
            let from = new Date(this.$data.dateRange[0]);
            from.setHours(0,0,0,0);

            let to = new Date(this.$data.dateRange[1]);
            to.setHours(23,59,999);

            this.$data.salesStatement = false;
            let self = this;

            getStatementItems(from.getTime(), to.getTime(), this.$data.currentPlaceId)
                .then(function(data) {
                    if(data.success === true) {
                        self.$data.salesStatement = data.item;
                    } else {
                        showDialog(data.message);
                    }
                })
                .catch(function(err) {
                    console.log(err);
                    if(err.response !== undefined) {
                        console.log(err.response);
                    }
                })
        },
        getMarketSales: function() {
            let from = new Date(this.$data.dateRange[0]);
            from.setHours(0,0,0,0);

            let to = new Date(this.$data.dateRange[1]);
            to.setHours(23,59,59, 999);

            let self = this;

            this.$data.report = false;
            this.$data.selectReport = false;
            getMarkerSales(this.$data.currentPlaceId, from.getTime(), to.getTime(), 'market')
            .then(function(data) {
                if(data.success === true) {
                    self.$data.report = data.item;
                } else {
                    showDialog(data.message);
                }
            })
            .catch(function(err) {
                console.log(err);
                if(err.response !== undefined) {
                    console.log(err.response);
                }
                showDialog(err, "ERROR");
            })

        },
        closePlaceAsset: function() {
            this.$data.assetItem = false;
        },
        openPlaceAsset: function(id) {
            if(this.$data.currentPlaceId === 0) {
                showDialog("매장을 먼저 선택하세요");
                return;
            }

            let self = this;
            getPlaceAsset(this.$data.currentPlaceId, id)
            .then(function(data) {
                if(data.success === true) {
                    self.$data.assetItem = data.item;
                } else {
                    showDialog(data.message);
                }
            })
            .catch(function(err) {
                showDialog(err, "ERROR");
            })
        },
        deletePlaceAsset: function(id) {
            let self = this;
            console.log("delete place asset ask");
            this.$modal.show('dialog',{
                    text: this.$data.assetItem.title + '을 삭제 하시겠습니까?',
                    buttons: [
                        {
                            title:'아니요',
                            handler:()=>{
                                self.$modal.hide('dialog');
                            }
                        },
                        {
                            title:'삭제하기',
                            handler:() => {
                                self.$modal.hide('dialog');
                                self.deletePlaceAssetConfirmed(id);
                            }
                        }
                    ]
                }

            );
        },
        deletePlaceAssetConfirmed: function(id) {
            let self = this;
            deletePlaceAsset(this.$data.assetItem.placeId, this.$data.assetItem.id)
            .then(function(data) {
                if(data.success === true) {
                    for(let i = 0 ; i < self.$data.assets.length ; i++) {
                        if(self.$data.assets[i].id === data.item.id) {
                            self.$data.assets.splice(i, 1);
                            break;
                        }
                    }
                    self.$data.assetItem = false;
                } else {
                    showDialog(data.message);
                }
            })
            .catch(function(err) {
                let msg = err;
                if(err.response !== undefined) {
                    msg += ' (' + err.response + ')';
                }
                showDialog(msg, "ERROR");
            })
        },
        addPlaceAsset: function() {
          if(this.$data.currentPlaceId === 0) {
              showDialog("매장을 선택하세요");
              return;
          }
          this.$data.assetItem = newPlaceAsset(this.$data.currentPlaceId);
        },
        changeToAssetMode: function() {
          this.$data.mode = 6;
          this.getPlaceAssetList();
        },
        getPlaceAssetList: function() {
            this.$data.assets = [];
            if(this.$data.currentPlaceId === 0) {
                return;
            }

            let self = this;
            getPlaceAssets(this.$data.currentPlaceId)
            .then(function(data) {
                if(data.success === true) {
                    self.$data.assets = data.item;
                } else {
                    // showDialog(data.message);
                }
            })
            .catch(function(err) {
                showDialog(err);
            })
        },
        updatePlaceAsset: function() {
            if(this.$data.assetItem.title === null || this.$data.assetItem.title.length === 0) {
                showDialog("이름을 입력해주세요");
                return;
            }

            let self = this;

            if(this.$data.assetItem.assetType === 'door' || this.$data.assetItem.assetType === 'timer') {
                if (this.$data.assetItem.startHour === -1 && this.$data.assetItem.endHour >= 0) {
                    showDialog("24시간 영업일경우, 종료시간도 24시간 영업을 선택해주세요");
                    return;
                }
                if (this.$data.assetItem.startHour > -1 && this.$data.assetItem.endHour === -1) {
                    showDialog("24시간 영업일경우, 시작시간도 24시간 영업을 선택해주세요");
                    return;
                }
            }

            updatePlaceAsset(this.$data.currentPlaceId, this.$data.assetItem)
            .then(function(data) {
                if(data.success === true) {
                    let found = false;
                    for(let i = 0 ; i < self.$data.assets.length ; i++) {
                        if(data.item.id === self.$data.assets[i].id) {
                            self.$data.assets.splice(i, 1, data.item);
                            found = true;
                            break;
                        }
                    }
                    if(found === false) {
                        self.$data.assets.push(data.item);
                    }
                    self.$data.assetItem = false;
                } else {
                    showDialog(data.message);
                }
            })
            .catch(function(err) {
                showDialog(err);
            })
        },
        openFridge: function(placeId, id) {
            openFridgeRelay(placeId, id)
            .then(function(data) {
                if(data.success === true) {
                    showDialog("냉장고 열기 command를 보냈습니다.");
                } else {
                    showDialog(data.message, "실패");
                }
            })
            .catch(function(err) {
                let msg = err;
                if(err.response !== undefined) {
                    msg += ' (' + err + ')';
                }
                showDialog(msg, "ERROR");
            })
        },
        openGate: function(placeId, id) {
            openGateRelay(placeId, id)
            .then(function(data) {
                if(data.success === true) {
                    showDialog("문 열기 command를 보냈습니다.");
                } else {
                    showDialog(data.message, "실패");
                }
            })
            .catch(function(err) {
                let msg = err;
                if(err.response !== undefined) {
                    msg += ' (' + err + ')';
                }
                showDialog(msg, "ERROR");
            })
        },
        toggleTime: function(placeId, id, onOff) {
            toggleTimerRelay(placeId, id, onOff)
            .then(function(data) {
                if(data.success === true) {
                    if(onOff) {
                        showDialog("timer 켜기 command 보냈습니다.");
                    } else {
                        showDialog("timer 끄기 command 보냈습니다.");
                    }
                } else {
                    showDialog(data.message, "실패");
                }
            })
            .catch(function(err) {
                let msg = err;
                if(err.response !== undefined) {
                    msg += ' (' + err + ')';
                }
                showDialog(msg, "ERROR");
            })
        },


        openPlaceAssetLog: function(item) {
            this.$data.assetLogItem = item;
        },
        closePlaceAssetLog: function() {
            this.$data.assetLogItem = false;
        },


        startRefund: function() {
            if(this.$data.salesOrderItem === null || this.$data.salesOrderItem === false) {
                return;
            }
            let self = this;
            this.$modal.show('dialog',{
                    text: this.$data.salesOrderItem.userName + " " + this.$data.salesOrderItem.paid + '원 결제 취소 하시겠습니까?',
                    buttons: [
                        {
                            title:'아니요',
                            handler:()=>{
                                self.$modal.hide('dialog');
                            }
                        },
                        {
                            title:'환불처리하기',
                            handler:() => {
                                self.$modal.hide('dialog');
                                setTimeout(self.processRefund, 1000);
                                // self.processRefund();
                            }
                        }
                    ]
                }

            );
        },
        showMessage: function(message, title = "") {
            this.$modal.show('dialog',{
                    title:title,
                    text: message,

                }

            );
        },
        processRefund: function() {
            let self = this;
            console.log("Start Refund " + this.$data.salesOrderItem.placeId + " " + this.$data.salesOrderItem.id);
            posOrderRefund(this.$data.salesOrderItem.placeId, this.$data.salesOrderItem.id)
            .then(function(data) {

                console.log(data);
                if(data.success == true) {
                    showDialog( data.item.paid + "원 환불처리 완료 되었습니다.");
                    self.$data.salesOrderItem.refundOrder = data.item;
                } else {
                    showDialog(data.message);
                }
            })
            .catch(function(err) {
                console.log(err);
                let msg = "환불 처리 에러 " + err;
                if(err.response != undefined) {
                    msg += ' (' + err.response + ')';
                    console.log(err.response);
                }
                showDialog(msg);
            })
        },


        clearOrderItem: function(product) {
            if(this.$data.placeOrder === false) {
               return;
            }

            if(this.$data.placeOrder.items.length > 0) {
                for(let i = 0 ; i < this.$data.placeOrder.items.length ; i++) {
                    if(this.$data.placeOrder.items[i].productId === product.id) {
                        this.$data.placeOrder.items.splice(i, 1);
                        this.recalculatePlaceOrder();
                        return;
                    }
                }
            }
        },
        getBasketProductCount: function(id) {
            if(this.$data.placeOrder === false) {
                return '0';
            }
            if(this.$data.placeOrder.items.length > 0) {
                for (let i = 0; i < this.$data.placeOrder.items.length; i++) {
                    if (this.$data.placeOrder.items[i].productId === id) {
                        return this.$data.placeOrder.items[i].amount;
                    }
                }
            }
            return '0';
        },
        addOrderItem: function(placeId, product) {
            if(this.$data.placeOrder === false) {
                this.$data.placeOrder = newPlaceOrder(placeId,0);
            }

            if(this.$data.placeOrder.items.length > 0) {
                for(let i = 0 ; i < this.$data.placeOrder.items.length ; i++) {
                    if(this.$data.placeOrder.items[i].productId === product.id) {
                        this.$data.placeOrder.items[i].amount++;
                        this.recalculatePlaceOrder();
                        return;
                    }
                }
            }

            let item = newPlaceOrder(placeId, product.id);
            item.unitPrice = product.price;
            item.amount = 1;
            // item.destId = product.id;
            item.totalPrice = product.price;
            item.title = product.title;
            item.tax = item.totalPrice/10;
            item.total = item.totalPrice + item.tax;
            item.unit = product.unit;
            item.amountPerUnit = product.amountPerUnit;
            this.$data.placeOrder.items.push(item);
            this.recalculatePlaceOrder();
        },
        addPlaceOrderItem: function(placeId, product) {


            if(this.$data.placeOrder === false) {
                this.$data.placeOrder = newPlaceOrder(placeId,0);
            }

            if(product.parentId > 0 && this.$data.placeOrder.items.length > 0) {
                for(let i = 0 ; i < this.$data.placeOrder.items.length ; i++) {
                    if(this.$data.placeOrder.items[i].productId === product.parentId) {
                        this.$data.placeOrder.items[i].amount++;
                        this.recalculatePlaceOrder();
                        return;
                    }
                }
            }
            let self = this;
            getProductForOrder(placeId, product.id)
            .then(function(data) {
                if(data.success === true) {
                    let item = newPlaceOrder(placeId, data.item.id);
                    item.unitPrice = data.item.price;
                    item.amount = 1;
                    // item.destId = product.id;
                    item.totalPrice = data.item.price;
                    item.title = data.item.title;
                    item.tax = item.totalPrice/10;
                    item.total = item.totalPrice + item.tax;
                    item.unit = data.item.unit;
                    item.amountPerUnit = data.item.amountPerUnit;
                    self.$data.placeOrder.items.push(item);
                    self.recalculatePlaceOrder();
                } else {
                    showDialog(data.message);
                }
            })
            .catch(function(err) {
                let msg = err;
                if(err.response !== undefined) {
                    msg += ' (' + err.response + ')';
                }
                showDialog(msg, "ERROR");
            })

        },
        recalculatePlaceOrder: function() {
            let totalPrice = 0;
            let totalTax = 0;
            for(let i = 0 ; i < this.$data.placeOrder.items.length ; i++ ) {
                this.$data.placeOrder.items[i].totalPrice = this.$data.placeOrder.items[i].amount * this.$data.placeOrder.items[i].unitPrice;
                totalPrice += this.$data.placeOrder.items[i].totalPrice;
                this.$data.placeOrder.items[i].tax = this.$data.placeOrder.items[i].totalPrice/10;
                totalTax += this.$data.placeOrder.items[i].tax;
                this.$data.placeOrder.items[i].total = this.$data.placeOrder.items[i].totalPrice + this.$data.placeOrder.items[i].tax;
            }

            this.$data.placeOrder.totalPrice = totalPrice;
            this.$data.placeOrder.tax = totalTax;
            this.$data.placeOrder.total = this.$data.placeOrder.totalPrice + this.$data.placeOrder.tax;
        },
        removePlaceOrderItem: function(index) {
            this.$data.placeOrder.items.splice(index, 1);
            this.recalculatePlaceOrder();
        },
        showBasketView: function() {
          if(this.$data.placeOrder === false) {
              return;

          }

          this.$data.showBasket = true;
        },
        submitPlaceOrder: function() {
            if(this.$data.placeOrder === false) {
                return;
            }

            if(this.$data.placeOrder.id !== 0) {
                showDialog("이미 접속 및 진행된 주문입니다.");
                return;
            }

            let self = this;
            submitPlaceOrder(this.$data.placeOrder.placeId, this.$data.placeOrder)
            .then(function(data) {
                if(data.success) {
                    self.$data.placeOrder = false;
                    self.$data.showBasket = false;
                    showDialog("주문이 성공적으로 접수되었습니다.");
                    self.onUpdatePlaceOrderItem(data.item);
                } else {
                    showDialog(data.message);
                }
            })
            .catch(function(err) {
                let msg = err;
                console.log(err);
                if(err.response !== undefined) {
                    msg += ' (' + err.response + ')';
                    console.log(err.response);
                }
                showDialog(msg, "ERROR");
            })
        },
        clearPlaceOrder: function() {
          this.$data.placeOrder = false;
          this.$data.showBasket = false;
        },
        openCardView: function() {
            if(this.$data.currentPlaceId === 0) {
                showDialog("매장을 선택해주세요");
                return;
            }
            for(let i = 0 ; i < this.$data.placeItems.length ; i++) {
                if(this.$data.placeItems[i].id === this.$data.currentPlaceId) {
                    this.$data.placeCardView = this.$data.placeItems[i];
                    return;
                }
            }
        },
        closeCardView: function() {
            this.$data.placeCardView = false;
        },
        openPlaceOrderViewer: function() {
          if(this.$data.currentPlaceId === 0) {
              showDialog("매장을 선택해주세요");
              return;
          }
          for(let i = 0 ; i < this.$data.placeItems.length ; i++) {
              if(this.$data.placeItems[i].id === this.$data.currentPlaceId) {
                  this.$data.placeOrderView = this.$data.placeItems[i];
                  return;
              }
          }
          showDialog("매장 정보가 없습니다. 페이지 새로고침후 다시 시도해주세요");
        },
        onDateSelected: function() {
          if(this.$data.dates === null) {
              return;
          }

          if(this.$data.dates[0] > this.$data.dates[1]) {
              let tempDate = this.$data.dates[0];
              this.$data.dates[0] = this.$data.dates[1];
              this.$data.dates[1] = tempDate;
          }
        },
        getPlaceOrderListByTime: function() {
            if(this.$data.dates === null || this.$data.dates.length !== 2) {
                showDialog("기간을 선택해주세요")
            }

            let fromDate = new Date(from);
            fromDate.setHours(0,0,0, 0);

            let toDate = new Date(to);
            toDate.setHours(23, 59, 59, 999);

            this.$data.placeOrders = [];

            let self = this;
            let query = "placeId=" + this.$data.currentPlaceId + "&timeFrom=" + fromDate.getTime() + "&timeTo=" + toDate.getTime();
            getPlaceOrderList(query)
            .then(function (data) {
                if(data.success === true) {
                    self.$data.placeOrders = data.item;
                } else {
                    showDialog(data.message);
                }
            })
            .catch(function(err) {
                let msg = err;
                if(err.response !== undefined) {
                    msg += ' (' + err.response + ')';
                }
                showDialog(msg, "ERROR");
            })


        },
        getPlaceOrderListByState: function(from, to) {
            if(this.$data.currentPlaceId === 0) {
                showDialog("매장을 선택하세요");
                return;
            }
            this.$data.placeOrders = [];
            let self = this;
            let query = 'placeId=' + this.$data.currentPlaceId + '&stateFrom=' + from  + '&stateTo=' + to;
            getPlaceOrderList(query)
            .then(function(data) {
                if(data.success === true) {
                    self.$data.placeOrders = data.item;
                } else {
                    showDialog(data.message);
                }
            })
            .catch(function(err) {
                let msg = err;
                if(err.response !== undefined) {
                    msg += ' (' + err.response + ')';
                }
                showDialog(msg, "ERROR");
            })
        },
        getHqOrderListByTime: function() {
            if(this.$data.dates === null || this.$data.dates.length !== 2) {
                showDialog("기간을 선택해주세요")
            }

            let fromDate = new Date(this.$data.dates[0]);
            fromDate.setHours(0,0,0, 0);

            let toDate = new Date(this.$data.dates[1]);
            toDate.setHours(23, 59, 59, 999);

            this.$data.hqOrders = [];

            let self = this;
            let query = "timeFrom=" + fromDate.getTime() + "&timeTo=" + toDate.getTime();
            getPlaceOrderList(query)
            .then(function (data) {
                if(data.success === true) {
                    self.$data.hqOrders = data.item;
                } else {
                    showDialog(data.message);
                }
            })
            .catch(function(err) {
                let msg = err;
                if(err.response !== undefined) {
                    msg += ' (' + err.response + ')';
                }
                showDialog(msg, "ERROR");
            })
        },
        getHqOrderListByState: function(from, to) {
            this.$data.placeOrders = [];
            let self = this;
            let query = 'stateFrom=' + from  + '&stateTo=' + to;
            if(this.$data.currentPlaceId > 0) {
                query += '&placeId=' + this.$data.currentPlaceId;
            }
            getPlaceOrderList(query)
            .then(function(data) {
                if(data.success === true) {
                    self.$data.hqOrders = data.item;
                } else {
                    showDialog(data.message);
                }
            })
            .catch(function(err) {
                let msg = err;
                if(err.response !== undefined) {
                    msg += ' (' + err.response + ')';
                }
                showDialog(msg, "ERROR");
            })
        },
        openHqOrder: function(placeId, id) {
            let self = this;

            getPlaceOrder(placeId, id)
            .then(function(data) {
                if(data.success === true) {
                    self.$data.hqOrder = data.item;
                } else {
                    showDialog(data.message);
                }
            })
            .catch(function(err) {
                let msg = err;
                if(err.response !== undefined) {
                    msg += ' (' + err.response + ')';
                }
                showDialog(msg, "ERROR");
            })
        },
        onUpdatePlaceOrderItem: function(order) {
          if(this.$data.hqOrders.length > 0) {
              for(let i = 0 ; i < this.$data.hqOrders.length ; i++) {
                  if(this.$data.hqOrders[i].id === order.id) {
                      this.$data.hqOrders.splice(i, 1, order);
                      break;
                  }
              }
          }
            if(this.$data.placeOrders.length > 0) {
                for(let i = 0 ; i < this.$data.placeOrders.length ; i++) {
                    if(this.$data.placeOrders[i].id === order.id) {
                        this.$data.placeOrders.splice(i, 1, order);
                        break;
                    }
                }
            }
        },
        processHqOrder: function(id, state) {
            let self = this;
            adminProcessPlaceOrder(id, state)
            .then(function(data) {
                if(data.success === true) {
                    self.$data.hqOrder = data.item;

                    self.onUpdatePlaceOrderItem(data.item);
                }
            })
            .catch(function(err) {
                let msg = err;
                if(err.response !== undefined) {
                    msg += ' (' + err.response + ')';
                }
                showDialog(msg, "ERROR");
            })
        },
        processHqOrderItem: function(index, state) {
            this.$data.hqOrder.items[index].state = state;
            this.updateHqOrder();
        },
        recalculateHqOrder: function() {
            if(this.$data.hqOrder === false) {
                return;
            }

            let totalPrice = 0;
            for(let i = 0 ; i < this.$data.hqOrder.items.length ; i++) {
                this.$data.hqOrder.items[i].totalPrice = this.$data.hqOrder.items[i].unitPrice * this.$data.hqOrder.items[i].amount;
                if(this.$data.hqOrder.items[i].state >= 0) {
                    totalPrice = this.$data.hqOrder.items[i].totalPrice;
                }
            }

            this.$data.hqOrder.totalPrice = totalPrice;

        },
        updateHqOrder: function() {
          if(this.$data.hqOrder === false) {
              return;
          }

          this.recalculateHqOrder();
          let self = this;
          updatePlaceOrder(this.$data.hqOrder.placeId, this.$data.hqOrder)
            .then(function(data) {
                if(data.success) {
                    self.$data.hqOrder = data.item;
                    self.onUpdatePlaceOrderItem(data.item);
                } else {
                    showDialog(data.message);
                }
            })
            .catch(function(err) {
                let msg = err;
                if(err.response !== undefined) {
                    msg += ' (' + err.response + ')';
                }
                showDialog(msg, "ERROR");
            })
        },

        onMessageArrived: function (topic, msg, packet) {

            // let topic = msg.destinationName;
            console.log("msg received from " + topic);
            if(topic !== null && topic.length > 0) {
                let topicWords = topic.split('/');
                if(topicWords !== null && topicWords.length > 0) {
                    let channel = topicWords[topicWords.length - 1];
                    if(this.$data.currentPlaceId !== parseInt(channel)) {
                        this.$data.mqtt.unsubscribe(topic);
                        return;
                    }
                }
            }

            let msgStr = msg.payloadString;
            console.log(msgStr);
            let item = JSON.parse(msgStr);

            for(let i = 0 ; i < this.$data.assets.length ; i++) {
                if(this.$data.assets[i].id === item.id) {
                    this.$data.assets[i].status = item.status;
                    this.$data.assets[i].createdTime = item.createdTime;
                    return;
                }
            }
        },
        subscribeChannel: function() {
            //Should be changed according to current user
            if(this.$data.mqttConnected && this.$data.currentPlaceId > 0) {
                this.$data.mqttClient.subscribe('admin/placeAsset/' + this.$data.currentPlaceId);
            }
        },
        /**
         * Callback when MQTT is connected
         * Now should subscribe maryBikes/(operatorId)
         */
        onConnect: function () {
            console.log("Connected subscribe maryapp");
            this.$data.mqttConnected = true;
            this.subscribeChannel();
        },
        /**
         * When conneciton is lost, should reconnect manually.
         * Reconnect option does not really reconnect when switching from WIFI to mobile network
         */
        onConnectionLost: function(resObj) {
            console.log("connection lost");
            // this.$data.mqttConnected = false;
            // this.$data.mqtt = null;
            console.log("Lost connection to " + resObj.uri + "\nError code: " + resObj.errorCode + "\nError text: " + resObj.errorMessage);
            console.log("Now try again");
            this.$data.mqttConnected = false;
            if(this.$data.disconnectMqtt == false) {
                setTimeout(this.connect, 1000);
            }
        },
        /**
         * Callback when MQTT connection failed
         * @param message
         */
        onMqttFailure: function (message) {
            console.log("MQTT failed " + message);
            console.log(message);
            console.log("Attempt to reconnect in 1 second");

            this.$data.mqttConnected = false;
            setTimeout(this.connect, 1000);
        },
        disconnect: function() {
            // if(this.$data.mqtt == null || this.$data.mqtt.isConnected() == false) {
            //     return;
            // }
            // this.$data.mqtt.disconnect();
            if(this.$data.mqttClient == null) {
                return;
            }
            this.$data.mqttClient.end();
        },
        connect: function () {
            // if(this.$data.mqttClient == null) {
            //     let options = {
            //         clientId:'maryApp-' + new Date().getTime(),
            //         username:'maryapp',
            //         password:'app23344',
            //         clean: true,
            //     }
            //     this.$data.mqttClient = mqtt.connect('mqtt://mqtt.marysharing.com', options);
            //     this.$data.mqttClient.on("connect", this.onConnect);
            //     this.$data.mqttClient.on("error", this.onMqttFailure);
            //     this.$data.mqttClient.on("message", this.onMessageArrived);
            // }
            // if(this.$data.mqtt == null || this.$data.mqtt.isConnected() == false) {
            //
            //     console.log("MQTT is not connected so attempt to connect now");
            //     this.$data.mqtt = new Paho.Client("mqtt.marysharing.com", Number(9001), 'maryApp-' + new Date().getTime());
            //     // this.$data.mqtt.disconnect();
            //     this.$data.mqtt.onConnectionLost = this.onConnectionLost;
            //     let options = {
            //         timeout: 3,
            //         useSSL: true,
            //         userName: "maryapp",
            //         password: "app23344",
            //         onSuccess: this.onConnect,
            //         onFailure: this.onMqttFailure,
            //         cleanSession: true, //Clean session
            //         // reconnect : true, // Enable automatic reconnect
            //         // reconnectInterval:1, //Reconnect attempt interval : 1 second
            //     };
            //     this.$data.mqtt.connect(options); //connect
            // } else {
            //     console.log("Already connected");
            //     this.$data.mqttConnected = true;
            // }
            // if(this.$data.mqtt.onMessageArrived === null || this.$data.mqtt.onMessageArrived !== this.onMessageArrived) {
            //     this.$data.mqtt.onMessageArrived = this.onMessageArrived;
            // } else {
            //     console.log("message arrive handler Already registered");
            // }

        },

        openSalesOrder: function(id) {

            let self = this;
            getMakerSalesOrder(this.$data.currentPlaceId, id)
                .then(function(data) {
                    if(data.success === true) {
                        self.$data.salesOrderItem = data.item;
                    } else {
                        showDialog(data.message);
                    }
                })
                .catch(function(err) {
                    let msg = err;
                    if(err.response !== undefined) {
                        msg += ' (' + err.response + ')';
                    }
                    showDialog(msg);
                })
        },

        selectAllOrders: function() {
            if(this.$data.report === false) {
                return;
            }
            let isAllSelected = false;
            let selectedCount = 0;
            for(let i = 0 ; i < this.$data.report.orders.length ; i++) {
                if(this.$data.report.orders[i].selected === true) {
                    selectedCount += 1;
                }
            }

            if(selectedCount === this.$data.report.orders.length) {
                isAllSelected = true;
            }


            console.log('currently selected all ' + isAllSelected);
            for(let i = 0 ; i < this.$data.report.orders.length ; i++) {
                if(this.$data.report.orders[i].statementId > 0) {
                    this.$data.report.orders[i].selected = false;
                } else {
                    this.$data.report.orders[i].selected = !isAllSelected;
                }

            }

        },
        onSalesItemSelected: function(index) {
            if(this.$data.report === false) {
                return;
            }
            console.log("clicked on check");
            if(this.$data.report.orders[index].statementId > 0) {
                this.$data.report.orders[index].selected = false;
                showDialog("이미 정산된 항목입니다.");
                return;
            }
            this.$data.report.orders[index].selected = !this.$data.report.orders[index].selected;
        },
        onStatementItemChanged: function(key, index) {

            let query = key + '=' + this.$data.statements[index][key];

            let self = this;
            updateStatementItem(this.$data.statements[index].id, query)
                .then(function(data) {
                    if(data.success === true) {
                        self.$data.statements.splice(index, 1, data.item);
                    } else {
                        showDialog(data.message);
                    }
                })
                .catch(function(err) {
                    let msg = err;
                    if(err.response !== undefined) {
                        msg += ' (' + err.response + ')';
                    }
                    showDialog(msg);
                })
        },
        generateStatement: function() {

            console.log("generate statement");
            if(this.$data.currentPlaceId === 0) {
                return;
            }
            let selectedCount = 0;
            let ids = [];
            let from = this.$data.dateRange[0];
            let to = this.$data.dateRange[1];
            for(let i = 0 ; i < this.$data.report.orders.length ; i++) {
                if(this.$data.report.orders[i].selected === true) {
                    selectedCount++;
                    console.log("selected item id " + this.$data.report.orders[i].id);
                    ids.push(this.$data.report.orders[i].id);
                    if(this.$data.report.orders[i].salesDate < from || this.$data.report.orders[i].salesDate > to) {
                        showDialog("지정된 기간의 매출이 아닙니다. 다시 선택하시고 시도해주세요");
                        return;
                    }
                }
            }

            if(selectedCount === 0) {
                return;
            }


            let self = this;
            generateStatements(this.$data.currentPlaceId, from, to, ids)
                .then(function(data) {
                    if(data.success === true) {
                        console.log("generated ");
                        console.log(data.item);
                        for(let i = 0 ; i < data.item.orders.length ; i++) {
                            for(let j = 0 ; j < self.$data.report.orders.length ; j++) {
                                if(self.$data.report.orders[j].id === data.item.orders[i].id) {
                                    self.$data.report.orders.splice(j, 1, data.item.orders[i]);
                                    break;
                                }
                            }
                        }
                        showDialog("생성되었습니다.");
                    } else {
                        showDialog(data.message);
                    }
                })
                .catch(function(err) {
                    console.log(err);
                    if(err.response !== undefined) {
                        console.log(err.response);
                    }
                })

        },


        changeToProductManager: function() {
            this.$data.mode = 0;
            this.$data.products = [];
            this.$data.categories = [];
            this.$data.category = false;
            let self = this;
            getPlaceAssets(this.$data.currentPlaceId)
                .then(function(data) {
                    if(data.success === true) {
                        self.$data.assets = data.item;
                    } else {
                        console.log(data.message);
                    }
                    self.getCategories();
                })
                .catch(function(err) {
                    console.log(err);
                    if(err.response !== undefined) {
                        console.log(err.response);
                    }
                })
        },
        openPurchaseMode: function() {
            this.$data.mode = 5;

            this.$data.pCategories = [];
            this.$data.pProducts = [];
            let self = this;
            getMarketCategories(0)
                .then(function(data) {
                    if(data.success === true) {
                        self.$data.pCategories = data.item;
                    } else {
                        showDialog(data.message);
                    }
                })
                .catch(function(err) {
                    console.log(err);
                    if(err.response !== undefined) {
                        console.log(err.response);
                    }
                })
        },

        selectPurchaseCategory: function(category) {
            this.$data.pCategory = category;
            this.$data.pProducts = [];

            this.$data.pCategory = category;
            let self = this;
            getMarketCategoryProducts(0, category.id)
                .then(function(data) {
                    if(data.success === true) {
                        self.$data.pProducts = data.list;
                    } else {

                    }
                })
                .catch(function(err) {
                    console.log(err);
                    if(err.response !== undefined) {
                        console.log(err.response);
                    }
                })
        },


        deleteNotificationItem: function() {
            let self = this;
            // console.log("delete product ask");
            this.$modal.show('dialog',{
                    text: "공지 " + this.$data.notificationItem.title + '을 삭제 하시겠습니까?',
                    buttons: [
                        {
                            title:'아니요',
                            handler:()=>{
                                self.$modal.hide('dialog');
                            }
                        },
                        {
                            title:'삭제하기',
                            handler:() => {
                                self.$modal.hide('dialog');
                                self.deleteNotificationItemConfirmed(self.$data.notificationItem.id);
                            }
                        }
                    ]
                }

            );
        },
        deleteNotificationItemConfirmed: function(id) {
            let self = this;
            deleteNotification(id)
                .then(function(data) {
                    if(data.success === true) {
                        for (let i = 0; i < self.$data.notifications.length; i++) {
                            if (self.$data.notifications[i].id === data.item.id) {
                                self.$data.notifications.splice(i, 1);
                                break;
                            }
                        }
                        self.$data.notificationForm = false;
                        if(self.$data.notificationItem !== false && self.$data.notifcationItem.id === data.item.id) {
                            self.$data.notificationItem = false;
                        }
                    } else {
                        showDialog(data.message);
                    }
                })
                .catch(function(err) {
                    let msg = err;
                    if (err.response !== undefined) {
                        msg += ' (' + err.response + ')';
                    }
                    showDialog(msg, "ERROR");
                })
        },
        openNotificationEditForm: function() {
            this.$data.notificationForm = JSON.parse(JSON.stringify(this.$data.notificationItem));
        },
        createNewNotification: function() {
            this.$data.notificationForm = newNotificationItem();
        },
        closeNotificationForm: function() {
            this.$data.notificationForm = false;
        },
        onNotificationUpdated: function(item) {
            for(let i = 0 ; i < this.$data.notifications.length ; i++) {
                if(this.$data.notifications[i].id === item.id) {
                    this.$data.notifications.splice(i, 1, item);
                    break;
                }
            }
            this.$data.notificationForm = false;
            this.$data.notificationItem = item;
        },
        onNotificationCreated: function(item) {
            this.$data.notifications.push(item);
            this.closeNotificationForm();
        },
        getNewNotificationCount: function() {
            let count = 0;
            for(let i = 0 ; i < this.$data.notifications.length ; i++) {
                if(this.$data.notifications[i].readTime === 0) {
                    count++;
                }
            }
            return count;
        },
        getNotifications: function() {
            let date = new Date();
            let to = date.getTime();
            date.setDate(date.getDate() - 30);
            let self = this;
            console.log("get notification user role " + this.$data.userRole);
            if(this.$data.userRole >= 99) {
                getAdminNotification(date.getTime(), to)
                    .then(function (data) {
                        if (data.success === true) {
                            self.$data.notifications = data.item;
                        } else {
                            showDialog(data.message);
                        }
                    })
                    .catch(function (err) {

                    })
            } else if(this.$data.userRole === 97) {
                if(this.$data.currentPlaceId <= 0) {
                    showDialog("매장을 선택하세요");
                    return;
                }
                console.log("get place notifications");
                getShopNotifications(this.$data.currentPlaceId, date.getTime())
                    .then(function (data) {
                        if (data.success === true) {
                            console.log("notification list " + data.item);
                            self.$data.notifications = data.item;
                        } else {
                            showDialog(data.message);
                        }
                    })
                    .catch(function (err) {
                        console.log("err");
                        if(err.response !== undefined) {
                            console.log(err.response);
                        }
                    })
            }

        },
        getPlaceName: function(id) {
            for(let i = 0 ; i < this.$data.placeItems.length ; i++) {
                if(this.$data.placeItems[i].id === id) {
                    return this.$data.placeItems[i].name;
                }
            }
            return 'Unknown(' + id + ')';
        },
        timeToText: millisToDateTime,
        openNotification: function(notification) {
            let self = this;

            if(this.$data.userRole >= 99) {
                getNotification(notification.id)
                    .then(function (data) {
                        if (data.success === true) {
                            self.$data.notificationItem = data.item;
                        } else {
                            showDialog(data.message);
                        }
                    })
                    .catch(function (err) {
                        let msg = err;
                        if (err.response !== undefined) {
                            msg += ' (' + err.response + ')';
                        }
                        showDialog(msg, "ERROR");
                    })
            } else {
                getShopNotification(notification.placeId, notification.id)
                    .then(function (data) {
                        if (data.success === true) {
                            self.$data.notificationItem = data.item;
                        } else {
                            showDialog(data.message);
                        }
                    })
                    .catch(function (err) {
                        let msg = err;
                        if (err.response !== undefined) {
                            msg += ' (' + err.response + ')';
                        }
                        showDialog(msg, "ERROR");
                    })
            }
        },
        closeNotification: function() {
            if(this.$data.notificationItem === false) {
                return;
            }

            if(this.$data.notificationItem.readTime > 0) {
                this.$data.notificationItem = false;
                return;
            }

            let self = this;
            markNotificationAsRead(this.$data.notificationItem.placeId, this.$data.notificationItem.id)
                .then(function(data) {
                    console.log("mark as response");
                    console.log(data);
                    if(data.success === true) {
                        for(let i = 0 ; i < self.$data.notifications.length ; i++) {
                            if(self.$data.notifications[i].id === data.item.id) {
                                self.$data.notifications.splice(i, 1, data.item);
                                break;
                            }
                        }
                        self.$data.notificationItem = false;
                    } else {
                        showDialog(data.message);
                    }
                })
                .catch(function(err) {
                    let msg = err;
                    console.log(err);
                    if(err.response !== undefined) {
                        console.log(err.response);
                        msg += ' (' + err.response + ')';
                    }
                    showDialog(msg, "ERROR");
                })


        }



    }
}
</script>

<style scoped>
.selected-asset {
    background: blue;
    color:white;
}
.refunded-item {
    background: #ccc;
}
.item-check {
    color: gray;
}
.item-check-selected {
    color: blue;
}
.selected-item-row td {
    background-color: #52a5e0;
}
.no-padding th, td {
    padding: 5px;
}
.selected-category {
    background-color: #AEAEAE;
    color:white;
}
.purchase-product-container {
    background-color: white;
    box-shadow: 3px 3px 5px gray;
    overflow: hidden;
    border-radius: 10px;
    margin: 5px;
    width: 100%;
    display: flex;
}
.purchase-product-container > .product-image-container {
    width: 100px;
    height: 100px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.purchase-product-container > .product-info-container {
    width: calc(100% - 60px);
    padding: 10px;
}
.product-info-container > .product-title {
    padding: 5px;
}
.notification-card {
    padding: 10px;
    background-color: white;
    border: 1px solid #eee;
    margin-bottom: 10px;

}
.notification-card.notification-selected {
    background-color: #D1E0FF;
}
.notification-card .title {
    line-height: 30px;
    font-size: 18px;
    font-weight: bold;
}
.notification-card.time {
    padding-top: 5px;
    text-align: right;
}
.notification-place-list-container {
    width: 100%;
    padding: 5px;
    display: inline-block;
}

.notification-place-list-container .notification-place-item {
    border: 1px solid #ccc;
    background-color: white;
    border-radius: 10px;
    padding: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    float: left;
    box-shadow: 3px 3px 10px gray;
}
.notification-place-list-container .notification-place-item.notification-read {
    background-color: #52a5e0;
}

.notification-btn {
    border: 1px solid gray;
    background-color: white;
}
.notification-btn.selected {
    background-color: #bbbbbb;
    colro: black;
}
.new-notification-icon {
    color: red;
    font-weight: bold;
}

.option-container {
    padding:10px;
    border: 1px solid #e2e2e2;
    border-radius: 10px;
    margin-bottom: 20px;
}
.option-selector {
    margin-top: 10px;
    display: flex;
    border: 2px solid #e4e4e4;
    background: #e6e6e6;
    border-radius: 10px;
}
.option-selector-input {
    width: calc(100% - 80px);
    padding: 10px;
}
.option-selector-btn-container {
    width: 80px;
    text-align: center;
    padding-top: 20px;
    color: red;
}
th {
    padding: 5px;
}
.cat-btn {
    padding: 0 5px 0 5px;
    margin-right: 10px;
    margin-bottom: 10px;
}
</style>
