<template>
    <tabs
        :tabs="tabMenu"
        :currentTab="currentTab"
        :wrapper-class="'default-tabs'"
        :tab-class="'default-tabs__item'"
        :tab-active-class="'default-tabs__item_active'"
        :line-class="'default-tabs__active-line'"
        @onClick="handleClick"
    />
</template>

<script>
    import Tabs from 'vue-tabs-with-active-line';

    export default {
        name: "TabsView",
        components: {
            Tabs
        },
        props: {
            tabMenu: {
                type: Array,
                default: []
            },
            currentTab: {
                type: String,
                default: ''
            }
        },
        methods:{
            handleClick: function(newTab) {
                this.$emit('onClick', newTab);
            }
        }
    }
</script>

<style lang="scss">
    .default-tabs {
        position: relative;
        margin: 0 auto;
        &__item {
            display: inline-block;
            margin: 0 5px;
            padding: 10px;
            padding-bottom: 8px;
            font-size: 16px;
            letter-spacing: 0.8px;
            color: gray;
            text-decoration: none;
            border: none;
            background-color: transparent;
            border-bottom: 2px solid transparent;
            cursor: pointer;
            transition: all 0.25s;
            &_active {
                color: black;
            }
            &:hover {
                border-bottom: 2px solid gray;
                color: black;
            }
            &:focus {
                outline: none;
                border-bottom: 2px solid gray;
                color: black;
            }
            &:first-child {
                margin-left: 0;
            }
            &:last-child {
                margin-right: 0;
            }
        }
        &__active-line {
            position: absolute;
            bottom: 0;
            left: 0;
            height: 2px;
            background-color: black;
            transition: transform 0.4s ease, width 0.4s ease;
        }
    }
</style>
