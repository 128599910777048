<template>
    <div id="app">
        <Header
            v-if="isLoggedIn()"/>
        <SideMenu
            v-if="isLoggedIn()"/>
        <div class="router-view-wrapper">
            <router-view :key="$route.path"></router-view>
        </div>
    </div>
</template>

<script>
import Header from "./components/Header";
import SideMenu from './components/SideMenu';
import {isLoggedIn} from "./store/auth.module";

export default {
    name: 'App',
    components: {
        Header,
        SideMenu,
    },
    methods:{
        isLoggedIn: isLoggedIn
    },
};
</script>

<style scoped>

/*
 * vw, vh:
 * 다른 css 값(부모객체 등)에 간섭받지 않고,
 * 뷰포트의 사이즈를 사용 하능한 단위
 * (viewport's width / height)
 */
.router-view-wrapper {
    position: absolute;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    padding-top: 50px;
    /*padding-left: 200px;*/
    background-color: #ECF0F1;
    z-index: 1;
}
</style>
