export function getBikeStatusString(status) {
    if(status == 0) {
        return '생성';
    } else if(status == 1) {
        return '대여가능';
    } else if(status == 2) {
        return '대여중';
    } else if(status == 3) {
        return '충전중';
    } else if(status == 4) {
        return '수리중';
    }

    return 'Unknown ' + status;
}

export function clone (source) {
    // using native JSON functions removes reactivity
    // so we can clone an object without mutating the original source
    return JSON.parse(JSON.stringify(source));
}

export function compareDicts(dict1, dict2) {
    if(dict1 == null || dict2 == null){
        return true;
    }

    let dict1_str = JSON.stringify(dict1);
    let dict2_str = JSON.stringify(dict2);

    console.log(dict1_str)
    console.log(dict2_str)

    return dict1_str === dict2_str;
}
