<template>
    <div style="width: 100%; height: 100%; padding-left:15px;padding-right: 15px;">
        <div style="width: 100%; height: 50px;">
            <strong>점검이력</strong>
            <date-picker style="margin-left: 20px;" v-model="dateRange" valueType="format" @change="dateRangeSelected" range></date-picker>

            <select v-model="selectedOperatorId" style="margin-left: 20px;" @change="onOperatorChanged">
                <option v-for="o in operators" v-bind:key="'operator-selector-' + o.value" :value="o.value">{{o.name}}</option>
            </select>



            <select v-model="selectedStaffId" style="margin-left: 20px;">
                <option value="0">All Staff</option>
                <option v-for="staff in staffList" v-bind:key="'staff-selector-'+staff.id" :value="staff.id">{{staff.name}}</option>
            </select>

            <select v-model="selectedBikeId" style="margin-left: 20px;">
                <option value="0">All Bikes</option>
                <option v-for="bike in bikeList" v-bind:key="'bike-selector-'+bike.id" :value="bike.id">{{bike.fullCode}}</option>
            </select>

            <button class="btn btn-primary" style="margin-left: 20px;" @click="getRecords">가져오기</button>
        </div>
        <div style="width: 100%; height: calc(100% - 50px); overflow-y: scroll;">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <table class="table">
                            <tr>
                                <th>#</th>
                                <th>일시</th>
                                <th>자전거</th>
                                <th>직원</th>
                                <th>제목</th>
                                <th>비고</th>
                            </tr>
                            <tr v-for="(item, ii) in items" v-bind:key="'item-tr-'+ item.id" class="text-left">
                                <td>{{ii+1}}</td>
                                <td>{{dateTimeString(item.createdTime)}}</td>
                                <td>{{getBikeName(item.assetId)}}</td>
                                <td>{{item.userName}}</td>
                                <td>{{item.title}}</td>
                                <td>{{item.description}}</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import {getOperatorId, getRoleId, isSuperAdmin} from "@/store";
import {getOperatorList} from "@/api/operator";
import {getStaffList} from "@/api/user";
import {millisToDateTime, showDialog} from "@/api/common";
import {getAssetsUrl, searchBikeServiceLogs} from "@/api/asset";

export default {

    name: "BikeServiceView",
    components: {
      DatePicker,
    },
    data() {
        return {
            operators:[],
            staffList:[],
            bikeList:[],

            selectedOperatorId:getOperatorId(),
            selectedStaffId:0,
            selectedBikeId:0,

            dateRange:null,
            items:[],

            userRole:0,
            superAdmin:false,
        }
    },
    created() {
    },
    mounted() {
        this.$data.userRole = getRoleId();
        this.onStart();
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    methods: {
        dateTimeString:millisToDateTime,
        getBikeName: function(id) {
            for(let i = 0 ; i < this.$data.bikeList.length ; i++) {
                if(this.$data.bikeList[i].id === id) {
                    return this.$data.bikeList[i].name;
                }
            }
            return '(' + id + ')';
        },
        async onStart() {
            this.$data.superAdmin = isSuperAdmin();
            this.$data.operators = await getOperatorList();
        },
        dateRangeSelected: function() {
            console.log("dateRangeSelected")
            console.log(this.$data.dateRange)

            if(this.$data.dateRange.length !== 2) {
                return;
            }

            if(this.$data.dateRange[0] > this.$data.dateRange[1]) {
                let temp = this.$data.dateRange[1];
                this.$data.dateRange[0] = this.$data.dateRange[1];
                this.$data.dateRange[1] = temp;
            }

        },

        onOperatorChanged: function() {
            this.$data.bikeList = [];
            this.$data.staffList = [];

            this.getStaffList();
        },
        getBikeList: function() {

            let url = "/assets?type=bike&operatorId=" + this.selectedOperatorId;
            let self = this;
            getAssetsUrl(url)
            .then(function(bikes){
                self.$data.bikeList = bikes;
            })
            .catch(function(err) {
                console.log(err);
                let msg = err;
                if(err.response !== undefined) {
                    msg += " (" + err.response + ")";
                }
                showDialog(msg, "ERROR");
            })
        },
        getStaffList: function() {

            let self = this;
            getStaffList(this.$data.selectedOperatorId)
            .then(function(data) {
                if(data.success === true) {
                    self.$data.staffList = data.item;


                } else {
                    showDialog(data.message);
                }
                if(self.$data.selectedOperatorId > 0) {
                    self.getBikeList();
                }
            })
            .catch(function(err) {
                let msg = err;
                if(err.response !== undefined) {
                    msg += ' (' + err.response + ')';
                }

                showDialog(msg, "ERROR");
            })
        },
        getRecords: function() {
            if(this.$data.dateRange === null || this.$data.dateRange.length !== 2) {
                showDialog("기간을 선택하세요");
                return;
            }

            let query = "from=" + this.$data.dateRange[0] + "&to=" + this.$data.dateRange[1];

            if(this.$data.selectedOperatorId === 0) {
                showDialog("사업자를 먼저 선택하세요");
                return;
            }
            query += "&operatorId=" + this.$data.selectedOperatorId;
            if(this.$data.selectedBikeId > 0) {
                query += "&assetId=" + this.$data.selectedBikeId;
            }
            if(this.$data.selectedStaffId > 0) {
                query += "&userId=" + this.$data.selectedStaffId;
            }


            let self = this;
            searchBikeServiceLogs(query)
            .then(function(data) {
                if(data.success === true) {
                    console.log(data.item);
                    self.$data.items = data.item;
                } else {
                    showDialog(data.message);
                }
            })
            .catch(function(err) {
                let msg = err;
                if(err.response !== undefined) {
                    msg += ' (' + err.response + ')';
                }
                showDialog(msg, "ERROR");
            })
        }
    }
}
</script>

<style scoped>
td {
    text-align: left;
}
</style>
