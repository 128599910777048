import Vue from 'vue'
import Router from 'vue-router'
import VModal from 'vue-js-modal'

import HomeView from "../views/HomeView";
import Login from "../components/Login";

import * as VueGoogleMaps from "vue2-google-maps";

import OperatorView from "../views/contents/OperatorView";
import TagView from "../views/contents/TagView";
import UserView from "../views/contents/UserView";
import CouponView from "../views/contents/CouponView";
import OrderView from "../views/contents/OrderView";
import ProductView from "../views/contents/ProductView";
import BikeView from "../views/contents/BikeView";
import AreaAssetView from "../views/contents/AreaView";
import PlaceView from "../views/contents/PlaceView";
import {isLoggedIn} from "../store/auth.module";
import MagazineView from "../views/contents/MagazineView";
import AssignmentView from "../views/contents/AssignmentView";
import ReportView from "../views/contents/ReportView";
import SyslogView from "../views/contents/SyslogView";
import NoticeView from "../views/contents/NoticeView";
import SettingView from "../views/contents/SettingView";
import ParkingPointView from "../views/contents/ParkingPointView";
import PlaceDashboardView from "../views/PlaceDashboardView";
import VueQrCodeReader from "vue-qrcode-reader";
import LocationDataView from "../views/contents/LocationDataView";
import IssuerView from "../views/contents/IssuerView";
import Shops from "../views/Shops";
import BikeServiceView from "@/views/BikeServiceView";
import MerchantsView from "@/views/MerchantsView";
import BookingManager from "@/views/BookingManager.vue";
import PosView from "@/views/PosView.vue";

Vue.use(Router);
Vue.use(VueQrCodeReader);
Vue.use(VModal, { dialog: true, width:'90%' });
Vue.use(VueGoogleMaps, {
    load: {
        key: "AIzaSyAyWGe1qsRunNZQRzTo7DIVpchK7iZ3_Ho",//"AIzaSyC_qnPTkTEwSrXAuT73ILGkmoBV9JpdIL0",
        libraries: "places" // necessary for places input
    }
});
Vue.prototype.logged  = false;

const requireAuth = () => (from, to, next) => {
    if (isLoggedIn()){
        return next()
    }
    next('/admin')
};

export default new Router({
    mode: 'history',
    routes: [
        {
            path: '/admin',
            component: Login,
        },
        {
            path: '/admin/home',
            component: HomeView,
            beforeEnter: requireAuth()
        },
        {
            path: '/admin/notice',
            component: NoticeView,
            beforeEnter: requireAuth()
        },
        {
            path: '/admin/magazine',
            component: MagazineView,
            beforeEnter: requireAuth()
        },
        {
            path: '/admin/operator',
            component: OperatorView,
            beforeEnter: requireAuth()
        },
        {
            path: '/admin/tag',
            component: TagView,
            beforeEnter: requireAuth()
        },
        {
            path: '/admin/normal',
            component: UserView,
            beforeEnter: requireAuth(),
            props: {type: 'normal'}
        },
        {
            path: '/admin/special',
            component: UserView,
            beforeEnter: requireAuth(),
            props: {type: 'special'}
        },
        {
            path: '/admin/bike',
            component: BikeView,
            beforeEnter: requireAuth()
        },
        {
            path: '/admin/bikeService',
            component: BikeServiceView,
            beforeEnter: requireAuth()
        },
        {
            path: '/admin/syslog',
            component: SyslogView,
            beforeEnter: requireAuth()
        },
        {
            path: '/admin/order',
            component: OrderView,
            beforeEnter: requireAuth()
        },
        {
            path: '/admin/points',
            component: ParkingPointView,
            beforeEnter: requireAuth()
        },
        {
            path: '/admin/place',
            component: PlaceView,
            beforeEnter: requireAuth()
        },
        {
            path: '/admin/booking',
            component: BookingManager,
            beforeEnter: requireAuth()
        },
        {
            path: '/admin/coupon',
            component: ProductView,
            beforeEnter: requireAuth(),
            props: {type: 'coupon'}
        },
        {
            path: '/admin/service',
            component: AreaAssetView,
            beforeEnter: requireAuth(),
            props: { modelKey: 'service'}
        },
        {
            path: '/admin/parking',
            component: AreaAssetView,
            beforeEnter: requireAuth(),
            props: { modelKey: 'parking'}
        },
        {
            path: '/admin/product',
            component: ProductView,
            beforeEnter: requireAuth(),
            props: {type: 'price'}
        },
        {
            path: '/admin/issuer',
            component: IssuerView,
            beforeEnter: requireAuth(),
        },
        {
            path: '/admin/place',
            component: HomeView,
            beforeEnter: requireAuth()
        },
        {
            path: '/admin/pos',
            component: PosView,
            beforeEnter: requireAuth()
        },
        {
            path: '/admin/placeItem',
            component: PlaceDashboardView,
            beforeEnter: requireAuth()
        },
        {
            path: '/admin/merchants',
            component: MerchantsView,
            beforeEnter: requireAuth()
        },
        {
            path: '/admin/shops',
            component: Shops,
            beforeEnter: requireAuth()
        },
        {
            path: '/admin/assign',
            component: AssignmentView,
            beforeEnter: requireAuth()
        },
        {
            path: '/admin/report',
            component: ReportView,
            beforeEnter: requireAuth()
        },
        {
            path: '/admin/settings',
            component: SettingView,
            beforeEnter: requireAuth()
        },
        {
            path: '/admin/locations',
            component: LocationDataView,
            beforeEnter: requireAuth()
        },
        {
            path: '*', redirect: '/admin',
        }
    ]
})
