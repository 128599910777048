import {
    createItem, defaultPagination,
    deleteItem, errorHandler, getAPIURL,
    getCommonHeader,
    getItemById, getItemListByURLPath,
    getItemListWithCondition, getPageItemByURLPath, logout,
    updateItem
} from "./common";
import EventBus from "./event-bus";
import {cleanLocalStorage} from "../store/auth.module";

const axios = require('axios');

const model = "user";

export const ROLE_GENERAL = 0;
export const ROLE_PLACE_STAFF = 96;
export const ROLE_PLACE_MANAGER = 97;
export const ROLE_ADMIN = 99;
export const ROLE_SUPER_ADMIN = 100;
export const ROLE_PARTNER = 98;

export function getNormalUsers(pagination = null) {
    let urlPath = 'admin/users/normal';

    if(pagination != null){
        urlPath += `?page=${pagination.page}`;
        urlPath += `&size=${pagination.size}`;
        urlPath += `&sort=${pagination.sort}`;
    }

    return getPageItemByURLPath(urlPath);
}

export function getSpecialUsers(pagination = null) {
    let urlPath = 'admin/users/special';

    if(pagination != null){
        urlPath += `?page=${pagination.page}`;
        urlPath += `&size=${pagination.size}`;
        urlPath += `&sort=${pagination.sort}`;
    }

    return getPageItemByURLPath(urlPath);
}

export function getUsers(roleId = ROLE_GENERAL) {
    let address = `${process.env.VUE_APP_API_BASE_URL}admin/users?roleId=${roleId}`;

    return new Promise(function(resolve, reject) {
        axios({
            method: 'get',
            url: address,
            headers: getCommonHeader()
        }).then(function(response){
            let data = response.data;

            if(data.success)
                resolve(data.list);
            else
                console.log(data.message);
        }).catch(error => {
            errorHandler(error);
            console.log(error.response);
        })
    })
}

export function getUsersWithCondition(condition) {

    return getItemListByURLPath(`admin/${model}s?${condition}`)
}

export function getUserById(id) {

    return getItemById(model, id)
}

export function writeUser(item) {

    return createItem(model, item);
}

export function deleteUser(id) {

    return deleteItem(model, id)
}

export function searchUserByText(text, role, pagination = null) {
    let urlPath = `admin/search/user/${role}?keyword=${text}`;

    if(pagination != null) {
        urlPath += `&page=${pagination.page}`;
        urlPath += `&size=${pagination.size}`;
        urlPath += `&sort=${pagination.sort}`;
    }

    return getItemListByURLPath(urlPath);
}

export function getUserPoint(userId) {
    let address = `${process.env.VUE_APP_API_BASE_URL}admin/point/${userId}`;

    return new Promise(function(resolve, reject) {
        EventBus.$emit("showLoading", true, "가져오는중");
        axios({
            method: 'get',
            url: address,
            headers: getCommonHeader()
        }).then(function(response){
            EventBus.$emit("showLoading", false);
            let data = response.data;
            if(data.success)
                resolve(data.item.balance);
            else {
                console.log(data.message);

                resolve(0);
            }
        }).catch(error => {
            EventBus.$emit("showLoading", false);
            errorHandler(error);
            console.log(error.response);
        })
    })
}

export function getCouponsByUserId(userId, pagination = null) {
    let urlPath = `admin/coupons?uid=${userId}`;

    if(pagination != null){
        urlPath += `&page=${pagination.page}`;
        urlPath += `&size=${pagination.size}`;
        urlPath += `&sort=${pagination.sort}`;
    }

    return getPageItemByURLPath(urlPath, false);
    // let address = `${process.env.VUE_APP_API_BASE_URL}admin/coupons?uid=${userId}`;
    //
    // return new Promise(function(resolve) {
    //     axios({
    //         method: 'get',
    //         url: address,
    //         headers: getCommonHeader()
    //     }).then(function(response){
    //         let data = response.data.list;
    //
    //         resolve(data);
    //     }).catch(error => {
    //         if(error.response.status === 401)
    //             cleanLocalStorage();
    //         else
    //             console.log(error.response);
    //     })
    // })
}
export function getAdminUserLogs(userId) {
    let address = `${process.env.VUE_APP_API_BASE_URL}admin/userlogs?accessUserId=${userId}`;

    return new Promise(function(resolve, reject) {
        axios({
            method: 'get',
            url: address,
            headers: getCommonHeader()
        }).then(function(response){
            let data = response.data;

            resolve(data)

        }).catch(error => {

            errorHandler(error);
            reject(error);
            console.log(error.response);

        })
    })
}
export function getUserLogs(userId) {
    let address = `${process.env.VUE_APP_API_BASE_URL}admin/userlogs?targetUserId=${userId}`;

    return new Promise(function(resolve, reject) {
        axios({
            method: 'get',
            url: address,
            headers: getCommonHeader()
        }).then(function(response){
            let data = response.data;
            resolve(data)
        }).catch(error => {
            errorHandler(error);
            reject(error);
            console.log(error.response);
        })
    })
}
export function getUserSysLogByTime(userId, from, to, reason) {
    let address = `${process.env.VUE_APP_API_BASE_URL}admin/syslog/user/time?userId=${userId}&from=${from}&to=${to}&reason=${reason}`;

    console.log("get user id " + address);
    return new Promise(function(resolve, reject) {
        EventBus.$emit("showLoading", true, "가져오는중");
        axios({
            method: 'get',
            url: address,
            headers: getCommonHeader()
        }).then(function(response){
            EventBus.$emit("showLoading", false);
            let data = response.data;

            if(data.success)
                resolve(data);
            else
                console.log(data.message);
        }).catch(error => {
            EventBus.$emit("showLoading", false);
            errorHandler(error);
            reject(error);
        })
    })
}

export function getMembershipReport() {
    let address = `${process.env.VUE_APP_API_BASE_URL}admin/report/members`;

    return new Promise(function(resolve, reject) {
        axios({
            method: 'get',
            url: address,
            headers: getCommonHeader()
        }).then(function(response){
            let data = response.data;
            resolve(data)
        }).catch(error => {
            errorHandler(error);
            reject(error);
            console.log(error.response);
        })
    })
}
export function unlinkUserLink(id) {
    let address = process.env.VUE_APP_API_BASE_URL + 'admin/userlink?id=' + id;

    return new Promise(function(resolve, reject) {
        axios({
            method: 'delete',
            url: address,
            headers: getCommonHeader()
        }).then(function(response){
            let data = response.data;

            resolve(data);
        }).catch(error => {
            errorHandler(error);
            console.log(error.response);
        })
    })
}

export function updateUserLink(item) {
    let address = process.env.VUE_APP_API_BASE_URL + 'admin/userlink';

    return new Promise(function(resolve, reject) {
        axios({
            method: 'post',
            url: address,
            data: JSON.stringify(item),
            headers: getCommonHeader()
        }).then(function(response){
            let data = response.data;

            resolve(data);
        }).catch(error => {
            errorHandler(error);
            console.log(error.response);
        })
    })
}

export function getStaffList(operatorId) {
    let address = `${process.env.VUE_APP_API_BASE_URL}admin/operator/` + operatorId + '/staffs';

    return new Promise(function(resolve, reject) {
        axios({
            method: 'get',
            url: address,
            headers: getCommonHeader()
        }).then(function(response){
            let data = response.data;

            resolve(data)

        }).catch(error => {

            errorHandler(error);
            reject(error);
            console.log(error.response);

        })
    })
}
export function generateNewUser() {
  return   {
      id: 0,
      role: 0,
      parentId: 0,
      operatorId: 0,
      title: "",
      type: "",
      name: "",
      email: "",
      phoneNumber: "",
      apiKey: "",
      accountType: "",
      socialAccount: "",
      invited: 0,
      invitorId: 0,
      invitorNumber: "",
      token: "",
      codeExpiry: 0,
      password: "P@ssw0rd",
      tags: []
  }
}

export function canUserLeave(userId) {
    let address = `${process.env.VUE_APP_API_BASE_URL}admin/user/${userId}/member/cancellable`;

    return new Promise(function(resolve, reject) {
        EventBus.$emit("showLoading", true, "가져오는중");
        axios({
            method: 'get',
            url: address,
            headers: getCommonHeader()
        }).then(function(response){
            EventBus.$emit("showLoading", false);
            resolve(response.data);

        }).catch(error => {
            EventBus.$emit("showLoading", false);
            errorHandler(error);
            console.log(error.response);
            reject(error);
        })
    })
}
