<template>
    <div style="width: 100%; height: 100%;">
        <div style="width: 100%; height: 50px; line-height: 50px; background: #ddd;padding-left: 20px; padding-right: 20px;">
            <select v-if="places.length > 0" v-model="currentPlaceId" style="height: 40px; width: 300px;border-radius: 5px; border: 1px solid grey;" @change="onPlaceSelected">
                <option value="0">전체</option>
                <option v-for="p in places" :value="p.id">{{p.name}}</option>
            </select>
            <date-picker v-model="dateRange" valueType="format" @change="dateRangeSelected" range></date-picker>
            <button class="btn btn-success" @click="getPlaceOrderListByTime">기간으로 가져오기</button>
            <button class="btn btn-primary" @click="getPlaceOrderListByState(0, 90)">진행중인 주문 보기</button>
        </div>
        <div style="width: 100%; height: calc(100% - 50px); overflow-y: scroll;">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <table class="table">
                            <tr>
                                <th>Order No</th>
                                <th>주문일</th>
                                <th>매장</th>
                                <th>상태</th>
                                <th>Action</th>
                                <th>금액</th>
                                <th>VAT</th>
                                <th>배송료</th>
                                <th>총</th>
                                <th>아이템</th><th>단위</th><th>가격</th><th>수량</th><th>총</th><th></th>
                            </tr>
                            <tbody v-for="(order, oi) in orders">
                            <tr v-for="(item, ii) in order.items">
                                <td>{{order.id}}</td>
                                <td><span v-if="ii === 0">{{dateString(order.orderedTime)}}</span></td>
                                <td><span v-if="ii === 0">{{getShopTitle(order.placeId)}}</span></td>
                                <td style="min-width: 150px;">
                                    <template v-if="ii === 0">
                                        <div class="form-control" v-for="state in stateList" v-if="state.val === order.state">{{state.title}}</div>
                                    </template>
<!--                                    <select v-if="ii === 0" v-model="order.state" @change="processHqOrder(ii)" disabled>-->
<!--                                        <option v-for="state in stateList" :value="state.val">{{state.title}}</option>-->
<!--                                    </select>-->
                                </td>
                                <td v-if="ii === 0" :rowspan="order.items.length" class="btn-container">
                                    <label class="form-control" v-if="order.transfer > 0">송금결제: {{order.transfer}} </label>
                                    <label class="form-control" v-if="order.card > 0">카드결제: {{order.card}} </label>

                                    <button v-if="order.invoiceUrl !== null && order.invoiceUrl.length > 0" class="btn btn-primary form-control" @click="openNewTab(order.invoiceUrl)">명세서 보기</button>
                                    <button v-if="order.state >= 20" class="btn btn-primary form-control" @click="generateInvoice(oi)">명세서 생성</button>
                                    <button class="btn btn-success form-control" v-if="order.state === 0" @click="confirmOrder(oi)">확정하기</button>
                                    <button class="btn btn-success form-control" v-if="order.state === 20" @click="takePayment(oi, 'transfer')">송금확인</button>
                                    <button class="btn btn-success form-control" v-if="order.state === 20" @click="takePayment(oi, 'card')">카드결제하기</button>

                                    <div v-if="order.state >= 20" ><strong>송장번호</strong> <input type="text" class="form-control" v-model="order.parcelNumber" @change="recalculateOrder(order.id)" placeholder="송장번호"/></div>
                                    <button class="btn btn-success form-control" v-if="order.state === 50" @click="dispatchOrder(oi)">배송시작</button>
                                    <button class="btn btn-primary form-control" v-if="order.state === 70" @click="processHqOrder(oi, 100)">완료하기</button>
                                </td>

                                <td><span v-if="ii === 0">{{order.totalPrice}}</span></td>
                                <td><span v-if="ii === 0">{{order.tax}}</span></td>
                                <td>
                                    <input v-if="ii === 0" type="number" style="max-width: 100px; text-align: right;" v-model="order.deliveryCharge" @change="recalculateOrder(order.id)" :disabled="order.state !== 0"/>
                                </td>
                                <td><span v-if="ii === 0">{{order.total}}</span></td>

                                <td>{{item.title}}</td>
                                <td>{{item.unit}}</td>
                                <td><input type="number" style="max-width: 100px; text-align: right;" v-model="item.unitPrice"  @change="recalculateOrder(order.id)" :disabled="order.state !== 0"/></td>
                                <td><input type="number" v-model="item.amount" style="max-width: 100px; text-align: center;" @change="recalculateOrder(order.id)" :disabled="order.state !== 0"/></td>
                                <td>{{item.totalPrice}}</td>
<!--                                <td>{{item.tax}}</td>-->
<!--                                <td>{{item.total}}</td>-->
                                <td><span v-if="item.state >= 0 && item.state <= 20"><i class="fas fa-trash"></i> 취소</span></td>

                            </tr>
                            <tr>
                                <td colspan="14" class="text-right">

                                </td>
                                <td>
                                    <button v-if="order.state <= 20" class="btn btn-danger" @click="cancelOrder(oi)">주문취소</button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {showDialog} from "../api/common";
import {
    adminGenerateInvoicePlaceOrder,
    adminTakePaymentPlaceOrder,
    cancelPlaceOrder,
    getPlaceOrderList
} from "../api/product";
import {millisToDate, millisToReadable} from "../utils/TimeConverter";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import {getPlaces} from "../api/place";
import {updatePlaceOrder, adminProcessPlaceOrder, placeOrderStateList} from "../api/product";

export default {
    name: "HqPlaceOrdersViewer",
    components: {
        DatePicker
    },
    props: {

    },
    data() {
        return {
            dateRange:null,
            orders:[],
            places:[],
            currentPlaceId:0,
            stateList:placeOrderStateList(),
        }
    },
    created() {
    },
    mounted() {
        this.onStart();
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    methods: {
        dateTimeString: millisToReadable,
        dateString:millisToDate,
        dummy: function() {
            console.log("dummy");
        },

        onStart: function() {
            let self = this;
            console.log("onStart");
            getPlaces('typeClass=market')
                .then(function(list) {
                    self.$data.places = list;
                    // if(self.$data.places.length == 1) {
                    //     self.$data.currentPlaceId = self.$data.placeItems[0].id;
                    //     // §console.log("")
                    //     // self.connect();
                    //     self.onPlaceSelected();
                    // }
                })
                .catch(function(err) {
                    console.log(err);
                    if(err.response != undefined) {
                        console.log(err.response);
                    }
                })
        },
        onPlaceSelected: function() {
            this.$data.currentPlaceId = parseInt(this.$data.currentPlaceId);
        },
        getShopTitle: function(id) {
            console.log("get shop title " + id);
            console.log(this.$data.places);
            for(let i = 0 ; i < this.$data.places.length ; i++) {
                if(this.$data.places[i].id === id) {
                    return this.$data.places[i].name;
                }
            }
            return '(' + id + ')';
        },
        dateRangeSelected: function() {
            console.log("dateRangeSelected")
            console.log(this.$data.dateRange)

            if(this.$data.dateRange.length !== 2) {
                return;
            }

            if(this.$data.dateRange[0] > this.$data.dateRange[1]) {
                let temp = this.$data.dateRange[1];
                this.$data.dateRange[0] = this.$data.dateRange[1];
                this.$data.dateRange[1] = temp;
            }

        },
        getPlaceOrderListByTime: function() {
            if(this.$data.dateRange === null || this.$data.dateRange.length !== 2) {
                showDialog("기간을 선택해주세요")
            }

            let fromDate = new Date(this.$data.dateRange[0]);
            fromDate.setHours(0,0,0, 0);

            let toDate = new Date(this.$data.dateRange[1]);
            toDate.setHours(23, 59, 59, 999);

            this.$data.orders = [];

            let self = this;
            let query = "timeFrom=" + fromDate.getTime() + "&timeTo=" + toDate.getTime();
            if(this.$data.currentPlaceId > 0) {
                query += "&placeId=" + this.$data.currentPlaceId
            }
            getPlaceOrderList(query)
            .then(function (data) {
                if(data.success === true) {
                    self.$data.orders = data.item;
                } else {
                    showDialog(data.message);
                }
            })
            .catch(function(err) {
                let msg = err;
                if(err.response !== undefined) {
                    msg += ' (' + err.response + ')';
                }
                showDialog(msg, "ERROR");
            })


        },
        getPlaceOrderListByState: function(from, to) {

            this.$data.orders = [];
            let self = this;
            let query = 'stateFrom=' + from  + '&stateTo=' + to;

            if(this.$data.currentPlaceId > 0) {
                query += '&placeId=' + this.$data.currentPlaceId;
            }
            getPlaceOrderList(query)
            .then(function(data) {
                if(data.success === true) {
                    self.$data.orders = data.item;
                } else {
                    showDialog(data.message);
                }
            })
            .catch(function(err) {
                let msg = err;
                if(err.response !== undefined) {
                    msg += ' (' + err.response + ')';
                }
                showDialog(msg, "ERROR");
            })
        },
        onOrderUpdated: function(order) {
            for(let i = 0 ; i < this.$data.orders.length ; i++) {
                if(this.$data.orders[i].id === order.id) {
                    this.$data.orders.splice(i, 1, order);
                    return;
                }
            }
        },
        recalculateOrder: function(id) {

            for(let i = 0 ; i < this.$data.orders.length ; i++) {
                if(this.$data.orders[i].id === id) {
                    let total = 0;
                    let totalTax = 0;
                    for(let j = 0 ; j < this.$data.orders[i].items.length ; j++) {
                        this.$data.orders[i].items[j].totalPrice = this.$data.orders[i].items[j].unitPrice * this.$data.orders[i].items[j].amount;
                        if(this.$data.orders[i].items[j].state >= 0) {

                            total += this.$data.orders[i].items[j].totalPrice;
                            this.$data.orders[i].items[j].tax = this.$data.orders[i].items[j].totalPrice/10;
                            this.$data.orders[i].items[j].total = this.$data.orders[i].items[j].totalPrice + this.$data.orders[i].items[j].tax;
                            totalTax += this.$data.orders[i].items[j].tax;
                        }
                    }


                    if(this.$data.orders[i].deliveryCharge > 0) {
                        total += parseInt(this.$data.orders[i].deliveryCharge);
                        this.$data.orders[i].deliveryChargeTax = parseInt(this.$data.orders[i].deliveryCharge)/10;
                        totalTax += this.$data.orders[i].deliveryChargeTax;
                    } else {
                        this.$data.orders[i].deliveryChargeTax = 0;
                    }
                    this.$data.orders[i].totalPrice = total;
                    this.$data.orders[i].tax = totalTax;
                    this.$data.orders[i].total = this.$data.orders[i].totalPrice + this.$data.orders[i].tax;

                    console.log("Delivery charge = " + this.$data.orders[i].deliveryCharge);
                    let self = this;
                    updatePlaceOrder(this.$data.orders[i].placeId, this.$data.orders[i])
                    .then(function(data) {
                        if(data.success === true) {
                            self.onOrderUpdated(data.item);
                        } else {
                            showDialog(data.message);
                        }
                    })
                    .catch(function(err) {
                        let msg = err;
                        console.log(err);
                        if(err.response !== undefined) {
                            msg += ' (' + err.response  + ')';
                            console.log(err.response);
                        }
                        showDialog(msg, "ERROR");
                    })

                    break;
                }
            }
        },
        confirmOrder: function(index) {
          // Check items
            let confirmedItemsFound = false;
          for(let i = 0 ; i < this.$data.orders[index].items.length ; i++) {
              if(this.$data.orders[index].items[i].state >= 0 && this.$data.orders[index].items[i].amount >= 0) {
                  confirmedItemsFound = true;
                  break;
              }
          }
          if(confirmedItemsFound === false) {
              showDialog("주문 확정할 항목이 없습니다.");
              return;
          }

          // if(this.$data.orders[index].deliveryCharge === 0) {
          //     showDialog("배송료를 입력해주세요");
          //     return;
          // }

          this.processHqOrder(index, 20);


          // Check delivery charge
        },


        dispatchOrder: function(index) {
          if(this.$data.orders[index].parcelNumber === null || this.$data.orders[index].parcelNumber.length === 0) {
              showDialog("배송번호를 입력하세요");
              return;
          }
          this.processHqOrder(index, 70);

        },
        takePayment: function(index, method) {
            let self = this;

            adminTakePaymentPlaceOrder(this.$data.orders[index].placeId, this.$data.orders[index].id, method)
                .then(function(data) {
                    if(data.success === true) {
                        console.log(data);
                        self.onOrderUpdated(data.item);
                    } else {
                        showDialog(data.message);
                    }
                })
                .catch(function(err) {
                    let msg = err;
                    if(err.response !== undefined) {
                        msg += ' (' + err.response + ')';
                    }
                    showDialog(msg, "ERROR");
                })
        },
        cancelOrder: function(index) {
            let self = this;
            cancelPlaceOrder(this.$data.orders[index].placeId, this.$data.orders[index].id)
            .then(function(data) {
                if(data.success === true) {
                    self.onOrderUpdated(data.item);
                } else {
                    showDialog(data.message);
                }
            })
            .catch(function(err) {
                console.log(err);
                if(err.response !== undefined) {
                    console.log(err.response);
                }

            })
        },
        processHqOrder: function(index, state) {
            let self = this;

            adminProcessPlaceOrder(this.$data.orders[index].id, state)
                .then(function(data) {
                    if(data.success === true) {

                        self.onOrderUpdated(data.item);
                    }
                })
                .catch(function(err) {
                    let msg = err;
                    if(err.response !== undefined) {
                        msg += ' (' + err.response + ')';
                    }
                    showDialog(msg, "ERROR");
                })
        },
        openNewTab: function(url) {
            window.open(url, '_blank');
        },
        generateInvoice: function(index) {
            let self = this;
            adminGenerateInvoicePlaceOrder(this.$data.orders[index].placeId, this.$data.orders[index].id)
            .then(function(data) {
                if(data.success === true) {

                    self.onOrderUpdated(data.item);
                    self.openNewTab(data.item.invoiceUrl);
                }
            })
            .catch(function(err) {
                let msg = err;
                console.log(err);
                if(err.response !== undefined) {
                    console.log(err.response);
                    msg += ' (' + err.response + ')';
                }
                showDialog(msg, "ERROR");
            })
        }

    }
}
</script>

<style scoped>
.btn-container {
    max-width: 200px;
}
.btn-container > * {
    margin-bottom:10px;
}
</style>
