import {createItem, deleteItem, errorHandler, getAPIURL, getCommonHeader} from "./common";
import {cleanLocalStorage} from "../store/auth.module";

const axios = require('axios');

export function getPosts(query = null) {
    let address = `${process.env.VUE_APP_API_BASE_URL}admin/posts`;

    if(query != null && query.length > 0) {
        address += "?" + query;
    }
    return new Promise(function(resolve) {
        axios({
            method: 'GET',
            url: address,
            headers: getCommonHeader()
        }).then(function(response){
            let data = response.data;

            if(data.success)
                resolve(data.list);
            else
                console.log(data.message);
        }).catch(error => {
            errorHandler(error);
            console.log(error.response);
        })
    })
}

export function writePost(item) {
    return createItem("post", item);
}
export function deletePost(id) {
    return deleteItem("post", id);
}
export function getPostById(id) {
    let address = process.env.VUE_APP_API_BASE_URL + '/admin/post?id=' + id;

    return new Promise(function(resolve) {
        axios({
            method: 'GET',
            url: address,
            headers: getCommonHeader()
        }).then(function(response){
            let data = response.data;

            if(data.success)
                resolve(data.item);
            else
                console.log(data.message);

        }).catch(error => {
            errorHandler(error);
            console.log(error.response);
        })
    })
}

export function newPost() {
    return {
        id: 0,
        type:'notice',
        parentId: 0,
        operatorId: 0,
        userId: 0,
        imageUrl:'',

        placeId: 0,

        title: "",
        name: "",

        shortContent: "",
        content: "",

        lat: 36.5, lon: 127.8,
        timeFrom: 0,
        timeTo: 0,

        tags: [],
    }
}

export function getPostTypes() {
    let list = [
        {
            value:'notice',
            label:'공지사항',
        },
        {
            value:'home_notice',
            label:'홈 공지사항',
        },
        {
            value:'short_notice',
            label:'간단 공지사항',
        },
        {
            value:'map_notice',
            label:'맵뷰 공지사항',
        }
    ]
    return list;
}
