import {createItem, deleteItem, errorHandler, getCommonHeader, getItemById, getItemList, updateItem} from "./common";
import {cleanLocalStorage} from "../store/auth.module";
import EventBus from "./event-bus";
const axios = require('axios');

const model = "product";

export function getProducts() {

    return getItemList(model);
}
export function getPlaceProducts(placeId) {
    return new Promise(function(resolve, reject) {
        EventBus.$emit("showLoading", true, "가져오는중");
        axios.get(process.env.VUE_APP_API_BASE_URL + 'admin/place/products?placeId=' + placeId, {
            headers: getCommonHeader()
        })
            .then(function(response) {
                console.log(response);
                EventBus.$emit("showLoading", false);
                let data = response.data;
                resolve(data);
            })
            .catch(function(err) {
                console.log(err);
                EventBus.$emit("showLoading", false);
                errorHandler(err);
                reject(err);
            })
    })
}
export function getMarketCategoryProducts(placeId, categoryId) {
    return new Promise(function(resolve, reject) {
        EventBus.$emit("showLoading", true, "가져오는중");
        axios.get(process.env.VUE_APP_API_BASE_URL + 'admin/shop/' + placeId + '/products?categoryId=' + categoryId, {
            headers: getCommonHeader()
        })
            .then(function(response) {
                console.log(response);
                EventBus.$emit("showLoading", false);
                let data = response.data;
                resolve(data);
            })
            .catch(function(err) {
                console.log(err);
                EventBus.$emit("showLoading", false);
                errorHandler(err);
                reject(err);
            })
    })
}
export function deletePlaceShopProduct(placeId, id) {
    return new Promise(function(resolve, reject) {
        EventBus.$emit("showLoading", true, "가져오는중");
        axios.delete(process.env.VUE_APP_API_BASE_URL + 'admin/shop/' + placeId + '/product?id='+id, {
            headers: getCommonHeader()
        })
            .then(function(response) {
                console.log(response);
                EventBus.$emit("showLoading", false);
                let data = response.data;
                resolve(data);
            })
            .catch(function(err) {
                console.log(err);
                EventBus.$emit("showLoading", false);
                errorHandler(err);
                reject(err);
            })
    })
}
export function getPlaceShopProduct(placeId, id) {
    return new Promise(function(resolve, reject) {
        EventBus.$emit("showLoading", true, "가져오는중");
        axios.get(process.env.VUE_APP_API_BASE_URL + 'admin/shop/' + placeId + '/product?id='+id, {
            headers: getCommonHeader()
        })
            .then(function(response) {
                console.log(response);
                EventBus.$emit("showLoading", false);
                let data = response.data;
                resolve(data);
            })
            .catch(function(err) {
                console.log(err);
                EventBus.$emit("showLoading", false);
                errorHandler(err);
                reject(err);
            })
    })
}
export function searchPlaceShopProducts(placeId, word) {
    return new Promise(function(resolve, reject) {
        EventBus.$emit("showLoading", true, "가져오는중");
        axios.get(process.env.VUE_APP_API_BASE_URL + 'admin/shop/' + placeId + '/products/search?word='+word, {
            headers: getCommonHeader()
        })
            .then(function(response) {
                console.log(response);
                EventBus.$emit("showLoading", false);
                let data = response.data;
                resolve(data);
            })
            .catch(function(err) {
                console.log(err);
                EventBus.$emit("showLoading", false);
                errorHandler(err);
                reject(err);
            })
    })
}
export function getPlaceShopProducts(placeId) {
    return new Promise(function(resolve, reject) {
        EventBus.$emit("showLoading", true, "가져오는중");
        axios.get(process.env.VUE_APP_API_BASE_URL + 'admin/shop/' + placeId + '/products?deleted=false', {
            headers: getCommonHeader()
        })
            .then(function(response) {
                console.log(response);
                EventBus.$emit("showLoading", false);
                let data = response.data;
                resolve(data);
            })
            .catch(function(err) {
                console.log(err);
                EventBus.$emit("showLoading", false);
                errorHandler(err);
                reject(err);
            })
    })
}
export function getPlaceBookingProduct(placeId, productId) {
    return new Promise(function(resolve, reject) {
        EventBus.$emit("showLoading", true, "가져오는중");
        axios.get(process.env.VUE_APP_API_BASE_URL + 'admin/place/' + placeId + '/product/' + productId, {
            headers: getCommonHeader()
        })
            .then(function(response) {
                console.log(response);
                EventBus.$emit("showLoading", false);
                let data = response.data;
                resolve(data);
            })
            .catch(function(err) {
                console.log(err);
                EventBus.$emit("showLoading", false);
                errorHandler(err);
                reject(err);
            })
    })
}
export function updatePlaceShopProduct(placeId, product) {
    return new Promise(function(resolve, reject) {
        EventBus.$emit("showLoading", true, "가져오는중");
        axios.post(process.env.VUE_APP_API_BASE_URL + 'admin/shop/' + placeId + '/product', JSON.stringify(product), {
            headers: getCommonHeader()
        })
            .then(function(response) {
                console.log(response);
                EventBus.$emit("showLoading", false);
                let data = response.data;
                resolve(data);
            })
            .catch(function(err) {
                console.log(err);
                EventBus.$emit("showLoading", false);
                errorHandler(err);
                reject(err);
            })
    })
}
export function updatePlaceShopProducts(placeId, products) {
    return new Promise(function(resolve, reject) {
        EventBus.$emit("showLoading", true, "가져오는중");
        axios.post(process.env.VUE_APP_API_BASE_URL + 'admin/shop/' + placeId + '/products', JSON.stringify(products), {
            headers: getCommonHeader()
        })
            .then(function(response) {
                console.log(response);
                EventBus.$emit("showLoading", false);
                let data = response.data;
                resolve(data);
            })
            .catch(function(err) {
                console.log(err);
                EventBus.$emit("showLoading", false);
                errorHandler(err);
                reject(err);
            })
    })
}
export function getBikeProducts(operatorId, type) {
    return new Promise(function(resolve, reject) {
        EventBus.$emit("showLoading", true, "가져오는중");
        console.log("getBikeProducts " + process.env.VUE_APP_API_BASE_URL + 'admin/bike/products?operatorId=' + operatorId + '&type='+type);
        axios.get(process.env.VUE_APP_API_BASE_URL + 'admin/bike/products?operatorId=' + operatorId + '&type='+type, {
            headers: getCommonHeader()
        })
            .then(function(response) {
                console.log(response);
                EventBus.$emit("showLoading", false);
                let data = response.data;
                resolve(data);
            })
            .catch(function(err) {
                console.log(err);
                EventBus.$emit("showLoading", false);
                errorHandler(err);
                reject(err);
            })
    })
}
export function getProductsByType(operatorId, type) {
    return new Promise(function(resolve, reject) {
        EventBus.$emit("showLoading", true, "가져오는중");
        console.log("getBikeProducts " + process.env.VUE_APP_API_BASE_URL + 'admin/products?operatorId=' + operatorId + '&type='+type);
        axios.get(process.env.VUE_APP_API_BASE_URL + 'admin/products?operatorId=' + operatorId + '&type='+type, {
            headers: getCommonHeader()
        })
            .then(function(response) {
                console.log(response);
                EventBus.$emit("showLoading", false);
                let data = response.data;
                resolve(data);
            })
            .catch(function(err) {
                console.log(err);
                EventBus.$emit("showLoading", false);
                errorHandler(err);
                reject(err);
            })
    })
}
export function getProductById(id) {

    return getItemById(model, id)
}

export function deleteProduct(id) {

    return deleteItem(model, id)
}

export function writeProduct(item) {
    let address = process.env.VUE_APP_API_BASE_URL + 'admin/'+ model;

    return new Promise(function(resolve, reject) {
        axios({
            method: 'post',
            url: address,
            data: JSON.stringify(item),
            headers: getCommonHeader()
        }).then(function(response){
            let data = response.data.item;

            console.log("write product");
            console.log(response.data);
            resolve(data);
        }).catch(error => {
            errorHandler(error);
            console.log(error.response);
        })
    })
}

export function togglePlaceProductAvailability(placeId, id, available) {
    return new Promise(function(resolve, reject) {
        EventBus.$emit("showLoading", true, "가져오는중");
        axios.get(process.env.VUE_APP_API_BASE_URL + 'admin/place/' + placeId + '/product/' + id + '/availability/' + available, {
            headers: getCommonHeader()
        })
            .then(function(response) {
                console.log(response);
                EventBus.$emit("showLoading", false);
                let data = response.data;
                resolve(data);
            })
            .catch(function(err) {
                console.log(err);
                EventBus.$emit("showLoading", false);
                errorHandler(err);
                reject(err);
            })
    })
}
export function generateNewProduct() {
  return   {
      id: 0,
      parentId: 0,
      operatorId: 0,
      userId: 0,
      title: "",
      name: "",
      type: "",
      deleted: 0,
      imageUrl: "",
      tags: [],
      content: "",
      duration: 0,
      rate: 0,
      groupId:0,
      placeId:0,
      payoutAmount:0,
      hours:[],
      slotLength:0,
      category:'',
      originalPrice:0,
      program:'',
      tnc:'',
      preparation:'',
      manual:'',
      minHours:0,
      refundable:0,
      refundPolicy:[],
      cutOffHour:0,
      transferable: false,
      priceName:'',
      priceName2:'',
      priceName3:'',
      priceName4:'',
      priceName5:'',
      count:1,
      validTo:null,
      validFor:30,
      timeLimited:false,
      available:false,
  }
}
export function getProductTypeString(type) {
    if(type === 'bike_base') {
        return '자전거 기본요금';
    }else if(type === 'bike_normal') {
        return '자전거 추가요금';
    } else if(type === 'bike_park') {
        return '자전거 주차요금';
    } else if(type === 'bike_insurance') {
        return '자전거 보험료';
    } else if(type === 'coupon') {
        return '쿠폰';
    } else if(type === 'place_coupon') {
        return '플레이스 쿠폰';
    } else if(type === 'place_product') {
        return '플레이스 판매 상품';
    } else if(type === 'place_booking_product') {
        return '플레이스 예약 상품';
    }
    return '알수없음';
}
export function newShopProduct(placeId) {
    let item = {
        id:0,
        title:'',
        description:'',
        mainImage:'',
        images:[],
        placeId:placeId,
        price:0,
        commission:0,
        originalPrice:0,
        manufacturer:'',
        originCountry:'',
        barcode:'',
        soldOut:false,
        deleted:false,
        minStock:0,
        shopMinStock:0,
        pos:0,
        options:[],
        optionEnabled: false,
    }
    return item;
}


export function getProductForOrder(pid, id) {
    return new Promise(function(resolve, reject) {
        EventBus.$emit("showLoading", true, "가져오는중");
        // console.log("getBikeProducts " + process.env.VUE_APP_API_BASE_URL + 'admin/products?operatorId=' + operatorId + '&type='+type);
        axios.get(process.env.VUE_APP_API_BASE_URL + 'admin/shop/' + pid + '/order/product/' + id + '/search', {
            headers: getCommonHeader()
        })
            .then(function(response) {
                console.log(response);
                EventBus.$emit("showLoading", false);
                let data = response.data;
                resolve(data);
            })
            .catch(function(err) {
                console.log(err);
                EventBus.$emit("showLoading", false);
                errorHandler(err);
                reject(err);
            })
    })
}
export function submitPlaceOrder(pid, order) {
    return new Promise(function(resolve, reject) {
        EventBus.$emit("showLoading", true, "가져오는중");
        // console.log("getBikeProducts " + process.env.VUE_APP_API_BASE_URL + 'admin/products?operatorId=' + operatorId + '&type='+type);
        axios.post(process.env.VUE_APP_API_BASE_URL + 'admin/shop/' + pid + '/order/place', JSON.stringify(order), {
            headers: getCommonHeader()
        })
            .then(function(response) {
                console.log(response);
                EventBus.$emit("showLoading", false);
                let data = response.data;
                resolve(data);
            })
            .catch(function(err) {
                console.log(err);
                EventBus.$emit("showLoading", false);
                errorHandler(err);
                reject(err);
            })
    })
}
export function adminProcessPlaceOrder(id, state) {
    return new Promise(function(resolve, reject) {
        EventBus.$emit("showLoading", true, "가져오는중");
        // console.log("getBikeProducts " + process.env.VUE_APP_API_BASE_URL + 'admin/products?operatorId=' + operatorId + '&type='+type);
        axios.get(process.env.VUE_APP_API_BASE_URL + 'admin/shop/order/' + id + '/state/' + state, {
            headers: getCommonHeader()
        })
            .then(function(response) {
                console.log(response);
                EventBus.$emit("showLoading", false);
                let data = response.data;
                resolve(data);
            })
            .catch(function(err) {
                console.log(err);
                EventBus.$emit("showLoading", false);
                errorHandler(err);
                reject(err);
            })
    })
}
export function adminGenerateInvoicePlaceOrder(pId, id) {
    return new Promise(function(resolve, reject) {
        EventBus.$emit("showLoading", true, "명세서 생성중입니다.");
        // console.log("getBikeProducts " + process.env.VUE_APP_API_BASE_URL + 'admin/products?operatorId=' + operatorId + '&type='+type);
        axios.get(process.env.VUE_APP_API_BASE_URL + 'admin/shop/' + pId + '/order/' + id + '/invoice', {
            headers: getCommonHeader()
        })
            .then(function(response) {
                console.log(response);
                EventBus.$emit("showLoading", false);
                let data = response.data;
                resolve(data);
            })
            .catch(function(err) {
                console.log(err);
                EventBus.$emit("showLoading", false);
                errorHandler(err);
                reject(err);
            })
    })
}
export function adminTakePaymentPlaceOrder(pId, id, method) {
    return new Promise(function(resolve, reject) {
        EventBus.$emit("showLoading", true, "가져오는중");
        // console.log("getBikeProducts " + process.env.VUE_APP_API_BASE_URL + 'admin/products?operatorId=' + operatorId + '&type='+type);
        axios.get(process.env.VUE_APP_API_BASE_URL + 'admin/shop/' + pId + '/order/' + id + '/pay/' + method, {
            headers: getCommonHeader()
        })
            .then(function(response) {
                console.log(response);
                EventBus.$emit("showLoading", false);
                let data = response.data;
                resolve(data);
            })
            .catch(function(err) {
                console.log(err);
                EventBus.$emit("showLoading", false);
                errorHandler(err);
                reject(err);
            })
    })
}
export function updatePlaceOrder(placeId, order) {
    return new Promise(function(resolve, reject) {
        EventBus.$emit("showLoading", true, "가져오는중");
        // console.log("getBikeProducts " + process.env.VUE_APP_API_BASE_URL + 'admin/products?operatorId=' + operatorId + '&type='+type);
        axios.post(process.env.VUE_APP_API_BASE_URL + 'admin/shop/' + placeId + '/order/order', JSON.stringify(order), {
            headers: getCommonHeader()
        })
        .then(function(response) {
            console.log(response);
            EventBus.$emit("showLoading", false);
            let data = response.data;
            resolve(data);
        })
        .catch(function(err) {
            console.log(err);
            EventBus.$emit("showLoading", false);
            errorHandler(err);
            reject(err);
        })
    })
}
export function getPlaceOrder(placeId, id) {
    return new Promise(function(resolve, reject) {
        EventBus.$emit("showLoading", true, "가져오는중");
        // console.log("getBikeProducts " + process.env.VUE_APP_API_BASE_URL + 'admin/products?operatorId=' + operatorId + '&type='+type);
        axios.get(process.env.VUE_APP_API_BASE_URL + 'admin/shop/' + placeId + 'order/order/' + id, {
            headers: getCommonHeader()
        })
            .then(function(response) {
                console.log(response);
                EventBus.$emit("showLoading", false);
                let data = response.data;
                resolve(data);
            })
            .catch(function(err) {
                console.log(err);
                EventBus.$emit("showLoading", false);
                errorHandler(err);
                reject(err);
            })
    })
}
export function getPlaceOrderList(query) {
    return new Promise(function(resolve, reject) {
        EventBus.$emit("showLoading", true, "가져오는중");
        // console.log("getBikeProducts " + process.env.VUE_APP_API_BASE_URL + 'admin/products?operatorId=' + operatorId + '&type='+type);
        axios.get(process.env.VUE_APP_API_BASE_URL + 'admin/shop/order/orders?' + query, {
            headers: getCommonHeader()
        })
            .then(function(response) {
                console.log(response);
                EventBus.$emit("showLoading", false);
                let data = response.data;
                resolve(data);
            })
            .catch(function(err) {
                console.log(err);
                EventBus.$emit("showLoading", false);
                errorHandler(err);
                reject(err);
            })
    })
}
export function cancelPlaceOrder(pid, id) {
    return new Promise(function(resolve, reject) {
        EventBus.$emit("showLoading", true, "가져오는중");
        // console.log("getBikeProducts " + process.env.VUE_APP_API_BASE_URL + 'admin/products?operatorId=' + operatorId + '&type='+type);
        axios.get(process.env.VUE_APP_API_BASE_URL + 'admin/shop/' + pid + '/order/' + id + '/state/cancel', {
            headers: getCommonHeader()
        })
            .then(function(response) {
                console.log(response);
                EventBus.$emit("showLoading", false);
                let data = response.data;
                resolve(data);
            })
            .catch(function(err) {
                console.log(err);
                EventBus.$emit("showLoading", false);
                errorHandler(err);
                reject(err);
            })
    })
}
export function completePlaceOrder(pid, id, code) {
    return new Promise(function(resolve, reject) {
        EventBus.$emit("showLoading", true, "가져오는중");
        // console.log("getBikeProducts " + process.env.VUE_APP_API_BASE_URL + 'admin/products?operatorId=' + operatorId + '&type='+type);
        axios.get(process.env.VUE_APP_API_BASE_URL + 'admin/shop/' + pid + '/order/' + id + '/state/complete/' + code, {
            headers: getCommonHeader()
        })
            .then(function(response) {
                console.log(response);
                EventBus.$emit("showLoading", false);
                let data = response.data;
                resolve(data);
            })
            .catch(function(err) {
                console.log(err);
                EventBus.$emit("showLoading", false);
                errorHandler(err);
                reject(err);
            })
    })
}
export function newPlaceOrder(placeId, productId) {
    let item = {
        id:0,
        title:0,
        placeId:placeId,
        amount:0,
        unitPrice:0,
        totalPrice:0,
        state:0,
        productId:productId,
        items:[],
        unit:'EA',
        destId:0,
        tax:0,
        total:0,
        amountPerUnit:1,
        deliveryCharge:0,
        courier:'',
        card:0,
        transfer:0,
        parcelNumber:'',
    };

    return item;
}
export function placeOrderStateList() {
    let list = [
        {val:0, title:'신규주문'},
        {val:20, title:'확정'},
        {val:40, title:'결제완료'},
        {val:50, title:'처리중'},
        {val:70, title:'배송시작'},
        {val:80, title:'배송완료'},
        {val:100, title:'완료'},
        {val:-10, title:'취소'},
        {val:-20, title:'주문자취소'},
    ];
    return list;
}
