<template>
  <div class="logo" v-on:click="showMenu">
      {{$t('common.title')}}
<!--    <router-link to="/">{{$t('common.title')}}</router-link>-->
  </div>
</template>

<script>
    import EventBus from "../api/event-bus";

    export default {
        name: 'Logo',
        methods: {
            showMenu: function() {
                EventBus.$emit("toggleMenu", true);
            }
        }
    };
</script>

<style scoped>
  .logo a {
    color: #333;
    font-weight: 700;
    font-family: Raleway, sans-serif;
    font-size: 18px;
    line-height: 20px;
    text-decoration: none;
  }
</style>
