import {deleteItem, errorHandler, getCommonHeader, getItemById, getItemList, getItemListByURLPath} from "./common";
import {cleanLocalStorage} from "../store/auth.module";
import EventBus from "./event-bus";

const axios = require('axios');

const model = "place";

export function getPlaces(query = null) {

    return getItemList(model, query);
}

export function getPlaceById(id) {

    return getItemById(model, id)
}

export function deletePlace(id) {

    return deleteItem(model, id)
}
export function getPlaceUsers(id) {
    let address = process.env.VUE_APP_API_BASE_URL + `admin/place/${id}/users`;
    return new Promise(function(resolve, reject) {
        axios( {
            method:'GET',
            url:address,
            headers: getCommonHeader()
        }).then(function(response) {

            console.log("place user response");
            console.log(response);
            resolve(response.data);
        }).catch(error => {
            errorHandler(error);
            console.log(error.response);
            reject(error);
        });
    })
    // return getItemListByURLPath(`admin/${model}/${id}/users`);
}
export function getPlaceExcel() {
    let address = process.env.VUE_APP_API_BASE_URL + 'admin/placelist';
    return new Promise(function(resolve, reject) {
        axios( {
            method:'GET',
            url:address,
            responseType: 'blob',
            headers: getCommonHeader()
        }).then(function(response) {

            resolve(response);
        }).catch(error => {
            errorHandler(error);
            console.log(error.response);
        });
    })
}
export function writePlaces(items) {
    let address = process.env.VUE_APP_API_BASE_URL + 'admin/places';

    return new Promise(function(resolve, reject) {
        axios({
            method: 'post',
            url: address,
            data: JSON.stringify(items),
            headers: getCommonHeader()
        }).then(function(response){
            let data = response.data;

            resolve(data);
        }).catch(error => {
            errorHandler(error);
            console.log(error.response);
        })
    })
}

export function writePlace(item) {
    let address = process.env.VUE_APP_API_BASE_URL + 'admin/'+ model;

    return new Promise(function(resolve, reject) {
        axios({
            method: 'post',
            url: address,
            data: JSON.stringify(item, function(key, value) {
                // milliseconds 로 전송해야하는데, 자동으로 DateFormat 으로 보내서 코드 추가했음
                if (key === 'createdWhen') return new Date(value).getTime();
                return value;
            }),
            headers: getCommonHeader()
        }).then(function(response){
            let data = response.data;

            resolve(data.item);
        }).catch(error => {
            errorHandler(error);
            reject(error);
            console.log(error.response);
        })
    })
}
export function getMarketCategories(placeId) {
    let address = process.env.VUE_APP_API_BASE_URL + 'admin/market/' + placeId + '/categories';
    EventBus.$emit("showLoading", true, "불러오는중입니다..");
    return new Promise(function(resolve, reject) {
        axios({
            method: 'get',
            url: address,
            headers: getCommonHeader()
        }).then(function(response){
            EventBus.$emit("showLoading", false);
            let data = response.data;

            resolve(data);
        }).catch(error => {
            EventBus.$emit("showLoading", false);
            errorHandler(error);
            reject(error);
            console.log(error.response);
        })
    })
}
export function deleteMarketCategory(placeId, id) {
    let address = process.env.VUE_APP_API_BASE_URL + 'admin/market/' + placeId + '/category/' + id;
    EventBus.$emit("showLoading", true, "삭제중입니다..");
    return new Promise(function(resolve, reject) {
        axios({
            method: 'delete',
            url: address,
            headers: getCommonHeader()
        }).then(function(response){
            EventBus.$emit("showLoading", false);
            let data = response.data;

            resolve(data);
        }).catch(error => {
            EventBus.$emit("showLoading", false);
            errorHandler(error);
            reject(error);
            console.log(error.response);
        })
    })
}
export function updateMarketCategory(placeId, category) {
    let address = process.env.VUE_APP_API_BASE_URL + 'admin/market/' + placeId + '/category';
    EventBus.$emit("showLoading", true, "업데이트중입니다..");
    return new Promise(function(resolve, reject) {
        axios({
            method: 'post',
            url: address,
            data:JSON.stringify(category),
            headers: getCommonHeader()
        }).then(function(response){
            EventBus.$emit("showLoading", false);
            let data = response.data;

            resolve(data);
        }).catch(error => {
            EventBus.$emit("showLoading", false);
            errorHandler(error);
            reject(error);
            console.log(error.response);
        })
    })
}
export function getNewMarketCategory(placeId, parentId) {
    let item = {
        id:0,
        title:'',
        placeId:placeId,
        parentId:parentId,
        pos:0,
    };
    return item;
}
export function getStatement(id) {
    let address = process.env.VUE_APP_API_BASE_URL + 'admin/market/statement'+ id;
    return new Promise(function(resolve, reject) {
        axios({
            method: 'get',
            url: address,
            headers: getCommonHeader()
        }).then(function(response){
            let data = response.data;

            resolve(data);
        }).catch(error => {
            errorHandler(error);
            reject(error);
            console.log(error.response);
        })
    })
}
export function updateStatement(statement) {
    let address = process.env.VUE_APP_API_BASE_URL + 'admin/market/statement/update';
    return new Promise(function(resolve, reject) {
        axios({
            method: 'post',
            url: address,
            data: JSON.stringify(statement),
            headers: getCommonHeader()
        }).then(function(response){
            let data = response.data;

            resolve(data);
        }).catch(error => {
            errorHandler(error);
            reject(error);
            console.log(error.response);
        })
    })
}
export function getMakerSalesOrder(placeId, id) {
    let address = process.env.VUE_APP_API_BASE_URL + 'admin/market/' + placeId + '/order/' + id;
    EventBus.$emit("showLoading", true, "불러오는중입니다..");
    return new Promise(function(resolve, reject) {
        axios({
            method: 'get',
            url: address,
            headers: getCommonHeader()
        }).then(function(response){
            EventBus.$emit("showLoading", false);
            let data = response.data;

            resolve(data);
        }).catch(error => {
            EventBus.$emit("showLoading", false);
            errorHandler(error);
            reject(error);
            console.log(error.response);
        })
    })
}
export function updateStatementItem(id, query) {
    let address = process.env.VUE_APP_API_BASE_URL + 'admin/market/statement/' + id + '/update?' + query;
    return new Promise(function(resolve, reject) {
        axios({
            method: 'get',
            url: address,
            headers: getCommonHeader()
        }).then(function(response){
            let data = response.data;

            resolve(data);
        }).catch(error => {
            errorHandler(error);
            reject(error);
            console.log(error.response);
        })
    })
}
export function deleteStatement(id) {
    let address = process.env.VUE_APP_API_BASE_URL + 'admin/market/statement'+ id;
    return new Promise(function(resolve, reject) {
        axios({
            method: 'delete',
            url: address,
            headers: getCommonHeader()
        }).then(function(response){
            let data = response.data;

            resolve(data);
        }).catch(error => {
            errorHandler(error);
            reject(error);
            console.log(error.response);
        })
    })
}

export function getPlaceStatements(from, to, placeId = 0) {
    let address = process.env.VUE_APP_API_BASE_URL + 'admin/market/' + placeId + '/statements?from='+ from + '&to=' +to ;
    console.log("get statements " + address);
    EventBus.$emit("showLoading", true, "불러오는중입니다..");
    return new Promise(function(resolve, reject) {
        axios({
            method: 'get',
            url: address,
            headers: getCommonHeader()
        }).then(function(response){
            EventBus.$emit("showLoading", false);
            let data = response.data;

            resolve(data);
        }).catch(error => {
            EventBus.$emit("showLoading", false);
            // errorHandler(error);
            reject(error);
            console.log(error.response);
        })
    })
}
export function getStatements(from, to, placeId = 0) {
    let address = process.env.VUE_APP_API_BASE_URL + 'admin/market/statements?from='+ from + '&to=' +to ;
    if(placeId > 0) {
        address += '&placeId=' + placeId;
    }
    console.log("get statements " + address);
    EventBus.$emit("showLoading", true, "불러오는중입니다..");
    return new Promise(function(resolve, reject) {
        axios({
            method: 'get',
            url: address,
            headers: getCommonHeader()
        }).then(function(response){
            EventBus.$emit("showLoading", false);
                let data = response.data;

            resolve(data);
        }).catch(error => {
            EventBus.$emit("showLoading", false);
            // errorHandler(error);
            reject(error);
            console.log(error.response);
        })
    })
}

export function correctVatStatements(id) {
    let address = process.env.VUE_APP_API_BASE_URL + 'admin/market/statement/' + id + '/vat/correction' ;
    console.log("get statements " + address);
    EventBus.$emit("showLoading", true, "불러오는중입니다..");
    return new Promise(function(resolve, reject) {
        axios({
            method: 'get',
            url: address,
            headers: getCommonHeader()
        }).then(function(response){
            EventBus.$emit("showLoading", false);
            let data = response.data;

            resolve(data);
        }).catch(error => {
            EventBus.$emit("showLoading", false);
            // errorHandler(error);
            reject(error);
            console.log(error.response);
        })
    })
}
export function getStatementItems(from, to, placeId = 0) {
    let address = process.env.VUE_APP_API_BASE_URL + 'admin/market/' + placeId + '/statement/items?from='+ from + '&to=' +to ;

    console.log("get statements " + address);
    EventBus.$emit("showLoading", true, "불러오는중입니다..");
    return new Promise(function(resolve, reject) {
        axios({
            method: 'get',
            url: address,
            headers: getCommonHeader()
        }).then(function(response){
            EventBus.$emit("showLoading", false);
            let data = response.data;

            resolve(data);
        }).catch(error => {
            EventBus.$emit("showLoading", false);
            // errorHandler(error);
            reject(error);
            console.log(error.response);
        })
    })
}
export function generateStatements(placeId, from, to, ids) {
    let address = process.env.VUE_APP_API_BASE_URL + 'admin/market/' + placeId + '/statement/generate/' +from + '/' + to ;
    EventBus.$emit("showLoading", true, "생성중입니다..");
    return new Promise(function(resolve, reject) {
        axios({
            method: 'post',
            url: address,
            data: JSON.stringify(ids),
            headers: getCommonHeader()
        }).then(function(response){
            EventBus.$emit("showLoading", false);
            let data = response.data;

            resolve(data);
        }).catch(error => {
            EventBus.$emit("showLoading", false);
            errorHandler(error);
            reject(error);
            console.log(error.response);
        })
    })
}

export function newPlace() {
  return   {
      id: 0,
      name: '',
      type: '',
      address: '',
      contact: '',
      lat: 0.0,
      lon: 0.0,
      siteAddress: '',
      openingTime: '',
      reviews: [],
      imageUrls: [],
      category:'',
      email:'',
      commissionRate:0,
      bankName:'',
      accountNumber:'',
      subscriptionFee:0,
      companyType:'G',
      onlineRegNumber:'',
      hours:[],
      companyName:'',
      companyNumber:'',
      representative:'',
      mapEmbed:'',
      tags:[],
      hotDeal:false,
      cardTitle:'',
      cardSubtitle:'',
      cardBackground:'',
      cardRate:'',
      cardLocation:'',
      description:'',
      images:[],
      videoLink:'',
      placeClass:'booking',
      commissionRateFloat:0.0,
    //   qrCode: '',
  }
}

export function getPlaceTypes() {
    let list = [
        {
            name:'무인카페',
            value:'unmanned-cafe',
        },
        {
            name: '아이스크림',
            value:'icecream',
        },
        {
            name: '빨래방',
            value:'laundry',
        },
        {
            name: '편의점',
            value:'convenient-store',
        },
        {
            name: '무인기타',
            value:'others',
        },
        {
            name:'카페',
            value:'cafe',
        },
        {
            name:'음식점',
            value:'restaurant',
        },

        {
            name:'약국',
            value:'pharmacy',
        },
        {
            name:'관광',
            value:'tour',
        },
        {
            name:'오락',
            value:'fun',
        },
        {
            name: '요트',
            value:'yacht',
        },
        {
            name:'낚시',
            value:'fishing',
        },
        {
            name:'예약기타',
            value:'booking-others'
        }
    ];
    return list;
}
export function getPlaceClassList() {
    let list = [
        {
            name:'일반',
            value:'general',
        },
        {
            name:'예약',
            value:'booking',
        },
        {
            name:'마켓',
            value:'market',
        },

    ];
    return list;
}
export function getPlaceAsset(placeId, id) {
    let address = process.env.VUE_APP_API_BASE_URL + `admin/shop/${placeId}/asset/${id}`;
    return new Promise(function(resolve, reject) {
        axios( {
            method:'GET',
            url:address,
            headers: getCommonHeader()
        }).then(function(response) {

            console.log("place user response");
            console.log(response);
            resolve(response.data);
        }).catch(error => {
            errorHandler(error);
            console.log(error.response);
            reject(error);
        });
    })
    // return getItemListByURLPath(`admin/${model}/${id}/users`);
}
export function deletePlaceAsset(placeId, id) {
    let address = process.env.VUE_APP_API_BASE_URL + `admin/shop/${placeId}/asset/${id}`;
    return new Promise(function(resolve, reject) {
        axios( {
            method:'DELETE',
            url:address,
            headers: getCommonHeader()
        }).then(function(response) {

            console.log("place user response");
            console.log(response);
            resolve(response.data);
        }).catch(error => {
            errorHandler(error);
            console.log(error.response);
            reject(error);
        });
    })
    // return getItemListByURLPath(`admin/${model}/${id}/users`);
}
export function getPlaceAssets(placeId) {
    let address = process.env.VUE_APP_API_BASE_URL + `admin/shop/${placeId}/assets`;
    return new Promise(function(resolve, reject) {
        axios( {
            method:'GET',
            url:address,
            headers: getCommonHeader()
        }).then(function(response) {

            console.log("place user response");
            console.log(response);
            resolve(response.data);
        }).catch(error => {
            errorHandler(error);
            console.log(error.response);
            reject(error);
        });
    })
    // return getItemListByURLPath(`admin/${model}/${id}/users`);
}
export function updatePlaceAsset(placeId, asset) {
    let address = process.env.VUE_APP_API_BASE_URL + `admin/shop/${placeId}/asset`;
    return new Promise(function(resolve, reject) {
        axios( {
            method:'POST',
            url:address,
            data:JSON.stringify(asset),
            headers: getCommonHeader()
        }).then(function(response) {

            console.log("place user response");
            console.log(response);
            resolve(response.data);
        }).catch(error => {
            errorHandler(error);
            console.log(error.response);
            reject(error);
        });
    })
    // return getItemListByURLPath(`admin/${model}/${id}/users`);
}

export function openFridgeRelay(placeId, id) {
    let address = process.env.VUE_APP_API_BASE_URL + `admin/shop/${placeId}/fridge/${id}/open`;
    EventBus.$emit("showLoading", true, "Sending command");
    return new Promise(function(resolve, reject) {
        axios( {
            method:'GET',
            url:address,
            headers: getCommonHeader()
        }).then(function(response) {
            EventBus.$emit("showLoading", false);
            console.log("place user response");
            console.log(response);
            resolve(response.data);
        }).catch(error => {
            EventBus.$emit("showLoading", false);
            errorHandler(error);
            console.log(error.response);
            reject(error);
        });
    })
    // return getItemListByURLPath(`admin/${model}/${id}/users`);
}

export function openGateRelay(placeId, id) {
    let address = process.env.VUE_APP_API_BASE_URL + `admin/shop/${placeId}/door/${id}/open`;
    EventBus.$emit("showLoading", true, "Sending command");
    return new Promise(function(resolve, reject) {
        axios( {
            method:'GET',
            url:address,
            headers: getCommonHeader()
        }).then(function(response) {
            EventBus.$emit("showLoading", false);
            console.log("place user response");
            console.log(response);
            resolve(response.data);
        }).catch(error => {
            EventBus.$emit("showLoading", false);
            errorHandler(error);
            console.log(error.response);
            reject(error);
        });
    })
    // return getItemListByURLPath(`admin/${model}/${id}/users`);
}


export function toggleTimerRelay(placeId, id, onOff) {
    let address = process.env.VUE_APP_API_BASE_URL + `admin/shop/${placeId}/timer/${id}/toggle?onOff=` + onOff;
    EventBus.$emit("showLoading", true, "Sending command");
    return new Promise(function(resolve, reject) {
        axios( {
            method:'GET',
            url:address,
            headers: getCommonHeader()
        }).then(function(response) {
            EventBus.$emit("showLoading", false);
            console.log("place user response");
            console.log(response);
            resolve(response.data);
        }).catch(error => {
            EventBus.$emit("showLoading", false);
            errorHandler(error);
            console.log(error.response);
            reject(error);
        });
    })
    // return getItemListByURLPath(`admin/${model}/${id}/users`);
}
export function getPlaceAssetAccessLogs(pId, paId, pagination) {
    let address = `${process.env.VUE_APP_API_BASE_URL}admin/shop/${pId}/asset/${paId}/logs?`;


    if(pagination != null) {
        address += `page=${pagination.page}`;
        address += `&size=${pagination.size}`;
        address += `&sort=${pagination.sort}`;
    }
    console.log("get place asset access log " + address);
    return new Promise(function(resolve, reject) {
        axios({
            method: 'get',
            url: address,
            headers: getCommonHeader()
        }).then(function(response){
            console.log("place asset log ");
            console.log(response.data);
            let data = response.data;
            resolve(data)
        }).catch(error => {
            errorHandler(error);
            reject(error);
            console.log(error.response);
        })
    })
}
export function newPlaceAsset(placeId) {
    let item = {
        id:0,
        placeId:placeId,
        title:'',
        assetType:'door',
        idx:-1,
        startHour:-1,
        endHour:-1,
        ipAddress:'',
        macAddress:'',
        serialNumber:'',

    };
    return item;
}


export function addShopProductStock(stock) {
    let address = process.env.VUE_APP_API_BASE_URL + `admin/shop/product/stock`;
    return new Promise(function(resolve, reject) {
        axios( {
            method:'POST',
            url:address,
            data:JSON.stringify(stock),
            headers: getCommonHeader()
        }).then(function(response) {

            console.log("place user response");
            console.log(response);
            resolve(response.data);
        }).catch(error => {
            errorHandler(error);
            console.log(error.response);
            reject(error);
        });
    })
    // return getItemListByURLPath(`admin/${model}/${id}/users`);
}

export function getShopProductStocks(pId, productId, from, to) {
    let address = process.env.VUE_APP_API_BASE_URL + 'admin/shop/' + pId + '/product/' + productId + '/stocks/' + from + '/' + to;
    return new Promise(function(resolve, reject) {
        axios( {
            method:'GET',
            url:address,
            headers: getCommonHeader()
        }).then(function(response) {

            console.log("place user response");
            console.log(response);
            resolve(response.data);
        }).catch(error => {
            errorHandler(error);
            console.log(error.response);
            reject(error);
        });
    })
    // return getItemListByURLPath(`admin/${model}/${id}/users`);
}
export function newStockItem(placeId, productId) {
    let item = {
        id:0,
        placeId:placeId,
        productId:productId,
        previousBalance:0,
        stock:0,
        balance:0,
        type:'stock_in',
        userId:0,
        orderId:0,
        orderItemId:0,
        createdWhen:0,
        toPlaceId:0,
    }
    return item;
}


export function newCardItem(placeId) {
    let item = {
        id:0,
        placeId:placeId,
        cardNumber:'',
        expMM:'',
        expYY:'',
        ccv:'',
    };
    return item;
}

export function getPlaceCardList(pId) {
    let address = process.env.VUE_APP_API_BASE_URL + `admin/shop/` + pId + '/cards';
    return new Promise(function(resolve, reject) {
        axios( {
            method:'GET',
            url:address,
            headers: getCommonHeader()
        }).then(function(response) {

            console.log("place user response");
            console.log(response);
            resolve(response.data);
        }).catch(error => {
            errorHandler(error);
            console.log(error.response);
            reject(error);
        });
    })

}
export function registerNewCard(pId, card) {
    let address = process.env.VUE_APP_API_BASE_URL + `admin/shop/` + pId + '/card';
    return new Promise(function(resolve, reject) {
        axios( {
            method:'POST',
            url:address,
            data:JSON.stringify(card),
            headers: getCommonHeader()
        }).then(function(response) {

            console.log("place user response");
            console.log(response);
            resolve(response.data);
        }).catch(error => {
            errorHandler(error);
            console.log(error.response);
            reject(error);
        });
    })

}
export function deletePlaceCard(pId, id) {
    let address = process.env.VUE_APP_API_BASE_URL + `admin/shop/` + pId + '/card/'+ id;
    return new Promise(function(resolve, reject) {
        axios( {
            method:'DELETE',
            url:address,
            headers: getCommonHeader()
        }).then(function(response) {

            console.log("place user response");
            console.log(response);
            resolve(response.data);
        }).catch(error => {
            errorHandler(error);
            console.log(error.response);
            reject(error);
        });
    })

}
export function setDefaultPlaceCard(pId, id) {
    let address = process.env.VUE_APP_API_BASE_URL + `admin/shop/` + pId + '/card/'+ id + '/default';
    return new Promise(function(resolve, reject) {
        axios( {
            method:'GET',
            url:address,
            headers: getCommonHeader()
        }).then(function(response) {

            console.log("place user response");
            console.log(response);
            resolve(response.data);
        }).catch(error => {
            errorHandler(error);
            console.log(error.response);
            reject(error);
        });
    })

}

export function getStockTypeString(type) {
    if(type === 'stock_in') {
        return '입고';
    } else if(type === 'stock_out') {
        return '출고';
    } else if(type === 'stock_correction') {
        return '조정';
    }
    return type;
}
