<template>
    <div style="width: 100%; height: 100%;">
        <div style="width: 100%; height: 50px; line-height: 50px;">
            <date-picker v-model="dateRange" valueType="format" @change="dateRangeSelected" range></date-picker>
            <button class="btn btn-success" @click="getPlaceOrderListByTime">기간으로 가져오기</button>
            <button class="btn btn-primary" @click="getPlaceOrderListByState(0, 90)">진행중인 주문 보기</button>
            <button class="btn btn-primary" @click="getPlaceOrderListByState(70, 80)">배송중</button>
        </div>
        <div style="width: 100%; height: calc(100% - 50px); overflow-y: scroll;">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <table class="table">
                            <tr>
                                <th>주문일</th>
                                <th>상태</th>
                                <th>금액</th>
                                <th>VAT</th>
                                <th>배송료</th>
                                <th>총</th>
                                <th>아이템</th><th>수량</th><th>가격</th><th>VAT</th><th>총</th>
                            </tr>
                            <tbody v-for="order in orders">
                            <tr v-for="(item, ii) in order.items">
                                <td><span v-if="ii === 0">{{dateString(order.orderedTime)}}</span></td>
                                <td>
                                    <span v-if="ii === 0">{{getStateText(order.state)}}</span>

                                </td>
                                <td><span v-if="ii === 0">{{order.totalPrice}}</span></td>
                                <td><span v-if="ii === 0">{{order.tax}}</span></td>
                                <td><span v-if="ii === 0">{{order.deliveryCharge}}</span></td>
                                <td><span v-if="ii === 0">{{order.total}}</span></td>

                                <td>{{item.title}}</td>
                                <td>{{item.amount}}</td>
                                <td>{{item.totalPrice}}</td>
                                <td>{{item.tax}}</td>
                                <td>{{item.deliveryCharge}}</td>
                                <td>{{item.total}}</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td colspan="11">
                                    <span v-if="order.state === 0" style="margin-right: 30px;"><button class="btn btn-danger" @click="cancelPlaceOrder(order.id)">주문 취소</button></span>
                                    <span v-if="order.state === 70" style="margin-right: 30px;"><button class="btn btn-danger" @click="completePlaceOrder(order)">수령 및 완료</button></span>
                                    <label v-if="order.parcelNumber !== null && order.parcelNumber.length > 0" style="margin-right: 30px;">배송번호: {{order.parcelNumber}}</label>
                                    <span v-if="order.card > 0" style="margin-right: 30px;">카드결제: {{order.card}} ({{dateTimeString(order.transactionTime)}})</span>
                                    <span v-if="order.transfer > 0" style="margin-right: 30px;">송금결제: {{order.transfer}}</span>
                                    <button v-if="order.invoiceUrl !== null && order.invoiceUrl.length > 0" class="btn btn-primary" @click="openNewTab(order.invoiceUrl)">명세서</button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {showDialog} from "../api/common";
import {getPlaceOrderList, placeOrderStateList, cancelPlaceOrder, completePlaceOrder} from "../api/product";
import {millisToDate, millisToReadable} from "../utils/TimeConverter";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
export default {
    name: "PlaceOrdersViewer",
    components: {
        DatePicker
    },
    props: {
        place: {
            type:Object,
            default: function(){return null;}
        }
    },
    data() {
        return {
            dateRange:null,
            orders:[],
            stateList: placeOrderStateList(),
        }
    },
    created() {
    },
    mounted() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    methods: {
        dateTimeString: millisToReadable,
        dateString:millisToDate,
        dummy: function() {
            console.log("dummy");
        },
        getStateText: function(state) {
          for(let i = 0 ; i < this.$data.stateList.length ; i++) {
              if(state === this.$data.stateList[i].val) {
                  return this.$data.stateList[i].title;
              }
          }
          return state;
        },
        dateRangeSelected: function() {
            console.log("dateRangeSelected")
            console.log(this.$data.dateRange)

            if(this.$data.dateRange.length !== 2) {
                return;
            }

            if(this.$data.dateRange[0] > this.$data.dateRange[1]) {
                let temp = this.$data.dateRange[1];
                this.$data.dateRange[0] = this.$data.dateRange[1];
                this.$data.dateRange[1] = temp;
            }

        },
        getPlaceOrderListByTime: function() {
            if(this.$data.dateRange === null || this.$data.dateRange.length !== 2) {
                showDialog("기간을 선택해주세요")
            }

            let fromDate = new Date(this.$data.dateRange[0]);
            fromDate.setHours(0,0,0, 0);

            let toDate = new Date(this.$data.dateRange[1]);
            toDate.setHours(23, 59, 59, 999);

            this.$data.orders = [];

            let self = this;
            let query = "placeId=" + this.place.id + "&timeFrom=" + fromDate.getTime() + "&timeTo=" + toDate.getTime();
            getPlaceOrderList(query)
            .then(function (data) {
                if(data.success === true) {
                    self.$data.orders = data.item;
                } else {
                    showDialog(data.message);
                }
            })
            .catch(function(err) {
                let msg = err;
                if(err.response !== undefined) {
                    msg += ' (' + err.response + ')';
                }
                showDialog(msg, "ERROR");
            })


        },
        getPlaceOrderListByState: function(from, to) {
            if(this.place.id === 0) {
                showDialog("매장을 선택하세요");
                return;
            }
            this.$data.orders = [];
            let self = this;
            let query = 'placeId=' + this.place.id + '&stateFrom=' + from  + '&stateTo=' + to;
            getPlaceOrderList(query)
            .then(function(data) {
                if(data.success === true) {
                    self.$data.orders = data.item;
                } else {
                    showDialog(data.message);
                }
            })
            .catch(function(err) {
                let msg = err;
                if(err.response !== undefined) {
                    msg += ' (' + err.response + ')';
                }
                showDialog(msg, "ERROR");
            })
        },
        onOrderUpdated: function(item) {
          for(let i = 0 ; i < this.$data.orders.length ; i++) {
              if(this.$data.orders[i].id === item.id) {
                  this.$data.orders.splice(i, 1, item);
                  return;
              }
          }
        },
        cancelPlaceOrder: function(id) {
            if(this.place.id === 0) {
                return;
            }

            let self = this;
            cancelPlaceOrder(this.place.id, id)
            .then(function(data) {
                if(data.success === true) {
                    self.onOrderUpdated(data.item);
                } else {
                    showDialog(data.message);
                }
            })
            .catch(function(err) {
                console.log(err);
                if(err.response !== undefined) {
                    console.log(err.response);
                }

            })
        },
        completePlaceOrder: function(order) {

            let self = this;
            completePlaceOrder(this.place.id, order.id, order.token)
            .then(function(data) {
                if(data.success === true) {
                    self.onOrderUpdated(data.item);
                } else {
                    showDialog(data.message);
                }
            })
            .catch(function(err) {
                console.log(err);
                let msg = err;
                if(err.response !== undefined) {
                    console.log(err.response);
                    msg += ' (' + err.response + ' )';
                }
                showDialog(msg, "ERROR");
            })
        },
        openNewTab: function(url) {
            window.open(url, '_blank');
        }
    }
}
</script>

<style scoped>

</style>
