import {errorHandler, getCommonHeader} from "./common";
import {cleanLocalStorage} from "../store/auth.module";
import {getUserId} from "../store";

const axios = require('axios');

export function getOperations(type, userId = getUserId()) {
    let address = `${process.env.VUE_APP_API_BASE_URL}admin/operations`;

    let isFirstCondition = false;
    if(type != null && type !== 'all') {
        address += '?type=' + type;

        isFirstCondition = false;
    }

    // if(userId != null){
    //     address += isFirstCondition ? "?" : "&";
    //
    //     address += `userId=${userId}`;
    // }

    console.log("getOperations " + address);
    return new Promise(function(resolve, reject) {
        axios({
            method: 'get',
            url: address,
            headers: getCommonHeader()
        }).then(function(response){
            let data = response.data;

            if(data.success)
                resolve(data.list);
            else
                console.log(data.message);
        }).catch(error => {
            errorHandler(error);
            console.log(error.response);
        })
    })
}

export function writeOperation(item) {
    let address = process.env.VUE_APP_API_BASE_URL + 'admin/operation';

    return new Promise(function(resolve, reject) {
        axios({
            method: 'post',
            url: address,
            data: JSON.stringify(item),
            headers: getCommonHeader()
        }).then(function(response){
            let data = response.data;

            if(data.success)
                resolve(data.item);
            else {
                console.log(data.message);
            }
        }).catch(error => {
            errorHandler(error);
            reject(error);
        })
    })
}

export function newOperation() {
  return   {
      id: 0,
      parentId: 0,
      operatorId: 0,
      userId: 0,

      assetId: 0,
      reporterId: 0,

      status: 0,

      title: "",
      name: "",
      type: "",
      content: "",

      createdWhen: null,
      completedWhen: null,

      deleted: false,

      tags: [],
  }
}

export function getStatusList() {
    let list = [
        {
            name:'생성',
            value:0,
        },
        {
            name:'할당',
            value:1,
        },
        {
            name:'진행',
            value:2,
        },
        {
            name:'완료',
            value:5,
        }
    ]
    return list;
}
export function getOperationTypes() {
    let list = [
        {
            name:'작업',
            value:'assign',
        },
        {
            name:'보고',
            value:'report',
        },
        {
            name:'고객문의',
            value:'customer_request'
        }
    ]
    return list;
}
