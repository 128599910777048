<template>
    <div class="detail-view">

        <div class="container-fluid h-100">
            <div class="row h-100">
                <div class="col-6 h-100">
                    <map-view
                        :assets="points"
                        :clickListener="clickOnMap"
                        :onMoveListener="onPointMoved"
                        :editable="isEditMode"
                        :model-key="modelKey"/>

                </div>
                <div class="col-6 h-100" style="overflow-y: scroll;">

                    <div class="col-12 text-right mary-button-container" style="position: sticky; top:0; background-color: white; z-index: 2; box-shadow: 2px 2px 3px gray;">
                        <div class="mary-button" style="background-color: black;" v-on:click="onClickCloseBtn()"><span><i class="fas fa-times"></i></span> {{$t('detail.close')}}</div>
                        <div class="mary-button" v-show="!isEditMode" v-on:click="onClickEditBtn()"><span><i class="fas fa-edit"></i></span> {{$t('detail.edit')}}</div>
                        <div class="mary-button" v-show="isEditMode" v-on:click="onClickSaveBtn()"><span><i class="fas fa-save"></i></span> {{$t('detail.save')}}</div>
                        <div class="mary-button" style="background-color: red; float:left;"
                             v-show="!isAddMode && !points[0].deleted"
                             v-on:click="onClickRemoveBtn()"><span><i class="fas fa-trash"></i></span> {{$t('detail.remove')}}</div>
                    </div>

                    <div class="col-12">
                        <div class="head-group">
                            <div class="bottom">
                                <div class="content-header">
                                    <vue-form style="width: 100%;" label-width="25%">
                                        <AccessDropdownInput
                                            :is-read-only="!isEditMode"
                                            v-bind:operatorId="points[0].operatorId"
                                            v-on:onClick="setOperatorId"/>
                                        <hr>
                                        <!--                        <vue-form-item :label="$t('content.parentId')">-->
                                        <!--                            <vue-input-->
                                        <!--                                type="number"-->
                                        <!--                                v-model="points[0].parentId"-->
                                        <!--                                :disabled="!isEditMode">-->
                                        <!--                            </vue-input>-->
                                        <!--                        </vue-form-item>-->
                                        <!--                        <vue-form-item :label="$t('content.userId')">-->
                                        <!--                            <vue-input-->
                                        <!--                                v-model="points[0].userId"-->
                                        <!--                                :disabled="!isEditMode">-->
                                        <!--                            </vue-input>-->
                                        <!--                        </vue-form-item>-->
                                        <!--                        <vue-form-item :label="$t('content.title')">-->
                                        <!--                            <vue-input-->
                                        <!--                                v-model="points[0].title"-->
                                        <!--                                :disabled="!isEditMode">-->
                                        <!--                            </vue-input>-->
                                        <!--                        </vue-form-item>-->
                                        <vue-form-item :label="$t('content.name')">
                                            <vue-input
                                                v-model="points[0].name"
                                                :disabled="!isEditMode">
                                            </vue-input>
                                        </vue-form-item>
                                        <!--                        <vue-form-item :label="$t('content.type')">-->
                                        <!--                            <vue-input-->
                                        <!--                                v-model="points[0].type"-->
                                        <!--                                :disabled="!isEditMode">-->
                                        <!--                            </vue-input>-->
                                        <!--                        </vue-form-item>-->
                                        <!--                        <vue-form-item :label="$t('asset.code')">-->
                                        <!--                            <vue-input-->
                                        <!--                                v-model="points[0].code"-->
                                        <!--                                :disabled="!isEditMode">-->
                                        <!--                            </vue-input>-->
                                        <!--                        </vue-form-item>-->
                                        <!--                        <vue-form-item :label="$t('asset.operatorCode')">-->
                                        <!--                            <vue-input-->
                                        <!--                                v-model="points[0].operatorCode"-->
                                        <!--                                :disabled="!isEditMode">-->
                                        <!--                            </vue-input>-->
                                        <!--                        </vue-form-item>-->
                                        <!--                        <vue-form-item :label="$t('asset.areaCode')">-->
                                        <!--                            <vue-input-->
                                        <!--                                v-model="points[0].areaCode"-->
                                        <!--                                :disabled="!isEditMode">-->
                                        <!--                            </vue-input>-->
                                        <!--                        </vue-form-item>-->
                                        <!--                        <vue-form-item :label="$t('asset.regionCode')">-->
                                        <!--                            <vue-input-->
                                        <!--                                v-model="points[0].regionCode"-->
                                        <!--                                :disabled="!isEditMode">-->
                                        <!--                            </vue-input>-->
                                        <!--                        </vue-form-item>-->
                                        <!--                        <vue-form-item :label="$t('asset.modelCode')">-->
                                        <!--                            <vue-input-->
                                        <!--                                v-model="points[0].modelCode"-->
                                        <!--                                :disabled="!isEditMode">-->
                                        <!--                            </vue-input>-->
                                        <!--                        </vue-form-item>-->
                                        <!--                        <vue-form-item :label="$t('asset.fullCode')">-->
                                        <!--                            <vue-input-->
                                        <!--                                v-model="points[0].fullCode"-->
                                        <!--                                :disabled="!isEditMode">-->
                                        <!--                            </vue-input>-->
                                        <!--                        </vue-form-item>-->
                                        <!--                        <vue-form-item :label="$t('asset.channel')">-->
                                        <!--                            <vue-input-->
                                        <!--                                v-model="points[0].channel"-->
                                        <!--                                :disabled="!isEditMode">-->
                                        <!--                            </vue-input>-->
                                        <!--                        </vue-form-item>-->
                                        <vue-form-item :label="$t('area.type')">
                                            <vue-radio
                                                v-model="onOffValue"
                                                @change="onEnableSelected"

                                                :value=1
                                                :disabled="!isEditMode">
                                                서비스지역
                                            </vue-radio>
                                            <vue-radio
                                                v-model="onOffValue"
                                                @change="onEnableSelected"

                                                :value=0
                                                :disabled="!isEditMode">
                                                서비스불가지역
                                            </vue-radio>
                                        </vue-form-item>

                                        <vue-form-item label="감추기">
                                            <vue-radio
                                                v-model="invisible"
                                                @change="onEnableInvisibe"

                                                :value=1
                                                :disabled="!isEditMode">
                                                감추기
                                            </vue-radio>
                                            <vue-radio
                                                v-model="invisible"
                                                @change="onEnableInvisibe"

                                                :value=0
                                                :disabled="!isEditMode">
                                                보이기
                                            </vue-radio>
                                        </vue-form-item>


                                        <vue-form-item label="폴리곤 순서">
                                            <vue-radio
                                                v-model="reverseOrder"
                                                @change="onEnableReverseOrder"

                                                :value=0
                                                :disabled="!isEditMode">
                                                정순서
                                            </vue-radio>
                                            <vue-radio
                                                v-model="reverseOrder"
                                                @change="onEnableReverseOrder"

                                                :value=1
                                                :disabled="!isEditMode">
                                                역순서
                                            </vue-radio>
                                        </vue-form-item>
                                        <!--                        <vue-form-item :label="$t('asset.status')">-->
                                        <!--                            <vue-input-->
                                        <!--                                v-model="points[0].status"-->
                                        <!--                                :disabled="!isEditMode">-->
                                        <!--                            </vue-input>-->
                                        <!--                        </vue-form-item>-->
                                        <hr>
                                        <div class="btns" v-if="isEditMode">
                                            <div class="naim-btn" v-on:click="onAddPoint()">
                                                {{$t('asset.area_add')}}
                                                <span class="fas fa-plus"/>
                                            </div>
                                        </div>
                                        <div v-for="(p, index) in points" class="hover-item-view text-right">
                                            <vue-form-item :label="p.id === undefined ? 'NEW' : `ID: ${p.id}`">
                                                <vue-button v-if="p.parentId !== 0" class="naim-btn" v-on:click="onDelPoint(index)">
                                                    <span class="fas fa-trash"/>
                                                </vue-button>
                                            </vue-form-item>
                                            <vue-form-item :label="`${$t('asset.lat')}`">
                                                <vue-input
                                                    v-model="p.lat"
                                                    :disabled="!isEditMode">
                                                </vue-input>
                                            </vue-form-item>
                                            <vue-form-item :label="`${$t('asset.lon')}`">
                                                <vue-input
                                                    v-model="p.lon"
                                                    :disabled="!isEditMode">
                                                </vue-input>
                                            </vue-form-item>
                                        </div>
                                        <hr>
                                        <hash-tag-input v-model="points[0].tags" v-bind:isReadOnly="!isEditMode"/>
                                    </vue-form>
                                </div><!-- ./content-header -->
                            </div><!-- ./bottom -->
                        </div><!-- ./head-group -->
                    </div>
                </div>
            </div>

        </div>



    </div>
</template>

<script>
    import {deleteArea, newBoundary, writeBoundary} from "../../../api/asset";
    import {millisToReadable} from "../../../utils/TimeConverter";
    import AccessDropdownInput from "../../../inputs/OperatorDropdownInput";
    import HashTagInput from "../../../inputs/HashTagInput";
    import MapView from "../../../views/BoundaryMapView";
    import {setTagsByOperatorId} from "../../../api/operator";
    import {changeURL} from "../../../api/common";

    export default {
        name: "DetailAsset",
        components: {
            MapView,
            AccessDropdownInput,
            HashTagInput
        },
        props: {
            points: {
                type: Array,
                default: newBoundary("")
            },
            isAddMode: {
                type: Boolean,
                default: true
            },
            modelKey: ''
        },
        data(){
          return {
              isEditMode: this.isAddMode,
              onOffValue: this.points[0].onOff ? 1 : 0,
              reverseOrder: this.points[0].reverseOrder ? 1 : 0,
              invisible: this.points[0].invisible ? 1 : 0,
          }
        },
        created() {
            this.isEditMode = this.isAddMode;
            console.log("area ");
            console.log(this.points[0]);
        },
        computed:{
            name: function(){
                return this.points[0].name
            }
        },
        methods: {
            onEnableSelected: function(value){
                this.points[0].onOff = value === 1;
            },
            onEnableReverseOrder: function(value) {
                this.points[0].reverseOrder = value === 1;
            },
            onEnableInvisibe: function(value) {
                this.points[0].invisible = value === 1;
            },
            clickOnMap: function(ev) {
                console.log("Editor Map Click Event " + ev.latLng) ;
                console.log(ev);
                if(1 === this.points.length && 0.0 === this.points[0].lat && 0.0 === this.points[0].lon) {
                    this.points[0].lat = ev.latLng.lat();
                    this.points[0].lon = ev.latLng.lng();
                } else {
                    this.points.push({
                        type: this.points[0].type,
                        lat: ev.latLng.lat(),
                        lon: ev.latLng.lng()
                    })
                }
            },
            onPointMoved: function(ev, index) {
                console.log("onPointMoved")
                this.points[index].lat = ev.latLng.lat();
                this.points[index].lon = ev.latLng.lng();
                console.log(this.points[index]);
            },
            setOperatorId: function(id) {
                setTagsByOperatorId(id, this.points[0]);
            },
            onClickSaveBtn: function() {
                let that = this;
                console.log("onClickSaveBtn");
                console.log(that.points);
                writeBoundary(that.points).then(function (item) {
                    alert("저장 되었습니다.");
                    console.log(item);
                    that.isEditMode = false;

                    changeURL(that.modelKey, that.$route, that.$router, item);

                    that.$emit("onChanged");
                })
            },
            onClickCloseBtn: function() {
                if(this.isEditMode){
                    this.isEditMode = false;

                    return;
                }

                changeURL(this.modelKey, this.$route, this.$router);
                // if(this.isAddMode || !this.isEditMode) {
                //     this.$router.push(`/admin/${this.modelKey}`);
                // } else {
                //     this.$router.push(`/admin/${this.modelKey}?id=${this.$route.query.id}`);
                // }
            },
            onClickEditBtn: function() {
                // TODO: 삭제된 아이템을 수정하여 저장하는 경우 다시 살릴것인지 묻기
                this.isEditMode = true;

                // this.$router.push(`/admin/${this.modelKey}?id=${this.$route.query.id}&edit`);
            },
            onClickRemoveBtn: function() {
                let areaName = this.points[0].name === "" ? "해당 영역" : `"${this.points[0].name}"`;

                let self = this;
                this.$modal.show('dialog', {
                    title: '',
                    text: areaName + '을 정말로 삭제 하시겠습니까?',
                    buttons:[
                        {
                            title: '아니요',
                            handler:() => {
                                self.$modal.hide('dialog');
                            }
                        },
                        {
                            title:'삭제',
                            handler:()=>{
                                self.$modal.hide('dialog');
                                self.onClickRemoveBtnConfirmed();
                            }
                        }
                    ]
                })
            },
            onClickRemoveBtnConfirmed: function () {
                let that = this;

                deleteArea(that.points[0].id).then( item => {
                    changeURL(that.modelKey, that.$route, that.$router);

                    that.$emit("onChanged");
                });
            },
            onClickCancelBtn: function () {
                changeURL(this.modelKey, this.$route, this.$router);
            },
            onAddPoint: function()
            {
                this.points.push({
                    parentId: this.points[0].id,
                    type: this.points[0].type,
                    lat: 0.0,
                    lon: 0.0
                })
            },
            onDelPoint: function(index)
            {
                let point = this.points[index]

                if(point.id === undefined) {
                    this.points.splice(index, 1)

                    return
                }

                this.$modal.show('dialog', {
                    title: '',
                    text: `'${this.name}' 구역 ${point.id}번 좌표를 삭제 하시겠습니까?`,
                    buttons:[
                        {
                            title: '아니요',
                            handler:() => {
                                this.$modal.hide('dialog');
                            }
                        },
                        {
                            title:'삭제',
                            handler:()=>{
                                this.$modal.hide('dialog');

                                let that = this;
                                deleteArea(point.id).then( item => {
                                    that.points.splice(index, 1)
                                });
                            }
                        }
                    ]
                })
            },
            millisToReadable: millisToReadable
        }
    }
</script>
<style lang="scss" scoped>
    .detail-wrapper {
        padding-bottom: 20px;
    }
    .detail-view {
        padding:0;
        width: 100%;
        height: 100%;
        /*overflow-y: scroll;*/
        /*padding-top: 370px;*/
    }
    /*.fixed-top {*/
    /*    position: absolute;*/
    /*    width: 100%;*/
    /*    top: 0;*/
    /*    left:0;*/
    /*    padding:20px;*/
    /*    background-color: white;*/
    /*}*/
    .btns {
        height:30px;
        line-height: 30px;
    }
    .hover-item-view {
        padding:10px;
        border: 1px solid gray;
        margin-bottom: 10px;
        box-shadow: 0px 0px 8px rgba(0,0,0,0.3);
        transition: all 0.2s ease-in;
    }
    .hover-item-view:hover {
        box-shadow: 5px 5px 10px rgba(0,0,0,0.3);
        transform: translate(-3px, -3px);
        background: #ECF0F1;
    }
    .map-marker {
        transition: all 0.2s ease-in;
    }
    .hover-item-view:hover .map-marker {
        transform: scale(1.1);
    }
</style>
