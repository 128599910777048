<template>
<!--    <div class="container-fluid">-->
        <div class="row">
            <div v-if="sortTypeList.length > 0" class="col-6 dropdown-input input">
                <label>{{$t('list.select_sort_type')}}</label>
                <div class="dropdown sort-dropdown">
                    <button class="btn btn-default dropdown-toggle" type="button" data-toggle="dropdown">
                        {{currentSortType.name}}
                        <span class="caret"/>
                    </button>
                    <ul class="dropdown-menu">
                        <li v-for="item in sortTypeList">
                            <a v-on:click="onClickList(item)">{{item.name}}</a>
                        </li>
                    </ul>
                </div>
                <!--            <div class="btn" v-if="enableRefresh"-->
                <!--                 style="float: right"-->
                <!--                 v-on:click="onClickRefresh()">-->
                <!--                새로고침 <div class="fas fa-sync-alt"/>-->
                <!--            </div>-->
            </div>
            <div :class="{'col-6': sortTypeList.length > 0, 'col-12': sortTypeList.length == 0}" v-if="labelSearch != null">
                <input class="form-control"
                       :type="String"
                       v-model="valueStr"
                       v-bind:placeholder="labelSearch + ' 검색'"
                       v-on:keyup.enter="onEnter"/>
            </div>
        </div>
<!--    </div>-->
</template>

<script>
    import DropdownInput from "./DropdownInput";
    export default {
        name: 'SortAndSearchInput',
        components: {DropdownInput},
        props: {
            sortTypeList: {
                type: Array,
                default: function () {return []},
            },
            labelSearch: {
                type: String,
                default: "태그"
            },
            // enableRefresh: {
            //     type: Boolean,
            //     default: false
            // }
        },
        data() {
            return {
                currentSortType: null,
                valueStr: "",
            }
        },
        // watch: {
        //     valueStr: function(newVal) {
        //         this.$emit('onInput', newVal);
        //     },
        // },
        created() {
            if(this.currentSortType == null && this.sortTypeList.length > 0){
                this.currentSortType = this.sortTypeList[0]
            }
        },
        methods: {
            onClickList: function(item) {
                this.currentSortType = item;

                this.$emit('onClickSortType', item);
            },
            onEnter: function() {
                this.$emit('onEnterSearch', this.valueStr);
            },
            onClickRefresh: function () {
                this.$emit('onClickRefresh');
            }
        },
    };
</script>

<style lang="scss">
    label,
    .sort-dropdown {
        display: inline-block;
    }

    label,
    input::placeholder {
        margin-right: 10px;
        font-size: 14px;
    }

    .dropdown ul a {
        cursor: pointer;
    }
</style>
