import {createItem, deleteItem, errorHandler, getCommonHeader, getItemById, getItemList, updateItem} from "./common";
import EventBus from "./event-bus";

const axios = require('axios');
const model = "tag";

export function getTags() {

    return getItemList(model);
}

export function getTagById(id) {

    return getItemById(model, id)
}

// export function createTag(item) {
//
//     return createItem(model, item);
// }

export function deleteTagById(id) {

    return deleteItem(model, id)
}

export function writeTag(item) {
    return updateItem(model, item);
}

export function getTagList(type) {
    return new Promise(function(resolve, reject) {
        EventBus.$emit("showLoading", true, "가져오는중");
        console.log("getBikeProducts " + process.env.VUE_APP_API_BASE_URL + 'tags?type=' + type);
        axios.get(process.env.VUE_APP_API_BASE_URL  + 'tags?type=' + type, {
            headers: getCommonHeader()
        })
            .then(function(response) {
                console.log(response);
                EventBus.$emit("showLoading", false);
                let data = response.data;
                resolve(data);
            })
            .catch(function(err) {
                console.log(err);
                EventBus.$emit("showLoading", false);
                errorHandler(err);
                reject(err);
            })
    })
}

export function generateNewTag() {
  return {
      id: 0,
      name: "",
      type:'',
      lat:0,
      lon:0,
      developer:false,
  }
}
export function getTagTypes() {
    let list = [
        {
            name:'일반',
            value:'',
        },
        {
            name:'메거진 전용',
            value:'magazine',
        },
        {
            name:'서비스 지역',
            value:'service',
        },
    ];
    return list;
}
