<template>
    <div class="list-detail-view">
        <DetailLayout :is-detail="false">
            <template v-slot:list-title-text>
                {{$t('menu.bike.list.syslog')}} 관리
            </template>
<!--            <template v-slot:top-items>-->
<!--                <div class="sort-btn-wrapper">-->
<!--                    <sort-and-search-input-->
<!--                        :sort-type-list="sortTypeList"-->
<!--                        :labelSearch="null"-->
<!--                        :enable-refresh="true"-->

<!--                        v-on:onClickRefresh="initialPageIndex"-->
<!--                        v-on:onClickSortType="onClickSortType"/>-->
<!--                </div>-->
<!--            </template>-->
            <template v-slot:list-items>
                <vue-good-table
                    mode="remote"
                    @on-page-change="onPageChange"
                    @on-sort-change="onSortChange"
                    @on-per-page-change="onPerPageChange"
                    :totalRows="totalRows"
                    :pagination-options="{
                        enabled: true,
                        perPage: 25,
                        perPageDropdown: [50],
                        dropdownAllowAll: false
                    }"
                    :rows="rows"
                    :columns="columns"/>
<!--                <vuetable-->
<!--                    :api-mode="false"-->
<!--                    :data="rows"-->
<!--                    :fields="tableFields"/>-->
<!--                <div style="text-align: center">-->
<!--                    <div class="btn" v-on:click="downPageIndex">-->
<!--                        <span class="glyphicon glyphicon-arrow-left"/> 이전-->
<!--                    </div>-->
<!--                    <div class="btn" v-on:click="upPageIndex">-->
<!--                        다음 <span class="glyphicon glyphicon-arrow-right"/>-->
<!--                    </div>-->
<!--                </div>-->
            </template>
        </DetailLayout>
    </div>
</template>

<script>
    import DetailLayout from "../DetailLayout";

    import SortAndSearchInput from "../../inputs/SortAndSearchInput";

    import Vuetable from 'vuetable-2/src/components/Vuetable'
    import {getSysLogs, getSysLogURL} from "../../api/asset";
    import {millisToReadable} from "../../utils/TimeConverter";
    import {isOk, reorderItems} from "../../api/common";

    export default {
        name: "SyslogView",
        components: {
            SortAndSearchInput,
            DetailLayout,

            Vuetable
        },
        created() {
            // this.currentSortType = this.sortTypeList[0];

            this.updateSyslogList();
        },
        data() {
            return {
                rows: [],

                columns: [
                    {field: 'createdWhen', label: '시간'},
                    {field: 'assetId', label: 'BIKE'},
                    {field: 'userId', label: 'USER'},
                    {field: 'lat', label: "lat"},
                    {field: 'lon', label: "lon"},
                    {field: 'onOff', label: "전원"},
                    {field: 'battery', label: "battery"},
                    {field: 'distance', label: "운행거리"},
                    {field: 'totalDistance', label: '총운행거리'},
                    {field: 'system', label: "system"},
                    {field: 'inside', label: "지역"},
                    {field: 'insideParkingArea', label: '주차지역'}
                ],

                sortTypeList: [
                    {name: "CREATED_TIME", value: 'createdWhen', order: 'desc'},
                    // {name: "BIKE", value: 'assetId', order: 'asc'},
                    // {name: "OPERATOR", value: 'operatorId', order: 'asc'},
                    // {name: "STATUS", value: 'status', order: 'asc'},
                    // {name: "ON/Off", value: 'onOff', order: 'desc'},
                    // {name: "IN_PARK_AREA", value: 'insideParkingArea', order: 'asc'}
                ],

                pagination : {
                    size: 25,
                    page: 1,
                    sort: 'createdWhen,desc',
                },

                totalRows: 1,
            }
        },
        methods: {
            // onClickSortType: function(selectedSortType) {
            //     this.currentSortType = selectedSortType;
            //
            //     this.getSyslogList();
            // },
            updateSyslogList: function(check = isOk){
                let that = this;
                getSysLogs(this.pagination).then(data => {
                    that.totalRows = data.totalElements;

                    that.rows = [];

                    for(let e of data.list){
                        // if(check(e)) {
                            e.createdWhen = millisToReadable(e.createdWhen);

                            e.lon = e.lon.toFixed(5);
                            e.lat = e.lat.toFixed(5);
                            e.onOff = e.onOff ? '운행' : '꺼짐';
                            e.distance += ' metre';
                            e.inside = e.inside ? '내부' : '이탈';
                            e.insideParkingArea = e.insideParkingArea ? '내부' : '이탈';

                            that.rows.push(e);
                        // }
                    }
                })
            },
            onPageChange(params) {
                this.pagination.page = params.currentPage - 1;

                this.updateSyslogList();
            },

            onPerPageChange(params) {
                this.pagination.page = 0;
                this.pagination.size = params.currentPerPage;

                this.updateSyslogList();
            },
            onSortChange(params) {
                let isBefore = false;

                this.pagination.sort = "";
                for(let param of params){
                    if(isBefore){
                        this.pagination.sort += '&';
                    }

                    this.pagination.sort += `${param.field},${param.type}`;

                    isBefore = true;
                }

                this.updateSyslogList();
            },
            // initialPageIndex(){
            //     this.currentIndex = 1;
            //
            //     this.getSyslogList();
            // },
            // downPageIndex(){
            //     let nextIndex = this.currentIndex - 1;
            //
            //     this.currentIndex = nextIndex > 1 ? nextIndex : 1;
            //
            //     this.getSyslogList();
            // },
            // upPageIndex(){
            //     this.currentIndex += 1;
            //
            //     this.getSyslogList();
            // },
        }
    }
</script>
