import EventBus from "./event-bus";
import {errorHandler, getCommonHeader} from "./common";
const axios = require('axios');
export function getPlaceItems(placeId, from, to) {
    return new Promise(function(resolve, reject) {
        EventBus.$emit("showLoading", true, "가져오는중");
        let url = process.env.VUE_APP_API_BASE_URL + 'admin/placeItems?placeId='+placeId +'&from=' + from + '&to=' + to;
        console.log("get place items " + url);
        axios.get(url, {
            headers: getCommonHeader()
        })
            .then(function(response) {
                console.log(response);
                EventBus.$emit("showLoading", false);
                let data = response.data;
                resolve(data);
            })
            .catch(function(err) {
                console.log(err);
                EventBus.$emit("showLoading", false);
                errorHandler(err);
                reject(err);
            })
    })
}

export function searchPlaceItems(placeId, ref) {
    return new Promise(function(resolve, reject) {
        EventBus.$emit("showLoading", true, "가져오는중");
        let url = process.env.VUE_APP_API_BASE_URL + 'admin/placeItems/search?placeId='+placeId +'&ref='+ ref;
        axios.get(url, {
            headers: getCommonHeader()
        })
            .then(function(response) {
                console.log(response);
                EventBus.$emit("showLoading", false);
                let data = response.data;
                resolve(data);
            })
            .catch(function(err) {
                console.log(err);
                EventBus.$emit("showLoading", false);
                errorHandler(err);
                reject(err);
            })
    })
}
export function getPendingPlaceItems(placeId) {
    return new Promise(function(resolve, reject) {
        EventBus.$emit("showLoading", true, "가져오는중");
        let url = process.env.VUE_APP_API_BASE_URL + 'admin/placeItems/pending?placeId='+placeId;
        console.log("get pending place items " + url);
        axios.get(url, {
            headers: getCommonHeader()
        })
            .then(function(response) {
                console.log(response);
                EventBus.$emit("showLoading", false);
                let data = response.data;
                resolve(data);
            })
            .catch(function(err) {
                console.log(err);
                EventBus.$emit("showLoading", false);
                errorHandler(err);
                reject(err);
            })
    })
}
export function getPlaceItem(placeId, itemId) {
    return new Promise(function(resolve, reject) {
        EventBus.$emit("showLoading", true, "가져오는중");
        let url = process.env.VUE_APP_API_BASE_URL + 'admin/placeItem?placeId='+placeId +'&id='+itemId;
        console.log("get place items " + url);
        axios.get(url, {
            headers: getCommonHeader()
        })
            .then(function(response) {
                console.log(response);
                EventBus.$emit("showLoading", false);
                let data = response.data;
                resolve(data);
            })
            .catch(function(err) {
                console.log(err);
                EventBus.$emit("showLoading", false);
                errorHandler(err);
                reject(err);
            })
    })
}
export function reschedulePlaceItem(placeId, itemId, date, time) {
    return new Promise(function(resolve, reject) {
        EventBus.$emit( "showLoading", true, "가져오는중");
        let url = process.env.VUE_APP_API_BASE_URL + 'admin/placeItem/reschedule?placeId='+placeId +'&itemId='+itemId + "&date="+date+"&time="+time;
        console.log("reschedule place items " + url);
        axios.get(url, {
            headers: getCommonHeader()
        })
            .then(function(response) {
                console.log(response);
                EventBus.$emit("showLoading", false);
                let data = response.data;
                resolve(data);
            })
            .catch(function(err) {
                console.log(err);
                EventBus.$emit("showLoading", false);
                errorHandler(err);
                reject(err);
            })
    })
}
export function cancelPlaceItem(query) {
    return new Promise(function(resolve, reject) {
        EventBus.$emit("showLoading", true, "가져오는중");
        let url = process.env.VUE_APP_API_BASE_URL + 'admin/placeItem/cancel?' + query;
        // console.log("get place items " + url);
        axios.get(url, {
            headers: getCommonHeader()
        })
            .then(function(response) {
                console.log(response);
                EventBus.$emit("showLoading", false);
                let data = response.data;
                resolve(data);
            })
            .catch(function(err) {
                console.log(err);
                EventBus.$emit("showLoading", false);
                errorHandler(err);
                reject(err);
            })
    })
}
export function confirmPlaceItem(placeId, itemId) {
    return new Promise(function(resolve, reject) {
        EventBus.$emit("showLoading", true, "가져오는중");
        let url = process.env.VUE_APP_API_BASE_URL + 'admin/placeItem/confirm?placeId='+placeId +'&id=' + itemId;
        // console.log("get place items " + url);
        axios.get(url, {
            headers: getCommonHeader()
        })
            .then(function(response) {
                console.log(response);
                EventBus.$emit("showLoading", false);
                let data = response.data;
                resolve(data);
            })
            .catch(function(err) {
                console.log(err);
                EventBus.$emit("showLoading", false);
                errorHandler(err);
                reject(err);
            })
    })
}

export function setPlaceItemUsed(placeId, itemId) {
    return new Promise(function(resolve, reject) {
        EventBus.$emit("showLoading", true, "업데이트중");
        let url = process.env.VUE_APP_API_BASE_URL + 'admin/placeItem/used?placeId='+placeId +'&id=' + itemId;
        // console.log("get place items " + url);
        axios.get(url, {
            headers: getCommonHeader()
        })
            .then(function(response) {
                console.log(response);
                EventBus.$emit("showLoading", false);
                let data = response.data;
                resolve(data);
            })
            .catch(function(err) {
                console.log(err);
                EventBus.$emit("showLoading", false);
                errorHandler(err);
                reject(err);
            })
    })
}
export function getPlaceItemReport(placeId, from, to) {
    return new Promise(function(resolve, reject) {
        EventBus.$emit("showLoading", true, "가져오는중");
        let url = process.env.VUE_APP_API_BASE_URL + 'admin/placeItems/sales?placeId='+placeId +'&from='+from + '&to=' + to;
        console.log("get place items " + url);
        axios.get(url, {
            headers: getCommonHeader()
        })
            .then(function(response) {
                console.log(response);
                EventBus.$emit("showLoading", false);
                let data = response.data;
                resolve(data);
            })
            .catch(function(err) {
                console.log(err);
                EventBus.$emit("showLoading", false);
                errorHandler(err);
                reject(err);
            })
    })
}
