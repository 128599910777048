<template>
    <div style="width: 100%; height: 100%; overflow-y: scroll;">
        <div class="container">


            <div class="row section-header" style="padding-top:20px;">
                <div class="col-4">
                    <h3>세팅 관리</h3>
                </div>
                <div class="col-4">
                    <div v-if="operators.length > 1">
                        <label>{{$t('operator.select')}}</label>
                        <select v-model="selectedOperatorId" @change="onOperatorSelected">
                            <option v-for="o in operators"  v-bind:key="'operator-'+o.value" :value="o.value">{{o.name}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-4 text-right">
                    <button class="btn btn-primary" @click="saveSettings">저장</button>
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-12">
                    <table class="table" style="text-align: left;">
                        <tr>
                            <th>#</th>
                            <th>Field</th>
                            <th>Value</th>
<!--                            <th>설명</th>-->
                        </tr>
                        <tbody>
                            <tr v-for="(setting, si) in settings" v-bind:key="'setting-item-' +si + '-' +setting.id" class="text-left">
                                <td>{{setting.id}}</td>
                                <td class="text-left">
                                    <strong>{{setting.title}}</strong>
                                    <br/>
                                    {{setting.description}}
                                </td>
                                <td class="text-left">
                                    <input v-if="setting.valueType == 'int'" type="number" v-model="setting.valueInt"/>
                                    <vue-form style="width: 100%;" v-else-if="setting.valueType == 'text'">
                                        <vue-editor  v-model="setting.valueText"/>
                                    </vue-form>
                                    <input v-else-if="setting.valueType === 'plaintext'" v-model="setting.valueText">
                                </td>
<!--                                <td>-->
<!--                                    {{setting.description}}-->
<!--                                    <br>Value Type: {{setting.valueType}}-->
<!--                                </td>-->
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-6 text-left">공휴일</div>
                <div class="col-6 text-right"><button class="btn btn-primary" @click="addNewHoliday">추가</button></div>
            </div>
            <div class="row">

                <div class="col-12">
                    <table class="table">
                        <tr>
                            <th>ID</th><th>날짜</th><th>이름</th><th></th>
                        </tr>
                        <tbody>
                            <tr v-for="(item, hi) in holidays" v-bind:key="'holiday-item-'+ item.id">
                                <td>{{item.id}}</td>
                                <td><input type="date" v-model="item.date" class="form-control" @change="updateHoliday(item)"/></td>
                                <td><input type="text" v-model="item.title" class="form-control" @change="updateHoliday(item)"/></td>
                                <td><button class="btn btn-danger" @click="deleteHoliday(hi)"><i class="fas fa-trash"></i></button></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {getOperatorId} from "../../store";
    import {getSettings, getSettingBones, updateSettings} from "../../api/settings";
    import {getOperatorList} from "../../api/operator";
    import { VueEditor } from "vue2-editor";
    import {deleteHoliday, getHolidays, newDateHour, updateHour} from "../../api/hours";
    import {millisToDate, showDialog} from "../../api/common";
    export default {
        name: "SettingView",
        components: {
            VueEditor,
        },
        data() {
            return {
                operators:[],
                selectedOperatorId: getOperatorId(),
                settings:[],
                settingList:[],
                holidays:[],
            }
        },
        created() {

        },
        async mounted() {
            this.operators = await getOperatorList();
            this.getSettingList();
            this.getHolidays();
        },
        methods: {
            onOperatorSelected: function() {
                this.getSettingList();
            },
            getSettingList: function () {
                let self = this;
                this.$data.settings = [];
                this.$data.settings = getSettingBones(this.$data.selectedOperatorId);
                console.log("Get bone settings for operator " + this.$data.selectedOperatorId);
                console.log(this.$data.settings);
                getSettings(this.$data.selectedOperatorId)
                .then(function(data) {
                    console.log("setting list acquired")
                    if(data.success == true) {
                        self.checkSettings(data.item);
                    } else {
                        self.checkSettings([]);
                    }

                })
                .catch(function(err) {
                    console.log("Error at gettting setting list " + err);
                    if(err.response != undefined) {
                        console.log(err.response);
                    }
                })

            },
            checkSettings: function(items) {
                console.log("check settings ")
                console.log(items);
                let listToCreate = [];
                for(let i = 0 ; i < this.$data.settings.length ; i++) {
                    let found = false;
                    if(items != undefined) {
                        for (let j = 0; j < items.length; j++) {
                            if (this.$data.settings[i].type === items[j].type && items[j].id > 0) {
                                this.$data.settings.splice(i, 1, items[j]);
                                found = true;
                                break;
                            }
                        }
                    }
                    if(found == false) {
                        console.log("create new");
                        listToCreate.push(this.$data.settings[i]);
                    }
                }
                let self = this;
                if(listToCreate.length > 0) {
                    updateSettings(listToCreate)
                    .then(function(data) {
                        if(data.success == true) {
                            self.checkSettings(data.item);
                        }
                    })
                }
            },
            saveSettings: function() {
                let self = this;
                let listToCreate = this.$data.settings;
                updateSettings(listToCreate)
                    .then(function(data) {
                        if(data.success == true) {
                            self.checkSettings(data.item);
                        }
                    })
            },
            getHolidays: function() {
                let self = this;
                let today = millisToDate(new Date().getTime(), '-');
                getHolidays(today)
                .then(function(data) {
                    if(data.success == true) {
                        self.$data.holidays = data.list;
                    }
                })
                .catch(function(err) {
                    console.log(err);
                    if(err.response !== undefined) {
                        console.log(err.response);
                    }
                    showDialog(err, "ERROR");
                })
            },
            addNewHoliday: function() {
                if(this.$data.holidays.length > 0) {
                    for(let i = 0 ; i < this.$data.holidays.length ; i++) {
                        if(this.$data.holidays[i].id == 0) {
                            showDialog("이미 새로 생성한 휴일아이템이 있습니다.");
                            return;
                        }
                    }
                }
                let newDay = newDateHour();
                newDay.type = 'holiday';
                this.$data.holidays.push(newDay);
            },
            updateHoliday: function(item) {
                if(item.date == null || item.date.length == 0) {
                    showDialog("날짜를 입력해주세요");
                    item.date = '';
                    return;
                }
                let today = millisToDate(new Date().getTime(), '-');
                if(item.date < today) {
                    showDialog("오늘보다 이전날짜를 휴일로 정할 수 없습니다.");
                    item.date = '';
                    return;
                }

                let self = this;

                updateHour(item)
                .then(function(data) {
                    if(data.success == true) {
                        for(let i = 0 ; i < self.$data.holidays.length ; i++) {
                            if(self.$data.holidays[i].id == 0) {
                                self.$data.holidays.splice(i, 1, data.item);
                                return;
                            }
                            if(self.$data.holidays[i].id == data.item.id) {
                                self.$data.holidays.splice(i, 1, data.item);
                                return;
                            }
                        }
                        self.$data.holidays.push(data.item);
                    } else {
                        showDialog("공휴일 업데이트 실패 " + data.message);
                    }
                })
                .catch(function(err) {
                    console.log(err);
                    if(err.response !== undefined) {
                        console.log(err.response);
                    }
                    showDialog(err, "ERROR");
                })
            },
            deleteHoliday: function(index) {
                if(this.$data.holidays[index].id == 0) {
                    this.$data.holidays.splice(index, 1);
                    return;
                }
                let self = this;
                deleteHoliday(this.$data.holidays[index].id)
                .then(function(data) {
                    if(data.success == true) {
                        for(let i = 0 ; i < self.$data.holidays.length ; i++) {
                            if(self.$data.holidays[i].id == data.item.id) {
                                self.$data.holidays.splice(i, 1);
                                return;
                            }
                        }
                    } else {
                        showDialog("공휴일 업데이트 실패 " + data.message);
                    }
                })
                .catch(function(err) {
                    console.log(err);
                    if(err.response !== undefined) {
                        console.log(err.response);
                    }
                    showDialog(err, "ERROR");
                })
            }

        }
    }
</script>

<style scoped>
.table tbody tr:hover {
    background: #eee;
    color: black;
    transform: translate(-5px, -5px);
    box-shadow: 2px 2px 2px gray;
}
</style>
