<template>
    <vuetable
        ref="vuetable"
        :api-url="apiUrl"
        :http-options= "headerInfo"
        :fields="tableFields"
        :transform="transform"
        :per-page="null"
        :initial-page="null"
        pagination-path=""

        data-path="content"/>
</template>

<script>
    import Vuetable from 'vuetable-2/src/components/Vuetable'
    import VuetablePagination from 'vuetable-2/src/components/VuetablePagination'
    import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'

    import {getCommonHeader} from "../../api/common";

    export default {
        name: "TableEditor",
        components: {
            Vuetable,
            VuetablePagination,
            VuetablePaginationInfo
        },
        props: {
            tableFields: {
                type: Array,
                default: []
            },
            apiUrl: null,
            selectedItems: null,
            transform:null
        },
        data() {
            return{
                headerInfo: {
                    headers: getCommonHeader()
                },
            }
        }
    }
</script>

<style>
    .table thead th {
        text-align: center;
        font-size: 80%;
    }
    td {
        text-align: center;
        font-size: 80%;
    }
</style>
