<template>
    <vue-form-item class="hash-tags-input" :label="$t('content.tags')">
        <label class="tag-label" v-if="!isReadOnly">
            <input type="text" v-model="inputStr" v-on:keyup.enter="onEnter" />
        </label>
        <b v-else-if="0 === tags.length">없음</b>

        <div class="hash-tags">
            <div class="hash-tag" v-for="tag in tags">
                <span>#{{tag.name}}</span>
                <span v-if="!isReadOnly"
                      class="fas fa-times" @click="onClickTagRemoveBtn(tag)"/>
            </div>
        </div>
    </vue-form-item>
</template>

<script>
    export default {
        name: 'HashTagInput',
        props: {
            labelName: {
                type: String,
                default: 'String',
            },
            value: {
                type: Array,
                default: null,
            },
            isReadOnly: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                tags: this.value,
                inputStr: '',
            }
        },
        watch: {
            value: function() {
                this.tags = this.value;
            },
            // tags: function(newVal) {
            //     this.$emit('input', newVal);
            // },
        },
        methods: {
            onEnter: function(event) {
                if (0 < this.inputStr.length) {
                    let inputStrings = this.inputStr.replace( /\//g, ',' ).split(',');

                    for (let inputString of inputStrings) {
                        let result = this.tags.filter(tag => tag.name === inputString);

                        if(0 === result.length)
                            this.tags.push({id: 0, name: inputString})
                    }
                }

                this.inputStr = '';
            },
            onClickTagRemoveBtn: function(tag) {
                let targetIndex = this.tags.indexOf(tag);
                if (-1 < targetIndex) {
                    this.tags.splice(targetIndex, 1);
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
    .hash-tags-input {

        label {
            vertical-align: top !important;
            padding-top: 5px !important;
        }

        input,
        .tag-label{
            width: 100%;
        }

        .hash-tags {
            .hash-tag {
                display: inline-block;
                border-radius: 25px;
                padding: 1px 10px;
                margin-left: 8px;

                background-color: #52a5e0;
                border-color: #1982ce;
                color: #FFF;

                span {
                    vertical-align: text-top;
                }
                span.fas {
                    vertical-align: sub;
                    margin-left: 2px;
                    cursor: pointer;
                }
                span.fas:hover {
                    color: #ff4a4a;
                    transition: all 0.2s;
                }
            }
            .hash-tag:first-child {
                margin-left: 0;
            }
        }

    }
</style>
