<template>
    <div style="width: 100%; height: 100%; padding-left:15px;padding-right: 15px;">
        <div style="width: calc(100% - 50px); height: 50px; padding-top:10px;">
            <select v-if="placeItems.length > 0" v-model="currentPlaceId" style="height: 40px; width: 200px;border-radius: 5px; border: 1px solid grey;" @change="onPlaceSelected">
                <option v-for="p in placeItems" :value="p.id">{{p.name}}</option>
            </select>


            <select v-model="selectedProductId" style="height: 40px; width: 200px;border-radius: 5px; border: 1px solid grey;" @change="onProductSelected">
                <template v-if="products != null && products.length > 0">
                    <option v-for="p in products" :value="p.id">{{p.title}}</option>
                </template>
            </select>

        </div>

        <div style="width: 100%; height: calc(100% - 50px); display: flex;">
            <div style="width: 100%; max-width: 100%; height: 100%;">
                <div style="width:100%; height: 50px; line-height: 50px;">

                    <button class="btn btn-danger" @click="getTodayItems">오늘</button>
                    <button class="btn btn-success" @click="getPendingItems">대기</button>
                    <input type="date" v-model="dateToGet" style="height: 38px;" @change="onDateChanged"/>
                    <button class="btn btn-primary" v-if="currentPlaceId > 0" style="float:right;" @click="reportView = true">결산 보기</button>
                </div>
                <div style="width: 100%; height: calc(100% - 50px); display: flex;">
                    <div style="width: 200px; height: 100%; overflow-y: auto; background-color: white;">
                        <ul class="list-group" v-if="todayHours != null && todayHours.length > 0">
                            <li class="list-group-item" v-for="h in todayHours" v-on:click="selectHour(h)">{{h.title}}  {{h.items.length}}건 <span style="padding:0px 5px 0px 5px;border: 1px solid grey;float:right; color:grey;" v-bind:class="{'button-active': h.unavailable != false}" v-on:click.stop="toggleFull(h)">Full</span></li>
                        </ul>
                    </div>
                    <div style="width: 500px; height: 100%; overflow-y: auto; background-color: white" >
                        <template v-if="selectedHour != false">
                            <div v-for="item in selectedHour.items" style="margin: 5px; padding:10px; background-color: white; border-radius: 3px; border: 1px solid grey;">
                                <div style="height: 30px; line-height: 30px; font-weight: bold;">
                                    {{item.title}}
                                </div>
                                <div style="height: 30px; line-height: 30px; font-weight: bold;">
                                    {{item.itemDate}} / {{item.itemTime}} <div style="float:right">{{item.count}}명</div>
                                </div>
                                <div v-for="i in 5">
                                    <div v-if="item['count'+i] > 0">{{getPriceName(i)}}: {{item['count'+i]}}명</div>
                                </div>
                                <div style="padding:10px; line-height: 20px;">
                                    연락처: {{item.contact}}
                                </div>
                                <div>
                                    <span v-if="item.state == -10 || item.state == -20">취소</span>
                                    <template v-else-if="item.state < 5">
                                        <button  class="btn btn-success" @click="confirmItem(item)">확정</button>

                                    </template>

                                    <button v-else-if="item.state < 10" class="btn btn-primary" @click="setItemUsed(item)">사용</button>

                                    <span v-else-if="item.state < 100">사용완료</span>
                                    <span v-else-if="item.state == 100">결산 완료</span>
                                    <button  class="btn btn-secondary" style="float:right;" v-if="item.state >= 0 && item.state < 100" @click="openItemToReschedule(item)">일시변경</button>
                                    <button class="btn btn-danger" style="float:right;" v-if="item.state >= 0 && item.state < 100" @click="showCancelPlaceItemDialog(item)">취소</button>
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <div v-for="h in todayHours">
                                <div>{{h.title}}  {{h.items.length}}건 </div>
                                <div v-for="item in h.items" style="margin: 5px; padding:10px; background-color: white; border-radius: 3px; border: 1px solid grey;">
                                    <div style="height: 30px; line-height: 30px; font-weight: bold;">
                                        {{item.title}}
                                    </div>
                                    <div style="height: 30px; line-height: 30px; font-weight: bold;">
                                        {{item.itemDate}} / {{item.itemTime}} <div style="float:right">{{item.count}}명</div>
                                    </div>
                                    <div v-for="i in 5" style="width: 100%;">
                                        <div v-if="item['count'+i] > 0">{{getPriceName(i)}}: {{item['count'+i]}}명</div>
                                    </div>
                                    <div style="padding:10px; line-height: 20px;">
                                        연락처: {{item.contact}}
                                    </div>
                                    <div>
                                        <span v-if="item.state == -10 || item.state == -20">취소</span>
                                        <template v-else-if="item.state < 5">
                                            <button  class="btn btn-success" @click="confirmItem(item)">확정</button>

                                        </template>

                                        <button v-else-if="item.state < 10" class="btn btn-primary" @click="setItemUsed(item)">사용</button>

                                        <span v-else-if="item.state < 100">사용완료</span>
                                        <span v-else-if="item.state == 100">결산 완료</span>
                                        <button  class="btn btn-secondary" style="float:right;" v-if="item.state >= 0 && item.state < 100" @click="openItemToReschedule(item)">일시변경</button>
                                        <button class="btn btn-danger" style="float:right;" v-if="item.state >= 0 && item.state < 100" @click="showCancelPlaceItemDialog(item)">취소</button>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>


<!--                <div style="width: 100%; height: calc(100% - 50px); overflow-y: auto; background-color: white; display:none;">-->
<!--                    <div v-for="item in todayItems" style="margin: 5px; padding:10px; background-color: white; border-radius: 3px; border: 1px solid grey;">-->
<!--                        <div style="height: 30px; line-height: 30px; font-weight: bold;">-->
<!--                            {{item.itemDate}} / {{item.itemTime}} <div style="float:right">{{item.count}}명</div>-->
<!--                        </div>-->
<!--                        <div style="padding:10px; line-height: 20px;">-->
<!--                            연락처: {{item.contact}}-->
<!--                        </div>-->
<!--                        <div>-->
<!--                            <span v-if="item.state == -10 || item.state == -20">취소</span>-->
<!--                            <template v-else-if="item.state < 5">-->
<!--                                <button  class="btn btn-success" @click="confirmItem(item)">확정</button>-->

<!--                            </template>-->

<!--                            <button v-else-if="item.state < 10" class="btn btn-primary" @click="setItemUsed(item)">사용</button>-->

<!--                            <span v-else-if="item.state < 100">사용완료</span>-->
<!--                            <span v-else-if="item.state == 100">결산 완료</span>-->
<!--                            <button  class="btn btn-secondary" style="float:right;" v-if="item.state >= 0 && item.state < 100" @click="openItemToReschedule(item)">일시변경</button>-->
<!--                            <button class="btn btn-danger" style="float:right;" v-if="item.state >= 0 && item.state < 100" @click="showCancelPlaceItemDialog(item)">취소</button>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
            </div>
            <div style="width: calc(100% - 500px); height: 100%; display:none;" class="mobile-hide">
                <div style="width: 100%; height: 50px;">
                    <date-picker v-model="dateRange" valueType="format" @change="dateRangeSelected" range></date-picker>
                    <button class="btn btn-primary" v-on:click="getItems">리스트 가져오기</button>
                </div>
                <div style="width: 100%; height: calc(100% - 50px); overflow-y: auto;">
                    <table class="table">
                        <tr>
                            <th>날짜</th>
                            <th>시간</th>
                            <th>인원</th>
                            <th>고객</th>
                            <th>결산금액</th>
                        </tr>
                        <tr v-for="item in items">
                            <td>{{item.itemDate}}</td>
                            <td>{{item.itemTime}}</td>
                            <td>{{item.count}}명</td>
                            <td>{{item.contact}}</td>
                            <td>{{item.payout}}</td>
                            <td>
                                <span v-if="item.state == -10">취소</span>
                                <button v-else-if="item.state < 5" class="btn btn-success" @click="confirmItem(item)">확정하기</button>
                                <button v-else-if="item.state < 10" class="btn btn-primary" @click="setItemUsed(item)">사용처리</button>
                                <span v-else-if="item.state < 100">사용완료</span>
                                <span v-else-if="item.state == 100">결산 완료</span>

                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
        <div style="position: fixed; top:0; left: 0; width: 100%; height: 100%; background-color: rgba(0,0,0,0.3);" v-if="showPendingItems == true" v-on:click="closePendingItems">
            <div style="position: absolute; width: 100%; max-width: 500px; height: 90%; background: white; top:50%; left:50%; transform: translate(-50%, -50%);" v-on:click.stop="dummy">
                <div style="width: 100%; height: 50px; line-height: 50px; font-size: 20px; text-align: center; font-weight: bold;">
                    대기 예약 <span style="float:right; margin-right:10px;font-weight: normal;" v-on:click="closePendingItems">닫기</span>
                </div>
                <div style="width: 100%; height: calc(100% - 50px); overflow-y: auto;">
                    <div v-for="item in pendingItems" style="margin: 5px; padding:10px; background-color: white; border-radius: 3px; border: 1px solid grey;">
                        <div style="height: 30px; line-height: 30px; font-weight: bold;">
                            {{item.title}}
                        </div>
                        <div style="height: 30px; line-height: 30px; font-weight: bold;">
                            {{item.itemDate}} / {{item.itemTime}} <div style="float:right">{{item.count}}명</div>
                        </div>
                        <div v-for="i in 5">
                            <div v-if="item['count'+i] > 0">{{getPriceName(i)}}: {{item['count'+i]}}명</div>
                        </div>
                        <div style="padding:10px; line-height: 20px;">
                            연락처: {{item.contact}}
                        </div>
                        <div>
                            <span v-if="item.state == -10 || item.state == -20">취소</span>
                            <template v-else-if="item.state < 5">
                                <button  class="btn btn-success" @click="confirmItem(item)">확정</button>

                            </template>

                            <button v-else-if="item.state < 10" class="btn btn-primary" @click="setItemUsed(item)">사용</button>

                            <span v-else-if="item.state < 100">사용완료</span>
                            <span v-else-if="item.state == 100">결산 완료</span>
                            <button  class="btn btn-secondary" style="float:right;" v-if="item.state >= 0 && item.state < 100" @click="openItemToReschedule(item)">일시변경</button>
                            <button class="btn btn-danger" style="float:right;" v-if="item.state >= 0 && item.state < 100" @click="showCancelPlaceItemDialog(item)">취소</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <button class="btn btn-primary" style="position:fixed; bottom:20px; right:20px; box-shadow: 3px 3px 5px grey;" @click="showQrCodeScanner"><i class="fas fa-qrcode"></i> SCAN</button>
        <div style="position: fixed; top:0; left: 0; width: 100%; height: 100%; background-color: rgba(0,0,0,0.3);" v-if="item != false" v-on:click="item = false">
            <div style="position: absolute; width: 100%; max-width: 500px; height: 90%; background: white; top:50%; left:50%; transform: translate(-50%, -50%);" v-on:click.stop="dummy">
                <div class="container" style="padding-top: 50px;">
                    <div class="row">
                        <div class="col-12">
                            {{item.title}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            날짜: {{item.itemDate}}
                        </div>
                        <div class="col-12">
                            시간: {{item.itemTime}}
                        </div>
                        <div class="col-12">
                            인원수: {{item.count}}명
                            <div v-for="i in 5">
                                <div v-if="item['count'+i] > 0">{{getPriceName(i)}}: {{item['count'+i]}}명</div>
                            </div>
                        </div>
                        <div class="col-12">
                            전화번호: {{item.contact}}
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <button class="form-control btn btn-primary" v-if="item.state == 0" @click="confirmItem(item)">예약 확정</button>
                            <button class="form-control btn btn-primary" v-else-if="item.state == 5" @click="setItemUsed(item)">체크인</button>

                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div style="position: fixed; top:0; left: 0; width: 100%; height: 100%; background-color: rgba(0,0,0,0.3);" v-if="itemToReschedule != false" v-on:click="closeItemToReschedule">
            <div style="position: absolute; width: 100%; max-width: 500px; height: 90%; background: white; top:50%; left:50%; transform: translate(-50%, -50%);" v-on:click.stop="dummy">
                <div class="container" style="padding-top: 50px;">
                    <div class="row">
                        <div class="col-12">
                            <strong>예약 시간 변경</strong>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            {{itemToReschedule.title}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-5">
                            변경전
                        </div>
                        <div class="col-7">변경후</div>
                    </div>
                    <div class="row">
                        <div class="col-5">
                            {{itemToReschedule.itemDate}}
                        </div>
                        <div class="col-7">
                            <input type="date" class="form-control" v-model="itemToReschedule.newDate" @change="onScheduleDateChanged">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-5">
                            {{itemToReschedule.itemTime}}
                        </div>
                        <div class="col-7" v-if="itemToReschedule.hoursList != undefined && itemToReschedule.hoursList != null && itemToReschedule.hoursList.length > 0">
                            <select class="form-control" v-model="itemToReschedule.newTime">
                                <option v-for="h in itemToReschedule.hoursList" :value="h.title">{{h.title}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            인원수: {{itemToReschedule.count}}명
                        </div>
                        <div class="col-12">
                            전화번호: {{itemToReschedule.contact}}
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <button class="form-control btn btn-primary" @click="submitReschedule">변경</button>

                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div style="position:fixed; top:0; left: 0; width: 100%; height: 100%; background-color: rgba(0,0,0,0.4);" v-if="cancelItem != false" @click="cancelItem = false">
            <div style="position: absolute; top:50%; left: 50%; width: 500px; max-width: 100%; transform: translate(-50%, -50%); background-color: white; border-radius: 20px; padding:20px;" v-on:click.stop="dummy">
                <h4>플레이스 예약 취소</h4>

                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <h5>{{cancelItem.item.title}} x {{cancelItem.item.count}}명</h5>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            날짜: {{cancelItem.item.itemDate}}
                            <br>
                            시간: {{cancelItem.item.itemTime}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">환불 %</div>
                        <div class="col-6">
                            <select v-model="cancelItem.rate" class="form-control">
                                <option v-for="r in 101" :value="r">{{r}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            예약 확정전일경우 결제항목이 없어서, 전액 환불 됩니다.
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <button class="btn btn-dark form-control" @click="cancelItem = false">닫기</button>
                        </div>
                        <div class="col-6">
                            <button class="btn btn-danger form-control" @click="cancelPlaceItem">예약 취소하기</button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div style="position: fixed; top:0; left: 0; width: 100%; height: 100%; background-color: rgba(0,0,0,0.3);" v-if="reportView != false" v-on:click="closeReport">
            <div style="position: absolute; width: 100%; max-width: 1000px; height: calc(100% - 150px); background: white; top:50%; left:50%; transform: translate(-50%, -50%);" v-on:click.stop="dummy">
                <div style="width: 100%; height: 50px;">
                    <date-picker v-model="reportDateRange" valueType="format" @change="reportDateRangeSelected" range></date-picker>
                    <button class="btn btn-primary" @click="getReport">가져오기</button>
                </div>
                <div style="width: 100%; height: 50px;">
                    <template v-if="reportItem != false">
                        총 {{reportItem.list.length}}건 / 결제금액 : {{reportItem.totalPayout}}원 | 환불 {{reportItem.refunded.length}}건
                    </template>
                </div>
                <div style="width: 100%; height: calc(100% - 100px); overflow-y: auto;">
                    <table class="table">
                        <tr>
                            <th>ID</th>
                            <th>Date/Time</th>
                            <th>항목</th>
                            <th>수량</th>
                            <th>결산금액</th>
                            <th>상태</th>
                            <th>결제번호</th>
                        </tr>
                        <tbody v-for="item in reportItem.list">
                        <tr :class="'place-item-state-'+item.state">
                            <td>{{item.id}}</td>
                            <td>{{item.itemTimeString}}</td>
                            <td>{{item.title}}</td>
                            <td>{{item.count}}</td>
                            <td>{{item.payout}}</td>
                            <td>{{item.adminStateString}}</td>
                            <td><span v-if="item.orderId > 0">{{item.orderId}}</span></td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="qrreader-container hide" id="qrReader" data-type="0">
            <p style="position:absolute;width: 100%; top: 40px;margin:auto;margin-top:50px; text-align: center;">QR CODE를 스캔해주세요</p>
            <div class="cam-container">
                <qrcode-stream @decode="onCodeScanned" v-if="qrReaderOn"></qrcode-stream>
            </div>
            <button class="btn btn-danger" style="position:absolute; bottom:30px;left:50%;transform: translateX(-50%);" @click="stopQrScanner">닫기</button>
        </div>
    </div>
</template>

<script>
    import {getPlaces} from "@/api/place";
    import DatePicker from 'vue2-datepicker';
    import {
        cancelPlaceItem,
        confirmPlaceItem,
        getPendingPlaceItems,
        getPlaceItem, getPlaceItemReport,
        getPlaceItems, reschedulePlaceItem,
        setPlaceItemUsed
    } from "@/api/placeItem";
    import {getHM, showDialog} from "@/api/common";
    import Paho from "@/assets/js/paho-mqtt";
    import {millisToDate} from "@/utils/TimeConverter";
    import {deleteHour, getHours, getUnavailableHours, updateHour} from "@/api/hours";
    import {getPlaceProducts} from "@/api/product";
    export default {
        name: "PlaceDashboardView",
        data() {
            return  {
                mqtt:null,
                mqttConnected:false,
                disconnectMqtt:false,
                currentPlaceId:0,
                placeItems:[],
                pendingItems:[],
                showPendingItems:false,
                itemsToConfirm:[],
                dateRange:null,
                todayText: '',
                items:[],
                todayItems:[],
                item:false,
                qrReaderOn: false,
                itemToReschedule: false,
                dateToGet:'',
                hours:[],
                products:[],
                selectedProductId:0,
                todayHours:[],
                selectedHour:false,
                cancelItem: false,
                reportView:false,
                reportDateRange: null,
                reportItem:false,
            }
        },
        components: {
            DatePicker,
        },
        created() {
            console.log("created")
        },
        beforeDestroy() {
            this.$data.disconnectMqtt = true;
            this.disconnect();
        },
        mounted() {
            console.log("mounted");
            this.$data.dateToGet = millisToDate(new Date().getTime(), '-');
            this.onStart();

        },

        methods: {
            dummy: function() {
                console.log("dummy");
            },
            onStart: function() {
                let self = this;
                console.log("onStart");
                getPlaces('typeClass=booking')
                .then(function(list) {
                    self.$data.placeItems = list;
                    if(self.$data.placeItems.length == 1) {
                        self.$data.currentPlaceId = self.$data.placeItems[0].id;
                        // §console.log("")
                        // self.connect();
                        self.onPlaceSelected();
                    }
                })
                .catch(function(err) {
                    console.log(err);
                    if(err.response != undefined) {
                        console.log(err.response);
                    }
                })
            },
            onPlaceSelected: function() {
                this.connect();
                this.getProductList();

            },
            getProductList: function() {
                let self = this;
                this.$data.products = [];
                this.$data.selectedProductId = 0;
                getPlaceProducts(this.$data.currentPlaceId)
                .then(function(data) {
                    if(data.success == true) {
                        self.$data.products = data.item;
                        if(data.item.length == 1) {
                            self.$data.selectedProductId = data.item[0].id;
                            self.onProductSelected();

                        }
                    }
                })
                .catch(function(err) {

                })
            },
            onProductSelected: function() {

                if(this.$data.selectedProductId == 0) {
                    return;
                }

                let query = "productId=" + this.$data.selectedProductId + '&placeId=' + this.$data.currentPlaceId;
                let self = this;
                getHours(query)
                .then(function(data) {
                    if(data.success == true) {
                        self.$data.hours = data.item;
                        self.onDateChanged();
                    } else {
                        console.log(data.message);
                    }
                })
                .catch(function(err) {

                })
            },
            getUnavailableHouse: function() {
                if(this.$data.dateToGet == null) {
                    return;
                }

                let query = "productId=" + this.$data.selectedProductId + "&date="+this.$data.dateToGet;
                getUnavailableHours(query)
                .then(function(data){

                })
                .catch(function(err) {

                })
            },

            dateRangeSelected: function() {
                console.log("dateRangeSelected")
                console.log(this.$data.dateRange)

                if(this.$data.dateRange.length !== 2) {
                    return;
                }

                if(this.$data.dateRange[0] > this.$data.dateRange[1]) {
                    let temp = this.$data.dateRange[1];
                    this.$data.dateRange[0] = this.$data.dateRange[1];
                    this.$data.dateRange[1] = temp;
                }

            },
            onDateChanged: function() {
                if(this.$data.dateToGet == null) {
                    return;
                }

                this.$data.selectedHour = false;
                this.$data.todayHours = [];
                let day = new Date(this.$data.dateToGet);
                let dayOfWeek =  day.getDay();
                if(this.$data.hours != null && this.$data.hours.length > 0) {
                    // for (let i = 0; i < this.$data.hours.length; i++) {
                    let hour = this.$data.hours[dayOfWeek];
                    let slot = hour.slotLength * 60000;

                    day.setHours(hour.openHour, hour.openMinute, 0 ,0);

                    let startTime = day.getTime();
                    let dayEnd = new Date(this.$data.dateToGet);
                    dayEnd.setHours(hour.closeHour, hour.closeMinute, 0, 0);
                    let endTime = dayEnd.getTime();

                    while(startTime < endTime) {
                        let hmString = getHM(startTime),
                            item = {
                                date:this.$data.dateToGet,
                                title:hmString,
                                select: false,
                                openHour:new Date(startTime).getHours(),
                                openMinute:new Date(startTime).getMinutes(),
                                closeHour:new Date(startTime+slot).getHours(),
                                closeMinute:new Date(startTime+slot).getMinutes(),
                                items:[],
                                unavailable:false,
                            }
                        this.$data.todayHours.push(item);
                        startTime += slot;
                    }

                    // }
                }
                let self = this;
                let query = "productId=" + this.$data.selectedProductId + "&date="+this.$data.dateToGet;
                getUnavailableHours(query)
                    .then(function(data){
                        if(data.success == true) {
                            self.setUnavailableHours(data.item);
                        }
                        self.getDayItems();

                    })
                    .catch(function(err) {

                    })

            },
            setUnavailableHours: function(list) {
                for(let i = 0 ; i < list.length ; i++) {
                    let day = new Date(this.$data.dateToGet);
                    day.setHours(list[i].openHour, list[i].openMinute, 0 ,0);
                    let hmString = getHM(day.getTime());
                    for(let j = 0 ; j < this.$data.todayHours.length ; j++) {
                        if(this.$data.todayHours[j].title == hmString) {
                            this.$data.todayHours[j].unavailable = list[i];
                            break;
                        }
                    }
                }
            },
            toggleFull: function(hour) {
                console.log("toggleFull");
                console.log(hour);
                if(hour.unavailable == false) {
                    // Create new one
                    let fullItem = {
                        id:0,
                        type:'unavailable',
                        date:this.$data.dateToGet,
                        openHour:hour.openHour,
                        openMinute:hour.openMinute,
                        closeHour: hour.closeHour,
                        closeMinute: hour.closeMinute,
                        slotLength:hour.length/60000,
                        productId:this.$data.selectedProductId,
                        placeId:this.$data.currentPlaceId,
                    }

                    updateHour(fullItem)
                    .then(function(data) {
                        if(data.success == true) {
                            hour.unavailable = data.item;
                        } else {
                            showDialog(data.message);
                        }
                    })
                    .catch(function(err) {
                        let msg = err;
                        if(err.response != undefined) {
                            msg += ' (' + err.response + ')';
                        }
                        showDialog(msg);
                    })

                } else {
                    // delete existing

                    deleteHour(hour.unavailable.id, hour.unavailable.placeId)
                    .then(function(data) {
                        if(data.success == true) {
                            hour.unavailable = false;
                        } else {
                            showDialog(data.message);
                        }
                    })
                    .catch(function(err) {
                        let msg = err;
                        console.log(err);
                        if(err.response != undefined) {
                            console.log(err.response);
                            msg += ' (' + err.response + ')';
                        }

                        showDialog(msg);
                    })

                }
            },
            selectHour: function(hour) {
                console.log("select hour");
                console.log(hour);
                this.$data.selectedHour = JSON.parse(JSON.stringify(hour));
            },
            getDayItems: function() {
                this.$data.todayItems = [];
                let self = this;

                getPlaceItems(this.$data.currentPlaceId, this.$data.dateToGet, this.$data.dateToGet)
                    .then(function(data) {

                        if(data.success == true) {

                            // self.$data.todayItems = data.list;

                            for(let i = 0 ; i < data.list.length ; i++) {
                                for(let j = 0 ; j < self.$data.todayHours.length ; j++) {
                                    // console.log(self.$data.todayHours[j]);
                                    if(data.list[i].itemTime == self.$data.todayHours[j].title) {
                                        self.$data.todayHours[j].items.push(data.list[i]);
                                        break;
                                    }
                                }
                            }
                        } else {
                            console.log(data.message);
                        }
                    })
                    .catch(function(err) {
                        console.log(err);
                        if(err.response != undefined) {
                            console.log(err.response);
                        }
                    })
            },
            getItems: function() {

                this.$data.items = [];
                let self = this;

                getPlaceItems(this.$data.currentPlaceId, this.$data.dateRange[0], this.$data.dateRange[1])
                .then(function(data) {

                    if(data.success == true) {

                        self.$data.items = data.list;
                    } else {
                        console.log(data.message);
                    }
                })
                .catch(function(err) {
                    console.log(err);
                    if(err.response != undefined) {
                        console.log(err.response);
                    }
                })
            },
            confirmItem: function(item) {

                let self = this;
                confirmPlaceItem(item.placeId, item.id)
                .then(function(data) {
                    if(data.success == true) {
                        self.onItemUpdated(data.item);
                    } else {
                        window.alert(data.message);
                    }

                })
                .catch(function(err) {

                })
            },
            setItemUsed: function(item) {
                let self = this;
                setPlaceItemUsed(item.placeId, item.id)
                    .then(function(data) {
                        if(data.success == true) {
                            self.onItemUpdated(data.item);
                        } else {
                            window.alert(data.message);
                        }

                    })
                    .catch(function(err) {

                    })
            },
            onItemUpdated: function(item) {

                for(let i = 0 ; i < this.$data.items.length ; i++) {
                    if(this.$data.items[i].id == item.id) {
                        this.$data.items.splice(i, 1, item);
                    }
                }

                if(this.$data.todayHours != null && this.$data.todayHours.length > 0) {
                    for(let i = 0 ; i < this.$data.todayHours.length ; i++) {
                        if(this.$data.todayHours[i].items != null && this.$data.todayHours[i].items.length > 0) {
                            for(let j = 0 ; j < this.$data.todayHours[i].items.length ; j++) {
                                if(this.$data.todayHours[i].items[j].id == item.id) {
                                    this.$data.todayHours[i].items.splice(j, 1, item);
                                }
                            }

                        }
                    }
                }
                if(this.$data.selectedHour != false && this.$data.selectedHour != null && this.$data.selectedHour.items != null && this.$data.selectedHour.items.length > 0) {
                    for(let i = 0 ; i < this.selectedHour.items.length ; i++) {
                        if(this.$data.selectedHour.items[i].id == item.id) {
                            this.$data.selectedHour.items.splice(i, 1, item);
                        }
                    }
                }
                if(this.$data.pendingItems != undefined && this.$data.pendingItems != null && this.$data.pendingItems.length > 0) {
                    for (let i = 0; i < this.$data.pendingItems.length; i++) {
                        if (this.$data.pendingItems[i].id == item.id) {
                            if(item.state != 0) {
                                this.$data.pendingItems.splice(i, 1);
                            } else {
                                this.$data.pendingItems.splice(i, 1, item);
                            }
                        }
                    }
                }
                if(this.$data.item != false && this.$data.item.id == item.id) {
                    this.$data.item = item;
                }
            },
            getTodayItems: function() {
                if(this.$data.currentPlaceId <= 0) {
                    showDialog("플레이스를 선택해주세요");
                    return;
                }
                this.$data.dateToGet = millisToDate(new Date().getTime(), '-');
                this.onDateChanged();
                // this.$data.todayItems = [];
                // let self = this;
                //
                // let day = millisToDate(new Date().getTime(), '-');
                // getPlaceItems(this.$data.currentPlaceId, day, day)
                //     .then(function(data) {
                //
                //         if(data.success == true) {
                //
                //             self.$data.todayItems = data.list;
                //         } else {
                //             console.log(data.message);
                //         }
                //     })
                //     .catch(function(err) {
                //         console.log(err);
                //         if(err.response != undefined) {
                //             console.log(err.response);
                //         }
                //     })
            },
            closePendingItems: function() {

                this.$data.showPendingItems = false;
                this.$data.pendingItems = [];
            },
            getPendingItems: function() {
                if(this.$data.currentPlaceId <= 0) {
                    showDialog("플레이스를 선택해주세요");
                    return;
                }
                this.$data.pendingItems = [];
                let self = this;
                this.$data.showPendingItems = true;
                getPendingPlaceItems(this.$data.currentPlaceId)
                .then(function(data) {
                    if(data.success == true) {

                        self.$data.pendingItems = data.list;
                    } else {
                        console.log(data.message);
                    }
                })
                .catch(function(err) {

                })
            },
            onScheduleDateChanged: function() {
                this.$data.itemToReschedule.housrList = [];
                this.$data.itemToReschedule.newTime = '';
                let day = new Date(this.$data.itemToReschedule.newDate);
                let dayOfWeek = day.getDay();


                let hour = this.$data.itemToReschedule.hours[dayOfWeek];

                let slot = hour.slotLength * 60000;

                day.setHours(hour.openHour, hour.openMinute, 0 ,0);

                let startTime = day.getTime();
                let dayEnd = new Date(this.$data.itemToReschedule.newDate);
                dayEnd.setHours(hour.closeHour, hour.closeMinute, 0, 0);
                let endTime = dayEnd.getTime();

                while(startTime < endTime) {
                    let hmString = getHM(startTime),
                        item = {
                            title:hmString,
                            select: false,
                        }
                    this.$data.itemToReschedule.hoursList.push(item);
                    startTime += slot;
                }
            },
            submitReschedule: function() {
                if(this.$data.itemToReschedule.newDate === '') {
                    showDialog("변경할 날짜를 선택해주세요");
                    return;
                }
                if(this.$data.itemToReschedule.newTime === '') {
                    showDialog("변경할 시간을 선택해주세요");
                    return;
                }

                if(this.$data.itemToReschedule.newDate === this.$data.itemToReschedule.itemDate && this.$data.itemToReschedule.newTime === this.$data.itemToReschedule.itemTime) {
                    showDialog("날짜 시간 변경이 없습니다.");
                    return;
                }

                let self = this;
                reschedulePlaceItem(this.$data.itemToReschedule.placeId,this.$data.itemToReschedule.id,this.$data.itemToReschedule.newDate,this.$data.itemToReschedule.newTime)
                .then(function(data) {
                    if(data.success) {
                        self.$data.itemToReschedule = false;
                        self.updatePlaceItem(data.item);
                    }
                })
                .catch(function(err) {

                })
            },
            updatePlaceItem: function(item) {
                for(let i = 0 ; i < this.$data.todayItems.length ; i++) {
                    if(this.$data.todayItems[i].id == item.id) {
                        this.$data.todayItems.splice(i, 1, item);
                    }
                }
            },
            searchPlaceItems: function() {

            },
            closeItemToReschedule: function() {
                console.log("close");
                this.$data.itemToReschedule = false;
            },
            openItemToReschedule: function(item) {
                let self = this;
                getPlaceItem(this.$data.currentPlaceId, item.id)
                .then(function(data) {
                    if(data.success == true) {
                        self.getHoursToReschedule(data.item);
                    }
                })
                .catch(function(err) {

                })
            },
            showCancelPlaceItemDialog: function(item) {
                this.$data.cancelItem = {
                    id:item.id,
                    rate:100,
                    placeId:item.placeId,
                    item:item,
                }
            },
            cancelPlaceItem: function() {
                let self = this;
                let query = 'placeId='+this.$data.cancelItem.placeId + '&itemId=' + this.$data.cancelItem.id +'&rate=' + this.$data.cancelItem.rate + '&asPlaceManager=true';
                cancelPlaceItem(query)
                    .then(function(data) {
                        self.$data.cancelItem = false;
                        if(data.success == true) {
                            self.onItemUpdated(data.item);
                        } else {
                            showDialog(data.message);
                        }

                    })
                    .catch(function(err) {
                        self.$data.cancelItem = false;
                        console.log(err);
                        if(err.response != undefined) {
                            console.log(err.response);
                        }
                        showDialog(err, "ERROR");
                    })
            },
            askCancelPlaceItem: function(item) {
                let self = this;
                console.log("askCancelPlaceItem")
                this.$modal.show('dialog',{
                        text:'예약을 취소 하시겠습니까?',
                        buttons: [
                            {
                                title:'아니요',
                                handler:()=>{
                                    self.$modal.hide('dialog');
                                }
                            },
                            {
                                title:'취소하기',
                                handler:() => {
                                    self.$modal.hide('dialog');
                                    self.cancelPlaceItemOld(item);
                                }
                            }
                        ]
                    }

                );
            },

            cancelPlaceItemOld: function(item) {
                let self = this;
                cancelPlaceItem(item.placeId, item.id)
                    .then(function(data) {
                        if(data.success == true) {
                            self.onItemUpdated(data.item);
                        } else {
                            window.alert(data.message);
                        }

                    })
                    .catch(function(err) {

                    })
            },
            getHoursToReschedule: function(item) {
                let query = "placeId=" + this.$data.currentPlaceId + "&productId=" + item.productId;
                let self = this;
                getHours(query)
                .then(function(data) {
                    if(data.success == true) {
                        console.log("get Hours");
                        console.log(data)
                        item.hours = data.item;
                        item.newDate = '';
                        item.newTime = '';
                        item.hoursList = [];
                        self.$data.itemToReschedule = item;
                        console.log(self.$data.itemToReschedule);

                    } else {
                        console.log(data.message);
                    }
                })
                .catch(function(err) {

                })
            },
            getProductHours: function(product) {

                let query = "placeId=" + product.placeId + "&productId=" + product.id;
                getHours(query)
                .then(function(data) {

                })
                .catch(function(err) {

                })
            },
            getPriceName: function(index) {
              if(this.$data.products == null || this.$data.products.length == 0) {
                  return 'NA';
              }
              if(this.$data.selectedProductId == 0) {
                  return 'NA';
              }
              for(let i = 0 ; i < this.$data.products.length ; i++) {
                  if(this.$data.products[i].id == this.$data.selectedProductId) {
                      if(index == 1) {
                          return this.$data.products[i].priceName;
                      } else {
                          return this.$data.products[i]['priceName' + index];
                      }
                  }
              }
              return 'Not found ' + index;

            },
            showQrCodeScanner: function() {
                $("#qrReader").removeClass("hide");
                this.$data.qrReaderOn = true;
            },
            stopQrScanner: function() {
                $("#qrReader").addClass("hide");
                this.$data.qrReaderOn = false;
            },
            onCodeScanned: function(text) {
                console.log("scanned " + text);
                if(!text.startsWith("maryplace://placeRef:")) {
                    window.alert("올바른 QRCode정보가 아닙니다.");
                    return;
                }
                let id = text.replace("maryplace://placeRef:", "");
                id = parseInt(id);


                let self = this;
                getPlaceItem(this.$data.currentPlaceId, id)
                .then(function(data) {
                    if(data.success == true) {
                        self.$data.item = data.item;
                        self.stopQrScanner();
                    } else {
                        showDialog(data.message);
                    }
                })
                .catch(function(err) {

                })

            },

            reportDateRangeSelected: function() {
                console.log("dateRangeSelected")
                console.log(this.$data.reportDateRange)

                if(this.$data.reportDateRange.length !== 2) {
                    return;
                }

                if(this.$data.reportDateRange[0] > this.$data.reportDateRange[1]) {
                    let temp = this.$data.reportDateRange[1];
                    this.$data.reportDateRange[0] = this.$data.reportDateRange[1];
                    this.$data.reportDateRange[1] = temp;
                }

            },
            closeReport: function() {
                this.$data.reportItem = false;
                this.$data.reportView = false;
            },
            getReport: function() {
                if(this.$data.reportDateRange.length !== 2) {
                    return;
                }

                let self = this;
                getPlaceItemReport(this.$data.currentPlaceId, this.$data.reportDateRange[0], this.$data.reportDateRange[1])
                .then(function(data) {
                    if(data.success == true) {
                        let list = [];
                        if(data.item.list != undefined && data.item.list != null && data.item.list.length > 0) {
                            list = data.item.list;
                        }
                        if(data.item.used != null && data.item.used.length > 0) {
                            list = list.concat(data.item.used)
                        }
                        if(data.item.refunded != null && data.item.refunded.length > 0) {
                            list = list.concat(data.item.refunded)
                        }
                        if(data.item.cancelled != null && data.item.cancelled.length > 0) {
                            list = list.concat(data.item.used)
                        }

                        data.item.list = list;
                        console.log(data.item);

                        data.item.list.sort(function(a, b) {
                            if(a.itemTimeString < b.itemTimeString) {
                                return -1;
                            } else if(a.itemTimeString > b.itemTimeString) {
                                return 1;
                            }
                            return 0;
                        })
                        data.item.totalPayout = 0;
                        for(let i = 0 ; i < data.item.list.length ; i++) {
                            data.item.totalPayout += data.item.list[i].payout;
                        }

                        self.$data.reportItem = data.item;
                    } else {
                        showDialog(data.message);
                    }
                })
                .catch(function(err) {
                    showDialog(err, "에러");
                })
            },
            subscribeChannel: function() {
                //Should be changed according to current user
                this.$data.mqtt.subscribe('place-' + this.$data.currentPlaceId);
            },
            onMessageArrived: function (msg) {
                // console.log("message arrived");
                let msgStr = msg.payloadString;
                console.log('message arrived ' + msgStr);
                let item = JSON.parse(msgStr);
                if(item.cmd != undefined && item.cmd === 'newItem') {
                    showDialog(item.msg, "신규 버튼을 눌러 대기 예약을 확정해주세요");
                }
            },
            /**
             * Callback when MQTT is connected
             * Now should subscribe maryBikes/(operatorId)
             */
            onConnect: function () {
                console.log("Connected subscribe maryapp");
                this.$data.mqttConnected = true;
                this.subscribeChannel();
            },


            /**
             * When conneciton is lost, should reconnect manually.
             * Reconnect option does not really reconnect when switching from WIFI to mobile network
             */
            onConnectionLost: function(resObj) {
                console.log("connection lost");
                // this.$data.mqttConnected = false;
                // this.$data.mqtt = null;
                console.log("Lost connection to " + resObj.uri + "\nError code: " + resObj.errorCode + "\nError text: " + resObj.errorMessage);
                console.log("Now try again");
                this.$data.mqttConnected = false;
                if(this.$data.disconnectMqtt === false) {
                    setTimeout(this.connect, 1000);
                }
            },

            /**
             * Callback when MQTT connection failed
             * @param message
             */
            onMqttFailure: function (message) {
                console.log("MQTT failed " + message);
                console.log(message);
                console.log("Attempt to reconnect in 1 second");

                this.$data.mqttConnected = false;
                setTimeout(this.connect, 1000);
            },
            disconnect: function() {
                if(this.$data.mqtt == null || this.$data.mqtt.isConnected() == false) {
                    return;
                }
                this.$data.mqtt.disconnect();
            },
            connect: function () {
                if(this.$data.mqtt == null || this.$data.mqtt.isConnected() == false) {

                    console.log("MQTT is not connected so attempt to connect now");
                    // this.$data.mqtt = new Paho.Client("mqtt.boriumstudio.com", Number(9001), "clientjsasdasd" + new Date().getTime());
                    // this.$data.mqtt = new Paho.Client("34.64.173.39", Number(1883), 'maryApp-' + new Date().getTime());
                    this.$data.mqtt = new Paho.Client("mqtt.marysharing.com", Number(9001), 'maryApp-' + new Date().getTime());
                    console.log("MQTT created");
                    console.log(this.$data.mqtt);
                    // this.$data.mqtt.disconnect();
                    this.$data.mqtt.onConnectionLost = this.onConnectionLost;
                    let options = {
                        timeout: 3,
                        useSSL: true,
                        userName: "maryapp",
                        password: "app23344",
                        onSuccess: this.onConnect,
                        onFailure: this.onMqttFailure,
                        cleanSession: true, //Clean session
                        reconnect : false, // Enable automatic reconnect
                        // reconnectInterval:1, //Reconnect attempt interval : 1 second
                    };
                    this.$data.mqtt.onMessageArrived = this.onMessageArrived;
                    console.log("MQTT now connects...");
                    this.$data.mqtt.connect(options); //connect
                } else {
                    console.log("Already connected");
                    this.$data.mqttConnected = true;
                }
                if(this.$data.mqtt != null || this.$data.mqtt.onMessageArrived !== undefined ) {
                    console.log("Not connected");
                } else if( this.$data.mqtt.onMessageArrived === null || this.$data.mqtt.onMessageArrived !== this.onMessageArrived) {
                    this.$data.mqtt.onMessageArrived = this.onMessageArrived;
                } else {
                    console.log("message arrive handler Already registered");
                }

            },

        }
    }
</script>

<style scoped>

    .mobile-hide {

    }

    @media screen and (max-width: 420px) {
        .mobile-hide {
            display: none;
        }
    }

    .place-item-state--10 {
        background:#E9E9E9;
    }
    .place-item-state--20 {
        background:#FFDFDF;
    }
</style>
