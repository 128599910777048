<template>
    <div style="width: 100%; height: 100%; display: flex;">
        <div style="width: 200px; height: 100%">
            <div style="width: 100%; height: 50px;" class="section-title">
                매장리스트
            </div>
            <div style="width: 100%; height: calc(100% - 50px); overflow-y: scroll;" v-if="item.id === 0">
                <ul class="list-group">
                    <template v-for="p in places">
                        <li class="list-group-item candidate" v-bind:key="'place-selector-'+p.id"
                            v-if="isPlaceSelectedForNotification(p) === false"
                            @click="selectNotificationPlace(p)">
                            {{p.name}}
                        </li>
                    </template>
                </ul>
            </div>
        </div>
        <div style="width: 200px; height: 100%">
            <div style="width: 100%; height: 50px;" class="section-title">
                선택매장
            </div>
            <div style="width: 100%; height: calc(100% - 50px); overflow-y: scroll;" v-if="item.id === 0">
                <ul class="list-group">
                    <template v-for="(p, pi) in selectedPlaces">
                        <li class="list-group-item selected" v-bind:key="'place-selected-'+p.id"
                            @click="unselectNotificationPlace(pi)">
                            {{p.name}}
                        </li>
                    </template>
                </ul>
            </div>
        </div>
        <div style="width: calc(100% - 400px); height: 100%; overflow-y: scroll;">
            <div class="container">
                <div class="row">
                    <div class="col-12 section-title" style="margin-bottom: 20px;" >
                        <span v-if="item.id === 0">공지사항 등록</span>
                        <span v-else>공지사항 수정</span>
                    </div>
                </div>
            </div>
            <div class="container input-container">
                <div class="row">
                    <div class="col-12">
                        제목
                        <input v-model="item.title" class="form-control"/>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        이미지<br/>
                        <img v-if="item.image !== null && item.image.length > 0" class="notification-image"  :src="item.image"/>
                        <progress value="0" max="100" id="background-uploader"></progress>
                        <input accept="image/*" type="file" ref="file" value="upload" @change="bgImagePickedUp"/>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <textarea v-model="item.content" cols="10" class="form-control"/>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <button class="btn btn-dark" @click="closeView">닫기</button>
                    </div>
                    <div class="col-6 text-right">
                        <button class="btn btn-success" v-if="item.id === 0" @click="registerNotification">등록</button>
                        <button class="btn btn-primary" v-else-if="item.id > 0" @click="updateNotification">업데이트</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {addNotifications, updateNotification} from "@/api/notifications";
import {showDialog} from "@/api/common";
import {getApp} from "firebase/app";
import {getDownloadURL, getStorage, ref, uploadBytesResumable} from "firebase/storage";
import moment from 'moment';
export default {
    name: "NotificationForm",
    props:{
        item:{
            type:Object,
            default: function() {return null;}
        },
        places:{
            type:Array,
            default: function(){return [];}
        },
        onClose:{
            type: Function,
            default:null,
        },
        onCompleted: {
            type: Function,
            default: null,
        },
        onUpdated: {
            type: Function,
            default: null,
        }
    },
    data() {
      return {
          selectedPlaces:[],
      }
    },
    created() {
    },
    mounted() {
    },
    methods: {
        closeView: function() {
          if(this.onClose !== null) {
              this.onClose();
          }
        },
        unselectNotificationPlace: function(index) {
            this.$data.selectedPlaces.splice(index, 1);
        },
        selectNotificationPlace: function(place) {
            for(let i = 0 ; i < this.$data.selectedPlaces.length ; i++) {
                if(this.$data.selectedPlaces[i].id === place.id) {
                    return;
                }
            }
            this.$data.selectedPlaces.push(place);
        },
        unselectAll: function() {
            this.$data.selectedPlaces = [];
        },
        selectAllForNotification: function() {
            this.$data.selectedPlaces = [];
            for(let i = 0 ; i < this.places.length ; i++) {
                this.$data.selectedPlaces.push(this.places[i]);
            }
        },
        isPlaceSelectedForNotification: function(place) {
            for(let i = 0 ; i < this.$data.selectedPlaces.length ; i++) {
                if(this.$data.selectedPlaces[i].id === place.id) {
                    return true;
                }
            }
            return false;
        },
        updateNotification: function() {
            let self = this;
            updateNotification(this.item)
                .then(function(data) {
                    if(data.success === true) {
                        if(self.onUpdated !== null) {
                            self.onUpdated(data.item);
                        }

                    } else {
                        showDialog(data.message);
                    }
                })
                .catch(function(err) {
                    let msg = err;
                    if(err.response !== undefined) {
                        msg += ' (' + err.response + ')';
                    }
                    showDialog(msg, "ERROR");
                })
        },
        registerNotification: function() {

            let items = [this.item];

            for(let i = 0 ; i < this.$data.selectedPlaces.length ; i++) {
                let placeNotiItem = JSON.parse(JSON.stringify(this.item));
                placeNotiItem.placeId = this.$data.selectedPlaces[i].id;
                items.push(placeNotiItem);
            }

            let self = this;
            addNotifications(items)
                .then(function(data) {

                    if(data.success === true) {
                        if(self.onCompleted !== null) {
                            self.onCompleted(data.item);
                        }
                    } else {
                        showDialog(data.message);
                    }
                })
                .catch(function(err) {
                    let msg = err;
                    if(err.response !== undefined) {
                        msg += ' (' + err.response + ')';
                    }
                    showDialog(msg, "ERROR");
                })
        },
        bgImagePickedUp: function(e) {
            const uploader = document.getElementById('background-uploader');
            let self = this;

            //get file
            console.log("File Btn Added");
            console.log(e);
            let getFile = e.target.files[0];
            console.log("File name - " + getFile.name);

            //set storage ref
            let d = new Date();
            let name  =  moment(d).format('YYYY/MM/DD/hhmmss');
            let names = getFile.name.split('.');
            let fileExt = names[names.length-1];
            let path = 'ncm-place/notices/' + this.item.id + '-' + name + '.' + names[names.length-1];
            const firebaseApp = getApp();
            let contentType = null;
            if(fileExt.toUpperCase() === 'JPG' || fileExt.toUpperCase() === 'JPEG') {
                contentType = 'image/jpeg';
            } else if(fileExt.toUpperCase() === 'PNG') {
                contentType = 'image/png';
            } else if(fileExt.toUpperCase() === 'PDF') {
                contentType = 'application/pdf';
            }
            const metadata = {
                contentType: contentType
            };
            const storage = getStorage(firebaseApp);
            let storageRef = ref(storage, path);
            const uploadTask = uploadBytesResumable(storageRef, getFile, metadata);
            uploadTask.on('state_changed',
                (snapshot) => {
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    console.log('Upload is ' + progress + '% done');
                    uploader.value = progress;
                    switch (snapshot.state) {
                        case 'paused':
                            console.log('Upload is paused');
                            break;
                        case 'running':
                            console.log('Upload is running');
                            break;
                    }
                },
                (error) => {
                    // EventBus.$emit("showLoading", false);
                    // A full list of error codes is available at
                    // https://firebase.google.com/docs/storage/web/handle-errors
                    switch (error.code) {
                        case 'storage/unauthorized':
                            // User doesn't have permission to access the object
                            break;
                        case 'storage/canceled':
                            // User canceled the upload
                            break;

                        // ...

                        case 'storage/unknown':
                            // Unknown error occurred, inspect error.serverResponse
                            break;
                    }
                },
                () => {
                    // Upload completed successfully, now we can get the download URL
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        console.log('File available at', downloadURL);
                        self.item.image = downloadURL;
                    });
                }
            );
        },
    }
}
</script>

<style scoped>
.notification-image {
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
}
.section-title {
    line-height: 50px;
    text-align: center;
    font-weight: bold;;
    background-color: #cccccc;
}
.input-container .row {
    margin-top: 20px;
}
.list-group-item.candidate {
    background-color: #eee;
}
.list-group-item.selected {
    background-color: #AAC5FA;
    color: black;
}
</style>
