<template>
    <div class="list-detail-view">
        <DetailLayout
            v-bind:is-available-more-btn="itemsToShow < tags.length"
            :on-detail="detailItem != null"
            v-on:onRequestMoreList="onRequestMoreList">

            <template v-slot:list-title-text>
                {{$t('menu.super.list.'+viewKey)}} 관리

                <select v-model="currentType" @change="onTagTypeChanged" style="float:right;">
                    <option v-for="type in types" :value="type.value">{{type.name}}</option>
                </select>
            </template>
            <!--      <template v-slot:graph-container>-->
            <!--        <div class="option-container">-->
            <!--          <select>-->
            <!--            <option value="7">최근7일</option>-->
            <!--            <option value="30">최근30일</option>-->
            <!--            <option value="90">최근90일</option>-->
            <!--          </select>-->
            <!--        </div>-->
            <!--        <GChart-->
            <!--          class="chart-view"-->
            <!--          type="ColumnChart"-->
            <!--          :data="stockGraphList"-->
            <!--          :options="chartOptions"-->
            <!--        />-->
            <!--      </template>-->
            <template v-slot:top-items>
                <div class="list-top-btns">
                    <div class="add-item" v-on:click="onClickAddBtn()">
                        <span style="margin-right: 5px;">{{$t('list.add')}}</span>
                        <span class="fas fa-plus"></span>
                    </div>
                </div>
                <div class="sort-btn-wrapper">
                    <sort-and-search-input label-search="이름"

                        :sort-type-list="sortTypeList"

                        v-on:onEnterSearch="onSearchName"
                        v-on:onClickSortType="onClickSortType"/>
                </div>

            </template>
            <template v-slot:list-items>
                <div v-if="tags.length === 0" class="empty-list">
                    <p>{{$t('list.has_no_material')}}</p>
                </div>
                <div v-else>
                    <template v-for="(item, index) in tags">
                        <Item v-if="index < itemsToShow"
                              v-bind:item="item"
                              :is-selected="detailItem != null && item.id === detailItem.id"
                              v-on:onClick="onClickItem" />
                    </template>
                </div>
            </template>
            <template v-slot:detail-item>
                <Editor
                    v-on:onClose="onClickItem"
                    v-on:updateList="updateListView"

                    v-bind:detail-item="detailItem"/>
            </template>
        </DetailLayout>
    </div>
</template>

<script>
    import DetailLayout from "../DetailLayout";
    import Item from "../../components/contents/tag/Item";
    import Editor from "../../components/contents/tag/Editor";

    import {generateNewTag, getTagById, getTagList, getTags, getTagTypes} from "../../api/tag";
    import {reorderItems} from "../../api/common";
    import {clone} from "../../utils/VarToString";
    import SortAndSearchInput from "../../inputs/SortAndSearchInput";

    export default {
        name: "TagView",
        components: {
            SortAndSearchInput,
            DetailLayout,
            Item,
            Editor
        },
        data() {
            return {
                viewKey:'tag',
                isSelectedIndex: -1,
                itemsToShow: 5,
                tags: [],
                detailItem: null,
                sortTypeList: [
                    {
                        name: this.$t('list.sort_type'),
                        value: 'type',
                        order: 'asc'
                    },
                    {
                        name: this.$t('list.sort_name'),
                        value: 'name',
                        order: 'asc'
                    },
                    {
                        name: this.$t('list.sort_position'),
                        value: 'pos',
                        order: 'asc'
                    }
                ],
                currentSortType: {},
                chartOptions: {
                    chart: {
                        title: 'Stock List',
                    }
                },
                types:getTagTypes(),
                currentType:'',
                stockGraphList:[]
            }
        },

        created() {
            this.currentSortType = this.sortTypeList[0];

            this.updateListView();
            this.updateEditorView();
        },
        watch: {
            $route(){
                this.render();
                this.updateEditorView();
            }
        },
        methods: {
            // render: function() {
            //     /**
            //      * If category changed, remove all items to refresh
            //      */
            //     this.$data.detailItem = null;
            //
            //     this.updateListView();
            //     this.updateEditorView();
            // },
            onTagTypeChanged: function() {
                let self = this;
              getTagList(this.$data.currentType)
                .then(function(data) {
                    if(data.success == true) {
                        self.$data.tags = data.list;
                    }
                })
            },
            onSearchName(value){
                this.tags = [];

                let that = this;
                getTags().then(tags => {
                    for(let tag of tags){
                        if(tag.name.includes(value)) that.tags.push(tag);
                    }

                    that.tags = reorderItems(that.tags, that.currentSortType);
                })
            },
            updateListView: function() {
                let that = this;

                getTags().then(function(tags){
                    that.tags = reorderItems(tags, that.currentSortType);
                })
            },
            updateEditorView: function() {
              let that = this;
              if (this.$route.query.hasOwnProperty('id')) {

                  getTagById(this.$route.query.id)
                      .then(tag => { that.detailItem = tag });
              }
            },
            onRequestMoreList: function() {
                this.itemsToShow = this.itemsToShow + 5;
            },
            onClickSortType: function(selectedOption) {
                this.currentSortType = selectedOption;

                this.tags = reorderItems(this.tags, this.currentSortType);
            },
            onClickAddBtn: function() {
                this.detailItem = generateNewTag();
                this.detailItem.type = this.$data.currentType;
                this.$router.push('/admin/tag?new');
            },
            onClickItem: function(item) {
                this.detailItem = clone(item);

                let path = `/admin/tag`;
                if(item) {
                    path += `?id=${item.id}`;
                }

                if(this.$route.fullPath !== path) {
                    this.$router.push(path);
                }
            }
        },
    }
</script>

<style lang="scss" scoped>

    .list-detail-view {
        height: 100%;
    }
    .tag-search-wrapper {
        margin-top: 15px;
    }

    .tag-search {
        width: 100%;
        border: 1px solid #ddd;
        border-radius: 3px;
        font-size: 14px;
        padding: 5px 10px;
    }
    .chart-view {
        width: 100%;
        height: 300px;
    }
    .option-container {
        width: 100%;
        select {
            background: #FFFFFF;
            border: 1px solid #000000;
            box-sizing: border-box;
        }
    }

</style>
