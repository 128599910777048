<template>
    <div class="list-detail-view">
        <NoMapWideDetailLayout
            v-bind:is-available-more-btn="false"

            :fullScreen="true"
            :on-detail="detailItem != null">

            <template v-slot:list-title-text>
                {{$t('menu.user.list.'+type)}} 관리 <span style="font-size: 20px;" v-if="userReport != null && userReport.length > 0">{{userReport}}</span>
            </template>
            <!--      <template v-slot:graph-container>-->
            <!--        <div class="option-container">-->
            <!--          <select>-->
            <!--            <option value="7">최근7일</option>-->
            <!--            <option value="30">최근30일</option>-->
            <!--            <option value="90">최근90일</option>-->
            <!--          </select>-->
            <!--        </div>-->
            <!--        <GChart-->
            <!--          class="chart-view"-->
            <!--          type="ColumnChart"-->
            <!--          :data="stockGraphList"-->
            <!--          :options="chartOptions"-->
            <!--        />-->
            <!--      </template>-->
            <template v-slot:top-items>
                <div class="list-top-btns">
                    <div class="add-item" v-on:click="onClickAddBtn()">
                        <span style="margin-right: 5px;">{{$t('list.add')}}</span>
                        <span class="fas fa-plus"/>
                    </div>
                </div>
            </template>
            <template v-slot:list-items>
                <sort-and-search-input :label-search="searchLabel()" v-on:onEnterSearch="onSearchPhoneNumber"/>
                <hr>
                <vue-good-table
                    mode="remote"
                    @on-sort-change="onClickSortType"
                    @on-row-click="onClickUser"
                    @on-page-change="onPageChange"
                    @on-per-page-change="onPerPageChange"

                    :pagination-options="{
                        enabled: true,
                        perPage: pagination.size,
                        perPageDropdown: [15, 25, 50],
                        dropdownAllowAll: false
                    }"

                    :rows="users"
                    :total-rows="itemsToShow"
                    :columns="columns"/>
<!--                <pulse-loader v-if="!isLoaded" :loading="!isLoaded" color="#FFC0CB" style="margin-top: 10%;"/>-->
<!--                <div v-else-if="users.length === 0" class="empty-list">-->
<!--                    <p>{{$t('list.has_no_material')}}</p>-->
<!--                </div>-->
<!--                <div v-else>-->
<!--                    <template v-for="(item, index) in users">-->
<!--                        <Item v-if="index < itemsToShow"-->
<!--                              v-bind:detail-item="item"-->
<!--                              v-on:onClick="onClickUser" />-->
<!--                    </template>-->
<!--                </div>-->
            </template>
            <template v-slot:detail-item>
                <normal-user-editor
                    v-if="'normal' === type"

                    v-bind:userInfo="detailItem"
                    v-on:onClose="onClickUser"
                    v-on:updateList="updateListView"
                    :is-add-mode="isAddMode"/>
                <special-user-editor
                    v-else

                    v-bind:userInfo="detailItem"
                    v-on:onClose="onClickUser"
                    v-on:updateList="updateListView"
                    :is-add-mode="isAddMode"/>
            </template>
        </NoMapWideDetailLayout>
        <v-dialog max-width="400px" persistent/>
    </div>
</template>

<script>
    import {
        generateNewUser, getMembershipReport, getNormalUsers, getSpecialUsers,
        getUserById,
        getUsers,
        ROLE_ADMIN,
        ROLE_GENERAL,
        searchUserByText
    } from "../../api/user";

    import DropdownInput from "../../inputs/DropdownInput";

    import NoMapWideDetailLayout from "../NoMapWideDetailLayout";
    import Item from "../../components/contents/user/Item";
    import Detail from "../../components/contents/user/SpecialEditor";
    import {getOperatorId, isSuperAdmin} from "../../store";
    import {
        changeURL,
        checkOperator,
        defaultPagination,
        defaultPaginationOption,
        isOk,
        reorderItems
    } from "../../api/common";
    import {getOperatorMap, setTagsByOperatorId} from "../../api/operator";

    import NormalUserEditor from "../../components/contents/user/NormalEditor";
    import SpecialUserEditor from "../../components/contents/user/SpecialEditor";
    import SortAndSearchInput from "../../inputs/SortAndSearchInput";

    import {PulseLoader} from 'vue-spinner/dist/vue-spinner.js'
    import {clone} from "../../utils/VarToString";

    export default {
        name: "UserView",
        components: {
            SortAndSearchInput,
            PulseLoader,

            SpecialUserEditor,
            NormalUserEditor,
            NoMapWideDetailLayout,
            DropdownInput,

            Item,
            Detail
        },
        props: {
            type: {
                type: String,
                default: ''
            }
        },
        data() {
            return {
                // isSelectedIndex: -1,
                // isLoaded: false,

                itemsToShow: 10,
                users: [],
                detailItem: null,
                // sortTypeList: [
                //     {
                //         name: 'PHONE_NUMBER',
                //         value: 'phoneNumber',
                //         order: 'asc'
                //     },
                //     {
                //         name: 'NAME',
                //         value: 'name',
                //         order: 'asc'
                //     },
                //     {
                //         name: 'EMAIL',
                //         value: 'email',
                //         order: 'asc'
                //     }
                // ],
                columns: [
                    {field: 'id', label: '#'},
                    {field: 'operatorId', label: '사업 소속'},
                    {field: 'name', label: '이름'},
                    {field: 'phoneNumber', label: '전화번호'},
                    {field: 'email', label: '이메일'},
                ],
                currentSortType: {
                    value: 'createdWhen',
                    order: 'desc'
                },

                operatorMap: {},

                pagination: {
                    page: 0,
                    size: 25,
                    sort: "createdWhen,desc"
                },
                // paginationOptions: defaultPaginationOption(this.pagination.size),

                isEditMode: false,
                isAddMode: false,
                userReport:'',
            }
        },
        created: async function() {
            this.operatorMap = await getOperatorMap();

            this.updateListView();
            this.updateEditorView();
        },
        watch: {
            $route() {
                this.updateEditorView();
            },
        },
        methods: {
            isInterestedInNormalUser(){
                return "normal" === this.type;
            },
            // render: function() {
            //     // this.$data.title = this.$i18n.t('board.' + this.$route.params.category);
            //
            //     /**
            //      * If category changed, remove all items to refresh
            //      */
            //     this.$data.detailItem = null;
            //
            //     this.updateListView();
            //     this.updateEditorView();
            // },
            updateListView: async function() {
                let dataList = [];

                let that = this;
                if(this.isInterestedInNormalUser()) {
                    await getNormalUsers(this.pagination).then(data => {
                        if(data.success) {
                            that.itemsToShow = data.totalElements;
                            for(let user of data.list){
                                user.operatorId = this.operatorMap[user.operatorId];

                                dataList.push(user);
                            }
                        }
                    })
                } else {
                    await getSpecialUsers(this.pagination).then(data => {
                        if(data.success) {
                            that.itemsToShow = data.totalElements;
                            for(let user of data.list){
                                user.operatorId = this.operatorMap[user.operatorId];

                                dataList.push(user);
                            }
                        }
                    })
                }

                this.getUserReport();
                this.users = dataList;
            },
            getUserReport: function() {
                let self = this;
                getMembershipReport()
                .then(function(data) {
                    if(data.success == true) {
                        self.$data.userReport = data.message;
                    }
                })
            },
            updateEditorView: function() {
                this.detailItem = null;

                if (this.$route.query.hasOwnProperty('id')) {
                    let contentId = this.$route.query.id;

                    let that = this;
                    getUserById(contentId).then(item => {
                        that.detailItem = item;
                    });
                }
            },
            // onRequestMoreList: function() {
            //     this.itemsToShow = this.itemsToShow + 5;
            // },
            onClickSortType: function(params) {
                let param = params[0];

                this.pagination.sort = `${param.field},${param.type}`;
                this.currentSortType = {
                    value: param.field,
                    order: param.type
                };

                this.updateListView();
                // this.currentSortType = selectedOption;
                // this.users = reorderItems(this.users, this.currentSortType);
            },
            onPageChange(params) {
                this.pagination.page = params.currentPage - 1;

                this.updateListView();
            },
            onPerPageChange(params) {
                this.pagination.page = 0;
                this.pagination.size = params.currentPerPage;

                this.updateListView();
            },
            onClickAddBtn: function() {
                this.isAddMode = true;

                this.detailItem = generateNewUser();
                if(!this.isInterestedInNormalUser())
                    this.detailItem.role = ROLE_ADMIN;

                setTagsByOperatorId(getOperatorId(), this.detailItem);

                changeURL(this.type, this.$route, this.$router);
            },
            onClickUser: function(item) {
                this.isAddMode = false;

                let user = item != null ? item.row : null;

                changeURL(this.type, this.$route, this.$router, user);
            },
            onSearchPhoneNumber: async function(value) {
                let that = this;

                let role = this.isInterestedInNormalUser() ? "GENERAL" : "SPECIAL";
                searchUserByText(value, role).then(list => {
                    that.users = list;
                })
            },
            isOk: function (user) {
                if(!checkOperator(user))
                    return false;

                return ! user.deleted;
            },

            searchLabel(){
                if(0 === getOperatorId())
                    return "사업자 이름, 이름, 전화번호, 이메일, 태그";
                else
                    return "이름, 전화번호, 이메일, 태그";
            },
        },
    }
</script>

<style lang="scss" scoped>

    .list-detail-view {
        height: 100%;
    }
    .tag-search-wrapper {
        margin-top: 15px;
    }

    .tag-search {
        width: 100%;
        border: 1px solid #ddd;
        border-radius: 3px;
        font-size: 14px;
        padding: 5px 10px;
    }
    .chart-view {
        width: 100%;
        height: 300px;
    }
    .option-container {
        width: 100%;
        select {
            background: #FFFFFF;
            border: 1px solid #000000;
            box-sizing: border-box;
        }
    }

</style>
