<template>
    <div class="list-detail-view">
        <MapListLayout
            v-bind:is-available-more-btn="itemsToShow < assets.length"
            :on-detail="detailItem != null"
            :key="'bike-list-view'"
            :fullScreen="true"
            :detailFullScreen="false"
            :detailHalfScreen="true"
            v-on:onRequestMoreList="itemsToShow += 10">
            <template v-slot:list-title-text>
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-4">
                            {{$t('menu.bike.list.'+modelKey)}} 관리
                        </div>
                        <div class="col-4">
                            <div v-if="operators.length > 1">
<!--                                <label>{{$t('operator.select')}}</label>-->
                                <select v-model="selectedOperatorId" @change="onOperatorSelected">
                                    <option v-for="o in operators" v-bind:key="'operator-selector-' + o.id" :value="o.value">{{o.name}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-4">
                            <span style="float:right;"><i class="fas" v-bind:class="{'fa-wifi':mqttConnected == true, 'fa-times': mqttConnected == false}"></i></span>
                        </div>
                    </div>
                </div>



            </template>
            <template v-slot:map-container>
                <gmap-map
                    ref="gmap"
                    :center="mapCenter"
                    :zoom="mapZoom"
                    :options="{
                        streetViewControl:false,
                        fullscreenControl:false,
                        mapTypeControl:false
                    }"
                    class="google-map h-100"

                    style="width: 100%; height: calc(100% - 100px);">
                    <gmap-marker
                        :key="index"
                        v-for="(m, index) in markers"
                        :position="m.position"
                        @click="onMapAssetClick(m)"
                        :icon="{
                            url: require('../../assets/images/bike-' + getBikeIcon(m) + '.png'),
                            scaledSize: {width:50, height:50},
                            // labelOrigin:m.position,
                            }"
                        :label="{
                            text: m.name,
                            color: '#000000',
                            fontWeight: 'bold'
                            }"
                    >
                    </gmap-marker>
                </gmap-map>
            </template>
            <template v-slot:top-items>
                <div style="width:100%; height: 86px;">
                    <div class="row">
<!--                        <div class="col-6" style="display: none;">-->
<!--                            <div class="sort-btn-wrapper">-->
<!--                                <sort-and-search-input-->
<!--                                    :sort-type-list="sortTypeList"-->

<!--                                    v-on:onEnterSearch="onSearchTags"-->
<!--                                    v-on:onClickSortType="onClickSortType"/>-->
<!--                            </div>-->
<!--                        </div>-->
                        <div class="col-3">
                            <select class="form-control" v-model="bikeType" @change="onBikeTypeChanged">
                                <option value="all">모든자전거</option>
                                <option value="noResponse">응답없음</option>
                                <option value="lowBattery">배터리 낮음</option>
                                <option value="noSysLog">로그없는 자전거</option>
                                <option value="available">대여가능</option>
                                <option value="hired">대여중</option>
                                <option value="charging">충전중</option>
                                <option value="repair">수리중</option>
                                <option value="warehouse">입고</option>
                                <option value="moving">이동중</option>
                                <option value="created">생성</option>

                            </select>
                        </div>
                        <div class="col-9" v-if="superAdmin == true">
                            <button class="btn btn-primary" v-on:click="onClickExcelDownloadBtn()">
                                <span style="margin-right: 5px;">{{$t('list.download')}}</span>
                                <span class="fas fa-file-excel"></span>
                            </button>
                            <button class="btn btn-primary" v-on:click="onClickAddBtn()">
                                <span style="margin-right: 5px;">{{$t('list.add')}}</span>
                                <span class="fas fa-plus"></span>
                            </button>
                        </div>
                    </div>
                    <div class="row" style="margin-top:10px;">
                        <div class="col-8">
                            <input class="form-control" v-model="searchWord" placeholder="자전거 이름,QRcode, MQTT channel, IMEI 검색"/>
                        </div>
                        <div class="col-4">
                            <button class="btn btn-primary" v-on:click="searchBikes"><i class="fas fa-search"></i></button>
                            <button class="btn btn-dark" v-on:click="cancelSearch"><i class="fas fa-times"></i></button>
                        </div>
                    </div>
                </div>



<!--            </template>-->
<!--            <template v-slot:list-items>-->
<!--                <pulse-loader v-if="!isLoaded" :loading="!isLoaded" color="#FFC0CB" style="margin-top: 10%;"/>-->
                <div v-if="assets.length === 0" class="empty-list">
                    <p>{{$t('list.has_no_material')}}</p>
                </div>
                <div style="width:100%; height: calc(100% - 89px); overflow-y: auto;">

                    <table class="table" style="margin-top: 0;">
                        <tr class="sticky-header-row">
                            <th></th>
                            <th>QRCode</th>
                            <th>다음점검</th>
<!--                            <th>Channel</th>-->
                            <th>On/Off</th>
                            <th>상태</th>
                            <th>Last Log</th>
                            <th style="min-width: 100px;">상태<br><i class="fas fa-battery-half"></i></th>
                            <th><span><i class="fas fa-map-marker-alt"></i></span></th>
                            <th><span><i class="fas fa-parking"></i></span></th>
                            <th>IMEI 만료일</th>

                        </tr>
                        <tbody>
                            <tr v-for="(item, index) in assets" v-bind:key="'bike-item-tr-'+item.id" v-on:click="onClickAsset(item)" v-bind:class="{'selected': detailItem != null && item.id === detailItem.id, 'low-battery-item': item.battery <= 20 || assetsHasRecentLogMap[item.id] === false, 'empty-log': item.lastSysLogTime == 0}" :id="'asset-' + item.id">
                                <td>{{index+1}}</td>
                                <td>{{item.fullCode}}</td>
                                <td>{{item.daysToNextService}}</td>
                                <td>
                                    <span style="font-size: 30px; color:red;" v-if="item.onOff==true" v-on:click.stop="askTurnOff(item)"><i class="fas fa-toggle-on"></i></span>
                                    <span style="font-size: 30px; color:grey;" v-else><i class="fas fa-toggle-off"></i></span>
                                </td>
                                <td>
                                    <img width="40px" :src="require('../../assets/images/bike-' + getBikeIcon(item) + '.png')"/>
                                </td>
                                <td>{{getLastSysLogText(item.lastSysLogTime)}}</td>
                                <td>
                                    {{getStatusString(item.status)}}<br>
                                    <i class="fas fa-bolt" style="font-size: 12px;"></i><span style="margin: 0 3px;">{{item.battery}}%</span><span v-if="item.battery <= 20" style="color: yellow;"><i class="fas fa-exclamation-circle"></i></span>
                                </td>
                                <td><span><i class="status-check fas " v-bind:class="{'fa-check': item.inside == true, 'fa-times': item.inside==false}"></i></span></td>
                                <td><span><i class="status-check fas " v-bind:class="{'fa-check': item.insideParkingArea == true, 'fa-times': item.insideParkingArea==false}"></i></span></td>
                                <td>{{getDayString(item.imeiExpiry)}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </template>
            <template v-slot:detail-item>
                <Editor
                    v-bind:item="detailItem"

                    v-on:onClose="onClickAsset"
                    v-on:updateList="updateListView"
                    v-on:sendMessage="sendMessage"

                    :is-add-mode="detailItem.id === 0"
                    :model-key="modelKey"/>
            </template>
        </MapListLayout>
<!--        <v-dialog max-width="400px" persistent/>-->
    </div>
</template>

<script>

    import {
        getAssetById,
        getAssets,
        newAsset,
        getBikeStatusString,
        getSysLogsByAssetId,
        getBikeExcel, getAssetsUrl, searchBikes, sendCommandToBike, getBikePendingHireSession
    } from "../../api/asset";

    import DropdownInput from "../../inputs/DropdownInput";

    import MapListLayout from "../MapListLayout";
    import Editor from "../../components/contents/asset/Editor";
    import Item from "../../components/contents/asset/Item";
    import {getOperatorList, setTagsByOperatorId} from "@/api/operator";
    import {getOperatorId, isSuperAdmin} from "../../store";
    import {includesTag, isOk, reorderItems, showDialog} from "@/api/common";
    import SortAndSearchInput from "../../inputs/SortAndSearchInput";

    import {PulseLoader} from 'vue-spinner/dist/vue-spinner.js'
    import Paho from "@/assets/js/paho-mqtt";

    import {millisToDate, millisToReadable} from "../../utils/TimeConverter";
    import EventBus from "../../api/event-bus";

    export default {
        name: "AssetView",
        components: {
            SortAndSearchInput,
            MapListLayout,
            DropdownInput,
            Item,
            Editor,

            PulseLoader
        },
        data() {
            return {
                mqtt:null,
                mqttConnected:false,
                mqttList:[],

                modelKey:'bike',
                isSelectedIndex: -1,
                itemsToShow: 10,

                operators:[],
                selectedOperatorId: getOperatorId(),
                superAdmin:false,

                assets: [],
                assetsHasRecentLogMap: {}, // ex) key: asset.id, value: Boolean
                detailItem: null,
                // isLoaded: false,

                sortTypeList: [
                    {name: "NAME", value: 'name', order: 'asc'},
                    {name: "OPERATOR", value: 'operatorId', order: 'asc'},
                    {name: "STATUS", value: 'status', order: 'desc'},
                    {name: "마지막 Sys 로그", value: 'lastSysLogTime', order:'asc'},
                    // {name: "CREATED_TIME", value: 'createdWhen', order: 'desc'},
                    {name: "SHORT_CODE", value: 'code', order: 'asc'},
                    {name: "FULL_CODE", value: 'fullCode', order: 'asc'}
                ],
                currentSortType: {},

                mapCenter: {
                    lat: 36.5, lng:127.8
                },
                mapZoom: 7,
                markers: [],

                isEditMode: false,
                isAddMode: false,
                disconnectMqtt: false,

                bikeType: 'all',
                searchWord:'',
                searchListEnabled: false,

            }
        },
        async created() {
            this.$data.superAdmin = isSuperAdmin();
            this.currentSortType = this.sortTypeList[0];
            this.operators = await getOperatorList();
            this.selectedOperatorId = getOperatorId();
            this.updateListView();
            this.updateEditorView();
            // this.connect();
            setTimeout(this.refreshAssets, 5000);

            console.log("operatorId " + this.selectedOperatorId + " is super admin " + isSuperAdmin());
        },
        beforeDestroy() {
            console.log("beforeDestroy");
            this.$data.disconnectMqtt = true;
            this.disconnect();
        },
        // watch: {
        //     $route() {
        //         this.render();
        //     },
        // },
        methods: {
            // render: function() {
            //     // this.$data.title = this.$i18n.t('board.' + this.$route.params.category);
            //
            //     /**
            //      * If category changed, remove all items to refresh
            //      */
            //     this.$data.detailItem = null;
            //
            //     this.updateListView();
            //     this.updateEditorView();
            // },
            onOperatorSelected: function() {
                let operatorId = this.selectedOperatorId;


                this.updateListView(function (e) {
                    if(!isOk(e))
                        return false;

                    if(operatorId === 0)
                        return true;

                    return operatorId === e.operatorId;
                })
            },
            updateBikeRecentLog: function() {

                let that = this;
                this.assetsHasRecentLogMap = {};

                return;
                this.assets.forEach(function(e) {

                    // 최근 로그 정보 (추후, getAssets API 에서 최신로그가 있는지 여부를 한번에 주는것이 좋을것같음)
                    getSysLogsByAssetId(e.id)
                        .then(data => {

                            let hasRecentLog = false;
                            let currentTime = new Date().getTime();
                            // console.log(new Date((currentTime-(1000*60*60*24*31))));

                            if (0 < data.list.length) {
                                let latestLog = data.list[0];
                                // let currentTime = new Date().getTime();
                                // 24시간이내 로그인지 체크
                                if ( (currentTime-(1000*60*60*24)) < latestLog.createdWhen ) {
                                    hasRecentLog = true;
                                }
                                that.assetsHasRecentLogMap[e.id] = hasRecentLog;
                            }

                        });

                });

            },
            onBikeTypeChanged: function() {
                this.updateListView();
            },
            getBikeQueryUrl: function() {
                let url = false;
                console.log("getbike query url " + this.bikeType);
                if(this.searchListEnabled && this.searchWord.length > 0) {
                    return "/assets/search?word=" + this.searchWord + "&operatorId=" + this.selectedOperatorId;
                }
                if(this.bikeType === 'all') {
                    url = "/assets?type=bike&operatorId=" + this.selectedOperatorId;
                } else if(this.bikeType === 'lowBattery') {
                    url = "/bikes/lowBattery?operatorId=" + this.selectedOperatorId;
                } else if(this.bikeType === 'noSysLog') {
                    url = "/bikes/warning?operatorId=" + this.selectedOperatorId;
                } else if(this.bikeType === "available") {
                    url = "/bikes/status?status=1";
                } else if(this.bikeType === "hired") {
                    url = "/bikes/status?status=2";
                } else if(this.bikeType === "charging") {
                    url = "/bikes/status?status=3";
                } else if(this.bikeType === "repair") {
                    url = "/bikes/status?status=4";
                } else if(this.bikeType === "warehouse") {
                    url = "/bikes/status?status=5";
                } else if(this.bikeType === "moving") {
                    url = "/bikes/status?status=6";
                }  else if(this.bikeType === "created") {
                    url = "/bikes/status?status=0";
                } else if(this.bikeType === "noResponse") {
                    url = "/bikes/notified?operatorId=" + this.selectedOperatorId;
                }
                return url;
            },
            updateListView: function(check = isOk) {
                // this.isLoaded = false;
                this.assets = [];
                this.markers = [];
                if(this.selectedOperatorId === 0) {
                    return;
                }


                let that = this;

                let url = this.getBikeQueryUrl();
                if(url == false) {
                    return;
                }

                getAssetsUrl(url).then(function (assets) {
                    let sumLat = 0;
                    let sumLng = 0;
                    assets.forEach(function (e) {
                        if (that.selectedOperatorId != 0 && that.selectedOperatorId != e.operatorId) {
                            return;
                        }
                        if (check(e)) {
                            sumLat += e.lat;
                            sumLng += e.lon;

                            that.markers.push({
                                id: e.id,
                                position: {
                                    lat: parseFloat(e.lat),
                                    lng: parseFloat(e.lon)
                                },
                                name: e.name,
                                battery: e.battery,
                                status: e.status,
                                daysToNextService: e.daysToNextService,
                            });

                            that.assets.push(e);
                        }
                    });

                    that.assets = reorderItems(that.assets, that.currentSortType)
                    // that.updateBikeRecentLog();

                    // that.isLoaded = true;
                })

            },
            cancelSearch: function() {
                this.searchListEnabled = false;
                this.updateListView();
            },
            searchBikes: function() {

                this.searchListEnabled = true;
                this.assets = [];
                this.markers = [];
                let that = this;
                searchBikes(this.$data.searchWord, this.$data.selectedOperatorId)
                .then(function(data) {
                    console.log(data);
                    if(data.success == true) {
                        let sumLat = 0;
                        let sumLng = 0;
                        for(let i = 0 ; i < data.item.length ; i++) {
                            if (that.selectedOperatorId != 0 && that.selectedOperatorId != data.item[i].operatorId) {
                                return;
                            }
                            sumLat += data.item[i].lat;
                            sumLng += data.item[i].lon;

                            that.markers.push({
                                id: data.item[i].id,
                                position: {
                                    lat: parseFloat(data.item[i].lat),
                                    lng: parseFloat(data.item[i].lon)
                                },
                                name: data.item[i].name,
                                battery: data.item[i].battery,
                                status: data.item[i].status,
                                daysToNextService: data.item[i].daysToNextService,
                            });

                            that.assets.push(data.item[i]);

                        }

                        that.assets = reorderItems(that.assets, that.currentSortType)
                        console.log(that.assets);
                        that.updateBikeRecentLog();
                    } else {
                        showDialog(data.message);
                        // window.alert(data.message);
                    }
                })
                .catch(function(err) {

                })
            },
            refreshAssets: function() {
                if(this.$data.disconnectMqtt == true) {
                    return;
                }
                let that = this;
                let url = this.getBikeQueryUrl();
                if(url == false) {
                    return;
                }
                // console.log("refresh Assets");
                getAssets("bike", this.$data.selectedOperatorId).then(function(assets){
                    let sumLat = 0;
                    let sumLng = 0;

                    assets.forEach(function (e) {
                        // if(check(e)) {
                            sumLat += e.lat;
                            sumLng += e.lon;

                            let foundFromMarkers = false;
                            for(let i = 0 ; i < that.markers.length ; i++) {
                                if(that.markers[i].id == e.id) {
                                    that.markers.splice(i, 1, {
                                        id: e.id,
                                        position: {
                                            lat: parseFloat(e.lat),
                                            lng: parseFloat(e.lon)
                                        },
                                        name:e.name,
                                        battery:e.battery,
                                        status:e.status,
                                        daysToNextService:e.daysToNextService,
                                    });
                                    foundFromMarkers = true;
                                    break;
                                }
                            }
                            // if(foundFromMarkers == false) {
                            //     that.markers.push({
                            //         id: e.id,
                            //         position: {
                            //             lat: parseFloat(e.lat),
                            //             lng: parseFloat(e.lon)
                            //         },
                            //         name:e.name,
                            //         battery:e.battery,
                            //         status:e.status
                            //     });
                            // }



                            let foundFromAssets = false;
                            for(let i = 0 ; i < that.assets.length ; i++) {
                                if(that.assets[i].id == e.id) {
                                    foundFromAssets = true;
                                    let statusChanged = false;
                                    if(that.assets[i].onOff != e.onOff || that.assets[i].status != e.status || that.assets[i].lastSysLogTime != e.lastSysLogTime) {
                                        statusChanged = true;
                                    }
                                    that.assets.splice(i, 1, e);
                                    if(statusChanged) {
                                        $("#asset-" + e.id).addClass('updated-row');
                                        setTimeout(function () {
                                            $("#asset-" + e.id).removeClass('updated-row');
                                        }, 5000)
                                    }
                                    break;
                                }
                            }
                            // if(foundFromAssets == false) {
                            //     that.assets.push(e);
                            // }
                        // }
                    });

                    that.assets = reorderItems(that.assets, that.currentSortType)

                    if(that.$data.disconnectMqtt == false) {
                        setTimeout(that.refreshAssets, 10000);
                    }
                    // that.assets = reorderItems(that.assets, that.currentSortType)

                    // that.isLoaded = true;
                })
            },
            updateEditorView: function() {
            //     this.isEditMode = this.$route.query.hasOwnProperty('edit');
            //     this.isAddMode = this.$route.query.hasOwnProperty('new')
            //
                if (this.$route.query.hasOwnProperty('id')) {
                    let contentId = this.$route.query.id;

                    let that = this;
                    getAssetById(contentId).then(data => {
                        if(data.success == true) {
                            if (isOk(data.item)) that.detailItem = data.item;
                        }
                    });
            //     } else if (this.isAddMode) {
            //         let that = this
            //
            //         that.detailItem = newAsset(this.modelKey);
            //         setTagsByOperatorId(getOperatorId(), that.detailItem);
            //
            //         this.isEditMode = true
            //     } else {
            //         this.isEditMode = false
                }
            },
            onRequestMoreList: function() {
                this.itemsToShow += 10;
            },
            onClickSortType: function(selectedOption) {
                this.currentSortType = selectedOption;

                this.assets = reorderItems(this.assets, this.currentSortType)
            },
            onClickAddBtn: function() {
                this.detailItem = newAsset(this.modelKey);
                setTagsByOperatorId(getOperatorId(), this.detailItem);

                this.$router.push(`/admin/bike?new`);
            },
            onMapAssetClick: function(marker) {
                for(let i = 0 ; i < this.assets.length ; i++) {
                    if(this.assets[i].id == marker.id) {
                        this.onClickAsset(this.assets[i]);
                        this.$refs.gmap.$mapPromise.then((map) => {
                            map.panTo(marker.position);
                            //map.fitBounds(bounds);
                        });
                        return;
                    }
                }
            },
            onClickAsset: function(item) {

                if(item) {
                    let bikeId = item.id;
                    this.detailItem = null;
                    let self = this;
                    getAssetById(bikeId)
                        .then(function (data) {

                            if (data.success == true) {
                                self.detailItem = data.item;
                                let path = `/admin/bike`;
                                path += `?id=${item.id}`;
                                for (let i = 0; i < self.markers.length; i++) {
                                    if (self.markers[i].id === item.id) {
                                        let marker = self.markers[i];
                                        self.$refs.gmap.$mapPromise.then((map) => {
                                            map.setZoom(17);
                                            map.panTo(marker.position);
                                            //map.fitBounds(bounds);
                                        });
                                        break;
                                    }
                                }
                                if (self.$route.fullPath !== path) {
                                    self.$router.push(path);
                                }
                            } else {
                                window.alert(data.message);
                            }
                        })
                        .catch(function (err) {
                            let msg = err;
                            if (err.response != undefined) {
                                msg += ' (' + err.response + ')';
                            }
                            window.alert(msg);
                        })
                } else {
                    this.detailItem = null;
                    let path = `/admin/bike`;
                    if (this.$route.fullPath !== path) {
                        this.$router.push(path);
                    }
                }
                // let path = `/admin/bike`;
                // if(item) {
                //     path += `?id=${item.id}`;
                //     for(let i = 0 ; i < this.markers.length ; i++) {
                //         if (this.markers[i].id === item.id) {
                //             let marker = this.markers[i];
                //             this.$refs.gmap.$mapPromise.then((map) => {
                //                 map.setZoom(17);
                //                 map.panTo(marker.position);
                //                 //map.fitBounds(bounds);
                //             });
                //             break;
                //         }
                //     }
                // }


            },
            // openDetailInfo: function (marker, idx) {
            //     Router.push(`/admin/bike?id=${marker.id}`);
            // },
            onSearchTags(value) {
                this.updateListView(function (e) {
                    return isOk(e) && includesTag(value, e.tags);
                });
            },
            askTurnOff: function(item) {
              // Check if there is pending order

                let self = this;
                getBikePendingHireSession(item.id)
                .then(function(data) {
                    if(data.success === false) {
                        self.$modal.show('dialog', {
                            title: '',
                            text: "자전거 끄기를 진행하시겠습니까?",
                            buttons: [
                                {
                                    title: '취소',
                                    handler: () => {
                                        self.$modal.hide('dialog');
                                        // self.turnOff(item);
                                    }
                                },
                                {
                                    title: '끄기 진행',
                                    handler: () => {
                                        self.$modal.hide('dialog');
                                        self.turnOff(item);
                                    }
                                },
                            ]
                        });
                    } else {
                        showDialog("현재 진행중인 대여가 있습니다. 취소를 위해서는 리스트에서 상세보기를 열고 진행해주세요");
                    }
                })
                .catch(function(err) {

                })
                // If no hire session found, show dialog to turn off

            },
            turnOff: function(item) {
                let self = this;
                EventBus.$emit("showLoading", true);
                sendCommandToBike(item.fullCode, 'lock')
                .then(function(data) {
                    console.log("sendCommandToBike");
                    console.log(data);
                    if(data.success) {
                        console.log("command sent")
                        self.retrieveBikeOnOff(item);
                    } else {
                        EventBus.$emit("showLoading", false);
                        console.log("failed to send command " + data.message);
                    }
                })
                .catch(function(err) {
                    EventBus.$emit("showLoading", false);
                    console.log("Error sedning command " + err);
                    if(err.response != undefined) {
                        console.log(err.response);
                    }
                })
            },
            retrieveBikeOnOff: function(item, count = 30) {
                let self = this;
                if(count <= 0) {
                    EventBus.$emit("showLoading", false);
                    showDialog("자전거로부터 꺼짐 응답 (4번 메세지)를 받지 못했습니다.");
                    return;
                }
                getAssetById(item.id)
                .then(function(data) {
                    if (data.success == true) {
                        if(data.item.onOff == false) {
                            EventBus.$emit("showLoading", false);
                            for(let i = 0 ; i < self.markers.length ; i++) {
                                if(self.markers[i].id === data.item.id) {
                                    let marker = {
                                        id: data.item.id,
                                        position: {
                                            lat: parseFloat(data.item.lat),
                                            lng: parseFloat(data.item.lon)
                                        },
                                        name: data.item.name,
                                        battery: data.item.battery,
                                        status: data.item.status
                                    };
                                    self.markers.splice(i, 1, marker);
                                    break;
                                }
                            }
                            for(let i = 0 ; i < self.assets.length ; i++) {
                                if(self.assets[i].id == data.item.id) {
                                    self.assets.splice(i, 1, data.item);
                                    $("#asset-" + data.item.id).addClass('updated-row');
                                    setTimeout(function () {
                                        $("#asset-" + data.item.id).removeClass('updated-row');
                                    }, 5000)
                                    break;
                                }
                            }
                            EventBus.$emit("showLoading", false);

                        } else {
                            setTimeout(self.retrieveBikeOnOff, 1000, item, count--);
                        }
                    } else {
                        EventBus.$emit("showLoading", false);
                    }
                })
                .catch(function(err) {
                    EventBus.$emit("showLoading", false);
                })
            },
            getBikeIcon: function(item) {
                // console.log("bike icon " + item.status)
                if(item.lastSysLogTime == 0) {
                    return 'no-log';
                }
                // if(item.emailSent == true) {
                //     return 'no-response';
                // }
                let serviceIcon = '';
                if(item.daysToNextService <= 0) {
                    serviceIcon = '-service-warning';
                } else if(item.daysToNextService <= 7) {
                    serviceIcon = '-service-7';
                } else if(item.daysToNextService <= 15) {
                    serviceIcon = '-service-15';
                }
                if(item.status == 2 && item.battery <=20) {
                    return 'hiring-low-battery' + serviceIcon;
                }
                if(item.battery <= 30) {
                    return 'low-battery' + serviceIcon;
                }
                if(item.status == 2) {
                    return'hiring' + serviceIcon;
                } else if(item.status == 3) {
                    return 'charging' + serviceIcon;
                } else if(item.status == 4) {
                    return 'repairing';
                } else if(item.status == 5) {
                    return 'warehouse' + serviceIcon;
                } else if(item.status == 6) {
                    return 'moving' + serviceIcon
                }

                return 'available' + serviceIcon;
            },



            /**
             * MQTT Methods
             *
             */

            /**
             * When message arrived from MQTT.
             * Should receive bike's asset record sent from Spring server after update from Bike via MQTT
             */
            onMessageArrived: function (msg) {
                console.log("message arrived from " + msg.destinationName);

                let msgStr = msg.payloadString;
                console.log(msgStr);
                let item = JSON.parse(msgStr);

                if(this.selectedOperatorId > 0 && item.operatorId != this.selectedOperatorId) {
                    return;
                }
                /**
                 * Now find bike from markers and update status and location
                 */
                let markerFound = false;
                for(let i = 0 ; i < this.$data.markers.length ; i++) {
                    if(this.$data.markers[i].id == item.id) {
                        this.$data.markers[i].position.lat = item.lat;
                        this.$data.markers[i].position.lng = item.lon;
                        this.$data.markers[i].battery = item.battery;
                        this.$data.markers[i].status = item.status;
                        markerFound = true;
                        break;
                    }
                }

                /**
                 * If bike received is not on current list, add
                 * Check user's operator Id
                 *
                 */
                if(markerFound == false) {
                    this.$data.markers.push({
                        id: item.id,
                        position: {
                            lat: item.lat,
                            lng: item.lon
                        },
                        battery: item.battery,
                        status: item.status
                    });
                }

                let assetFound = false;

                for(let i = 0 ; i < this.$data.assets.length ; i++) {
                    if(this.$data.assets[i].id == item.id) {
                        this.$data.assets.splice(i, 1, item);
                        assetFound = true;
                        break;
                    }
                }
                if(assetFound == false) {
                    this.$data.assets.push(item);
                }


                $("#asset-" + item.id).addClass('updated-row');
                setTimeout(function(){
                    $("#asset-" + item.id).removeClass('updated-row');
                }, 5000)


                /**
                 * Update detail item view as well
                 */
                if(this.detailItem != null && this.detailItem.id == item.id) {
                    console.log("Detail ITem Bike");
                    this.detailItem.lat = item.lat;
                    this.detailItem.lon = item.lon;
                    this.detailItem.battery = item.battery;
                    this.detailItem.status = item.status;
                }

            },
            subscribeChannel: function() {
                //Should be changed according to current user
                this.$data.mqtt.subscribe('maryBikes/#');
            },

            sendMessage: function(channel, string) {
                if(this.$data.mqtt != null && this.$data.mqtt.isConnected()) {
                    console.log("Send to " + channel);
                    let message = new Paho.Message(string);
                    message.destinationName = channel;
                    this.$data.mqtt.send(message);
                } else {
                    console.log("mqtt is not connected");
                }
            },

            /**
             * Callback when MQTT is connected
             * Now should subscribe maryBikes/(operatorId)
             */
            onConnect: function () {
                console.log("Connected subscribe maryapp");
                this.$data.mqttConnected = true;
                this.subscribeChannel();
            },


            /**
             * When conneciton is lost, should reconnect manually.
             * Reconnect option does not really reconnect when switching from WIFI to mobile network
             */
            onConnectionLost: function(resObj) {
                console.log("connection lost");
                // this.$data.mqttConnected = false;
                // this.$data.mqtt = null;
                console.log("Lost connection to " + resObj.uri + "\nError code: " + resObj.errorCode + "\nError text: " + resObj.errorMessage);
                console.log("Now try again");
                this.$data.mqttConnected = false;
                if(this.$data.disconnectMqtt == false) {
                    setTimeout(this.connect, 1000);
                }
            },

            /**
             * Callback when MQTT connection failed
             * @param message
             */
            onMqttFailure: function (message) {
                console.log("MQTT failed " + message);
                console.log(message);
                console.log("Attempt to reconnect in 1 second");

                this.$data.mqttConnected = false;
                setTimeout(this.connect, 1000);
            },
            disconnect: function() {
                if(this.$data.mqtt == null || this.$data.mqtt.isConnected() == false) {
                    return;
                }
                this.$data.mqtt.disconnect();
            },
            connect: function () {
                if(this.$data.mqtt == null || this.$data.mqtt.isConnected() == false) {

                    console.log("MQTT is not connected so attempt to connect now");
                    this.$data.mqtt = new Paho.Client("mqtt.flowz.co", Number(8081), 'maryApp-' + new Date().getTime());
                    // this.$data.mqtt.disconnect();
                    this.$data.mqtt.onConnectionLost = this.onConnectionLost;
                    let options = {
                        timeout: 3,
                        useSSL: true,
                        userName: "maryapp",
                        password: "app23344",
                        onSuccess: this.onConnect,
                        onFailure: this.onMqttFailure,
                        cleanSession: true, //Clean session
                        // reconnect : true, // Enable automatic reconnect
                        // reconnectInterval:1, //Reconnect attempt interval : 1 second
                    };
                    this.$data.mqtt.connect(options); //connect
                } else {
                    console.log("Already connected");
                    this.$data.mqttConnected = true;
                }
                if(this.$data.mqtt.onMessageArrived === null || this.$data.mqtt.onMessageArrived !== this.onMessageArrived) {
                    this.$data.mqtt.onMessageArrived = this.onMessageArrived;
                } else {
                    console.log("message arrive handler Already registered");
                }

            },
            getStatusString:getBikeStatusString,
            getDayString:millisToDate,
            getDateTimeString:millisToReadable,
            getLastSysLogText: function(time) {
                if(time == 0) {
                    return 'Never';
                }
                let currentTime = new Date().getTime();
                let diff = currentTime - time;
                if(diff < 60000) {
                    let sec = diff/1000;
                    return Math.round(sec) + '초전';
                }
                let min = diff/60000;
                return Math.round(min) + '분전';// (' + millisToReadable(time) + ')';
            },
            onClickExcelDownloadBtn: function() {
                const FileDownload = require('js-file-download');
                let self = this;
                getBikeExcel(this.$data.selectedOperatorId)
                    .then(function(response) {
                        FileDownload(response.data, "bikeList.xlsx");
                    })
            },
        },
    }
</script>
<style lang="scss" scoped>
    .thumbnail {
        width:50px;
        height: 50px;
        background-position: center;
        background-size: contain;
    }
    .updated-row {
        background: #FFCCCC;
        /*color: white;*/
    }
    .status-check.fa-check {
        color:blue;
    }
    .status-check.fa-times {
        color:red;
        font-weight: bold;
    }
    .updated-row .status-check.fa-times {
        /*color:white;*/
    }
    .color-info-box {
        width: 15px;
        height: 15px;
    }
    .empty-log {
        background: #fff;
    }
    .low-battery-item {
        background: #CCC;
    }
    .warning-item {
        background: red;
        color: white;
    }
    .no-response {
        background-color: #8002c9;
        color:white;
    }
    .sticky-header-row > th {
        position: sticky;
        top:0;
        background-color: white;
    }
</style>
