<template>
    <div class="list-detail-view">
        <DetailLayout
            v-bind:is-available-more-btn="itemsToShow < couponList.length"
            :on-detail="detailItem != null"
            v-on:onRequestMoreList="onRequestMoreList">

            <template v-slot:list-title-text>
                {{$t('menu.commerce.list.'+modelKey)}} 관리
            </template>
            <!--      <template v-slot:graph-container>-->
            <!--        <div class="option-container">-->
            <!--          <select>-->
            <!--            <option value="7">최근7일</option>-->
            <!--            <option value="30">최근30일</option>-->
            <!--            <option value="90">최근90일</option>-->
            <!--          </select>-->
            <!--        </div>-->
            <!--        <GChart-->
            <!--          class="chart-view"-->
            <!--          type="ColumnChart"-->
            <!--          :data="stockGraphList"-->
            <!--          :options="chartOptions"-->
            <!--        />-->
            <!--      </template>-->
            <template v-slot:top-items>
                <div class="list-top-btns">
                    <div class="add-item" v-on:click="onClickAddBtn()">
                        <span style="margin-right: 5px;">{{$t('list.add')}}</span>
                        <span class="fas fa-plus"/>
                    </div>
                </div>
                <div class="sort-btn-wrapper">
                    <sort-and-search-input
                        label-search="사용자"
                        :sort-type-list="sortTypeList"

                        v-on:onEnterSearch="onSearchUserInfo"
                        v-on:onClickSortType="onClickSortType"/>
                </div>

            </template>
            <template v-slot:list-items>
                <pulse-loader color="#FFC0CB" :loading="!isLoaded"/>
                <div v-if="isLoaded">
                    <div v-if="couponList.length === 0" class="empty-list">
                        <p>{{$t('list.has_no_material')}}</p>
                    </div>
                    <div v-else>
                        <template v-for="(item, index) in couponList">
                            <Item v-if="index < itemsToShow"
                                  v-bind:content="item"
                                  v-on:onClick="onClickItem" />
                        </template>
                    </div>
                </div>
            </template>
            <template v-slot:detail-item>
                <Editor
                    v-bind:detail-item="detailItem"
                    :user-info="userInfo"

                    v-on:onClose="onClickItem"
                    v-on:updateList="updateListView"

                    :items="couponList"
                    :is-add-mode="isAddMode"/>
            </template>
        </DetailLayout>
    </div>
</template>

<script>

    const lodash = require('lodash'); // order by 위해 사용

    import Item from "../../components/contents/coupon/Item";
    import DropdownInput from "../../inputs/DropdownInput";
    import DetailLayout from "../DetailLayout";
    import Editor from "../../components/contents/coupon/Editor";
    import {getCreditById, getCredits, newCredit} from "../../api/credit";
    import {includesTag, isOk, reorderItems} from "../../api/common";
    import {getUserById} from "../../api/user";
    import {clone} from "../../utils/VarToString";
    import SortAndSearchInput from "../../inputs/SortAndSearchInput";

    import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

    export default {
        name: "AssetView",
        components: {
            SortAndSearchInput,
            DetailLayout,
            DropdownInput,
            Item,
            Editor,

            PulseLoader
        },
        data() {
            return {
                modelKey: "coupon",
                itemsToShow: 50,

                couponList: [],
                detailItem: null,
                isLoaded: false,

                sortTypeList: [
                    { name: "CREATED_TIME",  value: 'createdWhen',  order: 'desc'},
                    { name: "USER_INFO",  value: 'userInfo',  order: 'asc'}
                ],
                currentSortType: {},

                userInfo: "없음",
                userInfoList: [],

                isEditMode: false,
                isAddMode: false
            }
        },
        created() {
            this.currentSortType = this.sortTypeList[0];

            this.updateListView();
            this.updateEditorView();
        },
        watch: {
            $route(){
                this.updateEditorView();
            }
        },
        methods: {
            updateListView: function(check = isOk) {
                this.couponList = [];

                this.isAddMode = false;
                this.isLoaded = false;

                let that = this;
                getCredits().then(function(items){
                    for(let item of items) {
                        if (item.userInfo == null)
                            item.userInfo = "없음";

                        if (check(item))
                            that.couponList.push(item);
                    }

                    that.couponList = reorderItems(that.couponList, that.currentSortType);
                });

                this.isLoaded = true;
            },
            updateEditorView: async function() {
                let that = this;
                if (this.$route.query.hasOwnProperty('id')) {
                    let contentId = this.$route.query.id;

                    getCreditById(contentId).then(item => {
                        if(isOk(item)) that.detailItem = item;
                    });
                } else if (this.$route.query.hasOwnProperty('userId')) {
                    this.isAddMode = true;

                    let userId = JSON.parse(this.$route.query.userId);

                    let item = newCredit();
                    item.userId = userId;
                    item.userInfo = await this.getUserInfo(userId);

                    this.detailItem = item;
                }
            },
            getUserInfo: async function(userId){
                let ret = "없음";

                if(userId !== 0 && userId !== null){
                    await getUserById(userId).then(u => {
                        ret = u.phoneNumber;
                    });
                }

                return ret;
            },
            onRequestMoreList: function() {
                this.itemsToShow = this.itemsToShow + 5;
            },
            onClickSortType: function(selectedOption = null) {
                this.currentSortType = selectedOption;

                this.couponList = reorderItems(this.couponList, this.currentSortType)
            },
            onClickAddBtn: function() {
                this.isAddMode = true;

                this.detailItem = newCredit();

                let path = `/admin/coupon`;
                if(this.$route.fullPath !== path) {
                    this.$router.push(path);
                }
            },
            onClickItem: function(item) {
                this.isAddMode = false;

                this.detailItem = clone(item);

                let path = `/admin/coupon`;
                if(item) {
                    path += `?id=${item.id}`;
                }

                if(this.$route.fullPath !== path) {
                    this.$router.push(path);
                }
            },

            onSearchUserInfo(value) {
                this.updateListView(e => {
                    return isOk(e) && e.userInfo.includes(value);
                });
            }
        },
    }
</script>

<style lang="scss" scoped>

  .list-detail-view {
    height: 100%;
  }
  .tag-search-wrapper {
    margin-top: 15px;
  }

  .tag-search {
    width: 100%;
    border: 1px solid #ddd;
    border-radius: 3px;
    font-size: 14px;
    padding: 5px 10px;
  }
  .chart-view {
    width: 100%;
    height: 300px;
  }
  .option-container {
    width: 100%;
    select {
      background: #FFFFFF;
      border: 1px solid #000000;
      box-sizing: border-box;
    }
  }

</style>
