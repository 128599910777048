<template>
    <div class="list-item" v-on:click="onClickList()" v-bind:class="{ selected: isSelected, 'area-main': item.onOff == true }">

        <div v-if="!item.deleted">
            <div class="thumbnail-wrapper">
                <div class="thumbnail-holder">
                </div>
            </div>

            <div class="content-info">
                <div class="title">사업자: {{item.operatorTitle}}</div>
                <div class="title">{{item.name}} ({{getServiceableString()}})</div>
                <div class="position">{{item.lat}} {{item.lon}}</div>
            </div>
        </div>
        <div class="content-info" v-else>
            <div class="timestamp">{{$t('detail.deleted')}}</div>
        </div>

    </div>
</template>

<script>
    export default {
        name: "ListAsset",
        props: {
            item: {
                type: Object,
                default: function() { return{} }
            }
        },
        data() {
            return {
                isSelected: false,
            }
        },
        created() {
            this.updateIsSelected();
        },
        watch: {
            '$route'() {
                this.updateIsSelected();
            }
        },
        methods: {
            onClickList: function() {
                this.$emit('onClick', this.item);
            },
            updateIsSelected: function() {
                var isSelected = false;
                var hasIdQuery = this.$route.query.hasOwnProperty('id');
                if (hasIdQuery) {
                    var contentId = this.$route.query.id;
                    if (contentId == this.item.id) {
                        isSelected = true;
                    }
                }

                this.isSelected = isSelected;
            },
            getServiceableString: function() {
                if(this.item.onOff == true) {
                    return '서비스 지역';
                } else {
                    return '서비스 불가 지역';
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
  .list-item {
    display: table;
    width: 100%;
    margin-top: 15px;
    background-color: #FFF;
    padding: 15px;
    border: 1px solid #EEE;
    box-shadow: 2px 3px 4px -1px rgba(0,0,0,0.3);
    cursor: pointer;

    .thumbnail-wrapper {
      display: table-cell;
      vertical-align: middle;
      padding-right: 15px;

      .thumbnail-holder {
        width: 50px;
        height: 50px;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        border: 1px solid #ddd;
        border-radius: 2px;
      }
    }

    .content-info {
      display: table-cell;
      width: 100%;
      vertical-align: middle;

      .timestamp {
        font-size: 12px;
        color: #999;
        /*text-align: right;*/
      }
      .title {
        font-size: 18px;
        font-weight: 500;
        margin-top: 10px;
      }

      .channel-number {
        vertical-align: middle;
        font-size: 14px;
        color: #666;
        margin-bottom: 10px;
      }

      .access-levels {
        font-size: 12px;
        color: #666;
        margin-top: 5px;

        .read-level, .write-level {
          display: inline-block;
        }
        .read-level {
          margin-right: 5px;
          padding-right: 10px;
          border-right: 1px solid #ddd;
        }
        .access-label {
          margin-right: 5px;
        }
      }
    }

    /*
    .channel-number {
        display: table-cell;
        width: 120px;
    }
    */


  }

  .list-item:hover {
    box-shadow: 3px 4px 6px -1px rgba(0,0,0,0.4);
  }
  .on-detail-view .list-item.selected {
    background-color: #2B90D9;
    border-color: #1982ce;
    box-shadow: 3px 4px 6px -1px rgba(0,0,0,0.4);
    .content-info {
      .title,
      .channel-number {
        color: #fefefe;
      }
      .timestamp,
      .access-levels {
        color: #EEE;
      }
    }
  }
    .area-main {
        background-color: #42b983;
    }
</style>
