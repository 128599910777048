<template>
    <div class="list-detail-view">
        <WideDetailLayout
            v-bind:is-available-more-btn="false"
            v-on:onRequestMoreList="onRequestMoreList"
            :on-detail="selectedBoundary != null">

            <template v-slot:list-title-text>
                {{$t('menu.bike.list.'+modelKey)}} 관리
            </template>
            <template v-slot:map-container>
                <gmap-map
                    ref="gmap"
                    :center="mapCenter"
                    :zoom="mapZoom"
                    :options="{
                        streetViewControl:false,
                        fullscreenControl:false,
                        mapTypeControl:false
                    }"
                    class="google-map"
                    style="width: 100%; height: 100%;">
                    <gmap-marker
                        :key="index"
                        v-for="(m, index) in markers"
                        :position="m.position">
                    </gmap-marker>
                </gmap-map>
            </template>
            <template v-slot:top-items>
                <div class="list-top-btns">
                    <div class="add-item" v-on:click="onClickAddBtn()">
                        <span style="margin-right: 5px;">{{$t('list.add')}}</span>
                        <span class="fas fa-plus"/>
                    </div>
                </div>
                <div v-if="operators.length > 1">
                    <label>{{$t('operator.select')}}</label>
                    <select class="form-control" v-model="selectedOperatorId" @change="onOperatorSelected">
                        <option v-for="o in operators" :value="o.value">{{o.name}}</option>
                    </select>
                </div>
<!--                <div class="sort-btn-wrapper">-->
<!--                    <sort-and-search-input v-on:onEnterSearch="onSearchTags"/>-->
<!--                </div>-->
            </template>
            <template v-slot:list-items>
                <vue-good-table
                    mode="remote"
                    @on-sort-change="onClickSortType"
                    @on-row-click="onClickAsset"
                    :rows="parentAssets"
                    :columns="columns"/>
<!--                <div v-if="parentAssets.length === 0" class="empty-list">-->
<!--                    <p>{{$t('list.has_no_material')}}</p>-->
<!--                </div>-->
<!--                <div v-else>-->
<!--                    <template v-for="(item, index) in parentAssets">-->
<!--                        <Item v-if="index < itemsToShow"-->
<!--                              v-bind:item="item"-->
<!--                              v-on:onClick="onClickAsset" />-->
<!--                    </template>-->
<!--                </div>-->
            </template>
            <template v-slot:detail-item>
                <Editor
                    v-bind:points="selectedBoundary"
                    v-on:onChanged="updateListView()"

                    :is-add-mode="isAddMode"
                    :model-key="modelKey"/>
            </template>
        </WideDetailLayout>
        <v-dialog max-width="400px" persistent/>
    </div>
</template>

<script>
    // import DropdownInput from "../../inputs/DropdownInput";
    import WideDetailLayout from "../WideDetailLayout";

    import Item from "../../components/contents/area/Item";
    import Editor from "../../components/contents/area/Editor";
    import MapView from "../BoundaryMapView";

    import {getBoundary, getParentAssets, newBoundary} from "../../api/asset";
    import {getOperatorList, getOperators} from "../../api/operator";
    import {setTagsByOperatorId} from "../../api/operator";
    import {getOperatorId} from "../../store";
    import {changeURL, checkOperator, includesTag, isOk, reorderItems} from "../../api/common";
    import SortAndSearchInput from "../../inputs/SortAndSearchInput";

    export default {
        name: "AreaAssetView",
        components: {
            SortAndSearchInput,
            MapView,
            WideDetailLayout,
            // DropdownInput,
            Item,
            Editor
        },
        props: {
            modelKey: {
                type: String,
                default: ""
            }
        },
        data() {
            return {
                columns: [
                    {field: 'id', label: '#'},
                    {field: 'operatorTitle', label: '사업자'},
                    {field: 'onOff', label: '구분'},
                    {field: 'name', label: '이름'}
                ],

                assetType: "",
                // itemsToShow: 5,
                // sortTypeList: [
                //     {
                //         name: this.$t('list.sort_name'),
                //         value: 'title',
                //         order: 'asc'
                //     },
                //     {
                //         name: this.$t('list.sort_type'),
                //         value: 'type',
                //         order: 'asc'
                //     }
                // ],
                currentSortType: {
                    value: 'createdWhen',
                    order: 'desc'
                },

                isEditMode: false,
                isAddMode: false,

                parentAssets: [],
                selectedBoundary: null,

                mapCenter: {
                    lat: 36.5, lng:127.8
                },
                mapZoom: 7,
                markers: [],

                operators:[],
                selectedOperatorId: getOperatorId(),
            }
        },
        async created() {
            switch (this.modelKey) {
                case "parking":
                    this.assetType = "parking";
                    break;
                case "service":
                    this.assetType = "service_area";
                    break;
            }

            // this.currentSortType = this.sortTypeList[0];
            this.operators = await getOperatorList();
            //this.getOperatorList();
            // this.render();

            this.updateListView();
        },
        watch: {
            $route() {
                this.updateEditorView();
            },
        },
        methods: {
            // render: function() {
            //     // TODO: Need to Change Load Flow (NCM-3)
            //
            //     this.updateListView();
            //     this.updateEditorView();
            // },
            // getOperatorList: function() {
            //     let self = this;
            //     getOperators().then(function(operators){
            //         self.$data.operators = operators;
            //     })
            // },
            getOperatorName: function(id) {
                for(let i = 0 ; i < this.$data.operators.length ; i++) {
                    if(this.$data.operators[i].value === id) {
                        return this.$data.operators[i].name;
                    }
                }
                return 'Unknown (' + id +')';
            },
            onOperatorSelected: function() {
                let operatorId = this.$data.selectedOperatorId;

                console.log("Selected operator Id " + operatorId)
                this.updateListView(function (e) {
                    if(!isOk(e))
                        return false;

                    if(operatorId === 0)
                        return true;

                    return operatorId === e.operatorId;
                })
            },
            updateListView: function(check = isOk) {
                this.parentAssets = [];

                let that = this;
                getParentAssets(this.assetType, this.$data.selectedOperatorId).then(function(assets){
                    // console.log(assets);
                    for(let e of assets) {
                        // if (check(e)) {
                            let markerFound = false;
                            let newMarker = {
                                id: e.id,
                                position: {
                                    lat: parseFloat(e.lat),
                                    lng: parseFloat(e.lon)
                                },
                            };
                            for (let i = 0; i < that.markers.length; i++) {
                                if (that.markers[i].id === e.id) {
                                    markerFound = true;
                                    that.markers.splice(i, 1, newMarker);
                                }
                            }

                            if (!markerFound) {
                                that.markers.push(newMarker);
                            }

                            e.operatorTitle = that.getOperatorName(e.operatorId);
                            for (let i = 0; i < that.parentAssets.length; i++) {
                                if (that.parentAssets[i].id === e.id) {
                                    that.parentAssets.splice(i, 1, e);
                                    return;
                                }
                            }
                            e.onOff = e.onOff ? "서비스 지역" : "불가 지역";
                            that.parentAssets.push(e);
                        // }
                    }

                    that.parentAssets = reorderItems(that.parentAssets, that.currentSortType);
                })
            },
            updateEditorView: function() {
                this.selectedBoundary = null;

                // this.isEditMode = this.$route.query.hasOwnProperty('edit');
                this.isAddMode = this.$route.query.hasOwnProperty('new');

                let that = this;
                if (this.$route.query.hasOwnProperty('id')) {
                    getBoundary(this.$route.query.id).then(item => {
                        if(isOk(item[0])) that.selectedBoundary = item;
                    });
                } else if (this.isAddMode) {
                    let that = this;

                    that.selectedBoundary = newBoundary(that.assetType);
                    // console.log("add boundary");
                    // console.log(that.selectedBoundary);

                    setTagsByOperatorId(getOperatorId(), that.selectedBoundary[0]);

                    // this.isEditMode = true
                // } else {
                //     this.isEditMode = false
                }
            },
            onRequestMoreList: function() {
                this.itemsToShow = this.itemsToShow + 5;
            },
            onClickSortType: function(params) {
                this.currentSortType = {
                    value: params[0].field,
                    order: params[0].type
                };

                this.parentAssets = reorderItems(this.parentAssets, this.currentSortType)
            },
            onClickAddBtn: function() {
                // this.$router.push(`/admin/${this.modelKey}?new`);
                changeURL(`${this.modelKey}?new`, this.$route, this.$router);
            },
            onClickAsset: function(param) {
                // let path = `/admin/${this.modelKey}`;
                // if(item) {
                //     path += `?id=${item.id}`;
                // }
                //
                // if(this.$route.fullPath !== path) {
                //     this.$router.push(path);
                // }

                changeURL(this.modelKey, this.$route, this.$router, param.row);
            },
            onSearchTags(value) {
                this.updateListView(function (e) {
                    return isOk(e) && includesTag(value, e.tags);
                });
            },
        },
    }
</script>

<style lang="scss" scoped>

  .list-detail-view {
    height: 100%;
  }
  .tag-search-wrapper {
    margin-top: 15px;
  }

  .tag-search {
    width: 100%;
    border: 1px solid #ddd;
    border-radius: 3px;
    font-size: 14px;
    padding: 5px 10px;
  }
  .chart-view {
    width: 100%;
    height: 300px;
  }
  .option-container {
    width: 100%;
    select {
      background: #FFFFFF;
      border: 1px solid #000000;
      box-sizing: border-box;
    }
  }

</style>
