import axios from "axios";
import EventBus from "@/api/event-bus";

const initialState = { loggedIn: false };

export function isLoggedIn() {
    return localStorage.id && localStorage.auth;
}

export function login(form) {
    console.log("login");
    console.log(form);
    let url = `${process.env.VUE_APP_API_BASE_URL}admin/login`
    return axios
        .post(url, form)
        .then(response => {
            console.log("admin login");
            console.log(response);
            if(!response.data.success){
                return Promise.reject(response.data.message)
            }

            localStorage.id = response.data.item.id;
            localStorage.auth = response.data.message;

            localStorage.name = response.data.item.name;
            localStorage.oid = response.data.item.operatorId;
            localStorage.role = response.data.item.role;

            return Promise.resolve(response.data.item)
        })
}

export function cleanLocalStorage() {
    delete localStorage.id;
    delete localStorage.auth;
    delete localStorage.name;
    delete localStorage.oid;
    delete localStorage.role;
    EventBus.$emit("endSession");
}

export const auth = {
    state: initialState,
    mutations: {
        loginSuccess(state) {
            state.loggedIn = true;
        },
        loginFailure(state) {
            state.loggedIn = false;
        },
        logout(state) {
            state.loggedIn = false;
            cleanLocalStorage();
        }
    },
    actions: {
        login ({commit}, {email, password}) {
            let form = new FormData()

            form.append('email', email)
            form.append('password', password)

            return login(form).then(
                user => {
                    commit('loginSuccess');
                    return Promise.resolve(user);
                },
                error => {
                    commit('loginFailure');
                    return Promise.reject(error);
                }
            )
        },
        logout({ commit }) {
            commit('logout')
        }
    }
}
