<template>
  <div class="list-item" v-on:click="onClickList()" v-bind:class="{ selected: isSelected }">
    <div class="content-info">
      <span class="col-xs-6">
        <div class="title-desc text-left">{{item.type}}</div>
        <div class="title text-left">{{item.name}}</div>
      </span>
    </div>
  </div>
</template>

<script>
    export default {
        name: "Item",
        props: {
            item: {
                type: Object,
                default: function() { return{} }
            },
            isSelected: {
                type: Boolean,
                default: false
            }
        },
        // data() {
        //     return {
        //         isSelected: false,
        //     }
        // },
        methods: {
            onClickList: function() {
                this.$emit('onClick', this.item);
            },
            // updateIsSelected: function() {
            //     var isSelected = false;
            //     var hasIdQuery = this.$route.query.hasOwnProperty('id');
            //     if (hasIdQuery) {
            //         var contentId = JSON.parse(this.$route.query.id);
            //         if (contentId == this.item.id) {
            //             isSelected = true;
            //         }
            //     }
            //
            //     this.isSelected = isSelected;
            // },
        },
    }
</script>


<style lang="scss" scoped>
  .list-item {
    display: table;
    width: 100%;
    margin-top: 15px;
    background-color: #FFF;
    padding: 15px;
    border: 1px solid #EEE;
    border-left: 10px solid yellow;
    box-shadow: 2px 3px 4px -1px rgba(0,0,0,0.3);
    cursor: pointer;

    .select-wrapper {
      display: table-cell;
      vertical-align: middle;

      input {
        width: 30px;
      }
    }

    .content-info {
      display: table-cell;
      width: 100%;
      vertical-align: middle;
      .title {
        font-weight: bold;
        font-size: 24px;
        line-height: 33px;
        margin: 10px 0;
        letter-spacing: 0.0044em;
      }
      .title-desc {
        font-weight: bold;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: -0.03em;
        color: #B3B3B3;
      }
      .channel-number {
        vertical-align: middle;
        font-size: 14px;
        color: #666;
        margin-bottom: 10px;
      }

      .access-levels {
        font-size: 12px;
        color: #666;
        margin-top: 5px;

        .read-level, .write-level {
          display: inline-block;
        }
        .read-level {
          margin-right: 5px;
          padding-right: 10px;
          border-right: 1px solid #ddd;
        }
        .access-label {
          margin-right: 5px;
        }
      }
    }

  }
  .list-item:hover,
  .list-item.is-selected {
    border-color: #1982ce;
    box-shadow: 3px 4px 6px -1px rgba(0,0,0,0.4);
    .content-info {
      .title,
      .channel-number {
        color: #fefefe;
      }
      .access-levels {
        color: #EEE;
      }
    }
  }
  .list-item:hover { background-color: #52a5e0; }
  .list-item.selected {
      background-color: #2B90D9;
      color: white;
  }

  .low-stock {
    border-left: 10px solid red;
  }
  .stock-safe {
    border-left: 10px solid green;
  }
  .stock-warning {
    border-left: 10px solid orange;
  }
  .stock-danger {
    border-left: 10px solid red;
  }
</style>
