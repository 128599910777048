<template>
  <div class="user-state">

    <div v-if="currentUser == null">
      <a v-bind:href="loginUrl">Login</a>
    </div>

    <div v-if="currentUser != null">
      <li class="dropdown">
        <a class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
          <span>Hello, {{currentUser.name}}</span>
          <span class="caret"></span>
        </a>
        <ul class="dropdown-menu">
            <li><a @click.prevent="onClickLogout($router)" >{{$t('common.logout')}}</a></li>
        </ul>
      </li>
    </div>

  </div>
</template>

<script>
    import {cleanLocalStorage} from "../store/auth.module";

    export default {
        name: 'UserState',
        data() {
            return {
                loginUrl: '',
                logoutUrl: '',
                currentUser: {
                    name: localStorage.getItem('name')
                }
            }
        },
        methods:{
            onClickLogout: function () {
                cleanLocalStorage();

                this.$router.push('/admin');
            }
        }
    };
</script>

<style scoped>
  .user-state {
    padding: 15px;

  }
  .user-state li {
    list-style: none;
  }
  .user-state li a {
    color: #333;
    text-decoration: none;
    font-size: 14px;
    line-height: 20px;
    font-family: Arial, sans-serif;
    font-weight: 400;
  }
</style>

