import {
    createItem,
    deleteItem,
    errorHandler,
    getAPIURL,
    getCommonHeader,
    getItemListByURLPath,
    getPageItemByURLPath
} from "./common";
import {cleanLocalStorage} from "../store/auth.module";
import EventBus from "./event-bus";

const axios = require('axios');
const model = "asset";

export function getAssets(type, operatorId = 0) {
    let address = `${process.env.VUE_APP_API_BASE_URL}admin/${model}s`;

    address += '?type=' + type + '&operatorId=' + operatorId;
    console.log("getAssets " + address);
    return new Promise(function(resolve) {
        axios({
            method: 'GET',
            url: address,
            headers: getCommonHeader()
        }).then(function(response){
            // console.log('get assets ' + type + ' response');
            // console.log(response);
            if(response.data.success) {
                resolve(response.data.list);
            } else {
                console.log("Failed to get asset list");
                console.log(response.data.message);
            }
        }).catch(error => {
            errorHandler(error);
            console.log(error.response);
        })
    })
}

export function searchBikes(word, operatorId = 0) {
    let address = `${process.env.VUE_APP_API_BASE_URL}admin/bikes/search?word=`+word + '&operatorId=' + operatorId;


    console.log("getAssets " + address);
    return new Promise(function(resolve) {
        axios({
            method: 'GET',
            url: address,
            headers: getCommonHeader()
        }).then(function(response){
            // console.log('get assets ' + type + ' response');
            // console.log(response);
            resolve(response.data);

        }).catch(error => {
            errorHandler(error);
            console.log(error.response);
        })
    })
}
export function getAssetsUrl(url) {
    let address = `${process.env.VUE_APP_API_BASE_URL}admin` + url;
    console.log("getAssets " + address);
    return new Promise(function(resolve) {
        axios({
            method: 'GET',
            url: address,
            headers: getCommonHeader()
        }).then(function(response){
            // console.log('get assets ' + type + ' response');
            // console.log(response);
            if(response.data.success) {
                if(response.data.list != undefined) {
                    resolve(response.data.list);
                } else if(response.data.item != undefined) {
                    resolve(response.data.item);
                }
            }
        }).catch(error => {
            errorHandler(error);
            console.log(error.response);
        })
    })
}
export function writeBoundary(item){
    return createItem("boundary",item);
}

export function getBoundary(pid) {
    let address = `${process.env.VUE_APP_API_BASE_URL}admin/boundary?pid=${pid}`;

    return new Promise(function(resolve) {
        axios({
            method: 'get',
            url: address,
            headers: getCommonHeader()
        }).then(function(response){
            let data = response.data.list;

            resolve(data);
        }).catch(error => {
            errorHandler(error);
            console.log(error.response);
        })
    })
}

export function getParentAssets(type, operatorId) {
    let address = `${process.env.VUE_APP_API_BASE_URL}admin/boundary?type=${type}&operatorId=${operatorId}` ;

    return new Promise(function(resolve) {
        axios({
            method: 'get',
            url: address,
            headers: getCommonHeader()
        }).then(function(response){
            let data = response.data.list;

            resolve(data);
        }).catch(error => {
            errorHandler(error);
            console.log(error.response);
        })
    })
}

export function getAssetById(id) {
    let address = process.env.VUE_APP_API_BASE_URL + model +'?id=' + id;

    return new Promise(function(resolve) {
        axios({
            method: 'get',
            url: address,
            headers: getCommonHeader()
        }).then(function(response){
            let data = response.data;

            resolve(data)
            // if(data.success)
            //     resolve(data.item);
            // else
            //     console.log(data.message);

        }).catch(error => {
            errorHandler(error);
            console.log(error.response);
        })
    })
}

export function getAssetNameByUserId(userId) {
    let address = `${process.env.VUE_APP_API_BASE_URL}admin/asset?userId=${userId}`;

    return new Promise(function(resolve) {
        axios({
            method: 'get',
            url: address,
            headers: getCommonHeader()
        }).then(function(response){
            let data = response.data;

            if(data.success)
                resolve(data.item.name);
            else {
                console.log(data.message);

                resolve("없음");
            }

        }).catch(error => {
            errorHandler(error);
            console.log(error.response);
        })
    })
}

export function getBikesByUID(userId) {
    let address = `${process.env.VUE_APP_API_BASE_URL}admin/bikes?userId=${userId}`;

    return new Promise(function(resolve, reject) {
        axios({
            method: 'GET',
            url: address,
            headers: getCommonHeader()
        }).then(function(response){
            let data = response.data;

            if(data.success)
                resolve(data.list);
            else
                console.log(data.message)
        }).catch(error => {
            errorHandler(error);
            console.log(error.response);
        })
    })
}

export function deleteArea(id) {

    return deleteItem("boundary", id)
}

export function deleteAsset(id) {

    return deleteItem(model, id)
}

export function writeAsset(item) {
    let address = `${process.env.VUE_APP_API_BASE_URL}admin/${model}`;

    return new Promise(function(resolve, reject) {
        axios({
            method: 'post',
            url: address,
            data: JSON.stringify(item),
            headers: getCommonHeader()
        }).then(function(response){
            // console.log("wrietasset response");
            // console.log(response.data);
            // let data = response.data;

            resolve(response.data);
        }).catch(error => {
            errorHandler(error);
            console.log(error.response);
        })
    })
}
export function addAssetHistory(item) {
    let address = process.env.VUE_APP_API_BASE_URL + 'admin/bike/history';
    return new Promise(function(resolve, reject) {
        axios({
            method: 'post',
            url: address,
            data: JSON.stringify(item),
            headers: getCommonHeader()
        }).then(function(response){
            // console.log("wrietasset response");
            // console.log(response.data);
            // let data = response.data;

            resolve(response.data);
        }).catch(error => {
            errorHandler(error);
            console.log(error.response);
        })
    })
}
export function getAssetHistory(assetId) {
    let address = process.env.VUE_APP_API_BASE_URL + 'admin/bike/history?assetId='+assetId;
    return new Promise(function(resolve, reject) {
        axios({
            method: 'get',
            url: address,
            headers: getCommonHeader()
        }).then(function(response){
            // console.log("wrietasset response");
            console.log(response.data);
            // let data = response.data;

            resolve(response.data);
        }).catch(error => {
            errorHandler(error);
            console.log(error.response);
        })
    })
}
export function getSysLogsByAssetId(assetId, page = 1, per_page= 15, sort = "createdWhen,desc"){
    let urlPath = `admin/syslog/${assetId}?size=${per_page}&page=${page}&sort=${sort}`;

    return getPageItemByURLPath(urlPath);
}

export function getAssetLogs(assetId, from, to) {
    let urlPath = process.env.VUE_APP_API_BASE_URL + 'admin/asset/' + assetId + '/logs?from=' + from + '&to=' + to;
    return new Promise(function(resolve, reject) {
        axios({
            method: 'get',
            url: urlPath,
            headers: getCommonHeader()
        }).then(function(response){
            // console.log("wrietasset response");
            console.log(response.data);
            // let data = response.data;

            resolve(response.data);
        }).catch(error => {
            errorHandler(error);
            console.log(error.response);
        })
    })
}

export function getSysLogs(pagination = null) {
    let urlPath = `admin/syslogs`;

    if(pagination != null) {
        urlPath += `?page=${pagination.page}`;
        urlPath += `&size=${pagination.size}`;
        urlPath += `&sort=${pagination.sort}`;
    }

    return getPageItemByURLPath(urlPath);
}

export function newBoundary(type) {
    return [
        {
            id: 0,
            parentId: 0,
            operatorId: 0,
            userId: 0,
            title: "",
            name: "",
            type: type,
            code: "",
            operatorCode: "",
            areaCode: "",
            regionCode: "",
            modelCode: "",
            fullCode: "",
            channel: "",
            lat: 36.5, lon:127.8,
            battery: 0.0,
            onOff: false,
            reverseOrder:false,
            status: 0,
            tags: [],
        }
    ]
}

export function newAsset(type) {
    return {
        id: 0,
        parentId: 0,
        operatorId: 0,
        userId: 0,
        title: "",
        name: "",
        type: type,
        code: "",
        operatorCode: "",
        areaCode: "",
        regionCode: "",
        modelCode: "",
        fullCode: "",
        channel: "",
        mqttServer:1,
        lat: 36.5, lon:127.8,
        battery: 0.0,
        onOff: false,
        status: 0,
        tags: [],
        deleted: false
    }
}

export function getBikeStateList() {
    let list = [
        {
            name:'생성',
            value:0,
        },
        {
            name:'대여가능',
            value:1,
        },
        {
            name:'대여중',
            value:2,
        },
        {
            name:'충전중',
            value:3,
        },
        {
            name:'수리중',
            value:4,
        },
        {
            name:'입고',
            value:5,
        },
        {
            name:'이동중',
            value:6,
        },
    ];
    return list;
}

export function getBikeStatusString(status) {
    if(status == 0) {
        return '생성';
    }else if(status == 1) {
        return '대여가능';
    } else if(status == 2) {
        return '대여중';
    } else if(status == 3) {
        return '충전중';
    } else if(status == 4) {
        return '수리중';
    } else if(status == 5) {
        return '입고';
    } else if(status == 6) {
        return '이동중';
    }
    return '알수없음';
}
export function newBikePrice() {
    return {
        bike_base: {
            name: "",
            duration: "0",
            rate: 0
        },
        bike_normal: {
            name: "",
            duration: "0",
            rate: 0
        },
        bike_park: {
            name: "",
            duration: "0",
            rate: 0
        },
        bike_insurance: {
            name: "",
            duration: "0",
            rate: 0
        }
    }
}

/**
 * Send command to bike
 * lock: 잠그기
 * unlock: 잠금해제
 * light: 라이트 켜기 (꺼진 상태에서는 동작 되지 않음)
 * alarm: 알람 울리기 (1초 간격으로 5번 울림)
 * @param fullCode
 * @param command
 */
export function sendCommandToBike(fullCode, command) {
    var url = process.env.VUE_APP_API_BASE_URL + 'asset/request/' + fullCode + '/' + command;
    console.log("URL: " + url);
    return new Promise(function(resolve, reject) {
        axios.get(url,
            {
                headers: getCommonHeader()
            })
            .then(response => {
                let data = response.data;
                resolve(data);
            })
            .catch(err=>{
                console.log(err);
                errorHandler(err);
                reject(err);
            })
    })

}
/**
 * Send command to bike
 * lock: 잠그기
 * unlock: 잠금해제
 * light: 라이트 켜기 (꺼진 상태에서는 동작 되지 않음)
 * alarm: 알람 울리기 (1초 간격으로 5번 울림)
 * @param fullCode
 * @param command
 */
export function getBikePendingHireSession(bikeId) {
    var url = process.env.VUE_APP_API_BASE_URL + 'admin/order/bike/pendingSession?bikeId='+bikeId;
    console.log("URL: " + url);
    return new Promise(function(resolve, reject) {
        axios.get(url,
            {
                headers: getCommonHeader()
            })
            .then(response => {
                let data = response.data;
                resolve(data);
            })
            .catch(err=>{
                console.log(err);
                errorHandler(err);
                reject(err);
            })
    })

}



export function getAssetPendingOrders(assetId) {
    var url = process.env.VUE_APP_API_BASE_URL + 'admin/pendingSales?assetId=' + assetId;
    console.log("URL: " + url);
    return new Promise(function(resolve, reject) {
        axios({
            method: 'get',
            url: url,
            headers: getCommonHeader()
        }).then(response => {
                console.log(response);
                let data = response.data;
                resolve(data);
        })
        .catch(err=>{
            console.log(err);
            errorHandler(err);
            reject(err);
        })
    })
}

export function finishPendingOrder(orderId, title = false, due = 0) {
    var url = process.env.VUE_APP_API_BASE_URL + 'returnHire/' + orderId;
    if(title != false && title.length > 0 && due != 0) {
        url += '?additionalChargeTitle=' + title + '&additionalCharge=' + due;
    }
    console.log("URL: " + url);
    return new Promise(function(resolve, reject) {
        axios({
            method: 'get',
            url: url,
            headers: getCommonHeader()
        }).then(response => {
            console.log(response);
            let data = response.data;
            resolve(data);
        })
            .catch(err=>{
                console.log(err);
                errorHandler(err);
                reject(err);
            })
    })
}
export function getBikeExcel(operatorId=0) {
    let address = process.env.VUE_APP_API_BASE_URL + 'admin/bike/report?operatorId='+operatorId;
    EventBus.$emit("showLoading", true, '생성중');
    return new Promise(function(resolve, reject) {
        axios( {
            method:'GET',
            url:address,
            responseType: 'blob',
            headers: getCommonHeader()
        }).then(function(response) {
            EventBus.$emit("showLoading", false);
            resolve(response);
        }).catch(error => {
            EventBus.$emit("showLoading", false);
            errorHandler(error);
            console.log(error.response);
        });
    })
}
export function searchBikeServiceLogs(query) {
    let address = `${process.env.VUE_APP_API_BASE_URL}admin/assets/logs/query?` + query;


    console.log("getAssets " + address);
    return new Promise(function(resolve) {
        axios({
            method: 'GET',
            url: address,
            headers: getCommonHeader()
        }).then(function(response){
            // console.log('get assets ' + type + ' response');
            // console.log(response);
            resolve(response.data);

        }).catch(error => {
            errorHandler(error);
            console.log(error.response);
        })
    })
}
