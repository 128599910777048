<template>
    <div class="detail-view h-100" :key="'asset-detail-' + item.id" style="background-color: white;">
        <div class="container-fluid h-100">
            <div class="row h-100">

                <div class="col-12 h-100">
                    <div style="width: 100%; height: 70px; display: flex;">
                        <div style="width: calc(100% - 100px); height: 70px;">
                            <tabs-view
                                :tab-menu="tabMenu"
                                :current-tab="currentTab"
                                v-on:onClick="handleClick"/>
                        </div>
                        <div style="width: 100px; height: 70px; line-height: 70px;">
                            <div class="mary-button" style="background-color: black; text-align: center;" v-on:click="onClickCloseBtn()"><span><i class="fas fa-times"></i></span> {{$t('detail.close')}}</div>
                        </div>

                    </div>
                    <div class="content" style="width: 100%; height: calc(100% - 70px); overflow-y: scroll;">
                        <div v-if="'bike' === currentTab">
                            <div class="col-12 text-right mary-button-container" style="position: sticky; top:0; background-color: white; z-index: 2; box-shadow: 2px 2px 3px gray;">

                                <!--                    <transition name="bounce">-->
<!--                                <div class="mary-button" style="background-color: black;" v-on:click="onClickCloseBtn()"><span><i class="fas fa-times"></i></span> {{$t('detail.close')}}</div>-->
                                <!--                    </transition>-->
                                <!--                    <transition name="bounce">-->
                                <div class="mary-button" v-show="!isEditMode" v-on:click="onClickEditBtn()"><span><i class="fas fa-edit"></i></span> {{$t('detail.edit')}}</div>
                                <!--                    </transition>-->
                                <!--                    <transition name="bounce">-->
                                <div class="mary-button" v-show="isEditMode" v-on:click="onClickSaveBtn()"><span><i class="fas fa-save"></i></span> {{$t('detail.save')}}</div>
                                <!--                    </transition>-->
                                <!--                    <transition name="bounce">-->
                                <div class="mary-button" style="background-color: red; float:left;" v-show="!isAddMode" v-on:click="onClickRemoveBtn()"><span><i class="fas fa-trash"></i></span> {{$t('detail.remove')}}</div>
                                <!--                    </transition>-->

                            </div>
                            <div class="col-12">
                                <div class="head-group">
                                    <div class="top"></div>
                                    <div class="bottom">
                                        <div class="content-header">
                                            <vue-form style="width: 100%;"
                                                      label-width="100%"
                                                      label-position="left">
                                                <AccessDropdownInput
                                                    :is-read-only="!isEditMode"
                                                    v-bind:operatorId="item.operatorId"
                                                    v-on:onClick="setOperatorId"/>
                                                <hr>
                                                <h4>기본 정보 #{{item.id}}</h4>
                                                <!--                        <vue-form-item :label="$t('content.parentId')">-->
                                                <!--                            <vue-input-->
                                                <!--                                type="number"-->
                                                <!--                                v-model="item.parentId"-->
                                                <!--                                :disabled="!isEditMode">-->
                                                <!--                            </vue-input>-->
                                                <!--                        </vue-form-item>-->
                                                <!--                        <vue-form-item :label="$t('content.userId')">-->
                                                <!--                            <vue-input-->
                                                <!--                                v-model="item.userId"-->
                                                <!--                                :disabled="!isEditMode">-->
                                                <!--                            </vue-input>-->
                                                <!--                        </vue-form-item>-->
                                                <!--                        <vue-form-item :label="$t('content.title')">-->
                                                <!--                            <vue-input-->
                                                <!--                                v-model="item.title"-->
                                                <!--                                :disabled="!isEditMode">-->
                                                <!--                            </vue-input>-->
                                                <!--                        </vue-form-item>-->
                                                <vue-form-item :label="$t('content.name')">
                                                    <vue-input
                                                        v-model="item.name"
                                                        :disabled="!isEditMode">
                                                    </vue-input>
                                                </vue-form-item>
                                                <vue-form-item :label="$t('content.type')">
                                                    <vue-input
                                                        v-model="item.type"
                                                        :disabled="true">
                                                    </vue-input>
                                                </vue-form-item>
                                                <hr>
                                                <h4>대여시 사용 정보</h4>
                                                <vue-form-item :label="$t('asset.code')">
                                                    <vue-input
                                                        v-model="item.code"
                                                        :disabled="!isEditMode">
                                                    </vue-input>
                                                </vue-form-item>
                                                <vue-form-item :label="$t('asset.fullCode')">
                                                    <vue-input
                                                        v-model="item.fullCode"
                                                        :disabled="!isEditMode">
                                                    </vue-input>
                                                </vue-form-item>
                                                <vue-form-item label="">
                                                    <qr-canvas :options="{cellSize:5, data:item.fullCode}"></qr-canvas>
                                                </vue-form-item>
                                                <hr>
                                                <h4>NAMING</h4>
                                                <vue-form-item :label="$t('asset.operatorCode')">
                                                    <vue-input
                                                        v-model="item.operatorCode"
                                                        :disabled="!isEditMode">
                                                    </vue-input>
                                                </vue-form-item>
                                                <vue-form-item :label="$t('asset.regionCode')">
                                                    <vue-input
                                                        v-model="item.regionCode"
                                                        :disabled="!isEditMode">
                                                    </vue-input>
                                                </vue-form-item>
                                                <vue-form-item :label="$t('asset.areaCode')">
                                                    <vue-input
                                                        v-model="item.areaCode"
                                                        :disabled="!isEditMode">
                                                    </vue-input>
                                                </vue-form-item>

                                                <vue-form-item :label="$t('asset.modelCode')">
                                                    <vue-input
                                                        v-model="item.modelCode"
                                                        :disabled="!isEditMode">
                                                    </vue-input>
                                                </vue-form-item>
                                                <hr>
                                                <h4>시스템 정보</h4>
                                                <vue-form-item label="IMEI 번호">
                                                    <vue-input
                                                        v-model="item.imei"
                                                        :disabled="!isEditMode">
                                                    </vue-input>
                                                </vue-form-item>
                                                <vue-form-item label="IMEI 만료일">
                                                    <input type="date" class="form-control" v-model="imeiExpiryDate" :disabled="!isEditMode"/>

                                                </vue-form-item>
                                                <vue-form-item :label="$t('asset.mqttServer')">
                                                    <select class="form-control" v-model="item.mqttServer" :disabled="!isEditMode">
                                                        <option value="1">신규 MQTT 서버</option>
                                                        <option value="2">구 MQTT 서버</option>
                                                    </select>
                                                </vue-form-item>
                                                <vue-form-item :label="$t('asset.channel')">
                                                    <vue-input
                                                        v-model="item.channel"
                                                        :disabled="!isEditMode">
                                                    </vue-input>
                                                </vue-form-item>
                                                <vue-form-item label="정검주기">
                                                    <select class="form-control" v-model="item.serviceInterval" :disabled="!isEditMode">
                                                        <option v-for="i in 30" :value="i">{{i}}일</option>
                                                    </select>
                                                </vue-form-item>
                                                마지막 정검 : <span v-if="item.lastServiceTime > 0">{{convertTime(item.lastServiceTime)}}</span>
                                                <br/>
                                                다음 정검 : <span v-if="item.nextServiceTime > 0">{{convertTime(item.nextServiceTime)}}</span>
                                                <br/><br/>
                                                <vue-form-item :label="$t('asset.lat')">
                                                    <vue-input
                                                        v-model="item.lat"
                                                        :disabled="!isEditMode">
                                                    </vue-input>
                                                </vue-form-item>
                                                <vue-form-item :label="$t('asset.lon')">
                                                    <vue-input
                                                        v-model="item.lon"
                                                        :disabled="!isEditMode">
                                                    </vue-input>
                                                </vue-form-item>

                                                <vue-form-item :label="$t('asset.battery')">
                                                    <vue-input
                                                        v-model="item.battery"
                                                        :disabled="!isEditMode">
                                                    </vue-input>
                                                </vue-form-item>
                                                <vue-form-item :label="$t('asset.onOff')">
                                                    <vue-radio
                                                        v-model="item.onOff ? 1 : 0"
                                                        @change="onChangeOnOff"
                                                        :value=1
                                                        :disabled="!isEditMode">
                                                        On
                                                    </vue-radio>
                                                    <vue-radio
                                                        v-model="item.onOff ? 1 : 0"
                                                        :value=0
                                                        :disabled="!isEditMode">
                                                        Off
                                                    </vue-radio>
                                                </vue-form-item>
                                                <vue-form-item :label="$t('bike.status')">
                                                    <select class="form-control" v-model="item.status" :disabled="!isEditMode">
                                                        <option v-for="state in stateList" :value="state.value">{{state.name}}</option>
                                                    </select>
                                                </vue-form-item>
                                                <h4 class="toggle-button-container"><span class="toggle-title" v-bind:class="{'activated': useBikePrice == true}" v-on:click="toggleBikePrice">요금제</span></h4>
                                                <!--                        <vue-form-item label="CONTENT">-->
                                                <!--                            <vue-input-->
                                                <!--                                type="textarea"-->
                                                <!--                                v-model="item.content"-->
                                                <!--                                :disabled="!isEditMode">-->
                                                <!--                            </vue-input>-->
                                                <!--                        </vue-form-item>-->
                                                <div v-if="useBikePrice" class="border-box">
                                                    <h5>기본 요금</h5>
                                                    <vue-form-item label="이름">
                                                        <vue-input
                                                            v-model="detailPrice.bike_base.name"
                                                            :disabled="!isEditMode">
                                                        </vue-input>
                                                    </vue-form-item>
                                                    <vue-form-item label="기간(ms)">
                                                        <vue-input
                                                            v-model="detailPrice.bike_base.duration"
                                                            :disabled="!isEditMode">
                                                        </vue-input>
                                                    </vue-form-item>
                                                    <vue-form-item label="가격">
                                                        <vue-input
                                                            v-model="detailPrice.bike_base.rate"
                                                            :disabled="!isEditMode">
                                                        </vue-input>
                                                    </vue-form-item>
                                                    <h5>추가 요금</h5>
                                                    <vue-form-item label="이름">
                                                        <vue-input
                                                            v-model="detailPrice.bike_normal.name"
                                                            :disabled="!isEditMode">
                                                        </vue-input>
                                                    </vue-form-item>
                                                    <vue-form-item label="기간(ms)">
                                                        <vue-input
                                                            v-model="detailPrice.bike_normal.duration"
                                                            :disabled="!isEditMode">
                                                        </vue-input>
                                                    </vue-form-item>
                                                    <vue-form-item label="가격">
                                                        <vue-input
                                                            v-model="detailPrice.bike_normal.rate"
                                                            :disabled="!isEditMode">
                                                        </vue-input>
                                                    </vue-form-item>
                                                    <h5>주차 요금</h5>
                                                    <vue-form-item label="이름">
                                                        <vue-input
                                                            v-model="detailPrice.bike_park.name"
                                                            :disabled="!isEditMode">
                                                        </vue-input>
                                                    </vue-form-item>
                                                    <vue-form-item label="기간(ms)">
                                                        <vue-input
                                                            v-model="detailPrice.bike_park.duration"
                                                            :disabled="!isEditMode">
                                                        </vue-input>
                                                    </vue-form-item>
                                                    <vue-form-item label="가격">
                                                        <vue-input
                                                            v-model="detailPrice.bike_park.rate"
                                                            :disabled="!isEditMode">
                                                        </vue-input>
                                                    </vue-form-item>
                                                    <h5>보험 요금</h5>
                                                    <vue-form-item label="이름">
                                                        <vue-input
                                                            v-model="detailPrice.bike_insurance.name"
                                                            :disabled="!isEditMode">
                                                        </vue-input>
                                                    </vue-form-item>
                                                    <vue-form-item label="기간(ms)">
                                                        <vue-input
                                                            v-model="detailPrice.bike_insurance.duration"
                                                            :disabled="!isEditMode">
                                                        </vue-input>
                                                    </vue-form-item>
                                                    <vue-form-item label="가격">
                                                        <vue-input
                                                            v-model="detailPrice.bike_insurance.rate"
                                                            :disabled="!isEditMode">
                                                        </vue-input>
                                                    </vue-form-item>
                                                </div>
                                                <hash-tag-input v-model="item.tags" v-bind:isReadOnly="!isEditMode"/>
                                            </vue-form>
                                        </div><!-- ./content-header -->
                                        <hr>

                                        <!--                <vuetable-->
                                        <!--                    ref="vuetable"-->
                                        <!--                    :api-url="apiURL"-->
                                        <!--                    :http-options= "headerInfo"-->
                                        <!--                    :fields="tableFields"-->
                                        <!--                    :transform="transform"-->
                                        <!--                    pagination-path=""/>-->
                                        <!--                <div style="text-align: center">-->
                                        <!--                    <div class="btn" v-on:click="downPageIndex">-->
                                        <!--                        <span class="glyphicon glyphicon-arrow-left"/> 이전-->
                                        <!--                    </div>-->
                                        <!--                    <div class="btn" v-on:click="initialPageIndex">-->
                                        <!--                        처음-->
                                        <!--                    </div>-->
                                        <!--                    <div class="btn" v-on:click="upPageIndex">-->
                                        <!--                        다음 <span class="glyphicon glyphicon-arrow-right"/>-->
                                        <!--                    </div>-->
                                        <!--                </div>-->
                                    </div><!-- ./bottom -->
                                </div><!-- ./head-group -->
                            </div>
                        </div>
                        <div v-if="'orders' === currentTab">
                            <table class="table">
                                <tr>
                                    <th>사용자</th>
                                    <th>현재 상태</th>
                                    <th>금액</th>
                                    <th>대여시작</th>
                                    <th>대여종료</th>
                                    <th></th>
                                </tr>
                                <tbody>
                                <tr v-for="order in pendingOrders">
                                    <td>{{order.user.name}}<br>{{order.user.phoneNumber}}</td>
                                    <td>{{stateString(order.state)}}</td>
                                    <td><span v-if="order.finalDue > 0">{{order.finalDue}}원</span></td>
                                    <td>{{convertTime(order.startTime)}}</td>
                                    <td>{{convertTime(order.toTime)}}</td>
                                    <td>
                                        <button v-if="order.state == 0" class="btn btn-primary" v-on:click="forceToFinish(order.id)">강제반납</button>
                                        <button v-if="order.state == 0" class="btn btn-danger" v-on:click="forceToFinishWithAdditionalCharge(order.id)">추가요금 + 강제반납</button>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div v-else-if="'syslog' === currentTab">
<!--                            <h4>SYS LOG</h4>-->
<!--                            <h5>위치 정보 접근 기록</h5>-->
                            <div style="display:flex; padding-top:30px;padding-bottom:10px;">
                                <div class="mary-button" v-on:click="refreshSysLog">새로고침 <i class="fas fa-sync"></i></div>
                            </div>
                            <vue-good-table
                                mode="remote"
                                @on-page-change="onPageChange"
                                @on-sort-change="onSortChange"
                                @on-per-page-change="onPerPageChange"
                                :totalRows="totalRows"
                                :pagination-options="{
                                    enabled: true,
                                    perPage: 15,
                                    perPageDropdown: [25],
                                    dropdownAllowAll: false
                                }"
                                :rows="sysLogList"
                                :columns="columns"/>
                        </div>
                        <div v-else-if="'history' === currentTab" style="min-height: 200px;">
                            <!--                            <h4>SYS LOG</h4>-->
                            <!--                            <h5>위치 정보 접근 기록</h5>-->

                            <table class="table">
                                <tr>
                                    <th>시간</th>
                                    <th>User</th>
                                    <th>내용</th>
                                </tr>
                                <tr v-for="h in histories">
                                    <td>{{convertTime(h.createdWhen)}}</td>
                                    <td>{{h.user != null?h.user.name:h.userId}}</td>
                                    <td>{{h.description}}</td>
                                </tr>

                            </table>
                            <textarea class="form-control" v-model="newHistoryDescription" rows="5"></textarea>
                            <button class="btn btn-primary" v-on:click="addHistory"><i class="fas fa-plus"></i> 이력 추가</button>
                        </div>
                        <div v-else-if="'asset-log' === currentTab" style="height: 100%;">
                            <div style="width: 100%; height: 50px; line-height: 50px;">
                                <date-picker v-model="dateRange" valueType="format" @change="dateRangeSelected" range></date-picker>
                                <button class="btn btn-success" @click="getBikeLogs">점검이력 가져오기</button>
                            </div>
                            <div style="width: 100%; height: calc(100% - 50px);">
                                <table class="table">
                                    <tr>
                                        <th>Date</th>
                                        <th>직업자</th>
                                        <th>제목</th>
                                        <th>내용</th>
                                        <th></th>
                                    </tr>
                                    <template v-for="log in assetLogs">
                                        <tbody v-bind:key="'log-item-' + log.id">
                                        <tr>
                                            <td>{{log.logDate}}</td>
                                            <td>{{log.userName}}</td>
                                            <td>{{log.title}}</td>
                                            <td>{{log.description}}</td>
                                            <td>{{convertTime(log.createdTime)}}</td>
                                        </tr>
                                        </tbody>
                                    </template>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="fullscreen-background" v-if="additionalChargeItem != false">
            <div class="form-container" style="overflow: hidden; padding:0;">
                <div class="container">
                    <div class="row">
                        <div class="col-12 text-center" style="font-size: 1.5rem; font-weight:bold; padding-top:10px; padding-bottom: 10px; background: #E3E3E3;">
                            추가 요금 부과 + 강제반납
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12" style="padding-top:30px;">
                            추가 요금명
                            <input type="text" class="form-control" v-model="additionalChargeItem.title"/>
                        </div>
                        <div class="col-12" style="padding-top:30px;">
                            추가 요금액
                            <input type="number" class="form-control" v-model="additionalChargeItem.due"/>
                        </div>
                    </div>
                    <div class="row" style="padding-top:30px;">
                        <div class="col-6">
                            <button class="form-control btn btn-secondary" v-on:click="cancelAdditionalCharge">취소</button>
                        </div>
                        <div class="col-6">
                            <button class="form-control btn btn-primary" v-on:click="processAdditionalCharge">진행</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>

    require('@/assets/css/common.css')
    import EventBus from '@/api/event-bus.js';
    import {
        deleteAsset,
        writeAsset,
        getBikeStateList,
        newBikePrice,
        getSysLogsByAssetId,
        getAssetById,
        sendCommandToBike,
        getAssetPendingOrders,
        finishPendingOrder,
        addAssetHistory,
        getAssetHistory, getAssetLogs
    } from "@/api/asset";
    import {millisToReadable, millisToDate} from "@/utils/TimeConverter";
    import AccessDropdownInput from "@/inputs/OperatorDropdownInput";
    import HashTagInput from "@/inputs/HashTagInput";
    import TabsView from "@/views/TabsView";
    import {setTagsByOperatorId} from "@/api/operator";
    import {getCommonHeader, isOk, showDialog} from "@/api/common";
    import {getBikeOrderStateString} from "@/api/order";

    const { QRCanvas } = require('qrcanvas-vue');
    import { Datetime } from 'vue-datetime';
    import DatePicker from "vue2-datepicker";
    import 'vue2-datepicker/index.css';

    export default {
        name: "DetailAsset",
        components: {
            AccessDropdownInput,
            HashTagInput,
            TabsView,

            Datetime,
            QrCanvas: QRCanvas,
            DatePicker,
        },
        props: {
            item: {
                type: Object,
                default: function() { return{} }
            },
            isAddMode: {
                type: Boolean,
                default: true
            }
        },
        data(){
          return {
              // onOffValue: 0,

              isEditMode: this.isAddMode,

              columns: [

                  {field: 'createdWhen', label: '시간',},
                  {field: 'orderId', label: "주문번호", sortable: false},
                  {field: 'source', label: "source", sortable: false},
                  {field: 'type', label: "type", sortable: false},
                  {field: 'lat', label: "lat", sortable: false},
                  {field: 'lon', label: "lon", sortable: false},
                  {field: 'onOff', label: "전원", sortable: false},
                  {field: 'battery', label: "battery", sortable: false},
                  {field: 'distance', label: "운행거리", sortable: false},
                  {field: 'totalDistance', label: '총운행거리', sortable: false},
                  {field: 'system', label: "system", sortable: false},
                  {field: 'inside', label: "지역"},
                  {field: 'insideParkingArea', label: '주차지역'}
              ],

              headerInfo: {
                  headers: getCommonHeader()
              },

              tabMenu : [
                  {title: '자전거 정보', value: 'bike'},
                  {title: '진행중인 대여', value: 'orders'},
                  {title: 'Sys Log', value: 'syslog'},
                  {title: '사용자 이력', value: 'history'},
                  {title: '정비 이력', value: 'asset-log'},

              ],

              currentTab: 'bike',
              totalRows: 1,
              sysLogList: [],
              currentParam: {
                  id: this.item.id,
                  page: 0,
                  size: 15,
                  sort: "createdWhen,desc"
              },

              stateList: getBikeStateList(),
              currentStatus:{
                  name:'선택',
                  value:0,
              },

              detailPrice: {},
              useBikePrice: false,

              imeiExpiryDate: '',

              pendingOrders:[],
              additionalChargeItem: false,
              histories:[],
              newHistoryDescription:'',
              dateRange:null,
              assetLogs:[],
          }
        },
        created() {
            this.model = {};
            this.$data.imeiExpiryDate = millisToDate(this.item.imeiExpiry, '-');
            // this.onOffValue = this.item.onOff ? 1 : 0;

            // if(this.item.onOff){
            //     this.onOffValue = 1
            // } else {
            //     this.onOffValue = 0
            // }

            for(let state of this.stateList) {
                if(state.value === this.item.status) {
                    this.currentStatus = state;
                }
            }

            // this.updateSyslogList();
            this.getDetailPrice();

            // Get pending orders if available
            // This list will be used for admin to return bike and finish pending session
            this.getPendingOrders();
        },
        watch:{
            $route(){
                this.isEditMode = this.isAddMode;

                this.currentParam = {
                    id: this.item.id,
                        page: 0,
                        size: 15,
                        sort: "createdWhen,desc"
                };

                this.updateSyslogList();
                this.getDetailPrice();
            }
        },
        methods: {
            stateString:getBikeOrderStateString,
            convertTime:millisToReadable,
            handleClick: function(selectedTab) {
                this.currentTab = selectedTab
                if(this.currentTab == 'history') {
                    this.getHistory();
                } else if(this.currentTab == 'syslog') {
                    this.refreshSysLog();
                }
            },
            onChangeOnOff: function(v){
                this.item.onOff = (1 === v);
            },
            setOperatorId: function(id) {
                setTagsByOperatorId(id, this.item);
            },
            onClickSaveBtn: function() {
                if(this.item.operatorId == 0) {
                    let self = this;
                    this.$modal.show('dialog', {
                        title: '',
                        text: "자전거 사업자를 선택해주세요",
                        buttons: [
                            {
                                title: '확인',
                                handler: () => {
                                    self.$modal.hide('dialog');
                                }
                            },
                        ]
                    });
                    return;
                }
                if(!this.$data.useBikePrice || this.detailPrice.length === 0) {
                    this.item.content = "";
                } else {

                    /**
                     *
                     */
                    let self = this;
                    for (let key in this.detailPrice) {

                        if(this.detailPrice[key].name == null || this.detailPrice[key].name === "") {
                            this.$modal.show('dialog', {
                                title: '',
                                text: "자전거 요금중에 이름이 없는 항목이 있습니다.",
                                buttons: [
                                    {
                                        title: '확인',
                                        handler: () => {
                                            self.$modal.hide('dialog');
                                        }
                                    },
                                ]
                            });
                            return;
                        }
                    }

                    this.item.content = JSON.stringify(this.detailPrice);
                }
                let expiryDay = new Date(this.$data.imeiExpiryDate);
                expiryDay.setHours(23);
                expiryDay.setMinutes(59);
                expiryDay.setSeconds(59);

                this.item.imeiExpiry = expiryDay.getTime();
                let that = this;
                writeAsset(that.item).then(function (data) {
                    if(data.success) {
                        alert("저장 되었습니다.");

                        that.isEditMode = false;

                        that.$emit("updateList")
                    } else {
                        alert("ERROR: " + data.message);
                    }
                    // that.$router.push(`/admin/bike?id=${item.id}`);
                })
            },
            // setStatus: function(item) {
            //     this.currentStatus = item;
            // },
            onClickCloseBtn: function() {
                // 현재 수정 중이 아니거나 새로 입력 중인 경우에 닫아서 상위 템플릿에게 알린다.
                if(!this.isEditMode) {
                    this.$emit('onClose', null);

                    return;
                }

                this.isEditMode = false;
                // if(this.isAddMode || !this.isEditMode) {
                //     this.$router.push(`/admin/${this.modelKey}`);
                // } else {
                //     this.$router.push(`/admin/${this.modelKey}?id=${this.$route.query.id}`);
                // }
            },
            onClickEditBtn: function() {
                this.isEditMode = true;

                // this.$router.push(`/admin/bike?id=${this.$route.query.id}&edit`);
            },
            onClickRemoveBtn: function() {
                let self = this;
                this.$modal.show('dialog', {
                    title: '',
                    text: "자전거 " + this.item.name + ' [' + this.item.fullCode + ']를 삭제 하시겠습니까?',
                    buttons:[
                        {
                            title: '아니요',
                            handler:() => {
                                self.$modal.hide('dialog');
                            }
                        },
                        {
                            title:'삭제',
                            handler:()=>{
                                self.$modal.hide('dialog');
                                self.onClickRemoveBtnConfirmed();
                            }
                        }
                    ]
                })
            },
            onClickRemoveBtnConfirmed: function () {
                let that = this;

                deleteAsset(that.item.id).then(function (post) {
                    alert("삭제 되었습니다.");

                    that.$emit("updateList")

                    that.$emit('onClose', null);

                    // that.$router.push(`/admin/bike`)
                });
            },
            refreshSysLog: function() {
                this.currentParam = {
                    id: this.item.id,
                    page: 0,
                    size: 15,
                    sort: "createdWhen,desc"
                };
                this.sysLogList = [];
                this.totalRows = 0;
                this.updateSyslogList();

            },
            updateSyslogList: function(check = isOk){
                let that = this;
                console.log("get sys log");
                getSysLogsByAssetId(this.currentParam.id, this.currentParam.page, this.currentParam.size, this.currentParam.sort)
                    .then(data => {
                        that.sysLogList = [];
                        that.totalRows = data.totalElements;

                        for(let e of data.list){
                            if(check(e)){
                                e.createdWhen = millisToReadable(e.createdWhen);
                                e.lon = e.lon.toFixed(5);
                                e.lat = e.lat.toFixed(5);
                                e.onOff = e.onOff ? '운행' : '꺼짐';
                                e.distance += ' metre';
                                e.inside = e.inside ? '내부' : '이탈';
                                e.insideParkingArea = e.insideParkingArea ? '내부' : '이탈';

                                that.sysLogList.push(e);
                            }
                        }
                    });
            },
            getBikeLogs: function() {

                this.$data.assetLogs = [];
                if(this.$data.dateRange === null || this.$data.dateRange.length !== 2) {
                    showDialog("기간을 선택하세요");
                    return;
                }
                let self = this;
                getAssetLogs(this.item.id, this.$data.dateRange[0], this.$data.dateRange[1])
                .then(function(data) {
                    if(data.success === true) {
                        self.$data.assetLogs = data.item;
                    } else {
                        showDialog(data.message);
                    }
                })
                .catch(function(err) {
                    let msg = err;
                    if(err.response !== undefined) {
                        msg += ' (' + err.response  + ')';
                    }
                    showDialog(msg, "ERROR");
                })
            },
            onPageChange(params) {
                this.currentParam.page = params.currentPage;

                this.updateSyslogList();
            },

            onPerPageChange(params) {
                this.currentParam.page = 0;
                this.currentParam.size = params.currentPerPage;

                this.updateSyslogList();
            },
            onSortChange(params) {
                this.currentParam.page = 0;
                this.currentParam.sort = "";
                for(let param of params){
                    this.currentParam.sort += `${param.field},${param.type}`;
                    break;
                }

                this.updateSyslogList();
            },
            addHistory: function() {
                if(this.$data.newHistoryDescription.length < 1) {
                    return;
                }

                let history = {
                    id:0,
                    assetId:this.item.id,
                    description:this.$data.newHistoryDescription,
                }
                let self = this;

                addAssetHistory(history)
                .then(function(data) {
                    if(data.success == true) {
                        self.$data.newHistoryDescription = '';
                        if(self.$data.histories.length == 0) {
                            self.$data.histories.push(data.item);
                        } else {
                            self.$data.histories.unshift(data.item);
                        }
                    } else {
                        console.log("err " + data.message);
                    }
                })
                .catch(function(err) {
                    console.log("Failed to create " + err);
                    if(err.response != undefined) {
                        console.log(err.response);
                    }
                })
            },
            getHistory: function() {
                console.log("get history");
                let self = this;
                this.$data.histories = [];
                getAssetHistory(this.item.id)
                .then(function(data) {
                    if(data.success) {
                        self.$data.histories = data.item;
                    } else {
                        console.log(data.message);
                    }
                })
                .catch(function(err) {
                    console.log("err at getting history " + err);
                    if(err.response != undefined) {
                        console.log(err.response);
                    }
                })
            },
            getDetailPrice(){
                if(this.item.content == null || this.item.content === ""){
                    // this.detailPrice = newBikePrice()
                    this.$data.useBikePrice = false;
                } else {
                    this.detailPrice = JSON.parse(this.item.content)
                    this.$data.useBikePrice = true;
                }

                // console.log(this.detailPrice);
            },
            toggleBikePrice: function() {
                if(!this.isEditMode) {
                    return;
                }
                this.$data.useBikePrice = !this.$data.useBikePrice;
                if(this.$data.useBikePrice) {
                    /**
                     * Disable userBikePrice
                     */
                    this.detailPrice = newBikePrice()

                } else {
                    this.detailPrice = [];

                }
            },


            /**
             * Get pending orders if available
             * This list will be used for admin to return bike and finish pending session
             */
            getPendingOrders: function() {
                let self = this;
                getAssetPendingOrders(this.item.id)
                .then(function(data) {
                    console.log(data);
                    if(data.success == true) {
                        self.pendingOrders = data.list;
                    } else {
                        console.log("No pending hirings");
                    }
                })
                .catch(function(err) {

                })
            },
            forceToFinishWithAdditionalCharge: function(id) {
                this.$data.additionalChargeItem = {
                    title:'',
                    due:0,
                    orderId: id,
                }

            },
            cancelAdditionalCharge: function() {
                this.$data.additionalChargeItem = false;
            },
            processAdditionalCharge: function() {
                if(this.$data.additionalChargeItem.title === '' || this.$data.additionalChargeItem.title.length == 0) {
                    window.alert("추가 요금 이름을 입력해주세요");
                    return;
                }
                if(this.$data.additionalChargeItem.due == 0) {
                    window.alert("추가 요금액을 입력해주세요");
                    return;
                }
                let id = this.$data.additionalChargeItem.orderId;
                let additionalChargeTitle = this.$data.additionalChargeItem.title;
                let additionalCharge = Number(this.$data.additionalChargeItem.due);
                this.$data.additionalChargeItem = false;
                console.log("Start turning off " + additionalChargeTitle + " " + additionalCharge);
                this.turnOnOffBike(id, false, additionalChargeTitle, additionalCharge);
            },
            forceToFinish: function(id) {

                this.turnOnOffBike(id, false);
            },
            turnOnOffBike: function(orderId, onOff, title = false, due = 0) {
                let self = this;
                console.log("turnOnOffBike " + title + " due:" + due);
                EventBus.$emit("showLoading", true, onOff==false?'잠그는중':'잠금해제중');
                sendCommandToBike(this.item.fullCode, onOff==false?'lock':'unlock')
                .then(function(data) {
                    console.log("sendCommandToBike");
                    console.log(data);
                    if(data.success) {
                        console.log("command sent")
                        self.retrieveBikeOnOff(orderId, onOff, 10, title, due);
                    } else {
                        EventBus.$emit("showLoading", false);
                        console.log("failed to send command " + data.message);
                    }
                })
                .catch(function(err) {
                    EventBus.$emit("showLoading", false);
                    console.log("Error sedning command " + err);
                    if(err.response != undefined) {
                        console.log(err.response);
                    }
                })
            },

            retrieveBikeOnOff: function(orderId, onOff, count, title = false, due = 0) {
                let self = this;
                console.log("polling bike info " + count);
                console.log("retrieveBikeOnOff " + title + " due:" + due);
                if(count == 0) {
                    EventBus.$emit("showLoading", false);
                    return;
                }
                EventBus.$emit("showLoading", true, '응답 기다리는 중');
                getAssetById(this.item.id)
                .then(function(data) {
                    if(data.success == true) {
                        console.log(data.item);
                        if (data.item.onOff == onOff) {
                            console.log("OK");
                            self.returnHire(orderId, title, due);
                        } else {
                            setTimeout(self.retrieveBikeOnOff, 1000, orderId, onOff, count-1, title, due);
                        }
                    } else {
                        EventBus.$emit("showLoading", false);
                        console.log("Failed to check bike " + data.message);
                    }
                })
                .catch(function(err) {
                    EventBus.$emit("showLoading", false);
                    console.log("Error getting bike info " + err);
                    if(err.response != undefined) {
                        console.log(err.response);
                    }
                })
            },
            returnHire: function(orderId, title = false, due = 0) {
                let self = this;
                console.log("returnHire " + title + " due:" + due);
                EventBus.$emit("showLoading", true, '대여 끝내는 중');
                finishPendingOrder(orderId, title, due)
                .then(function(data) {
                    if(data.success == true) {
                        EventBus.$emit("showLoading", false);
                        let pendingOrder = data.item;
                        for(let i = 0 ; i < self.$data.pendingOrders.length ; i++) {
                            if(self.$data.pendingOrders[i].id == pendingOrder.id) {
                                if(self.$data.pendingOrders[i].user != null && self.$data.pendingOrders[i].user != undefined) {
                                    let user = JSON.parse(JSON.stringify(self.$data.pendingOrders[i].user));
                                    pendingOrder.user = user;
                                }
                                self.$data.pendingOrders.splice(i, 1, pendingOrder);
                                // let msgToSend = {
                                //     "orderId": pendingOrder.id,
                                //     "command": "refresh",
                                // }

                                console.log("강제 반납 완료")
                                self.$modal.show('dialog', {
                                    title: '',
                                    text: "자전거 강제 반납이 완료되었습니다.",
                                    buttons: [
                                        {
                                            title: '확인',
                                            handler: () => {
                                                self.$modal.hide('dialog');
                                            }
                                        },
                                    ]
                                });
                                // self.$emit("sendMessage", "maryapp/order/" + pendingOrder.id, JSON.stringify(msgToSend));
                                return;
                            }
                        }
                    } else {
                        console.log("Failed to finish pending order " + data.message);
                    }
                })
                .catch(function(err) {
                    EventBus.$emit("showLoading", false);
                    console.log("Error finishing pending order " + err);
                    if(err.response != undefined) {
                        console.log(err.response);
                    }
                })
            },
            dateRangeSelected: function() {
                console.log("dateRangeSelected")
                console.log(this.$data.dateRange)

                if(this.$data.dateRange.length !== 2) {
                    return;
                }

                if(this.$data.dateRange[0] > this.$data.dateRange[1]) {
                    let temp = this.$data.dateRange[1];
                    this.$data.dateRange[0] = this.$data.dateRange[1];
                    this.$data.dateRange[1] = temp;
                }

            },
        }
    }
</script>
<style>
    .toggle-button-container {
        height: 50px;
    }
    .toggle-title {
        background: #bbb;
        padding:10px;
    }
    .toggle-title.activated {
        background: #42b983;
        color: white;
    }
    .border-box {
        border: 1px solid gray;
        padding:10px;
    }
</style>
