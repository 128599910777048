<template>
    <div class="list-detail-view">
        <DetailLayout
            :fullScreen="true"
            :on-detail="detailItem !== null">

<!--            <template v-slot:list-title-text>-->
<!--                <div v-if="operators.length > 1">-->
<!--                    <label>{{$t('operator.select')}}</label>-->
<!--                    <select v-model="selectedOperatorId" @change="onOperatorSelected">-->
<!--                        <option v-for="o in operators" :value="o.value">{{o.name}}</option>-->
<!--                    </select>-->
<!--                </div>-->
<!--            </template>-->
            <template v-slot:list-items>
                <div style="width: 100%; display: flex;">
                    <date-picker v-model="dateRange" valueType="format" @change="dateRangeSelected" range></date-picker>
                    <select v-model="selectedOperatorId" @change="onOperatorSelected">
                        <option v-for="o in operators" :value="o.value">{{o.name}}</option>
                    </select>
                    <input v-if="superAdmin === true"  type="text" v-model="bikeWord" style="width:100px; margin-left:20px;" placeholder="자전거 이름"/>
                    <div class="selectable" style="" v-for="t in salesTypes" v-if="t.superUser === false || (t.superUser === true && superAdmin === true) " v-bind:class="{'selected': t.selected == true}" v-on:click="t.selected = !t.selected">{{t.name}}</div>

                    <button class="btn btn-primary" style="margin-left: 10px;" v-on:click="getReportList">가져오기</button>

                    <button v-if="superAdmin === true" class="btn btn-danger" style="margin-left: 10px;" v-on:click="getUnpaidList">미결제건 가져오기</button>
                    <button v-if="superAdmin === true" class="btn btn-success" style="margin-left: 10px;" v-on:click="getReportExcel"><i class="fas fa-file-excel"></i> 엑셀</button>

                    <input v-if="superAdmin === true" type="text" v-model="searchWord" style="width:200px; margin-left:20px;" @change="searchWithWord" placeholder="이름/전화번호 입력후 엔터"/>

                </div>
<!--                <input type="date" v-model="dateFrom"/><input type="date" v-model="dateTo"/>-->

                <table class="table">
                    <tr>
                        <th>#</th>
                        <th>Order ID</th>
                        <th>사업자</th>
                        <th v-if="superAdmin == true">고객</th>
                        <th v-if="superAdmin == true">전화번호</th>
                        <th>자전거</th>
                        <th>Status</th>
                        <th>Type</th>
                        <th v-if="superAdmin == true" class="text-right">Bill</th>
                        <th class="text-right">Paid</th>
                        <th>Paid time</th>
                        <th v-if="superAdmin == true">Start</th>
                        <th v-if="superAdmin == true">End</th>
                        <th>보험사</th>
                        <th class="text-right">보험료</th>
                        <th>S</th>
                        <th>E</th>
                    </tr>
                    <tbody>
                    <tr v-for="(item, index) in items" v-on:click="onItemSelected(item)" :class="rowStyleClassFn(item)">
                        <td>{{index + 1}}</td>
                        <td>{{item.id}}</td>
                        <td>{{item.operatorId}}</td>
                        <td v-if="superAdmin == true" style="text-align: left;">{{item.userName}}</td>
                        <td v-if="superAdmin == true">{{item.userPhoneNumber}}</td>
                        <td>{{item.assetName}}</td>
                        <td>{{item.state}}</td>
                        <td><span v-html="item.typeString"/></td>
                        <td v-if="superAdmin == true" class="text-right">{{item.finalDue}}</td>
                        <td class="text-right">{{item.paid}}</td>
                        <td>{{item.transactionTime}}</td>
                        <td v-if="superAdmin == true">{{item.startTime}}</td>
                        <td v-if="superAdmin == true">{{item.toTime}}</td>
                        <td>{{item.insurer}}</td>
                        <td class="text-right">{{item.insurancePremium}}</td>
                        <td><span v-html="item.insuranceStarted"/></td>
                        <td><span v-html="item.insuranceFinished"/></td>

                    </tr>
                    </tbody>
                </table>

            </template>
        </DetailLayout>

        <div class="fullscreen-background" style="background:white" v-if="orderItem != false">

            <div class="container-fluid h-100">
                <div class="row h-100">
                    <div class="col-6 h-100">
                        <div class="info-container text-left">
                            <h3>
                                <span v-if="orderItem.originalOrderId > 0">환불 정보</span>
                                <span v-else>이용 / 결제 정보</span>
                                <span v-if="orderItem.refundId > 0" v-on:click="getSaleItemById(orderItem.refundId)">(환불 #{{orderItem.refundId}})</span>
                                <span style="float:right"><div class="mary-button" v-on:click="closeDetail"><i class="fas fa-times"></i> 닫기</div></span></h3>
                            <table class="text-left">
                                <tr v-if="orderItem.originalOrderId > 0">
                                    <td>환불 대상 매출</td>
                                    <td><span v-on:click="getSaleItemById(orderItem.originalOrderId)"> #{{orderItem.originalOrderId}}</span></td>
                                </tr>
                                <tr v-if="orderItem.refundId > 0">
                                    <td>환불 기록</td>
                                    <td><span v-on:click="getSaleItemById(orderItem.refundId)">#{{orderItem.refundId}}</span></td>
                                </tr>
                                <tr>
                                    <td>#</td>
                                    <td>{{orderItem.id}}</td>
                                </tr>
                                <tr>
                                    <td>사업자</td>
                                    <td>{{operatorMap[orderItem.operatorId]}}</td>
                                </tr>

                                <tr>
                                    <td>종류</td>
                                    <td>{{orderItem.typeString}}</td>
                                </tr>
                                <tr>
                                    <td>이름</td>
                                    <td>{{orderItem.user.name}}</td>
                                </tr>
                                <tr>
                                    <td>전화번호</td>
                                    <td>{{orderItem.user.phoneNumber}}</td>
                                </tr>
                                <tbody v-if="orderItem.type === 'hire'">
                                <tr>
                                    <td>자전거</td>
                                    <td>{{orderItem.assetName}}</td>
                                    <td>MQTT Ch {{orderItem.channel}}</td>
                                    <td>#{{orderItem.assetId}}</td>
                                </tr>
                                <tr>
                                    <td>대여시간</td>
                                    <td>{{timeString(orderItem.startTime)}}</td>
                                    <td>{{timeString(orderItem.toTime)}}</td>
                                    <td>{{Math.round(orderItem.duration/1000/60)}}분</td>
                                </tr>

                                <tr v-for="child in orderItem.list" v-if="child.type != 'insurance'">
                                    <td>{{child.type}}</td>
                                    <td>{{timeString(child.startTime)}}</td>
                                    <td>{{timeString(child.toTime)}}</td>
                                    <td>{{Math.round(child.duration/1000/60)}}분</td>
                                    <td>{{child.due}}원</td>
                                </tr>
                                <tr>
                                    <td>이용거리</td>
                                    <td>{{orderItem.distance}}m</td>
                                </tr>

                                <tr>
                                    <td>보험사</td>
                                    <td>
                                        {{orderItem.insurer}}
                                    </td>
                                </tr>
                                <tr>
                                    <td>보험시작</td>
                                    <td>
                                        <span v-if="orderItem.insuranceStarted == true" class="positive"><i class="fas fa-check"></i></span>
                                        <span v-if="orderItem.insuranceStarted == false" class="negative"><i class="fas fa-times"></i></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>보험종료</td>
                                    <td>
                                        <span v-if="orderItem.insuranceFinished == true" class="positive"><i class="fas fa-check"></i></span>
                                        <span v-if="orderItem.insuranceFinished == false" class="negative"><i class="fas fa-times"></i></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>보험료</td>
                                    <td>
                                        {{orderItem.insurancePremium}} 원
                                    </td>
                                </tr>
                                </tbody>
                                <tbody>
                                <tr v-if="orderItem.pointUsed > 0">
                                    <td>포인트 사용</td>
                                    <td>{{orderItem.pointUsed}}</td>
                                </tr>
                                <tr v-if="orderItem.couponUsed > 0">
                                    <td>쿠폰 사용</td>
                                    <td>{{orderItem.couponUsed}}</td>
                                </tr>
                                <tr v-if="orderItem.deductAmount > 0">
                                    <td>할인금액</td>
                                    <td>{{orderItem.deductAmount}}</td>
                                </tr>
                                <tr>
                                    <td>총액</td>
                                    <td>{{orderItem.finalDue}}</td>
                                </tr>
                                <tr>
                                    <td>총결제액</td>
                                    <td>{{orderItem.paid}}</td>
                                </tr>
                                <tr v-if="orderItem.finalDue > 0 && orderItem.paid == 0">
                                    <td></td>
                                    <td><div class="mary-button" v-on:click="takePayment(orderItem.id)"><i class="fas fa-credit-card"></i> 강제결제</div></td>
                                </tr>
                                <tr>
                                    <td>transactionId</td>
                                    <td>{{orderItem.transactionId}}</td>
                                </tr>
                                <tr>
                                    <td>결제시간</td>
                                    <td>{{timeString(orderItem.transactionTime)}}</td>
                                </tr>


                                </tbody>
                            </table>
                            <template v-if="orderItem.state == 1 || orderItem.state == 0">
                                <h3><span style="float:left"><div class="mary-button" v-on:click="cancelOrder(orderItem)"><i class="fas fa-times"></i>자전거 대여 세션 취소</div></span></h3>
                                *진행중인 대여 세션인 경우 취소후 자전거 상태를 별도로 재설정 해야 합니다.
                            </template>
                            <template v-if="orderItem.paid > 0 && orderItem.refundId == 0">
                            <h3 v-if="orderItem.type === 'hire' && orderItem.paid > 0"><span style="float:left"><div class="mary-button" v-on:click="refundHire(orderItem)"><i class="fas fa-times"></i> 결제 취소</div></span></h3>
                            <h3 v-if="orderItem.type === 'coupon' && orderItem.paid > 0"><span style="float:left"><div class="mary-button" v-on:click="refundCoupon(orderItem)"><i class="fas fa-times"></i> 쿠폰 결제 취소</div></span></h3>
                            </template>


                            <template v-if="orderItem.placeItems != null && orderItem.placeItems.length > 0">
                                <hr/>
                                <h3>플레이스 아이템</h3>
                                <div style="width: 100%; padding:10px;display: inline-block;">
                                    <div v-for="placeItem in orderItem.placeItems" style="margin:10px; padding: 10px; float:left; border: 1px solid black; background-color: white; border-radius: 5px; box-shadow: 3px 3px 5px grey;">
                                        <h4>[{{placeItem.id}}] {{placeItem.title}} {{placeItem.count}}명</h4>
                                        <div>{{placeItem.itemDate}} {{placeItem.itemTime}}</div>
                                        <div>Payout: {{placeItem.payout}}</div>
                                        <div>상태 : {{placeItem.userStateString}}</div>

                                        <button class="btn btn-danger" v-if="placeItem.state >= 0" @click="showCancelPlaceItemDialog(placeItem)">취소하기</button>
                                    </div>
                                </div>
                            </template>

                            <template v-if="orderItem.coupons != null && orderItem.coupons.length > 0">
                                <hr/>
                                <h3>쿠폰</h3>
                                <div style="width: 100%; padding:10px;display: inline-block;">
                                    <div v-for="coupon in orderItem.coupons" style="margin:10px; padding: 10px; float:left; border: 1px solid black; background-color: white; border-radius: 5px; box-shadow: 3px 3px 5px grey;">
                                        <h4>[{{coupon.id}}]{{coupon.title}}</h4>

                                        <div>{{coupon.reference}}</div>
                                        <div v-if="coupon.refunded == true">
                                            환불 완료 {{timeString(coupon.refundedtime)}}
                                        </div>
                                        <div v-if="coupon.used == 1">
                                            사용된시간 : {{timeString(coupon.usedTime)}}
                                        </div>
                                        <div>
                                            유효기간: {{timeString(coupon.validToTime)}}
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <img v-if="orderItem.imageUrl != null && orderItem.imageUrl.length > 0" :src="'https://storage.googleapis.com/ncm-mary-254508.appspot.com/ncm-image/' + orderItem.imageUrl" width="100%"/>
                        </div>
                    </div>
                    <div class="col-6 h-100">
                        <div class="info-container" >
                            <h5><button class="btn btn-primary" v-on:click="getOrderSysLog(orderItem)">Sys Log</button>  <button class="btn btn-success" v-on:click="getOrderHistory(orderItem)">이력</button></h5>
                            <template v-if="orderItem.logMode == 0">
                            <table class="table" v-if="orderItem.logs != null">
                                <tbody >
                                <tr style="position:sticky; top:0;">
                                    <th></th>
                                    <th>시간</th>
                                    <th><span><i class="fas fa-map-pin"></i></span></th>
                                    <th><span><i class="fas fa-battery-half"></i></span></th>
                                    <th><span><i class="fas fa-route"></i></span></th>
                                    <th>지역</th>
                                    <th><span><i class="fas fa-parking"></i></span></th>
                                </tr>
                                </tbody>
                                <tbody>
                                    <tr v-for="log in orderItem.logs" v-bind:class="{'iot-log': log.source !== 'Android' && log.source !== 'iOS'}">
<!--                                        <td>{{log.id}}</td>-->
                                        <td><span v-if="log.source === 'Android'"><i class="fab fa-android"></i></span><span v-else-if="log.source === 'iOS'"><i class="fab fa-apple"></i></span> <span v-else style="color:blue;"><i class="fas fa-bicycle"></i></span></td>
                                        <td>{{timeString(log.createdWhen)}}</td>

                                        <td>{{log.lat}}<br>{{log.lon}}</td>
                                        <td>{{log.battery}}%</td>
                                        <td>{{log.distance}}</td>
                                        <td><span><i class="fas" v-bind:class="{'fa-check positive':log.inside == true, 'fa-times negative':log.inside == false}"></i></span></td>
                                        <td><span><i class="fas" v-bind:class="{'fa-check positive':log.insideParkingArea == true, 'fa-times negative':log.insideParkingArea == false}"></i></span></td>
                                    </tr>
                                </tbody>
                            </table>
                            </template>
                            <template v-if="orderItem.logMode == 1">
                            <table class="table" v-if="orderItem.histories != undefined && orderItem.histories != null">
                                <tr>
                                    <th>시간</th>
                                    <th>User</th>
                                    <th>내용</th>
                                </tr>
                                <tr v-for="h in orderItem.histories">
                                    <td>{{timeString(h.createdWhen)}}</td>
                                    <td>[{{h.userId}}] {{h.user != null?h.user.name:h.userId}}</td>
                                    <td class="text-left" v-html="h.description"></td>
                                </tr>

                            </table>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div style="position:fixed; top:0; left: 0; width: 100%; height: 100%; background-color: rgba(0,0,0,0.4);" v-if="cancelPlaceItem != false" @click="cancelPlaceItem = false">
            <div style="position: absolute; top:50%; left: 50%; width: 500px; max-width: 100%; transform: translate(-50%, -50%); background-color: white; border-radius: 20px; padding:20px;" v-on:click.stop="dummy">
                <h4>플레이스 예약 취소</h4>

                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <h5>{{cancelPlaceItem.item.title}} x {{cancelPlaceItem.item.count}}명</h5>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            날짜: {{cancelPlaceItem.item.itemDate}}
                            <br>
                            시간: {{cancelPlaceItem.item.itemTime}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">환불 비율 방식</div>
                        <div class="col-6">
                            <select v-model="cancelPlaceItem.type">
                                <option value="select">선택</option>
                                <option value="rate">%</option>
                                <option value="amount">금액 입력</option>
                            </select>
                        </div>
                    </div>
                    <div class="row" v-if="cancelPlaceItem.type === 'rate'">
                        <div class="col-6">환불 %</div>
                        <div class="col-6">
                            <select v-model="cancelPlaceItem.rate" class="form-control">
                                <option v-for="r in 100" :value="r">{{r}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="row" v-if="cancelPlaceItem.type === 'amount'">
                        <div class="col-6">환불 금액</div>
                        <div class="col-6">
                            <input type="number" class="form-control" v-model="cancelPlaceItem.amount">
                        </div>
                        <div class="col-6">환불 payout</div>
                        <div class="col-6">
                            <input type="number" class="form-control" v-model="cancelPlaceItem.payout">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            예약 확정전일경우 결제항목이 없어서, 전액 환불 됩니다.
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <button class="btn btn-dark form-control" @click="cancelItem = false">닫기</button>
                        </div>
                        <div class="col-6">
                            <button class="btn btn-danger form-control" @click="cancelPlaceItemSale">예약 취소하기</button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import Item from "../../components/contents/coupon/Item";
    import DropdownInput from "../../inputs/DropdownInput";
    import Editor from "../../components/contents/coupon/Editor";
    import {getOperatorList, getOperatorMap} from "../../api/operator";
    import {defaultPagination, getBlob, showDialog, urlGetRequest} from "../../api/common";
    import {
        getOrderById,
        searchOrderListByText,
        getOrderReport,
        getOrderSysLog,
        getSaleItem,
        makePayment, hireRefund, couponRefund, cancelSales, getOrderHistory
    } from "../../api/order";
    import TabsView from "../TabsView";
    import SortAndSearchInput from "../../inputs/SortAndSearchInput";
    import OrderEditor from "../../components/contents/order/Editor";
    import OrderItem from "../../components/contents/order/Item";
    import DetailLayout from "../DetailLayout";
    import {getNormalUsers} from "../../api/user";

    import {PulseLoader} from 'vue-spinner/dist/vue-spinner.js'
    import {millisToReadableWithoutSecond, millisToReadable} from "../../utils/TimeConverter";
    import {getAssets} from "../../api/asset";
    import {getOperatorId, isSuperAdmin} from "../../store";
    import EventBus from "../../api/event-bus";
    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    import {cancelPlaceItem} from "../../api/placeItem";

    export default {
        name: "OrderView",
        components: {
            DetailLayout,
            OrderItem,
            OrderEditor,
            SortAndSearchInput,
            TabsView,
            DropdownInput,

            PulseLoader,

            Item,
            Editor,

            DatePicker,



        },
        data() {
            return {
                modelKey: "order",
                operators:[],
                selectedOperatorId: getOperatorId(),
                isLoaded: false,
                // itemsToShow: 5,
                items: [],
                detailItem: null,
                dateRange:null,
                stateMap: {
                    0: '사용',
                    1: '사용종료',
                    2: '결제완료',
                    5: '완료',
                    7: '취소',
                    8: '환불',
                },

                columns: [
                    {field: 'id', label: '#'},
                    {field: 'operatorId', label: '사업자'},
                    {field: 'userName', label:'이름'},
                    {field: 'userPhoneNumber', label: '고객 전화번호'},
                    {field: 'assetName', label: '자전거'},
                    {field: 'state', label: this.$t('order.state')},
                    {field: 'typeString', label: this.$t('order.type'), html:true},
                    {field: 'finalDue', label: this.$t('order.finalDue')},
                    {field: 'paid', label: this.$t('order.paid')},
                    {field: 'transactionTime', label: this.$t('order.paidTime')},
                    {field: 'startTime', label: this.$t('order.start')},
                    {field: 'toTime', label: this.$t('order.end')},
                    {field: 'insurer', label: '보험사'},
                    {field: 'insurancePremium', label: '보험료'},
                    {field: 'insuranceStarted', label:'보험시작', html:true},
                    {field: 'insuranceFinished', label:'보험종료', html:true},
                ],
                totalRows: 0,

                currentParam: defaultPagination(),
                currentKeyword: "",
                currentPaginationOption: {
                    enabled: true,
                    perPage: 100,
                    perPageDropdown: [15, 25, 50, 100, 250, 500],
                    dropdownAllowAll: false,
                },

                operatorMap: {},
                userMap: {},
                bikeMap: {},

                isAddMode: false,


                dateFrom:'',
                dateTo:'',

                orderItem:false,

                searchWord:'',
                bikeWord:'',
                salesTypes:[
                    {name:'자전거', value:'hire,refund', selected:false, superUser:false},
                    {name:'쿠폰', value:'coupon,coupon_refund', selected:false, superUser:false},
                    {name:'플레이스', value:'place,place_refund', selected:false, superUser:true},
                    {name:'마켓', value:'market,place_refund', selected:false, superUser:true},
                    {name:'카드등록', value:'setup_payment,setup_payment_cancel,', selected:false, superUser:true},
                ],
                superAdmin:false,
                cancelPlaceItem: false,
            }
        },
        created: async function() {
            this.$data.superAdmin = isSuperAdmin();
            await this.loadInitialData();
            this.operators = await getOperatorList();
            this.selectedOperatorId = getOperatorId();
            // this.updateListView();
            this.updateEditorView();
        },
        methods: {
            dummy: function() {

            },
            rowStyleClassFn(row) {
                if(row.type === 'hire') {
                    if(row.finalDue > 0 && row.paid == 0) {
                        return 'report-row bike-row not-paid';
                    }
                    if(row.state == '사용') {
                        return 'report-row bike-row in-use';
                    }
                    return 'report-row bike-row';
                } else if(row.type === 'coupon') {
                    return 'report-row coupon-row';
                } else if(row.type === 'setup_payment' || row.type === 'setup_payment_cancel') {
                    return 'report-row card-row';
                }
                return 'report-row';
            },
            timeString: millisToReadable,
            onOperatorSelected: function() {
                let operatorId = this.selectedOperatorId;
                // this.updateListView();
            },
            setItemNaming(item){
                console.log(item);
                item.assetId = this.bikeMap[item.assetId];
                item.operatorId = this.operatorMap[item.operatorId];
                item.userId = this.userMap[item.userId];
                item.state = this.stateMap[item.state];

                if(item.type === 'hire') {
                    item.typeString = '<span><i class="fas fa-bicycle" style="color:#F67390;"></i></span>';

                } else if(item.type === 'coupon') {
                    item.typeString = '<span><i class="fas fa-tag" style="color:blue;"></i></span>';
                } else if(item.type === 'setup_payment_cancel') {
                    item.typeString = '<span><i class="fas fa-credit-card" style="color:red;"></i></span>';
                } else if(item.type === 'setup_payment') {
                    item.typeString = '<span><i class="fas fa-credit-card" style="color:green;"></i></span>';
                } else if(item.type === 'place') {
                    item.typeString = '<span><i class="fas fa-map-marker-alt" style="color:green;"></i></span>';
                } else if(item.type === 'place_refund') {
                    item.typeString = '<span><i class="fas fa-map-marker-alt" style="color:red;"></i></span>';
                } else {
                    item.typeString = item.type;
                }
                // console.log(item);
                if(item.user != undefined && item.user != null && item.user.name != undefined) {
                    item.userName = item.user.name;
                    console.log(item.userName);
                } else {
                    item.userName = '';
                }
                if(item.user != undefined && item.user != null && item.user.phoneNumber != undefined) {
                    item.userPhoneNumber = item.user.phoneNumber;
                    console.log(item.userPhoneNumber);
                } else {
                    item.userPhoneNumber = '';
                }

                if(item.transactionTime > 0) {
                    item.transactionTime = millisToReadableWithoutSecond(item.transactionTime);
                } else {
                    item.transactionTime = "";
                }
                if(item.type === 'hire') {
                    item.startTime = millisToReadableWithoutSecond(item.startTime);
                    item.toTime = millisToReadableWithoutSecond(item.toTime);
                    item.insuranceStarted = item.insuranceStarted == true ? '<span><i class="fas fa-check" style="color:green;"></i></span>' : '<span><i class="fas fa-times" style="color:red;"></i></span>';
                    item.insuranceFinished = item.insuranceFinished == true ? '<span><i class="fas fa-check" style="color:green;"></i></span>' : '<span><i class="fas fa-times" style="color:red;"></i></span>';
                } else {
                    item.startTime = "";
                    item.startTo = "";
                    item.insuranceStarted = "";
                    item.insuranceFinished = "";
                }


                return item;
            },
            updateListView: function() {
                let that = this;

                that.isLoaded = false;
                that.items = [];
                that.totalRows = 0;
                searchOrderListByText(this.currentKeyword, this.selectedOperatorId, this.currentParam).then(function(data){
                    if(data.success) {
                        that.totalRows = data.totalElements;

                        that.items = [];
                        for (let e of data.list) {
                            let item = that.setItemNaming(e);

                            that.items.push(item);
                        }
                    }

                    that.isLoaded = true
                })
            },
            updateEditorView: function() {
                this.detailItem = null;

                let that = this;
                if (this.$route.query.hasOwnProperty('id')) {
                    let contentId = this.$route.query.id;

                    getOrderById(contentId).then(function(item) {
                        that.detailItem = item;
                    });
                }
            },
            loadInitialData: async function() {
                this.operatorMap = await getOperatorMap();
                // this.bikeMap = {};
                //
                // let users = [];
                // await getNormalUsers().then(data => {
                //     if(data.success){
                //         users = data.list;
                //     }
                // });
                //
                // for(let e of users){this.userMap[e.id] = e.phoneNumber}
                //
                //
                //
                // let that = this;
                // await getAssets("bike", getOperatorId() )
                //     .then(list => {
                //         for(let bike of list){
                //             that.bikeMap[bike.id] = bike.name;
                //         }
                //     })
            },
            onClickAddBtn: function() {
              this.$router.push('/admin/order?new');
            },
            clearDetail(){
                this.detailItem = null;
            },
            onSearchOrder(params){
                this.currentKeyword = params.searchTerm;

                this.updateListView();
            },
            onSortChange(params){
                this.currentParam.page = 0;
                this.currentParam.sort = "";
                for(let param of params){
                    this.currentParam.sort += `${param.field},${param.type}`;
                    break;
                }

                this.updateListView();
            },
            onPageChange(params) {
                this.currentParam.page = params.currentPage - 1;

                this.updateListView();
            },
            onPerPageChange(params) {
                this.currentParam.page = 0;
                this.currentParam.size = params.currentPerPage;

                this.updateListView();
            },
            getReport: function () {
                if(this.$data.dateFrom == null || this.$data.dateFrom.length == 0 || this.$data.dateFrom === '0000-00-00') {
                    window.alert("시작날짜를 선택하세요");
                    return;
                }
                if(this.$data.dateTo == null || this.$data.dateTo.length == 0 || this.$data.dateTo === '0000-00-00') {
                    window.alert("마지막날짜를 선택하세요");
                    return;
                }

                if(this.$data.dateFrom > this.$data.dateTo) {
                    window.alert("시작 마지막 날짜가 맞지 않습니다.");
                    return;
                }
                let start = new Date(this.$data.dateFrom);
                start.setHours(0,0,0,0);
                let end = new Date(this.$data.dateTo);
                end.setHours(23,59,59,999);

                const FileDownload = require('js-file-download');
                let self = this;
                getOrderReport(start.getTime(), end.getTime(), this.selectedOperatorId)
                .then(function(response) {
                    FileDownload(response.data, self.$data.dateFrom +'-' + self.$data.dateTo + "-report.xlsx");
                })
                .catch(function(err) {
                    console.log("report error " + err);
                    if(err.response != undefined) {
                        console.log(err.response);
                    }
                })

            },
            closeDetail: function() {
              this.$data.orderItem = false;
            },
            onRowClick: function(params) {
                console.log("onRowClick");
                console.log(params);
                let self = this;
                if(params.row != undefined) {
                    let id = params.row.id;
                    getSaleItem(id)
                    .then(function(data) {
                        if(data.success == true) {
                            if(data.item.type === 'hire') {
                                data.item.typeString = '자전거대여';
                            } else if(data.item.type === 'coupon') {
                                data.item.typeString = '쿠폰';
                            } else if(data.item.type === 'setup_payment_cancel') {
                                data.item.typeString = '카드등록 결제취소';
                            } else if(data.item.type === 'setup_payment') {
                                data.item.typeString = '카드등록 결제';
                            } else {
                                data.item.typeString = data.item.type;
                            }
                            data.item.logMode = 0;
                            data.item.histories = [];
                            self.$data.orderItem = data.item;
                            // console.log(data.item);
                        } else {

                        }
                    })
                    .catch(function(err) {

                    })
                }
            },
            getOrderHistory: function(order) {
                if(this.$data.orderItem == false) {
                    return;
                }

                let self = this;
                if(this.$data.orderItem.logMode == 1 && this.$data.orderItem.histories.length > 0) {
                    return;
                }
                this.$data.orderItem.logMode = 1;
                if(this.$data.orderItem.histories.length > 0) {
                    return;
                }
                getOrderHistory(order.id)
                    .then(function(data) {
                        if(data.success == true) {
                            self.$data.orderItem.histories = data.item;
                            return;
                        } else {
                            window.alert(data.message);
                        }
                        self.$data.orderItem.histories = [];
                    })
                    .catch(function(err) {

                    })
            },
            getOrderSysLog: function(order) {
              if(this.$data.orderItem == false) {
                  return;
              }

              let self = this;
                if(this.$data.orderItem.logMode == 0 && this.$data.orderItem.logs.length > 0) {
                    return;
                }
              this.$data.orderItem.logMode = 0;
                if(this.$data.orderItem.logs.length > 0) {
                    return;
                }
              getOrderSysLog(order.id)
                .then(function(data) {
                    if(data.success == true) {
                        console.log(data.item);
                        self.$data.orderItem.logs = data.item;
                        return;
                    } else {
                        window.alert(data.message);
                    }
                    self.$data.orderItem.logs = [];
                })
                .catch(function(err) {

                })
            },
            takePayment: function(id) {
                let self = this;
                EventBus.$emit("showLoading", true, "결제 시도중입니다.");
                makePayment(id)
                .then(function(data) {
                    EventBus.$emit("showLoading", false);
                    if(data.success == true) {
                        if(data.item.type === 'hire') {
                            data.item.typeString = '자전거대여';
                        } else if(data.item.type === 'coupon') {
                            data.item.typeString = '쿠폰';
                        } else if(data.item.type === 'setup_payment_cancel') {
                            data.item.typeString = '카드등록 결제취소';
                        } else if(data.item.type === 'setup_payment') {
                            data.item.typeString = '카드등록 결제';
                        } else {
                            data.item.typeString = data.item.type;
                        }
                        self.$data.orderItem = data.item;
                        let tableItem = self.setItemNaming(data.item);
                        for(let i = 0 ; i < self.items.length ; i++) {
                            if(self.items[i].id == tableItem.id) {
                                self.items.splice(i, 1, tableItem);
                                return;
                            }
                        }
                    } else {
                        window.alert("결제 실패 " + data.message);
                    }
                })
                .catch(function(err) {
                    EventBus.$emit("showLoading", false);
                    let msg = err;
                    if(err.response != undefined) {
                        msg += ' (' + err.response + ')';
                    }
                    window.aler("결제 시도 에러 " + msg);
                })
            },
            cancelOrder: function(item) {
                if(item.type !== 'hire') {
                    window.alert("저전거 사용이 아닙니다.")
                    return;
                }
                let self = this;
                cancelSales(item.id)
                    .then(function(data) {
                        if(data.success == true) {
                            if(data.item.type === 'hire') {
                                data.item.typeString = '자전거대여';
                            } else if(data.item.type === 'coupon') {
                                data.item.typeString = '쿠폰';
                            } else if(data.item.type === 'setup_payment_cancel') {
                                data.item.typeString = '카드등록 결제취소';
                            } else if(data.item.type === 'setup_payment') {
                                data.item.typeString = '카드등록 결제';
                            } else {
                                data.item.typeString = data.item.type;
                            }
                            self.$data.orderItem = data.item;
                            let tableItem = self.setItemNaming(data.item);
                            for(let i = 0 ; i < self.items.length ; i++) {
                                if(self.items[i].id == tableItem.id) {
                                    self.items.splice(i, 1, tableItem);
                                    return;
                                }
                            }
                            window.alert("취소되었습니다.")
                        } else {
                            window.alert(data.message);
                        }
                    })
                    .catch(function(err) {
                        let msg = "취소 처리 에러 " + err;
                        if(err.response != undefined) {
                            msg += ' (' + err.response + ')';
                        }
                        window.alert(msg);
                    })
            },
            refundHire: function(item) {
                if(item.type !== 'hire' && item.type !== 'place') {
                    window.alert("저전거 사용이 아닙니다.")
                    return;
                }
                hireRefund(item.id)
                .then(function(data) {
                    if(data.success == true) {
                        window.alert( data.item.paid + "원 환불처리 완료 되었습니다.")
                    } else {
                        window.alert(data.message);
                    }
                })
                .catch(function(err) {
                    let msg = "환불 처리 에러 " + err;
                    if(err.response != undefined) {
                        msg += ' (' + err.response + ')';
                    }
                    window.alert(msg);
                })
            },
            refundCoupon: function(item) {
                if(item.type !== 'coupon') {
                    window.alert("쿠폰 구매가 아닙니다.")
                    return;
                }
                couponRefund(item.id)
                    .then(function(data) {
                        if(data.success == true) {
                            window.alert( data.item.paid + "원 환불처리 완료 되었습니다.")
                        } else {
                            window.alert(data.message);
                        }
                    })
                    .catch(function(err) {
                        let msg = "환불 처리 에러 " + err;
                        if(err.response != undefined) {
                            msg += ' (' + err.response + ')';
                        }
                        window.alert(msg);
                    })
            },
            dateRangeSelected: function() {
                console.log("dateRangeSelected")
                console.log(this.$data.dateRange)

                if(this.$data.dateRange.length !== 2) {
                    return;
                }

                if(this.$data.dateRange[0] > this.$data.dateRange[1]) {
                    let temp = this.$data.dateRange[1];
                    this.$data.dateRange[0] = this.$data.dateRange[1];
                    this.$data.dateRange[1] = temp;
                }

            },
            getSaleItemById: function(id) {
                let self = this;
                getSaleItem(id)
                    .then(function(data) {
                        console.log(data);
                        if(data.success == true) {
                            if(data.item.type === 'hire') {
                                data.item.typeString = '자전거대여';
                            } else if(data.item.type === 'coupon') {
                                data.item.typeString = '쿠폰';
                            } else if(data.item.type === 'setup_payment_cancel') {
                                data.item.typeString = '카드등록 결제취소';
                            } else if(data.item.type === 'setup_payment') {
                                data.item.typeString = '카드등록 결제';
                            } else if(data.item.type === 'place') {
                                data.item.typeString = '플레이스 결제';
                            } else if(data.item.type === 'place_refund') {
                                data.item.typeString = '플레이스 환불';
                            } else {
                                data.item.typeString = data.item.type;
                            }
                            data.item.logMode = 0;
                            data.item.histories = [];
                            self.$data.orderItem = data.item;
                            // console.log(data.item);
                        } else {

                        }
                    })
                    .catch(function(err) {

                    })
            },
            onItemSelected: function(item) {
                if(this.$data.superAdmin == false) {
                    return;
                }
                let self = this;

                getSaleItem(item.id)
                    .then(function(data) {
                        console.log(data);
                        if(data.success == true) {
                            if(data.item.type === 'hire') {
                                data.item.typeString = '자전거대여';
                            } else if(data.item.type === 'coupon') {
                                data.item.typeString = '쿠폰';
                            } else if(data.item.type === 'setup_payment_cancel') {
                                data.item.typeString = '카드등록 결제취소';
                            } else if(data.item.type === 'setup_payment') {
                                data.item.typeString = '카드등록 결제';
                            } else {
                                data.item.typeString = data.item.type;
                            }
                            data.item.logMode = 0;
                            data.item.histories = [];
                            self.$data.orderItem = data.item;
                            // console.log(data.item);
                        } else {

                        }
                    })
                    .catch(function(err) {

                    })
            },
            searchWithWord: function() {
              if(this.$data.searchWord == null || this.$data.searchWord.length <= 2) {
                  return;
              }
                let query = "operatorId=" + this.$data.selectedOperatorId;
                query += "&searchWord=" + this.$data.searchWord;

                this.$data.items = [];
                let self = this;
                urlGetRequest("admin/sales/report/list?" + query)
                .then(function(data) {
                    console.log(data);
                    if(data.success == true) {
                        for (let e of data.item) {
                            let item = self.setItemNaming(e);

                            self.items.push(item);
                        }
                    } else {

                    }
                })
                .catch(function(err) {

                    console.log(err);
                    if(err.response != undefined) {
                        console.log(err.response);
                    }
                })
            },
            getUnpaidList: function() {
                if(this.$data.dateRange == null || this.$data.dateRange.length == 0) {
                    window.alert("기간을 선택해주세요");
                    return;
                }

                if(this.$data.dateRange.length == 2) {
                    if(this.$data.dateRange[0] > this.$data.dateRange[1]) {
                        let temp = this.$data.dateRange[1];
                        this.$data.dateRange[0] = this.$data.dateRange[1];
                        this.$data.dateRange[1] = temp;
                    }
                }

                let types = [];
                for(let i = 0 ; i < this.$data.salesTypes.length ; i++) {
                    if(this.$data.salesTypes[i].selected == true) {
                        types.push(this.$data.salesTypes[i].value);
                    }
                }
                if(types.length == 0) {
                    window.alert("판매 유형을 최소 1개 선택해주세요");
                    return;
                }

                let from = new Date(this.$data.dateRange[0]);
                from.setHours(0);
                from.setMinutes(0);
                from.setSeconds(0);
                from.setMilliseconds(0);



                let to = 0;
                if(this.$data.dateRange.length == 2) {
                    to = new Date(this.$data.dateRange[1]);
                    to.setHours(23);
                    to.setMinutes(59);
                    to.setSeconds(59);
                    to.setMilliseconds(999);
                } else {
                    to = new Date(this.$data.dateRange[0]);
                    to.setHours(0);
                    to.setMinutes(0);
                    to.setSeconds(0);
                    to.setMilliseconds(0);
                }
                let query = "dateFrom=" + from.getTime() + "&dateTo=" + to.getTime();

                this.$data.items = [];
                let self = this;
                urlGetRequest("admin/sale/unpaid?" + query)
                    .then(function(data) {
                        console.log(data);
                        if(data.success == true) {
                            for (let e of data.list) {
                                let item = self.setItemNaming(e);

                                self.items.push(item);
                            }
                        } else {

                        }
                    })
                    .catch(function(err) {

                        console.log(err);
                        if(err.response != undefined) {
                            console.log(err.response);
                        }
                    })
            },
            searchWithBikeName: function() {

            },
            getReportList: function() {
                if(this.$data.dateRange == null || this.$data.dateRange.length == 0) {
                    window.alert("기간을 선택해주세요");
                    return;
                }

                if(this.$data.dateRange.length == 2) {
                    if(this.$data.dateRange[0] > this.$data.dateRange[1]) {
                        let temp = this.$data.dateRange[1];
                        this.$data.dateRange[0] = this.$data.dateRange[1];
                        this.$data.dateRange[1] = temp;
                    }
                }

                let types = [];
                for(let i = 0 ; i < this.$data.salesTypes.length ; i++) {
                    if(this.$data.salesTypes[i].selected == true) {
                        types.push(this.$data.salesTypes[i].value);
                    }
                }
                if(types.length == 0) {
                    window.alert("판매 유형을 최소 1개 선택해주세요");
                    return;
                }

                let from = new Date(this.$data.dateRange[0]);
                from.setHours(0);
                from.setMinutes(0);
                from.setSeconds(0);
                from.setMilliseconds(0);



                let to = 0;
                if(this.$data.dateRange.length == 2) {
                    to = new Date(this.$data.dateRange[1]);
                    to.setHours(23);
                    to.setMinutes(59);
                    to.setSeconds(59);
                    to.setMilliseconds(999);
                } else {
                    to = new Date(this.$data.dateRange[0]);
                    to.setHours(0);
                    to.setMinutes(0);
                    to.setSeconds(0);
                    to.setMilliseconds(0);
                }
                let query = "operatorId=" + this.$data.selectedOperatorId;
                query += "&from=" + from.getTime() + "&to=" + to.getTime();
                query += "&types=" + types.join(",");

                this.$data.items = [];
                let self = this;
                if(this.$data.bikeWord !== null && this.$data.bikeWord.length > 3) {
                    query += "&bikeName=" + this.$data.bikeWord;
                }
                console.log("query " + query);
                urlGetRequest("admin/sales/report/list?" + query)
                .then(function(data) {
                    console.log(data);
                    if(data.success == true) {
                        for (let e of data.item) {
                            let item = self.setItemNaming(e);

                            self.items.push(item);
                        }
                    } else {

                    }
                })
                .catch(function(err) {

                    console.log(err);
                    if(err.response != undefined) {
                        console.log(err.repsonse);
                    }
                })


            },
            getReportExcel: function() {
                if(this.$data.dateRange == null || this.$data.dateRange.length == 0) {
                    window.alert("기간을 선택해주세요");
                    return;
                }

                if(this.$data.dateRange.length == 2) {
                    if(this.$data.dateRange[0] > this.$data.dateRange[1]) {
                        let temp = this.$data.dateRange[1];
                        this.$data.dateRange[0] = this.$data.dateRange[1];
                        this.$data.dateRange[1] = temp;
                    }
                }

                let types = [];
                for(let i = 0 ; i < this.$data.salesTypes.length ; i++) {
                    if(this.$data.salesTypes[i].selected == true) {
                        types.push(this.$data.salesTypes[i].value);
                    }
                }
                if(types.length == 0) {
                    window.alert("판매 유형을 최소 1개 선택해주세요");
                    return;
                }

                let from = new Date(this.$data.dateRange[0]);
                from.setHours(0);
                from.setMinutes(0);
                from.setSeconds(0);
                from.setMilliseconds(0);



                let to = 0;
                if(this.$data.dateRange.length == 2) {
                    to = new Date(this.$data.dateRange[1]);
                    to.setHours(23);
                    to.setMinutes(59);
                    to.setSeconds(59);
                    to.setMilliseconds(999);
                } else {
                    to = new Date(this.$data.dateRange[0]);
                    to.setHours(0);
                    to.setMinutes(0);
                    to.setSeconds(0);
                    to.setMilliseconds(0);
                }
                let query = "operatorId=" + this.$data.selectedOperatorId;
                query += "&from=" + from.getTime() + "&to=" + to.getTime();
                query += "&types=" + types.join(",");
                const FileDownload = require('js-file-download');
                let self = this;
                getBlob("admin/sales/report/excel?" + query)
                .then(function(response) {
                    FileDownload(response.data, "report.xlsx");
                })
                .catch(function(err) {

                })
            },
            showCancelPlaceItemDialog: function(item) {
                this.$data.cancelPlaceItem = {
                    id:item.id,
                    rate:100,
                    placeId:item.placeId,
                    item:item,
                    type:'select',
                }
            },
            cancelPlaceItemSale: function() {
                let self = this;
                let query = 'placeId='+this.$data.cancelPlaceItem.placeId + '&itemId=' + this.$data.cancelPlaceItem.id;

                if(this.$data.cancelPlaceItem.type === 'rate') {
                    query += '&rate=' + this.$data.cancelPlaceItem.rate;
                } else if(this.$data.cancelPlaceItem.type === 'amount') {
                    query += "&amount=" + this.$data.cancelPlaceItem.amount + "&payout=" + this.$data.cancelPlaceItem.payout;
                } else {
                    showDialog("환불 금액 방식을 선택해주세요");
                    return;
                }
                cancelPlaceItem(query)
                    .then(function(data) {
                        self.$data.cancelPlaceItem = false;
                        if(data.success == true) {
                            // self.onItemUpdated(data.item);
                            showDialog("취소 및 환불 처리 되었습니다.");
                            self.onItemSelected(self.$data.orderItem);
                        } else {
                            showDialog(data.message);
                        }

                    })
                    .catch(function(err) {
                        self.$data.cancelPlaceItem = false;
                        console.log(err);
                        if(err.response != undefined) {
                            console.log(err.response);
                        }
                        showDialog(err, "ERROR");
                    })
            },

        },
    }
</script>

<style lang="scss" scoped>
    .content {
        margin-top: 30px;
        font-size: 20px;
    }
    .list-detail-view {
        height: 100%;
    }
    .tag-search-wrapper {
        margin-top: 15px;
    }

    .tag-search {
        width: 100%;
        border: 1px solid #ddd;
        border-radius: 3px;
        font-size: 14px;
        padding: 5px 10px;
    }
    .chart-view {
        width: 100%;
        height: 300px;
    }
    .option-container {
        width: 100%;
        select {
            background: #FFFFFF;
            border: 1px solid #000000;
            box-sizing: border-box;
        }
    }
    .iot-log {
        background-color: #ddd;
    }
    .selectable {
        border:1px solid gray;
        border-radius:20px;
        height: 34px;
        line-height: 34px;
        padding-left: 10px;
        padding-right:10px;
        margin-left:10px;
        background-color: white;
        box-shadow: 3px 3px 5px grey;
        transition: all 0.3s ease-in-out;
        cursor: pointer;

    }
    .selectable.selected {
        background-color: #F67390;
        border: 1px solid #F67390;
        box-shadow: 0px 0px 0px grey;
    }
</style>
