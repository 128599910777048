<template>
  <div class="list-detail-view">
    <DetailLayout
      v-bind:is-available-more-btn="itemsToShow < items.length"
      :on-detail="detailItem != null"
      v-on:onRequestMoreList="onRequestMoreList">

      <template v-slot:list-title-text>
          {{$t('menu.super.list.'+modelKey)}} 관리
      </template>
<!--      <template v-slot:graph-container>-->
<!--        <div class="option-container">-->
<!--          <select>-->
<!--            <option value="7">최근7일</option>-->
<!--            <option value="30">최근30일</option>-->
<!--            <option value="90">최근90일</option>-->
<!--          </select>-->
<!--        </div>-->
<!--        <GChart-->
<!--          class="chart-view"-->
<!--          type="ColumnChart"-->
<!--          :data="stockGraphList"-->
<!--          :options="chartOptions"-->
<!--        />-->
<!--      </template>-->
      <template v-slot:top-items>
        <div class="list-top-btns">
          <div class="add-item" v-on:click="onClickAddBtn()">
            <span style="margin-right: 5px;">{{$t('list.add')}}</span>
            <span class="fas fa-plus"/>
          </div>
<!--          <div class="sort-btn-wrapper">-->
<!--            <DropdownInput-->
<!--              v-bind:labelName="$t('list.select_sort_type')"-->
<!--              v-bind:dropdownList="sortTypeList"-->
<!--              v-bind:currentSelected="currentSortType"-->
<!--              v-on:onClickItem="onClickSortType"/>-->
<!--          </div>-->
        </div>
      </template>
      <template v-slot:list-items>
        <div v-if="items.length === 0" class="empty-list">
          <p>{{$t('list.has_no_material')}}</p>
        </div>
        <div v-else>
          <template v-for="(item, index) in items">
            <Item v-if="index < itemsToShow"
                         v-bind:item="item"
                         v-on:onClick="onClickProduct" />
          </template>
        </div>
      </template>
      <template v-slot:detail-item>
          <Editor
              v-bind:detail-item="detailItem"
              :is-edit-mode="isEditMode"
              :is-add-mode="isAddMode"/>
      </template>
    </DetailLayout>
  </div>
</template>

<script>
    import {getOperatorById, getOperators, generateNewOperator} from "../../api/operator";

    var lodash = require('lodash');
    import DropdownInput from "../../inputs/DropdownInput";

    import DetailLayout from "../DetailLayout";
    import Item from "../../components/contents/operator/Item";
    import Editor from "../../components/contents/operator/Editor";

    export default {
        name: "UserView",
        components: {
            DetailLayout,
            DropdownInput,
            Item,
            Editor
        },
        data() {
            return {
                modelKey: "operator",
                itemsToShow: 5,
                items: [],
                detailItem: null,
                sortTypeList: [
                    {
                        name: this.$t('list.sort_name'),
                        value: 'name',
                        order: 'asc'
                    },
                    {
                        name: this.$t('list.sort_type'),
                        value: 'type',
                        order: 'asc'
                    }
                ],
                currentSortType: {},
                chartOptions: {
                    chart: {
                        title: 'Stock List',
                    }
                },
                stockGraphList:[],
                isEditMode: false,
                isAddMode: false
            }
        },
        created() {
            this.currentSortType = this.sortTypeList[0];

            this.render();
        },
        watch: {
            $route() {
                this.render();
            },
        },
        computed: {},
        methods: {
            reorderItems: function(items) {
                return lodash.orderBy(
                    items,
                    this.currentSortType.value,
                    this.currentSortType.order
                );
            },
            render: function() {
                // TODO: Need to Change Load Flow (NCM-3)

                /**
                 * If category changed, remove all items to refresh
                 */
                this.detailItem = null;

                this.updateListView();
                this.updateEditorView();
            },
            updateListView: function() {
                let that = this;

                getOperators().then(function(items){
                    that.items = that.reorderItems(items)
                })
            },
            updateEditorView: function() {
                this.isEditMode = this.$route.query.hasOwnProperty('edit');
                this.isAddMode = this.$route.query.hasOwnProperty('new')

                let that = this;
                if (this.$route.query.hasOwnProperty('id')) {
                    let contentId = this.$route.query.id;

                    getOperatorById(contentId).then(function(item) {
                        that.detailItem = item;
                    });
                } else if (this.isAddMode) {
                    let that = this;

                    that.detailItem = generateNewOperator();

                    // console.log(that.detailItem);
                    // that.detailItem.operatorId = getOperatorId();

                    // getOperatorById(that.detailItem.operatorId).then(item => {
                    //     that.detailItem.tags = item.tags;
                    // });

                    this.isEditMode = true
                } else {
                    this.isEditMode = false
                }
            },
            onRequestMoreList: function() {
                this.itemsToShow = this.itemsToShow + 5;
            },
            onClickSortType: function(selectedOption) {
                this.currentSortType = selectedOption;
                this.items = this.reorderItems(this.items)
            },
            onClickAddBtn: function() {
              this.$router.push(`/admin/${this.modelKey}?new`);
            },
            onClickProduct: function(operator) {
              let path = `/admin/operator?id=${operator.id}`;

              if(this.$route.fullPath !== path){
                this.$router.push(path);
              }
            }
        },
    }
</script>

<style lang="scss" scoped>

  .list-detail-view {
    height: 100%;
  }
  .tag-search-wrapper {
    margin-top: 15px;
  }

  .tag-search {
    width: 100%;
    border: 1px solid #ddd;
    border-radius: 3px;
    font-size: 14px;
    padding: 5px 10px;
  }
  .chart-view {
    width: 100%;
    height: 300px;
  }
  .option-container {
    width: 100%;
    select {
      background: #FFFFFF;
      border: 1px solid #000000;
      box-sizing: border-box;
    }
  }

</style>
