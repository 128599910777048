<template>
    <vue-form-item :label="$t('list.operator')">
        <dropdown-input
            v-on:onClickItem="onClickItem"

            :currentSelected="selected"
            :dropdownList="operatorList"
            :is-read-only="isReadOnly"/>
    </vue-form-item>
</template>

<script>
    import DropdownInput from "../inputs/DropdownInput";
    import {getOperatorList, getOperators} from "../api/operator";
    import {isOk} from "../api/common";
    import {getOperatorId, isSuperAdmin} from "../store";

    export default {
        name: 'OperatorDropdownInput',
        components: {
            DropdownInput
        },
        props: {
            operatorId: {
                type: Number,
                default: 0,
            },
            isReadOnly: {
                type: Boolean,
                default: true,
            },
        },
        data() {
            return {
                operatorList: [],

                selected: {},
            };
        },
        async created() {
            this.operatorList = await getOperatorList();

            this.selected = this.getOperatorItem(this.operatorId);
        },
        watch: {
            $route(){
                this.selected = this.getOperatorItem(this.operatorId);
            }
        },
        methods: {
            getOperatorItem(operatorId){
                let item = this.operatorList.find((v) => (v.value === operatorId));

                if(!item)
                    return {name: "없음"};

                return item;
            },
            onClickItem: function(item){
                this.selected = item;

                this.$emit('onClick', item.value);
            },
        }
    };
</script>
