<template>
    <div class="detail-view" v-bind:id="'detail-view-'+_uid">

        <div class="col-12 text-right mary-button-container" style="position: sticky; top:0; background-color: white; z-index: 2; box-shadow: 2px 2px 3px gray;">
            <div class="mary-button" style="background-color: black;" v-on:click="onClickCloseBtn()"><span><i class="fas fa-times"></i></span> {{$t('detail.close')}}</div>
            <div class="mary-button" v-show="!isEditMode" v-on:click="onClickEditBtn()"><span><i class="fas fa-edit"></i></span> {{$t('detail.edit')}}</div>
            <div class="mary-button" v-show="isEditMode" v-on:click="onClickSaveBtn()"><span><i class="fas fa-save"></i></span> {{$t('detail.save')}}</div>
            <div class="mary-button" style="background-color: red; float:left;" v-show="!isAddMode" v-on:click="onClickRemoveBtn()"><span><i class="fas fa-trash"></i></span> {{$t('detail.remove')}}</div>
        </div>

        <div class="head-group">
            <div class="top">

                <!-- created time -->
                <div class="timestamp" v-if="!isAddMode">
                    {{millisToReadable(userInfo.createdWhen)}}
                </div>

                <!-- read/write access -->
                <!--                <div class="access-rights">-->
                <!--                    <div class="read-levels">-->
                <!--                        <span class="access-label">{{$t('list.read_access')+':'}}</span>-->
                <!--                        <div class="read-level" v-for="(readAccess, index) in userInfo.readableAccessList" v-bind:key="index">-->
                <!--                            <span v-if="readAccess.type == 'private'" class="glyphicon glyphicon-lock" aria-hidden="true"/>-->
                <!--                            <span v-if="readAccess.type == 'public'" class="glyphicon glyphicon-globe" aria-hidden="true"/>-->
                <!--                            <span v-if="readAccess.type == 'group'" class="glyphicon glyphicon-user" aria-hidden="true"/>-->
                <!--                            <span>{{readAccess.name}}</span>-->
                <!--                        </div>-->
                <!--                    </div>-->
                <!--                    <div class="write-levels">-->
                <!--                        <span class="access-label">{{$t('list.write_access')+':'}}</span>-->
                <!--                        <div class="write-level" v-for="(writeAccess, index) in userInfo.writeableAccessList" v-bind:key="index">-->
                <!--                            <span v-if="writeAccess.type == 'private'" class="glyphicon glyphicon-lock" aria-hidden="true"/>-->
                <!--                            <span v-if="writeAccess.type == 'public'" class="glyphicon glyphicon-globe" aria-hidden="true"/>-->
                <!--                            <span v-if="writeAccess.type == 'group'" class="glyphicon glyphicon-user" aria-hidden="true"/>-->
                <!--                            <span>{{writeAccess.name}}</span>-->
                <!--                        </div>-->
                <!--                    </div>-->
                <!--                </div>-->
            </div><!-- ./top -->

            <div class="bottom">
                <div class="content-header">
                    <vue-form style="width: 100%;"
                              label-width="100%"
                              label-position="left">
                        <operator-dropdown-input
                            :is-read-only="!isEditMode"
                            v-bind:operatorId="userInfo.operatorId"
                            v-on:onClick="setOperatorId"/>
                        <hr>
                        <vue-form-item :label="$t('list.access')">
                            <vue-select
                                v-model="userInfo.role"

                                :disabled="!isSuperAdmin() || !isEditMode"
                                :data="roleList">
                                <vue-option
                                    v-for="i in roleList"
                                    :key="i.value"
                                    :value="i.value"
                                    :label="i.label">
                                </vue-option>
                            </vue-select>
                        </vue-form-item>
                        <vue-form-item :label="$t('content.type')">
                            <vue-input
                                v-model="userInfo.type"
                                :disabled="!isEditMode">
                            </vue-input>
                        </vue-form-item>
                        <vue-form-item label="Place" v-if="isPlaceUser() == true">

                            <select class="form-control" v-model="userInfo.placeId" :disabled="!isEditMode">
                                <option value="0">Select</option>
                                <option v-for="p in places" :value="p.id">{{p.name}}</option>
                            </select>
                        </vue-form-item>
<!--                        <vue-form-item :label="$t('user.accountType')">-->
<!--                            <vue-input-->
<!--                                v-model="userInfo.accountType"-->
<!--                                :disabled="!isEditMode">-->
<!--                            </vue-input>-->
<!--                        </vue-form-item>-->
                        <vue-form-item :label="$t('content.name')">
                            <vue-input
                                v-model="userInfo.name"
                                :disabled="!isEditMode">
                            </vue-input>
                        </vue-form-item>
                        <vue-form-item :label="$t('user.phoneNumber')">
                            <vue-input
                                v-model="userInfo.phoneNumber"
                                :disabled="!isEditMode">
                            </vue-input>
                        </vue-form-item>
                        <vue-form-item :label="$t('user.email')">
                            <vue-input
                                v-model="userInfo.email"
                                :disabled="!isEditMode">
                            </vue-input>
                        </vue-form-item>
                        <vue-form-item :label="$t('user.password')">
                            <vue-input
                                v-model="userInfo.password"
                                type="password"
                                :disabled="!isEditMode">
                            </vue-input>
                        </vue-form-item>
                        <vue-form-item v-if="isPartnerUser(userInfo.role)" :label="$t('user.apiKey')">
                            <vue-input
                                v-model="userInfo.apiKey"
                                :disabled="true">
                            </vue-input>
                        </vue-form-item>
                        <hr>
                        <hash-tag-input v-model="userInfo.tags" v-bind:isReadOnly="!isEditMode"/>
                    </vue-form>
                </div><!-- ./content-header -->
                <h5>위치 정보 접근 기록</h5>
                <vue-good-table
                    :columns="columns"
                    :rows="userLogs"
                    :pagination-options="{
                    enabled: true,
                    mode: 'records',
                    perPage: 15,
                    perPageDropdown: [25]
                }"/>
                <!--            <vuetable-->
                <!--                :v-validate="{fieldBagName: 'veeFields'}"-->
                <!--                v-bind:class="{ 'loading': true }"-->
                <!--                :api-url="apiURL"-->
                <!--                :http-options= "headerInfo"-->
                <!--                :fields="orderFields"-->
                <!--                :transform="transform"-->
                <!--                :getSortParam="getSortParam"-->

                <!--                pagination-path=""-->
                <!--                :per-page="15"-->
                <!--                :initial-page="0"/>-->
            </div><!-- ./bottom -->
        </div><!-- ./head-group -->
    </div>
</template>

<script>
    import OperatorDropdownInput from "../../../inputs/OperatorDropdownInput";

    import {millisToReadable, toDateFormat} from "../../../utils/TimeConverter";
    import {
        createUser,
        deleteUser, getAdminUserLogs,
        ROLE_ADMIN,
        ROLE_GENERAL,
        ROLE_PARTNER, ROLE_PLACE_MANAGER, ROLE_PLACE_STAFF,
        ROLE_SUPER_ADMIN,
        updateUser, writeUser
    } from "../../../api/user";
    import HashTagInput from "../../../inputs/HashTagInput";
    import CouponItem from "./CouponItem";
    import TabsView from "../../../views/TabsView";
    import TableEditor from "../../vuetable/TableEditor";
    import DropdownInput from "../../../inputs/DropdownInput";
    import {setTagsByOperatorId} from "../../../api/operator";
    import {getRoleId, isSuperAdmin} from "../../../store";
    import {changeURL, getAPIURL, getCommonHeader, getItemList} from "../../../api/common";

    import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
    import Vuetable from 'vuetable-2'
    import {getPlaces} from "../../../api/place";

    export default {
        name: 'SpecialUserEditor',
        components: {
            OperatorDropdownInput,
            DropdownInput,
            TableEditor,
            TabsView,
            CouponItem,
            HashTagInput,

            PulseLoader,
            Vuetable
        },
        props: {
            userInfo: {
                type: Object,
                default: function() { return{} }
            },
            isAddMode: {
                type: Boolean,
                default: true
            }
        },
        data(){
            return {
                headerInfo: {
                    headers: getCommonHeader()
                },

                userLogs: [],
                columns: [
                    {field: 'lastUpdatedWhen', label: "TIME (lastUpdatedWhen)"},
                    {field: 'targetAssetInfo', label: "BIKE (targetAssetInfo)"},
                ],

                roleList: [
                    {value: ROLE_SUPER_ADMIN, label:this.$t('user.superAdmin')},
                    {value: ROLE_PLACE_STAFF, label:'플레이스 Staff'},
                    {value: ROLE_PLACE_MANAGER, label:'플레이스 관리자'},
                    {value: ROLE_ADMIN, label:this.$t('user.admin')},
                    {value: ROLE_PARTNER, label:this.$t('user.partner')},
                    {value: ROLE_GENERAL, label:this.$t('user.general')}
                ],
                selectedRole: this.userInfo.role,
                roleName: "",

                isEditMode: this.isAddMode,
                places:[],
            }
        },
        created() {
            this.getUserLogs();
            this.getPlaceList();
        },
        watch: {
            $route() {
                this.isEditMode = this.isAddMode;

                this.getUserLogs();
                this.getPlaceList();
            }
        },
        methods: {
            isPlaceUser: function() {
                return (this.userInfo.role == ROLE_PLACE_MANAGER || this.userInfo.role == ROLE_PLACE_STAFF);
            },
            setOperatorId: function(id) {
                setTagsByOperatorId(id, this.userInfo);
            },
            onClickCloseBtn: function() {
                if(!this.isEditMode) {
                    this.$emit('onClose', null);

                    return;
                }

                this.isEditMode = false;
            },
            getPlaceList: function() {
                let self = this;
                getItemList("place")
                .then(function(data) {
                    data.sort(function(a, b) {
                        if(a.name > b.name) {
                            return 1;
                        }
                        return -1;
                    })
                    self.$data.places = data;
                })
                .catch(function(err) {

                })
            },
            onClickSaveBtn: function() {
                let that = this;
                if(null == this.userInfo.phoneNumber || "" === this.userInfo.phoneNumber){
                    // TODO: 중복체크도 해야함.
                    alert("전화번호 입력은 필수 입니다.");

                    return;
                }

                if(this.userInfo.role === ROLE_GENERAL) {
                    this.userInfo.operatorId = 0;
                }

                writeUser(that.userInfo).then(function (user) {
                    alert("저장 되었습니다.");

                    that.isEditMode = false;

                    that.$emit("updateList");

                    changeURL(`special?id=${user.id}`, that.$route, that.$router);
                });
            },
            onClickEditBtn: function() {
                let thisRoleId = Number(this.userInfo.role);

                if(thisRoleId > getRoleId()){
                    alert("권한이 없습니다.")

                    return;
                }

                this.isEditMode = true;
                // this.$router.push('/admin/special?id='+this.userInfo.id+'&edit');
            },
            onClickRemoveBtn: function() {
                let self = this;
                this.$modal.show('dialog', {
                    title: '',
                    text: "영역 " + this.userInfo.name + ' ' + this.userInfo.email + ' ' + this.userInfo.phoneNumber + '를 삭제 하시겠습니까?',
                    buttons:[
                        {
                            title: '아니요',
                            handler:() => {
                                self.$modal.hide('dialog');
                            }
                        },
                        {
                            title:'삭제',
                            handler:()=>{
                                self.$modal.hide('dialog');
                                self.onClickRemoveBtnConfirmed();
                            }
                        }
                    ]
                })
            },
            onClickRemoveBtnConfirmed: function() {
                let that = this;

                deleteUser(that.userInfo.id).then(function() {
                    alert("삭제 되었습니다.");

                    that.$emit("updateList")

                    that.$emit('onClose', null);
                });
            },

            isPartnerUser(role){
                return ROLE_PARTNER === role;
            },
            // transform: function(data){
            //     if(!data.success){
            //         return null;
            //     }
            //
            //     for(let e of data.list){
            //         e.createdWhen = millisToReadable(e.createdWhen);
            //         e.lastUpdatedWhen = millisToReadable(e.lastUpdatedWhen);
            //     }
            //
            //     return {
            //         data: data.list
            //     };
            // },
            // getSortParam: function(){
            //     return "createdWhen,desc";
            // },
            getUserLogs(){
                let that = this;

                getAdminUserLogs(this.userInfo.id).then(data => {
                    if(data.success == true) {
                        let list = data.list;
                        for (let e of list) {
                            e.lastUpdatedWhen = millisToReadable(e.lastUpdatedWhen);
                        }

                        that.userLogs = list;
                    }
                })
            },

            isSuperAdmin: isSuperAdmin,
            millisToReadable: millisToReadable,
        },
    };
</script>

<style lang="scss">
    .content {
        margin-top: 30px;
        font-size: 20px;
    }
</style>
