<template>
    <div class="page-wrapper">
        <div class="container narrow-container">
            <div class="row" style="margin-top:50px;margin-bottom: 50px;">
                <div class="col-12 text-center">
                    <img style="max-height: 100px;" src="https://firebasestorage.googleapis.com/v0/b/ncm-mary-254508.appspot.com/o/mary-icon.png?alt=media&token=8f22c129-3eb0-4d89-9fa8-acf44c257a0f"/>
<!--                    <h1><img src="../assets/images/logo.gif"/></h1>-->
                </div>
            </div>
            <form @submit.prevent="onSubmit(email, password)">
                <div class="row form-group section-padding-top">
                    <div class="col-12">
                        <input type="text" class="form-control text-center" v-model="email" placeholder="이메일"/>
                    </div>
                </div>
                <div class="row form-group">
                    <div class="col-12">
                        <input type="password" class="form-control text-center" v-model="password" placeholder="비밀번호"/>
                    </div>
                </div>
                <div class="row form-group section-padding-top">
                    <div class="col-12">
                        <input type="submit" class="btn btn-primary form-control" value="로그인">
                    </div>
                </div>
            </form>
        </div>
        <v-dialog width="80%"/>
    </div>

</template>

<script>
    import {isLoggedIn} from "../store/auth.module";

    export default {
        name: "Login",
        data() {
            return {
                email:'',
                password:'',
            }
        },
        created() {
            if(isLoggedIn()){
                this.$router.push("/admin/home")
            }
        },
        methods: {
            showModal: function(title, message) {
                this.$modal.show('dialog',{
                    title: title,
                    text: message,
                    buttons:[
                        {title:'Close'}
                    ]
                })
            },
            onSubmit(email, password) {
                let that = this;

                if("" === email) {
                    that.showModal("아이디 입력", "아이디를 입력해주시기 바랍니다.");
                    return
                }

                if("" === password) {
                    that.showModal("비밀번호 입력", "비밀번호를 입력해주시기 바랍니다.");
                    return
                }
                // LOGIN 액션 실행
                console.log("onsubmit " + email + "  " + password);
                this.$store.dispatch('login', {email, password})
                    .then((item) => {
                        that.$router.push("/admin/home")
                    })
                    .catch((message) => {
                        that.showModal("로그인 실패", message);
                    })
            }
        }
    }
</script>

<style scoped>
    .page-wrapper {
        width: 100%;
        height:100%;
        position: fixed;
        top: 0;
        left: 0;
    }
    .narrow-container {
        max-width: 500px;
    }
</style>
