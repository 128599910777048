<template>
    <div class="list-detail-view">
        <DetailLayout
            :on-detail="detailItem != null"
            :fullScreen="true"
            v-on:onRequestMoreList="itemsToShow += 5">
            <template v-slot:list-title-text>
                {{$t('menu.bike.list.assign')}} {{getCurrentTypeString()}} 관리
            </template>
            <template v-slot:top-items>
                <div class="list-top-btns">
                    <div style="float:left">
                        <div class="selector-wrapper">
                            <label>종류</label>
                            <select v-model="selectedType" v-on:change="updateListView">
                                <option v-for="type in typeList" :value="type.value">{{type.name}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="btn add-item" v-on:click="onClickAddBtn()">
                        <span style="margin-right: 5px;">{{$t('list.add')}}</span>
                        <span class="fas fa-plus"></span>
                    </div>
                </div>
                <div class="sort-btn-wrapper">
                    <sort-and-search-input v-on:onEnterSearch="onSearchTags"/>
                </div>
            </template>
            <template v-slot:list-items>
                <vue-good-table
                    mode="remote"
                    @on-sort-change="onSortChange"
                    @on-row-click="onRowClick"
                    :rows="itemList"
                    :columns="columns"/>
            </template>
            <template v-slot:detail-item>
                <operation-editor
                    v-bind:item="detailItem"

                    v-on:onClose="onCloseClick"
                    v-on:updateList="updateListView"

                    :is-add-mode="isAddMode"/>
            </template>

        </DetailLayout>
    </div>
</template>

<script>
    import BoundaryMapView from "../BoundaryMapView";
    import DropdownInput from "../../inputs/DropdownInput";

    import DetailLayout from "../DetailLayout";

    import {
        changeURL,
        checkOperator,
        getItemById,
        getItemList,
        includesTag,
        isOk,
        reorderItems
    } from "../../api/common";
    import PageEditor from "../../components/contents/magazine/Editor";
    import PageItem from "../../components/contents/magazine/Item";
    import {clone} from "../../utils/VarToString";
    import {getOperations, newOperation, getOperationTypes, getStatusList} from "../../api/operation";
    import {setTagsByOperatorId} from "../../api/operator";
    import {getOperatorId, getUserId} from "../../store";
    import OperationEditor from "../../components/contents/assign/Editor";
    import AssignItem from "../../components/contents/assign/Item";
    import SortAndSearchInput from "../../inputs/SortAndSearchInput";

    import {millisToReadable, millisToReadableWithoutSecond} from "../../utils/TimeConverter";

    export default {
        name: "MagazineView",
        components: {
            SortAndSearchInput,
            AssignItem,
            OperationEditor,
            PageItem,
            PageEditor,
            BoundaryMapView,
            DetailLayout,
            DropdownInput,
        },
        data() {
            return {
                // mapCenter: {
                //     lat: 36.5, lng:127.8
                // },
                // mapZoom: 7,
                // markers: [],

                columns: [
                    {field: 'id', label: '#'},
                    {field: 'asset.name', label: '자전거 이름'},
                    {field: 'asset.fullCode', label: '자전거 코드'},
                    {field: 'user.name', label: '담당자'},
                    {field: 'status', label: "상태"},
                    {field: 'content', label: "내용"},
                    {field: 'comments.length', label: "코멘트"},
                    {field: 'createdWhen', label: "생성"},
                    {field: 'lastUpdatedWhen', label: "업데이트"},
                ],

                // sortTypeList: [
                //     {name: this.$t('list.sort_name'), value: 'title', order: 'asc'},
                //     {name: this.$t('list.sort_type'), value: 'type', order: 'asc'}
                // ],
                // currentSortType: {},

                itemList: [],
                detailItem: null,

                isEditMode: false,
                isAddMode: false,

                typeList: [],
                selectedType:'assign',

                statusList:[],
            }
        },
        created() {
            this.typeList = getOperationTypes();
            this.statusList = getStatusList();
            this.updateListView();
            this.updateEditorView();
        },
        watch: {
            $route(){
                this.updateEditorView();
            }
        },
        methods: {
            updateListView: function(check = isOk) {
                this.isAddMode = false;

                let userId = getUserId();

                let that = this;
                getOperations(this.selectedType, userId).then(items => {
                    that.itemList = [];

                    for(let e of items){
                        if(check(e)) {
                            e.status = 1 === e.status ? "할당" : "생성";
                            if(e.createdWhen != null)
                                e.createdWhen = millisToReadableWithoutSecond(e.createdWhen);
                            if(e.lastUpdatedWhen != null)
                                e.lastUpdatedWhen = millisToReadableWithoutSecond(e.lastUpdatedWhen);

                            that.itemList.push(e);
                        }
                    }
                })
            },
            updateEditorView: function() {
            //     this.isEditMode = this.$route.query.hasOwnProperty('edit');
            //     this.isAddMode = this.$route.query.hasOwnProperty('new')
            //
                if (this.$route.query.hasOwnProperty('id')) {
                    let contentId = this.$route.query.id;

                    let that = this;
                    getItemById("operation", contentId).then(item => {
                        if(isOk(item)) that.detailItem = item;
                    });
            //     } else if (this.isAddMode) {
            //         let that = this
            //
            //         that.detailItem = newPlace()
            //         setTagsByOperatorId(getOperatorId(), that.detailItem);
            //
            //         this.isEditMode = true
            //     } else {
            //         this.isEditMode = false
                }
            },
            onClickSortType: function(selectedOption) {
                this.currentSortType = selectedOption;

                this.items = reorderItems(this.items, this.currentSortType)
            },
            onClickAddBtn: function() {
                this.isAddMode = true;

                this.detailItem = newOperation();
                this.detailItem.userId = getUserId();
                this.detailItem.type = this.selectedType;

                setTagsByOperatorId(getOperatorId(), this.detailItem);

                this.$router.push(`/admin/assign?new`);
            },
            onCloseClick: function(item) {
                this.isAddMode = false;

                this.detailItem = null;

                changeURL('assign', this.$route, this.$router);
            },
            onSearchTags(v) {
                this.updateListView(e => { return isOk(e) && includesTag(v, e.tags) });
            },
            // getTimeString:millisToReadableWithoutSecond,
            // onTypeChanged: function() {
            //     this.updateListView();
            // },
            getCurrentTypeString: function() {
                for(let i = 0 ; i < this.typeList.length ; i++) {
                    if(this.typeList[i].value === this.selectedType) {
                        return this.typeList[i].name;
                    }
                }
                return '알수없는 ' + this.selectedType;
            },
            getStatusString: function(status) {
                for(let i = 0 ; i < this.statusList.length ; i++) {
                    if(this.statusList[i].value === status) {
                        return this.statusList[i].name;
                    }
                }
                return '알수없음';
            },
            onSortChange(params){
                let sortType = {
                    value: params[0].field,
                    order: params[0].type
                };

                this.itemList = reorderItems(this.itemList, sortType)
            },
            onRowClick(params){
                this.isAddMode = false;

                changeURL('assign', this.$route, this.$router, params.row);
            }
        }
    }
</script>
<style lang="scss" scoped>
    .selector-wrapper {
        margin-right: 10px;
    }
    .selector-wrapper label {
        margin-right: 10px;
        font-size: 14px;
        font-weight: 300;
    }
    .selector-wrapper > select {
        height: 34px;

    }

    .operation-status-0 {
        background: #F67373;
        color: white;
    }
    .operation-status-1 {
        background: #8AE68E;

    }
    .operation-status-2 {
        background: #2E4DBF;
        color:white;
    }
    .operation-status-5 {
        background: #A7A7A7;
        color: white;
    }

</style>
