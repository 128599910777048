<template>
  <div class="list-detail-view">
      <DetailLayout
          v-on:onRequestMoreList="onRequestMoreList"
          v-bind:on-detail="detailItem != null"
          :is-available-more-btn="itemsToShow < items.length">

      <template v-slot:list-title-text>
          {{pageTitle}}
      </template>
<!--      <template v-slot:graph-container>-->
<!--        <div class="option-container">-->
<!--          <select>-->
<!--            <option value="7">최근7일</option>-->
<!--            <option value="30">최근30일</option>-->
<!--            <option value="90">최근90일</option>-->
<!--          </select>-->
<!--        </div>-->
<!--        <GChart-->
<!--          class="chart-view"-->
<!--          type="ColumnChart"-->
<!--          :data="stockGraphList"-->
<!--          :options="chartOptions"-->
<!--        />-->
<!--      </template>-->
          <template v-slot:top-items>
              <div class="list-top-btns">
                  <div class="add-item" v-on:click="onClickAddBtn()">
                      <span style="margin-right: 5px;">{{$t('list.add')}}</span>
                      <span class="fas fa-plus"></span>
                  </div>
              </div>
              <div v-if="operators.length > 1">
                  <label>{{$t('operator.select')}}</label>
                  <select v-model="selectedOperatorId" @change="onOperatorSelected">
                      <option v-for="o in operators" :value="o.value">{{o.name}}</option>
                  </select>
              </div>
              <div class="sort-btn-wrapper">
                  <sort-and-search-input
                      :sort-type-list="sortTypeList"

                      v-on:onEnterSearch="onSearchTags"
                      v-on:onClickSortType="onClickSortType"/>
              </div>
          </template>
          <template v-slot:list-items>
              <div v-if="items.length === 0" class="empty-list">
                  <p>{{$t('list.has_no_material')}}</p>
              </div>
              <div v-else>
                  <template v-for="(item, index) in items">
                      <Item v-if="index < itemsToShow"
                            v-bind:item="item"
                            v-bind:operatorName="getOperatorName(item.operatorId)"
                            v-on:onClick="onClickProduct"
                            v-bind:class="{'is-selected': detailItem != null && item.id === detailItem.id}"/>
                  </template>
              </div>
          </template>
          <template v-slot:detail-item>
              <Editor
                  v-bind:detail-item="detailItem"
                  v-on:onClose="onClickProduct"
                  v-on:updateList="onUpdateList"
                  :items="items"
                  :places="places"
                  :is-add-mode="detailItem.id === 0"/>
          </template>
      </DetailLayout>
      <v-dialog max-width="400px" persistent/>
  </div>
</template>

<script>
    import {getOperatorList, setTagsByOperatorId} from "../../api/operator";

    import DropdownInput from "../../inputs/DropdownInput";
    import DetailLayout from "../DetailLayout";
    import Item from "../../components/contents/product/Item";
    import Editor from "../../components/contents/product/Editor";
    import {generateNewProduct, getProductById, getProducts, getBikeProducts} from "../../api/product";
    import {getOperatorId} from "../../store";
    import {getItemList, includesTag, isOk, reorderItems} from "../../api/common";
    import {getOperators} from "../../api/operator";
    import {clone} from "../../utils/VarToString";
    import SortAndSearchInput from "../../inputs/SortAndSearchInput";

    export default {
        name: "UserView",
        components: {
            SortAndSearchInput,
            DetailLayout,
            DropdownInput,
            Item,
            Editor
        },
        props: {
            type: {
                type: String,
                default: ''
            }
        },
        data() {
            return {
                modelKey: "product",
                pageTitle:'',
                itemsToShow: 5,
                items: [],
                sortTypeList: [
                    {name: this.$t('list.sort_name'), value: 'name', order: 'asc'},
                    {name: this.$t('list.sort_type'), value: 'type', order: 'asc'}
                ],
                currentSortType: {},
                chartOptions: {
                    chart: {
                        title: 'Stock List',
                    }
                },
                stockGraphList:[],

                isAddMode: false,
                detailItem: null,

                operators:[],
                selectedOperatorId: getOperatorId(),
                places:[],
            }
        },
        async created() {
            if(this.type === 'price') {
                this.$data.pageTitle = '자전거 요금 관리';
            } else if(this.type === 'coupon') {
                this.$data.pageTitle = '자전거 쿠폰 관리';
            }
            this.currentSortType = this.sortTypeList[0];
            this.operators = await getOperatorList();

            this.onUpdateList();
            this.onUpdateItem();
            this.getPlaceList();
        },
        methods: {
            getPlaceList: function() {
                let self = this;
                getItemList("place")
                .then(function(data) {
                    data.sort(function(a, b) {
                        if(a.name > b.name) {
                            return 1;
                        }
                        return -1;
                    })
                    self.$data.places = data;
                })
                .catch(function(err) {

                })
            },
            getOperatorName: function(operatorId) {
                // console.log("getOperatorName ");
                // console.log(this.$data.operators);
                for(let operator of this.operators) {
                    if(operator.value === operatorId)
                        return operator.name;
                }
                return '사업자 미지정';
            },
            onOperatorSelected: function() {
                let operatorId = this.selectedOperatorId;

                // this.onUpdateList(function (e) {
                //     if(!isOk(e))
                //         return false;
                //
                //     if(operatorId === 0)
                //         return true;
                //
                //     return operatorId === e.operatorId;
                // })
                this.onUpdateList();
            },
            onUpdateList: function() {
                let that = this;

                // if(this.selectedOperatorId == 0) {
                //     return;
                // }
                that.items = [];
                if(this.selectedOperatorId == 0) {
                    // return;
                }
                // if(this.type === 'price') {
                getBikeProducts(this.selectedOperatorId, this.type)
                    .then(function (data) {
                        if (data.success == true) {
                            that.items = data.list;
                            console.log("Products");
                            console.log(that.items);
                        }

                        if(that.selectedOperatorId == 0) {
                            getBikeProducts(that.selectedOperatorId, "FORMAT_WELCOME_COUPON")
                            .then(function(data) {
                                if(data.success == true) {
                                    console.log("get coupon format ");
                                    console.log(data);

                                    for(let i = 0 ; i < data.list.length ; i++) {
                                        that.items.push(data.list[i]);
                                    }

                                } else {
                                    console.log("no coupon format " + data.message);
                                }
                            })
                        }
                    })
                    .catch(function (err) {

                    })
                // } else if(this.type === 'coupon') {
                //
                // }
                // getProducts().then(function(items){
                //     that.items = [];
                //     items.forEach( e => {
                //         if(check(e))
                //             that.items.push(e);
                //     });
                //     that.items = reorderItems(that.items, that.currentSortType);
                // })
            },
            onUpdateItem: function(){
                let isId = this.$route.query.hasOwnProperty('id');

                let that = this;
                if (isId) {
                    let contentId = this.$route.query.id;

                    getProductById(contentId).then(item => {
                        if(isOk(item)) that.detailItem = item;
                    });
                }
            },
            onItemDeleted: function() {

            },
            onRequestMoreList: function() {
                this.itemsToShow = this.itemsToShow + 5;
            },
            onClickSortType: function(selectedOption) {
                this.currentSortType = selectedOption;

                this.items = reorderItems(this.items, this.currentSortType);
            },
            onClickAddBtn: function() {
                this.detailItem = generateNewProduct();
                setTagsByOperatorId(getOperatorId(), this.detailItem);
            },
            onClickProduct: function(item) {
                // if(!compareDicts(this.detailItem, this.selectedItem)){
                //     if(!confirm("변경된 내용이 있습니다. 무시하시겠습니까?")){
                //         return;
                //     }
                // }

                this.detailItem = clone(item);

                let path = `/admin/`;
                if(this.type == 'coupon') {
                    path += 'coupon';
                } else {
                    path += 'product';
                }
                if(item) {
                    path += `?id=${item.id}`;
                }

                if(this.$route.fullPath !== path) {
                    this.$router.push(path);
                }
            },
            onSearchTags(v) {
                this.onUpdateList(e => { return isOk(e) && includesTag(v, e.tags) });
            }
        },
    }
</script>

<style lang="scss" scoped>

  .list-detail-view {
    height: 100%;
  }
  .tag-search-wrapper {
    margin-top: 15px;
  }

  .tag-search {
    width: 100%;
    border: 1px solid #ddd;
    border-radius: 3px;
    font-size: 14px;
    padding: 5px 10px;
  }
  .chart-view {
    width: 100%;
    height: 300px;
  }
  .option-container {
    width: 100%;
    select {
      background: #FFFFFF;
      border: 1px solid #000000;
      box-sizing: border-box;
    }
  }

</style>
