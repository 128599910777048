<template>
<!--    <div class="map-container" style="background-color:blue;position:relative;">-->
        <gmap-map
            ref="gmapEditor"
            :center="center"
            :zoom="15"
            :options="mapOption"
            class="google-map"
            :clickable="editable"
            @click="clickedOnMap"
            style="width: 100%; height: 100%;">
            <gmap-marker
                v-for="(mark, index) in paths"
                :key="'path-' + index"
                :position="mark"
                :draggable="editable"
                class="map-marker"
                @dragstart="onDragStart(index)"
                @dragend="positionUpdated"/>
            <gmap-polygon
                :paths="paths"
                v-bind:options="polygonOption"/>
        </gmap-map>
</template>

<script>
    export default {
        name: "BoundaryMapView",
        props: {
            modelKey: {
                type: String,
                default: ""
            },
            assets: {
                type: Array,
                default: []
            },
            clickListener: {
                type: Function,
                default: null
            },
            onMoveListener: {
                type: Function,
                default: null
            },
            editable: {
                type: Boolean,
                default:false
            }
        },
        data: function() {
            return {
                center: { lat: 37.491649, lng: 127.030824 },
                mapOption: {
                    streetViewControl:false,
                    fullscreenControl:false,
                    mapTypeControl:false
                },
                polygonOption:{
                    fillColor:'#CB0457',
                    fillOpacity:0.3,
                    strokeColor:'#CB0457',
                    strokeOpacity:0.0
                },

                paths: [],
                dragIndex: -1,
            }
        },
        mounted() {
            this.getCenter();
            this.setPaths();

        },
        watch: {
            assets: function () {
                this.setPaths();
            }
        },
        methods: {
            clickedOnMap: function(ev) {
                if(this.editable == true) {
                    console.log("clicked on map " + ev.latLng);
                    if (this.clickListener != null) {
                        this.clickListener(ev);
                    }
                }
            },
            setPaths: function() {
                this.paths = [];

                let that = this;

                this.assets.forEach(function (e) {
                    that.paths.push({
                        lat: e.lat,
                        lng: e.lon
                    })
                })
            },

            getCenter: function(){
                let sumLat = 0;
                let sumLng = 0;

                if(this.assets != null && this.assets.length > 0) {
                    console.log("getCenter");
                    console.log(this.assets);
                    this.assets.forEach(function (e) {
                        sumLat += e.lat;
                        sumLng += e.lon;
                    });

                    this.center = {
                        lat: sumLat / this.assets.length,
                        lng: sumLng / this.assets.length
                    };
                }
            },
            positionUpdated: function(evt) {
                console.log("dragged ");
                console.log(evt);

                let newPath = {
                    lat: evt.latLng.lat(),
                    lng: evt.latLng.lng()
                }
                this.paths.splice(this.$data.dragIndex, 1, newPath);
                // this.paths[this.$data.dragIndex].lat = evt.latLng.lat();
                // this.paths[this.$data.dragIndex].lng = evt.latLng.lng();
                if(this.onMoveListener != null) {
                    console.log("call move listener");
                    this.onMoveListener(evt, this.$data.dragIndex);
                }
                this.$data.dragIndex = -1;
            },
            onDragStart: function(index) {
                console.log("drag started " + index);
                this.$data.dragIndex = index;
            },
            onDragEnded: function(index) {
                console.log("drag ended " + index);
            }
        }
    }
</script>
