import {createItem, deleteItem, getItemById, getItemList, updateItem} from "./common";
import {getOperatorId, isSuperAdmin} from "../store";

const model = "operator";

export function setTagsByOperatorId(operatorId, that) {
    that.operatorId = operatorId;
    if(0 === that.operatorId) {
        return;
    }

    let tagIds = [];
    that.tags.forEach(e => {
        tagIds.push(e.id);
    });

    getOperatorById(operatorId).then(item => {
        item.tags.forEach(e => {
            if(!tagIds.includes(e.id))
                that.tags.push(e);
        });
    });
}

export async function getOperatorMap() {
    let operatorMap = {};

    let operatorId = getOperatorId();
    if(0 === operatorId){
        operatorMap[0] = "전체";
    }

    await getOperators().then(function (operators) {
        operators.forEach(function (e) {
            operatorMap[e.id] = e.name;
        });
    });

    return operatorMap;
}

export function getOperators() {
    return getItemList(model);
}

export function getOperatorById(id) {
    return getItemById(model, id);
}

export function createOperator(item) {

    return createItem(model, item);
}

export function deleteOperator(id) {

    return deleteItem(model, id)
}

export function updateOperator(item) {
    return updateItem(model, item);
}

export function generateNewOperator() {
    // console.log("generateNewOperator");

  return {
      id: 0,
      title: "",
      type: "",
      email: "",
      phoneNumber: "",
      regNumber: "",
      taxNumber: "",
      accountNumber: "",
      bankName: "",
      tags: []
  }
}
export function getOperatorTypeList() {
    let list = [
        {
            value:'bike',
            label:'전기자전거 공유 사업자',
        },
        {
            value:'place',
            label:'플레이스 사업자',
        },
        {
            value:'bike_partner',
            label:'바이크 파트너',
        },

    ]
    return list;
}
export async function getOperatorList() {
    let operatorList = [];

    let operatorId = getOperatorId();
    if(0 === operatorId){
        operatorList.push(
            {
                name: "사업자 전체",
                value: 0
            }
        );
    }

    await getOperators().then(function (operators) {
        operators.forEach(function (e) {
            if(0 === operatorId || e.id === operatorId) {
                operatorList.push({
                    name: e.name,
                    value: e.id,
                    lat: e.lat,
                    lng: e.lon,
                });
            }
        });
    });

    return operatorList;
}


