<template>
  <div class="list-item" v-on:click="onClickList()">

<!--    <div class="thumbnail-wrapper">-->
<!--      <div class="thumbnail-holder">-->
<!--      </div>-->
<!--    </div>-->

    <div class="content-info">
<!--      <div class="title">{{content.title}}</div>-->
<!--      <div class="position">이름: {{content.name}}</div>-->
      <div class="channel-number">{{content.type}}</div>
      <div class="timestamp">포인트: {{content.balance}}</div>
      <div class="timestamp">유효기간: {{getExpirationDate()}}</div>

    </div>

  </div>
</template>

<script>
    export default {
        name: "ListCoupon",
        props: {
            content: {
                type: Object,
                default: function() { return{} }
            }
        },
        methods:{
            onClickList: function() {
                this.$emit('onClick', this.content);
            },
            getExpirationDate(){
                let expirationMs = this.content.validToTime - Date.now();
                // console.log(expirationMs);
                if(expirationMs <= 1000)
                    return "만료";

                let date = new Date(expirationMs);

                let text = '';
                let months = date.getMonth();
                if(months >= 1){
                    text += `${months}개월 `
                }

                let days = date.getDay();
                if(days >= 1){
                    text += `${days}일 `
                }

                let hours = date.getHours();
                if(hours >= 1){
                    text += `${hours}시간 `
                }

                let minnutes = date.getMinutes();
                if(minnutes >= 1){
                    text += `${minnutes}분 `
                }

                return text;
            }
        }
    }
</script>


<style lang="scss" scoped>
  .list-item {
    display: table;
    width: 100%;
    margin-top: 15px;
    background-color: #FFF;
    padding: 15px;
    border: 1px solid #EEE;
    box-shadow: 2px 3px 4px -1px rgba(0,0,0,0.3);
    cursor: pointer;

    .thumbnail-wrapper {
      display: table-cell;
      vertical-align: middle;
      padding-right: 15px;

      .thumbnail-holder {
        width: 50px;
        height: 50px;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        border: 1px solid #ddd;
        border-radius: 2px;
      }
    }

    .content-info {
      display: table-cell;
      width: 100%;
      vertical-align: middle;

      .timestamp {
        font-size: 12px;
        color: #999;
        /*text-align: right;*/
      }
      .title {
        font-size: 18px;
        font-weight: 500;
        margin-top: 10px;
      }

      .channel-number {
        vertical-align: middle;
        font-size: 14px;
        color: #666;
        margin-bottom: 10px;
      }

      .access-levels {
        font-size: 12px;
        color: #666;
        margin-top: 5px;

        .read-level, .write-level {
          display: inline-block;
        }
        .read-level {
          margin-right: 5px;
          padding-right: 10px;
          border-right: 1px solid #ddd;
        }
        .access-label {
          margin-right: 5px;
        }
      }
    }
  }

  .list-item:hover {
    box-shadow: 3px 4px 6px -1px rgba(0,0,0,0.4);
  }
  .on-detail-view .list-item.selected {
    background-color: #2B90D9;
    border-color: #1982ce;
    box-shadow: 3px 4px 6px -1px rgba(0,0,0,0.4);
    .content-info {
      .title,
      .channel-number {
        color: #fefefe;
      }
      .timestamp,
      .access-levels {
        color: #EEE;
      }
    }
  }
</style>
