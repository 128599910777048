<template>
    <div class="dropdown-input input">
        <label v-if="labelName">{{labelName}}</label>
        <div class="dropdown">
            <button
                class="btn btn-default dropdown-toggle"
                type="button" data-toggle="dropdown"
                :disabled="isReadOnly">

                {{currentSelected.name}}
                <span class="caret align-right"/>
            </button>

            <ul class="dropdown-menu dropdown-list" style="max-height: 60vh; overflow-y: scroll;">
                <li class="list-group-item" v-for="item in dropdownList" v-bind:key="item.value" v-on:click="onClickList(item)">
                   {{item.name}}
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'DropdownInput',
        props: {
            currentSelected: {
                type: Object,
                default: {
                    name: 'Select',
                    value: null,
                },
            },
            labelName: {
                type: String,
                default: '',
            },
            dropdownList: {
                type: Array,
                default: [],
            },
            isReadOnly: {
                type: Boolean,
                default: false,
            },
        },
        methods: {
            onClickList: function(item) {
                this.$emit('onClickItem', item);
            },
        },
    };
</script>

<style lang="scss">
    .dropdown-list
    {
        width: 100%;
    }

    /*.dropdown {*/
    /*    display: inline-block;*/
    /*}*/

    /*label,*/
    /*input::placeholder {*/
    /*    margin-right: 10px;*/
    /*    font-size: 14px;*/
    /*}*/

    /*.dropdown ul a {*/
    /*    cursor: pointer;*/
    /*}*/

    .dropdown-toggle{
        width: 100%;

        text-align: left;
    }
    .align-right{
        position: absolute;
        left: 95%;
        top: 45%;
    }
</style>
