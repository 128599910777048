import {errorHandler, getCommonHeader} from "./common";
import {cleanLocalStorage} from "../store/auth.module";
import EventBus from "./event-bus";

const axios = require('axios');

export function getMerchantList() {
    let address = process.env.VUE_APP_API_BASE_URL + `merchant/merchants`;
    return new Promise(function(resolve, reject) {
        axios( {
            method:'GET',
            url:address,
            headers: getCommonHeader()
        }).then(function(response) {

            resolve(response.data);
        }).catch(error => {
            errorHandler(error);
            console.log(error.response);
            reject(error);
        });
    })
}
export function getMerchantItem(id) {
    let address = process.env.VUE_APP_API_BASE_URL + `merchant/merchant/` + id;
    return new Promise(function(resolve, reject) {
        axios( {
            method:'GET',
            url:address,
            headers: getCommonHeader()
        }).then(function(response) {

            resolve(response.data);
        }).catch(error => {
            errorHandler(error);
            console.log(error.response);
            reject(error);
        });
    })
}
export function updateMerchantItem(merchant) {
    let address = process.env.VUE_APP_API_BASE_URL + 'merchant/merchant';

    return new Promise(function(resolve, reject) {
        axios({
            method: 'post',
            url: address,
            data: JSON.stringify(merchant),
            headers: getCommonHeader()
        }).then(function(response){
            let data = response.data;

            resolve(data);
        }).catch(error => {
            errorHandler(error);
            console.log(error.response);
            reject(error);
        })
    })
}

export function createNewMerchant() {
    let item = {
        id:0,
        title:'',
        description:'',
        address:'',
        postCode:'',
        contact:'',
        contactEmail:'',
        businessNumber:'',
        businessType:'',
        email:'',
        logo:'',
        images:[],
        documents:[],
        deleted:false,
        tags:[],
        b2b:false,
        b2c:false,
        rate:0,
        rateFloat:0.0,
        b2bRate:0,
        b2cRate:0,
        b2bRateFloat:0.0,
        b2cRateFloat:0.0,

    }
    return item;
}
export function getMerchantUsers(merchantId) {
    let address = process.env.VUE_APP_API_BASE_URL + `merchant/` + merchantId + '/users';
    return new Promise(function(resolve, reject) {
        axios( {
            method:'GET',
            url:address,
            headers: getCommonHeader()
        }).then(function(response) {

            resolve(response.data);
        }).catch(error => {
            errorHandler(error);
            console.log(error.response);
            reject(error);
        });
    })
}
export function getMerchantInvitations(merchantId) {
    let address = process.env.VUE_APP_API_BASE_URL + `merchant/` + merchantId + '/invitations';
    return new Promise(function(resolve, reject) {
        axios( {
            method:'GET',
            url:address,
            headers: getCommonHeader()
        }).then(function(response) {

            resolve(response.data);
        }).catch(error => {
            errorHandler(error);
            console.log(error.response);
            reject(error);
        });
    })
}
export function updateMerchantProduct(merchantId, product) {
    let address = process.env.VUE_APP_API_BASE_URL + 'merchant/' + merchantId + '/product';

    return new Promise(function(resolve, reject) {
        axios({
            method: 'post',
            url: address,
            data: JSON.stringify(product),
            headers: getCommonHeader()
        }).then(function(response){
            let data = response.data;

            resolve(data);
        }).catch(error => {
            errorHandler(error);
            console.log(error.response);
            reject(error);
        })
    })
}
export function updateMerchantProducts(merchantId, products) {
    let address = process.env.VUE_APP_API_BASE_URL + 'merchant/' + merchantId + '/products';

    return new Promise(function(resolve, reject) {
        axios({
            method: 'post',
            url: address,
            data: JSON.stringify(products),
            headers: getCommonHeader()
        }).then(function(response){
            let data = response.data;

            resolve(data);
        }).catch(error => {
            errorHandler(error);
            console.log(error.response);
            reject(error);
        })
    })
}
export function getMerchantProduct(merchantId, id) {
    let address = process.env.VUE_APP_API_BASE_URL + `merchant/${merchantId}/product/${id}`;
    return new Promise(function(resolve, reject) {
        axios( {
            method:'GET',
            url:address,
            headers: getCommonHeader()
        }).then(function(response) {

            resolve(response.data);
        }).catch(error => {
            errorHandler(error);
            console.log(error.response);
            reject(error);
        });
    })
}

export function getMerchantProducts(merchantId, query) {
    let address = process.env.VUE_APP_API_BASE_URL + `merchant/${merchantId}/products?` + query;
    return new Promise(function(resolve, reject) {
        axios( {
            method:'GET',
            url:address,
            headers: getCommonHeader()
        }).then(function(response) {

            resolve(response.data);
        }).catch(error => {
            errorHandler(error);
            console.log(error.response);
            reject(error);
        });
    })
}

export function getMerchantMetas(merchantId, type) {
    let address = process.env.VUE_APP_API_BASE_URL + `merchant/${merchantId}/metas?type=${type}`;
    return new Promise(function(resolve, reject) {
        axios( {
            method:'GET',
            url:address,
            headers: getCommonHeader()
        }).then(function(response) {

            resolve(response.data);
        }).catch(error => {
            errorHandler(error);
            console.log(error.response);
            reject(error);
        });
    })
}
export function updateMerchantMeta(merchantId, meta) {
    let address = process.env.VUE_APP_API_BASE_URL + 'merchant/' + merchantId + '/meta';

    return new Promise(function(resolve, reject) {
        axios({
            method: 'post',
            url: address,
            data: JSON.stringify(meta),
            headers: getCommonHeader()
        }).then(function(response){
            let data = response.data;

            resolve(data);
        }).catch(error => {
            errorHandler(error);
            console.log(error.response);
            reject(error);
        })
    })
}
export function updateMerchantMetas(merchantId, metas) {
    let address = process.env.VUE_APP_API_BASE_URL + 'merchant/' + merchantId + '/metas';

    return new Promise(function(resolve, reject) {
        axios({
            method: 'post',
            url: address,
            data: JSON.stringify(metas),
            headers: getCommonHeader()
        }).then(function(response){
            let data = response.data;

            resolve(data);
        }).catch(error => {
            errorHandler(error);
            console.log(error.response);
            reject(error);
        })
    })
}


export function getMerchantOrder(merchantId, orderId) {
    let address = process.env.VUE_APP_API_BASE_URL + `merchant/${merchantId}/order/${orderId}`;
    return new Promise(function(resolve, reject) {
        axios( {
            method:'GET',
            url:address,
            headers: getCommonHeader()
        }).then(function(response) {

            resolve(response.data);
        }).catch(error => {
            errorHandler(error);
            console.log(error.response);
            reject(error);
        });
    })
}
export function getMerchantOrders(merchantId, query) {
    let address = process.env.VUE_APP_API_BASE_URL + `merchant/${merchantId}/orders?${query}`;
    return new Promise(function(resolve, reject) {
        axios( {
            method:'GET',
            url:address,
            headers: getCommonHeader()
        }).then(function(response) {

            resolve(response.data);
        }).catch(error => {
            errorHandler(error);
            console.log(error.response);
            reject(error);
        });
    })
}

export function getMerchantPurchases(merchantId, query) {
    let address = process.env.VUE_APP_API_BASE_URL + `merchant/${merchantId}/purchases?${query}`;
    return new Promise(function(resolve, reject) {
        axios( {
            method:'GET',
            url:address,
            headers: getCommonHeader()
        }).then(function(response) {

            resolve(response.data);
        }).catch(error => {
            errorHandler(error);
            console.log(error.response);
            reject(error);
        });
    })
}

export function processMerchantOrder(merchantId, orderId, state) {
    let address = process.env.VUE_APP_API_BASE_URL + `merchant/${merchantId}/order/${orderId}/process/${state}`;
    return new Promise(function(resolve, reject) {
        axios( {
            method:'GET',
            url:address,
            headers: getCommonHeader()
        }).then(function(response) {

            resolve(response.data);
        }).catch(error => {
            errorHandler(error);
            console.log(error.response);
            reject(error);
        });
    })
}

export function cancelMerchantOrder(merchantId, orderId) {
    let address = process.env.VUE_APP_API_BASE_URL + `merchant/${merchantId}/order/${orderId}/cancel`;
    return new Promise(function(resolve, reject) {
        axios( {
            method:'GET',
            url:address,
            headers: getCommonHeader()
        }).then(function(response) {

            resolve(response.data);
        }).catch(error => {
            errorHandler(error);
            console.log(error.response);
            reject(error);
        });
    })
}

export function confirmMerchantOrder(merchantId, orderId) {
    let address = process.env.VUE_APP_API_BASE_URL + `merchant/${merchantId}/order/${orderId}/confirm`;
    return new Promise(function(resolve, reject) {
        axios( {
            method:'GET',
            url:address,
            headers: getCommonHeader()
        }).then(function(response) {

            resolve(response.data);
        }).catch(error => {
            errorHandler(error);
            console.log(error.response);
            reject(error);
        });
    })
}


export function submitMerchantOrder(order) {
    let address = process.env.VUE_APP_API_BASE_URL + 'merchant/order/submit';

    return new Promise(function(resolve, reject) {
        axios({
            method: 'post',
            url: address,
            data: JSON.stringify(order),
            headers: getCommonHeader()
        }).then(function(response){
            let data = response.data;

            resolve(data);
        }).catch(error => {
            errorHandler(error);
            console.log(error.response);
            reject(error);
        })
    })
}
