import { render, staticRenderFns } from "./ParkingPointView.vue?vue&type=template&id=1f3cbfc5&scoped=true&"
import script from "./ParkingPointView.vue?vue&type=script&lang=js&"
export * from "./ParkingPointView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f3cbfc5",
  null
  
)

export default component.exports