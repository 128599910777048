<template>
    <div style="width: 100%; height:100%; position: relative;">
        <div style="width: 100%; height: 50px; line-height: 50px;padding-left:20px;">
            발행자:<input v-model="issuer" style="width: 200px; height: 38px;"/>
            Ref: <input v-model="issuerReference" style="width: 200px; height: 38px;"/>
            <button class="btn btn-primary" @click="getItems"><i class="fas fa-search"></i>가져오기</button>
            <button class="btn btn-success" @click="downloadItems"><i class="fas fa-download"></i>다운받기</button>

            <div style="float:right; height: 50px; line-height: 50px; padding-right:20px;">
                <button class="btn btn-success" @click="newCoupon"><i class="fas fa-plus"></i>신규등록</button>
            </div>

        </div>
        <div style="width: 100%; height: calc(100% - 50px); overflow-y: auto;">
            <div class="container-fluid" style="padding-bottom: 50px;">
                <div class="row">
                    <div class="col-12">
                        <table class="table">
                            <tr style="position: sticky; top:0; background:white;">
                                <th>#</th><th>발행일</th><th>발행자</th><th>Ref</th><th>쿠폰명</th><th>CODE</th><th>정산금액</th><th>판매종료일</th><th>쿠폰시간</th><th>사용기간</th><th>사용자</th><th>사용자 사용</th>
                            </tr>
                            <tbody v-for="(item, index) in items">
                            <tr>
                                <td>{{index+1}}</td>
                                <td>{{dateTimeConverter(item.createdWhen)}}</td>
                                <td>{{item.issuer}}</td>

                                <td>{{item.issuerReference}}</td>

                                <td>{{item.title}}</td>
                                <td>{{item.reference}}</td>
                                <th>{{item.payoutAmount}}</th>
                                <td><span v-if="item.validToTime <= 0">무제한</span><span v-else>{{dateTimeConverter(item.validToTime)}}</span></td>
                                <td>{{item.creditTime/1000/60}}분</td>
                                <td>{{item.validForDays}}</td>
                                <td><span v-if="item.userId > 0">Y</span></td>
                                <td><span v-if="item.usedTime > 0">{{dateTimeConverter(item.usedTime)}}</span></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div style="position: absolute; top:0; left: 0; width: 100%; height: 100%; background-color: rgba(0,0,0,0.4);" v-if="editItem !== false">
            <div style="position: relative; top: 50%; left: 50%; width: 1000px; height: 80%; transform: translate(-50%, -50%); background-color: white;">
                <div style="height: 50px; width: 100%; line-height: 50px; display: flex; font-weight: bold;">
                    <div style="height: 50px; width: calc(100% - 100px); line-height: 50px; padding-left: 20px;">
                        쿠폰 발행
                    </div>
                    <div style="height: 50px; width: 100px; line-height: 50px; text-align: right; padding-right: 20px;">
                        <span @click="editItem = false">닫기</span>
                    </div>

                </div>
                <div style="height: calc(100% - 50px); width: 100%; display: flex;">
                    <div style="width: 50%; height: 100%; overflow-y: auto;">
                        <div class="container text-left input-form" style="padding-bottom: 50px;">
                            <div class="row">
                                <div class="col-12">
                                    발행자
                                    <input v-model="editItem.issuer" class="form-control"/>
                                </div>

                            </div>

                            <div class="row">
                                <div class="col-12">
                                    발행 Reference
                                    <input v-model="editItem.issuerReference" class="form-control"/>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12">
                                    <strong>발행매주 (*)</strong>
                                    <input type="number" v-model="editItem.howMany" class="form-control"/>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12">
                                    판매종료일
                                    <input type="date" v-model="editItem.expiryDate" class="form-control"/>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    쿠폰 제목
                                    <input v-model="editItem.title" class="form-control"/>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <strong>정산금액 (*)</strong>
                                    <input type="number" v-model="editItem.payoutAmount" class="form-control"/>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    쿠폰종류
                                    <select class="form-control" v-model="editItem.timeLimited" >
                                        <option :value="false">일반</option>
                                        <option :value="true">시간 (정해진 시간내 무제한)</option>

                                    </select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    양도가능
                                    <select class="form-control" v-model="editItem.transferable">
                                        <option :value="false">불가능</option>
                                        <option :value="true">가능</option>

                                    </select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    사용시간
                                </div>
                                <div class="col-6">
                                    <select class="form-control" v-model="editItem.hours">
                                        <option v-for="(h, hi) in 25" :value="hi">{{hi}}시간</option>
                                    </select>
                                </div>
                                <div class="col-6">
                                    <select class="form-control" v-model="editItem.minutes">
                                        <option v-for="(m, mi) in 5" :value="mi*15">{{mi*15}}분</option>
                                    </select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    쿠폰 구매후 사용기한 일수
                                    <select class="form-control" v-model="editItem.validForDays">
                                        <option value="0">선택하세요</option>
                                        <option value="1">24시간</option>
                                        <option value="7">7일</option>
                                        <option value="30">30일</option>
                                        <option value="60">60일</option>
                                        <option value="90">90일</option>
                                        <option value="180">180일</option>
                                        <option value="365">1년</option>
                                        <option value="730">2년</option>
                                    </select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    설명
                                    <vue-editor
                                        v-model="editItem.description"/>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    <button class="btn btn-dark" @click="editItem = false">취소</button>
                                </div>
                                <div class="col-6 text-right">
                                    <button class="btn btn-primary" @click="submitItem">등록하기</button>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div style="width: 50%; height: 100%; overflow-y: auto;">
                        <template v-if="editItem.issuer !== null && editItem.issuer.length > 0 && editItem.issuerReference !== null && editItem.issuerReference.length > 0">
                        <div>
                            쿠폰 이미지
                        </div>
                        <div>
                        <progress value="0" max="100" id="uploader"></progress>
                        <input accept="image/*" type="file" ref="file" value="upload" @change="imageSelected">
                        <img width="100%" :src="editItem.imageUrl"/>
                        </div>
                        직접입력
                        <input
                            v-model="editItem.imageUrl" class="form-control"/>

                        </template>
                        <div class="container">
                            <div class="row">
                                <div class="col-12">
                                    서비스 지역 선택 (선택하지 않으면 전 지역 사용가능)
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div style="width: 100%; display: inline-block; border:1px solid black;">
                                        <div style="padding:10px; float: left; margin: 10px; border: 1px solid grey; border-radius: 10px;" v-for="t in editItem.tags">
                                            {{t.name}} <span @click="removeTag(t)"><i class="fas fa-trash"></i></span>
                                        </div>
                                    </div>
<!--                                    <hash-tag-input v-if="editItem.tags != null" v-model="editItem.tags"/>-->
                                </div>
                                <div class="col-12">
                                    아래에서 선택해주세요
                                    <div style="width: 100%; display: inline-block; border:1px solid black;">
                                        <div style="padding:10px; float: left; margin: 10px; border: 1px solid grey; border-radius: 10px;" v-for="t in serviceTags" v-if="isTagOnList(t) == false" @click="onTagClicked(t)">
                                            {{t.name}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {millisToDate, showDialog} from "../../api/common";
    import {generateIssuerCoupons, getIssuedCoupons, getIssuedCouponsReport} from "../../api/credit";
    import {VueEditor} from "vue2-editor";
    import {getTagList} from "../../api/tag";
    import HashTagInput from "../../inputs/HashTagInput";
    import {getApp} from "firebase/app";
    import {getDownloadURL, getStorage, ref, uploadBytesResumable} from "firebase/storage";
    import moment from "moment";

    // import "firebase/auth";
    // import "firebase/storage";
    export default {
        name: "IssuerView",
        data() {
            return {
                editItem: false,
                items:[],
                issuer:'',
                issuerReference:'',
                serviceTags:[]
            }
        },
        components: {
            HashTagInput,
            VueEditor,
        },
        created() {

        },
        mounted() {
            this.initialise();
        },
        beforeDestroy() {
        },
        destroyed() {
        },
        methods: {
            dateTimeConverter: millisToDate,
            initialise: function() {
                let self = this;
                getTagList('service')
                .then(function(data) {
                    console.log(data);
                    if(data.success == true) {
                        self.$data.serviceTags = data.list;

                    }
                })
            },
            downloadItems: function() {
                if(this.$data.issuer === null || this.$data.issuer.length === 0) {
                    showDialog("검색하고자 하는 발행자를 입력하세요");
                    return;
                }
                // if(this.$data.issuerReference === null || this.$data.issuerReference.length === 0) {
                //     showDialog("검색하고자 하는 발행자의 발행 Reference를 입력하세요");
                //     return;
                // }

                const FileDownload = require('js-file-download');
                let fileName = this.$data.issuer;
                if(this.$data.issuerReference !== null && this.$data.issuerReference.length > 0) {
                    fileName += "-" + this.$data.issuerReference;
                }
                fileName += "-Coupon-Issued.xlsx";
                getIssuedCouponsReport(this.$data.issuer, this.$data.issuerReference === ""?null:this.$data.issuerReference)
                    .then(function(response) {
                        FileDownload(response.data, fileName);
                    })
                    .catch(function(err) {
                        let msg = err;
                        if(err.response !== undefined) {
                            msg += ' (' + err.response + ')';
                        }
                        showDialog(msg, "ERROR");
                    })
            },
            getItems: function() {
                if(this.$data.issuer === null || this.$data.issuer.length === 0) {
                    showDialog("검색하고자 하는 발행자를 입력하세요");
                    return;
                }
                // if(this.$data.issuerReference === null || this.$data.issuerReference.length === 0) {
                //     showDialog("검색하고자 하는 발행자의 발행 Reference를 입력하세요");
                //     return;
                // }

                this.$data.items = [];
                let self = this;
                getIssuedCoupons(this.$data.issuer, this.$data.issuerReference === ""?null:this.$data.issuerReference)
                .then(function(data) {
                    if(data.success === true) {
                        self.$data.items = data.list;
                    } else {
                        showDialog(data.message);
                    }
                })
                .catch(function(err) {
                    let msg = err;
                    if(err.response !== undefined) {
                        msg += ' (' + err.response + ')';
                    }
                    showDialog(msg, "ERROR");
                })

            },
            newCoupon: function() {
                let newItem = {
                    id:0,
                    type:'coupon',
                    issuer:'',
                    issuerReference:'',
                    toBeClaimed: true,
                    payoutAmount:0,
                    title:'',
                    description:'',
                    creditTime:0,
                    transferable:false,
                    timeLimited:true,
                    hours:0,
                    minutes:0,
                    imageUrl:'',
                    tags:[],
                    expiryDate:'',
                    validToTime:0,
                    validForDays:0,
                    howMany:0,

                }
                this.$data.editItem = newItem;
            },
            imageSelected: function(e) {
                const uploader = document.getElementById('uploader');
                //get file
                console.log("File Btn Added");
                console.log(e);
                let getFile = e.target.files[0];
                console.log("File name - " + getFile.name);
                //set storage ref
                let d = new Date();
                let name  =  moment(d).format('YYYYMMDD-hhmmss');
                let names = getFile.name.split('.');
                let fileExt = names[names.length-1];
                let path = 'ncm-product/' + this.$data.editItem.issuer + '/' + this.$data.editItem.issuerReference + '-' + name + '.' + names[names.length-1];
                const firebaseApp = getApp();
                let contentType = null;
                if(fileExt.toUpperCase() === 'JPG' || fileExt.toUpperCase() === 'JPEG') {
                    contentType = 'image/jpeg';
                } else if(fileExt.toUpperCase() === 'PNG') {
                    contentType = 'image/png';
                } else if(fileExt.toUpperCase() === 'PDF') {
                    contentType = 'application/pdf';
                }
                const metadata = {
                    contentType: contentType
                };
                const storage = getStorage(firebaseApp);
                let storageRef = ref(storage, path);
                const uploadTask = uploadBytesResumable(storageRef, getFile, metadata);
                let self = this;
                uploadTask.on('state_changed',
                    (snapshot) => {
                        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        console.log('Upload is ' + progress + '% done');
                        uploader.value = progress;
                        switch (snapshot.state) {
                            case 'paused':
                                console.log('Upload is paused');
                                break;
                            case 'running':
                                console.log('Upload is running');
                                break;
                        }
                    },
                    (error) => {
                        // EventBus.$emit("showLoading", false);
                        // A full list of error codes is available at
                        // https://firebase.google.com/docs/storage/web/handle-errors
                        switch (error.code) {
                            case 'storage/unauthorized':
                                // User doesn't have permission to access the object
                                break;
                            case 'storage/canceled':
                                // User canceled the upload
                                break;

                            // ...

                            case 'storage/unknown':
                                // Unknown error occurred, inspect error.serverResponse
                                break;
                        }
                    },
                    () => {
                        // Upload completed successfully, now we can get the download URL
                        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                            console.log('File available at', downloadURL);
                            self.editItem.imageUrl = downloadURL;
                        });
                    }
                );
                // mountainsRef.put(getFile).on('state_changed',
                //     function progress(snapshot){
                //
                //         let percentage = (snapshot.bytesTransferred / snapshot.totalBytes) *100;
                //         console.log("percentage " + percentage);
                //         uploader.value = percentage;
                //     },
                //     function error(err){
                //         console.log(err);
                //     },
                //     function complete(){
                //         console.log('complete upload');
                //         self.editItem.imageUrl = 'https://storage.googleapis.com/ncm-mary-254508.appspot.com/' + path;
                //     }
                // );
            },
            removeTag: function(tag) {
                for(let i = 0 ; i < this.editItem.tags.length ; i++) {
                    if(this.editItem.tags[i].id === tag.id) {
                        this.editItem.tags.splice(i, 1);
                    }
                }
            },
            isTagOnList: function(tag) {
                for(let i = 0 ; i < this.editItem.tags.length ; i++) {
                    if(this.editItem.tags[i].id === tag.id) {
                        return true;
                    }
                }
                return false;
            },
            onTagClicked: function(tag) {
                for(let i = 0 ; i < this.editItem.tags.length ; i++) {
                    if(this.editItem.tags[i].id === tag.id) {
                        return;
                    }
                }
                this.editItem.tags.push(JSON.parse(JSON.stringify(tag)));
            },
            submitItem: function() {
                if(this.$data.editItem.issuer === null || this.$data.editItem.issuer.length === 0) {
                    showDialog("발행자를 입력해주세요");
                    return;
                }
                if(this.$data.editItem.issuerReference === null || this.$data.editItem.issuerReference.length === 0) {
                    showDialog("발행 Reference를 입력해주세요");
                    return;
                }
                if(this.$data.editItem.howMany === null || parseInt(this.$data.editItem.howMany) <= 0) {
                    showDialog("발행매수를 입력해주세요");
                    return;
                }
                if(this.$data.editItem.title === null || this.$data.editItem.title.length === 0) {
                    showDialog("제목을 입력해주세요");
                    return;
                }
                if(this.$data.editItem.hours === 0 && this.$data.editItem.minutes === 0) {
                    showDialog("사용 시간을 입력해주세요");
                    return;
                }

                if(this.$data.editItem.payoutAmount === null || parseInt(this.$data.editItem.payoutAmount) <= 0) {
                    showDialog("정산금액을 입력해주세요");
                    return;
                }

                let today = millisToDate(new Date().getTime());
                if(this.$data.editItem.expiryDate === '' || this.$data.editItem.expiryDate === '0000-00-00' || this.$data.editItem.expiryDate <= today) {
                    showDialog("판매 종료일을 선택해주세요");
                    return;
                }
                if(this.$data.editItem.validForDays === 0) {
                    showDialog("구매후 사용기간 일수를 선택해주세요");
                    return;
                }
                this.$data.editItem.creditTime = (this.$data.editItem.hours * 60 * 60 + this.$data.editItem.minutes * 60) * 1000;
                let ed = new Date(this.$data.editItem.expiryDate);
                ed.setHours(23,59,59,999);
                this.$data.editItem.validToTime = ed.getTime();



                let self = this;
                generateIssuerCoupons(this.$data.editItem, this.$data.editItem.howMany)
                .then(function(data) {
                    if(data.success === true) {
                        self.$data.items = data.list;
                        self.$data.editItem = false;
                        showDialog(data.list.length + "매가 성공적으로 생성되었습니다.");
                    } else {
                        showDialog(data.message);
                    }
                })
                .catch(function(err) {
                    let msg = err;
                    if(err.response !== undefined) {
                        msg += ' (' + err.response + ')';
                    }
                    showDialog(msg, "ERROR");
                })
            }

        }
    }
</script>

<style scoped>
.input-form > .row {
    margin-top: 10px;
}
</style>
