<template>
    <div class="detail-view">

        <div class="col-12 text-right mary-button-container" style="position: sticky; top:0; background-color: white; z-index: 2; box-shadow: 2px 2px 3px gray;">
            <div class="mary-button" style="background-color: black;" v-on:click="onClickCloseBtn()"><span><i class="fas fa-times"></i></span> {{$t('detail.close')}}</div>
            <div class="mary-button" v-show="!isEditMode" v-on:click="onClickEditBtn()"><span><i class="fas fa-edit"></i></span> {{$t('detail.edit')}}</div>
            <div class="mary-button" v-show="isEditMode" v-on:click="onClickSaveBtn()"><span><i class="fas fa-save"></i></span> {{$t('detail.save')}}</div>
            <div class="mary-button" style="background-color: red; float:left;" v-show="!isAddMode" v-on:click="onClickRemoveBtn()"><span><i class="fas fa-trash"></i></span> {{$t('detail.remove')}}</div>
        </div>

        <div class="head-group">
            <div class="top"></div>
            <div class="bottom">
                <div class="content-header">
                    <vue-form style="width: 100%;"
                              label-width="100%"
                              label-position="left">
                        <vue-form-item label="푸쉬알람" v-if="isEditMode">
                            <vue-radio
                                v-model="sendNotification"

                                :value=1
                                :disabled="!isEditMode">
                                푸쉬알림 보내기
                            </vue-radio>
                            <vue-radio
                                v-model="sendNotification"

                                :value=0
                                :disabled="!isEditMode">
                                푸쉬알림 보내지 않
                            </vue-radio>
                        </vue-form-item>
                        <AccessDropdownInput
                            :is-read-only="!isEditMode"
                            v-bind:operatorId="item.operatorId"
                            v-on:onClick="setOperatorId"/>
                        <vue-form-item label="플레이스(Post.placeId)">
                            <vue-input
                                v-model="item.placeId"
                                :disabled="!isEditMode">
                            </vue-input>
                        </vue-form-item>
                        <vue-form-item label="공지사항 종류">
                            <vue-select
                                :disabled="!isEditMode"
                                v-model="item.type"
                                :data="typeList">
                                <vue-option
                                    v-for="(type, index) in typeList"
                                    :key="'type-' + index"
                                    :value="type.value"
                                    :label="type.label">
                                </vue-option>
                            </vue-select>
                        </vue-form-item>
                        <vue-form-item :label="$t('content.title')">
                            <vue-input
                                v-model="item.title"
                                :disabled="!isEditMode">
                            </vue-input>
                        </vue-form-item>
                        <vue-form-item label="이름">
                            <vue-input
                                v-model="item.name"
                                :disabled="!isEditMode">
                            </vue-input>
                        </vue-form-item>
                        <label><strong>대표 이미지 URL</strong></label>
                        <div style="width: 100%;">
                        <img style="width: 100%; max-width: 400px;" :src="item.imageUrl"/>

                        <progress v-if="isEditMode" value="0" max="100" id="uploader"></progress>
                        <input accept="image/*" type="file" ref="file" value="upload" @change="fileBtn" :disabled="!isEditMode">
                        </div>

                        <vue-form-item label="위도(Post.lat)">
                            <vue-input
                                v-model="item.lat"
                                :disabled="!isEditMode">
                            </vue-input>
                        </vue-form-item>
                        <vue-form-item label="경도(Post.lon)">
                            <vue-input
                                v-model="item.lon"
                                :disabled="!isEditMode">
                            </vue-input>
                        </vue-form-item>
                        <vue-form-item label="공지 시작 시간(Post.timeFrom)">
                            <datetime
                                type="date"
                                v-model="fromDate"
                                format="yyyy.MM.dd"
                                value-zone="UTC+09:00"
                                :disabled="!isEditMode"
                                use12-hour/>
                        </vue-form-item>
                        <vue-form-item label="공지 종료 시간(Post.timeTo)">
                            <datetime
                                type="date"
                                v-model="toDate"
                                format="yyyy.MM.dd"
                                value-zone="UTC+09:00"
                                :disabled="!isEditMode"
                                use12-hour/>
                        </vue-form-item>
                        <vue-form-item label="공지 사항 간단 내용 (앱 푸쉬알림에 보여줄 내용)">
                            <vue-input
                                v-model="item.shortContent"
                                :disabled="!isEditMode">
                            </vue-input>
                        </vue-form-item>
                        <vue-form-item label="공지 사항 내용">
                            <vue-editor
                                v-model="item.content"
                                :disabled="!isEditMode"/>
                        </vue-form-item>
                        <hr>
                        <hash-tag-input v-if="item.tags != null" v-model="item.tags" v-bind:isReadOnly="!isEditMode"/>
                        <div style="width: 100%; display: inline-block; border:1px solid black;" v-if="isEditMode">
                            <div style="padding:10px; float: left; margin: 10px; border: 1px solid grey; border-radius: 10px;" v-for="t in serviceTags" v-if="isTagOnList(t) == false" @click="onTagClicked(t)">
                                {{t.name}}
                            </div>
                        </div>
<!--                        <hash-tag-input v-model="item.tags" v-bind:isReadOnly="!isEditMode"/>-->
                    </vue-form>
                </div><!-- ./content-header -->
            </div><!-- ./bottom -->
        </div><!-- ./head-group -->
    </div>
</template>

<script>
    import AccessDropdownInput from "../../../inputs/OperatorDropdownInput";
    import HashTagInput from "../../../inputs/HashTagInput";
    import {setTagsByOperatorId} from "../../../api/operator";
    import {writePost, deletePost, getPostTypes} from "../../../api/post";

    import { VueEditor } from "vue2-editor";
    import { Datetime } from 'vue-datetime';
    import {toDateFormat, toTimestamp} from "../../../utils/TimeConverter";
    import moment from "moment";
    import {getApp} from "firebase/app";
    import {getDownloadURL, getStorage, ref, uploadBytesResumable} from "firebase/storage";
    // import * as firebase from "firebase/app";
    // import "firebase/auth";
    // import "firebase/storage";
    import {getTagList} from "../../../api/tag";

    export default {
        name: "PostEditor",
        components: {
            AccessDropdownInput,
            HashTagInput,

            Datetime,
            VueEditor,
        },
        props: {
            item: {
                type: Object,
                default: function() { return{} }
            },
            isAddMode: {
                type: Boolean,
                default: true
            },
        },
        data(){
          return {
              typeList : getPostTypes(),

              onOffValue: 0,

              isEditMode: this.isAddMode,

              fromDate: null,
              toDate: null,

              sendNotification: 0,
              serviceTags:[],
          }
        },
        created() {
            this.fromDate = this.item.timeFrom ? toDateFormat(this.item.timeFrom) : null;
            this.toDate = this.item.timeTo ? toDateFormat(this.item.timeTo) : null;
            this.getTagList();
        },
        watch:{
            $route(){
                this.isEditMode = this.isAddMode;

                console.log("post edit route");
                console.log(this.item);

                // if(this.item.timeFrom != null && this.item.timeFrom.length > 0 && this.item.timeFrom !== '0000-00-00') {
                this.fromDate = this.item.timeFrom ? toDateFormat(this.item.timeFrom) : null;

                // }
                this.toDate = this.item.timeTo ? toDateFormat(this.item.timeTo) : null;
                console.log(this.fromDate);
                console.log(this.toDate);

            }
        },
        methods: {
            setOperatorId: function(id) {
                setTagsByOperatorId(id, this.item);
            },
            getTagList: function() {
                let self = this;
                console.log("get tag list");
                getTagList('service')
                    .then(function(data) {
                        console.log(data);
                        if(data.success == true) {
                            self.$data.serviceTags = data.list;

                        }
                    })
            },
            isTagOnList: function(tag) {
                for(let i = 0 ; i < this.item.tags.length ; i++) {
                    if(this.item.tags[i].id == tag.id) {
                        return true;
                    }
                }
                return false;
            },
            onTagClicked: function(tag) {
                for(let i = 0 ; i < this.item.tags.length ; i++) {
                    if(this.item.tags[i].id == tag.id) {
                        return;
                    }
                }
                this.item.tags.push(JSON.parse(JSON.stringify(tag)));
            },
            onClickSaveBtn: function() {
                console.log("from date" + this.fromDate);
                this.item.timeFrom = toTimestamp(this.fromDate);
                this.item.timeTo = toTimestamp(this.toDate);

                this.item.push = this.sendNotification == 1?true:false;
                console.log(this.item);
                let that = this;
                writePost(that.item).then(function (item) {
                    alert("저장 되었습니다.");

                    that.isEditMode = false;

                    that.$emit("updateList");

                    that.$router.push(`/admin/notice?id=${item.id}`);
                })
            },
            onClickCloseBtn: function() {
                this.isEditMode = false;

                this.$emit('onClose', null);

                // if(this.isAddMode || !this.isEditMode) {
                //     this.$router.push(`/admin/${this.modelKey}`);
                // } else {
                //     this.$router.push(`/admin/${this.modelKey}?id=${this.$route.query.id}`);
                // }
            },
            onClickEditBtn: function() {
                this.isEditMode = true;

                // this.$router.push(`/admin/${this.modelKey}?id=${this.$route.query.id}&edit`);
            },
            onClickRemoveBtn: function () {
                if(confirm("정말로 삭제하시겠습니까?")) {
                    this.item.deleted = true;

                    let that = this;
                    deletePost(that.item.id).then(function (data) {
                        alert("삭제 되었습니다.");

                        that.$emit("updateList")

                        that.$emit('onClose', null);
                    });
                }
            },
            fileBtn:function(e){
                // e.preventDefault();
                const uploader = document.getElementById('uploader');
                //get file
                console.log("File Btn Added");
                console.log(e);
                let getFile = e.target.files[0];
                console.log("File name - " + getFile.name);
                //set storage ref
                let d = new Date();
                let name  =  moment(d).format('YYYYMMDD-hhmmss');
                let names = getFile.name.split('.');
                let fileExt = names[names.length-1];
                let path = 'ncm-post/' + this.item.id + '-' + name + '.' + names[names.length-1];
                const firebaseApp = getApp();
                let contentType = null;
                if(fileExt.toUpperCase() === 'JPG' || fileExt.toUpperCase() === 'JPEG') {
                    contentType = 'image/jpeg';
                } else if(fileExt.toUpperCase() === 'PNG') {
                    contentType = 'image/png';
                } else if(fileExt.toUpperCase() === 'PDF') {
                    contentType = 'application/pdf';
                }
                const metadata = {
                    contentType: contentType
                };
                const storage = getStorage(firebaseApp);
                let storageRef = ref(storage, path);
                const uploadTask = uploadBytesResumable(storageRef, getFile, metadata);
                let self = this;
                uploadTask.on('state_changed',
                    (snapshot) => {
                        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        console.log('Upload is ' + progress + '% done');
                        uploader.value = progress;
                        switch (snapshot.state) {
                            case 'paused':
                                console.log('Upload is paused');
                                break;
                            case 'running':
                                console.log('Upload is running');
                                break;
                        }
                    },
                    (error) => {
                        // EventBus.$emit("showLoading", false);
                        // A full list of error codes is available at
                        // https://firebase.google.com/docs/storage/web/handle-errors
                        switch (error.code) {
                            case 'storage/unauthorized':
                                // User doesn't have permission to access the object
                                break;
                            case 'storage/canceled':
                                // User canceled the upload
                                break;

                            // ...

                            case 'storage/unknown':
                                // Unknown error occurred, inspect error.serverResponse
                                break;
                        }
                    },
                    () => {
                        // Upload completed successfully, now we can get the download URL
                        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                            console.log('File available at', downloadURL);
                            self.item.imageUrl = downloadURL;
                        });
                    }
                );
                // mountainsRef.put(getFile).on('state_changed',
                //     function progress(snapshot){
                //
                //         let percentage = (snapshot.bytesTransferred / snapshot.totalBytes) *100;
                //         console.log("percentage " + percentage);
                //         uploader.value = percentage;
                //     },
                //     function error(err){
                //         console.log(err);
                //     },
                //     function complete(){
                //         console.log('complete upload');
                //         self.item.imageUrl = 'https://storage.googleapis.com/ncm-mary-254508.appspot.com/' + path;
                //     }
                // );

            }
        }
    }
</script>
