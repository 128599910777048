import {getCommonHeader} from "./common";
import {cleanLocalStorage} from "../store/auth.module";

const axios = require('axios');

export function writeMagazine(item) {
    let address = process.env.VUE_APP_API_BASE_URL + 'admin/magazine';

    return new Promise(function(resolve, reject) {
        axios({
            method: 'post',
            url: address,
            data: JSON.stringify(item),
            headers: getCommonHeader()
        }).then(function(response){
            let data = response.data.item;

            resolve(data);
        }).catch(error => {
            if(error.response.status === 401)
                cleanLocalStorage();
            else
                console.log(error.response);
        })
    })
}

export function newMagazine() {
  return   {
      id: 0,
      parentId: 0,
      operatorId: 0,
      userId: 0,

      title: "",
      name: "",
      type: "",
      imageUrl: "",
      content: "",

      deleted: false,

      contentType:"webzine",
      placeId:0,
      productId:0,
      tags: [],
      hotDeal: false,
  }
}
export function getMagazineContentTypes() {
    let list = [
        {
            name:'웹진',
            value:'webzine',
        },
        {
            name:'플레이스',
            value:'place',
        },
        {
            name:'상품',
            value:'product',
        },
    ];
    return list;
}
export function getMagazineTypes() {
    let list = [
        {
            name:'웹진',
            value:'webzine',
        },
        {
            name:'메리바이크',
            value:'marybike',
        },
        {
            name:'무인마켓',
            value:'maryshop',
        },
        {
            name:'플레이예약',
            value:'marybooking',
        },
        {
            name:'오락',
            value:'fun',
        },
    ];
    return list;
}
