import Vue from 'vue'
import Vuex from 'vuex'

import {auth} from "./auth.module";
import {ROLE_ADMIN, ROLE_PLACE_STAFF, ROLE_PLACE_MANAGER, ROLE_SUPER_ADMIN} from "../api/user";

Vue.use(Vuex);

export function getRoleId() {
    return Number(localStorage.getItem('role'));
}

export function isSuperAdmin() {
    return ROLE_SUPER_ADMIN === Number(localStorage.getItem('role'));
}
export function isOperatorManager() {
    return ROLE_ADMIN === Number(localStorage.getItem('role'));
}
export function isPlaceUser() {
    return (ROLE_PLACE_MANAGER === Number(localStorage.getItem('role')) || ROLE_PLACE_STAFF === Number(localStorage.getItem('role')))  ;
}

export function isPlaceManager() {
    return ROLE_PLACE_MANAGER === Number(localStorage.getItem('role'))  ;
}

export function isPlaceStaff() {
    return ROLE_PLACE_STAFF === Number(localStorage.getItem('role'))  ;
}

export function getUserId() {
    return Number(localStorage.getItem('id'));
}

export function getOperatorId() {
    if(isSuperAdmin()) return 0;

    return Number(localStorage.getItem('oid'));
}

export function getUserName() {
    return localStorage.getItem('name');
}

export default new Vuex.Store({
    modules: {
        auth
    }
})
