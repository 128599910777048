<template>
    <div class="list-detail-view">
        <DetailLayout
            v-bind:is-available-more-btn="itemsToShow < itemList.length"
            :on-detail="detailItem != null"
            v-on:onRequestMoreList="itemsToShow += 5">
            <template v-slot:list-title-text>
                {{$t('menu.place.list.place')}} 관리
            </template>
            <template v-slot:map-container>
                <gmap-map
                    ref="gmap"
                    :center="mapCenter"
                    :zoom="mapZoom"
                    :options="{
                        streetViewControl:false,
                        fullscreenControl:false,
                        mapTypeControl:false
                    }"
                    class="google-map"
                    style="width: 100%; height: 500px;">
                    <gmap-marker
                        :key="index"
                        v-for="(m, index) in markers"
                        :position="m.position">
                    </gmap-marker>
                </gmap-map>
            </template>
            <template v-slot:top-items>
                <div class="list-top-btns">
                    <select v-model="selectedPlaceClass" @change="onPlaceClassChanged">
                        <option value="">All</option>
                        <option v-for="p in placeClasses" :value="p.value">{{p.name}}</option>
                    </select>
                    <div class="add-item" v-on:click="onClickAddBtn()">
                        <span style="margin-right: 5px;">{{$t('list.add')}}</span>
                        <span class="fas fa-plus"></span>
                    </div>
                    <div class="add-item" v-on:click="onClickExcelUploadBtn()">
                        <input type="file" ref="file" style="display: none" v-on:change="onExcelSelected"/>
                        <span style="margin-right: 5px;">{{$t('list.upload')}}</span>
                        <span class="fas fa-file-excel"></span>
                    </div>
                    <div class="add-item" v-on:click="onClickExcelDownloadBtn()">
                        <span style="margin-right: 5px;">{{$t('list.download')}}</span>
                        <span class="fas fa-file-excel"></span>
                    </div>
                </div>
                <div class="sort-btn-wrapper">
                    <sort-and-search-input
                        :sort-type-list="sortTypeList"

                        v-on:onEnterSearch="onSearchTags"
                        v-on:onClickSortType="onClickSortType"/>
                </div>
            </template>
            <template v-slot:list-items>
                <div v-if="itemList.length === 0" class="empty-list">
                    <p>{{$t('list.has_no_material')}}</p>
                </div>
                <div v-else>
                    <template v-for="(item, index) in itemList">
                        <place-item v-if="index < itemsToShow"
                              v-bind:item="item"
                              v-on:onClick="onClickItem" />
                    </template>
                </div>
            </template>
            <template v-slot:detail-item>
                <place-editor
                    v-bind:item="detailItem"

                    v-on:onClose="onClickItem"
                    v-on:updateList="updateListView"

                    :is-add-mode="isAddMode"/>
            </template>
        </DetailLayout>
    </div>
</template>

<script>
    import {clone} from "../../utils/VarToString";

    var lodash = require('lodash'); // order by 위해 사용

    import BoundaryMapView from "../BoundaryMapView";
    import DropdownInput from "../../inputs/DropdownInput";

    import DetailLayout from "../DetailLayout";
    import {getAssetById} from "../../api/asset";
    import {checkOperator, getItemList, includesTag, isOk, reorderItems} from "../../api/common";
    import {setTagsByOperatorId} from "../../api/operator";
    import {getOperatorId, isSuperAdmin, isPlaceManager, isPlaceStaff, getRoleId} from "../../store";
    import {getPlaceById, newPlace, writePlace, writePlaces, getPlaceExcel, getPlaceClassList} from "../../api/place";
    import PlaceItem from "../../components/contents/place/Item";
    import PlaceEditor from "../../components/contents/place/Editor";
    import SortAndSearchInput from "../../inputs/SortAndSearchInput";

    export default {
        name: "PlaceView",
        components: {
            SortAndSearchInput,
            PlaceEditor,
            PlaceItem,
            BoundaryMapView,
            DetailLayout,
            DropdownInput,
        },
        data() {
            return {
                mapCenter: {
                    lat: 36.5, lng:127.8
                },
                mapZoom: 7,
                markers: [],

                placeClasses:getPlaceClassList(),
                itemsToShow: 200,
                sortTypeList: [
                    {
                        name: this.$t('list.sort_name'),
                        value: 'title',
                        order: 'asc'
                    },
                    {
                        name: this.$t('list.sort_type'),
                        value: 'type',
                        order: 'asc'
                    }
                ],
                currentSortType: {},

                itemList: [],
                detailItem: null,

                isEditMode: false,
                isAddMode: false,

                selectedPlaceClass:'',
                state: {
                    tickets: [{name: "test"}],
                    headers: ["Test header"]
                },
                userRole:0,

            }
        },
        created() {
            this.$data.userRole = getRoleId();
            this.updateListView();
            this.updateEditorView();
        },
        methods: {
            onPlaceClassChanged: function() {
                this.updateListView();
            },
            updateListView: function(check = isOk) {
                this.isAddMode = false;

                let that = this;

                let query = null;
                if(this.$data.selectedPlaceClass !== '' && this.$data.selectedPlaceClass.length > 0) {
                    query = 'typeClass=' + this.$data.selectedPlaceClass;
                }
                console.log("qeury "+ query);
                getItemList("place", query).then(function(items){
                    that.itemList = [];
                    that.markers = [];

                    let sumLat = 0;
                    let sumLng = 0;

                    console.log("Places");
                    console.log(items);
                    items.forEach(function (e) {
                        if(check(e)) {
                            sumLat += e.lat;
                            sumLng += e.lon;

                            that.markers.push({
                                id: e.id,
                                position: {
                                    lat: parseFloat(e.lat),
                                    lng: parseFloat(e.lon)
                                },
                            });

                            that.itemList.push(e);
                        }
                    });

                    that.itemList = reorderItems(that.itemList, that.currentSortType)
                })
            },
            updateEditorView: function() {
            //     this.isEditMode = this.$route.query.hasOwnProperty('edit');
            //     this.isAddMode = this.$route.query.hasOwnProperty('new')
            //
                if (this.$route.query.hasOwnProperty('id')) {
                    let contentId = this.$route.query.id;

                    let that = this;
                    getPlaceById(contentId).then(item => {
                        if(isOk(item)) that.detailItem = item;
                    });
            //     } else if (this.isAddMode) {
            //         let that = this
            //
            //         that.detailItem = newPlace()
            //         setTagsByOperatorId(getOperatorId(), that.detailItem);
            //
            //         this.isEditMode = true
            //     } else {
            //         this.isEditMode = false
                }
            },
            onClickSortType: function(selectedOption) {
                this.currentSortType = selectedOption;

                this.items = reorderItems(this.items, this.currentSortType);
            },
            onClickAddBtn: function() {
                this.isAddMode = true;

                this.detailItem = newPlace();

                setTagsByOperatorId(getOperatorId(), this.detailItem);

                this.$router.push(`/admin/place?new`);
            },
            onClickItem: function(item) {
                this.detailItem = clone(item);

                let path = `/admin/place`;
                if(item) {
                    path += `?id=${item.id}`;
                }

                if(this.$route.fullPath !== path) {
                    this.$router.push(path);
                }
            },
            onSearchTags(v) {
                this.updateListView(e => { return isOk(e) && includesTag(v, e.tags) });
            },
            onClickExcelDownloadBtn: function() {
                const FileDownload = require('js-file-download');
                let self = this;
                getPlaceExcel()
                .then(function(response) {
                    FileDownload(response.data, "placeList.xlsx");
                })
            },
            onClickExcelUploadBtn: function() {
                this.$refs.file.value = "";
                this.$refs.file.click()
            },

            createPlaceList: function(list) {
                if(list == null && list.length == 0) {
                    return;
                }

                let self = this;
                writePlaces(list).then(function (data) {
                    this.itemList = [];
                    if(data.success == true) {
                        let items = data.list;
                        for(let i = 0 ; i < items.length ; i++) {
                            self.$data.itemList.push(items[i]);
                        }

                    }

                    // list.splice(0,1);
                    // self.createPlaceList(list);
                });
            },
            onExcelSelected(e) {
                console.log("onExcelSelected");
                let self = this;
                if(e.target != undefined) {
                    if (e.target.files != undefined) {
                        console.log("PATH: " + e.target.files[0].name);
                        let droppedFiles = e.target.files;

                        console.log(droppedFiles);
                        ([...droppedFiles]).forEach(f => {
                            if(f == undefined) {
                                return;
                            }
                            var reader = new FileReader(),
                                name = f.name;

                            reader.onload = function (e) {
                                var results;
                                var data = e.target.result;


                                var fixedData = self.fixdata(data),
                                    workbook = XLSX.read(btoa(fixedData), {type: 'base64'}),
                                    firstSheetName = workbook.SheetNames[0],
                                    worksheet = workbook.Sheets[firstSheetName];
                                self.$data.state.headers = self.get_header_row(worksheet);
                                results = XLSX.utils.sheet_to_json(worksheet, {range: 0, defval: ""});
                                //self.$data.state.tickets=results;
                                console.log("Result");


                                let list = [];
                                for(let i = 1 ; i < results.length ; i++) {
                                    console.log(results[i]);
                                    let newItem = newPlace();

                                    let result = results[i];
                                    newItem.id = 0;
                                    if(result.id != undefined && result.id != '') {
                                        newItem.id = result.id;
                                    }
                                    newItem.type = result.type;
                                    newItem.name = result.name;
                                    newItem.category = result.category;
                                    newItem.contact = result.contact;
                                    newItem.siteAddress = result.siteAddress;
                                    newItem.address = result.address;
                                    newItem.openingTime = result.openingTime;
                                    if(result.lat != undefined && result.lat !== '') {
                                        newItem.lat = parseFloat(result.lat);
                                    }
                                    if(result.lon != undefined && result.lon !== '') {
                                        newItem.lon = parseFloat(result.lon);
                                    }

                                    // console.log("연락처 " + results[i]["연락처"]);
                                    if(result.imageUrls != undefined && result.imageUrls != "") {
                                        let images = result.imageUrls.replace("[", "").replace("]", "").split(',');
                                        if(images.length > 0) {
                                            for(let imi = 0 ; imi < images.length ; imi++) {
                                                if(images[imi].includes('86x86')) {
                                                    images[imi] = images[imi].replace('86x86', '344x344');
                                                }
                                            }
                                        }
                                        newItem.imageUrls = images;

                                        if(images.length > 0) {
                                            newItem.imageUrl = images[0];
                                        }

                                    }
                                    if(result.rating != undefined && result.rating !== '') {
                                        result.rating = Number(parseFloat(result.rating) * 10);
                                    }
                                    console.log("신규");
                                    console.log(newItem);
                                    list.push(newItem);
                                }

                                if(list.length > 0) {
                                    console.log(list)
                                    self.createPlaceList(list);
                                }
                            }
                            reader.readAsArrayBuffer(f);
                        })
                    }
                }

            },
            get_header_row: function (sheet) {
                var headers = [], range = XLSX.utils.decode_range(sheet['!ref']);
                //range.s.r = 2;//XLSX.utils.decode_row("3");
                var C, R = range.s.r; /* start in the first row */
                for (C = range.s.c; C <= range.e.c; ++C) { /* walk every column in the range */
                    var cell = sheet[XLSX.utils.encode_cell({c: C, r: R})] /* find the cell in the first row */
                    var hdr = "UNKNOWN " + C; // <-- replace with your desired default
                    if (cell && cell.t) hdr = XLSX.utils.format_cell(cell);
                    headers.push(hdr);
                }
                return headers;
            },
            fixdata: function (data) {
                var o = "", l = 0, w = 10240;
                for (; l < data.byteLength / w; ++l) o += String.fromCharCode.apply(null, new Uint8Array(data.slice(l * w, l * w + w)));
                o += String.fromCharCode.apply(null, new Uint8Array(data.slice(l * w)));
                return o;
            },
            workbook_to_json: function (workbook) {
                var result = {};
                workbook.SheetNames.forEach(function (sheetName) {
                    var roa = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
                    if (roa.length > 0) {
                        result[sheetName] = roa;
                    }
                });
                return result;
            },
        }
    }
</script>
