import {deleteItem, errorHandler, getCommonHeader, getItemById, getItemList} from "./common";
import EventBus from "./event-bus";

const axios = require('axios');

const model = "hour";


export function getLocationData(query) {
    return new Promise(function(resolve, reject) {
        EventBus.$emit("showLoading", true, "가져오는중");
        console.log("getBikeProducts " + process.env.VUE_APP_API_BASE_URL + 'admin/locations/' + query);
        axios.get(process.env.VUE_APP_API_BASE_URL + 'admin/locations/' + query, {
            headers: getCommonHeader()
        })
            .then(function(response) {
                console.log(response);
                EventBus.$emit("showLoading", false);
                let data = response.data;
                resolve(data);
            })
            .catch(function(err) {
                console.log("get hours error");
                console.log(err);
                if(err.response != undefined) {
                    console.log(err.response);
                }
                EventBus.$emit("showLoading", false);
                errorHandler(err);
                reject(err);
            })
    })
}
