<template>
    <div class="container-fluid h-100" v-bind:id="'detail-view-'+_uid" :key="'tag-edit-view-' + detailItem.id" :ref="'tag-edit-view-' + detailItem.id">
        <div class="row h-100">
            <div class="col-6 h-100">
                <div class="top">
                    <gmap-map
                            ref="gmap"
                            :center="mapCenter"
                            :zoom="mapZoom"
                            :options="{
                    streetViewControl:false,
                    fullscreenControl:false,
                    mapTypeControl:false
                }"
                            @click="clickOnMap"
                            class="google-map"
                            style="width: 100%; height: 300px;">
                        <gmap-marker
                                v-if="marker != false"
                                :key="'marker-' + detailItem.id"
                                :position="marker"
                                @click="clickOnMap"
                        >
                        </gmap-marker>
                    </gmap-map>
                </div>
            </div>
            <div class="col-6 h-100 vertical-scroll">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12 text-right">
                            <span class="border-btn" v-on:click="onClickCancelBtn()">{{$t('detail.close')}}</span>
                            <span class="border-btn" v-on:click="onClickSaveBtn()">{{$t('detail.save')}}</span>
                        </div>
                    </div>
                    <!--            <div class="naim-btn remove" v-on:click="onClickRemoveBtn()">{{$t('detail.remove')}}</div>-->
                </div>
                <vue-form style="width: 100%;"
                          label-width="100%"
                          label-position="left">
                    <label>해시태그</label>
                    <vue-input v-model="detailItem.name"/>
                </vue-form>
                <vue-form style="width: 100%;"
                          label-width="100%"
                          label-position="left">
                    <vue-form-item :label="$t('tag.type')">
                        <select class="form-control" v-model="detailItem.type">
                            <option v-for="type in types" :value="type.value">{{type.name}}</option>

                        </select>

                    </vue-form-item>

                    <vue-form-item :label="`${$t('asset.lat')}`">
                        <vue-input
                                v-model="marker.lat"
                        >
                        </vue-input>
                    </vue-form-item>
                    <vue-form-item :label="`${$t('asset.lon')}`">
                        <vue-input
                                v-model="marker.lng"
                        >
                        </vue-input>
                    </vue-form-item>
                    <vue-form-item label="개발자용">
                        <select class="form-control" v-model="detailItem.developer">
                            <option value="false">No</option>
                            <option value="true">Yes</option>
                        </select>
                    </vue-form-item>
                </vue-form>
            </div>
        </div>
    </div>
</template>

<script>
    import {millisToReadable} from "../../../utils/TimeConverter";
    import {createTag, deleteTagById, writeTag, updateTag, getTagTypes} from "../../../api/tag";
    export default {
        name: 'EditorProduct',
        props: {
            detailItem: {
                type: Object,
                default: function () {
                    return {}
                }
            },

        },
        data() {
            return {
                types:[],
                mapZoom:7,
                millisToReadable: millisToReadable,
                mapCenter: {
                    lat: 36.5, lng:127.8
                },
                marker:false,
            }
        },
        created() {
            console.log("detailed item");
            console.log(this.detailItem);
            this.types = getTagTypes();
            this.marker = {
                lat: this.detailItem.lat,
                lng: this.detailItem.lon,
            }
        },
        mounted(){
            console.log("mounted");
            this.marker = {
                lat: this.detailItem.lat,
                lng: this.detailItem.lon,
            }
        },
        watch:{
            detailItem() {
                console.log("detailItem changed");
                this.marker = {
                    lat: this.detailItem.lat,
                    lng: this.detailItem.lon,
                }
            }
        },
        methods: {
            clickOnMap: function(ev) {
                console.log("Editor Map Click Event " + ev.latLng) ;
                console.log(ev);
                this.detailItem.lat = ev.latLng.lat();
                this.detailItem.lon = ev.latLng.lng();

                this.marker = {
                    lat: this.detailItem.lat,
                    lng: this.detailItem.lon,
                }
            },
            onClickSaveBtn: function() {
                this.detailItem.lat = this.marker.lat;
                this.detailItem.lon = this.marker.lng;

                // if(0 === this.detailItem.id){
                let that = this;
                writeTag(this.detailItem).then(function (tag) {
                    alert("저장 되었습니다.");

                    let path = "/admin/tag?id=" + tag.id ;

                    if(that.$route.fullPath !== path) {
                        that.$router.push(path);
                    }

                    that.$emit("updateList")
                });
                // } else {
                //     updateTag(that.detailItem).then(function(tag) {
                //         let path = "/admin/tag";
                //
                //         that.$router.push(path)
                //     })
                // }
                // } else {
                //   that.content.pass
                //   updateUser(that.content).then(function (post) {
                //     let path = "/admin/tag?id=" + post.id ;
                //
                //     that.$router.push(path)
                //     });
                //   }
            },
            // onClickRemoveBtn: function () {
            //     let that = this;
            //
            //     deleteTagById(that.detailItem.id).then(function (post) {
            //         let path = "/admin/tag";
            //
            //         that.$router.push(path)
            //     });
            // },
            onClickCancelBtn: function () {
                this.$emit('onClose', null);

                // this.$router.push("/admin/tag")
            }
        }
    };
</script>
<style lang="scss" scoped>
    .detail-wrapper {
        padding-bottom: 20px;
    }
    .detail-view {
        padding:0;
        width: 100%;
        height: 100%;
        overflow-y: scroll;
        padding-top: 370px;
    }
    .fixed-top {
        position: absolute;
        width: 100%;
        top: 0;
        left:0;
        padding:20px;
        background-color: white;
    }
    .btns {
        height:30px;
        line-height: 30px;
    }
</style>
