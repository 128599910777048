// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import i18n from './translations';
import store from './store'


import VeeValidate from 'vee-validate'
Vue.use(VeeValidate, {
    fieldsBagName: 'veeFields',
    errorBagName: 'veeErrors'
});

import VueGoodTablePlugin from 'vue-good-table';

// import the styles
import 'vue-good-table/dist/vue-good-table.css'

Vue.use(VueGoodTablePlugin);

/**
 * Vue Form Components
 */
import VFC from 'vfc'
import 'vfc/dist/vfc.css'
Vue.use(VFC);

/**
 * Firebase  설정
 */
// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
import * as firebase from "firebase/app";

// Add the Firebase services that you want to use
import "firebase/auth";
import "firebase/storage";

var firebaseConfig = {
    apiKey: "AIzaSyAyWGe1qsRunNZQRzTo7DIVpchK7iZ3_Ho",
    authDomain: "ncm-mary-254508.firebaseapp.com",
    databaseURL: "https://ncm-mary-254508.firebaseio.com",
    projectId: "ncm-mary-254508",
    storageBucket: "ncm-mary-254508.appspot.com",
    messagingSenderId: "529058014005",
    appId: "1:529058014005:web:835115e7094ff0d7a8227c"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

/**
 * End of Firebase 설정
 */

/**
 * Vue Datetime
 */
// You need a specific loader for CSS files
import 'vue-datetime/dist/vue-datetime.css'

/**
 * momentjs
 */
import moment from "moment";
import VueMomentJS from "vue-momentjs";

Vue.use(VueMomentJS, moment);

new Vue({
    router,
    i18n,
    store,
    render: h => h(App),
}).$mount('#app')
