import {errorHandler, getCommonHeader} from "./common";
import EventBus from "./event-bus";

const axios = require('axios');

export function getHours(query) {
    return new Promise(function(resolve, reject) {
        EventBus.$emit("showLoading", true, "가져오는중");
        console.log("getBikeProducts " + process.env.VUE_APP_API_BASE_URL + 'admin/hours?' + query);
        axios.get(process.env.VUE_APP_API_BASE_URL + 'admin/hours?' + query, {
            headers: getCommonHeader()
        })
            .then(function(response) {
                console.log(response);
                EventBus.$emit("showLoading", false);
                let data = response.data;
                resolve(data);
            })
            .catch(function(err) {
                console.log("get hours error");
                console.log(err);
                if(err.response != undefined) {
                    console.log(err.response);
                }
                EventBus.$emit("showLoading", false);
                errorHandler(err);
                reject(err);
            })
    })
}
export function getRefundPolicy(query) {
    return new Promise(function(resolve, reject) {
        EventBus.$emit("showLoading", true, "가져오는중");
        console.log("getBikeProducts " + process.env.VUE_APP_API_BASE_URL + 'admin/hours/refundPolicy?' + query);
        axios.get(process.env.VUE_APP_API_BASE_URL + 'admin/hours/refundPolicy?' + query, {
            headers: getCommonHeader()
        })
            .then(function(response) {
                console.log(response);
                EventBus.$emit("showLoading", false);
                let data = response.data;
                resolve(data);
            })
            .catch(function(err) {
                console.log("get hours error");
                console.log(err);
                if(err.response != undefined) {
                    console.log(err.response);
                }
                EventBus.$emit("showLoading", false);
                errorHandler(err);
                reject(err);
            })
    })
}
export function getUnavailableHours(query) {
    return new Promise(function(resolve, reject) {
        EventBus.$emit("showLoading", true, "가져오는중");
        console.log("getBikeProducts " + process.env.VUE_APP_API_BASE_URL + 'place/product/hours/unavailable?' + query);
        axios.get(process.env.VUE_APP_API_BASE_URL + 'place/product/hours/unavailable?' + query, {
            headers: getCommonHeader()
        })
            .then(function(response) {
                console.log(response);
                EventBus.$emit("showLoading", false);
                let data = response.data;
                resolve(data);
            })
            .catch(function(err) {
                console.log("get hours error");
                console.log(err);
                if(err.response != undefined) {
                    console.log(err.response);
                }
                EventBus.$emit("showLoading", false);
                errorHandler(err);
                reject(err);
            })
    })
}
export function updateHour(hour) {
    return new Promise(function(resolve, reject) {
        EventBus.$emit("showLoading", true, "업데이트중");
        axios.post(process.env.VUE_APP_API_BASE_URL + 'admin/hour', JSON.stringify(hour), {
            headers: getCommonHeader()
        })
            .then(function(response) {
                console.log(response);
                EventBus.$emit("showLoading", false);
                let data = response.data;
                resolve(data);
            })
            .catch(function(err) {
                console.log(err);
                EventBus.$emit("showLoading", false);
                errorHandler(err);
                reject(err);
            })
    })
}
export function deleteSchedule(id) {
    return new Promise(function(resolve, reject) {
        EventBus.$emit("showLoading", true, "업데이트중");
        axios.delete(process.env.VUE_APP_API_BASE_URL + 'admin/hour?id='+id, {
            headers: getCommonHeader()
        })
            .then(function(response) {
                console.log(response);
                EventBus.$emit("showLoading", false);
                let data = response.data;
                resolve(data);
            })
            .catch(function(err) {
                console.log(err);
                EventBus.$emit("showLoading", false);
                errorHandler(err);
                reject(err);
            })
    })
}
export function deleteHour(id, placeId) {
    return new Promise(function(resolve, reject) {
        EventBus.$emit("showLoading", true, "업데이트중");
        axios.delete(process.env.VUE_APP_API_BASE_URL + 'admin/place/hour?id='+id + "&placeId="+placeId, {
            headers: getCommonHeader()
        })
            .then(function(response) {
                console.log(response);
                EventBus.$emit("showLoading", false);
                let data = response.data;
                resolve(data);
            })
            .catch(function(err) {
                console.log(err);
                EventBus.$emit("showLoading", false);
                errorHandler(err);
                reject(err);
            })
    })
}
export function updateHours(hours) {
    return new Promise(function(resolve, reject) {
        EventBus.$emit("showLoading", true, "업데이트중");
        axios.post(process.env.VUE_APP_API_BASE_URL + 'admin/hours', JSON.stringify(hours), {
            headers: getCommonHeader()
        })
            .then(function(response) {
                console.log(response);
                EventBus.$emit("showLoading", false);
                let data = response.data;
                resolve(data);
            })
            .catch(function(err) {
                console.log(err);
                EventBus.$emit("showLoading", false);
                errorHandler(err);
                reject(err);
            })
    })
}
export function getHolidays(date) {
    return new Promise(function(resolve, reject) {
        EventBus.$emit("showLoading", true, "휴일 가져오는중");
        // console.log("getBikeProducts " + process.env.VUE_APP_API_BASE_URL + 'admin/holidays?date=' + date);
        axios.get(process.env.VUE_APP_API_BASE_URL + 'admin/holidays?date=' + date, {
            headers: getCommonHeader()
        })
            .then(function(response) {
                console.log(response);
                EventBus.$emit("showLoading", false);
                let data = response.data;
                resolve(data);
            })
            .catch(function(err) {
                console.log("get hours error");
                console.log(err);
                if(err.response != undefined) {
                    console.log(err.response);
                }
                EventBus.$emit("showLoading", false);
                errorHandler(err);
                reject(err);
            })
    })
}
export function deleteHoliday(id) {
    return new Promise(function(resolve, reject) {
        EventBus.$emit("showLoading", true, "삭제중입니다");
        axios.delete(process.env.VUE_APP_API_BASE_URL + 'admin/holiday?id='+id, {
            headers: getCommonHeader()
        })
            .then(function(response) {
                console.log(response);
                EventBus.$emit("showLoading", false);
                let data = response.data;
                resolve(data);
            })
            .catch(function(err) {
                console.log(err);
                EventBus.$emit("showLoading", false);
                errorHandler(err);
                reject(err);
            })
    })
}
export function newDateHour() {
    return {
        id:0,
        parentId:0,
        type:'opening_time',
        placeId:0,
        productId:0,
        dayOfWeek:0,
        open:true,
        openHour:0,
        openMinute:0,
        closeHour:0,
        closeMinute:0,
        price:0,
        price2:0,
        price3:0,
        price4:0,
        price5:0,
        payout:0,
        payout2:0,
        payout3:0,
        payout4:0,
        payout5:0,
        list:[],
        slotLength:0,
        refundable:0,
        refundDayBefore:0,
        refundHoursBefore:0,
        date:'',
    }
}
export function getDayString(dayOfWeek) {
    if(dayOfWeek == 0) {
        return '일요일';
    } else if(dayOfWeek == 1) {
        return '월요일';
    } else if(dayOfWeek == 2) {
        return '화요일';
    } else if(dayOfWeek == 3) {
        return '수요일';
    } else if(dayOfWeek == 4) {
        return '목요일';
    } else if(dayOfWeek == 5) {
        return '금요일';
    } else if(dayOfWeek == 6) {
        return '토요일';
    } else if(dayOfWeek == 100) {
        return '공휴일';
    }
    return 'Unknown (' + dayOfWeek + ')';
}
