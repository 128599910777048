

<template>
    <div style="width: 100%; height: 100%;">
        <div style="width: 100%; height: 50px;">
            <select v-if="placeItems.length > 0" v-model="currentPlaceId" style="height: 40px; width: 300px;border-radius: 5px; border: 1px solid grey;" @change="onPlaceSelected">
                <option value="0" v-if="userRole >= 99">전체</option>
                <template v-for="p in placeItems">
                    <option v-bind:key="'place-selector-' + p.id" :value="p.id">{{p.name}} <span v-if="userRole === 100">({{p.commissionRateFloat}}%)</span></option>
                </template>
            </select>
            <button style="margin-left: 20px;" class="btn btn-primary" @click="getSummary">오늘 결산보기</button>
        </div>
        <div style="width: 100%; height: calc(100% - 50px); overflow-y: auto;">
            <div class="container-fluid">
                <div class="row">
                    <template v-for="order in orders">
                        <div :key="'order-item-'+order.id" class="col-6 col-md-4 col-lg-3">
                            <div class="order-container">
                                <div class="order-title">
                                    주문번호: {{order.id}}
                                </div>
                                <div class="order-information">
                                    {{dateTimeText(order.createdWhen)}}
                                </div>
                                <div class="order-list-container">
                                    <table>
                                        <tr>
                                            <th>Item</th>
                                            <th>Qty</th>
                                        </tr>
                                        <template v-for="item in order.list">
                                            <tbody :key="'order-item-'+item.id">
                                            <tr>
                                                <td class="text-left">
                                                    {{item.title}}

                                                    <span v-html="getOptionText(item)"></span>

                                                </td>
                                                <td>{{item.amount}}</td>
                                            </tr>
                                            </tbody>
                                        </template>
                                    </table>
                                </div>
                                <div class="order-btn-container">
                                    <button class="btn btn-success" style="width: calc(50% - 10px);" @click="printBill(order.id)">영수증 출력</button>
                                    <button class="btn btn-primary" style="width: calc(50% - 10px);margin-left: auto;" @click="printOrder(order.id)">주문지출력</button>
                                </div>
                                <div class="order-btn-container">
                                    <button class="btn btn-dark form-control" @click="completeOrder(order.id)">완료</button>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
        <div v-if="summaryView !== null" style="position:fixed; top:50px; left: 0; width: 100%; height: calc(100% - 50px); background: rgba(0,0,0,0.4); z-index: 20;" @click="summaryView = null;">
            <div style="position:absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); width: 700px; max-width: 90%; height: 90%; background: white;" @click.stop="dummy">
                <div class="sum-title">
                    {{summaryView.title}}
                </div>
                <div class="list-container">
                    <table>
                        <tr>
                            <th class="text-left">시간</th>
                            <th class="text-left">Item</th>
                            <th class="text-right">결제금액</th>
                        </tr>
                        <template v-for="o in summaryView.list">
                            <tr v-bind:key="'sum-order-' + o.id">
                                <td class="text-left">{{hmText(o.createdWhen)}}</td>
                                <td class="text-left">
                                    {{getOrderItemText(o)}}
                                </td>
                                <td class="text-right">{{o.finalDue.toLocaleString()}}</td>
                            </tr>
                        </template>
                        <tr>
                            <th class="text-left"></th>
                            <th class="text-left">총 {{summaryView.count}}건</th>
                            <th class="text-right">{{summaryView.total.toLocaleString()}}</th>
                        </tr>
                    </table>
                </div>
                <div class="sum-bottom-view">
                    <button class="btn btn-dark" @click="summaryView = null">닫기</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {getBlob, getHM, millisToDate, millisToDateTime, playSound, showDialog} from "@/api/common";
import Paho from "@/assets/js/paho-mqtt";
import {completePosOrder, getPosOrder, getPosOrders, getPosPendingOrders} from "@/api/order";
import {getRoleId} from "@/store";
import {getPlaces} from "@/api/place";

export default {
    name:'PosView',
    data() {
        return {
            placeItems:[],
            currentPlaceId:0,
            placeItem:false,
            orders:[],
            userRole:0,
            mqtt:null,
            mqttConnected:false,
            disconnectMqtt:false,
            currentTopic:null,
            summaryView: null,
        }
    },
    created() {
    },
    mounted() {
        this.$data.userRole = getRoleId();
        this.onStart();

    },
    beforeDestroy() {
        this.$data.disconnectMqtt = true;
        this.disconnect();
    },
    destroyed() {
    },
    methods:{
        dummy: function(){},
        hmText:getHM,
        dateTimeText:millisToDateTime,
        onStart: function() {
            let self = this;
            console.log("onStart");
            getPlaces('typeClass=market')
                .then(function(list) {
                    console.log(list);
                    self.$data.placeItems = list;
                    if(self.$data.placeItems.length === 1) {
                        self.$data.currentPlaceId = self.$data.placeItems[0].id;
                        // §console.log("")
                        // self.connect();
                        self.onPlaceSelected();


                    }
                    self.connect();
                    console.log("user role id " + self.$data.userRole);


                })
                .catch(function(err) {
                    console.log(err);
                    if(err.response != undefined) {
                        console.log(err.response);
                    }
                })
        },
        getOrderItemText: function(order) {
            let list = [];
            for(let i = 0 ; i < order.list.length ; i++) {
              let item = order.list[i].title;
              item += this.getOptionTextOneLine(order.list[i]);
              item += ' x' + order.list[i].amount;
              list.push(item);
            }

            if(list.length === 0) {
                return '';
            }

            let r = list.join(', ');
            return r;
        },
        onPlaceSelected: function() {
            this.$data.placeItem = false;
            this.$data.orders = [];
            for(let i = 0 ; i < this.$data.placeItems.length ; i++) {
                if(this.$data.placeItems[i].id === this.$data.currentPlaceId) {
                    this.$data.placeItem = this.$data.placeItems[i];
                    break;
                }
            }


            if(this.$data.placeItem !== false) {
                this.getPendingOrderList();
            }
            this.subscribeChannel();
        },
        getPendingOrderList: function() {

            let today = new Date();
            let to = today.getTime();
            today.setHours(0,0,0,0);

            let from = today.getTime();

            let self = this;
            getPosPendingOrders(this.$data.currentPlaceId, from, to)
                .then(function(data) {
                    if(data.success === true) {
                        self.$data.orders = data.item;
                        console.log("Pending orders");
                        console.log(self.$data.orders);
                    }
                })
                .catch(function(err) {
                    console.log(err);
                    let msg = err;
                    if(err.response !== undefined) {
                        msg += ' (' + err.response +')';
                    }
                    showDialog(msg, "ERROR");
                })
        },
        completeOrder:function(id) {
            let self = this;
            completePosOrder(this.$data.currentPlaceId, id)
                .then(function(data) {
                    if(data.success === true) {
                        for(let i = 0 ; i < self.$data.orders.length ; i++) {
                            if(self.$data.orders[i].id === data.item.id) {
                                self.$data.orders.splice(i, 1);
                                return;
                            }
                        }
                    } else {
                        showDialog(data.message);
                    }
                })
                .catch(function(err) {
                    console.log(err);
                    let msg = err;
                    if(err.response !== undefined) {
                        msg += ' (' + err.response +')';
                    }
                    showDialog(msg, "ERROR");
                })
        },
        getOrder: function(id, printOrder = false) {
            let self = this;
            getPosOrder(this.$data.currentPlaceId, id)
                .then(function(data){
                    if(data.success === true) {
                        let found = false;
                        for(let i = 0 ; i < self.$data.orders.length ; i++) {
                            if(self.$data.orders[i].id === data.item.id) {
                                self.$data.orders.splice(i, 1, data.item);
                                found = true;
                                break;
                            }
                        }
                        if(found === false) {
                            self.$data.orders.push(data.item);
                            if(printOrder === true) {
                                self.printOrder(data.item.id);
                            }
                        }
                    } else {
                        showDialog(data.message);
                    }
                })
                .catch(function(err){
                    console.log(err);
                    let msg = err;
                    if(err.response !== undefined) {
                        msg += ' (' + err.response +')';
                    }
                    showDialog(msg, "ERROR");
                })
        },


        getSummary: function() {


            let self = this;
            let today = new Date();
            let title = millisToDate(today.getTime());

            today.setHours(0,0,0,0);
            let from = today.getTime();
            today.setHours(23,59,59,999);

            let to = today.getTime();
            getPosOrders(this.$data.currentPlaceId, from, to)
                .then(function(data) {
                    if(data.success === true) {
                        self.buildSummaryView(data.item, title);
                    } else {
                        showDialog(data.message);
                    }
                })
                .catch(function(err) {
                    console.log(err);
                    let msg = err;
                    if(err.response !== undefined) {
                        msg += ' (' + err.response +')';
                    }
                    showDialog(msg, "ERROR");
                })
        },
        buildSummaryView: function(list, title) {
            let total = 0;
            for(let i = 0 ; i < list.length ; i++) {
                total += list[i].finalDue;
            }

            this.$data.summaryView = {
                title: title + " 내역",
                total: total,
                count: list.length,
                list: list,
            }
        },
        getOptionTextOneLine: function(item) {
            if(item.options === undefined || item.options === null || item.options.length === 0) {
                return '';
            }


            let options = JSON.parse(item.options);
            let selectedOptions = [];
            for(let i = 0 ; i < options.length ; i++) {
                for(let j = 0 ; j < options[i].list.length ; j++) {
                    if(options[i].list[j].selected === true) {
                        selectedOptions.push(options[i].list[j].title);
                    }
                }

            }

            if(selectedOptions.length === 0) {
                return '';
            }
            return '(' + selectedOptions.join(", ") +')';
        },
        getOptionText: function(item) {
            if(item.options === undefined || item.options === null || item.options.length === 0) {
                return '';
            }


            let options = JSON.parse(item.options);
            let selectedOptions = [];
            for(let i = 0 ; i < options.length ; i++) {
                for(let j = 0 ; j < options[i].list.length ; j++) {
                    if(options[i].list[j].selected === true) {
                        selectedOptions.push(options[i].list[j].title);
                    }
                }

            }

            if(selectedOptions.length === 0) {
                return '';
            }
            return '<br/>*(' + selectedOptions.join(", ") +')';
        },
        subscribeChannel: function() {
            //Should be changed according to current user
            if(this.$data.mqtt === null) {
                return;
            }
            if(this.$data.currentTopic !== null && this.$data.currentTopic !== newTopic) {
                this.$data.mqtt.unsubscribe(this.$data.currentTopic);
            }
            if(this.$data.currentPlaceId <= 0) {
                return;
            }
            let newTopic = 'place-'+this.$data.currentPlaceId;
            console.log("subscribe now to " + newTopic);

            this.$data.mqtt.subscribe('place-' + this.$data.currentPlaceId);
            this.$data.currentTopic  = newTopic;
        },

        onMessageArrived: function (msg) {
            // console.log("message arrived");
            let msgStr = msg.payloadString;
            console.log('message arrived ' + msgStr);
            let item = JSON.parse(msgStr);
            if(item.cmd !== undefined && item.cmd === 'newOrder') {
                // showDialog(item.msg, "신규 버튼을 눌러 대기 예약을 확정해주세요");
                if(item.id !== undefined) {
                    playSound();
                    this.getOrder(item.id, true);
                }
            }
        },
        /**
         * Callback when MQTT is connected
         * Now should subscribe maryBikes/(operatorId)
         */
        onConnect: function () {
            console.log("Connected subscribe maryapp");
            this.$data.mqttConnected = true;
            this.subscribeChannel();
        },


        /**
         * When conneciton is lost, should reconnect manually.
         * Reconnect option does not really reconnect when switching from WIFI to mobile network
         */
        onConnectionLost: function(resObj) {
            console.log("connection lost");
            // this.$data.mqttConnected = false;
            // this.$data.mqtt = null;
            console.log("Lost connection to " + resObj.uri + "\nError code: " + resObj.errorCode + "\nError text: " + resObj.errorMessage);
            console.log("Now try again");
            this.$data.mqttConnected = false;
            if(this.$data.disconnectMqtt === false) {
                setTimeout(this.connect, 1000);
            }
        },

        /**
         * Callback when MQTT connection failed
         * @param message
         */
        onMqttFailure: function (message) {
            console.log("MQTT failed " + message);
            console.log(message);
            console.log("Attempt to reconnect in 1 second");

            this.$data.mqttConnected = false;
            setTimeout(this.connect, 1000);
        },
        disconnect: function() {
            if(this.$data.mqtt == null || this.$data.mqtt.isConnected() == false) {
                return;
            }
            this.$data.mqtt.disconnect();
        },
        connect: function () {
            if(this.$data.mqtt === null || this.$data.mqtt.isConnected() === false) {

                console.log("MQTT is not connected so attempt to connect now");
                // let url = 'ncmmary.mqtt.broker.host2';

                this.$data.mqtt = new Paho.Client("mqtt.marysharing.com", Number(9001), 'maryApp-' + new Date().getTime());
                console.log("MQTT created");

                // this.$data.mqtt.disconnect();
                this.$data.mqtt.onConnectionLost = this.onConnectionLost;
                let options = {
                    timeout: 3,
                    useSSL: true,
                    userName: "maryapp",
                    password: "app23344",
                    onSuccess: this.onConnect,
                    onFailure: this.onMqttFailure,
                    cleanSession: true, //Clean session
                    reconnect : false, // Enable automatic reconnect
                    // reconnectInterval:1, //Reconnect attempt interval : 1 second
                };
                this.$data.mqtt.onMessageArrived = this.onMessageArrived;
                console.log(this.$data.mqtt);
                console.log("MQTT now connects...");
                this.$data.mqtt.connect(options); //connect
            } else {
                console.log("Already connected");
                this.$data.mqttConnected = true;
            }
            if(this.$data.mqtt != null || this.$data.mqtt.onMessageArrived !== undefined ) {
                console.log("Not connected");
            } else if( this.$data.mqtt.onMessageArrived === null || this.$data.mqtt.onMessageArrived !== this.onMessageArrived) {
                this.$data.mqtt.onMessageArrived = this.onMessageArrived;
            } else {
                console.log("message arrive handler Already registered");
            }

        },
        printOrder: function(id) {
            // const FileDownload = require('js-file-download');
            let url = "admin/pos/" + this.$data.currentPlaceId + "/order/" + id + '/ticket';

            // if(this.$data.selectedAssetId > 0) {
            //     url += "?assetId=" + this.$data.selectedAssetId;
            // }

            // if(type !== null) {
            //     url += "?type=asset";
            // }
            // let fileName = "";

            // let date = new Date();
            let self = this;
            // const FileDownload = require('js-file-download');
            // fileName += "Order-" + id + "-" + date.getTime();
            getBlob(url)
                .then(function(response) {
                    // FileDownload(response.data, fileName +".pdf");
                    self.invokePrinter(response.data);
                })
                .catch(function(err) {
                    console.log(err);
                    if(err.response !== undefined) {
                        console.log(err.response);
                    }

                })
        },
        printBill: function(id) {
            // const FileDownload = require('js-file-download');
            let url = "admin/pos/" + this.$data.currentPlaceId + "/order/" + id + '/bill';

            // if(this.$data.selectedAssetId > 0) {
            //     url += "?assetId=" + this.$data.selectedAssetId;
            // }

            // if(type !== null) {
            //     url += "?type=asset";
            // }
            // let fileName = "";

            // let date = new Date();
            // fileName += "Receipt-" + id + "-" + date.getTime();
            let self = this;
            getBlob(url)
                .then(function(response) {
                    // FileDownload(response.data, fileName +".pdf");
                    self.invokePrinter(response.data);
                })
                .catch(function(err) {
                    console.log(err);
                    if(err.response !== undefined) {
                        console.log(err.response);
                    }

                })
        },
        invokePrinter: function(data) {
            var blob = new Blob([data], {type: 'application/pdf'}); //this make the magic
            var blobURL = URL.createObjectURL(blob);

            let iframe =  document.createElement('iframe'); //load content in an iframe to print later
            document.body.appendChild(iframe);

            iframe.style.display = 'none';
            iframe.src = blobURL;
            iframe.onload = function() {
                setTimeout(function() {
                    iframe.focus();
                    iframe.contentWindow.print();
                }, 1);
            };
        }

    }
}
</script>
<style scoped>
.order-container {
    background: white;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 3px 3px 3px #bbb;
}
.order-title {
    font-weight: bold;
    padding-top: 5px;
    padding-bottom: 5px;

}
.order-information{

    padding-top: 5px;
    padding-bottom: 5px;
    text-align: right;
    border-bottom: 1px solid #EEE;
}
.order-list-container{

    padding-bottom: 10px;
}
.order-list-container > table {
    width: 100%;
}
.order-list-container td {
    font-size: 18px;
    vertical-align: top;
}
.order-btn-container {
    width: 100%;
    margin-top: 10px;
    display: flex;
}
.sum-title {
    height: 50px;
    line-height: 50px;
    font-size: 25px;
    font-weight: bold;
    text-align: center;
}
.list-container {
    width: 100%;
    height: calc(100% - 100px);
    overflow-y: auto;
    padding-left: 10px; padding-right: 10px;
}
.list-container table {
    width: 100%;
}
.list-container tr{
    border-bottom: 1px solid #eee;
}
.list-container td,th {
    padding: 5px;
}
.sum-bottom-view {
    height: 50px;
    padding-right: 15px;
    text-align: right;
}
</style>
