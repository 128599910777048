<template>
    <div class="detail-view">

        <div class="col-12 text-right mary-button-container" style="position: sticky; top:0; background-color: white; z-index: 2; box-shadow: 2px 2px 3px gray;">
            <div class="mary-button" style="background-color: black;" v-on:click="onClickCloseBtn()"><span><i class="fas fa-times"></i></span> {{$t('detail.close')}}</div>
            <div class="mary-button" v-show="!isEditMode" v-on:click="onClickEditBtn()"><span><i class="fas fa-edit"></i></span> {{$t('detail.edit')}}</div>
            <div class="mary-button" v-show="isEditMode" v-on:click="onClickSaveBtn()"><span><i class="fas fa-save"></i></span> {{$t('detail.save')}}</div>
            <div class="mary-button" style="background-color: red; float:left;" v-show="!isAddMode" v-on:click="onClickRemoveBtn()"><span><i class="fas fa-trash"></i></span> {{$t('detail.remove')}}</div>
        </div>

        <div class="head-group">
            <div class="top"></div>
            <div class="bottom">
                <div class="content-header">
                    <vue-form style="width: 100%;"
                              label-width="100%"
                              label-position="left">
                        <AccessDropdownInput
                            :is-read-only="!isEditMode"
                            v-bind:operatorId="item.operatorId"
                            v-on:onClick="setOperatorId"/>
                        <hr>
                        <vue-form-item label="Report ID(operation.id)">
                            <label v-if="0 === item.id">신규 생성시엔 저장 후 번호가 생성됩니다.</label>
                            <label v-else>REPORT_{{item.id}}</label>
<!--                            <vue-input-->
<!--                                v-model="item.title"-->
<!--                                :disabled="!isEditMode">-->
<!--                            </vue-input>-->
                        </vue-form-item>
                        <vue-form-item label="Assignment ID(operation.parentId)">
<!--                            <vue-input-->
<!--                                v-model="item.parentId"-->
<!--                                :disabled="!isEditMode">-->
<!--                            </vue-input>-->
                            <dropdown-input
                                v-on:onClickItem="onClickAssign"

                                :currentSelected="selectedIssue"
                                :dropdownList="issueList"
                                :is-read-only="!isEditMode"/>
                        </vue-form-item>
<!--                        <vue-form-item label="담당자(operation.userId)">-->
<!--                            <label>{{item.userId}}</label>-->
<!--                        </vue-form-item>-->
                        <vue-form-item label="Device ID(operation.assetId)">
                            <vue-input
                                v-model="item.assetId"
                                :disabled="!isEditMode">
                            </vue-input>
                        </vue-form-item>
                        <vue-form-item label="완료 시간(operation.completedWhen)">
<!--                            <label v-if="!isEditMode">{{datetimeData}}</label>-->
                            <datetime
                                type="datetime"
                                v-model="datetimeData"
                                format="yyyy.MM.dd HH:mm:ss"
                                value-zone="UTC+09:00"
                                :disabled="!isEditMode"
                                use12-hour/>
<!--                            <vue-input-->
<!--                                v-model="item.createdWhen"-->
<!--                                :disabled="!isEditMode">-->
<!--                            </vue-input>-->
                        </vue-form-item>
                        <vue-form-item label="내용(operation.content)">
                            <vue-input
                                type="textarea"
                                v-model="item.content"
                                :disabled="!isEditMode">
                            </vue-input>
                        </vue-form-item>
<!--                        <vue-form-item label="SUBTITLE">-->
<!--                            <vue-input-->
<!--                                v-model="item.name"-->
<!--                                :disabled="!isEditMode">-->
<!--                            </vue-input>-->
<!--                        </vue-form-item>-->


<!--                        <vue-form-item label="대표 이미지 URL">-->
<!--                            <progress v-if="isEditMode" value="0" max="100" id="uploader"></progress>-->
<!--                            <input accept="image/*" type="file" ref="file" value="upload" @change="imageSelected" :disabled="!isEditMode">-->
<!--&lt;!&ndash;                            <img width="100%" :src="item.imageUrl"/>&ndash;&gt;-->
<!--                            <vue-input-->
<!--                                v-model="item.imageUrl"-->
<!--                                :disabled="!isEditMode">-->
<!--                            </vue-input>-->
<!--                        </vue-form-item>-->
<!--                        <vue-editor-->
<!--                            v-model="item.content"-->
<!--                            :disabled="!isEditMode"/>-->
                        <hr>
                        <hash-tag-input v-model="item.tags" v-bind:isReadOnly="!isEditMode"/>
                    </vue-form>
                </div><!-- ./content-header -->
            </div><!-- ./bottom -->
        </div><!-- ./head-group -->
    </div>
</template>

<script>
    import {millisToReadable, toDate, toDateFormat, toTimestamp} from "../../../utils/TimeConverter";
    import AccessDropdownInput from "../../../inputs/OperatorDropdownInput";
    import HashTagInput from "../../../inputs/HashTagInput";
    import {setTagsByOperatorId} from "../../../api/operator";
    import {getOperations, writeOperation} from "../../../api/operation";
    import {checkOperator, deleteItem, reorderItems} from "../../../api/common";

    import { VueEditor } from "vue2-editor";
    import { Datetime } from 'vue-datetime';
    import DropdownInput from "../../../inputs/DropdownInput";

    export default {
        name: "ReportEditor",
        components: {
            DropdownInput,
            AccessDropdownInput,
            HashTagInput,

            Datetime,
            VueEditor,
        },
        props: {
            item: {
                type: Object,
                default: function() { return{} }
            },
            isAddMode: {
                type: Boolean,
                default: true
            }
        },
        data(){
          return {
              datetimeData: null,

              isEditMode: this.isAddMode,

              issueList: [],
              selectedIssue: {}
          }
        },
        async created() {
            this.updateIssue();
            this.timestampToDatetime();

            let that = this;
            await getOperations("assign").then(function(items){
                that.issueList = [];

                items.forEach(function (e) {
                    if(!e.deleted) {
                        that.issueList.push({
                            name: `ISSUE_${e.id}`,
                            value: e.id
                        })
                    }
                });
            })
        },
        watch:{
            $route(){
                this.isEditMode = this.isAddMode;

                this.updateIssue();
                this.timestampToDatetime();
            }
        },
        methods: {
            setOperatorId: function(id) {
                setTagsByOperatorId(id, this.item);
            },
            onClickSaveBtn: async function() {
                this.item.completedWhen = toTimestamp(this.datetimeData);

                let that = this;
                await writeOperation(that.item).then(function (item) {
                    alert("저장 되었습니다.");

                    that.item.id = item.id;
                    that.isEditMode = false;

                    that.$emit("updateList");
                });

                let path =`/admin/report?id=${this.item.id}`;
                if(this.$route.fullPath !== path) {
                    await this.$router.push(path);
                }
            },
            onClickCloseBtn: function() {
                this.isEditMode = false;

                this.$emit('onClose', null);

                // if(this.isAddMode || !this.isEditMode) {
                //     this.$router.push(`/admin/${this.modelKey}`);
                // } else {
                //     this.$router.push(`/admin/${this.modelKey}?id=${this.$route.query.id}`);
                // }
            },
            onClickEditBtn: function() {
                this.isEditMode = true;

                // this.$router.push(`/admin/${this.modelKey}?id=${this.$route.query.id}&edit`);
            },
            onClickRemoveBtn: function () {
                if(confirm("정말로 삭제하시겠습니까?")) {
                    let that = this;

                    deleteItem("operation", that.item.id).then(function (post) {
                        alert("삭제 되었습니다.");

                        that.$emit("updateList")

                        that.$emit('onClose', null);
                        // let path = `/admin/${that.modelKey}`;

                        // that.$router.push(path)
                    });
                }
            },
            timestampToDatetime(){
                if(this.item.completedWhen)
                    this.datetimeData = toDateFormat(this.item.completedWhen);
                else
                    this.datetimeData = null;
            },
            updateIssue: function(){
                if(0 !== this.item.parentId){
                    this.selectedIssue = {
                        name: `ISSUE_${this.item.parentId}`,
                        value: this.item.parentId
                    }
                } else {
                    this.selectedIssue = {
                        name: "Select",
                        value: 0
                    }
                }
            },
            onClickAssign: function(item){
                this.selectedIssue = item;

                this.item.parentId = item.value;

                // this.$emit('onClick', item.value);
            }
        }
    }
</script>

<!--<style scoped>-->
<style lang="scss">
    .vue-form__item-label, .tag_label {
        font-weight: bold;
        font-size: 12px;
        line-height: 30px;
        color: #B3B3B3;
    }

    .vdatetime-input {
        width: 100%;
        height: 30px;
    }

    textarea {
        width: 100%;
    }
</style>
