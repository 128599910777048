<template>
    <div style="width: 100%; height: 100%; overflow-y: scroll;" >
        <div class="container" style="padding-top: 50px;">
            <div class="row">
                <div class="col-6 col-md-3 col-lg-2" v-for="m in merchants" v-bind:key="'merchant-item-'+m.id" style="margin-bottom: 20px;">
                    <div class="card text-center" @click="openMerchantItem(m.id)" style="background-color: white; border-radius: 20px; overflow: hidden; box-shadow: 3px 3px 5px rgba(0,0,0,0.7);">
                        <div class="card-head" style="padding-top: 20px; padding-bottom: 20px;">

                            <div style="width: 90%; margin:auto; padding-bottom: 70%; background-position: center; background-size: contain; background-repeat: no-repeat"
                                 v-bind:style="{backgroundImage: 'url(' + m.logo + ')'}"/>
                        </div>
                        <div class="card-body" style="background: #D8FAC4; font-size: 1.2rem; font-weight: bold;">
                            {{m.title}}
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="fullscreen-background"  v-if="merchantItem != false">
            <div class="item-view-container">
                <merchant-item-view
                    :item="merchantItem"
                    :on-updated="onMerchantUpdated"
                    :on-close="onMerchantClose"/>
            </div>
        </div>
        <button style="position: fixed; bottom: 20px; right: 20px;" class="btn btn-primary" @click="openMerchantItem(0)">추가하기</button>
    </div>
</template>

<script>
import {getMerchantList, getMerchantItem, createNewMerchant} from "@/api/merchants";
import {showDialog} from "@/api/common";
import MerchantItemView from "@/views/contents/MerchantItemView";

export default {
    name: "MerchantsView",
    data() {
        return {
            merchants:[],
            merchantItem:false,
        }
    },
    components: {
        MerchantItemView,
    },
    created() {
    },
    mounted() {
        this.getMerchantList();
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    methods: {
        getMerchantList: function() {
            let self = this;
            getMerchantList()
            .then(function(data) {
                if(data.success === true) {
                    self.$data.merchants = data.item;
                } else {
                    showDialog(data.message);
                }
            })
            .catch(function(err) {
                let msg = err;
                if(err.response !== undefined) {
                    msg += " (" + err.response + ")";
                }
                console.log(msg);
                showDialog(msg, "ERROR");
            })
        },
        openMerchantItem: function(id) {
            if(id === 0) {
                this.$data.merchantItem = createNewMerchant();
                return;
            }
            let self = this;
            getMerchantItem(id)
            .then(function(data) {
                if(data.success === true) {
                    console.log(data.item);
                    self.$data.merchantItem = data.item;
                } else {
                    showDialog(data.message);
                }
            })
            .catch(function(err) {
                let msg = err;
                if(err.response !== undefined) {
                    msg += " (" + err.response + ")";
                }
                showDialog(msg, "ERROR");
            })

        },
        onMerchantUpdated: function(item) {
            for(let i = 0 ; i < this.$data.merchants.length ; i++) {
                if(this.$data.merchants[i].id === item.id) {
                    this.$data.merchants.splice(i, 1, item);
                    return;
                }
            }

            this.$data.merchants.push(item);
        },
        onMerchantClose: function() {
            this.$data.merchantItem = false;
        }
    }
}
</script>

<style scoped>

</style>
