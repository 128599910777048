import {deleteItem, getBlob, getCommonHeader, getItemById, getPageItemByURLPath} from "./common";
import {cleanLocalStorage} from "../store/auth.module";
import EventBus from "./event-bus";
const axios = require('axios');

const model = "credit";

export function getCredits() {

    var address = process.env.VUE_APP_API_BASE_URL + 'admin/'+ model +'s';

    return new Promise(function(resolve) {
        axios({
            method: 'post',
            url: address,
            headers: getCommonHeader()
        }).then(function(response){
            let data = response.data.list;

            resolve(data);
        }).catch(error => {
            if(error.response.status === 401)
                cleanLocalStorage();
            else
                console.log(error.response);
        })
    })
}

export function getIssuedCoupons(issuer, ref = null) {

    var address = process.env.VUE_APP_API_BASE_URL + 'admin/coupons/issuer?issuer=' + issuer;
    if(ref !== null) {
        address += '&ref=' + ref;
    }

    EventBus.$emit("showLoading", true, "가져오는중");
    return new Promise(function(resolve, reject) {
        axios({
            method: 'get',
            url: address,
            headers: getCommonHeader()
        }).then(function(response){
            EventBus.$emit("showLoading", false);
            let data = response.data;

            resolve(data);
        }).catch(error => {
            EventBus.$emit("showLoading", false);
            if(error.response.status === 401)
                cleanLocalStorage();
            else
                console.log(error.response);

            reject(error);
        })
    })
}
export function getIssuedCouponsReport(issuer, ref) {
    let urlPath = `admin/coupons/issued/excel?issuer=`+issuer;
    if(ref !== null) {
        urlPath += '&ref=' + ref;
    }
    return getBlob(urlPath);
}
export function generateIssuerCoupons(coupon, count) {

    var address = process.env.VUE_APP_API_BASE_URL + 'admin/coupons/issue/bulk?count='+count;

    EventBus.$emit("showLoading", true, "Generating");
    return new Promise(function(resolve, reject) {
        axios({
            method: 'post',
            url: address,
            data: JSON.stringify(coupon),
            headers: getCommonHeader()
        }).then(function(response){
            EventBus.$emit("showLoading", false);
            let data = response.data;

            resolve(data);
        }).catch(error => {
            EventBus.$emit("showLoading", false);
            if(error.response.status === 401)
                cleanLocalStorage();
            else
                console.log(error.response);
            reject(error);
        })
    })
}

export function getUserPoints(userId, pagination) {
    let urlPath = `admin/points?userId=${userId}&size=${pagination.size}&page=${pagination.page}&sort=${pagination.sort}`;
    console.log("get user point list " + urlPath);
    return getPageItemByURLPath(urlPath);
}

export function getCreditById(id) {

    return getItemById(model, id)
}

export function writeCredit(item) {
    if(0===item.id){
        return createCredit(item);
    }

    return updateCredit(item);
}

export function createCredit(item) {
    let address = `${process.env.VUE_APP_API_BASE_URL}admin/${model}/register`;

    return new Promise(function(resolve, reject) {
        axios({
            method: 'post',
            url: address,
            data: JSON.stringify(item),
            headers: getCommonHeader()
        }).then(function(response){
            console.log(response);
            let data = response.data;
            resolve(data);
        }).catch(error => {
            if(reject != null) {
                reject(error)
            }
            if(error.response.status === 401)
                cleanLocalStorage();
            else
                console.log(error.response);
        })
    })
}

export function deleteCredit(id) {

    return deleteItem(model, id)
}

export function updateCredit(item) {
    let address = `${process.env.VUE_APP_API_BASE_URL}admin/${model}/update`;

    return new Promise(function(resolve, reject) {
        axios({
            method: 'post',
            url: address,
            data: JSON.stringify(item),
            headers: getCommonHeader()
        }).then(function(response){
            let data = response.data.item;

            resolve(data);
        }).catch(error => {
            if(error.response.status === 401)
                cleanLocalStorage();
            else
                console.log(error.response);
        })
    })
}


export function newCredit() {
    return {
        id: 0,
        parentId: null,
        operatorId: 0,
        userId: 0,
        title: "",
        name: "",
        type: "",
        lastUpdatedWhen: null,
        tags: [],
        description: "",
        credit: 0,
        used: 0,
        balance: 0,
        transferable: false,
        usedTime: 0,
        creditTime:0, //자전거용 쿠폰 발행시 시간으로 사용
        validFromTime: 0,
        validToTime: 0
    }
}
