<template>
    <div class="detail-view">

        <div>

            <div class="col-12 text-right mary-button-container" style="position: sticky; top:0; background-color: white; z-index: 2; box-shadow: 2px 2px 3px gray;">
                <div class="mary-button" style="background-color: black;" v-on:click="onClickCloseBtn()"><span><i class="fas fa-times"></i></span> {{$t('detail.close')}}</div>
                <div class="mary-button" v-show="!isEditMode" v-on:click="onClickEditBtn()"><span><i class="fas fa-edit"></i></span> {{$t('detail.edit')}}</div>
                <div class="mary-button" v-show="isEditMode" v-on:click="onClickSaveBtn()"><span><i class="fas fa-save"></i></span> {{$t('detail.save')}}</div>
                <div class="mary-button" style="background-color: red; float:left;" v-show="!isAddMode" v-on:click="onClickRemoveBtn()"><span><i class="fas fa-trash"></i></span> {{$t('detail.remove')}}</div>
            </div>

            <div class="head-group" style="position: relative;">
                <div class="top"></div>
                <div class="bottom">
                    <div class="content-header">
                        <vue-form style="width: 100%;"
                                  label-width="100%"
                                  label-position="left">
                            <AccessDropdownInput
                                :is-read-only="!isEditMode"
                                v-bind:operatorId="item.operatorId"
                                v-on:onClick="setOperatorId"/>
                            <hr>
                            <vue-form-item label="Issue ID(operation.id)">
                                <label v-if="0 === item.id">저장 후 번호가 생성됩니다.</label>
                                <label v-else>ISSUE_{{item.id}}</label>
                                <!--                            <vue-input-->
                                <!--                                v-model="item.title"-->
                                <!--                                :disabled="!isEditMode">-->
                                <!--                            </vue-input>-->
                            </vue-form-item>
                            <vue-form-item label="종류">
                                <select class="form-control" v-model="item.type" :disabled="!isEditMode">
                                    <option v-for="type in typeList" :value="type.value">{{type.name}}</option>
                                </select>
                            </vue-form-item>
                            <vue-form-item label="상태(operation.status)">
                                <select class="form-control" v-model="item.status" :disabled="!isEditMode">
                                    <option v-for="status in statusList" :value="status.value">{{status.name}}</option>
                                </select>
                            </vue-form-item>
                            <vue-form-item label="자전거">
                                <select class="form-control" v-model="item.assetId" :disabled="!isEditMode">
                                    <option value="0">없음</option>
                                    <option v-for="bike in bikes" :value="bike.id">{{bike.name}} {{bike.fullCode}}</option>
                                </select>
                            </vue-form-item>
                            <vue-form-item label="담당자(operation.userId)">
                                <select class="form-control" v-model="item.userId" :disabled="!isEditMode">
                                    <option value="0">선택</option>
                                    <option v-for="user in users" :value="user.id">{{user.name}} {{user.phoneNumber}} {{user.email}}</option>
                                </select>
<!--                                <label>{{item.userId}}</label>-->
                            </vue-form-item>
                            <vue-form-item label="리포터(operation.reporterId)">
                                <select class="form-control" v-model="item.reporterId" :disabled="!isEditMode">
                                    <option value="0">선택</option>
                                    <option v-for="user in users" :value="user.id">{{user.name}} {{user.phoneNumber}} {{user.email}}</option>
                                </select>
                            </vue-form-item>
                            <vue-form-item label="발생시간(operation.createdWhen)">
                                <!--                            <label v-if="!isEditMode">{{datetimeData}}</label>-->
                                <datetime
                                    type="datetime"
                                    v-model="datetimeData"
                                    format="yyyy.MM.dd HH:mm:ss"
                                    value-zone="UTC+09:00"
                                    :disabled="!isEditMode"
                                    use12-hour/>
                                <!--                            <vue-input-->
                                <!--                                v-model="item.createdWhen"-->
                                <!--                                :disabled="!isEditMode">-->
                                <!--                            </vue-input>-->
                            </vue-form-item>

                            <vue-form-item label="내용(operation.content)">
                                <vue-input
                                    type="textarea"
                                    v-model="item.content"
                                    :disabled="!isEditMode">
                                </vue-input>
                            </vue-form-item>

                            <div class="container">
                                <div class="row">
                                    <div class="col-12 text-left">
                                        <h4>코멘트</h4>
                                    </div>
                                </div>
                            </div>
                            <div class="container" style="margin-bottom:30px;" v-for="(comment, index) in item.comments">
                                <div class="row">
                                    <div class="col-6 text-left">
                                        <span v-if="comment.reporter != null" >{{comment.reporter.name}}</span>
                                    </div>
                                    <div class="col-6 text-right">
                                        {{millisToReadable(comment.createdWhen)}}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 comment-content">
                                        <div v-html="comment.comment"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="container">
                                <div class="row">
                                    <textarea class="col-12 form-control" rows="5" v-model="newComment" placeholder="코멘트를 남겨주세요"></textarea>
                                </div>
                                <div class="row">
                                    <div class="col-12" style="margin-top:20px;">
                                        <button v-show="newComment.length > 0" type="button" class="btn btn-primary" v-on:click="addNewComment">코멘트 남기기</button>
                                    </div>
                                </div>
                            </div>
<!--                            <details open>-->
<!--                                <summary>COMMENTS</summary>-->
<!--                                <p>-->
<!--                                <div class="btn add-item" v-on:click="onClickAddBtn()">-->
<!--                                    <span>{{$t('list.add')}}</span>-->
<!--                                    <span class="fas fa-plus"></span>-->
<!--                                </div>-->
<!--                                <vue-form-item label="자전거(operation.assetId)">-->
<!--                                    <select class="form-control" v-model="item.assetId" :disabled="!isEditMode">-->
<!--                                        <option value="0">선택</option>-->
<!--                                        <option v-for="bike in bikes" :value="bike.id">{{bike.name}} {{bike.fullCode}}</option>-->
<!--                                    </select>-->
<!--                                </vue-form-item>-->
<!--                                    <div class="row">-->
<!--                                        <div class="col-6">-->
<!--                                            <span v-if="comment.reporter != null" >{{comment.reporter.name}}</span>-->
<!--                                        </div>-->
<!--                                        <div class="col-6 text-right">-->
<!--                                            {{millisToReadable(comment.createdWhen)}}-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--                                <div v-for="(comment, index) in item.comments">-->
<!--                                    <vue-form v-if="!comment.deleted"-->
<!--                                              style="width: 100%;"-->
<!--                                              label-width="25%"-->
<!--                                              label-position="left">-->

<!--                                        <vue-form-item label="내용">-->
<!--                                            <vue-input-->
<!--                                                type="textarea"-->
<!--                                                v-model="comment.content"-->
<!--                                                :disabled="!isEditMode">-->
<!--                                            </vue-input>-->
<!--                                        </vue-form-item>-->
<!--                                        <vue-button v-if="isEditMode" class="btn light col-12 form-control" v-on:click="deleteComment(index)">-->
<!--                                            삭제-->
<!--                                        </vue-button>-->
<!--                                        <br><br>-->
<!--                                    </vue-form>-->
<!--                                </div>-->
<!--                                <vue-button class="btn light col-12 form-control" v-on:click="addNewComment">-->
<!--                                    코멘트 추가-->
<!--                                </vue-button>-->

<!--                                    <div class="row">-->
<!--                                        <div class="col-12 comment-content">-->
<!--                                            <div v-html="comment.comment"></div>-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--                                <div class="container">-->
<!--                                    <div class="row">-->
<!--                                        <textarea class="col-12 form-control" rows="5" v-model="newComment"></textarea>-->
<!--                                    </div>-->
<!--                                    <div class="row">-->
<!--                                        <div class="col-12" style="margin-top:20px;">-->
<!--                                            <button type="button" class="btn btn-primary" v-on:click="addNewComment">코멘트 남기기</button>-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                                </p>-->
<!--                            </details>-->
                            <hr>
                            <hash-tag-input v-model="item.tags" v-bind:isReadOnly="!isEditMode"/>
                        </vue-form>
                    </div><!-- ./content-header -->
                </div><!-- ./bottom -->
            </div><!-- ./head-group -->
        </div>
    </div>
</template>

<script>
    import {millisToReadable, toDate, toDateFormat, toTimestamp} from "../../../utils/TimeConverter";
    import AccessDropdownInput from "../../../inputs/OperatorDropdownInput";
    import HashTagInput from "../../../inputs/HashTagInput";
    import {setTagsByOperatorId} from "../../../api/operator";
    import {writeOperation, getStatusList, getOperationTypes} from "../../../api/operation";
    import {changeURL, deleteItem, isOk} from "../../../api/common";
    import {getAssets} from "../../../api/asset";

    import { VueEditor } from "vue2-editor";
    import { Datetime } from 'vue-datetime';
    import {getUsersWithCondition} from "../../../api/user";

    export default {
        name: "AssignEditor",
        components: {
            AccessDropdownInput,
            HashTagInput,

            Datetime,
            VueEditor,
        },
        props: {
            item: {
                type: Object,
                default: function() { return{} }
            },
            isAddMode: {
                type: Boolean,
                default: true
            }
        },
        data(){
          return {
              datetimeData: null,

              isEditMode: this.isAddMode,

              users:[],
              bikes:[],
              statusList:[],
              typeList:[],
              newComment:'',
          }
        },
        created() {
            this.$data.statusList = getStatusList();
            this.$data.typeList = getOperationTypes();

            this.datetimeData = this.item.createdWhen ? toDateFormat(this.item.createdWhen) : null;

            this.getBikeList();
            this.getUserList();
        },
        watch:{
            $route(){
                this.isEditMode = this.isAddMode;

                this.datetimeData = this.item.createdWhen ? toDateFormat(this.item.createdWhen) : null;
            }
        },
        methods: {
            setOperatorId: function(id) {
                console.log("setOperatorId " + id);
                setTagsByOperatorId(id, this.item);

                this.getBikeList();
                this.getUserList();
            },
            onClickSaveBtn: async function() {
                // console.log(this.datetimeData);
                this.item.createdWhen = toTimestamp(this.datetimeData);
                // console.log(this.item);

                if(this.item.comments !== undefined) {
                    for (let comment of this.item.comments) {
                        await writeOperation(comment);
                    }
                }

                let that = this;
                writeOperation(that.item).then(function (item) {
                    that.item.id = item.id;

                    alert("저장 되었습니다.");

                    that.isEditMode = false;

                    that.$emit("updateList");

                    changeURL('assign', that.$route, that.$router, item);
                });
            },
            onClickCloseBtn: function() {
                if(this.isEditMode){
                    this.isEditMode = false;

                    return;
                }

                this.$emit('onClose', null);

                // if(this.isAddMode || !this.isEditMode) {
                //     this.$router.push(`/admin/${this.modelKey}`);
                // } else {
                //     this.$router.push(`/admin/${this.modelKey}?id=${this.$route.query.id}`);
                // }
            },
            onClickEditBtn: function() {
                this.isEditMode = true;

                // this.$router.push(`/admin/${this.modelKey}?id=${this.$route.query.id}&edit`);
            },
            onClickRemoveBtn: function () {
                if(confirm("정말로 삭제하시겠습니까?")) {
                    let that = this;

                    deleteItem("operation", that.item.id).then(function (post) {
                        alert("삭제 되었습니다.");

                        that.$emit("updateList")

                        that.$emit('onClose', null);
                        // let path = `/admin/${that.modelKey}`;

                        // that.$router.push(path)
                    });
                }
            },
            getBikeList: function(check = isOk) {
                console.log("getBikeList " + this.item.operatorId);
                this.$data.bikes = [];
                //this.item.assetId = 0;
                if(this.item.operatorId == 0) {
                    return;
                }
                let self = this;
                console.log("getBikes now");
                getAssets("bike", this.item.operatorId)
                .then(function(data) {
                    // console.log(data);
                    self.$data.bikes = data;
                })
                // .catch(function(err) {
                //     console.log(err);
                // })
            },
            getUserList: function() {
                this.$data.users = [];

                let self = this;

                getUsersWithCondition(`operatorId=${this.item.operatorId}`)
                    .then( list => {
                        self.$data.users = list;
                    });
            },
            addNewComment: function() {
                // this.isEditMode = true;
                if(this.item.comments === undefined){
                    this.item.comments = [];
                }

                if(this.$data.newComment == undefined || this.$data.newComment.length == 0) {
                    return;
                }
                let newItem = {
                    id: 0,
                    parentId: this.item.id,
                    comment:this.$data.newComment,

                };
                //
                // console.log(this.item.comments.length);

                console.log("New comment to be added");
                let self = this;
                // console.log(this.item);
                writeOperation(newItem)
                .then(function(item) {
                    self.$data.newComment = '';
                    // console.log(self.item.comments);
                    self.item.comments.push(item);
                })
                .catch(function(err) {
                    console.log("ERR " + err);
                })

            },
            deleteComment: function(index){
                if(0 === this.item.comments[index].id){
                    this.item.comments.pop();

                    return;
                }

                if(confirm(`코멘트를 지우시겠습니까?`)){
                    let comment = this.item.comments[index];

                    comment.deleted = true;

                    writeOperation(comment).then(e => {
                        alert("삭제되었습니다.")
                    });
                }
            },
            millisToReadable: millisToReadable,
        }
    }
</script>

<!--<style scoped>-->
<style lang="scss">
    .vue-form__item-label, .tag_label {
        font-weight: bold;
        font-size: 12px;
        line-height: 30px;
        color: #B3B3B3;
    }

    .vdatetime-input {
        width: 100%;
        height: 30px;
    }

    textarea {
        width: 100%;
    }
    .comment-wrapper {
        /*border: 1px solid gray;*/
        margin-bottom: 20px;
    }
    .comment-content {
        border: 1px solid #ddd;
        padding-top:10px;
        padding-bottom:10px;
        border-radius: 10px;
    }

</style>
