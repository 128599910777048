<template>
    <div style="width: 100%; height: 100%;">
        <div style="width: 100%; height: 50px; line-height: 50px; font-size: 30px; font-weight: bold; text-align: center;">
            사업자 정보
        </div>
        <div style="width: 100%; height: calc(100% - 100px); display: flex;">
            <div style="width: 50%; height: 100%; overflow-y: scroll;">
                <div class="container" style="padding-bottom: 50px;">
                    <div class="row">
                        <div class="col-12 col-md-6">
                            판매사업자명
                            <input class="form-control" v-model="item.title"/>
                        </div>
                        <div class="col-12 col-md-6">
                            LOGO
                            <img v-if="item.logo !== null && item.logo.length > 0" :src="item.logo" style="width: 100px; margin-bottom: 20px;"/>
                            <br/>

                            <progress value="0" max="100" id="logo-uploader"></progress>
                            <input accept="image/*" type="file" ref="logoimage" value="upload" @change="onLogoImagePickedUp">

                        </div>
                        <div class="col-12 col-md-6">
                            설명
                            <input class="form-control" v-model="item.description"/>
                        </div>
                        <div class="col-12 col-md-6">
                            대표전화
                            <input class="form-control" v-model="item.contact"/>
                        </div>
                        <div class="col-12 col-md-6">
                            대표이메일
                            <input class="form-control" v-model="item.contactEmail"/>
                        </div>
                        <div class="col-12 col-md-6">
                            주소
                            <input class="form-control" v-model="item.address"/>
                        </div>
                        <div class="col-12 col-md-6">
                            우편번호
                            <input class="form-control" v-model="item.postCode"/>
                        </div>
                        <div class="col-12 col-md-6">
                            사업자번호
                            <input class="form-control" v-model="item.businessNumber"/>
                        </div>

                        <div class="col-12 col-md-6" v-if="item.id === 0">
                                신규등록 이후에 사진을 등록할 수 있습니다.
                        </div>
                        <div class="col-12" v-else>
                            <div  style="width: 100%; height: 300px; overflow-y: auto; display: flex;" v-if="item.images !== null && item.images.length > 0">
                                <div style="width: 300px; position: relative; border:1px solid gray;" v-for="(image, ii) in item.images" v-bind:key="'image-' + ii">
                                    <img style="width: 300px;" :src="image" />
                                    <div style="position: absolute; top: 20px; right: 20px;"><span style="color:red;" v-on:click="onClickRemoveDescriptionImage(ii)"><i class="fas fa-trash"></i></span></div>
                                </div>
                            </div>
                            <progress value="0" max="100" id="img-uploader"></progress>
                            <input accept="image/*" type="file" ref="descriptionfile" value="upload" @change="onImagePickedUp">

                        </div>
                        <div class="col-6">
                            <button class="form-control btn" v-bind:class="{'btn-success': item.b2c === true}" @click="item.b2c = !item.b2c">B2C</button>
                            수수료
                            <input class="form-control" type="number" v-model="item.b2cRateFloat"/>
                        </div>
                        <div class="col-6">
                            <button class="form-control btn" v-bind:class="{'btn-success': item.b2b === true}" @click="item.b2b = !item.b2b">B2B</button>
                            수수료
                            <input class="form-control" type="number" v-model="item.b2bRateFloat"/>
                        </div>
                        <div class="col-12" v-if="item.id > 0 && item.requireApproval === true">
                            <button class="btn btn-danger" @click="approve">승인하기</button>
                        </div>
                    </div>
                </div>
            </div>
            <div style="width: 50%; height: 100%; overflow-y: scroll;">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            관리자
                        </div>
                        <div class="col-12">
                            <table class="table">
                                <tr>
                                    <th>이메일/이름</th>
                                    <th></th>
                                </tr>
                                <tbody v-for="user in users" v-bind:key="'user-link-item-row-' + user.id">
                                    <tr>
                                        <td>{{user.user.email}}</td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="col-12">
                            초대
                        </div>
                        <div class="col-12">
                            <table class="table">
                                <tr>
                                    <th>이메일</th>
                                    <th>이름</th>
                                    <th>초대일</th>
                                    <th>
                                        <button class="btn btn-success"><i class="fas fa-plus"></i> 초대하기</button>
                                    </th>
                                </tr>
                                <tbody v-for="invitation in invitations" v-bind:key="'invitation-item-' + invitation.id">
                                <tr>
                                    <td>{{invitation.email}}</td>
                                    <td>{{invitation.name}}</td>
                                    <td>{{timeToDate(invitation.invitationTime)}}</td>
                                </tr>
                                </tbody>

                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div style="width: 100%; height: 50px; padding-top:10px; background-color: #adadad;">
            <div class="container">
                <div class="row">
                    <div class="col-6 text-left">
                        <button class="btn btn-dark" @click="closeView">닫기</button>
                    </div>
                    <div class="col-6 text-right">
                        <button class="btn btn-primary" @click="updateItem">업데이트</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {getMerchantInvitations, getMerchantUsers, updateMerchantItem} from "@/api/merchants";
import {showDialog} from "@/api/common";
import {getApp} from "firebase/app";
import {getDownloadURL, getStorage, ref, uploadBytesResumable} from "firebase/storage";
import {millisToDate} from "@/api/common";
import moment from 'moment';

export default {
    name: "MerchantItemView",
    props: {
        item:{
            type:Object,
            default:function(){return null;}
        },
        onUpdated:{
            type:Function,
            default: null,
        },
        onClose:{
            type:Function,
            default: null,
        }
    },
    data(){
        return {
            users:[],
            invitations:[],
        }
    },
    created() {
    },
    mounted() {
        this.getUserList();
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    methods: {
        timeToDate: millisToDate,
        closeView: function() {
            if(this.onClose !== null) {
                this.onClose();
            }
        },
        onClickRemoveDescriptionImage: function(index) {
            this.item.images.splice(index, 1);
        },
        onImagePickedUp: function(e) {
            const uploader = document.getElementById('img-uploader');
            let self = this;

            //get file
            console.log("File Btn Added");
            console.log(e);
            let getFile = e.target.files[0];
            console.log("File name - " + getFile.name);

            //set storage ref
            let d = new Date();
            let name  =  moment(d).format('YYYYMMDD-hhmmss');
            let names = getFile.name.split('.');
            let fileExt = names[names.length-1];
            let path = 'ncm-merchant/' + this.item.id + '-' + name + '.' + names[names.length-1];
            const firebaseApp = getApp();
            let contentType = null;
            if(fileExt.toUpperCase() === 'JPG' || fileExt.toUpperCase() === 'JPEG') {
                contentType = 'image/jpeg';
            } else if(fileExt.toUpperCase() === 'PNG') {
                contentType = 'image/png';
            } else if(fileExt.toUpperCase() === 'PDF') {
                contentType = 'application/pdf';
            }
            const metadata = {
                contentType: contentType
            };
            const storage = getStorage(firebaseApp);
            let storageRef = ref(storage, path);
            const uploadTask = uploadBytesResumable(storageRef, getFile, metadata);
            uploadTask.on('state_changed',
                (snapshot) => {
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    console.log('Upload is ' + progress + '% done');
                    uploader.value = progress;
                    switch (snapshot.state) {
                        case 'paused':
                            console.log('Upload is paused');
                            break;
                        case 'running':
                            console.log('Upload is running');
                            break;
                    }
                },
                (error) => {
                    // EventBus.$emit("showLoading", false);
                    // A full list of error codes is available at
                    // https://firebase.google.com/docs/storage/web/handle-errors
                    switch (error.code) {
                        case 'storage/unauthorized':
                            // User doesn't have permission to access the object
                            break;
                        case 'storage/canceled':
                            // User canceled the upload
                            break;

                        // ...

                        case 'storage/unknown':
                            // Unknown error occurred, inspect error.serverResponse
                            break;
                    }
                },
                () => {
                    // Upload completed successfully, now we can get the download URL
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        console.log('File available at', downloadURL);

                        self.item.images.push(downloadURL);
                    });
                }
            );

        },

        onLogoImagePickedUp: function(e) {
            const uploader = document.getElementById('logo-uploader');
            let self = this;

            //get file
            console.log("File Btn Added");
            console.log(e);
            let getFile = e.target.files[0];
            console.log("File name - " + getFile.name);

            //set storage ref
            let d = new Date();
            let name  =  moment(d).format('YYYYMMDD-hhmmss');
            let names = getFile.name.split('.');
            let fileExt = names[names.length-1];
            let path = 'ncm-merchant/logo-' + this.item.id + '-' + name + '.' + names[names.length-1];
            const firebaseApp = getApp();
            let contentType = null;
            if(fileExt.toUpperCase() === 'JPG' || fileExt.toUpperCase() === 'JPEG') {
                contentType = 'image/jpeg';
            } else if(fileExt.toUpperCase() === 'PNG') {
                contentType = 'image/png';
            } else if(fileExt.toUpperCase() === 'PDF') {
                contentType = 'application/pdf';
            }
            const metadata = {
                contentType: contentType
            };
            const storage = getStorage(firebaseApp);
            let storageRef = ref(storage, path);
            const uploadTask = uploadBytesResumable(storageRef, getFile, metadata);
            uploadTask.on('state_changed',
                (snapshot) => {
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    console.log('Upload is ' + progress + '% done');
                    uploader.value = progress;
                    switch (snapshot.state) {
                        case 'paused':
                            console.log('Upload is paused');
                            break;
                        case 'running':
                            console.log('Upload is running');
                            break;
                    }
                },
                (error) => {
                    // EventBus.$emit("showLoading", false);
                    // A full list of error codes is available at
                    // https://firebase.google.com/docs/storage/web/handle-errors
                    switch (error.code) {
                        case 'storage/unauthorized':
                            // User doesn't have permission to access the object
                            break;
                        case 'storage/canceled':
                            // User canceled the upload
                            break;

                        // ...

                        case 'storage/unknown':
                            // Unknown error occurred, inspect error.serverResponse
                            break;
                    }
                },
                () => {
                    // Upload completed successfully, now we can get the download URL
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        console.log('File available at', downloadURL);

                        self.item.logo = downloadURL;
                    });
                }
            );

        },
        updateItem: function() {
            let self = this;
            this.item.b2bRate = Math.round(parseFloat(this.item.b2bRateFloat)*100);
            this.item.b2cRate = Math.round(parseFloat(this.item.b2cRateFloat)*100);
            console.log(this.item);
            updateMerchantItem(this.item)
                .then(function(data) {
                    if(data.success === true) {
                        if(self.onUpdated !== null) {
                            self.onUpdated(data.item);
                        }
                        if(self.onClose !== null) {
                            self.onClose();
                        }
                    } else {
                        showDialog(data.message);
                    }
                })
                .catch(function(err) {
                    let msg = err;
                    if(err.response !== undefined) {
                        msg += ' (' + err.response + ')';
                    }
                    showDialog(msg, "에러");
                })
        },
        approve: function() {

        },
        getUserList: function() {
            let self = this;
            getMerchantUsers(this.item.id)
                .then(function(data) {
                    if(data.success === true) {
                        self.$data.users = data.item;
                    } else {
                        console.log("No users");
                    }
                    self.getPendingInvitations();
                })
                .catch(function(err) {
                    let msg = err;
                    if(err.response !== undefined) {
                        msg += ' (' + err.response + ')';
                    }
                    showDialog(msg, "에러");
                })
        },
        getPendingInvitations: function() {
            let self = this;
            getMerchantInvitations(this.item.id)
                .then(function(data) {
                    if(data.success === true) {
                        self.$data.invitations = data.item;
                    } else {
                        console.log("No Invitations");
                    }
                })
                .catch(function(err) {
                    let msg = err;
                    if(err.response !== undefined) {
                        msg += ' (' + err.response + ')';
                    }
                    showDialog(msg, "에러");
                })
        }
    }
}
</script>

<style scoped>

</style>
