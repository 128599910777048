<template>
    <div class="detail-view">

        <div class="col-12 text-right mary-button-container" style="position: sticky; top:0; background-color: white; z-index: 2; box-shadow: 2px 2px 3px gray;">
            <div class="mary-button" style="background-color: black;" v-on:click="onClickCloseBtn()"><span><i class="fas fa-times"></i></span> {{$t('detail.close')}}</div>
            <div class="mary-button" v-show="!isEditMode" v-on:click="onClickEditBtn()"><span><i class="fas fa-edit"></i></span> {{$t('detail.edit')}}</div>
            <div class="mary-button" v-show="isEditMode" v-on:click="onClickSaveBtn()"><span><i class="fas fa-save"></i></span> {{$t('detail.save')}}</div>
            <div class="mary-button" style="background-color: red; float:left;" v-show="!isAddMode" v-on:click="onClickRemoveBtn()"><span><i class="fas fa-trash"></i></span> {{$t('detail.remove')}}</div>
        </div>

        <div class="head-group">
            <div class="top"></div>
            <div class="bottom">
                <div class="content-header">
                    <vue-form style="width: 100%;"
                              label-width="100%"
                              label-position="left">
                        <AccessDropdownInput
                            :is-read-only="!isEditMode"
                            v-bind:operatorId="item.operatorId"
                            v-on:onClick="setOperatorId"/>
                        <vue-form-item :label="$t('content.title')">
                            <vue-input
                                v-model="item.title"
                                :disabled="!isEditMode">
                            </vue-input>
                        </vue-form-item>
                        <vue-form-item label="SUBTITLE">
                            <vue-input
                                v-model="item.name"
                                :disabled="!isEditMode">
                            </vue-input>
                        </vue-form-item>
                        <vue-form-item label="우선순위 (핫딜 / 홈화면 리스팅)">
                            <select class="form-control" v-model="item.hotDeal" :disabled="!isEditMode">
                                <option value="false">일반</option>
                                <option value="true">핫딜</option>
                            </select>
                        </vue-form-item>
                        <vue-form-item label="매거진 종류">
                            <select class="form-control" v-model="item.contentType" :disabled="!isEditMode" @change="onContentTypeChanged">
                                <option v-for="(type, index) in contentTypes"
                                        :key="'ct-'+index"
                                        :value="type.value">{{type.name}}</option>
                            </select>
                        </vue-form-item>

                        <vue-form-item label="매거진 카테고리">
                            <select class="form-control" v-model="item.type" :disabled="!isEditMode">
                                <option v-for="(type, index) in categoryTypes"
                                        :key="'cat-'+index"
                                        :value="type.value">{{type.name}}</option>
                            </select>
                        </vue-form-item>

                        <vue-form-item label="연결 플레이스" v-if="item.contentType === 'place'">
                            <select class="form-control" v-model="item.placeId" :disabled="!isEditMode">
                                <option value="0">선택하세요</option>
                                <template v-if="placeList.length > 0">
                                <option v-for="place in placeList"
                                        :key="place.id"
                                        :value="place.id">{{place.name}}</option>
                                </template>
                            </select>
                        </vue-form-item>
                        <vue-form-item label="대표 이미지 URL">
                            <progress v-if="isEditMode" value="0" max="100" id="uploader"></progress>
                            <input accept="image/*" type="file" ref="file" value="upload" @change="imageSelected" :disabled="!isEditMode">
                            <img width="100%" :src="item.imageUrl"/>
                            <vue-input
                                v-model="item.imageUrl"
                                :disabled="!isEditMode">
                            </vue-input>
                        </vue-form-item>
                        <vue-editor
                            v-model="item.content"
                            :disabled="!isEditMode"/>
                        <hr>
<!--                        <hash-tag-input v-model="item.tags" v-bind:isReadOnly="!isEditMode"/>-->
                        <hash-tag-input v-model="item.tags" v-bind:isReadOnly="!isEditMode"/>
                        <div style="width: 100%; display: inline-block; border:1px solid black;" v-if="isEditMode">
                            <template v-for="t in serviceTags">
                                <div style="padding:10px; float: left; margin: 10px; border: 1px solid grey; border-radius: 10px;" v-bind:key="'service-tag-' + t.id" v-if="isTagOnList(t) == false" @click="onTagClicked(t)">
                                    {{t.name}}
                                </div>
                            </template>
                        </div>
                    </vue-form>
                </div><!-- ./content-header -->
            </div><!-- ./bottom -->
        </div><!-- ./head-group -->
    </div>
</template>

<script>
    import {millisToReadable} from "../../../utils/TimeConverter";
    import AccessDropdownInput from "../../../inputs/OperatorDropdownInput";
    import HashTagInput from "../../../inputs/HashTagInput";
    import {setTagsByOperatorId} from "../../../api/operator";

    import {getMagazineContentTypes, getMagazineTypes, writeMagazine} from "../../../api/magazine";
    import {deleteItem} from "../../../api/common";

    import { VueEditor } from "vue2-editor";
    import moment from 'moment';
    // import * as firebase from "firebase/app";

    // Add the Firebase services that you want to use
    // import "firebase/auth";
    // import "firebase/storage";
    import {getApp} from "firebase/app";
    import {getDownloadURL, getStorage, ref, uploadBytesResumable} from "firebase/storage";
    import {getPlaces} from "../../../api/place";
    import {getTagList} from "../../../api/tag";


    export default {
        name: "PageEditor",
        components: {
            AccessDropdownInput,
            HashTagInput,

            VueEditor,
        },
        props: {
            item: {
                type: Object,
                default: function() { return null }
            },
            isAddMode: {
                type: Boolean,
                default: true
            },
        },
        data(){
          return {
              onOffValue: 0,

              isEditMode: this.isAddMode,

              firebase: null,
              contentTypes:getMagazineContentTypes(),
              categoryTypes:getMagazineTypes(),
              placeList:[],
              serviceTags:[],
          }
        },
        created() {

            this.getTagList();
            this.getPlaceList();
        },
        watch:{
            $route(){
                this.isEditMode = this.isAddMode;
            },
            item: function() {
                this.getTagList();
            }
        },
        methods: {
            getPlaceList: function() {
                let self = this;
                getPlaces()
                .then(function(list){
                    self.$data.placeList = list;
                })
                .catch(function(err) {
                    console.log(err);
                    if(err.response !== undefined) {
                        console.log(err.response);
                    }
                })
            },
            onContentTypeChanged: function() {
              if(this.$data.placeList.length == 0 && this.item.contentType === 'place') {
                  this.getPlaceList();
              }
            },
            setOperatorId: function(id) {
                setTagsByOperatorId(id, this.item);
            },
            onClickSaveBtn: function() {
                let that = this;

                writeMagazine(that.item).then(function (item) {
                    alert("저장 되었습니다.");

                    that.isEditMode = false;

                    that.$emit("updateList");

                    that.$router.push(`/admin/magazine?id=${item.id}`);
                })
            },
            onClickCloseBtn: function() {
                this.isEditMode = false;

                this.$emit('onClose', null);

                // if(this.isAddMode || !this.isEditMode) {
                //     this.$router.push(`/admin/${this.modelKey}`);
                // } else {
                //     this.$router.push(`/admin/${this.modelKey}?id=${this.$route.query.id}`);
                // }
            },
            onClickEditBtn: function() {
                this.isEditMode = true;

                // this.$router.push(`/admin/${this.modelKey}?id=${this.$route.query.id}&edit`);
            },
            onClickRemoveBtn: function() {
                let self = this;
                this.$modal.show('dialog', {
                    title: '',
                    text: "메거진 " + this.item.title + '를 삭제 하시겠습니까?',
                    buttons:[
                        {
                            title: '아니요',
                            handler:() => {
                                self.$modal.hide('dialog');
                            }
                        },
                        {
                            title:'삭제',
                            handler:()=>{
                                self.$modal.hide('dialog');
                                self.onClickRemoveBtnConfirmed();
                            }
                        }
                    ]
                })
            },
            onClickRemoveBtnConfirmed: function () {
                // if(confirm("정말로 삭제하시겠습니까?")) {
                let that = this;

                deleteItem("magazine", that.item.id).then(function (post) {
                    alert("삭제 되었습니다.");

                    console.log("deleted");
                    console.log(post);
                    that.$emit("updateList")

                    that.$emit('onClose', null);
                    // let path = `/admin/${that.modelKey}`;

                    // that.$router.push(path)
                });
                // }
            },
            millisToReadable: millisToReadable,
            imageSelected:function(e){
                // e.preventDefault();
                const uploader = document.getElementById('uploader');
                //get file
                console.log("File Btn Added");
                console.log(e);
                let getFile = e.target.files[0];
                console.log("File name - " + getFile.name);
                //set storage ref
                let d = new Date();
                let name  =  moment(d).format('YYYYMMDD-hhmmss');
                let names = getFile.name.split('.');

                let fileExt = names[names.length-1];
                let path = 'ncm-magazine/'  + this.item.id + '-' + name + '.' + names[names.length-1];
                const firebaseApp = getApp();
                let contentType = null;
                if(fileExt.toUpperCase() === 'JPG' || fileExt.toUpperCase() === 'JPEG') {
                    contentType = 'image/jpeg';
                } else if(fileExt.toUpperCase() === 'PNG') {
                    contentType = 'image/png';
                } else if(fileExt.toUpperCase() === 'PDF') {
                    contentType = 'application/pdf';
                }
                const metadata = {
                    contentType: contentType
                };
                const storage = getStorage(firebaseApp);
                let storageRef = ref(storage, path);
                const uploadTask = uploadBytesResumable(storageRef, getFile, metadata);
                // let storageRef = firebase.storage().ref();
                // let mountainsRef = storageRef.child(path);
                let self = this;
                uploadTask.on('state_changed',
                    (snapshot) => {
                        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        console.log('Upload is ' + progress + '% done');
                        uploader.value = progress;
                        switch (snapshot.state) {
                            case 'paused':
                                console.log('Upload is paused');
                                break;
                            case 'running':
                                console.log('Upload is running');
                                break;
                        }
                    },
                    (error) => {
                        // EventBus.$emit("showLoading", false);
                        // A full list of error codes is available at
                        // https://firebase.google.com/docs/storage/web/handle-errors
                        switch (error.code) {
                            case 'storage/unauthorized':
                                // User doesn't have permission to access the object
                                break;
                            case 'storage/canceled':
                                // User canceled the upload
                                break;

                            // ...

                            case 'storage/unknown':
                                // Unknown error occurred, inspect error.serverResponse
                                break;
                        }
                    },
                    () => {
                        // Upload completed successfully, now we can get the download URL
                        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                            console.log('File available at', downloadURL);
                            self.item.imageUrl = downloadURL;
                        });
                    }
                );
                // mountainsRef.put(getFile).on('state_changed',
                //
                //     function progress(snapshot){
                //
                //         let percentage = (snapshot.bytesTransferred / snapshot.totalBytes) *100;
                //         console.log("percentage " + percentage);
                //         uploader.value = percentage;
                //     },
                //     function error(err){
                //         console.log(err);
                //     },
                //     function complete(){
                //         console.log('complete upload');
                //         self.item.imageUrl = 'https://storage.googleapis.com/ncm-mary-254508.appspot.com/' + path;
                //     }
                // );

            },
            getTagList: function() {
                let self = this;
                console.log("get tag list");
                getTagList('service')
                    .then(function(data) {
                        console.log(data);
                        if(data.success == true) {
                            self.$data.serviceTags = data.list;

                        }
                    })
            },
            isTagOnList: function(tag) {
                if(this.item.tags.length == 0 ) {
                    return false;
                }
                for(let i = 0 ; i < this.item.tags.length ; i++) {
                    if(this.item.tags[i].id == tag.id) {
                        return true;
                    }
                }
                return false;
            },
            onTagClicked: function(tag) {
                for(let i = 0 ; i < this.item.tags.length ; i++) {
                    if(this.item.tags[i].id == tag.id) {
                        return;
                    }
                }
                if(this.item !== null && this.item.tags !== undefined) {
                    this.item.tags.push(JSON.parse(JSON.stringify(tag)));
                }
            },
        }
    }
</script>
