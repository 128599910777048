<template>
    <div class="detail-view h-100" v-bind:id="'detail-view-'+_uid">
        <div class="container-fluid h-100">
            <div class="row h-100">
                <div class="col-6 h-100" style="overflow-y: scroll;">

                    <div class="col-12 text-right mary-button-container" style="position: sticky; top:0; background-color: white; z-index: 2; box-shadow: 2px 2px 3px gray;">
                        <div class="mary-button" style="background-color: black;" v-on:click="onClickCloseBtn()"><span><i class="fas fa-times"></i></span> {{$t('detail.close')}}</div>
                        <div class="mary-button" v-show="!isEditMode" v-on:click="onClickEditBtn()"><span><i class="fas fa-edit"></i></span> {{$t('detail.edit')}}</div>
                        <div class="mary-button" v-show="isEditMode" v-on:click="onClickSaveBtn()"><span><i class="fas fa-save"></i></span> {{$t('detail.save')}}</div>
                        <div class="mary-button" style="background-color: red; float:left;" v-show="!isAddMode" v-on:click="onClickRemoveBtn()"><span><i class="fas fa-trash"></i></span> {{$t('detail.remove')}}</div>
                    </div>

                    <div class="head-group">
                        <div class="top">

                            <!-- created time -->
                            <div class="timestamp" v-if="!isAddMode">
                                {{millisToReadable(userInfo.createdWhen)}}
                            </div>

                            <!-- read/write access -->
                            <!--                <div class="access-rights">-->
                            <!--                    <div class="read-levels">-->
                            <!--                        <span class="access-label">{{$t('list.read_access')+':'}}</span>-->
                            <!--                        <div class="read-level" v-for="(readAccess, index) in userInfo.readableAccessList" v-bind:key="index">-->
                            <!--                            <span v-if="readAccess.type == 'private'" class="glyphicon glyphicon-lock" aria-hidden="true"/>-->
                            <!--                            <span v-if="readAccess.type == 'public'" class="glyphicon glyphicon-globe" aria-hidden="true"/>-->
                            <!--                            <span v-if="readAccess.type == 'group'" class="glyphicon glyphicon-user" aria-hidden="true"/>-->
                            <!--                            <span>{{readAccess.name}}</span>-->
                            <!--                        </div>-->
                            <!--                    </div>-->
                            <!--                    <div class="write-levels">-->
                            <!--                        <span class="access-label">{{$t('list.write_access')+':'}}</span>-->
                            <!--                        <div class="write-level" v-for="(writeAccess, index) in userInfo.writeableAccessList" v-bind:key="index">-->
                            <!--                            <span v-if="writeAccess.type == 'private'" class="glyphicon glyphicon-lock" aria-hidden="true"/>-->
                            <!--                            <span v-if="writeAccess.type == 'public'" class="glyphicon glyphicon-globe" aria-hidden="true"/>-->
                            <!--                            <span v-if="writeAccess.type == 'group'" class="glyphicon glyphicon-user" aria-hidden="true"/>-->
                            <!--                            <span>{{writeAccess.name}}</span>-->
                            <!--                        </div>-->
                            <!--                    </div>-->
                            <!--                </div>-->
                        </div><!-- ./top -->

                        <div class="bottom">
                            <div class="content-header">
                                <vue-form style="width: 100%;"
                                          label-width="100%"
                                          label-position="left">
                                    <!--                        <vue-form-item :label="$t('user.accountType')">-->
                                    <!--                            <vue-input-->
                                    <!--                                v-model="userInfo.accountType"-->
                                    <!--                                :disabled="!isEditMode">-->
                                    <!--                            </vue-input>-->
                                    <!--                        </vue-form-item>-->
                                    <vue-form-item :label="$t('list.access')">
                                        <vue-select
                                            v-model="userInfo.role"

                                            :disabled="!isSuperAdmin() || !isEditMode"
                                            :data="roleList"
                                            :placeholder="roleName">
                                            <vue-option
                                                v-for="i in roleList"
                                                :key="i.value"
                                                :value="i.value"
                                                :label="i.label">
                                            </vue-option>
                                        </vue-select>
                                    </vue-form-item>
                                    <operator-dropdown-input
                                        v-if="userInfo.role > 0"
                                        :is-read-only="!isEditMode"
                                        v-bind:operatorId="userInfo.operatorId"
                                        v-on:onClick="setOperatorId"/>
                                    <vue-form-item :label="$t('content.name')">
                                        <vue-input
                                            v-model="userInfo.name"
                                            :disabled="!isEditMode">
                                        </vue-input>
                                    </vue-form-item>
                                    <vue-form-item :label="$t('user.phoneNumber')">
                                        <vue-input
                                            v-model="userInfo.phoneNumber"
                                            :disabled="!isEditMode"
                                            :placeholder="$t('common.required')">
                                        </vue-input>
                                    </vue-form-item>
                                    <vue-form-item :label="$t('user.email')">
                                        <vue-input
                                            v-model="userInfo.email"
                                            :disabled="!isEditMode">
                                        </vue-input>
                                    </vue-form-item>
                                    <vue-form-item :label="$t('user.password')">
                                        <vue-input
                                            v-model="userInfo.password"
                                            type="password"
                                            :disabled="!isEditMode"
                                            :placeholder="$t('common.required')">
                                        </vue-input>
                                    </vue-form-item>
                                    <!--                        <vue-form-item :label="$t('user.socialAccount')">-->
                                    <!--                            <vue-input-->
                                    <!--                                v-model="userInfo.socialAccount"-->
                                    <!--                                :disabled="!isEditMode">-->
                                    <!--                            </vue-input>-->
                                    <!--                        </vue-form-item>-->
                                    <hr>
                                    <vue-form-item label="대여 자전거" >
                                        <pulse-loader style="float: left" color="#FFC0CB" :loading="!isBikesLoaded"/>
                                        <div v-if="isBikesLoaded">
                                            <div v-if="bikes.length === 0">
                                                <label>없음</label>
                                            </div>
                                            <div v-else v-for="bike in bikes">
                                                <a :href="`/admin/bike?id=${bike.id}`">{{bike.name}}</a>
                                                <div class="btn" v-on:click="onClickReturn(bike)">
                                                    반납
                                                </div>
                                            </div>
                                        </div>
                                    </vue-form-item>
                                    <vue-form-item label="블랙리스트(User.type)">
                                        <vue-radio v-model="isBlack"
                                                   @change="onBlackSelected"
                                                   :value=1 :disabled="!isEditMode">
                                            On
                                        </vue-radio>
                                        <vue-radio v-model="isBlack"
                                                   @change="onBlackSelected"
                                                   :value=0 :disabled="!isEditMode">
                                            Off
                                        </vue-radio>
                                    </vue-form-item>
                                    <vue-form-item label="포인트(Credit.CREDIT_POINT)">
                                        <label>{{point}}</label>
                                    </vue-form-item>
                                    <hr>
                                    <hash-tag-input v-model="userInfo.tags" v-bind:isReadOnly="!isEditMode"/>
                                </vue-form>
                            </div><!-- ./content-header -->
                        </div><!-- ./bottom -->
                    </div><!-- ./head-group -->
                    <div class="col-12">
                        <button class="btn btn-dark" @click="checkIfUserCanLeave">탈퇴가능여부확인하기</button>
                    </div>
                </div>
                <div class="col-6 h-100">
                    <div class="h-100 box" v-if="userInfo.id !== 0">
                        <tabs-view
                            :tab-menu="tabMenu"
                            :current-tab="currentTab"
                            v-on:onClick="handleClick"/>
                        <div class="flex-grow-1 content">
                            <div v-if="'orders' === currentTab">
                                <vue-good-table
                                    mode="remote"
                                    :columns="orderColumns"
                                    :rows="userOrderList"
                                    :total-rows="totalRows"
                                    @on-cell-click="onOrderRowClick"
                                    @on-page-change="onPageChange"
                                    @on-sort-change="onSortChange"
                                    @on-per-page-change="onPerPageChange"
                                    :pagination-options="paginationOption"
                                    :sort-options="{enabled: true}"/>
                            </div>
                            <div v-else-if="'coupons' === currentTab">
                                <div class="btns">
                                    <div class="add-btn" v-on:click="onAddCoupon()">
                                        {{$t('list.add')}}
                                        <span class="fas fa-plus"/>
                                    </div>
                                </div>
                                <vue-good-table
                                    mode="remote"
                                    :columns="couponColumns"
                                    :rows="coupons"
                                    :total-rows="totalRows"
                                    @on-page-change="onPageChange"
                                    @on-sort-change="onSortChange"
                                    @on-per-page-change="onPerPageChange"
                                    :pagination-options="paginationOption"
                                    :sort-options="{enabled: true}"/>
                            </div>
                            <div v-else-if="'points' === currentTab">
                                <div class="btns">
                                    <div class="add-btn" v-on:click="onAddPoint()">
                                        {{$t('list.add')}}
                                        <span class="fas fa-plus"/>
                                    </div>
                                </div>
                                <vue-good-table
                                    mode="remote"
                                    :columns="pointColumns"
                                    :rows="points"
                                    :total-rows="totalRows"
                                    @on-page-change="onPageChange"
                                    @on-sort-change="onSortChange"
                                    @on-per-page-change="onPerPageChange"
                                    :pagination-options="paginationOption"
                                    :sort-options="{enabled: true}"/>
                            </div>
                            <div v-else-if="'access_log' === currentTab">
                                <vue-good-table
                                    mode="remote"
                                    :columns="accessLogColumns"
                                    :rows="accessLogs"
                                    :total-rows="totalRows"
                                    @on-page-change="onPageChange"
                                    @on-sort-change="onSortChange"
                                    @on-per-page-change="onPerPageChange"
                                    :pagination-options="paginationOption"
                                    :sort-options="{enabled: true}"/>
                            </div>
                            <div v-else-if="'syslog' === currentTab">
                                <div class="container-fluid">
                                    <div class="row">
                                        <div class="col-12">
                                            <input type="date" v-model="logFilter.date"/>
                                            <button type="button" class="btn btn-primary" v-on:click="getUserSysLog">불러오기</button>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12">
                                            <gmap-map
                                                ref="gmap"
                                                :center="mapCenter"
                                                :zoom="mapZoom"
                                                :options="{
                                                    streetViewControl:false,
                                                    fullscreenControl:false,
                                                    mapTypeControl:false
                                                }"
                                                class="google-map"
                                                style="width: 100%; height: 250px;">
                                                <gmap-polyline v-bind:path.sync="routes" v-bind:options="{ strokeColor:'#008000'}">
                                                </gmap-polyline>
                                            </gmap-map>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12">
                                            <table class="table" v-if="orderHistory.length > 0">
                                                <tr>
                                                    <th>대상</th>
                                                    <th>취득경로</th>
                                                    <th>제공서비스</th>
                                                    <th>제공받는자</th>
                                                    <th>이용일시</th>
                                                    <th>자전거</th>
                                                </tr>
                                                <tr v-for="h in orderHistory" class="table-item" v-bind:class="{'table-selected-row': selectedRoute == h.id}" v-on:click="selectedLog(h)">
                                                    <td>{{userInfo.name}} {{userInfo.phoneNumber}} {{userInfo.email}}</td>
                                                    <td>{{getOs(h)}}</td>
                                                    <td>자전거 대여 서비스</td>
                                                    <td>3자 제공 없는 서비스</td>
                                                    <td>{{millisToReadable(h.startTime)}} - {{millisToReadable(h.toTime)}}</td>
                                                    <td>{{h.assetName}}</td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="fullscreen-background" style="z-index:2" v-if="orderLocation != false">
            <div class="form-container">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12">
                            <h4>자전거 대여 위치 경로</h4>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <gmap-map
                                ref="gmaph"
                                :center="mapCenter"
                                :zoom="mapZoom"
                                :options="{
                                                    streetViewControl:false,
                                                    fullscreenControl:false,
                                                    mapTypeControl:false
                                                }"
                                class="google-map"
                                style="width: 100%; height: 600px;">
                                <template  v-if="orderLocation.routes.length > 0">
                                <gmap-polyline v-bind:path.sync="orderLocation.routes" v-bind:options="{ strokeColor:'#FF0000'}">

                                </gmap-polyline>
                                </template>
                            </gmap-map>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <button type="button" class="form-control btn btn-secondary" v-on:click="orderLocation = false">닫기</button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div class="fullscreen-background" v-if="newPoint != false">
            <div class="form-container">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12">
                            <h4>포인트 발행</h4>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-4"><label class="form-control">포인트</label></div>
                        <div class="col-8"><input class="form-control" v-model="newPoint.credit"/></div>

                    </div>
                    <div class="row">
                        <div class="col-6">
                            <button type="button" class="form-control btn btn-secondary" v-on:click="closeNewPoint">취소</button>
                        </div>
                        <div class="col-6">
                            <button type="button" class="form-control btn btn-primary" v-on:click="submitNewPoint">완료</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="fullscreen-background" v-if="newCoupon != false">
            <div class="form-container">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12">
                            <h4>쿠폰발행</h4>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-4"><label class="form-control">제목</label></div>
                        <div class="col-8"><input class="form-control" v-model="newCoupon.title"/></div>

                    </div>
                    <div class="row">
                        <div class="col-4"><label class="form-control">내용</label></div>
                        <div class="col-8"><input class="form-control" v-model="newCoupon.description"/></div>
                    </div>
                    <div class="row">
                        <div class="col-4"><label class="form-control">사용시간 (시간: 분)</label></div>
                        <div class="col-2"><input type="number" class="form-control" v-model="newCoupon.timeH" placeholder="시간"/></div>
                        <div class="col-2"><label class="form-control text-left">시간</label></div>
                        <div class="col-2"><input type="number" class="form-control" v-model="newCoupon.timeM" placeholder="분"/></div>
                        <div class="col-2"><label class="form-control text-left">분</label></div>
                    </div>
                    <div class="row">
                        <div class="col-4"><label class="form-control">만료일</label></div>
                        <div class="col-8"><input type="date" class="form-control" v-model="newCoupon.validTime"/></div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <button type="button" class="form-control btn btn-secondary" v-on:click="cancelNewCoupon">취소</button>
                        </div>
                        <div class="col-6">
                            <button type="button" class="form-control btn btn-primary" v-on:click="submitCoupon">완료</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import EventBus from "../../../api/event-bus";

    require('../../../assets/css/common.css');

    import OperatorDropdownInput from "../../../inputs/OperatorDropdownInput";

    import {millisToReadable} from "../../../utils/TimeConverter";
    import {
        deleteUser,
        getCouponsByUserId,
        getUserPoint, ROLE_ADMIN, ROLE_GENERAL, ROLE_PARTNER,
        ROLE_SUPER_ADMIN,
        writeUser,
        getUserSysLogByTime,
        getUserLogs, canUserLeave
    } from "../../../api/user";
    import HashTagInput from "../../../inputs/HashTagInput";
    import CouponItem from "./CouponItem";
    import TabsView from "../../../views/TabsView";
    import {
        changeURL,
        getAPIURL,
        getCommonHeader,
        isOk,
        createItem,
        defaultPagination,
        showDialog
    } from "../../../api/common";
    import {createCredit} from "../../../api/credit";
    import TableEditor from "../../vuetable/TableEditor";
    import DropdownInput from "../../../inputs/DropdownInput";
    import {getOperatorMap, getOperators, setTagsByOperatorId} from "../../../api/operator";
    import {getOrderSysLog, getOrderTypeString, getUserOrder} from "../../../api/order";

    import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

    import {getBikesByUID} from "../../../api/asset";
    import {getOperatorId, getRoleId, isSuperAdmin} from "../../../store";
    import {millisToDate} from "../../../utils/TimeConverter";
    import {getUserPoints} from "../../../api/credit";

    export default {
        name: 'NormalUserEditor',
        components: {
            OperatorDropdownInput,
            DropdownInput,
            TableEditor,
            TabsView,
            CouponItem,
            HashTagInput,

            PulseLoader
        },
        props: {
            userInfo: {
                type: Object,
                default: function() { return{} }
            },
            isAddMode: {
                type: Boolean,
                default: true
            }
        },
        data(){
            return {
                isBlack: "black" === this.userInfo.type ? 1 : 0,

                coupons: [],
                point: 0,
                points:[],

                bikes: [],
                isBikesLoaded: false,

                roleName: "",
                roleList: [
                    {value: ROLE_SUPER_ADMIN, label:this.$t('user.superAdmin')},
                    {value: ROLE_ADMIN, label:this.$t('user.admin')},
                    {value: ROLE_PARTNER, label:this.$t('user.partner')},
                    {value: ROLE_GENERAL, label:this.$t('user.general')}
                ],

                tabMenu : [
                    {title: this.$t('user.orders'), value: 'orders'},
                    {title: this.$t('user.coupons'), value: 'coupons'},
                    {title: this.$t('user.points'), value: 'points'},
                    {title: this.$t('user.syslog'), value: 'syslog'},
                ],

                currentTab: 'orders',

                // headerInfo: {
                //     headers: getCommonHeader()
                // },

                orderColumns: [
                    {field: 'operatorId', label: this.$t('order.operator')},
                    {field: 'state', label: this.$t('order.state')},
                    {field: 'type', label: this.$t('order.type')},
                    {field: 'startTime', label: this.$t('order.start')},
                    {field: 'toTime', label: this.$t('order.end')},
                    {field: 'finalDue', label: this.$t('order.finalDue'), type: 'number'},
                    {field: 'paid', label: this.$t('order.paid'), type: 'number'},
                    {field: 'createdWhen', hidden: true, type: 'number'},
                    {field: 'location', label: '위치', html:true}
                ],
                couponColumns: [
                    {field: 'title', label: this.$t('coupon.title')},
                    {field: 'description', label: this.$t('coupon.description')},
                    {field: 'creditTime', label: this.$t('coupon.creditTime'), type: 'number'},
                    {field: 'validFromTime', label: this.$t('coupon.validFromTime'), type: 'number'},
                    {field: 'validToTime', label: this.$t('coupon.validToTime'), type: 'number'},
                    {field: 'usedTime', label: this.$t('coupon.usedTime'), type: 'number'},
                ],
                pointColumns: [
                    {field: 'createdWhen', label: '시간'},
                    {field: 'credit', label: '적립', type:'number'},
                    {field: 'used', label: '시용', type: 'number'},
                    {field: 'balance', label: '잔여포인트', type: 'number'},
                ],
                accessLogColumns: [
                    {field: 'createdWhen', label: '조회시간'},
                    {field: 'name', label:'조회자'},
                    {field: 'title', label:'조회사유'}
                ],
                pagination: defaultPagination(),
                // orderPagination: defaultPagination(),
                // pointPagination: defaultPagination(),
                // accessLogPagination: defaultPagination(),
                totalRows: 0,
                paginationOption: {
                    enabled: true,
                    perPage: 15,
                    perPageDropdown: [15, 25],
                    dropdownAllowAll: false
                },

                userOrderList: [],
                operatorMap: {},
                // isOrdersLoaded: false,

                isEditMode: this.isAddMode,


                newCoupon: false,
                newPoint: false,
                orderHistory:[],
                logFilter: {
                    from:'',
                    to:'',
                    date:'',
                },
                mapCenter: {
                    lat: 36.5, lng:127.8
                },
                mapZoom: 7,
                routes:[],
                selectedRoute:0,
                accessLogs:[],
                orderLocation: false,
            }
        },
        created: async function() {
            if(isSuperAdmin())
                this.tabMenu.push(
                    {title: this.$t('user.syslog_access'), value: 'access_log'}
                );

            this.setRoleName();

            this.operatorMap = await getOperatorMap();

            this.updateRentedBicycle();

            this.updateList();
        },
        // watch: {
        //     $route() {
        //         this.isEditMode = this.isAddMode;
        //         this.isBlack = "black" === this.userInfo.type ? 1 : 0;
        //
        //         this.setRoleName();
        //
        //         this.updateCouponList();
        //         this.updateOrderList();
        //     }
        // },
        methods: {
            setRoleName(){
                for(let role of this.roleList){
                    if(role.value === this.userInfo.role)
                        this.roleName = role.label;
                }
            },
            updateOrderList(){
                let that = this;
                getUserOrder(this.userInfo.id, this.pagination).then(data => {
                    that.userOrderList = [];

                    if(data.success) {
                        that.totalRows = data.totalElements;

                        for (let e of data.list) {
                            // if (check(e)) {
                                let operatorName = this.operatorMap[e.operatorId];

                                e.operatorId = operatorName === undefined ? "NULL" : operatorName;
                                e.state = this.$t('order.stateStep')[e.state];
                                e.from = e.startTime;
                                e.to = e.toTime;
                                e.startTime = millisToReadable(e.startTime);

                                e.toTime = millisToReadable(e.toTime);
                                e.type = getOrderTypeString(e.type);

                                e.location = '<span><i class="fas fa-map-marker-alt"></i></span>';
                                this.userOrderList.push(e);
                            // }
                        }
                    }
                })
            },
            // transform: function(data){
            //     let transformedList = [];
            //
            //     data.list.forEach(e => {
            //         if(e.state >= 1 && 0 === e.parentId) {
            //             let operatorName = this.operatorMap[e.operatorId];
            //
            //             e.operatorId = operatorName === undefined ? "NULL" : operatorName;
            //             e.state = this.$t('order.stateStep')[e.state];
            //             e.startTime = millisToReadable(e.startTime);
            //             e.toTime = millisToReadable(e.toTime);
            //             e.type = getOrderTypeString(e.type);
            //             transformedList.push(e);
            //         }
            //     });
            //
            //     transformedList.sort(function(a,b) {
            //         return b.lastUpdatedWhen - a.lastUpdatedWhen
            //     });
            //
            //     return {data: transformedList}
            // },
            setOperatorId: function(id) {
                setTagsByOperatorId(id, this.userInfo);
            },
            onClickCloseBtn: function() {
                this.$emit('onClose', null);

                // if(this.isAddMode || !this.isEditMode) {
                //     this.$router.push('/admin/normal');
                // } else {
                //     this.$router.push('/admin/normal?id='+this.$route.query.id);
                // }
            },
            onClickSaveBtn: async function() {
                let that = this;
                if(null == this.userInfo.phoneNumber || "" === this.userInfo.phoneNumber){
                    // TODO: 중복체크도 해야함.
                    // that.showModal("ERROR", "전화번호 입력은 필수 입니다.")
                    alert("전화번호 입력은 필수 입니다.");

                    return;
                }

                if(this.userInfo.role === ROLE_GENERAL) {
                    this.userInfo.operatorId = 0;
                }
                if(this.userInfo.role > ROLE_GENERAL && this.userInfo.operatorId === 0) {
                    alert("관리자 설정을 위해서 사업자를 선택해주세요");
                    return;
                }

                writeUser(that.userInfo).then(function (user) {
                    alert("저장 되었습니다.");

                    that.isEditMode = false;

                    that.$emit("updateList");

                    changeURL(`normal?id=${user.id}`, that.$route, that.$router);
                });
            },
            onClickEditBtn: function() {
                if( this.userInfo.role > getRoleId() || ! isOk(this.userInfo) ){
                    alert("수정 권한이 없습니다.");

                    return;
                }

                this.isEditMode = true;
            },
            onClickRemoveBtn: function() {
                let self = this;
                this.$modal.show('dialog', {
                    title: '',
                    text: "영역 " + this.userInfo.name + ' ' + this.userInfo.phoneNumber + '를 삭제 하시겠습니까?',
                    buttons:[
                        {
                            title: '아니요',
                            handler:() => {
                                self.$modal.hide('dialog');
                            }
                        },
                        {
                            title:'삭제',
                            handler:()=>{
                                self.$modal.hide('dialog');
                                self.onClickRemoveBtnConfirmed();
                            }
                        }
                    ]
                })
            },
            onClickRemoveBtnConfirmed: function() {
                let that = this;

                deleteUser(that.userInfo.id).then(function() {
                    alert("삭제 되었습니다.");

                    that.$emit("updateList")

                    that.$emit('onClose', null);

                    // that.$router.push('/admin/normal');
                });
            },
            onAddCoupon: function() {
                this.prepareNewCoupon()
                // return;
                // let path = `/admin/coupon?userId=${this.userInfo.id}`;
                //
                // if(this.$route.fullPath !== path) {
                //     this.$router.push(path);
                // }
                // this.couponItem = newCredit();
                // this.couponItem.userId = this.userInfo.id;
                // this.couponItem.operatorId = this.userInfo.operatorId;
            },
            closeNewPoint: function() {
                this.$data.newPoint = false;
            },
            onAddPoint: function() {
                this.$data.newPoint = {
                    id:0,
                    credit:0,
                    userId:this.userInfo.id,
                    type:'POINT',

                }
            },
            submitNewPoint: function() {

                console.log("submitNewPoint");
                console.log(this.$data.newPoint);
                if(this.$data.newPoint.credit == '' || this.$data.newPoint.credit == 0) {
                    console.log("Please enter point")
                    return;
                }
                let self = this;
                createCredit(this.$data.newPoint)
                .then(function(data) {
                    if(data.success == true) {
                        self.$data.newPoint = false;
                        self.updatePoints();
                        getUserPoint(self.userInfo.id).then(point => {
                            self.point = point;
                        });
                    } else {
                        console.log("Failed to create point " + data.message);
                    }
                })
                .catch(function(err) {
                    console.log("Error creating point " + err);
                    if(err.response != undefined) {
                        console.log(err.response);
                    }
                })
            },
            // onEditCoupon: function(item) {
            //     let path = `/admin/coupon`;
            //     if(item) {
            //         path += `?id=${item.id}`;
            //     }
            //
            //     if(this.$route.fullPath !== path) {
            //         this.$router.push(path);
            //     }
            // },

            updateRentedBicycle(){
                this.isBikesLoaded = false;
                let that = this;
                getBikesByUID(this.userInfo.id).then(list => {
                    that.bikes = [];

                    for(let bike of list){
                        if(bike.operatorId !== getOperatorId())
                            that.bikes.push(bike);
                    }

                    that.isBikesLoaded = true;
                });
            },
            updateCouponList: function(){
                let that = this;
                getCouponsByUserId(this.userInfo.id, this.pagination).then(data => {
                    that.coupons = [];

                    if(data.success) {
                        for (let e of data.list) {
                            console.log(e.id);

                            if (e.validFromTime > 0) e.validFromTime = millisToReadable(e.validFromTime);
                            if (e.validToTime > 0) e.validToTime = millisToReadable(e.validToTime);
                            e.creditTime = e.creditTime / 60000 + '분';
                            if (e.usedTime > 0) e.usedTime = millisToReadable(e.usedTime);

                            that.coupons.push(e);
                        }
                    }
                });
            },
            onPageChange(params) {
                this.pagination.page = params.currentPage - 1;

                this.updateList();
            },

            onPerPageChange(params) {
                this.pagination.page = 0;
                this.pagination.size = params.currentPerPage;

                this.updateList();
            },
            onSortChange(params) {
                this.pagination.page = 0;
                this.pagination.sort = `${params[0].field},${params[0].type}`;

                this.updateList();
            },
            updatePoints: function() {
                let self = this;
                this.$data.points = [];
                EventBus.$emit("showLoading", true, "가져오는중");
                getUserPoints(this.userInfo.id, this.pagination)
                .then(function(data) {
                    EventBus.$emit("showLoading", false);
                    if(data.success == true) {
                        for(let i = 0 ; i < data.list.length ; i++) {
                            data.list[i].createdWhen = millisToReadable(data.list[i].createdWhen);
                        }
                        self.$data.points = data.list;

                    } else {

                    }
                })
                .catch(function(err) {
                    EventBus.$emit("showLoading", false);
                })
            },
            onOrderRowClick: function(param) {
                console.log(param);
                if(param.column !== undefined && param.column.field !== undefined && param.column.field === 'location' ) {
                    // let from = this.$data.userOrderList[param.rowIndex].from;
                    // let to = this.$data.userOrderList[param.rowIndex].to;


                    this.$data.orderLocation = {
                        item: this.$data.userOrderList[param.rowIndex],
                        routes:[],
                    }


                    let self = this;
                    getOrderSysLog(this.$data.userOrderList[param.rowIndex].id)
                    .then(function(data) {
                        console.log("log history");
                        console.log(data);
                        if(data.success === true) {
                            self.setOrderLocationDate(data.item);
                        }
                    })
                    .catch(function(reason) {

                        console.log(reason);
                    })
                }
            },
            setOrderLocationDate: function(items) {
                for(let i = 0 ; i < items.length ; i++) {
                    items[i].lng = items[i].lon;
                }
                this.$refs.gmaph.$mapPromise.then((map) => {
                    const bounds = new google.maps.LatLngBounds()
                    for (let m of items) {
                        bounds.extend(m)
                    }
                    map.fitBounds(bounds);
                });
                this.$data.orderLocation.routes = items;
                console.log(this.$data.orderLocation);
            },
            getUserSysLog: function() {
                console.log("getUserSysLog");
                if(this.$data.logFilter.date == '' || this.$data.logFilter.date == '0000-00-00') {
                    return;
                }

                let reason = window.prompt("사용자 위치 기록 열람 이유를 적어주세요");
                console.log(reason);

                if(reason == null) {
                    return;
                }
                if(reason === '') {
                    window.alert("이유를 입력해야 기록을 볼 수 있습니다");
                    return;
                }


                let selectedDay = new Date(this.$data.logFilter.date);
                selectedDay.setHours(0);
                selectedDay.setMinutes(0);
                selectedDay.setSeconds(0);
                let from = selectedDay.getTime();

                selectedDay.setHours(23);
                selectedDay.setMinutes(59);
                selectedDay.setSeconds(59);
                let to = selectedDay.getTime();

                let self = this;
                self.$data.orderHistory = [];
                self.$data.routes = [];
                self.$data.selectedRoute = 0;
                getUserSysLogByTime(this.userInfo.id, from, to, reason)
                .then(function(data) {
                    console.log("log history");
                    console.log(data);
                    if(data.success === true) {

                        self.$data.orderHistory = data.list;
                    }
                })
                .catch(function(reason) {

                })

            },
            getOs: function(item) {
                if(item.logs != null && item.logs != undefined && item.logs.length > 0) {
                    console.log(item.logs);
                    if(item.logs[0].source.length > 0) {
                        return item.logs[0].source;
                    } else {
                        return '취득경로 없음';
                    }

                }
                return '위치정보 없음';
            },
            selectedLog: function(item) {
                this.$data.routes = [];
                this.$data.selectedRoute = item.id;
                if(item.logs != null && item.logs != undefined && item.logs.length > 0) {
                    for(let i = 0 ; i < item.logs.length ; i++) {
                        item.logs[i].lng = item.logs[i].lon;
                    }
                    this.$data.routes = item.logs;
                    let self = this;
                    this.$refs.gmap.$mapPromise.then((map) => {
                        const bounds = new google.maps.LatLngBounds()
                        for (let m of self.$data.routes) {
                            bounds.extend(m)
                        }
                        map.fitBounds(bounds);
                    });
                }
            },
            onAccessLogPageChanged(params) {
                this.accessLogPagination.page = params.currentPage;
                this.getUserLogAccess();
            },
            getUserLogAccess: function() {
                let self = this;
                getUserLogs(this.userInfo.id, this.pagination.page)
                .then(function(data) {
                    console.log(data);
                    if(data.success == true) {
                        for(let i = 0 ; i < data.list.length ; i++) {
                            data.list[i].createdWhen = millisToReadable(data.list[i].createdWhen);
                        }
                        self.$data.accessLogs = data.list;
                    } else {
                        window.alert(data.message);
                    }
                })
                .catch(function(err) {
                    console.log(err);

                })
            },
            updateList(){
                let that = this;
                getUserPoint(this.userInfo.id).then(point => {
                    that.point = point;
                });

                switch (this.currentTab) {
                    case "orders":
                        this.updateOrderList();

                        return;
                    case "coupons":
                        this.updateCouponList();

                        return;
                    case "points":
                        this.updatePoints();

                        return;
                    case 'syslog':
                        this.$data.orderHistory = [];

                        return;
                    case 'access_log':
                        this.$data.accessLogs = [];
                        this.getUserLogAccess();

                        return;
                }
            },
            handleClick: function(selectedTab = "orders") {
                this.totalRows = 0;
                this.pagination = defaultPagination();

                if(this.currentTab === selectedTab) {
                    return;
                }

                this.currentTab = selectedTab;

                this.updateList();
            },
            // onLogDateSelected: function() {
            //
            // },
            // onLoading() {
            //     this.isOrdersLoaded=false;
            //     // console.log("onLoading")
            // },
            // onLoaded() {
            //     this.isOrdersLoaded=true;
            //     // console.log("onLoaded")
            // },
            onClickReturn(bike){

                console.log(bike.id)
            },
            onBlackSelected(value){
                this.userInfo.type = 1 === value ? "black" : "";
            },

            isSuperAdmin: isSuperAdmin,
            millisToReadable: millisToReadable,

            prepareNewCoupon: function() {
                this.$data.newCoupon = {
                    id:0,
                    type:'coupon',
                    userId: this.userInfo.id,
                    timeH:0,
                    timeM:0,
                    title:'',
                    description:'',
                    validTime:millisToDate(new Date().getTime(), '-'),
                }
                console.log(this.userInfo);
            },

            cancelNewCoupon: function() {
                this.$data.newCoupon = false;
            },
            submitCoupon: function() {
                if(this.$data.newCoupon.timeH == '') {
                    this.$data.newCoupon.timeH = 0;
                }
                if(this.$data.newCoupon.timeM == '') {
                    this.$data.newCoupon.timeM = 0;
                }
                let creditTime = this.$data.newCoupon.timeH * (60000*60) + this.$data.newCoupon.timeM * 60000;
                if(creditTime <= 0) {
                    console.log("Enter time");
                    return;
                }
                if(this.$data.newCoupon.title.length == 0) {
                    console.log("No title");
                    return;
                }
                if(this.$data.newCoupon.description.length == 0) {
                    console.log("No Description");
                    return;
                }
                if(this.$data.newCoupon.validTime == '' || this.$data.newCoupon.validTime == '0000-00-00') {
                    console.log("No expiry");
                    return;
                }
                let timeTo = new Date(this.$data.newCoupon.validTime);
                timeTo.setHours(23);
                timeTo.setMinutes(59);
                timeTo.setSeconds(59);
                timeTo = timeTo.getTime();

                let today = new Date().getTime();
                if(timeTo <= today) {
                    console.log("Expiry wrong");
                    return;
                }

                this.$data.newCoupon.creditTime = creditTime;
                this.$data.newCoupon.validToTime = timeTo;

                let newItem = {
                    id:0,
                    userId: this.$data.newCoupon.userId,
                    type:'COUPON',
                    creditTime:creditTime,
                    validToTime:timeTo,
                    title:this.$data.newCoupon.title,
                    description:this.$data.newCoupon.description,

                }
                console.log(newItem);
                this.$data.newCoupon = false;

                let self = this;

                createCredit(newItem)
                .then(function(data) {
                    if(data.success == true) {
                        let item = data.item;
                        item.validFromTime = millisToReadable(item.validFromTime);
                        if (item.validToTime > 0) item.validToTime = millisToReadable(item.validToTime);
                        if (item.creditTime > 0) item.creditTime = e.creditTime / 60000 + '분';
                        if (item.usedTime > 0) item.usedTime = millisToReadable(item.usedTime);
                        self.$data.newCoupon = false;
                        self.$data.coupons.unshift(item);
                    }
                })
                .catch(function(err) {

                })
            },
            checkIfUserCanLeave: function() {

                console.log("check if user can leave " + this.userInfo.phoneNumber);
                canUserLeave(this.userInfo.id)
                    .then(function(data) {
                        console.log(data);
                        if(data.success === true) {
                            showDialog(data.message);
                        } else {
                            showDialog(data.message);
                        }
                    })
                    .catch(function(err) {
                        console.log(err);
                        let msg = err;
                        if(err.response !== undefined) {
                            console.log(err.response);
                            msg += ' (' + err.response + ')';
                        }
                        showDialog(msg, "ERROR");
                    })
            }
        },
    };
</script>

<style lang="scss">
    .btns {
        text-align: right;

        .add-btn {
            font-size: 16px;
            display: inline-block;
            margin-left: 5px;
            color: #999;
            cursor: pointer;
        }
        .add-btn:hover {
            color: #333;
        }
    }
    .content {
        margin-top: 30px;
        font-size: 20px;
    }
    .loading {
        display: none;
    }
    th {
        font-size: 15px;
    }
    td {
        font-size: 13px;
    }
    .flex-grow-1  {

        overflow-y: scroll;
    }
</style>
