<template>
    <div style="width: 100%; height:100%; position: relative;">
        <gmap-map
            ref="gmapPoints"
            :center="mapCenter"
            :zoom="mapZoom"
            :options="{
                        streetViewControl:false,
                        fullscreenControl:false,
                        mapTypeControl:false
                    }"

            class="google-map"
            style="width: 100%; height: 100%;">
            <gmap-cluster>
            <gmap-marker

                v-for="(m, index) in markers"

                :key="m.id"
                :position="m"
                :icon="{
                            url: require('../../assets/images/' + getMarkerUrl(m)),
                            scaledSize: {width:30, height:30},
                            // labelOrigin:m.position,
                            }"


            >
            </gmap-marker>
            </gmap-cluster>

        </gmap-map>
        <div style="position: absolute; top: 10px; left: 10px; display:flex; background-color: white;padding:10px;">
            <date-picker v-model="dateRange" valueType="format" @change="dateRangeSelected" range></date-picker>
            <select v-model="selectedOperatorId" v-on:change="onOperatorChanged">
                <option v-for="o in operators" :value="o.value">{{o.name}}</option>
            </select>
            <select v-model="type" >
                <option v-for="t in typeList" :value="t.val">{{t.name}}</option>
            </select>
            <button class="btn btn-success" @click="getList">가져오기</button>
        </div>
    </div>
</template>

<script>
    import {getOperatorId} from "../../store";
    import {getOperatorList} from "../../api/operator";
    import {getLocationData} from "../../api/locations";
    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    import GmapCluster from 'vue2-google-maps/dist/components/cluster';
    import {showDialog} from "../../api/common";

    export default {
        name: "LocationDataView",
        data() {
            return {
                mapCenter: {
                    lat: 36.5, lng:127.8
                },
                mapZoom: 7,
                operators:[],
                markers:[],
                type:'hire_start',
                selectedOperatorId: getOperatorId(),
                typeList: [
                    {
                        name:"대여시작",
                        val: 'hire_start',
                    },
                    {
                        name:"대여반납",
                        val: 'hire_return',
                    },
                ],
                dateRange: null,
            }
        },
        components: {
            GmapCluster,
            DatePicker,

        },
        created() {

        },
        mounted() {
            this.initialise();
        },
        beforeDestroy() {
        },
        destroyed() {
        },
        methods: {
            getMarkerUrl(item) {
                if(item.type === 'hire_start') {
                    return 'green-circle.svg';
                } else {
                    return 'red-circle.svg';
                }
            },
            async initialise() {
                this.operators = await getOperatorList();
                console.log(this.operators);
                if(this.selectedOperatorId != 0) {
                    for (let i = 0; i < this.$data.operators.length; i++) {
                        if (this.selectedOperatorId == this.$data.operators[i].value) {
                            this.mapCenter.lat = this.$data.operators[i].lat;
                            this.mapCenter.lng = this.$data.operators[i].lng;

                            this.$refs.gmapPoints.$mapPromise.then((map) => {
                                map.setZoom(17);
                            });
                        }
                    }
                }
            },
            onOperatorChanged: function() {
                if (this.selectedOperatorId != 0) {
                    for (let i = 0; i < this.$data.operators.length; i++) {
                        if (this.selectedOperatorId == this.$data.operators[i].value) {
                            this.mapCenter.lat = this.$data.operators[i].lat;
                            this.mapCenter.lng = this.$data.operators[i].lng;
                            console.log("Map Center");
                            console.log(this.mapCenter);
                            this.$refs.gmapPoints.$mapPromise.then((map) => {
                                map.setZoom(15);
                            });
                        }
                    }
                }
            },
            dateRangeSelected: function() {
                console.log("dateRangeSelected")
                console.log(this.$data.dateRange)

                if(this.$data.dateRange.length !== 2) {
                    return;
                }

                if(this.$data.dateRange[0] > this.$data.dateRange[1]) {
                    let temp = this.$data.dateRange[1];
                    this.$data.dateRange[0] = this.$data.dateRange[1];
                    this.$data.dateRange[1] = temp;
                }

            },
            getList: function() {
                if(this.$data.dateRange === null) {
                    return;
                }
                this.$data.markers = [];
                let params = this.selectedOperatorId + '/' + this.$data.type + '/';

                let from = new Date(this.$data.dateRange[0]);
                from.setHours(0,0,0,0);
                let to = new Date(this.$data.dateRange[1]);
                to.setHours(23,59,59,999);

                params += from.getTime() + '/' + to.getTime();

                let self = this;
                getLocationData(params)
                .then(function(data) {
                    if(data.success === true) {
                        self.$data.markers = data.list;
                    } else {
                        showDialog(data.message);
                    }
                })
                .catch(function(err){

                })

            }
        }
    }
</script>

<style scoped>

</style>
