<template>
  <nav class="navbar navbar-default navbar-fixed-top" style="padding:0; z-index:11; height: 50px;">
    <div class="container-fluid" style="height: 50px;">

      <div class="navbar-header">
        <a class="navbar-brand">
          <Logo />
        </a>

          <span v-if="currentPath != null && currentPath.length > 0">
              {{currentPath}}
          </span>
      </div>

<!--      <div class="navbar-header navbar-right">-->
<!--        <UserState />-->
<!--      </div>-->

    </div>
      <v-dialog/>
      <transition name="dissolve">
          <div class="progressView " v-if="showProgressLoading==true">
              <div class="center-container">
                  <div class="content-center">
                      <img src="https://storage.googleapis.com/ncm-mary-254508.appspot.com/ncm-res/mary-logo.png" class="rotation-anim"/>
                      <p class="loading-message"> {{progressMessage}}</p>
                  </div>

              </div>


          </div>
      </transition>

      <div style="position: fixed; top: 0; left: 0; width: 100%; height: 100%; background-color: rgba(0,0,0,0.4);" v-if="sec >= 0">
          <div style="position: absolute; top: 50%; left: 50%; width: 320px; background-color: white; line-height: 30px; transform: translate(-50%, -50%); text-align: center;">
              자동 로그아웃
              <br/>
              {{sec}}초 뒤에 자동 로그인 됩니다.

              <div class="container">
                  <div class="row">
                      <div class="col-6">
                          <button class="btn form-control" @click="startSession">로그인 유지</button>
                      </div>
                      <div class="col-6">
                          <button class="btn form-control" @click="onSecondTimeOut(0)">로그아웃</button>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </nav>
</template>

<script>

    import EventBus from '../api/event-bus.js';
    import Logo from '@/views/Logo';
    import UserState from '@/components/UserState';
    import Router from "@/router";

    export default {
        name: 'Header',
        components: {
            Logo,
            UserState,
        },
        data() {
            return {
                showProgressLoading: false,
                progressMessage: '로딩중',
                currentPath:'',
                timer:null,
                secondTimer: null,
                sec:-1,
            }
        },
        created() {
            EventBus.$on('showLoading', this.showLoading)
            EventBus.$on('showDialog', this.showDialog);
            EventBus.$on('setTitlePath', this.setTitlePath);
            EventBus.$on('startSession', this.startSession);
            EventBus.$on('endSession', this.clearTimers);
        },
        mounted() {

        },
        beforeDestroy() {
            if(this.$data.timer !== null) {
                clearTimeout(this.$data.timer);
            }
            if(this.$data.secondTimer !== null) {
                clearTimeout(this.$data.secondTimer);
            }
        },
        methods: {
            showLoading: function(show, message = '') {
                this.$data.progressMessage = message;
                this.$data.showProgressLoading = show;

            },
            showDialog: function(params) {
                console.log("show dialog");
                this.$modal.show('dialog', params);
            },
            setTitlePath: function(title) {
                this.$data.currentPath = title;
            },
            clearTimers: function() {
                if(this.$data.timer !== null) {
                    clearTimeout(this.$data.timer);
                }
                if(this.$data.secondTimer !== null) {
                    clearTimeout(this.$data.secondTimer);
                }
            },
            startSession: function() {
                this.clearTimers();

                this.$data.sec = -1;
                let self = this;
                this.$data.timer = setTimeout(function(){self.onTimedOut(15);}, 60000*15);
            },
            onTimedOut: function(sec) {
                if(this.$route.path === '/admin/pos' || this.$route.path === '/admin/shops' || this.$route.path === '/admin/placeItem'
                    || this.$route.path === '/admin/place' || this.$route.path === '/admin/booking') {
                    this.startSession();
                    return;
                }
                console.log("Current path " + this.$route.path);
                this.$data.sec = sec;
                this.clearTimers();

                let self = this;
                this.$data.secondTimer = setTimeout(function(){self.onSecondTimeOut(sec);}, 1000);
            },
            onSecondTimeOut: function(sec) {
                if(sec > 1) {
                    this.onTimedOut(sec-1);
                    return;
                }
                this.$store.dispatch('logout');
                this.$router.push('/admin');
            }
        }
    };
</script>

<style scoped>
  .container-fluid {
    background-color: #FFF;
    box-shadow: 0 4px 12px -8px;
  }
  .progressView {
      height: 100%;
      width: 100%;
      position: fixed;
      top: 0;
      left: 0;
      background-color: rgba(0,0,0,0.7);
      /*z-index:1040;*/
  }
  .center-container {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
  }
  .rotation-anim {
      animation-name: spin;
      animation-duration: 1000ms;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
  }
  @keyframes spin {
      from {
          transform:rotate(0deg);
      }
      to {
          transform:rotate(360deg);
      }
  }
  .loading-message {
      color:white;
      margin-top:20px;
  }
  .dissolve-enter-active,
  .dissolve-leave-active {
      transition: all 0.1s;
  }

  .dissolve-enter {
      background-color: rgba(0,0,0,0);
      /*transform: translate(100%, 0);*/
      /*-webkit-transform: translate(100%, 0);*/

  }
  .dissolve-enter-to {
      background-color: rgba(0,0,0,0.7);
  }
  .dissolve-leave-to {
      background-color: rgba(0,0,0,0);
  }

  .dissolve-enter-active,
  .dissolve-leave-active {
      transition: all 0.1s;
  }
</style>
