import {cleanLocalStorage} from "../store/auth.module";
import {errorHandler, getCommonHeader} from "./common";

const axios = require('axios');
const model = "setting";


export function getSettings(operatorId = 0) {
    let address = `${process.env.VUE_APP_API_BASE_URL}admin/${model}s`;

    address += '?operatorId=' + operatorId;
    // console.log("getAssets " + address);
    return new Promise(function(resolve, reject) {
        axios({
            method: 'GET',
            url: address,
            headers: getCommonHeader()
        }).then(function(response){
            // console.log('get assets ' + type + ' response');
            // console.log(response);
            resolve(response.data);

        }).catch(error => {
            errorHandler(error);
            reject(error);
            console.log(error.response);
        })
    })
}
export function updateSettings(items) {
    let address = process.env.VUE_APP_API_BASE_URL + 'admin/settings';

    return new Promise(function(resolve, reject) {
        axios({
            method: 'post',
            url: address,
            data: JSON.stringify(items),
            headers: getCommonHeader()
        }).then(function(response){
            let data = response.data;

            resolve(data);
        }).catch(error => {
            errorHandler(error);
            console.log(error.response);
        })
    })
}
export function updateSetting(item) {
    let address = process.env.VUE_APP_API_BASE_URL + 'admin/setting';

    return new Promise(function(resolve, reject) {
        axios({
            method: 'post',
            url: address,
            data: JSON.stringify(item),
            headers: getCommonHeader()
        }).then(function(response){
            let data = response.data;

            resolve(data);
        }).catch(error => {
            errorHandler(error);
            console.log(error.response);
        })
    })
}

export function getSettingBones(operatorId = 0) {
    let list = [
        {
            id:0,
            type:'syslog_min_time',
            title:'Sys Log 최소 시간',
            valueInt: 5,
            valueType:'int',
            description:'최근 몇분간 sys log가 없으면 경고',
            operatorId:operatorId,
        },
        {
            id:0,
            type:'bike_alarm_count',
            title:'자전거 System Log Count',
            valueInt: 5,
            valueType:'int',
            description:'경고를 알리기 위한 자전거 시스템 log 횟수',
            operatorId:operatorId,
        },
        {
            id:0,
            type:'bike_alarm_interval',
            title:'자전거 System Log Count 시간',
            valueInt: 1,
            valueType:'int',
            description:'자전거 경고 메세지 횟수 올라오는 시간',
            operatorId:operatorId,
        },
        {
            id:0,
            type:'min_syslog_to_hire',
            title:'대여가능한 마지막 시스로그 최대시간',
            valueInt: 20,
            valueType:'int',
            description:'자전거의 마지막 syslog시간이 정해진 분 이전이면 대여불가',
            operatorId:operatorId,
        },
        {
            id:0,
            type:'out_of_service_area_alarm',
            title:'서비스 지역 이탈 경고음 간격',
            valueInt: 1,
            valueType:'int',
            description:'서비스 지역 이탈할 경우 몇분 간격으로 벨을 울릴지',
            operatorId:operatorId,
        },
        {
            id:0,
            type:'message_before_hire',
            title:'자전거 대여 진행 전 확인 메세지',
            valueInt: 1,
            valueType:'text',
            valueText:'',
            description:'',
            operatorId:operatorId,
        },
        {
            id:0,
            type:'coupon_gift_confirmation_message',
            title:'선물보내기전 숙지 사항',
            valueInt: 1,
            valueType:'text',
            valueText:'',
            description:'쿠폰 선물보내기 확정전 사용자가 확인해야할 메세지',
            operatorId:operatorId,
        },
        {
            id:0,
            type:'user_cancel_notification_emails',
            title:'회원탈퇴시 알림 받을 이메일',
            valueInt: 1,
            valueType:'plaintext',
            valueText:'',
            description:'회원 탈퇴시 알림 이메일이 전송됩니다. 여러개의 이메일인 경우 콤마 (,)로 구분하시고 space는 없어야 합니다.',
            operatorId:operatorId,
        },


    ]

    return list;
}
