<template>
    <div class="list-detail-view container-fluid h-100">
        <div class="row h-100">
            <div class="list-wrapper col-xs-12 col-sm-12 col-md-12 full-screen-list h-100"
                 v-bind:class="{'on-detail-view': onDetail }">
                <div class="container-fluid h-100">
                    <div class="row h-100">
                        <div class="col-6 h-100">
                            <slot name="map-container"/>
                        </div>
                        <div class="col-6 h-100" style="overflow-y: scroll">
                            <div class="list-header">
                                <div class="list-title">
                                    <slot name="list-title-text" />
                                </div>
                                <slot name="top-items"/>
                            </div>
                            <div class="list-items-wrapper">
                                <slot name="list-items"/>
                            </div>
                            <div class="show-more-btn-wrapper" v-if="isAvailableMoreBtn">
                                <div class="show-more-btn" v-on:click="onClickShowMore()">
                                    {{$t('list.show_more')}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

            <transition name="slide">
                <div v-if="onDetail" class="detail-wrapper col-xs-12 col-sm-12 col-md-12 h-100 full-screen-detail" style="overflow-y: scroll;">
<!--                    <div class="detail-container">-->
                        <slot name="detail-item" />
<!--                    </div>-->
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
    import TabsView from "./TabsView";
    export default {
        name: 'WideDetailLayout',
        components: {TabsView},
        props: {
            isAvailableMoreBtn: {
                type: Boolean,
                default: false,
            },
            // tabMenu: {
            //     type: Array,
            //     default: null
            // },
            onDetail: {
                type: Boolean,
                default: false
            },
            isDetail: {
                type: Boolean,
                default: true
            },
            fullScreen: {
                type: Boolean,
                default: false
            }
        },
        // data() {
        //     return {
        //         isOnDetailView: this.onDetail,
        //     };
        // },
        methods: {
            // updateIsOnDetailView: function() {
            //     this.isOnDetailView = this.$route.query.hasOwnProperty('id') ||
            //         this.$route.query.hasOwnProperty('new') ||
            //         this.$route.query.hasOwnProperty('fileName');
            // },
            onClickShowMore: function() {
                this.$emit('onRequestMoreList');
            },
        },
    };
</script>

<style lang="scss">
    .show-more-btn-wrapper {
        margin-top: 40px;
    }

    .show-more-btn {
        padding: 10px;
        text-align: center;
        font-size: 16px;
        font-weight: 300;
        background-color: #FFF;
        border: 1px solid #EEE;
        -webkit-box-shadow: 2px 3px 4px -1px rgba(0, 0, 0, 0.3);
        box-shadow: 2px 3px 4px -1px rgba(0, 0, 0, 0.3);
        cursor: pointer;
    }
    .show-more-btn:hover {
        background-color: #2b90d9;
        border-color: #1982ce;
        color: #fefefe;
    }

    .list-detail-view {
        position: relative;
        width: 100%;
        height: 100%;
        background-color: #f9f9f9;
        overflow: hidden;

        .list-wrapper,
        .detail-wrapper {
            height: 100%;
            padding-top: 20px;
            padding-bottom: 20px;
            overflow-x: hidden;
            overflow-y: scroll;
        }

        .list-wrapper {
            border-right: 1px solid #CCC;

            .list-header {

                .list-title {
                    font-size: 32px;
                    font-weight: 500;
                }

                .line {
                    width: 100%;
                    height: 1px;
                    background-color: #DDD;
                    margin: 20px auto;
                }

                .list-top-btns {
                    text-align: right;

                    .add-item {
                        display: inline-table;
                        border: 1px solid #DDD;
                        border-radius: 3px;
                        padding: 5px 8px;
                        background: #fff;
                        cursor: pointer;
                    }
                    .add-item:hover {
                        color: #333;
                        background-color: #e6e6e6;
                        border-color: #adadad;
                    }

                    .sort-btn-wrapper {
                        display: inline-block;
                        float: left;
                        text-align: left;
                        margin-bottom: 20px;
                    }
                }
            }

            .list-items-wrapper {
                .empty-list {
                    text-align: center;
                    font-weight: 300;
                    font-size: 21px;
                    color: #CCC;
                    margin-top: 60px;
                }
            }
        }

        .detail-view {
            padding: 20px;
            background-color: #FFF;
            border: 1px solid #EEE;
            border-radius: 5px;
            -webkit-box-shadow: 2px 3px 4px -1px rgba(0,0,0,.3);
            box-shadow: 2px 3px 4px -1px rgba(0,0,0,.3);

            .btns {
                text-align: right;

                .naim-btn {
                    display: inline-block;
                    margin-left: 5px;
                    color: #999;
                    cursor: pointer;
                }
                .naim-btn:hover {
                    color: #333;
                }
            }

            .head-group {

                .top {
                    display: table;
                    width: 100%;
                    font-size: 12px;
                    margin-bottom: 30px;

                    .access-rights {
                        margin-top: 5px;

                        .read-levels {
                            border-right: 1px solid #ddd;
                            padding-right: 5px;
                            margin-right: 5px;
                        }

                        .read-levels,
                        .write-levels,
                        .read-levels > *,
                        .write-levels > * {
                            display: inline-block;
                        }
                        .access-label {
                            margin-right: 5px;
                        }
                    }

                    .access-dropdown {
                        display: inline-block;
                        margin-right: 10px;
                    }

                }

                .bottom {
                    /*display: table;*/
                    width: 100%;

                    .content-header {
                        /*display: table-cell;*/
                        /*width: 50%;*/
                        padding-left: 10px;
                        vertical-align: middle;
                    }
                }

            }

        }

        .detail-view.rendered {
            .input {
                display: table;
                width: 100%;
                margin-bottom: 10px;

                label {
                    display: table-cell;
                    vertical-align: middle;
                    color: #333;
                }
                input, textarea {
                    display: table-cell;
                    width: 100%;
                    vertical-align: middle;
                    padding: 3px 5px;
                }
            }

        }

    }

    @media (max-width: 1199px) {
        .list-wrapper.on-detail-view {
            display: none;
        }
    }
    .list-wrapper.on-detail-view.full-screen-list {
        /*display: none;*/
    }
    .full-screen-detail {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: rgba(0,0,0,0.6);

        .detail-container {
            width: 100%;
            height: 100%;

            float:right;
            min-width: 370px;
            background: white;
            overflow-y: scroll;
        }
    }
    .slide-enter {
        transform: translate(100%, 0);
        -webkit-transform: translate(100%, 0);

    }
    .slide-enter-to {
        transform: translate(0, 0);
        -webkit-transform: translate(0, 0);
    }
    .slide-leave-to {
        transform: translate(100%, 0);
        -webkit-transform: translate(100%, 0);
    }

    .slide-enter-active,
    .slide-leave-active {
        transition: all 0.2s ease-in-out;
    }
    .table {
        margin-top:30px;
        background: white;
    }
    .table th {
        height: 50px;

    }
    .table tr {
        transition: all 0.3s ease-in-out;
    }
    .table tbody tr:hover {
        background: green;
        color: white;
        transform: translate(-5px, -5px);
        box-shadow: 2px 2px 2px gray;
    }
    .selected {
        background: blue;
        color:white;
    }
    .list-group-item {
        transition: all 0.3s ease-in-out;
    }
    .list-group-item:hover {
        background: #ddd;

    }
</style>
