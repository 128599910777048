<template>

    <div style="width: 100%; height: 100%; position: relative;">
        <div style="width: 100%; height: 50px; background-color: white;">
            <div class="container">
                <div class="row" style="padding-top:5px;">
                    <div class="col-9">
                        <select class="service-selector" v-model="selectedType">
                            <option value="all">모든종류</option>
                            <option v-for="type in noticeTypes" :value="type.value">{{type.label}}</option>
                        </select>

                        <select class="service-selector" v-model="selectedServiceTagId">
                            <option value="0">모든 서비스 지역</option>
                            <option v-for="tag in serviceTags" :value="tag.id">{{tag.name}}</option>
                        </select>
                        <button class="btn btn-success" v-on:click="updateListView">가져오기</button>
                    </div>
                    <div class="col-3 text-right">
                        <button class="btn btn-primary" @click="onClickAddBtn">만들기</button>
                    </div>
                </div>
            </div>
        </div>
        <div style="width: 100%; height: calc(100% - 50px); overflow-y: auto;">
            <div class="container">
                <div class="row" style="padding-bottom: 30px;">
                    <div class="col-6 col-md-3"  v-for="(item, index) in itemList" style="margin-top:10px;">
                        <post-item v-if="index < itemsToShow"
                                   v-bind:item="item"
                                   :is-selected="detailItem != null && item.id === detailItem.id"
                                   v-on:onClick="onClickItem" />
                    </div>
                </div>
            </div>
        </div>

        <div style="position: absolute; top:0; left: 0; width: 100%; height: 100%; background-color: rgba(0,0,0,0.4);" v-if="detailItem !== null">
            <div style="position:absolute; top:50%; left: 50%; height: calc(100% - 50px); transform: translate(-50%, -50%); padding:20px; width: 90%; min-width: 350px; overflow-y:auto; background-color: white;">
                <post-editor
                    v-bind:item="detailItem"

                    v-on:onClose="onClickItem"
                    v-on:updateList="updateListView"

                    :is-add-mode="isAddMode"/>
            </div>
        </div>
    </div>


</template>

<script>
    import BoundaryMapView from "../BoundaryMapView";

    import DetailLayout from "../DetailLayout";

    import {getItemById, includesTag, isOk, reorderItems} from "../../api/common";
    import PageEditor from "../../components/contents/magazine/Editor";
    import PageItem from "../../components/contents/magazine/Item";
    import {newMagazine} from "../../api/magazine";
    import {clone} from "../../utils/VarToString";
    import SortAndSearchInput from "../../inputs/SortAndSearchInput";
    import {getPostById, getPosts, getPostTypes, newPost} from "../../api/post";
    import PostEditor from "../../components/contents/post/Editor";
    import PostItem from "../../components/contents/post/Item";
    import {setTagsByOperatorId} from "../../api/operator";
    import {getOperatorId} from "../../store";
    import {getTagList} from "../../api/tag";

    export default {
        name: "NoticeView",
        components: {
            PostItem,
            PostEditor,
            SortAndSearchInput,

            PageItem,
            PageEditor,
            BoundaryMapView,
            DetailLayout,
        },
        data() {
            return {
                mapCenter: {
                    lat: 36.5, lng:127.8
                },
                mapZoom: 7,
                markers: [],

                itemsToShow: 200,
                sortTypeList: [
                    {
                        name: this.$t('list.sort_name'),
                        value: 'title',
                        order: 'asc'
                    },
                    {
                        name: this.$t('list.sort_type'),
                        value: 'type',
                        order: 'asc'
                    }
                ],
                currentSortType: {},

                itemList: [],
                detailItem: null,

                isEditMode: false,
                isAddMode: false,

                noticeTypes:getPostTypes(),
                selectedType:'all',
                serviceTags:[],
                selectedServiceTagId:0,


            }
        },
        created() {
            this.updateListView();
            this.updateEditorView();
            this.getTagList();
        },
        methods: {
            updateListView: function(check = isOk) {
                this.isAddMode = false;

                let that = this;

                let queries = [];
                if(this.$data.selectedType !== 'all') {
                    queries.push("type=" + this.$data.selectedType);
                }

                if(this.$data.selectedServiceTagId > 0) {
                    queries.push("tagId=" + this.$data.selectedServiceTagId);
                }

                let query = null;
                if(queries.length > 0) {
                    query = queries.join('&');
                }
                getPosts(query).then(function(items){
                    that.itemList = items;
                    //
                    // items.forEach(function (e) {
                    //     if(check(e)) {
                    //         that.itemList.push(e);
                    //     }
                    // });

                    // that.itemList = reorderItems(that.itemList, that.currentSortType)
                })
            },
            getTagList: function() {
                let self = this;
                console.log("get tag list");
                getTagList('service')
                    .then(function(data) {
                        console.log(data);
                        if(data.success == true) {
                            self.$data.serviceTags = data.list;

                        }
                    })
            },
            updateEditorView: function() {
            //     this.isEditMode = this.$route.query.hasOwnProperty('edit');
            //     this.isAddMode = this.$route.query.hasOwnProperty('new')
            //
                if (this.$route.query.hasOwnProperty('id')) {
                    let contentId = this.$route.query.id;

                    let that = this;
                    getPostById(contentId).then(item => {
                        that.detailItem = item;
                    });
            //     } else if (this.isAddMode) {
            //         let that = this
            //
            //         that.detailItem = newPlace()
            //         setTagsByOperatorId(getOperatorId(), that.detailItem);
            //
            //         this.isEditMode = true
            //     } else {
            //         this.isEditMode = false
                }
            },
            onClickSortType: function(selectedOption) {
                this.currentSortType = selectedOption;

                this.items = reorderItems(this.items, this.currentSortType)
            },
            onClickAddBtn: function() {
                this.isAddMode = true;

                this.detailItem = newPost();
                if(this.$data.selectedType !== 'all') {
                    this.detailItem.type = this.$data.selectedType;
                }
                this.detailItem.timeFrom = this.detailItem.timeTo = Date.now() / 1000;

                setTagsByOperatorId(getOperatorId(), this.detailItem);

                this.$router.push(`/admin/notice?new`);
            },
            onClickItem: function(item) {
                this.isAddMode = false;

                this.detailItem = clone(item);

                let path = `/admin/notice`;
                if(item) {
                    path += `?id=${item.id}`;
                }

                if(this.$route.fullPath !== path) {
                    this.$router.push(path);
                }
            },
            onSearchTags(v) {
                this.updateListView(e => { return isOk(e) && includesTag(v, e.tags) });
            }
        }
    }
</script>
