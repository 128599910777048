import {
    createItem,
    deleteItem, getCommonHeader,
    getItemById,
    getItemList,
    getItemListByURLPath,
    getPageItemByURLPath,
    updateItem,
    getBlob,
    urlGetRequest, errorHandler
} from "./common";
import {cleanLocalStorage} from "../store/auth.module";
import EventBus from "./event-bus";
import axios from "axios";

const model = "order";

// export function getOrders(pagination) {
//     let urlPath = `admin/orders`;
//
//     if(pagination != null) {
//         urlPath += `?page=${pagination.page}`;
//         urlPath += `&size=${pagination.size}`;
//         urlPath += `&sort=${pagination.sort}`;
//     }
//
//     return getPageItemByURLPath(urlPath);
// }

export function getOrderById(id) {

    return getItemById(model, id)
}


export function getOrderReport(from, to, operatorId=0) {
    let urlPath = `admin/sales/report?from=${from}&to=${to}&operatorId=${operatorId}`;
    return getBlob(urlPath);
}
export function searchOrderListByText(text, operatorId=0, pagination = null) {
    let urlPath = `admin/search/order?operatorId=${operatorId}&keyword=${text}`;

    if(pagination != null) {
        urlPath += `&page=${pagination.page}`;
        urlPath += `&size=${pagination.size}`;
        urlPath += `&sort=${pagination.sort}`;
    }

    return getPageItemByURLPath(urlPath);
}

// export function deleteOrder(id) {
//
//     return deleteItem(model, id)
// }

export function getUserOrder(userId, pagination) {
    let urlPath = `admin/orders/${userId}`;

    if(pagination != null) {
        urlPath += `?page=${pagination.page}`;
        urlPath += `&size=${pagination.size}`;
        urlPath += `&sort=${pagination.sort}`;
    }

    return getPageItemByURLPath(urlPath, false)
}
export function getMarkerSales(placeId, from, to, type) {
    return new Promise(function(resolve, reject) {
        EventBus.$emit("showLoading", true, "가져오는중");
        console.log("getBikeProducts " + process.env.VUE_APP_API_BASE_URL + 'admin/market/' + placeId + '/sales/' + type + "?from=" + from + "&to=" + to);
        axios.get(process.env.VUE_APP_API_BASE_URL + 'admin/market/' + placeId + '/sales/' + type + "?from=" + from + "&to=" + to, {
            headers: getCommonHeader()
        })
            .then(function(response) {
                console.log(response);
                EventBus.$emit("showLoading", false);
                let data = response.data;
                resolve(data);
            })
            .catch(function(err) {
                console.log(err);
                EventBus.$emit("showLoading", false);
                errorHandler(err);
                reject(err);
            })
    })
}
export function applyNewCommission(placeId, from, to, type) {
    return new Promise(function(resolve, reject) {
        EventBus.$emit("showLoading", true, "적용중입니다.");
        axios.get(process.env.VUE_APP_API_BASE_URL + 'admin/market/' + placeId + '/commission/update/' + type + "?from=" + from + "&to=" + to, {
            headers: getCommonHeader()
        })
            .then(function(response) {
                console.log(response);
                EventBus.$emit("showLoading", false);
                let data = response.data;
                resolve(data);
            })
            .catch(function(err) {
                console.log(err);
                EventBus.$emit("showLoading", false);
                errorHandler(err);
                reject(err);
            })
    })
}
export function updateOrder(item) {
    return updateItem(model, item);
}
export function getOrderHistory(orderId) {
    let urlPath = `admin/order/history?orderId=${orderId}`;
    return getPageItemByURLPath(urlPath, false);
}
export function getOrderSysLog(orderId) {
    let urlPath = `admin/order/syslog/${orderId}`;
    return getPageItemByURLPath(urlPath, false);
}
export function getSaleItem(orderId) {
    let urlPath = `admin/sale?id=` + orderId;
    return getPageItemByURLPath(urlPath, false);
}
export function makePayment(orderId) {
    let urlPath = 'admin/order/pay?orderId=' + orderId;
    return urlGetRequest(urlPath);
}
export function hireRefund(orderId) {
    let urlPath = 'admin/order/refund?orderId=' + orderId;
    return urlGetRequest(urlPath);
}
export function posOrderRefund(placeId, orderId) {
    let urlPath = 'admin/pos/' + placeId + '/order/' + orderId + '/refund';
    return urlGetRequest(urlPath);
}
export function cancelSales(orderId) {
    let urlPath = 'admin/sales/cancel?id=' + orderId;
    return urlGetRequest(urlPath);
}
export function couponRefund(orderId) {
    let urlPath = 'admin/order/coupon/refund?orderId=' + orderId;
    return urlGetRequest(urlPath);
}

export function getPosPendingOrders(placeId, from, to) {
    let urlPath = 'admin/pos/' + placeId + '/orders/pending/' + from + '/' + to;
    return urlGetRequest(urlPath);
}
export function getPosOrders(placeId, from, to) {
    let urlPath = 'admin/pos/' + placeId + '/orders/' + from + '/' + to;
    return urlGetRequest(urlPath);
}
export function completePosOrder(placeId, id) {
    let urlPath = 'admin/pos/' + placeId + '/order/' + id + '/complete';
    return urlGetRequest(urlPath);
}
export function getPosOrder(placeId, id) {
    let urlPath = 'admin/pos/' + placeId + '/order/' + id;
    return urlGetRequest(urlPath);
}
export function newOrder() {
    return {
        id: 0,
        parentId: null,
        operatorId: 0,
        userId: null,
        orderId: "",
        assetId: 0,
        assetName: "",
        creditId: 0,
        title: "",
        name: "",
        type: "",
        lastUpdatedWhen: null,
        tags: [],
        startTime: null,
        toTime: null,
        duration: 0,
        chargableDuration: 0,
        rateDuration: 0,
        baseDuration: 0,
        baseBalance: 0,
        chargeRate: 0,
        baseCharge: 0,
        due: 0,
        finalDue: 0,
        deductAmount: 0,
        paid: 0,
        transactionId: "",
        transactionTime: null,
        state: 0,
        uuid: ""
    }
}

export function getOrderTypeString(type) {
    if(type==='hire') {
        return '자전거 대여';
    } else if(type === 'setup_payment') {
        return '카드등록결제';
    } else if(type === 'setup_payment_cancel') {
        return '카드등록결제 취소';
    } else if(type === 'market') {
        return '마켓';
    } else if(type === 'place') {
        return '플레이스';
    }
    return 'Unknown (' + type + ')';
}
export function getBikeOrderStateString(state) {
    if(state == 0) {
        return '대여중';
    } else if(state == 1) {
        return '대여종료';
    }
    return state;
}
