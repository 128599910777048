import {getOperatorId} from "../store";
import {cleanLocalStorage} from "../store/auth.module";

const axios = require('axios');
const lodash = require('lodash');
import EventBus from "./event-bus";

export function reorderItems(items, sortType) {
    return lodash.orderBy(items, sortType.value, sortType.order);
}

export function getAPIURL(){
    return process.env.VUE_APP_API_BASE_URL;
}

export function checkOperator(item) {
    let oid = getOperatorId();
    if(0 === oid){
        return true;
    }

    if(0 === item.operatorId){
        return true;
    }

    return oid === item.operatorId;
}

export function getCommonHeader() {
    startSession();
    let authId = localStorage.getItem('id');
    let authToken = localStorage.getItem('auth');

    return {
        'Content-Type': 'application/json',
        'id': authId,
        'auth': authToken
    };
}
export function errorHandler(err) {
    if(err.response != undefined) {
        if(err.response.status == 401) {
            cleanLocalStorage();
            EventBus.$emit("sessionExpired");
        }
    }
}
export function createItem(model, item) {
    let address = `${process.env.VUE_APP_API_BASE_URL}admin/${model}`;

    return new Promise(function(resolve, reject) {
        axios({
            method: 'post',
            url: address,
            data: JSON.stringify(item),
            headers: getCommonHeader()
        }).then(function(response){
            let data = response.data;

            if(data.success) {
                resolve(data.item);
            } else {
                alert(data.message);
            }
        }).catch(error => {
            errorHandler(error);
            console.log(error.response);
        })
    })
}

export function postBlob(urlPath, data) {
    let axiosConfig = {
        method: 'post',
        responseType: 'blob',
        data: JSON.stringify(data),
        url: process.env.VUE_APP_API_BASE_URL + urlPath,
        headers: getCommonHeader()
    };
    EventBus.$emit("showLoading", true, '가졍오는중');
    return new Promise(function(resolve, reject) {
        axios( axiosConfig).then(function(response) {
            EventBus.$emit("showLoading", false);
            resolve(response);
        }).catch(function(err) {
            EventBus.$emit("showLoading", false);
            errorHandler(err);
            reject(err);
        });
    })
}
export function getBlob(urlPath) {
    let axiosConfig = {
        method: 'get',
        responseType: 'blob',
        url: process.env.VUE_APP_API_BASE_URL + urlPath,
        headers: getCommonHeader()
    };
    EventBus.$emit("showLoading", true, "가져오는중");
    return new Promise(function(resolve, reject) {
        axios( axiosConfig).then(function(response) {
            EventBus.$emit("showLoading", false);
            resolve(response);
        }).catch(function(err) {
            EventBus.$emit("showLoading", false);
            errorHandler(err);
            reject(err);
        });
    })
}
export function getPageItemByURLPath(urlPath, enableAlert = true) {
    let axiosConfig = {
        method: 'get',
        url: process.env.VUE_APP_API_BASE_URL + urlPath,
        headers: getCommonHeader()
    };

    // console.log("getPageItemByURLPath " + urlPath);
    return new Promise(function(resolve, reject) {
        EventBus.$emit("showLoading", true, "Getting Data");
        axios(axiosConfig).then(function(response){
            EventBus.$emit("showLoading", false);
            resolve(response.data);
            if(!response.data.success && enableAlert)
                alert(response.data.message);
        }).catch(error => {
            console.log("page item error " + error);
            EventBus.$emit("showLoading", false);
            if(error.response != undefined) {
                console.log(error.response);
            }
            if(reject != null) {
                reject(error);
            }
            errorHandler(error);
            console.log(error.response);
        })
    })
}

export function getItemListByURLPath(urlPath) {
    let axiosConfig = {
        method: 'get',
        url: process.env.VUE_APP_API_BASE_URL + urlPath,
        headers: getCommonHeader()
    };

    return new Promise(function(resolve) {
        axios(axiosConfig).then(function(response){
            let data = response.data;

            if(data.success) {
                resolve(data.list);
            } else {
                alert(data.message);
            }
        }).catch(error => {

            console.log("error");
            console.log(error);
            errorHandler(error);
            // if(error.response.status === 401)
            //     cleanLocalStorage();
            // else
            //     console.log(error.response);
        })
    })
}

export function getItemList(model, query = null) {

    let url = `admin/${model}s`;
    if(query !== null && query.length > 0) {
        url += '?' + query;
    }
    console.log("getItemList url =" + url);
    return getItemListByURLPath(url);
}

// export function getItemListWithCondition(model, condition) {
//
//     return getItemListByURLPath(`admin/${model}s?${condition}`)
// }

export function getItemById(model, id) {
    let address = process.env.VUE_APP_API_BASE_URL + 'admin/'+ model +'/' + id;

    return new Promise(function(resolve, reject) {
        axios({
            method: 'get',
            url: address,
            headers: getCommonHeader()
        }).then(function(response){
            let data = response.data;

            if(data.success) {
                resolve(data.item);
            } else {
                // alert(data.message);
                console.log(`${data.message}_ID_${id}`);
            }
        }).catch(error => {
            errorHandler(error);
            console.log(error.response);
        })
    })
}

export function deleteItem(model, id) {
    let address = process.env.VUE_APP_API_BASE_URL + 'admin/'+ model +'/' + id;

    return new Promise(function(resolve, reject) {
        axios({
            method: 'delete',
            url: address,
            headers: getCommonHeader(),
            data : null
        }).then(function(response){
            let data = response.data;

            if(data.success) {
                resolve(data.item);
            } else {
                alert(data.message);
            }
        }).catch(error => {
            errorHandler(error);
            console.log(error.response);
        })
    })
}

export function updateItem(model, item) {
    let address = process.env.VUE_APP_API_BASE_URL + 'admin/'+ model;

    return new Promise(function(resolve, reject) {
        axios({
            method: 'post',
            url: address,
            data: JSON.stringify(item),
            headers: getCommonHeader()
        }).then(function(response){
            let data = response.data;

            if(data.success) {
                resolve(data.item);
            } else {
                alert(data.message);
            }
        }).catch(error => {
            errorHandler(error);
            console.log(error.response);
        })
    })
}

export function urlGetRequest(address) {
    address = process.env.VUE_APP_API_BASE_URL + address;
    return new Promise(function(resolve, reject) {
        EventBus.$emit("showLoading", true, "Processing");
        axios({
            method: 'get',
            url: address,
            headers: getCommonHeader()
        }).then(function(response){
            EventBus.$emit("showLoading", false);
            let data = response.data;
            resolve(data)
            // if(data.success) {
            //     resolve(data.item);
            // } else {
            //     // alert(data.message);
            //     console.log(`${data.message}_ID_${id}`);
            // }
        }).catch(error => {
            EventBus.$emit("showLoading", false);
            errorHandler(error);
            reject(error);
        })
    })
}
/**
 * Item(user, order 등) 조회 및 생성시 페이지 이동에 대한 함수 입니다.
 * @param page
 * @param route
 * @param router
 * @param item
 */
export function changeURL(page, route, router, item = null) {
    let path = `/admin/${page}`;
    if(item) {
        path += `?id=${item.id}`;
    }

    if(route.fullPath !== path) {
        router.push(path);
    }
}

// 테이블 리스트 조건 검사
export function isOk(e){
    if(e.deleted) return false;

    // if(e.operatorId === undefined || e.operatorId === 0)
    //     return true;

    return checkOperator(e);
}

export function includesTag(value, tags) {
    if("" === value) return true;

    if(0 === tags.length) return false;

    for(let tag of tags){
        if(tag.name.includes(value))
            return true;
    }

    return false;
}

export function defaultPagination() {
    return {
        keyword: null,
        page: 0,
        size: 100,
        sort: "createdWhen,desc"
    }
}
export function showDialog(message = '', title = '') {
    let params = {
        title:title,
        text:message
    }
    EventBus.$emit("showDialog", params);
}
export function startSession() {
    EventBus.$emit("startSession");
}
export function millisToYYYYMM(timestamp, splitter = '-') {
    if(timestamp == 0) {
        return "";
    }
    let date = new Date(timestamp);

    //

    let yy = date.getFullYear().toString();

    let mm = (date.getMonth()+1).toString();
    if (mm.length == 1) mm = '0'+mm;

    return yy + splitter + mm;

}
export function millisToDate(timestamp, splitter = '-') {
    if(timestamp == 0) {
        return "";
    }
    let date = new Date(timestamp);

    //

    let yy = date.getFullYear().toString();

    let mm = (date.getMonth()+1).toString();
    if (mm.length == 1) mm = '0'+mm;

    let dd = date.getDate().toString();
    if (dd.length == 1) dd = '0'+dd;


    return yy + splitter + mm + splitter + dd;

}
export function millisToDateTime(timestamp) {
    if(timestamp == 0) {
        return "";
    }
    let date = new Date(timestamp);

    //

    let yy = date.getFullYear().toString();

    let mm = (date.getMonth()+1).toString();
    if (mm.length == 1) mm = '0'+mm;

    let dd = date.getDate().toString();
    if (dd.length == 1) dd = '0'+dd;

    let hh = date.getHours().toString();
    if (hh.length == 1) hh = '0'+hh;

    let minute = date.getMinutes().toString();
    if (minute.length == 1) minute = '0'+minute;

    return yy + '.' + mm + '.' + dd + ' ' + hh + ':' + minute;

}
export function getHM(time) {

    let date = new Date(time);

    //

    let hh = date.getHours().toString();
    if (hh.length == 1) hh = '0'+hh;

    let minute = date.getMinutes().toString();
    if (minute.length == 1) minute = '0'+minute;



    // let today = new Date();
    // if(today.getFullYear().toString() === yy) {
    //   return mm+'.'+dd+' '+hh+':'+minute+':'+second;
    // }
    return hh+':'+minute;
}
export function playSound() {
    const audio = new Audio("/mary-order.mp3");
    audio.play();
}
